import { v4 } from 'uuid'
import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import _ from 'lodash'
import './assets/css/style.css'
import TextField from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'
import {
  FormGroup,
  Label,
  Input,
  FormFeedback,
} from 'reactstrap'
import { getCountries } from 'redux/actions/medioCamp.actions'
import { addpayment } from 'redux/actions/dashboard.actions'
import { showLoader } from 'redux/actions/loader.actions'
const ModalCard = (props) => {
  const [nameVale, setNameValue] = useState('')
  const [numValue, setNumValue] = useState('')
  const [dateValue, setDateValue] = useState('')
  const [cvvValue, setCvvValue] = useState('')
  const [errors, setErrors] = useState({
    name: false,
    num: false,
    cvv: false,
    date: false,    
  })
  const [errorsMessage ,setErrorsMesagge] = useState({
    name: "",
    num: "",
    cvv: "",
    date: "",    
  })

    useEffect(() => {      
      // props._getCountries();
    }, [])
  
  
    
    const checkdata = () => {
      
      
      
      props._showLoader(true)
      //checkparametros

      let mErrors = {        
        name: nameVale === '',
        cvv:(cvvValue.length!=3&&cvvValue.length!=4),
        num:numValue.length!=16,
        date:dateValue.length!=5  
        
      }
  
      // props.utils.log('mErrors', mErrors)
  
      setErrors((prev) => ({
        ...prev,
        ...mErrors,
      }))
  
      return !_.some(mErrors, e => e)

      // register(e)
      
    }
    const register =(e) => {  
      if (!checkdata()) return
      console.log("nameVale ",nameVale)
      console.log("numValue ",numValue)
      console.log("dateValue ",dateValue)
      console.log("cvvValue ",cvvValue)
      const mAccount = _.find(props.accounts, { id: props.account })
      console.log("current account ", mAccount)
      props._showLoader(false)
      let now = Date.now()
      let id = v4()
      const body={
        id,
        name:nameVale,
        card:numValue,
        expiration_date :dateValue,
        cvv:cvvValue,
        status:true,
        default:true,
        createAt:now,
        updateAt:now,
        client:mAccount.client
      }
      props._addpayment(body,mAccount)
      props._showLoader(false)
      closeModal(e)
    }
    
    
    const closeModal = (e) => 
    {      
        if (e) e.preventDefault()
        window.$.fancybox.close()
    }
    

  return (
    <div
      id='associate-card'
      style={{ display: 'none' }}
      className='upsert-modal w-75 h-75'
    >
        <div className='titleOneStep w-100'>
    <p>
      Asociemos tu tarjeta
    </p>
  </div>
  <div className='bodyOneStep w-100'> 
    <div className='oneColBody'>
        <FormGroup>
          <Label
            style={{
              color: '#05252d',
              fontWeight: 500,
              marginBottom: '5px',
            }}
          >
            Nombre que aparece en la tarjeta
          </Label>
          <Input              
            name='nameCard'
            id='nameCard'            
            className='input'
            type='text'
            // maxLength='5'
            value={nameVale}
            onChange={(e) => setNameValue(e.target.value)}
            placeholder='Ingresa el nombre que aparece en la tarjeta'
            invalid={errors.name}
          />
          <FormFeedback>Nombre de tarjeta vacia</FormFeedback>
        </FormGroup>
    </div>
    <div className='oneColBody'>
        <FormGroup>
          <Label
            style={{
              color: '#05252d',
              fontWeight: 500,
              marginBottom: '5px',
            }}
          >
            Numeración de la tarjeta
          </Label>
          <Input              
            name='numberCard'
            id='numberCard'
            className='input'
            type='number'
            maxLength={5}
            mask='9999 9999 9999 9999'
            value={numValue}
            onChange={(e) => setNumValue(e.target.value)}
            placeholder='Ingrese la numeración de la tarjeta sin guiones'
            invalid={errors.num}
          />
          <FormFeedback>Ingresa una tarjeta valida</FormFeedback>
        </FormGroup>
    </div>
    <div className='oneColBody'>
        <FormGroup>
          <Label
            style={{
              color: '#05252d',
              fontWeight: 500,
              marginBottom: '5px',
            }}
          >
            Fecha de vencimiento
          </Label>
          <Input              
            name='datexp'
            id='datexp'
            className='input'
            type='text'
            mask='99/99'
            value={dateValue}
            onChange={(e) => setDateValue(e.target.value)}
            placeholder='Ingrese la fecha de vencimiento 99/99'
            invalid={errors.date}
          />
          <FormFeedback>Ingreso de fecha incorrecta</FormFeedback>
        </FormGroup>
    </div>
    <div className='oneColBody'>
        <FormGroup>
          <Label
            style={{
              color: '#05252d',
              fontWeight: 500,
              marginBottom: '5px',
            }}
          >
            CVV
          </Label>
          <Input              
            name='cvv'
            id='cvv'
            className='input'
            type='number'
            mask='9999'
            value={cvvValue}
            onChange={(e) => setCvvValue(e.target.value)}
            placeholder='Ingresa código CVV'
            invalid={errors.cvv}
          />
          <FormFeedback>Codigo CVV no cumple con el formato</FormFeedback>
        </FormGroup>
    </div>
  </div>
  <div className='footOneStep w-100'>
    <a href='#' className='btnCancelOS' onClick={closeModal}>
      Cancelar
    </a>

    <a className='btnConfirmOS' onClick={register} >
      Confirmar
    </a>
  </div>
        
      
    </div>
  )   

    
    
}

const mapStateToProps = (state) => ({        
    countries:state.countryReducer,
    currentUser: state.app.current_user,
    currentAccount: state.accounts.default,
    accounts: state.accounts.list,
    account: state.accounts.default,
  })
  
  const mapDispatchToProps = (dispatch) => ({
    _getCountries: () => dispatch(getCountries()),
    _addpayment: (body,account) => dispatch(addpayment(body,account)),
    _showLoader: (show) => dispatch(showLoader(show)),

  })

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ModalCard))








