import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { DateRangeEditFormat } from "@easygosanet/react-datatable";
import { setImplementButton } from "redux/actions/templates.actions";
import Swal from "sweetalert2";
import { parse, isAfter, isValid } from "date-fns";

const CustomCellDates = ({ row, editValueDates }) => {
  const propsEdit = useSelector((state) => state.templates.edit);
  const dataUser = useSelector((state) => state.templates.userInfo);
  const dispatch = useDispatch();

  /**
   * Función para obtener una fecha formateada en el formato MM/DD/YYYY.
   * @param {string} fecha - La fecha en formato "DD.MM.YYYY".
   * @returns {string|null} - La fecha formateada en formato "MM/DD/YYYY" o null si el formato de entrada es inválido.
   */
  const getData = (fecha) => {
    console.log("first", { fecha });
    // Expresión regular para validar el formato "DD.MM.YYYY"
    const regex = /^\d{2}\.\d{2}\.\d{4}$/;

    // Verifica si la fecha cumple con el formato válido
    if (!regex.test(fecha)) return null;

    // Verifica si la fecha no es "00.00.0000" y si cumple con el formato válido
    if (fecha && regex.test(fecha) && fecha !== "00.00.0000") {
      // Divide la fecha en día, mes y año
      const fini = fecha.split(".");
      // Formatea la fecha como "MM/DD/YYYY"
      const formattedDate = `${fini[1]}/${fini[0]}/${fini[2]}`;

      // Valida si formattedDate es una fecha anterior al día de hoy
      const date = new Date(formattedDate);
      const currentDate = new Date();
      if (date < currentDate) {
        // Devuelve la fecha actual con el formato correcto
        const currentFormattedDate = `${
          currentDate.getMonth() + 1
        }/${currentDate.getDate()}/${currentDate.getFullYear()}`;
        return currentFormattedDate;
      }

      return formattedDate;
    } else {
      // Obtiene la fecha actual
      const currentDate = new Date();
      // Formatea la fecha actual como "MM/DD/YYYY"
      const currentFormattedDate = `${
        currentDate.getMonth() + 1
      }/${currentDate.getDate()}/${currentDate.getFullYear()}`;
      return currentFormattedDate;
    }
  };

  const editDataDates = (value, propsEdit, dataUser) => {
    console.log("first", { value, propsEdit, dataUser });

    const { start } = value[0];
    // Valida que la fecha de inicio no sea menor a la fecha actual (sin importar la hora)
    const currentDate = new Date().setHours(0, 0, 0, 0);
    const date = new Date(start).setHours(0, 0, 0, 0);
    if (date < currentDate) {
      Swal.fire({
        title: "Error",
        text: "La fecha de inicio no puede ser anterior a la fecha actual",
        icon: "error",
        confirmButtonText: "Aceptar",
      });
      return;
    } else {
      editValueDates(value, propsEdit, dataUser);
    }
  };

  const fechaIni = row.fechaIni ? getData(row.fechaIni) : null;
  const fechaFin = row.fechaFin ? getData(row.fechaFin) : null;
  const isDateEmpty =
    (row?.fechaFin === "" ||
      row?.fechaIni === "" ||
      fechaIni === null ||
      fechaFin === null ||
      (fechaIni && fechaFin && new Date(fechaIni) > new Date(fechaFin))) &&
    row.name !== "Totales";

  if (isDateEmpty) {
    // dispatch(setImplementButton(true));
  } else {
    // dispatch(setImplementButton(false))
  }
  // console.log("isDateEmpty ini", row.fechaIni);
  const cellStyles = {
    display: "flex",
    alignItems: "center",
    border: isDateEmpty ? "2px solid red" : "2px solid transparent",
  };

  return (
    <div style={cellStyles}>
      {isDateEmpty && (
        <i
          className="fa fa-exclamation-circle text-danger"
          aria-hidden="true"
          style={{ marginRight: "8px" }}
        ></i>
      )}
      <DateRangeEditFormat
        start={fechaIni !== null ? new Date(fechaIni) : null}
        end={fechaFin !== null ? new Date(fechaFin) : null}
        format="dd/mm/yyyy"
        justifyContent="center"
        center="true"
        onEditValue={(value) => editDataDates(value, propsEdit, dataUser)}
        id={{ id: row.id, campo: "dates" }}
        nameColumn={row}
        type="text"
        min={new Date().toISOString().slice(0, 10)}
      />
    </div>
  );
};

export default CustomCellDates;
