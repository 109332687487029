import { v4 } from 'uuid'
import * as Logger from 'utils/logger'
import * as APILA from 'helpers/apiLA'


export const ADD_CLIENT_MYSQL = 'CLIENT_MYSQL:ADD'
export const CLEAN_CLIENT_MYSQL = 'CLIENT_MYSQL:CLEAN'
const TAG = 'CLIENT_MYSQL:Actions'



export const cleanClient_Mysql = () => (dispatch) => {
  dispatch({
    type: CLEAN_CLIENT_MYSQL,
  })
}

export const getClient_Mysql = (body) => dispatch => {
    dispatch(cleanClient_Mysql());
    
    console.log(TAG,"  action body.data",body);  
   
     APILA.getAllClients(body)
      .then(response => {
        Logger.log(TAG, ' response', response)
        let { clients } = response.data.clients
  
        response.data.clients.forEach(client => {
              dispatch(addClient(client))
         })
      })
      .catch(err => {
        Logger.log(TAG, 'error getClient_Mysql', err)
      })
      .then(() => {
        // dispatch()
        Logger.log(TAG, 'getClient_Mysql then')

      })
  }


  const addClient = client => {
    
    return dispatch => {
      dispatch({
        type: ADD_CLIENT_MYSQL,
        client
      })
    }
  }
  