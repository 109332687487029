import React, { useEffect, useState } from "react";
import _ from "lodash";
import { connect } from "react-redux";

// organization components
import { Tooltip } from "antd";
import * as APILA from "helpers/apiLA";
import PageLoader from "components/page_loader";
import Swal from "sweetalert2";
import { upsertReport } from "redux/actions/report.actions";
import ReactDataTable, { Status, SimpleText } from "@easygosanet/react-datatable";
import DataTableUtils, {
  Share,
  ShowColumn2,
  FilterColumn2,
} from "@easygosanet/react-datatable-utils";
import { useLocation, useHistory } from "react-router-dom";
import DetailColEdit from "components/DetailCol";
import analytics from "../../../helpers/analytics";

import * as Modal from "redux/actions/modal.actions";
import { showLoader } from "redux/actions/loader.actions";
import {
  setEdit,
  clearEdit,
  getReports,
  clearReports,
  deleteReport,
} from "redux/actions/report.actions";
import { v4 } from 'uuid'
import { withUtils } from "utils";
import { Auth } from "aws-amplify";
import * as API from "helpers/api";
import CreateAccountLogOut from "components/flowLogOutUser/createAccount";
import { nextStep } from "redux/actions/userLogout.action"
import "./css/styles.scss";


const getActiveLabel = (active) => {
  if (active) {
    return 'Activo'
  } else {
    return 'Eliminado'
  }
}

const getClassActive = (active) => {
  if (active) {
    return "status_mix_aprobado"
  } else {
    return "status_cancelado"
  }
}

const Content = (props) => {
  const location = useLocation();
  const history = useHistory();

  // Busqueda de los parametros en la url
  const params = new URLSearchParams(location.pathname);
  const searchParams = new URLSearchParams(location.search);

  // Obtencion de los parametros de la URL  
  const accountParams = params.get('account');
  const viewColumn = params.get('viewColumn');
  const viewGeneral = params.get('view');
  const agrupation = params.get('group');
  const page = params.get('page');
  const objectParams = {
    accountCurrent: accountParams === null ? props.currentAccount : accountParams,
    viewColumnParams: viewColumn === null ? "default" : viewColumn,
    viewGeneralParams: viewGeneral === null ? "list" : viewGeneral,
    agrupationParams: agrupation === null ? "default" : agrupation,
    pageParams: page === null ? 1 : page
  }
  // FilterInfo: estado de la librearia de filtros
  const columnParams = searchParams.get('column_nombre');
  let columnNombre = [
    {
      column: "Nombre",
      value: columnParams,
    }
  ]
  const [filterInfo, setFilterInfo] = useState(columnParams === null || columnParams === undefined ? [] : [...columnNombre]);

  // Agrupation 
  let validateAgrupation = agrupation === null || agrupation === undefined ? "default" : agrupation
  const [groupedName, setGroupedName] = useState(validateAgrupation === "default" ? "" : validateAgrupation);
  const [groupedDef, setGroupedDef] = useState("Por defecto");
  const [account, setAccount] = useState(props.currentAccount);
  const [reports, setReports] = useState([]);
  const [searchData, setSearchData] = useState(false)
  const [filterText, setFilterText] = useState(searchParams.get('results') || '');
  const [isLoading, setIsLoading] = useState(false);
  const [reportValue, setReportValue] = useState(viewGeneral || "");
  const [filters, setFilters] = useState({});
  const [nameView, setNameView] = useState("");
  const [views, setViews] = useState([]);
  const [link, setLink] = useState(viewGeneral || "");
  const orderDefault = ["name", "link", "createAt"];
  const [options, setOptions] = useState(["Nombres"]);
  const [isAdmin, setIsAdmin] = useState(false)
  const [permisos, setPermisos] = useState({
    approve: false,
    create: false,
    delete: false,
    edit: false,
    export: false,
    manageRoles: false,
    view: true,
  });

  const [currentPage, setCurrentPage] = useState(Number(objectParams.pageParams) || 1);

  const [columns, setColumns] = useState([
    {
      name: "Nombre",
      selector: "name",
      sortable: true,
      type: "text",
      center: true,
      maxWidth: "20%",
      id: "name",
      sortable: true,
      tag: "name",
      minWidth: "20%",
      omit: false,
      visible: false,
      show: true,
      cell: (row) => (
        <DetailColEdit
          text={row.name}
          id={"name"}
          accounts={[]}
          viewBudget={false}
          deleteData={() => deleteReport(row.id, row.name)}
          deleteV={true}
          nameColumn={row}
          editInfo={() => goToEditPage(row)}
          edit={row.permisos ? row.permisos.edit : false}
          onEditValue={editData}
          editable={false}
          budgetOld={false}
          type={"text"}
          permissions={row.permisos}
          reActivar={true}
          activate={row.active}
          activeData={() => activateData(row)}
        />
      ),
    },
    {
      name: "Enlace",
      selector: "link",
      sortable: true,
      center: true,
      minWidth: "150px",
      tag: "link",
      omit: false,
      id: "link",
      style: {
        fontWeight: "bold",
      },
      cell: (row) => (
        <div className="simpleText">
          <SimpleText
            style={{ width: "100%" }}
            value={row.link}
            type={"text"}
            editable={false}
            nameColumn={row}
            onEditValue={editData}
            id={"link"}
          />
        </div>
      ),
    },
    {
      name: "Fecha de Creación",
      selector: "createAt",
      omit: false,
      tag: "createAt",
      sortable: true,
      center: true,
      id: "createAt",
      style: {
        fontWeight: "bold",
      },
      cell: (row) => formatDate(row.createAt),
    },
    {
      omit: false,
      name: "Estado",
      id: "status",
      tag: "status",
      type: "status",
      show: false,
      selector: "status",
      sortable: true,
      center: true,
      cell: (row) => <Status
        value={getActiveLabel(row.active)}
        // label={getStatusLabel(row.status)}
        // className={getStatusClassName(row.status)}
        label={getActiveLabel(row.active)}
        className={getClassActive(row.active)}
        justifyContent={"center"}
        center={"true"}
      />,
    },
    // {
    //   name: '',
    //   selector: 'botones',
    //   sortable: false,
    //   button: true,
    //   cell: (row) => (
    //     <Actions onClick={() => deleteReport(row.id, row.name)} />
    //   ),
    // },
  ]);

  const setParamsUrl = (objeto) => {
    const { accountCurrent, viewColumnParams, agrupationParams, pageParams, viewGeneralParams } = objeto
    history.push({
      pathname: `/reportes/account=${accountCurrent}&view=${viewGeneralParams}&group=${agrupationParams}&viewColumn=${viewColumnParams}&page=${pageParams}`,
    })
    if (location.search.length > 0) {
      history.push({
        search: location.search
      })
    }
  }

  const getReports = async () => {
    try {
      let response = await API.getReports(props.currentAccount)
      let { reports } = response.data
      let found = reports.find(({ link }) => link === viewGeneral)
      setReportValue(viewGeneral);
      setLink(viewGeneral);
      analytics.seleccionReporte({
        ubicacion: "Reportes",
        nombre: found?.name,
      });
    } catch (error) {
      console.log("Error message reports", error)
    }
  }

  useEffect(() => {
    setParamsUrl(objectParams)
    if (columnParams !== null && columnParams !== undefined && columnParams === "") {
      history.push({
        search: ''
      })
    }
    if (agrupation !== null && agrupation !== undefined && agrupation !== 'default') {
      changeOptions(agrupation)
    }
    if (viewGeneral !== null && viewGeneral !== undefined && viewGeneral !== "default") {
      getReports()
    } else {
      setGroupedName("")
      setReportValue("default");
      setLink("");
    }
  }, []);

  useEffect(() => {
    setAccount(props.currentAccount);
    getPermisos();
    setLink("");
    setReportValue("");
    getView(true);
  }, [props.currentAccount]);

  useEffect(() => {
    if (props.currentUser !== "") {
      account !== "" && props._getReports(account);
    }
  }, [account, props.currentUser]);

  useEffect(() => {
    let reports3 = _.sortBy(props.reports, ["createAt"]);
    setReports(reports3);
  }, [props.reports, props.permisos]);

  const getPermisos = async () => {
    try {
      setIsLoading(true);
      const userData = await Auth.currentAuthenticatedUser();
      let sub = userData.username
      let body = {
        account: props.currentAccount,
        user: sub,
      };

      let respuesta = await APILA.getPermitsByAccountAndUser(body);
      let permisos = await APILA.getRoleNameById({
        id: respuesta.data.permisos[0].idRol,
      });
      let permisosAc = permisos.data.rol[0].permissions.find(
        (ele) => ele.name === "Reportes"
      );
      if (permisos.data.rol[0].rolPadre === "Administrador" || permisos.data.rol[0].id === "Administrador") {
        setIsAdmin(true)
      } else {
        setIsAdmin(false)
      }
      setPermisos(permisosAc);
      setIsLoading(false);
    } catch (err) {
      if (err === 'The user is not authenticated') {
        setIsAdmin(true)
        setPermisos(prev => ({
          ...prev,
          view: true,
          create: true,
          edit: true,
          delete: true,
          approve: true,
          export: true,
          manageRoles: true
        }))
      }
      setIsLoading(false);
      console.log("error permisos", err);
    }
  };

  const editData = async (valor) => {
    let { column, id, value } = valor[0];
    let title = "Está seguro que desea editar?"
    props._showConfirmationModal("Editar reporte", title, async () => {
      analytics.accionesTabla({
        accion: "Editar con doble clic",
        ubicacion: "Reportes",
      });
      let state = {
        reportId: column.id,
        name: id === "name" ? value : column.name,
        link: id === "link" ? value : column.link,
        currentAccount: column.account,
        edit: true,
      };

      props._upsertReport(
        state.reportId,
        state.name,
        state.link,
        state.currentAccount,
        state.edit
      );
    });
  };

  const goToEditPage = (edit) => {
    props._setEdit(edit);

    window.$.fancybox.open({
      src: "#upsert-report-modal",
      //type: 'modal',
      opts: {
        modal: true,
        afterClose: () => props._clearEdit(),
      },
    });
  };

  const deleteReport = async (id, name) => {
    props._showConfirmationModal(
      "Eliminar Reporte?",
      `Esta seguro que desea eliminar el reporte ${name}`,
      async () => {
        let response = await API.deleteReport({
          id: id
        })
        props._getReports(account);
      }
    );
  };

  const formatDate = (millis) => {
    let date = new Date(millis);
    const months = [
      "Ene",
      "Feb",
      "Mar",
      "Abr",
      "May",
      "Jun",
      "Jul",
      "Ago",
      "Sep",
      "Oct",
      "Nov",
      "Dic",
    ];

    return `${date.getDate()} - ${months[date.getMonth()]
      } - ${date.getFullYear()}`;
  };

  const handleFilterTextChange = (value) => {
    analytics.busqueda({
      ubicacion: "Reportes",
      busqueda: value,
    });

    history.push({
      search: `search_query=&results=${value}`
    })

    setFilterText(value);

    reports.forEach((item) => {
      item.status = item.active ? "Activo" : "Eliminado"
      if (item.name.toLowerCase().includes(filterText.toLowerCase())) {
        setSearchData(true)
        return item.name.toLowerCase().includes(filterText.toLowerCase())
      } else if (item.status.toLowerCase().includes(filterText.toLowerCase())) {
        setSearchData(true)
        return item.status.toLowerCase().includes(filterText.toLowerCase())
      }
    })
    if (value === "") {
      history.push({
        search: ''
      })
      setSearchData(false)
    }
  };

  const handleShowColumnChange = (column, show) => {
    let index = columns.findIndex((col) => col.selector === column);
    let aux = columns.map((col) => col);
    if (aux[index]) {
      aux[index].omit = show;
      setColumns(aux);
    }
  };

  const handleColumnSortChange = (positions) => {
    let aux = columns.map((col) => col);
    positions.forEach((columnId, position) => {
      let index = columns.findIndex((col) => col.selector === columnId);
      if (index !== -1) {
        aux[index].position = position;
      }
    });

    aux = _.orderBy(aux, ["position"]);

    setColumns(aux);
  };

  const activateData = async (row) => {
    let title = ''
    title = `Esta seguro que desea activar el reporte "${row.name}"?`;

    props._showConfirmationModal("Activar reporte?", title, async () => {
      try {
        setIsLoading(true)
        let activat = await API.activateReport({ id: row.id })

        let bodyBitacora = {
          id: v4(),
          date: new Date(),
          account: account,
          action: 'se ha activado un reporte',
          component: 'reports',
          data: row
        }
        APILA.insertBitacora(bodyBitacora).then(() => {
          Swal.fire({
            title: "Reporte activado!",
            text: "Reporte activado correctamente.",
            icon: "success",
            showCancelButton: false,
            confirmButtonColor: "#3085d6",
            confirmButtonText: "Ok",
          });
          props._getReports(account);
          setFilterText('');
          setSearchData(false);
        })
        setIsLoading(false)


      } catch (error) {
        Swal.fire({
          title: "Ups!",
          text: "Hubo un error al activar el reporte",
          icon: "error",
          showCancelButton: false,
          confirmButtonColor: "#3085d6",
          confirmButtonText: "Ok",
        });
        setIsLoading(false)
      }
    })
  }

  const handleFilterPerColumn = (value) => {
    if (value[0].value === "") {
      history.push({
        search: ''
      })
    }
    if (value.length > 0) {
      setFilterInfo(value);
      analytics.filtro({
        ubicacion: "Reportes",
      });
      let dataFinal = [];
      value.forEach((val) => {
        if (val.value === "") {
          history.push({
            search: ''
          })
        } else {
          history.push({
            search: `search_column=&column_nombre=${val.value}`
          })
        }
        if (val.column === "Nombre") {
          let dataEnc = props.reports.filter((ele) =>
            ele.name.toLowerCase().includes(val.value.toLowerCase())
          );
          if (dataEnc.length > 0) {
            dataFinal = [...dataFinal, ...dataEnc];
          } else {
            dataFinal = [];
          }
        }
      });
      let set = new Set(dataFinal.map(JSON.stringify));
      let arrSinDuplicaciones = Array.from(set).map(JSON.parse);
      setReports([...arrSinDuplicaciones]);
    } else {
      history.push({
        search: ''
      })
      let reports3 = _.sortBy(props.reports, ["createAt"]);
      setReports(reports3);
      setFilterInfo([]);
    }
  };

  let filteredData = reports.filter((item) => {
    item.status = item.active ? "Activo" : "Eliminado"
    if (item.name.toLowerCase().includes(filterText.toLowerCase())) {

      return item.name.toLowerCase().includes(filterText.toLowerCase())
    } else if (item.status.toLowerCase().includes(filterText.toLowerCase())) {

      return item.status.toLowerCase().includes(filterText.toLowerCase())
    }
  }
  );

  if (!_.isEmpty(filters)) {
    filteredData = filteredData.filter((item) => {
      let result = true;

      for (const filterKey in filters) {
        let response;
        let filter = filters[filterKey];
        let dataValue = item[filterKey];

        switch (filter.type) {
          case "EQUALS":
            response =
              `${filter.value}`.toLowerCase() === `${dataValue}`.toLowerCase();
            break;
          case "INLIST":
            response = filter.value[`${dataValue}`];
            break;
          case "GREATERTHAN":
            response = filter.value === -1 || dataValue > filter.value;
            break;
          case "LESSTHAN":
            response = filter.value === -1 || dataValue < filter.value;
            break;
          case "EQUALTHAN":
            response = filter.value === -1 || filter.value === dataValue;
            break;
          default:
            response = `${dataValue}`
              .toLowerCase()
              .includes(`${filter.value}`.toLowerCase());
            break;
        }

        result = result && response;
      }

      return result;
    });
  }

  const customStyles = {
    headCells: {
      style: {
        textAlign: "center",
        "&:nth-of-type(2n)": {
          backgroundColor: "#eff0f2",
          borderRadius: "12px 12xp 0 0",
        },
      },
    },
    cells: {
      style: {
        "&:nth-of-type(2n)": {
          backgroundColor: "#eff0f2",
        },
      },
    },
  };

  const actualizarViewActive = async (id, estado) => {
    setIsLoading(true);
    let body = {
      id: id,
      active: estado,
    };
    await APILA.updateViewActive(body);
    getView();
    setIsLoading(false);
  };

  const handleAddView = async (name) => {
    setIsLoading(true);
    let id = views.find((ele) => ele.active);
    let viewEnc = views.find(ele => ele.name === name)
    let idVIew = ''
    if (viewEnc) idVIew = viewEnc.id
    else idVIew = v4()
    if (id) actualizarViewActive(id.id, false);
    let body = {
      id: idVIew,
      name: name,
      component: "report",
      columns: columns,
      account: account,
      user: props.currentUser,
      active: true,
    };
    await APILA.insertView(body);
    getView();
    setIsLoading(false);
  };

  const handleShowView = async (vista, show) => {
    let nombres = [];
    let id = views.find((ele) => ele.active);
    if (id) actualizarViewActive(id.id, false);
    actualizarViewActive(vista, show);
    let vistaEnc = views.find((ele) => ele.id === vista);
    vistaEnc.columns.sort((a, b) => {
      return a.position - b.position;
    });
    vistaEnc.columns.forEach((ele) => {
      if (show) {
        nombres.push(ele.id);
        handleShowColumnChange(ele.selector, ele.omit);
      } else {
        handleShowColumnChange(ele.selector, false);
      }
    });
    if (show) {
      handleColumnSortChange(nombres);
    } else {
      handleColumnSortChange(orderDefault);
    }
  };

  const handleDeleteView = async (name) => {
    setIsLoading(true);
    let id = views.find((ele) => ele.active);
    if (id) {
      if (id.id === name) {
        setNameView("lista");
        columns.forEach((ele) => {
          handleShowColumnChange(ele.selector, false);
        });
        handleColumnSortChange(orderDefault);
      }
    }
    await APILA.deleteView({ id: name });
    getView();
    setIsLoading(false);
  };

  const handleViewReport = (value) => {
    let objetoViewGeneral = {
      ...objectParams,
      viewGeneralParams: value
    }
    setParamsUrl(objetoViewGeneral)
    if (value !== "default") {
      setGroupedName("")
      let reportFound = props.reports.filter((el) => el.link === value);
      setReportValue(value);
      setLink(value);
      analytics.seleccionReporte({
        ubicacion: "Reportes",
        nombre: reportFound[0]?.name,
      });
    } else {
      setGroupedName("")
      setReportValue("default");
      setLink("");
    }
  };

  const getView = async (initialValue) => {
    if (Auth.user) {
      setIsLoading(true);
      let sub = Auth.user.signInUserSession.idToken.payload.sub;
      let viewsData = await APILA.getViewsByUser({
        account: props.currentAccount,
        user: sub,
        component: "report",
      });
      if (viewGeneral && viewGeneral !== "list") {
        let findView = viewsData.data.views?.find(({ id }) => id === viewGeneral)
        if (!findView) return history.replace({ pathname: "/campañas" })
      }
      setViews(viewsData.data.views);
      if (viewsData.data.views.length === 0) {
        setNameView("lista");
        let objectView = {
          ...objectParams,
          viewColumnParams: 'default'
        }
        setParamsUrl(objectView)
      } else {
        let vistaActiva = viewsData.data.views.find((ele) => ele.active);
        if (vistaActiva) {
          let objetoId = {
            ...objectParams,
            viewColumnParams: vistaActiva.id
          }
          setParamsUrl(objetoId)
          setNameView(vistaActiva.name);
          if (initialValue) {
            let nombres = [];
            vistaActiva.columns.forEach((ele) => {
              nombres.push(ele.id);
              handleShowColumnChange(ele.selector, ele.omit);
            });
            handleColumnSortChange(nombres);
          }
        } else {
          let objetoInactivo = {
            ...objectParams,
            viewColumnParams: 'default'
          }
          setParamsUrl(objetoInactivo)
          columns.forEach((ele) => {
            handleShowColumnChange(ele.selector, ele.omit);
          });
          handleColumnSortChange(orderDefault);
          setNameView("lista");
        }
      }
      setIsLoading(false);
    }
  };

  const exportDataToExcel = () => {
    let headers = [];
    let dataExcel = [];
    let selectors = [];
    let excelData = [];

    let filteredColumns = columns.filter(function (col) {
      return col.omit === false;
    });

    filteredColumns.forEach((element) => {
      headers.push(element.name);
      selectors.push(element.tag);
    });
    dataExcel.push(headers);
    if (!_.isEmpty(filteredData)) {
      filteredData?.forEach((ele) => {
        excelData = [];
        for (let k = 0; k < selectors.length; k++) {
          excelData.push(
            selectors[k] === "name"
              ? ele.name
              : selectors[k] === "link"
                ? ele.link
                : formatDate(ele.createAt)
          );
        }
        dataExcel.push(excelData);
      });
    }

    return dataExcel;
  };

  const styleIfrem1 = {
    width: "100%",
    height: "65vh",
    border: "none",
    overflowY: "auto",
    overflowX: "auto",
  };

  const styleContIfrem1 = {
    marginTop: "30px",
    width: "100%",
  };

  const handlePageChange = (page) => {
    setCurrentPage(page)
    let nuevosParametros = {
      ...objectParams,
      pageParams: page
    }
    setParamsUrl(nuevosParametros)
  }

  const viewGrouped = (value) => {
    let data = filteredData.filter(ele => ele[groupedName] === value[groupedName])
    data.forEach(ele => {
      ele.permisos = permisos
    })
    if (searchData) {
      data = data.filter(ele => ele)
    } else {
      data = data.filter(ele => ele.active)
    }
    return (
      <div className="Kanbang-content">
        <div className="List-category">
          {value[groupedName]}
          <ReactDataTable
            columns={columns}
            data={data}
            persistTableHead
            customStyles={customStyles}
            pagination
            noHeader
          />
        </div>
      </div>
    );
  }

  const sendView = () => {
    if (reportValue === "" || reportValue === "default") {
      if (isAdmin) {
        let columnsWithoutName = filteredData.map(column => {
          let { permisos, ...columnWithoutTag } = column;
          return columnWithoutTag;
        });
        columnsWithoutName.forEach(ele => ele.permisos = permisos)
        if (searchData) {
          columnsWithoutName = columnsWithoutName.filter(ele => ele)
        } else if (!searchData && columnParams === null) {
          columnsWithoutName = columnsWithoutName.filter(ele => ele.active)
        } else {
          columnsWithoutName = columnsWithoutName.filter(ele => {
            return ele.name.toLowerCase().includes(columnParams.toLowerCase())
          })
        }

        return (
          <div className="Kanbang-content">
            <div className="List-category">
              <ReactDataTable
                columns={columns}
                data={columnsWithoutName}
                persistTableHead
                customStyles={customStyles}
                onChangePage={handlePageChange}
                paginationPerPage={2}
                paginationDefaultPage={currentPage}
                pagination
                noHeader
              />
            </div>
          </div>
        );
      }

    } else {
      return vistaReports()
    }
  };

  const vistaReports = () => (
    <div className="" style={styleContIfrem1}>
      <iframe className="" src={link} style={styleIfrem1} />
    </div>
  )

  const sendViewIp = () => {
    if (reportValue === "" || reportValue === "default") {


      let columnsWithoutName = filteredData.map(column => {
        let { permisos, ...columnWithoutTag } = column;
        return columnWithoutTag;
      });
      columnsWithoutName.forEach(ele => ele.permisos = permisos)
      if (searchData) {
        columnsWithoutName = columnsWithoutName.filter(ele => ele)
      } else {
        columnsWithoutName = columnsWithoutName.filter(ele => ele.active)
      }

      return (
        <div className="table__reports__logout" >
          <div className="Kanbang-content">
            <div className="List-category">
              <ReactDataTable
                columns={columns}
                data={columnsWithoutName}
                persistTableHead
                customStyles={customStyles}
                pagination
                noHeader
              />
            </div>
          </div>
        </div>
      );
    }
  };

  const changeOptions = (value) => {
    console.log("Validando agrupaciones", value)
    let gruoped = value === 'Por defecto' ? 'default' : value
    let objectGrouped = {
      ...objectParams,
      agrupationParams: gruoped
    }
    setParamsUrl(objectGrouped)
    analytics.agrupar({
      ubicacion: "Reportes",
    });
    switch (value) {
      case "Nombres":
        setGroupedName("name");
        break;
      default:
        setGroupedName("");
        break;
    }
  };

  let nombres = [];
  if (props?.isIpv4) {
    return (
      <>
        {
          filteredData?.length === 0 || filteredData.filter(item => item.active).length === 0
            ?
            <>
              <div className="form homeSinLogin">
                <div>
                  <p className="subTitle">Bienvenido a Let's Advertise</p>
                  <h1 className="title">Crea tu primer reporte</h1>
                  <a
                    href="#"
                    className="btn-plus"
                    onClick={() => {
                      const mCurrentAccount =
                        props.currentUser !== ""
                          ? props.currentAccount
                          : props.currentPWAAccount;

                      if (mCurrentAccount === "") {
                        Swal.fire({
                          title: "Cuenta Requerida",
                          text: "Para utilizar esta sección, debes crear una cuenta.",
                          icon: "warning",
                        }).then((response) => {
                          if (!response.isConfirmed) return
                          if (props?.accounts.length > 0) return
                          props._nexStep(1)
                        })
                        return;
                      }
                      analytics.Add({
                        ubicacion: "Categories",
                      });

                      window.$.fancybox.open({
                        src: "#upsert-report-modal",
                        data: "new-report",
                        //type: 'modal',
                        opts: {
                          modal: true,
                          afterClose: () => { },
                        },
                      });
                    }}
                  >
                    <i className="fas fa-plus" />
                  </a>
                </div>
                <div></div>
              </div>
            </>
            :
            <>
              <div className="barraSuperior barraSuperiorReport">
                <select
                  defaultValue=""
                  onChange={handleViewReport}
                  value={reportValue}
                  className="select-workspace"
                >
                  <option value={"default"}>Vista de lista</option>
                  {props.reports.map((report, idx) => (
                    <option key={idx} value={report.link}>
                      {report.name}
                    </option>
                  ))}
                </select>
                <DataTableUtils
                  data={reports}
                  action={
                    () => {
                      if (props.currentAccount === "") {
                        Swal.fire({
                          title: "Cuenta requerida.",
                          text: "Para utilizar esta sección, debes crear una cuenta.",
                          icon: "warning",
                        }).then((response) => {
                          if (!response.isConfirmed) return
                          if (props?.accounts.length > 0) return
                          props._nexStep(1)
                        })
                        return;
                      }

                      window.$.fancybox.open({
                        src: "#upsert-report-modal",
                        data: "new-report",
                        //type: 'modal',
                        opts: {
                          modal: true,
                          afterClose: () => { },
                        },
                      });
                    }
                  }
                  actionIcon={<i className="fas fa-plus" />}
                  onChangeFilterText={handleFilterTextChange}
                >
                  <div
                    onClick={() => {
                      analytics.Compartir({
                        ubicacion: "Reportes",
                      });
                    }}
                    className="ExportBtn"
                  >
                    <Share
                      data={exportDataToExcel()}
                      columns={columns}
                      isArray={true}
                      exportXLSX={true}
                      exportTXT={true}
                      exportDropbox={true}
                      exportDrive={true}
                      exportCSV={true}
                      printable={true}
                      exportPNG={true}
                      exportLink={true}
                      idImg={'root'}
                      appKey='2u78gno172idwbz'
                    />
                  </div>
                  <div id="shareBtn">
                    <ShowColumn2
                      columns={columns}
                      showColumn={(data, data1) => {
                        analytics.ShowHide({
                          ubicacion: "Reportes",
                        });
                        handleShowColumnChange(data, data1);
                      }}
                      showView={(vista, show) => {
                        handleShowView(vista, show)
                      }}
                      onSortChange={(positions) => {
                        analytics.Columnas({
                          ubicacion: "Reportes",
                        });
                        handleColumnSortChange(positions);
                      }}
                      vistas={views}
                      setVista={(nombre) => {
                        analytics.accionesVista({
                          ubicacion: 'Reportes',
                          accion: 'Agregar'
                        })
                        handleAddView(nombre)
                      }}
                      deleteView={(name) => {
                        analytics.accionesVista({
                          ubicacion: "Reportes",
                          accion: 'ELiminar'
                        })
                        handleDeleteView(name)
                      }}
                      nameAc={nameView}
                    />
                  </div>
                  <div className="Filtro2 filtro3">
                    <FilterColumn2
                      columns={columns}
                      data={[]}
                      onFilterColumn={handleFilterPerColumn}
                      filterInfoCheck={filterInfo}
                      options={options}
                      changeGrouped={changeOptions}
                    />
                  </div>
                </DataTableUtils>
              </div>
              {groupedName !== "" ? (
                filteredData.map((ele) => {
                  if (!nombres.includes(ele[groupedName])) {
                    nombres.push(ele[groupedName])
                    return viewGrouped(ele)
                  }
                }))
                :
                sendViewIp()
              }
            </>
        }
        {props.step == 1 && <CreateAccountLogOut clase={"component"} />}
      </>
    )
  }
  return (
    <>
      {isLoading ? (
        <PageLoader />
      ) : permisos.view ? (
        <>
          <div className="barraSuperior barraSuperiorReport">
            <select
              // defaultValue=""
              onChange={(e) => handleViewReport(e.target.value)}
              value={reportValue}
              className="select-workspace"
            >
              {isAdmin ?
                <option value={"default"}>Vista de lista</option>
                :
                <option value={"default"}>Elige un reporte</option>
              }
              {props.reports.map((report, idx) => (
                <option key={idx} value={report.link}>
                  {report.name}
                </option>
              ))}
            </select>
            <DataTableUtils
              data={reports}
              action={
                permisos.create ?
                  () => {
                    if (props.currentAccount === "") {
                      props._showSelectAccountModal();
                      return;
                    }
                    window.$.fancybox.open({
                      src: "#upsert-report-modal",
                      data: "new-report",
                      //type: 'modal',
                      opts: {
                        modal: true,
                        afterClose: () => { },
                      },
                    });
                  } :
                  null
              }
              actionIcon={permisos.create && <i className="fas fa-plus" />}
              onChangeFilterText={handleFilterTextChange}
              valueFilter={filterText}
            >
              <div
                onClick={() => {
                  analytics.Compartir({
                    ubicacion: "Reportes",
                  });
                }}
                className="ExportBtn"
              >
                {permisos.export && isAdmin && (
                  <Share
                    // data={columns}
                    // contacts={[]}
                    // onShareContact={handleShareContact}
                    data={exportDataToExcel()}
                    columns={columns}
                    isArray={true}
                    exportXLSX={true}
                    exportTXT={true}
                    exportDropbox={true}
                    exportDrive={true}
                    exportCSV={true}
                    printable={true}
                    exportPNG={true}
                    exportLink={true}
                    idImg={'root'}
                    appKey='2u78gno172idwbz'
                  />
                )}
              </div>


              <div id="shareBtn">
                {isAdmin &&
                  <ShowColumn2
                    columns={columns}
                    showColumn={(data, data1) => {
                      analytics.ShowHide({
                        ubicacion: "Reportes",
                      });
                      handleShowColumnChange(data, data1);
                    }}
                    showView={(vista, show) => {
                      handleShowView(vista, show)
                    }}
                    onSortChange={(positions) => {
                      analytics.Columnas({
                        ubicacion: "Reportes",
                      });
                      handleColumnSortChange(positions);
                    }}
                    vistas={views}
                    setVista={(nombre) => {
                      analytics.accionesVista({
                        ubicacion: 'Reportes',
                        accion: 'Agregar'
                      })
                      handleAddView(nombre)
                    }}
                    deleteView={(name) => {
                      analytics.accionesVista({
                        ubicacion: "Reportes",
                        accion: 'ELiminar'
                      })
                      handleDeleteView(name)
                    }}
                    nameAc={nameView}
                  />}
              </div>

              {/* <FilterColumn
                  columns={columns}
                  onFilterColumn={handleFilterPerColumn}
                /> */}
              <div className="Filtro2 filtro3">
                {isAdmin &&
                  <FilterColumn2
                    columns={columns}
                    data={[]}
                    onFilterColumn={handleFilterPerColumn}
                    filterInfoCheck={filterInfo}
                    options={options}
                    changeGrouped={changeOptions}
                    groupedDefault={groupedName === "name" ? "Nombres" : "Por defecto"}
                  />
                }
              </div>
            </DataTableUtils>
          </div>
          {/* {groupedName !== "" ?
              reportValue === "default"  || reportValue === "" ?
              filteredData.map((ele) => {
                if (!nombres.includes(ele[groupedName])) {
                  nombres.push(ele[groupedName])
                  return viewGrouped(ele)
                }
              })
              : 
              vistaReports()
              :
              sendView()
            } */}
          {groupedName !== "" && isAdmin ?
            filteredData.map((ele) => {
              if (!nombres.includes(ele[groupedName])) {
                nombres.push(ele[groupedName])
                return viewGrouped(ele)
              }
            })
            :
            sendView()
          }
        </>
      ) : (
        <h1
          style={{
            marginTop: "8rem",
            marginLeft: "3rem",
            position: "absolute",
          }}
        >
          No tienes permisos
        </h1>
      )}
    </>
  );

};

const mapStateToProps = (state) => ({
  reports: state.report.list,
  isIpv4: state.app.isIPV4,
  accounts: state.accounts.list,
  currentUser: state.app.current_user,
  currentAccount: state.accounts.default,
  permisos: state.permisos.current_permissions,
  step: state.userLogout.nextStepCategory,
});

const mapDispatchToProps = (dispatch) => ({
  _nexStep: (step) => dispatch(nextStep(step)),
  _clearEdit: () => dispatch(clearEdit()),
  _setEdit: (edit) => dispatch(setEdit(edit)),
  _clearReports: () => dispatch(clearReports()),
  _showLoader: (show) => dispatch(showLoader(show)),
  _upsertReport: (id, name, link, account, edit) =>
    dispatch(upsertReport(id, name, link, account, edit)),
  _deleteReport: (id) => dispatch(deleteReport(id)),
  _getReports: (account) => dispatch(getReports(account)),
  _showSelectAccountModal: () =>
    dispatch(
      Modal.showAlertModal(
        "Cuenta Requerida",
        "Por favor selecciona una cuenta antes",
        Modal.MODAL_TYPE_WARNING,
        () => { }
      )
    ),
  _showConfirmationModal: (title, message, callback) =>
    dispatch(
      Modal.showAlertModal(
        title,
        message,
        Modal.MODAL_TYPE_CONFIRMATION,
        callback
      )
    ),
});

export default connect(mapStateToProps, mapDispatchToProps)(withUtils(Content));
