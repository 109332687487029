import * as API from 'helpers/api'
import * as Logger from 'utils/logger'
import * as Modal from './modal.actions'
import * as Storage from 'helpers/storage'
import { showLoader } from './loader.actions'

export const CREATIVE_CLEAR = 'CREATIVE:CLEAR'
export const CREATIVE_ADD_FILE = 'CREATIVE:ADD:FILE'
export const CREATIVE_REMOVE_FILE = 'CREATIVE:REMOVE:FILE'
export const CREATIVE_SET_FILTER_TEXT = 'CREATIVE:SET:FILTER:TEXT'

const TAG = 'Creative:Actions'

export const setFilterText = text => dispatch => {
  dispatch({
    type: CREATIVE_SET_FILTER_TEXT,
    text
  })
}

export const getFiles = (account) => async dispatch => {  
  dispatch(showLoader(true))
  dispatch(clearFiles())
  console.log("entro a getFiles" , account)

  //API.getMyFiles(account)
    // .then((response) => {
    //   Logger.log(TAG, 'getMyFiles22', response)
    //   let { files } = response.data
      
      //files.forEach(async (file) => {
        const fileResponse = await Storage.listFiles('')//(file.id)
        Logger.log(TAG, 'getFilesCreativite.actions', fileResponse)
        fileResponse.forEach(async (item) => {
          let file = {
            id: item.eTag,
            name: item.key,
            size: item.size,
            url: await getFileUrl(item.key),
            type: await getFileType(item.key),
            urlPublic: await getFileUrlPublic(item.key),
            lastModified: item.lastModified,
          }
          //console.log(TAG, 'fileDentroGetFiles', file)
          dispatch(addFile(file)) 
        })
      //})
    //})
    // .catch((err) => {
    //   Logger.log(TAG, 'error getMyFiles', err)
    // })
    // .then(() => {
    //   dispatch(showLoader(false))
    // })
}

export const uploadFiles = (files, account) => async dispatch => {
  // Logger.log(TAG, 'uploading files')
  // Logger.log(TAG, files)
  dispatch(showLoader(true))
  
  for (const file of files) {
    let options = {
      contentType: file.type,
      progressCallback(progress) {
        // Logger.log(TAG, `uploaded: ${progress.loaded}/${progress.total}`)
      }
    }

    
    
    try {
      const uploadedFile = await Storage.uploadFile(file.name, file, options)

    Logger.log(TAG, 'uploadedFile', uploadedFile)
      const fileup=await API.associateFile({
        Key:uploadedFile.key, account
      })
      console.log("fileup ", fileup);
    } catch (err) {
      Logger.log(TAG, 'error uploading file', err)
    }
  }
   dispatch(getFiles(account))
   setTimeout(() => {
    dispatch(Modal.showAlertModal('Archivos Subidos', 'Los archivos fueron subidos exitosamente.', Modal.MODAL_TYPE_SUCCESS, () => {
      //dispatch(getFiles(account))
    }))
  }, 3500);
  
    
  dispatch(showLoader(false))
}

export const downloadFile = name => dispatch => {
  dispatch(showLoader(true))

  Storage.downloadFile(name)
    .then(response => {
      Logger.log(TAG, 'downloadFile', response)
    })
    .catch(err => {
      Logger.log(TAG, 'error downloadFile', err)
    })
    .then(() => {
      dispatch(showLoader(false))
    })
}

export const removeFile = (file, account) => dispatch => {
  Logger.log(TAG, 'removeFile')
  Logger.log(TAG, 'file', file)
  dispatch(showLoader(true))

  API.disassociateFile(file, account)
    .then((response) => {
      Logger.log(TAG, 'disassociateFile', response)
      dispatch(Modal.showAlertModal('Archivo Eliminado', 'El archivo fue eliminado exitosamente.', Modal.MODAL_TYPE_SUCCESS, () => {
        dispatch(deleteFile(file))
        dispatch(getFiles(account))
      }))
    })
    .catch((err) => {
      Logger.log(TAG, 'error disassociateFile', err)
    })
    .then(() => {
      dispatch(showLoader(false))
    })
}

export const clearFiles = () => dispatch => {
  dispatch({
    type: CREATIVE_CLEAR
  })
}

const addFile = file => {
  return dispatch => {
    dispatch({
      type: CREATIVE_ADD_FILE,
      file
    })
  }
}

const deleteFile = file => {
  return dispatch => {
    dispatch({
      type: CREATIVE_REMOVE_FILE,
      file
    })
  }
}

const getFileUrl = async file => await Storage.downloadFile(file)

const getFileType = async file => {
  let info = await Storage.downloadFile(file, { download: true })
  // console.log(TAG, 'info', info)
  return info.ContentType
}

const getFileUrlPublic = async file => await Storage.getUrlPublic(file)
