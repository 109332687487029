import React from "react";
import ReactDOM from "react-dom";
import Amplify from "aws-amplify";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import Livechat from "chatwoot";
import { persistor, store } from "redux/configureStore";

//pwa
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";

import App from "app";
import awsconfig from "aws-exports";
import * as serviceWorker from "utils/serviceWorker";
import TagManager from "react-gtm-module";
const ReactPixel = import(
  /* webpackChunkName: "ReactPixel", webpackPrefetch: true */ "react-facebook-pixel"
);
const isLocalhost = Boolean(
  window.location.hostname === "localhost" ||
    window.location.hostname === "[::1]" ||
    window.location.hostname.match(
      /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
    )
);
const tagManagerArgs = {
  gtmId: "GTM-PLL4MV6",
};
const [localRedirectSignIn, productionRedirectSignInLetsAdvertise] = [
  "http://localhost:3000/",
  "https://lets-advertise.com/",
];

const [localRedirectSignOut, productionRedirectSignOutLetsAdvertise] = [
  "http://localhost:3000/",
  "https://lets-advertise.com/",
];

TagManager.initialize(tagManagerArgs);

ReactPixel.then((rp) =>
  rp.default.init(
    450979496484873,
    {},
    {
      autoConfig: true,
      debug: false === "true",
    }
  )
);

const updatedAwsConfig = {
  ...awsconfig,
  aws_cloud_logic_custom: [
    ...awsconfig.aws_cloud_logic_custom,
    // TODO: descomentar para dev
    {
      name: "MarketingSuite",
      endpoint: "https://hvpr5diseh.execute-api.us-east-1.amazonaws.com/dev",
      region: "us-east-1",
    },

    // TODO: descomentar para produccion
    // {
    //   name: "MarketingSuite",
    //   endpoint:
    //     "https://8v40ttqct9.execute-api.us-east-1.amazonaws.com/production",
    //   region: "us-east-1",
    // },
  ],
  // TODO: descomentar para dev
  // aws_user_files_s3_bucket: "marketing-suite-files-bucket150012-dev",
  // aws_user_files_s3_bucket_region: "us-east-1",

  // TODO: descomentar para produccion
  aws_user_files_s3_bucket: "marketing-suite-files-bucket90024-production",
  aws_user_files_s3_bucket_region: "us-east-1",

  aws_cognito_identity_pool_id:
    "us-east-1:5c496058-e4ec-4d0e-a6fe-0c73dcfe73db",
  aws_cognito_region: "us-east-1",
  aws_user_pools_id: "us-east-1_fJNIVIyPi",
  aws_user_pools_web_client_id: "3geainljo7slpe5vlqolrgtevr",

  oauth: {
    //* / TODO: descomentar para dev
    // domain:
    // "marketingsuite378cef6d-378cef6d-dev.auth.us-east-1.amazoncognito.com",

    // TODO: descomentar para produccion
    domain:
      "marketingsuite378cef6d-378cef6d-production.auth.us-east-1.amazoncognito.com",
    scope: [
      "phone",
      "email",
      "openid",
      "profile",
      "aws.cognito.signin.user.admin",
    ],
    responseType: "code",
    redirectSignIn: isLocalhost
      ? localRedirectSignIn
      : productionRedirectSignInLetsAdvertise,
    redirectSignOut: isLocalhost
      ? localRedirectSignOut
      : productionRedirectSignOutLetsAdvertise,
  },
};

// console.log('updatedAwsConfig', updatedAwsConfig)

Amplify.configure(updatedAwsConfig);

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <BrowserRouter>
        <App awsConfig={updatedAwsConfig} />
        <Livechat />
      </BrowserRouter>
    </PersistGate>
  </Provider>,
  document.getElementById("root")
);

// if (process.env.NODE_ENV !== "development") {
//   serviceWorker.register()
// }
serviceWorker.register();

serviceWorkerRegistration.register({
  onUpdate: async (registration) => {
    // Corremos este código si hay una nueva versión de nuestra app
    // Detalles en: https://developers.google.com/web/fundamentals/primers/service-workers/lifecycle
    if (registration && registration.waiting) {
      await registration.unregister();
      registration.waiting.postMessage({ type: "SKIP_WAITING" });
      // Des-registramos el SW para recargar la página y obtener la nueva versión. Lo cual permite que el navegador descargue lo nuevo y que invalida la cache que tenía previamente.
      window.location.reload();
    }
  },
});
