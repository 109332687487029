import { APP_CLEAR_CURRENT_USER } from '../actions/app.actions'
import * as Actions from '../actions/logs.actions'

const initialState = {
  total: 0,  
  logs: []
}

export default (state = initialState, action) => {
  switch (action.type) {
    case Actions.CLEAN_LOGS:
      return {
        ...state,
        logs: [],
        total: 0,
      }
    case Actions.ADD_LOGS:
       return {...state, logs:action.log}
    case Actions.TOTAL_LOGS:
    return {
      ...state,
      total: action.total,
    }
    case APP_CLEAR_CURRENT_USER:
      return initialState
    default:
      return state
  }
}