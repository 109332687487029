import Storage from "@aws-amplify/storage";
import { stubFalse } from "lodash-es";
import * as APILA from "helpers/apiLA";
import * as Logger from "utils/logger";

const TAG = "StorageHelper";

export const uploadFile = async (name, content, type) => {
  const response = await APILA.upsertFiles({
    name: name,
    file: content,
    type: type,
  });
  return response.data;
};

export const getUrlPublic = (name, options) => {
  // Logger.log(TAG, 'downloading file')
  // Logger.log(TAG, 'with name public', name)
  // Logger.log(TAG, 'with options public', options)

  return Storage.get(name, { level: "public", download: false });
};

export const downloadFile = (name, options) => {
  // Logger.log(TAG, 'downloading file')
  // Logger.log(TAG, 'with name', name)
  // Logger.log(TAG, 'with options', options)

  return Storage.get(name, { level: "public", download: false });
};

export const listFiles = async (path) => {
  const imgageResponse = await APILA.getUrlFile({ key: path });
  return imgageResponse.data.url;
};

export const removeFile = (name, customOptions) => {
  // Logger.log(TAG, 'removing file')

  let options = {};

  if (customOptions) {
    options = {
      ...options,
      customOptions,
    };
  }

  // Logger.log(TAG, 'with name', name)
  // Logger.log(TAG, 'with options', options)

  return Storage.remove(name, options);
};
export const getUrlImage = (key) => {
  try {
    const options = {
      level: "public",
    };
    return Storage.get(key);
  } catch (error) {
    console.log("error en imagen---", error);
  }
};
