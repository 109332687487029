import React from 'react'
import { includes } from 'lodash'
import { connect } from 'react-redux'
import ReactKanban from '@easygosanet/react-kanban'

import status from 'config/status'
import { updateCampaingStatus } from 'redux/actions/dashboard.actions'
import { withUtils } from 'utils'

const TAG = 'Kanban'

const Kanban = ({ campaigns, utils, _updateCampaingStatus }) => {
  const getCards = (status) => {
    const mCampaigns = campaigns.filter((campaign) =>
      includes(status, campaign.status)
    )

    return mCampaigns.map((campaign) => {
      return {
        id: campaign.id,
        title: campaign.name,
        label: campaign.medioo,
        description: `${campaign.fechaIni} al ${campaign.fechafin}`,
      }
    })
  }

  const data = {
    lanes: [
      {
        id: status.ELIMINADO.id,
        title: status.ELIMINADO.label,
        label: '',
        style: {
          width: 280,
          color: 'white',
          backgroundColor: status.ELIMINADO.color,
        },
        cards: getCards([status.ELIMINADO.id, status.NO_IMPLEMENTADO.id]),
      },
      {
        id: status.PAUSADO.id,
        title: status.PAUSADO.label,
        label: '',
        style: {
          width: 280,
          color: 'white',
          backgroundColor: status.PAUSADO.color,
        },
        cards: getCards([status.PAUSADO.id]),
      },
      {
        id: status.CREADO.id,
        title: status.CREADO.label,
        label: '',
        style: {
          width: 280,
          color: 'white',
          backgroundColor: status.CREADO.color,
        },
        cards: getCards([status.CREADO.id]),
      },
      {
        id: status.EN_REVISION.id,
        title: status.EN_REVISION.label,
        label: '',
        style: {
          width: 280,
          color: 'white',
          backgroundColor: status.EN_REVISION.color,
        },
        cards: getCards([status.EN_REVISION.id, status.PENDIENTE.id, status.RECHAZADO.id]),
      },
      {
        id: status.MIX_APROBADO.id,
        title: status.MIX_APROBADO.label,
        label: '',
        style: {
          width: 280,
          color: 'white',
          backgroundColor: status.MIX_APROBADO.color,
        },
        cards: getCards([status.MIX_APROBADO.id]),
      },
      {
        id: status.ACTIVO.id,
        title: status.ACTIVO.label,
        style: {
          width: 280,
          color: 'white',
          backgroundColor: status.ACTIVO.color,
        },
        label: '',
        cards: getCards([status.ACTIVO.id]),
      },
      {
        id: status.EN_IMPLEMENTACION.id,
        title: status.EN_IMPLEMENTACION.label,
        style: {
          width: 280,
          color: 'white',
          backgroundColor: status.EN_IMPLEMENTACION.color,
        },
        label: '',
        cards: getCards([status.EN_IMPLEMENTACION.id]),
      },
      {
        id: status.FINALIZADO.id,
        title: status.FINALIZADO.label,
        style: {
          width: 280,
          color: 'white',
          backgroundColor: status.FINALIZADO.color,
        },
        label: '',
        cards: getCards([status.FINALIZADO.id, status.FINALIZADO_SIN_META.id, status.FINALIZADO_SIN_REPORTE.id]),
      },
    ],
  }

  const handleDragEnd = (cardId, sourceLaneId, targetLaneId) => {
    utils.log(TAG, 'handleDragEnd')
    utils.log(TAG, 'cardId', cardId)
    utils.log(TAG, 'sourceLaneId', sourceLaneId)
    utils.log(TAG, 'targetLaneId', targetLaneId)

    if (sourceLaneId !== targetLaneId) {
      _updateCampaingStatus(cardId, targetLaneId, sourceLaneId)
    }
  }

  return (
    <div id='Kanbang-content' className='kanban-container'>
      <ReactKanban data={data} handleDragEnd={handleDragEnd} />
    </div>
  )
}

const mapDispatchToProps = (dispatch) => ({
  _updateCampaingStatus: (campaingId, status, prevStatus) => dispatch(updateCampaingStatus(campaingId, status, prevStatus)),
})

export default connect(undefined, mapDispatchToProps)(withUtils(Kanban))