import React, { useEffect, useState, useRef } from "react";
import _ from "lodash";
import classnames from "classnames";
import status from "config/status";
import { connect } from "react-redux";
import { showLoader } from "redux/actions/loader.actions";
import DataTableUtils, {
  ShowColumn2,
  FilterColumn2,
} from "@easygosanet/react-datatable-utils";
import google from "./assets/img/google.png";
import youtube from "./assets/img/youtube.png";
import facebook from "./assets/img/facebook.png";
import linkedin from "./assets/img/linkedin.png";
import instagram from "./assets/img/instagram.png";
import CurrencyInput from "react-currency-input-field";
import analytics from "../../helpers/analytics";
import DetailColEdit from "components/DetailCol";
import ReactDataTable, { OptionNewEdit } from "@easygosanet/react-datatable";
import { columnsDashboard as columnsExa } from "config/columns"; // se puede utilizar la varibale de las columnas
//imagenes
import "./assets/css/styles.scss";
import $ from "jquery";
import Swal from "sweetalert2";
import styles from "./assets/css/select.module.css";
//componentes
import Table from "./table";
import Kanban from "./kanban";
import Calendar from "./calendar";
import ModalDetaill from "components/ModalDetaill/ModalDetaill";
import ModalAudience from "components/audiences/modal/upsert_audience";
import ModalImage from "components/ModalImage/ModalImage";
import ModalRegister from "components/ModalRegister/ModalRegister";
import ModalCard from "components/ModalCard/ModalCard";

import * as Modal from "redux/actions/modal.actions";
import {
  funcampaignupd,
  clearCampaignupd,
  updateCampaign,
  msgsuccess,
} from "redux/actions/dashboard.actions";
import { cleanSteps } from "redux/actions/nameCamp.actions";
import { updateAccountMembers } from "redux/actions/accounts.actions";
import { getUsersRole } from "redux/actions/users.actions";
import { withUtils } from "utils";
import { addIdMixDetaill } from "redux/actions/modalDetail.actions";
import { getUrlImages } from "redux/actions/modalImage.actions";
import * as API from "helpers/apiLA";
import * as APILA from "helpers/api";
import { Auth } from "aws-amplify";
import PageLoader from "components/page_loader";
import ModalPauta from "components/modalPautaPwa/ModalPauta";
// import DetailColEdit from "./detailColEdit";
import { Tooltip } from "antd";
import { DateRangeFilter } from "libs/dateRange";
import { nextStep } from "redux/actions/userLogout.action";
import CreateAccountLogOut from "components/flowLogOutUser/createAccount";
import { IdComponent } from "components/budgetOld/pages/idComponent";
import Campana from "components/flowLogOutUser/createCampaign";
import CampanaWithAccount from "components/campana";
import { nextStepLogoutUser } from "redux/actions/nameCamp.actions";

const idmod = 2;
const mod = "DASHBOARD";

localStorage.setItem(
  "state",
  JSON.stringify({
    place: "dias",
    data: [],
  }),
);

const Bar = (props) => {
  const [account, setAccount] = useState(props.currentAccount);
  const [cargarView, setCargarView] = useState(false);
  const [colsSorted, setcolsSorted] = useState([]);
  const [colsBack, setColsBack] = useState([]);
  const [medioCh, setMedioCh] = useState([]);
  const { sendCampaignss, categories } = props;
  const [columns, setColumns] = useState([]);
  const [columns1, setColumns1] = useState([]);
  const [filtered, setFiltered] = useState([]);
  const [groupedBy, setGroupedBy] = useState("ninguno");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalRow, setTotalRow] = useState();
  const [sendCampaigns, setSendCampaigns] = useState([]);
  const [search, setSearch] = useState(true);
  const [searchFilterData, setSearchFilterData] = useState([]);
  const [searchDataAlt, setSearchDataAlt] = useState([]);
  const [categoriesSorted, setCategoriesSorted] = useState([]);
  const [actualState, setActualState] = useState({});
  const [filterBool, setFilterBool] = useState(false);
  const [campEdit, setCampEdit] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showModal2, setShowModal2] = useState(false);
  const [audiences, setAudiences] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [isCustomDate, setIsCustomDate] = useState(false);
  const [customDates, setCustomDates] = useState([]);
  const [load, setLoad] = useState(false);
  const [arraynIds, setArraynIds] = useState("");
  const [nameView, setNameView] = useState("");
  const [views, setViews] = useState([]);
  const [viewCount, setviewCount] = useState(false);
  const [namesCate, setNamesCate] = useState([]);
  const [permissionsBar, setPermissionsBar] = useState(props.permissions);
  const [showCalendar, setShowCalendar] = useState(false);
  const [stateKpi, setStateKpi] = useState({
    id: "",
    goal: 0,
  });
  //localStorage.setItem("Dash")
  const [datesToShow, setDatesToShow] = useState({
    fechaIni: "",
    fechaFin: "",
  });

  const modalRefPwa = useRef();
  var dataEnviada = {
    account: account,
    user: props.currentAccount,
    idmod: idmod,
    mod: mod,
  };
  const [customStyles, setCustomStyles] = useState({
    headCells: {
      style: {
        "&:nth-of-type(1)": {
          position: "sticky",
          zIndex: "3 !important",
          left: "0",
          backgroundColor: "#fff",
          textAlign: "center",
        },
      },
    },
    cells: {
      style: {
        textAlign: "center",
        "&:nth-of-type(1)": {
          position: "sticky",
          zIndex: "3 !important",
          left: "0",
          backgroundColor: "#fff",
        },
      },
    },
  });
  const [filterInfo, setFilterInfo] = useState([]);

  let catAux = [];
  let groupedData = [];
  const getCampRefresh = () => {
    setRefresh(true);
  };
  const getDataOptions = async () => { };

  useEffect(() => {
    if (props.currentAccount !== "") setAccount(props.currentAccount);
    localStorage.setItem("campaignAdd", null);
  }, [props.currentAccount]);

  const audienceComponent = (idAudience) => {
    let audience = props.audiences?.filter((el) => el.id === idAudience);
    let audienceToShow = "";
    if (audience.length > 0) {
      audienceToShow = audience[0].name;
    }
    return <>{audienceToShow}</>;
  };

  const intermediariaEdit = (row) => {
    console.log("Validando los valores de la edicion", { row });
  };

  const audienceComponent1 = (idAudience) => {
    let audience = props.audiences?.filter((el) => el.id === idAudience);
    let audienceToShow = "";
    if (audience.length > 0) {
      audienceToShow = audience[0].name;
    }
    return audienceToShow;
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        modalRefPwa.current &&
        !modalRefPwa.current.contains(event.target) &&
        !event.target.closest(".boxCalendar")
      ) {
        setShowCalendar(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [modalRefPwa]);

  const columnsMappin = {
    name: {
      cell: (row) => (
        <Tooltip title={row.name} color="#002448" placement="top">
          <DetailColEdit
            text={row.name}
            activate={true}
            id={"name"}
            deleteData={() => showDeleteConfirmation(row)}
            deleteV={true}
            nameColumn={row}
            check={
              row.status === status.PAUSADO.id ||
                row.status === status.CREADO.id
                ? true
                : false
            }
            editInfo={() => intermediaria(row)}
            edit={true}
            onEditValue={editData}
            approveConfirmation={() => showApproveConfirmation(row)}
            // editable={true}
            type={"text"}
            permissions={{
              edit: true,
              delete: true,
              approve: true,
            }}
          />
        </Tooltip>
      ),
    },
    id: {
      cell: (row) => {
        return <IdComponent text={row.id} />;
      },
    },
  };

  let newColumnsExa = columnsExa.map((col) => {
    const mappignElement = columnsMappin[col.id];
    if (mappignElement) {
      return { ...col, ...mappignElement };
    }
    return col;
  });

  // let columnsExa =
  //   [
  //     {
  //       id: "ninguno",
  //       name: "Ninguno",
  //       selector: "ninguno",
  //       tag: "ninguno",
  //       group: true,
  //       visible: false,
  //       omit: true,
  //     },
  //     {
  //       id: "name",
  //       name: (
  //         <Tooltip title="Nombre de la campaña." color="#002448" placement="top">
  //           Nombre
  //         </Tooltip>
  //       ),
  //       selector: "name",
  //       sortable: false,
  //       visible: false,
  //       tag: "name",
  //       type: "text",
  //       center: true,
  //       minWidth: "250px",
  //       omit: false,
  //       style: {
  //         position: "sticky",
  //         left: 0,
  //         zIndex: "3 !important",
  //         textAlign: "center",
  //       },
  //       cell: (row) => (
  //         <DetailColEdit
  //           row={row}
  //           accounts={[]}
  //           budgetOld={false}
  //           viewBudget={false}
  //           id={row.id}
  //           nameColumn={"name"}
  //           onEditValue={editData}
  //           showApproveConfirmation={showApproveConfirmation}
  //           editable={true}
  //           type={"text"}
  //         />
  //       ),
  //     },
  //     {
  //       id: "year",
  //       name: (
  //         <Tooltip
  //           title="Período en el que tu campaña está activa."
  //           color="#002448"
  //           placement="top"
  //         >
  //           Rango de Fecha
  //         </Tooltip>
  //       ),
  //       selector: "year",
  //       sortable: true,
  //       tag: "fechaIni",
  //       minWidth: "250px",
  //       center: true,
  //       omit: false,
  //       cell: (row) => (
  //         <DateRangeEdit
  //           start={new Date(getData(row.fechaIni))}
  //           end={new Date(getData(row.fechafin))}
  //           format="dd/mm/yyyy"
  //           justifyContent={"center"}
  //           center={"true"}
  //           onEditValue={(e) => {
  //             e[0].end = getFecha(e[0].end);
  //             e[0].start = getFecha(e[0].start);
  //             editData(e);
  //           }}
  //           id={row.id}
  //           nameColumn={"year"}
  //           type={"text"}
  //           barClass=""
  //           className={"budgetRange"}
  //         />
  //       ),
  //     },
  //     {
  //       id: "id",
  //       name: (
  //         <Tooltip
  //           title="ID único de la campaña."
  //           color="#002448"
  //           placement="top"
  //         >
  //           ID
  //         </Tooltip>
  //       ),
  //       tag: "id",
  //       selector: "id",
  //       type: "text",
  //       show: true,
  //       sortable: true,
  //       value: 1,
  //       center: true,
  //       minWidth: "180px",
  //       omit: false,
  //       cell: (row) => row.id,
  //     },
  //     {
  //       id: "id_sale",
  //       name: (
  //         <Tooltip
  //           title="Orden de venta de donde sale el presupuesto de la campaña."
  //           color="#002448"
  //           placement="top"
  //         >
  //           Orden de Venta
  //         </Tooltip>
  //       ),
  //       selector: "id_sale",
  //       tag: "id_sale",
  //       minWidth: "190px",
  //       sortable: true,
  //       center: true,
  //       omit: false,
  //       show: true,
  //       type: "text",
  //       cell: (row) => (
  //         <NumberFormatEdit
  //           prefix={""}
  //           value={row.id_sale ? row.id_sale : "0"}
  //           thousandSeparator={false}
  //           editable={true}
  //           onEditValue={editData}
  //           id={row.id}
  //           displayType={"text"}
  //           tipo={"text"}
  //           nameColumn={"id_sale"}
  //         />
  //       ),
  //     },
  //     {
  //       id: "status",
  //       name: (
  //         <Tooltip title="Estado de la campaña." color="#002448" placement="top">
  //           Estado
  //         </Tooltip>
  //       ),
  //       selector: "status",
  //       tag: "searchable_status",
  //       group: true,
  //       sortable: true,
  //       minWidth: "190px",
  //       center: true,
  //       omit: false,
  //       cell: (row) => (
  //         <Status
  //           value={row.status}
  //           label={getStatusLabel(row.status)}
  //           className={getStatusClassName(row.status)}
  //           justifyContent={"center"}
  //           center={"true"}
  //         />
  //       ),
  //     },
  //     {
  //       id: "country",
  //       name: (
  //         <Tooltip title="País de segmentación." color="#002448" placement="top">
  //           País
  //         </Tooltip>
  //       ),
  //       selector: "country",
  //       tag: "country",
  //       minWidth: "150px",
  //       group: true,
  //       sortable: true,
  //       type: "text",
  //       center: true,
  //       omit: false,
  //       cell: (row) => (
  //         <SimpleText
  //           value={row.name_region}
  //           id={row.id}
  //           type={"select"}
  //           editable={true}
  //           dataSelect={countries}
  //           nameColumn="name_region"
  //           onEditValue={handleChangeCountry}
  //         />
  //       ),
  //     },
  //     {
  //       id: "medio",
  //       name: (
  //         <Tooltip
  //           title="Medio en el que se activa la campaña."
  //           color="#002448"
  //           placement="top"
  //         >
  //           Medio
  //         </Tooltip>
  //       ),
  //       selector: "medio",
  //       tag: "medio",
  //       sortable: true,
  //       maxWidth: "100px",
  //       type: "text",
  //       group: true,
  //       center: true,
  //       omit: false,
  //       cell: (row) => (
  //         //  row.medio,
  //         <OptionsSelectedEdit
  //           id={row.id_region ? row.id_region + "," + row.id : row.id}
  //           editable={true}
  //           data={row.options ? row.options : []}
  //           value={row.medio}
  //           nameColumn={row.optionsMedium}
  //           onEditValue={mediumChange}
  //           textSelect={
  //             row.options
  //               ? row.options.length !== 0
  //                 ? "Selecciona una opción"
  //                 : "Selecciona un pais"
  //               : "Selecciona un pais"
  //           }
  //         />
  //       ),
  //     },
  //     {
  //       id: "banner",
  //       name: (
  //         <Tooltip
  //           title="Tipo de formato de la campaña."
  //           color="#002448"
  //           placement="top"
  //         >
  //           Objetivo
  //         </Tooltip>
  //       ),
  //       selector: "banner",
  //       group: true,
  //       tag: "banner",
  //       sortable: true,
  //       type: "text",
  //       center: true,
  //       omit: false,
  //       cell: (row) => (
  //         // row.banner,
  //         <OptionsSelectedEdit
  //           id={row.id}
  //           editable={true}
  //           data={row.optionsBanner ? row.optionsBanner : []}
  //           value={row.banner}
  //           nameColumn={row.optionsB ? row.optionsB : "banner"}
  //           onEditValue={bannerChange}
  //           textSelect={
  //             row.optionsBanner
  //               ? row.optionsBanner.length !== 0
  //                 ? "Selecciona una opción"
  //                 : "Selecciona un medio"
  //               : "Selecciona un medio"
  //           }
  //         />
  //       ),
  //     },
  //     {
  //       id: "budgetI",
  //       name: (
  //         <Tooltip title="Presupuesto aprobado." color="#002448" placement="top">
  //           Presupuesto Invertido
  //         </Tooltip>
  //       ),
  //       selector: "budgetI",
  //       tag: "budgetI",
  //       sortable: true,
  //       minWidth: "150px",
  //       center: true,
  //       omit: false,
  //       cell: (row) => (
  //         <NumberFormatEdit
  //           prefix={"$"}
  //           value={row.budget}
  //           thousandSeparator={true}
  //           editable={true}
  //           onEditValue={editData}
  //           id={row.id}
  //           displayType={"text"}
  //           tipo={"text"}
  //           nameColumn={"budget"}
  //         />
  //       ),
  //     },
  //     {
  //       id: "budget",
  //       name: (
  //         <Tooltip
  //           title="Presupuesto implementado en el medio."
  //           color="#002448"
  //           placement="top"
  //         >
  //           Presupuesto Neto
  //         </Tooltip>
  //       ),
  //       selector: "budget",
  //       tag: "budget",
  //       sortable: true,
  //       minWidth: "150px",
  //       center: true,
  //       omit: false,
  //       cell: (row) => (
  //         <NumberFormat
  //           prefix={"$"}
  //           value={row.budget}
  //           displayType={"text"}
  //           thousandSeparator={true}
  //           justifyContent={"center"}
  //           center={"true"}
  //         />
  //       ),
  //     },
  //     {
  //       id: "budgetConsumido",
  //       name: (
  //         <Tooltip
  //           title="Presupuesto gastado en el medio."
  //           color="#002448"
  //           placement="top"
  //         >
  //           Presupuesto Consumido
  //         </Tooltip>
  //       ),
  //       selector: "budgetConsumido",
  //       tag: "budgetConsumido",

  //       sortable: true,
  //       minWidth: "150px",
  //       center: true,
  //       omit: false,
  //       cell: (row) => "$ 0"
  //     },
  //     {
  //       id: "swc",
  //       omit: false,
  //       name: (
  //         <Tooltip
  //           title="Presupuesto gastado aprobado."
  //           color="#002448"
  //           placement="top"
  //         >
  //           Presupuesto Consumido con Comisión
  //         </Tooltip>
  //       ),
  //       minWidth: "150px",
  //       selector: "swc",
  //       tag: "swc",
  //       sortable: true,
  //       center: true,
  //       omit: false,
  //       cell: (row) => (
  //         <NumberFormat
  //           prefix={"$"}
  //           value={row.swc}
  //           displayType={"text"}
  //           thousandSeparator={true}
  //           justifyContent={"center"}
  //           center={"true"}
  //         />
  //       ),
  //     },
  //     {
  //       id: "budgetSpend1",
  //       name: (
  //         <Tooltip
  //           title="Presupuesto no gastado en el medio."
  //           color="#002448"
  //           placement="top"
  //         >
  //           Presupuesto sin Consumir
  //         </Tooltip>
  //       ),
  //       selector: "budgetSpend1",
  //       tag: "budgetSinConsumir",
  //       sortable: true,
  //       center: true,
  //       omit: false,
  //       minWidth: "150px",
  //       cell: (row) => "$" + numberWithCommas(row.budget)
  //     },
  //     {
  //       id: "sp_perc",
  //       name: (
  //         <Tooltip
  //           title="Porcentaje del presupuesto aprobado que ha sido gastado en el medio."
  //           color="#002448"
  //           placement="top"
  //         >
  //           Porcentaje de Presupuesto Consumido
  //         </Tooltip>
  //       ),
  //       minWidth: "150px",
  //       tag: "spent_percent",
  //       selector: "sp_perc",
  //       sortable: true,
  //       center: true,
  //       omit: false,
  //       cell: (row) => "0" + "%",
  //     },
  //     {
  //       id: "dias_campaña",
  //       name: (
  //         <Tooltip
  //           title="Número de días que tu campaña estará activa según fechas configuradas."
  //           color="#002448"
  //           placement="top"
  //         >
  //           Días de Campaña
  //         </Tooltip>
  //       ),
  //       selector: "dias_campaña",
  //       tag: "dias_campaña",
  //       sortable: true,
  //       center: true,
  //       omit: false,
  //       cell: (row) => row.dias_campaña,
  //     },
  //     {
  //       id: "segmentation",
  //       name: (
  //         <Tooltip
  //           title="Público objetivo al que tu campaña va dirigida."
  //           color="#002448"
  //           placement="top"
  //         >
  //           Públicos
  //         </Tooltip>
  //       ),
  //       selector: "segmentation",
  //       tag: "segmentation",
  //       minWidth: "150px",
  //       textAlign: "center",
  //       group: true,
  //       sortable: true,
  //       style: {
  //         textAlign: "center",
  //       },
  //       type: "text",
  //       center: true,
  //       omit: false,
  //       cell: (row) => (
  //         <OptionsSelectedEdit
  //           id={row.id}
  //           editable={true}
  //           data={row.optionsAudiences ? row.optionsAudiences : []}
  //           value={audienceComponent(row.idAudience)}
  //           nameColumn={row.optionsAudiences ? row.optionsAudiences : []}
  //           onEditValue={changeAudiencia}
  //         />
  //       ),
  //     },
  //     {
  //       id: "comentarios",
  //       name: (
  //         <Tooltip
  //           title="Detalles, información adicional, observaciones a tomar en cuenta en la configuración de tu campaña."
  //           color="#002448"
  //           placement="top"
  //         >
  //           Comentarios
  //         </Tooltip>
  //       ),
  //       selector: "comentarios",
  //       tag: "comentarios",
  //       maxWidth: "300px",
  //       sortable: true,
  //       center: true,
  //       omit: false,
  //       cell: (row) =>
  //         row.comentarios ? row.comentarios.substr(0, 10) + "..." : "",
  //     },
  //     {
  //       id: "ctr",
  //       name: (
  //         <Tooltip
  //           title="Porcentaje de veces que las personas vieron tu anuncio e hicieron un clic."
  //           color="#002448"
  //           placement="top"
  //         >
  //           CTR
  //         </Tooltip>
  //       ),
  //       selector: "ctr",
  //       tag: "ctr",
  //       sortable: true,
  //       center: true,
  //       omit: false,
  //       cell: (row) => "0" + "%",
  //     },
  //     {
  //       id: "photo",
  //       name: (
  //         <Tooltip
  //           title="Número de veces que las personas hacen clic en las fotos para verlas en Facebook."
  //           color="#002448"
  //           placement="top"
  //         >
  //           Visualizaciones de imagen
  //         </Tooltip>
  //       ),
  //       minWidth: "150px",
  //       selector: "photo",
  //       tag: "photo",
  //       sortable: true,
  //       center: true,
  //       omit: false,
  //       cell: (row) => (
  //         <NumberFormat
  //           prefix={""}
  //           value={row.photo}
  //           displayType={"text"}
  //           thousandSeparator={true}
  //           justifyContent={"center"}
  //           center={"true"}
  //         />
  //         // ,<DetailCol row={row}/>
  //       ),
  //     },
  //     {
  //       id: "leads",
  //       omit: false,
  //       name: (
  //         <Tooltip
  //           title="Número de clientes potenciales obtenido en tus anuncios."
  //           color="#002448"
  //           placement="top"
  //         >
  //           Leads
  //         </Tooltip>
  //       ),
  //       selector: "leads",
  //       tag: "leads",
  //       sortable: true,
  //       center: true,
  //       cell: (row) => (
  //         <NumberFormat
  //           prefix={""}
  //           value={row.leads}
  //           displayType={"text"}
  //           thousandSeparator={true}
  //           justifyContent={"center"}
  //           center={"true"}
  //         />
  //       ),
  //     },
  //     {
  //       id: "event_responses",
  //       name: (
  //         <Tooltip
  //           title="Número de personas que respondieron ME INTERESA o ASISTIRÉ a tu evento de Facebook como resultado de tus anuncios de Facebook."
  //           color="#002448"
  //           placement="top"
  //         >
  //           Respuestas a Eventos
  //         </Tooltip>
  //       ),
  //       minWidth: "150px",
  //       selector: "event_responses",
  //       tag: "event_responses",
  //       sortable: true,
  //       center: true,
  //       omit: false,
  //       cell: (row) => (
  //         <NumberFormat
  //           prefix={""}
  //           value={row.event_responses}
  //           displayType={"text"}
  //           thousandSeparator={true}
  //           justifyContent={"center"}
  //           center={"true"}
  //         />
  //       ),
  //     },
  //     {
  //       id: "installs",
  //       name: (
  //         <Tooltip
  //           title="Número de instalaciones de la app que se registraron como resultado de tus anuncios."
  //           color="#002448"
  //           placement="top"
  //         >
  //           Instalación de aplicaciones
  //         </Tooltip>
  //       ),
  //       minWidth: "150px",
  //       selector: "installs",
  //       tag: "installs",
  //       sortable: true,
  //       center: true,
  //       omit: false,
  //       cell: (row) => (
  //         <NumberFormat
  //           prefix={""}
  //           value={row.installs}
  //           displayType={"text"}
  //           thousandSeparator={false}
  //           justifyContent={"center"}
  //           center={"true"}
  //         />
  //       ),
  //     },
  //     {
  //       id: "estimated_ad_recallers",
  //       name: (
  //         <Tooltip
  //           title="Número estimado de personas que es posible que recuerden haber visto tus anuncios, si se les pregunta, en un plazo de 2 días. Aplica en Facebook."
  //           color="#002448"
  //           placement="top"
  //         >
  //           Reconocimiento de marca (personas)
  //         </Tooltip>
  //       ),
  //       selector: "estimated_ad_recallers",
  //       tag: "estimated_ad_recallers",
  //       sortable: true,
  //       center: true,
  //       omit: false,
  //       minWidth: "150px",
  //       cell: (row) => numberWithCommasTwoo(0),
  //     },
  //     {
  //       id: "frequency",
  //       name: (
  //         <Tooltip
  //           title="Promedio de veces que cada persona vio tu anuncio."
  //           color="#002448"
  //           placement="top"
  //         >
  //           Frecuencia
  //         </Tooltip>
  //       ),
  //       selector: "frequency",
  //       tag: "frequency",
  //       sortable: true,
  //       center: true,
  //       omit: false,
  //       minWidth: "150px",
  //       cell: (row) => (
  //         <NumberFormat
  //           prefix={""}
  //           value={row.frequency}
  //           displayType={"text"}
  //           thousandSeparator={true}
  //           justifyContent={"center"}
  //           center={"true"}
  //         />
  //       ),
  //     },
  //     {
  //       id: "calificacion",
  //       name: (
  //         <Tooltip
  //           title="El anuncio se clasifica en relación con anuncios que compitieron por el mismo público en Facebook. La calidad se mide con las opiniones sobre tus anuncios y la experiencia después de hacer clic en la publicación."
  //           color="#002448"
  //           placement="top"
  //         >
  //           Calificación del anunncio
  //         </Tooltip>
  //       ),
  //       selector: "calificacion",
  //       tag: "calificacion",
  //       sortable: true,
  //       center: true,
  //       omit: false,
  //       cell: (row) => (row.calificacion ? row.calificacion : 0),
  //     },
  //     {
  //       id: "cpl",
  //       name: (
  //         <Tooltip
  //           title="Costo promedio por lead"
  //           color="#002448"
  //           placement="top"
  //         >
  //           CPL
  //         </Tooltip>
  //       ),
  //       selector: "cpl",
  //       tag: "cpl",
  //       sortable: true,
  //       center: true,
  //       omit: false,
  //       cell: (row) => (
  //         <NumberFormat
  //           prefix={"$"}
  //           value={row.cpl}
  //           displayType={"text"}
  //           thousandSeparator={false}
  //           justifyContent={"center"}
  //           center={"true"}
  //         />
  //       ),
  //     },
  //     {
  //       id: "cpi",
  //       name: (
  //         <Tooltip
  //           title="Costo promedio por instalación de app."
  //           color="#002448"
  //           placement="top"
  //         >
  //           CPI
  //         </Tooltip>
  //       ),
  //       selector: "cpi",
  //       tag: "cpi",
  //       sortable: true,
  //       center: true,
  //       omit: false,
  //       cell: (row) => (
  //         <NumberFormat
  //           prefix={"$"}
  //           value={row.cpi}
  //           displayType={"text"}
  //           thousandSeparator={true}
  //           justifyContent={"center"}
  //           center={"true"}
  //         />
  //       ),
  //     },
  //     {
  //       id: "cpre",
  //       omit: false,
  //       name: (
  //         <Tooltip
  //           title="Costo promedio por respuesta a evento."
  //           color="#002448"
  //           placement="top"
  //         >
  //           CPRE
  //         </Tooltip>
  //       ),
  //       selector: "cpre",
  //       tag: "cpr",
  //       sortable: true,
  //       center: true,
  //       minWidth: "150px",
  //       cell: (row) => (
  //         <NumberFormat
  //           prefix={"$"}
  //           value={row.cpr}
  //           displayType={"text"}
  //           thousandSeparator={true}
  //           justifyContent={"center"}
  //           center={"true"}
  //         />
  //       ),
  //     },
  //     {
  //       id: "cpa",
  //       name: (
  //         <Tooltip
  //           title="Costo promedio por interacción en la publicación: Clics, comentarios, compartidos, reacciones, visualizaciones de imagen, reproducciones de video."
  //           color="#002448"
  //           placement="top"
  //         >
  //           CPInt
  //         </Tooltip>
  //       ),
  //       selector: "cpa",
  //       tag: "cpa",
  //       sortable: true,
  //       center: true,
  //       omit: false,
  //       cell: (row) => (
  //         <NumberFormat
  //           prefix={"$"}
  //           value={row.cpa}
  //           displayType={"text"}
  //           thousandSeparator={false}
  //           justifyContent={"center"}
  //           center={"true"}
  //         />
  //       ),
  //     },
  //     {
  //       id: "cpc",
  //       name: (
  //         <Tooltip
  //           title="Costo promedio por clic."
  //           color="#002448"
  //           placement="top"
  //         >
  //           CPC
  //         </Tooltip>
  //       ),
  //       selector: "cpc",
  //       tag: "cpc",
  //       sortable: true,
  //       center: true,
  //       omit: false,
  //       cell: (row) => (
  //         <NumberFormat
  //           prefix={"$"}
  //           value={row.cpc}
  //           displayType={"text"}
  //           thousandSeparator={false}
  //           justifyContent={"center"}
  //           center={"true"}
  //         />
  //       ),
  //     },
  //     {
  //       id: "cpv",
  //       name: (
  //         <Tooltip
  //           title="Costo promedio por reproducción de video."
  //           color="#002448"
  //           placement="top"
  //         >
  //           CPV
  //         </Tooltip>
  //       ),
  //       selector: "cpv",
  //       tag: "cpv",
  //       sortable: true,
  //       center: true,
  //       omit: false,
  //       cell: (row) => (
  //         <NumberFormat
  //           decimalScale={3}
  //           prefix={"$"}
  //           value={row.cpv}
  //           displayType={"text"}
  //           thousandSeparator={true}
  //           justifyContent={"center"}
  //           center={"true"}
  //         />
  //       ),
  //     },
  //     {
  //       id: "cpm",
  //       name: (
  //         <Tooltip
  //           title="Costo promedio por mil impresiones."
  //           color="#002448"
  //           placement="top"
  //         >
  //           CPM
  //         </Tooltip>
  //       ),
  //       selector: "cpm",
  //       tag: "cpm",
  //       sortable: true,
  //       center: true,
  //       omit: false,
  //       cell: (row) => (
  //         <NumberFormat
  //           prefix={"$"}
  //           value={row.cpm}
  //           displayType={"text"}
  //           thousandSeparator={false}
  //           justifyContent={"center"}
  //           center={"true"}
  //         />
  //       ),
  //     },
  //     {
  //       id: "cpf",
  //       name: (
  //         <Tooltip
  //           title="Costo promedio por fan."
  //           color="#002448"
  //           placement="top"
  //         >
  //           CPF
  //         </Tooltip>
  //       ),
  //       selector: "cpf",
  //       tag: "cpf",
  //       sortable: true,
  //       center: true,
  //       omit: false,
  //       minWidth: "150px",
  //       cell: (row) => (
  //         <NumberFormat
  //           prefix={"$"}
  //           value={row.cpf}
  //           displayType={"text"}
  //           thousandSeparator={false}
  //           justifyContent={"center"}
  //           center={"true"}
  //         />
  //       ),
  //     },
  //     {
  //       id: "cprm",
  //       omit: false,
  //       name: (
  //         <Tooltip
  //           title="Costo promedio por reconocimiento de marca."
  //           color="#002448"
  //           placement="top"
  //         >
  //           CPRM
  //         </Tooltip>
  //       ),
  //       selector: "cprm",
  //       tag: "cprm",
  //       sortable: true,
  //       center: true,
  //       minWidth: "150px",
  //       cell: (row) => "$" + numberWithCommasThree(row.cprm),
  //     },
  //     {
  //       id: "prints",
  //       name: (
  //         <Tooltip
  //           title="Número de veces que se mostraron tus anuncios."
  //           color="#002448"
  //           placement="top"
  //         >
  //           Impresiones
  //         </Tooltip>
  //       ),
  //       minWidth: "150px",
  //       selector: "prints",
  //       tag: "prints",
  //       sortable: true,
  //       center: true,
  //       omit: false,
  //       cell: (row) => (
  //         <NumberFormat
  //           prefix={""}
  //           value={row.prints}
  //           displayType={"text"}
  //           thousandSeparator={true}
  //           justifyContent={"center"}
  //           center={"true"}
  //         />
  //       ),
  //     },

  //     {
  //       id: "clicks",
  //       name: (
  //         <Tooltip
  //           title="Número de clics en tus anuncios."
  //           color="#002448"
  //           placement="top"
  //         >
  //           Clics
  //         </Tooltip>
  //       ),
  //       selector: "clicks",
  //       tag: "clicks",
  //       sortable: true,
  //       center: true,
  //       omit: false,
  //       cell: (row) => (
  //         <NumberFormat
  //           prefix={""}
  //           value={row.clicks}
  //           displayType={"text"}
  //           thousandSeparator={true}
  //           justifyContent={"center"}
  //           center={"true"}
  //         />
  //       ),
  //     },

  //     {
  //       id: "fans",
  //       name: (
  //         <Tooltip
  //           title="Número de Me gusta de la página de Facebook."
  //           color="#002448"
  //           placement="top"
  //         >
  //           Fans
  //         </Tooltip>
  //       ),
  //       selector: "fans",
  //       tag: "fans",
  //       sortable: true,
  //       omit: false,
  //       justifyContent: "center",
  //       center: true,
  //       cell: (row) => (
  //         <NumberFormat
  //           prefix={""}
  //           value={row.fans}
  //           displayType={"text"}
  //           thousandSeparator={true}
  //           justifyContent={"center"}
  //           center={"true"}
  //         />
  //       ),
  //     },
  //     {
  //       id: "reach",
  //       name: (
  //         <Tooltip
  //           title="Número de personas que vieron tus anuncios al menos una vez."
  //           color="#002448"
  //           placement="top"
  //         >
  //           Alcance
  //         </Tooltip>
  //       ),
  //       selector: "reach",
  //       tag: "reach",
  //       sortable: true,
  //       center: true,
  //       omit: false,
  //       cell: (row) => (
  //         <NumberFormat
  //           prefix={""}
  //           value={row.reach}
  //           displayType={"text"}
  //           thousandSeparator={true}
  //           justifyContent={"center"}
  //           center={"true"}
  //         />
  //       ),
  //     },

  //     {
  //       id: "views",
  //       name: (
  //         <Tooltip
  //           title="Número de veces que se reproduce tu video en un anuncio."
  //           color="#002448"
  //           placement="top"
  //         >
  //           Reproducciones de video
  //         </Tooltip>
  //       ),
  //       minWidth: "150px",
  //       selector: "views",
  //       tag: "views",
  //       sortable: true,
  //       center: true,
  //       omit: false,
  //       cell: (row) => (
  //         <NumberFormat
  //           prefix={""}
  //           value={row.views}
  //           displayType={"text"}
  //           thousandSeparator={true}
  //           justifyContent={"center"}
  //           center={"true"}
  //         />
  //       ),
  //     },
  //     {
  //       id: "video_playbacks_25",
  //       name: (
  //         <Tooltip
  //           title="Número de veces que se reproduce tu video hasta el 25% de su duración."
  //           color="#002448"
  //           placement="top"
  //         >
  //           Reproducciones de video 25%
  //         </Tooltip>
  //       ),
  //       minWidth: "150px",
  //       selector: "video_playbacks_25",
  //       tag: "video_playbacks_25",
  //       sortable: true,
  //       center: true,
  //       omit: false,
  //       cell: (row) => (
  //         <NumberFormat
  //           prefix={""}
  //           value={row.video_playbacks_25}
  //           displayType={"text"}
  //           thousandSeparator={true}
  //           justifyContent={"center"}
  //           center={"true"}
  //         />
  //       ),
  //     },
  //     {
  //       id: "video_playbacks_50",
  //       name: (
  //         <Tooltip
  //           title="Número de veces que se reproduce tu video hasta el 50% de su duración."
  //           color="#002448"
  //           placement="top"
  //         >
  //           Reproducciones de video 50%
  //         </Tooltip>
  //       ),
  //       minWidth: "150px",
  //       selector: "video_playbacks_50",
  //       tag: "video_playbacks_50",
  //       sortable: true,
  //       omit: false,
  //       center: true,
  //       cell: (row) => (
  //         <NumberFormat
  //           prefix={""}
  //           value={row.video_playbacks_50}
  //           displayType={"text"}
  //           thousandSeparator={true}
  //           justifyContent={"center"}
  //           center={"true"}
  //         />
  //       ),
  //     },
  //     {
  //       id: "video_playbacks_75",
  //       name: (
  //         <Tooltip
  //           title="Número de veces que se reproduce tu video hasta el 75% de su duración."
  //           color="#002448"
  //           placement="top"
  //         >
  //           Reproducciones de video 75%
  //         </Tooltip>
  //       ),
  //       tag: "video_playbacks_75",
  //       minWidth: "150px",
  //       selector: "video_playbacks_75",
  //       sortable: true,
  //       omit: false,
  //       center: true,
  //       cell: (row) => (
  //         <NumberFormat
  //           prefix={""}
  //           value={row.video_playbacks_75}
  //           displayType={"text"}
  //           thousandSeparator={true}
  //           justifyContent={"center"}
  //           center={"true"}
  //         />
  //       ),
  //     },
  //     {
  //       id: "video_playbacks_95",
  //       name: (
  //         <Tooltip
  //           title="Número de veces que se reproduce tu video hasta el 95% de su duración."
  //           color="#002448"
  //           placement="top"
  //         >
  //           Reproducciones de video 95%
  //         </Tooltip>
  //       ),
  //       minWidth: "150px",
  //       selector: "video_playbacks_95",
  //       tag: "video_playbacks_95",
  //       sortable: true,
  //       omit: false,
  //       center: true,
  //       cell: (row) => (
  //         <NumberFormat
  //           prefix={""}
  //           value={row.video_playbacks_95}
  //           displayType={"text"}
  //           thousandSeparator={true}
  //           justifyContent={"center"}
  //           center={"true"}
  //         />
  //       ),
  //     },
  //     {
  //       id: "video_playbacks_100",
  //       name: (
  //         <Tooltip
  //           title="Número de veces que se reproduce tu video hasta el 100% de su duración."
  //           color="#002448"
  //           placement="top"
  //         >
  //           Reproducciones de video 100%
  //         </Tooltip>
  //       ),
  //       minWidth: "150px",
  //       tag: "video_playbacks_100",
  //       selector: "video_playbacks_100",
  //       omit: false,
  //       sortable: true,
  //       center: true,
  //       cell: (row) => (
  //         <NumberFormat
  //           prefix={""}
  //           value={row.video_playbacks_100}
  //           displayType={"text"}
  //           thousandSeparator={true}
  //           justifyContent={"center"}
  //           center={"true"}
  //         />
  //       ),
  //     },
  //     {
  //       id: "adv",
  //       name: (
  //         <Tooltip
  //           title="Tiempo promedio de reproducción del video en tu anuncio."
  //           color="#002448"
  //           placement="top"
  //         >
  //           Tiempo promedio de reproducción del video
  //         </Tooltip>
  //       ),
  //       selector: "adv",
  //       tag: "adv",
  //       minWidth: "150px",
  //       sortable: true,
  //       center: true,
  //       omit: false,
  //       cell: (row) => row.adv
  //     },
  //     // {
  //     //   id: "postShares",
  //     //   omit: false,
  //     //   name: (
  //     //     <Tooltip
  //     //       title="Número de veces que se compartió la publicación de Facebook."
  //     //       color="#002448"
  //     //       placement="top"
  //     //     >
  //     //       Veces que se compartió la publicación
  //     //     </Tooltip>
  //     //   ),
  //     //   minWidth: "150px",
  //     //   selector: "postShares",
  //     //   tag: "postShares",
  //     //   sortable: true,
  //     //   center: true,
  //     //   cell: (row) => (
  //     //     <NumberFormat
  //     //       prefix={""}
  //     //       value={row.postShares}
  //     //       displayType={"text"}
  //     //       thousandSeparator={true}
  //     //       center={"center"}
  //     //     />
  //     //   ),
  //     // },

  //     // {
  //     //   id: "postComments",
  //     //   name: (
  //     //     <Tooltip
  //     //       title="Número de comentarios en tus anuncios de Facebook."
  //     //       color="#002448"
  //     //       placement="top"
  //     //     >
  //     //       Comentarios en la publicación
  //     //     </Tooltip>
  //     //   ),
  //     //   selector: "postComments",
  //     //   tag: "postComments",
  //     //   minWidth: "150px",
  //     //   group: true,
  //     //   sortable: true,
  //     //   type: "text",
  //     //   center: true,
  //     //   omit: false,
  //     //   Header: () => (
  //     //     <div
  //     //       style={{
  //     //         textAlign: "center",
  //     //       }}
  //     //     ></div>
  //     //   ),
  //     //   cell: (row) => row.postComments,
  //     // },
  //     // {
  //     //   id: "postLikes",
  //     //   omit: false,
  //     //   name: (
  //     //     <Tooltip
  //     //       title="Número de reacciones en tus anuncios de Facebook."
  //     //       color="#002448"
  //     //       placement="top"
  //     //     >
  //     //       Reaciones de la publicación
  //     //     </Tooltip>
  //     //   ),
  //     //   minWidth: "150px",
  //     //   selector: "postLikes",
  //     //   tag: "post_likes",
  //     //   sortable: true,
  //     //   justifyContent: "center",
  //     //   center: true,
  //     //   cell: (row) => (
  //     //     <NumberFormat
  //     //       prefix={""}
  //     //       value={row.post_likes}
  //     //       displayType={"text"}
  //     //       thousandSeparator={true}
  //     //       justifyContent={"center"}
  //     //       center={"true"}
  //     //     />
  //     //   ),
  //     // },
  //   ];

  const [optionss, setOptionss] = useState([]);
  let nameCat = [
    ["Banner"],
    ["Estado"],
    ["Medio"],
    ["Pais"],
    ["Tipo de compra"],
  ];
  let ids = [];
  let namesCopy = ["Banner", "Estado", "Medio", "Pais", "Tipo de compra"];
  let nameCat2 = [
    ["Banner"],
    ["Estado"],
    ["Medio"],
    ["Pais"],
    ["Tipo de compra"],
  ];
  useEffect(() => {
    catAux = props.categories;
    if (Object.entries(actualState).length != 0) {
      const colsSort = _.orderBy(newColumnsExa, ["name"], ["asc", "desc"]);
      setcolsSorted(colsSort);

      //Para las categorias en columnas
      let columnsCopy = newColumnsExa;
      var columnsCategories = [];
      let activeCategories = props.categories?.filter(
        (item) =>
          item.active == true &&
          (item.padre == "97bc9ee5-36a0-455b-974c-fa7832c9c341" ||
            item.padre == ""),
      );
      let catsSorted = _.orderBy(activeCategories, ["name"], ["asc", "desc"]);
      setCategoriesSorted(catsSorted);
      // alert(catsSorted[0].name )
      if (catsSorted.length != 0) {
        if (catsSorted[0].name == "Categoria por defecto") {
          setCargarView(false);
        } else {
          if (cargarView == false && viewCount == false) {
            setCargarView(true);
            // actualizarViews(true)
            actView();
          }
        }
      }

      for (let i = 0; i < catsSorted.length; i++) {
        let optionsPorCategorias = [];
        nameCat.push(catsSorted[i].name);
        ids.push(catsSorted[i].id);
        nameCat2.push(catsSorted[i].name);
        let optionsCats = props.categories.filter(
          (ele) => ele.padre === catsSorted[i].id && ele.active === true,
        );
        optionsCats.forEach((ele) => {
          let body = {
            label: ele.name,
            value: ele.id,
          };
          optionsPorCategorias.push(body);
        });

        let body = {
          id: catsSorted[i].id,
          name: catsSorted[i].name,
          selector: catsSorted[i].id,
          sortable: false,
          tag: catsSorted[i].id,
          type: "text",
          center: true,
          minWidth: "250px",
          omit: false,
          cell: (row) => {
            let value = [];
            if (row.categoriesSelected) {
              if (row.categoriesSelected.length !== 0) {
                row.categoriesSelected.forEach((ele) => {
                  if (ele.padre === catsSorted[i].id) {
                    let bodyValue = {
                      label: ele.label,
                      value: ele.value,
                    };
                    value.push(bodyValue);
                  }
                });
              }
            }
            return (
              <OptionNewEdit
                multiple
                value={value}
                options={optionsPorCategorias}
                text={
                  row[catsSorted[i].id] !== undefined
                    ? new String(row[catsSorted[i].id]).length < 10
                      ? row[catsSorted[i].id]
                      : row[catsSorted[i].id].substr(0, 10) + "..."
                    : "..."
                }
                edit={true}
                styles={styles}
                id={row.id}
                nameColumn={catsSorted[i].id}
                onEditValue={editCategories}
                optionalValue={catsSorted[i].name}
              />
            );
          },
        };
        columnsCategories.push(body);
      }
      columnsCopy = [...columnsCopy, ...columnsCategories];
      setColumns1([...columnsCategories]);
      setColsBack([...columnsCopy]);
      nameCat.sort();
      nameCat2.sort();
      nameCat2.splice(0, 0, "Por defecto");
      setOptionss(nameCat2);
      setNamesCate(nameCat);
    } else {
      //vacio
    }
  }, [props.categories, props.budget_spent, props.budgets, actualState]);
  const [colAct, setColAct] = useState([]);
  useEffect(() => {
    if (cargarView) {
      let show = false;
      let dataFinal = [];
      let or = [];
      let namesActualesColumns = [];
      let namesViews = [];
      colsBack.forEach((ele) => {
        namesActualesColumns.push(ele.selector);
      });
      setColumns(colsBack);
      views.forEach((ele) => {
        if (ele.active) {
          ele.columns.forEach((elementos) => {
            show = true;
            name(ele.name);
            namesViews.push(elementos.selector);
            handleShowColumnChange(elementos.selector, elementos.omit);
            if (namesActualesColumns.includes(elementos.selector)) {
              dataFinal.push(elementos);
            }
          });
        }
      });

      if (dataFinal.length == 0) setNameView("Lista");
      if (show) {
        namesActualesColumns.forEach((ele) => {
          if (namesViews.includes(ele)) {
          } else {
            handleShowColumnChange(ele, true);
          }
        });
        dataFinal.sort((a, b) => {
          return a.position - b.position;
        });
        dataFinal.forEach((element) => {
          or.push(element.id);
        });

        if (dataFinal.length > 0) {
          handleColumnSortChange(or);
        }
      }
    }
  }, [colAct, cargarView]);

  const editCategories = async (value) => {
    let categoriasSelected = [];
    if (value[0].optionsSelect.length !== 0) {
      value[0].optionsSelect.forEach((ele) => {
        let body = {
          label: ele.label,
          nombrePadre: value[0].value,
          padre: value[0].column,
          value: ele.value,
        };
        categoriasSelected.push(body);
      });
    }
    await editData([
      {
        id: value[0].id,
        column: "categoriesSelected",
        value: categoriasSelected,
      },
    ]);
  };

  const actView = async () => {
    if (Auth.user) {
      // setLoad(true);
      let sub = Auth.user.signInUserSession.idToken.payload.sub;
      let viewsData = await API.getViewsByUser({
        account: account,
        user: sub,
        component: "campaign",
      });
      let dataVie = viewsData.data.views;
      setViews(dataVie);
      setCargarView(true);
      setColAct(dataVie);
      setviewCount(true);
      // setLoad(false);
    }
  };
  const actualizarViews = async (tipo) => {
    // let sub = Auth.user.signInUserSession.idToken.payload.sub;
    const apiUrl = "https://api.ipify.org?format=json";
    const responseGeo = await fetch(apiUrl);
    const data = await responseGeo.json();
    const sub = data.ip;
    let viewsData = await API.getViewsByUser({
      account: account,
      user: sub,
      component: "campaign",
    });
    let dataVie = viewsData.data.views;
    setViews(dataVie);
    if (tipo) {
      setColAct(dataVie);
    }
  };

  useEffect(() => {
    getAudiences(account);
    getDataOptions();
    setSendCampaigns(props.sendCampaignss);
    setActualState({
      place: "dias",
      data: [],
    });

    let fechaFin = new Date();
    let fechaIn = new Date();
    fechaIn.setDate(fechaIn.getDate() - 7);
    let fechaIni = new Date(fechaIn);
    setDatesToShow({
      fechaIni,
      fechaFin,
    });

    var camps = props.sendCampaignss;
    for (let i = 0; i < camps.length; i++) {
      if (camps[i].categoriesSelected) {
        var categories = camps[i].categoriesSelected;

        /* const grouped = groupBy(categories, cat => cat.nombrePadre); */
        const categoriesGruped = categories.reduce(
          (groups, item) => ({
            ...groups,
            [item.nombrePadre]: [...(groups[item.nombrePadre] || []), item],
          }),
          {},
        );

        let valores = Object.values(categoriesGruped);

        for (let j = 0; j < valores.length; j++) {
          let catsString = "";
          for (let k = 0; k < valores[j].length; k++) {
            catsString = catsString + valores[j][k].label + ", ";
          }
          let body = { [valores[j][0].padre]: catsString };

          camps[i] = { ...camps[i], ...body };
        }
      }
    }
    setSendCampaigns(camps);
  }, [sendCampaignss]);

  useEffect(() => {
    if (sendCampaigns) {
      let pagData = [];
      let presupuestoNeto = 0;
      let presupuestoInvertido = 0;
      let estimatedAdRecallers = 0;
      let presupuestoConsumido = 0;
      let presupuestoComision = 0;
      let presupuestoSinConsumir = 0;
      let totalPorcentajePresupuestoConsumido = 0;
      let porcentajePresupuestoConsumido = 0;
      let contPresupuestoConsumido = 0;
      let visualizacionesImagen = 0;
      let eventResponses = 0;
      let appInstalations = 0;
      let frequency = 0;
      let totalFrequency = 0;
      let contFrequency = 0;
      let leads = 0;
      let cpf = 0;
      let totalCpf = 0;
      let contCpf = 0;
      let cpre = 0;
      let totalCpre = 0;
      let contCpre = 0;
      let cpi = 0;
      let totalCpi = 0;
      let contCpi = 0;
      let cpv = 0;
      let totalCpv = 0;
      let contCpv = 0;
      let ctr = 0;
      let totalCtr = 0;
      let contCtr = 0;
      let cpl = 0;
      let totalCpl = 0;
      let contCpl = 0;
      let cpa = 0;
      let totalCpa = 0;
      let contCpa = 0;
      let cpc = 0;
      let totalCpc = 0;
      let contCpc = 0;
      let cpm = 0;
      let totalCpm = 0;
      let contCpm = 0;
      let cprm = 0;
      let totalCprm = 0;
      let contCprm = 0;
      let prints = 0;
      let clicks = 0;
      let fans = 0;
      let reach = 0;
      let views = 0;
      let meta = 0;
      let video_playbacks_25 = 0;
      let video_playbacks_50 = 0;
      let video_playbacks_75 = 0;
      let video_playbacks_95 = 0;
      let video_playbacks_100 = 0;
      let adv = 0;
      let totalAdv = 0;
      let contAdv = 0;
      let postShares = 0;
      let postLikes = 0;
      let totalDiasCamp = 0;
      let diasCamp = 0;
      let contDiasCamp = 0;
      let comments = 0;

      let percentage = 0;
      let contPercentage = 0;
      let totalPercentage = 0;
      let valueTotalPercentage = 0;
      for (let x = 0; x < sendCampaigns.length; x++) {
        presupuestoNeto += new Number(
          sendCampaigns[x].budget
            ? sendCampaigns[x].budget
            : sendCampaigns[x].net_budget,
        );

        /* if(sendCampaigns[x].budgetI){
            presupuestoInvertido+=new Number(sendCampaigns[x].budgetI)
          }else{
            presupuestoInvertido+=new Number(sendCampaigns[x].budget)
          } */

        let percentageValue = getPercentNumber(sendCampaigns[x]);

        if (percentageValue != "NA") {
          percentage += new Number(percentageValue);
          contPercentage++;
        }

        let percentageTot = getPercentValue(sendCampaigns[x]);
        if (percentageTot != "NA") {
          valueTotalPercentage += new Number(percentageTot);
        }

        if (new Number(sendCampaigns[x].budget) > 0) {
          presupuestoInvertido += new Number(sendCampaigns[x].budget);
        }

        if (sendCampaigns[x].meta != null) {
          meta += new Number(sendCampaigns[x].meta);
        }

        if (new Number(sendCampaigns[x].estimated_ad_recallers)) {
          estimatedAdRecallers += new Number(
            sendCampaigns[x].estimated_ad_recallers,
          );
        }

        if (sendCampaigns[x].budgetConsumido > 0) {
          presupuestoConsumido += new Number(sendCampaigns[x].budgetConsumido);
        } else {
          presupuestoConsumido += new Number(
            getTotalspent(sendCampaigns[x].idMixDetaill),
          );
        }

        if (sendCampaigns[x].swc) {
          presupuestoComision += new Number(sendCampaigns[x].swc);
        }

        if (sendCampaigns[x].budgetSinConsumir) {
          presupuestoSinConsumir += new Number(
            sendCampaigns[x].budgetSinConsumir,
          );
        }

        if (sendCampaigns[x].photo > 0) {
          visualizacionesImagen += new Number(sendCampaigns[x].photo);
        }

        if (sendCampaigns[x].spent_percent) {
          porcentajePresupuestoConsumido += new Number(
            sendCampaigns[x].spent_percent,
          );
          contPresupuestoConsumido++;
        }
        if (sendCampaigns[x].cprm) {
          cprm += new Number(sendCampaigns[x].cprm);
          contCprm++;
        }
        if (sendCampaigns[x].cpf) {
          cpf += new Number(sendCampaigns[x].cpf);
          contCpf++;
        }
        if (sendCampaigns[x].cpre) {
          cpre += new Number(sendCampaigns[x].cpre);
          contCpre++;
        }
        if (sendCampaigns[x].cpi) {
          cpi += new Number(sendCampaigns[x].cpi);
          contCpi++;
        }
        if (sendCampaigns[x].cpv) {
          cpv += new Number(sendCampaigns[x].cpv);
          contCpv++;
        }
        if (sendCampaigns[x].ctr) {
          ctr += new Number(sendCampaigns[x].ctr);
          contCtr++;
        }
        if (sendCampaigns[x].leads) {
          leads += new Number(sendCampaigns[x].leads);
        }
        if (sendCampaigns[x].event_responses) {
          eventResponses += new Number(sendCampaigns[x].event_responses);
        }
        if (sendCampaigns[x].installs) {
          appInstalations += new Number(sendCampaigns[x].installs);
        }
        if (sendCampaigns[x].frequency) {
          frequency += new Number(sendCampaigns[x].frequency);
          contFrequency++;
        }
        if (sendCampaigns[x].cpl) {
          cpl += new Number(sendCampaigns[x].cpl);
          contCpl++;
        }
        if (sendCampaigns[x].cpa) {
          cpa += new Number(sendCampaigns[x].cpa);
          contCpa++;
        }
        if (sendCampaigns[x].cpc) {
          cpc += new Number(sendCampaigns[x].cpc);
          contCpc++;
        }
        if (sendCampaigns[x].cpm) {
          cpm += new Number(sendCampaigns[x].cpm);
          contCpm++;
        }
        if (sendCampaigns[x].prints) {
          prints += new Number(sendCampaigns[x].prints);
        }
        if (sendCampaigns[x].clicks) {
          clicks += sendCampaigns[x].clicks;
        }
        if (sendCampaigns[x].fans) {
          fans += sendCampaigns[x].fans;
        }
        if (sendCampaigns[x].reach) {
          reach += sendCampaigns[x].reach;
        }
        if (sendCampaigns[x].views) {
          views += sendCampaigns[x].views;
        }
        if (sendCampaigns[x].video_playbacks_25) {
          video_playbacks_25 += sendCampaigns[x].video_playbacks_25;
        }
        if (sendCampaigns[x].video_playbacks_50) {
          video_playbacks_50 += sendCampaigns[x].video_playbacks_50;
        }
        if (sendCampaigns[x].video_playbacks_75) {
          video_playbacks_75 += sendCampaigns[x].video_playbacks_75;
        }
        if (sendCampaigns[x].video_playbacks_95) {
          video_playbacks_95 += sendCampaigns[x].video_playbacks_95;
        }
        if (sendCampaigns[x].video_playbacks_100) {
          video_playbacks_100 += sendCampaigns[x].video_playbacks_100;
        }
        if (sendCampaigns[x].adv) {
          var a = sendCampaigns[x].adv.split(":");
          var seconds = +a[0] * 60 * 60 + +a[1] * 60 + +a[2];
          adv += seconds;
          contAdv++;
        }
        if (sendCampaigns[x].postShares) {
          postShares += sendCampaigns[x].postShares;
        }
        if (sendCampaigns[x].post_likes > 0) {
          postLikes += sendCampaigns[x].post_likes;
        }
        if (sendCampaigns[x].dias_campaña) {
          diasCamp += sendCampaigns[x].dias_campaña;
          contDiasCamp++;
        }
        if (sendCampaigns[x].postComments) {
          comments += sendCampaigns[x].postComments;
        }
      }
      totalAdv = adv / contAdv;
      totalCpm = cpm / contCpm;
      totalCpc = cpc / contCpc;
      totalCpa = cpa / contCpa;
      totalCpl = cpl / contCpl;
      totalCtr = ctr / contCtr;
      totalCpv = cpv / contCpv;
      totalCpi = cpi / contCpi;
      totalCpre = cpre / contCpre;
      totalCpf = cpf / contCpf;
      totalCprm = cprm / contCprm;
      totalPorcentajePresupuestoConsumido =
        porcentajePresupuestoConsumido / contPresupuestoConsumido;
      totalFrequency = frequency / contFrequency;
      totalDiasCamp = diasCamp / contDiasCamp;
      if (contPercentage != 0) {
        totalPercentage = percentage / contPercentage;
      } else {
        totalPercentage = "NA";
      }
      setTotalRow([
        {
          /*  "adv": totalAdv>0 ? totalAdv.toFixed(2) : 0, */
          adv:
            totalAdv > 0
              ? new Date(totalAdv * 1000).toISOString().substr(11, 8)
              : "00:00:00",
          ctr: totalCtr > 0 ? totalCtr.toFixed(2) : "0.00",
          cpc: totalCpc > 0 ? totalCpc.toFixed(2) : "0.00",
          estimated_ad_recallers: estimatedAdRecallers,
          cprm: totalCprm > 0 ? totalCprm : "0",
          photo: visualizacionesImagen,
          video_playbacks_50: video_playbacks_50,
          video_playbacks_25: video_playbacks_25,
          video_playbacks_75: video_playbacks_75,
          video_playbacks_95: video_playbacks_95,
          video_playbacks_100: video_playbacks_100,
          cpv: totalCpv > 0 ? totalCpv.toFixed(3) : "0.000",
          prints: prints,
          country: "",
          clicks: clicks,
          views: views,
          status: 100,
          fr: 0,
          searchable_status: "",
          idBudget: 0,
          swc: presupuestoComision.toFixed(2),
          budgetConsumido: presupuestoConsumido.toFixed(2),
          budgetI: presupuestoInvertido?.toFixed(2),
          budget: presupuestoInvertido?.toFixed(2),
          budgetSinConsumir: presupuestoSinConsumir.toFixed(2),
          installs: appInstalations,
          spent_percent:
            totalPorcentajePresupuestoConsumido > 0
              ? totalPorcentajePresupuestoConsumido.toFixed(2)
              : "0.00",
          leads: leads > 0 ? leads : 0,
          reach: reach,
          event_responses: eventResponses > 0 ? eventResponses.toFixed(0) : 0,
          name: "Totales",
          metaTotales: meta,
          totalPercentage:
            totalPercentage != "NA"
              ? valueTotalPercentage.toFixed(0) +
              "/" +
              totalPercentage.toFixed(0) +
              "%105"
              : "NA",
          client: "0",
          post_likes: postLikes,
          medio: "",
          idMixChange: 0,
          frequency: totalFrequency > 0 ? totalFrequency.toFixed(2) : 0,
          cpf: totalCpf > 0 ? totalCpf.toFixed(2) : "0.00",
          cpr: totalCpre > 0 ? totalCpre.toFixed(2) : "0.00",
          cpi: totalCpi > 0 ? totalCpi.toFixed(2) : "0.00",
          fans: fans,
          cpm: totalCpm > 0 ? totalCpm.toFixed(2) : "0.00",
          cpl: totalCpl > 0 ? totalCpl.toFixed(2) : "0.00",
          cpa: totalCpa > 0 ? totalCpa.toFixed(3) : "0.000",
          postShares: postShares,
          dias_campaña: totalDiasCamp > 0 ? totalDiasCamp.toFixed(0) : 0,
          postComments: comments,
        },
      ]);
    }
    setFiltered(sendCampaigns);
    setSearch(false);
  }, [sendCampaigns]);

  const getPercentValue = (row) => {
    if (row.meta > 0) {
      let valueToGet;
      if (row.columnaCompare === "post_likes") {
        valueToGet = 100 * new Number(row.post_likes);
      } else if (row.columnaCompare === "page_comments") {
        valueToGet = 100 * new Number(row.postComments);
      } else if (row.columnaCompare === "page_shares") {
        valueToGet = 100 * new Number(row.postShares);
      } else if (row.columnaCompare === "video_avg_time") {
        valueToGet = 100 * new Number(row.adv);
      } else if (row.columnaCompare === "video_playbacks_100") {
        valueToGet = 100 * new Number(row.video_playbacks_100);
      } else if (row.columnaCompare === "video_playbacks_95") {
        valueToGet = 100 * new Number(row.video_playbacks_95);
      } else if (row.columnaCompare === "video_playbacks_75") {
        valueToGet = 100 * new Number(row.video_playbacks_75);
      } else if (row.columnaCompare === "video_playbacks_50") {
        valueToGet = 100 * new Number(row.video_playbacks_50);
      } else if (row.columnaCompare === "video_playbacks_25") {
        valueToGet = 100 * new Number(row.video_playbacks_25);
      } else if (row.columnaCompare === "views") {
        valueToGet = 100 * new Number(row.views);
      } else if (row.columnaCompare === "reach") {
        valueToGet = 100 * new Number(row.reach);
      } else if (row.columnaCompare === "fans") {
        valueToGet = 100 * new Number(row.fans);
      } else if (row.columnaCompare === "clicks") {
        valueToGet = 100 * new Number(row.clicks);
      } else if (row.columnaCompare === "prints") {
        valueToGet = 100 * new Number(row.prints);
      } else if (row.columnaCompare === "frequency") {
        valueToGet = 100 * new Number(row.frequency);
      } else if (row.columnaCompare === "estimated_ad_recallers") {
        valueToGet = 100 * new Number(row.estimated_ad_recallers);
      } else if (row.columnaCompare === "mobile_app_install") {
        valueToGet = 100 * new Number(row.installs);
      } else if (row.columnaCompare === "event_responses") {
        valueToGet = 100 * new Number(row.event_responses);
      } else if (row.columnaCompare === "leads") {
        valueToGet = 100 * new Number(row.leads);
      } else if (row.columnaCompare === "photo_view") {
        valueToGet = 100 * new Number(row.photo);
      }

      if (valueToGet > 0) {
        let final = valueToGet / row.meta;

        return row.meta * (final / 100);
      } else if (valueToGet === 0) {
        return 0;
      } else {
        return "NA";
      }
    } else {
      return "NA";
    }
  };

  const getPercentNumber = (row) => {
    if (row.meta > 0) {
      let valueToGet;
      if (row.columnaCompare === "post_likes") {
        valueToGet = 100 * new Number(row.post_likes);
      } else if (row.columnaCompare === "page_comments") {
        valueToGet = 100 * new Number(row.postComments);
      } else if (row.columnaCompare === "page_shares") {
        valueToGet = 100 * new Number(row.postShares);
      } else if (row.columnaCompare === "video_avg_time") {
        valueToGet = 100 * new Number(row.adv);
      } else if (row.columnaCompare === "video_playbacks_100") {
        valueToGet = 100 * new Number(row.video_playbacks_100);
      } else if (row.columnaCompare === "video_playbacks_95") {
        valueToGet = 100 * new Number(row.video_playbacks_95);
      } else if (row.columnaCompare === "video_playbacks_75") {
        valueToGet = 100 * new Number(row.video_playbacks_75);
      } else if (row.columnaCompare === "video_playbacks_50") {
        valueToGet = 100 * new Number(row.video_playbacks_50);
      } else if (row.columnaCompare === "video_playbacks_25") {
        valueToGet = 100 * new Number(row.video_playbacks_25);
      } else if (row.columnaCompare === "views") {
        valueToGet = 100 * new Number(row.views);
      } else if (row.columnaCompare === "reach") {
        valueToGet = 100 * new Number(row.reach);
      } else if (row.columnaCompare === "fans") {
        valueToGet = 100 * new Number(row.fans);
      } else if (row.columnaCompare === "clicks") {
        valueToGet = 100 * new Number(row.clicks);
      } else if (row.columnaCompare === "prints") {
        valueToGet = 100 * new Number(row.prints);
      } else if (row.columnaCompare === "frequency") {
        valueToGet = 100 * new Number(row.frequency);
      } else if (row.columnaCompare === "estimated_ad_recallers") {
        valueToGet = 100 * new Number(row.estimated_ad_recallers);
      } else if (row.columnaCompare === "mobile_app_install") {
        valueToGet = 100 * new Number(row.installs);
      } else if (row.columnaCompare === "event_responses") {
        valueToGet = 100 * new Number(row.event_responses);
      } else if (row.columnaCompare === "leads") {
        valueToGet = 100 * new Number(row.leads);
      } else if (row.columnaCompare === "photo_view") {
        valueToGet = 100 * new Number(row.photo);
      }

      if (valueToGet > 0) {
        let final = valueToGet / row.meta;
        return final;
      } else if (valueToGet === 0) {
        return 0;
      } else {
        return "NA";
      }
    } else {
      return "NA";
    }
  };

  const getPercentExport = (row) => {
    if (row != undefined) {
      if (row.meta > 0) {
        let valueToGet;
        if (row.columnaCompare === "post_likes") {
          valueToGet = 100 * new Number(row.post_likes);
        } else if (row.columnaCompare === "page_comments") {
          valueToGet = 100 * new Number(row.postComments);
        } else if (row.columnaCompare === "page_shares") {
          valueToGet = 100 * new Number(row.postShares);
        } else if (row.columnaCompare === "video_avg_time") {
          valueToGet = 100 * new Number(row.adv);
        } else if (row.columnaCompare === "video_playbacks_100") {
          valueToGet = 100 * new Number(row.video_playbacks_100);
        } else if (row.columnaCompare === "video_playbacks_95") {
          valueToGet = 100 * new Number(row.video_playbacks_95);
        } else if (row.columnaCompare === "video_playbacks_75") {
          valueToGet = 100 * new Number(row.video_playbacks_75);
        } else if (row.columnaCompare === "video_playbacks_50") {
          valueToGet = 100 * new Number(row.video_playbacks_50);
        } else if (row.columnaCompare === "video_playbacks_25") {
          valueToGet = 100 * new Number(row.video_playbacks_25);
        } else if (row.columnaCompare === "views") {
          valueToGet = 100 * new Number(row.views);
        } else if (row.columnaCompare === "reach") {
          valueToGet = 100 * new Number(row.reach);
        } else if (row.columnaCompare === "fans") {
          valueToGet = 100 * new Number(row.fans);
        } else if (row.columnaCompare === "clicks") {
          valueToGet = 100 * new Number(row.clicks);
        } else if (row.columnaCompare === "prints") {
          valueToGet = 100 * new Number(row.prints);
        } else if (row.columnaCompare === "frequency") {
          valueToGet = 100 * new Number(row.frequency);
        } else if (row.columnaCompare === "estimated_ad_recallers") {
          valueToGet = 100 * new Number(row.estimated_ad_recallers);
        } else if (row.columnaCompare === "mobile_app_install") {
          valueToGet = 100 * new Number(row.installs);
        } else if (row.columnaCompare === "event_responses") {
          valueToGet = 100 * new Number(row.event_responses);
        } else if (row.columnaCompare === "leads") {
          valueToGet = 100 * new Number(row.leads);
        } else if (row.columnaCompare === "photo_view") {
          valueToGet = 100 * new Number(row.photo);
        }

        if (valueToGet > 0) {
          let final = valueToGet / row.meta;
          return row.meta * (final / 100) + "/" + final + "%";
        } else if (valueToGet === 0) {
          return "0%";
        } else {
          return "NA";
        }
      } else {
        return "NA";
      }
    } else {
      return "NA";
    }
  };

  const getTotalPercentage = (value) => {
    let valueSplitter = value.split("%");
    return valueSplitter[0] + "%";
  };
  const exportDataToExcel = () => {
    let headers = [];
    let dataExcel = [];
    let selectors = [];
    let excelData = [];
    let columnsId = [];
    var filteredColumns = columns.filter(function (col) {
      return col.omit === false;
    });

    filteredColumns.forEach((element) => {
      headers.push(element.name);
      selectors.push(element.tag);
    });

    dataExcel.push(headers);
    //Este for es para data
    if (searchFilterData.length > 0) {
      searchFilterData?.forEach((element) => {
        excelData = [];
        for (let k = 0; k < selectors.length; k++) {
          excelData.push(
            selectors[k] == "budgetI"
              ? "$ " + element[selectors[k]]
              : selectors[k] == "budget"
                ? "$ " +
                (element.budgetNeto ? element.budgetNeto : element.net_budget)
                : selectors[k] == "budgetConsumido"
                  ? "$ " + element[selectors[k]]
                  : selectors[k] == "swc"
                    ? "$ " + element[selectors[k]]
                    : selectors[k] == "budgetSinConsumir"
                      ? "$ " + element[selectors[k]]
                      : selectors[k] == "spent_percent"
                        ? element[selectors[k]] + " %"
                        : selectors[k] == "ctr"
                          ? element[selectors[k]] + " %"
                          : selectors[k] == "cpl"
                            ? "$ " + element[selectors[k]]
                            : selectors[k] == "cpi"
                              ? "$ " + element[selectors[k]]
                              : selectors[k] == "cpr"
                                ? "$ " + element[selectors[k]]
                                : selectors[k] == "cpa"
                                  ? "$ 3:" + element[selectors[k]]
                                  : selectors[k] == "cpc"
                                    ? "$ " + element[selectors[k]]
                                    : selectors[k] == "cpv"
                                      ? "$ 3:" + element[selectors[k]]
                                      : selectors[k] == "cpm"
                                        ? "$ " + element[selectors[k]]
                                        : selectors[k] == "cpf"
                                          ? "$ " + element[selectors[k]]
                                          : selectors[k] == "cprm"
                                            ? "$ 3:" + element[selectors[k]]
                                            : selectors[k] == "meta"
                                              ? element[selectors[k]]
                                              : selectors[k] == "columnaCompare"
                                                ? getPercentExport(
                                                  element[selectors[k]],
                                                )
                                                : selectors[k] ==
                                                  "estimated_ad_recallers"
                                                  ? element[selectors[k]]
                                                  : selectors[k] ==
                                                    "segmentation"
                                                    ? audienceComponent1(
                                                      element.idAudience,
                                                    )
                                                    : selectors[k] == "fechaIni"
                                                      ? element[
                                                        "fechaIni"
                                                      ].replace(/\./g, "/") +
                                                      " " +
                                                      element[
                                                        "fechafin"
                                                      ].replace(/\./g, "/")
                                                      : element[selectors[k]],
          );
        }
        dataExcel.push(excelData);
      });
    } else if (searchData.length > 0) {
      searchData?.forEach((element) => {
        excelData = [];
        for (let k = 0; k < selectors.length; k++) {
          excelData.push(
            selectors[k] == "budgetI"
              ? "$ " + element[selectors[k]]
              : selectors[k] == "budget"
                ? "$ " +
                (element.budgetNeto ? element.budgetNeto : element.net_budget)
                : selectors[k] == "budgetConsumido"
                  ? "$ " + element[selectors[k]]
                  : selectors[k] == "swc"
                    ? "$ " + element[selectors[k]]
                    : selectors[k] == "budgetSinConsumir"
                      ? "$ " + element[selectors[k]]
                      : selectors[k] == "spent_percent"
                        ? element[selectors[k]] + " %"
                        : selectors[k] == "ctr"
                          ? element[selectors[k]] + " %"
                          : selectors[k] == "cpl"
                            ? "$ " + element[selectors[k]]
                            : selectors[k] == "cpi"
                              ? "$ " + element[selectors[k]]
                              : selectors[k] == "cpr"
                                ? "$ " + element[selectors[k]]
                                : selectors[k] == "cpa"
                                  ? "$ 3:" + element[selectors[k]]
                                  : selectors[k] == "cpc"
                                    ? "$ " + element[selectors[k]]
                                    : selectors[k] == "cpv"
                                      ? "$ 3:" + element[selectors[k]]
                                      : selectors[k] == "cpm"
                                        ? "$ " + element[selectors[k]]
                                        : selectors[k] == "cpf"
                                          ? "$ " + element[selectors[k]]
                                          : selectors[k] == "cprm"
                                            ? "$ 3:" + element[selectors[k]]
                                            : selectors[k] == "meta"
                                              ? element[selectors[k]]
                                              : selectors[k] == "columnaCompare"
                                                ? getPercentExport(
                                                  element[selectors[k]],
                                                )
                                                : selectors[k] == "segmentation"
                                                  ? audienceComponent1(
                                                    element.idAudience,
                                                  )
                                                  : selectors[k] ==
                                                    "estimated_ad_recallers"
                                                    ? element[selectors[k]]
                                                    : selectors[k] == "fechaIni"
                                                      ? element[
                                                        "fechaIni"
                                                      ].replace(/\./g, "/") +
                                                      " " +
                                                      element[
                                                        "fechafin"
                                                      ].replace(/\./g, "/")
                                                      : element[selectors[k]],
          );
        }
        dataExcel.push(excelData);
      });
    } else if (filteredData.length > 0) {
      filteredData?.forEach((element) => {
        excelData = [];
        for (let k = 0; k < selectors.length; k++) {
          excelData.push(
            selectors[k] == "budgetI"
              ? "$ " + element[selectors[k]]
              : selectors[k] == "budget"
                ? "$ " +
                (element.budgetNeto ? element.budgetNeto : element.net_budget)
                : selectors[k] == "budgetConsumido"
                  ? "$ " + element[selectors[k]]
                  : selectors[k] == "swc"
                    ? "$ " + element[selectors[k]]
                    : selectors[k] == "budgetSinConsumir"
                      ? "$ " + element[selectors[k]]
                      : selectors[k] == "spent_percent"
                        ? element[selectors[k]] + " %"
                        : selectors[k] == "ctr"
                          ? element[selectors[k]] + " %"
                          : selectors[k] == "cpl"
                            ? "$ " + element[selectors[k]]
                            : selectors[k] == "cpi"
                              ? "$ " + element[selectors[k]]
                              : selectors[k] == "cpr"
                                ? "$ " + element[selectors[k]]
                                : selectors[k] == "cpa"
                                  ? "$ 3:" + element[selectors[k]]
                                  : selectors[k] == "cpc"
                                    ? "$ " + element[selectors[k]]
                                    : selectors[k] == "cpv"
                                      ? "$ 3:" + element[selectors[k]]
                                      : selectors[k] == "cpm"
                                        ? "$ " + element[selectors[k]]
                                        : selectors[k] == "cpf"
                                          ? "$ " + element[selectors[k]]
                                          : selectors[k] == "cprm"
                                            ? "$ 3:" + element[selectors[k]]
                                            : selectors[k] == "meta"
                                              ? element[selectors[k]]
                                              : selectors[k] == "columnaCompare"
                                                ? getPercentExport(element)
                                                : selectors[k] ==
                                                  "estimated_ad_recallers"
                                                  ? element[selectors[k]]
                                                  : selectors[k] ==
                                                    "segmentation"
                                                    ? audienceComponent1(
                                                      element.idAudience,
                                                    )
                                                    : selectors[k] == "fechaIni"
                                                      ? element[
                                                        "fechaIni"
                                                      ].replace(/\./g, "/") +
                                                      " " +
                                                      element[
                                                        "fechafin"
                                                      ].replace(/\./g, "/")
                                                      : element[selectors[k]],
          );
        }

        dataExcel.push(excelData);
      });
    }

    //Este for es para totales
    totalRow?.forEach((element) => {
      excelData = [];
      for (let k = 0; k < selectors.length; k++) {
        excelData.push(
          selectors[k] == "budgetI"
            ? "$ " + element[selectors[k]]
            : selectors[k] == "budget"
              ? "$ " + element.budgetNeto
              : selectors[k] == "installs"
                ? element.installs
                : selectors[k] == "budgetConsumido"
                  ? "$ " + element[selectors[k]]
                  : selectors[k] == "swc"
                    ? "$ " + element[selectors[k]]
                    : selectors[k] == "budgetSinConsumir"
                      ? "$ " + element[selectors[k]]
                      : selectors[k] == "spent_percent"
                        ? element[selectors[k]] + " %"
                        : selectors[k] == "ctr"
                          ? element[selectors[k]] + " %"
                          : selectors[k] == "cpl"
                            ? "$ " + element[selectors[k]]
                            : selectors[k] == "cpi"
                              ? "$ " + element[selectors[k]]
                              : selectors[k] == "cpr"
                                ? "$ " + element[selectors[k]]
                                : selectors[k] == "cpa"
                                  ? "$ 3:" + element[selectors[k]]
                                  : selectors[k] == "cpc"
                                    ? "$ " + element[selectors[k]]
                                    : selectors[k] == "cpv"
                                      ? "$ 3:" + element[selectors[k]]
                                      : selectors[k] == "cpm"
                                        ? "$ " + element[selectors[k]]
                                        : selectors[k] == "cpf"
                                          ? "$ " + element[selectors[k]]
                                          : selectors[k] == "cprm"
                                            ? "$ 3:" + element[selectors[k]]
                                            : selectors[k] == "meta"
                                              ? element["metaTotales"]
                                              : selectors[k] == "columnaCompare"
                                                ? getTotalPercentage(
                                                  element["totalPercentage"],
                                                )
                                                : selectors[k] ==
                                                  "estimated_ad_recallers"
                                                  ? element[selectors[k]]
                                                  : element[selectors[k]],
        );
      }
      dataExcel.push(excelData);
    });

    return dataExcel;
  };

  const DetailCol = ({ row }) => {
    if (permissionsBar.edit === true) {
    }
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <div
          className={classnames("catEditFind catEditFind2", {
            "icons-2": row.status !== status.CREADO.id,
            "icons-3":
              row.status === status.CREADO.id ||
              row.status === status.PAUSADO.id,
            "icons-4":
              row.status === status.CREADO.id ||
              row.status === status.PAUSADO.id,
          })}
        >
          {row.status === status.PAUSADO.id ||
            (row.status === status.CREADO.id && (
              <Tooltip title="Aprobar" placement="top" color="#002448">
                <i
                  //onClick={(e) => showApproveConfirmation(row.idMixChange)}
                  onClick={(e) => showApproveConfirmation(row)}
                  className="fas fa-check"
                />
              </Tooltip>
            ))}
        </div>
        <p className="name-fc">{row.name}</p>
      </div>
    );
  };

  const getFecha = (fecha) => {
    let fechaIni = new Date(fecha);
    fechaIni = fechaIni.toLocaleString();
    fechaIni = fechaIni.toString().split(",");
    fechaIni = fechaIni[0];
    fechaIni = fechaIni.toString().replace("/", ".");
    fechaIni = fechaIni.toString().replace("/", ".");
    return fechaIni;
  };

  const changeAudiencia = (value) => {
    let options = value[0].column;
    let valueNew = value[0].value;
    let idx = options.findIndex((value) => value === valueNew);
    let opcionesAud = props.audiences;
    editData([
      {
        id: value[0].id,
        column: "publico",
        value: opcionesAud[idx].id,
        tagss: opcionesAud[idx].tags,
      },
    ]);
  };

  const editData = async (value) => {
    let name = value[0].column;
    let id = value[0].id;
    let valueData = value[0].value;
    let prevData = [];
    setFiltered((prev) => {
      prevData = [...prev];

      return prev;
    });
    let campaignSearch = prevData.filter((ele) => ele.id === id);
    setLoad(true);
    const bodyCampaign = {
      id: id,
      name: name !== "name" ? campaignSearch[0].name : valueData,
      medio: name !== "id_medium" ? campaignSearch[0].medio : valueData,
      objetivo: name !== "objetivo" ? campaignSearch[0].objetivo : valueData,
      status: 14,
      fechaIni: name !== "year" ? campaignSearch[0].fechaIni : value[0].start,
      fechafin: name !== "year" ? campaignSearch[0].fechaFin : value[0].end,
      budget: name !== "budget" ? campaignSearch[0].budget : valueData,
      publico:
        name !== "publico" ? campaignSearch[0].publico : value[0].tagss.data,
      id_medium:
        name !== "id_medium"
          ? campaignSearch[0].id_medium
          : value[0].bod.id_medium,
      medioo: name !== "id_medium" ? campaignSearch[0].medioo : valueData,
      id_ad_product:
        name !== "banner"
          ? campaignSearch[0].id_ad_product
          : value[0].id_ad_product,
      banner: name !== "banner" ? campaignSearch[0].banner : valueData,
      idBudget: name !== "idBudget" ? campaignSearch[0].idBudget : valueData,
      account: name !== "account" ? campaignSearch[0].account : valueData,
      observation:
        name !== "observation" ? campaignSearch[0].observation : valueData,
      copy: name !== "copy" ? campaignSearch[0].copy : valueData,
      id_region:
        name !== "name_region"
          ? campaignSearch[0].id_region
          : value[0].id_region,
      name_region:
        name !== "name_region" ? campaignSearch[0].name_region : valueData,
      version: name !== "version" ? campaignSearch[0].version : valueData,
      categoriesSelected:
        name !== "categoriesSelected"
          ? campaignSearch[0].categoriesSelected
          : valueData,
      creativity:
        name !== "creativity" ? campaignSearch[0].creativity : valueData,
      id_sale:
        name !== "id_sale" ? campaignSearch[0].id_sale : valueData.toString(),
      idAudience: name !== "publico" ? campaignSearch[0].idAudience : valueData,
      UserOutside: true,
    };
    API.createCampaign(bodyCampaign)
      .then(async () => {
        Swal.fire({
          title: "Campaña editada!",
          text: "Su campaña fue editada exitosamente.",
          icon: "success",
          showCancelButton: false,
          confirmButtonColor: "#3085d6",
          confirmButtonText: "Ok",
        });
        if (name === "name_region") {
          medio(value[0].bod, "medioPau", value[0].data);
        } else if (name === "id_medium") {
          medio(value[0].bod, "formato", value[0].data);
        } else if (name === "budget") {
          const now = Date.now();

          let bodyBudget = {
            id: campaignSearch[0].idBudget,
            tags: [],
            title: "Presupuesto_" + campaignSearch[0].name,
            amount: valueData,
            status: 1,
            account: campaignSearch[0].account,
            available: "0",
            createAt: now,
            updateAt: now,
            padre: "",
            nombrePadre: "",
          };
          await APILA.createBudget(bodyBudget);
          refreshData();
        } else {
          refreshData();
        }

        setLoad(false);
      })
      .catch((err) => {
        console.log("El error encontrado", err);
        Swal.fire({
          title: "Ups!",
          text: "Hubo un error inesperado.",
          icon: "error",
          showCancelButton: false,
          confirmButtonColor: "#3085d6",
          confirmButtonText: "Ok",
        });
      });
    setLoad(false);
  };

  const mediumChange = async (value) => {
    let value2 = value[0].id.split(",");
    let mediumFIlter = value[0].column.filter(
      (ele) => ele.site === value[0].value,
    );
    if (value) {
      value[0].id = value2[1];
      let bod = {
        id_medium: mediumFIlter[0].id_medium,
        id_region: Number(value2[0]),
      };
      await editData([
        {
          data: value,
          column: "id_medium",
          id: value[0].id,
          bod: bod,
          value: value[0].value,
        },
      ]);
    }
  };
  const bannerChange = async (value) => {
    let id_ad_product = value[0].column.filter(
      (ele) => ele.banner === value[0].value,
    );
    id_ad_product = id_ad_product[0].id_ad_product;

    await editData([
      {
        id: value[0].id,
        value: value[0].value,
        column: "banner",
        id_ad_product: id_ad_product,
      },
    ]);
  };

  const getStatusLabel = (mStatus) => {
    switch (mStatus) {
      case status.CREADO.id:
        return status.CREADO.label;

      case status.INACTIVO.id:
        return status.INACTIVO.label;

      case status.ELIMINADO.id:
        return status.ELIMINADO.label;

      case status.MIX_APROBADO.id:
        return status.MIX_APROBADO.label;

      case status.EN_REVISION.id:
        return status.EN_REVISION.label;

      case status.APROBADO.id:
        return status.APROBADO.label;

      case status.MODIFICADO.id:
        return status.MODIFICADO.label;

      case status.EN_IMPLEMENTACION.id:
        return status.EN_IMPLEMENTACION.label;
      case status.ACTIVO.id:
        return status.ACTIVO.label;

      case status.PENDIENTE.id:
        return status.PENDIENTE.label;

      case status.FINALIZADO.id:
        return status.FINALIZADO.label;

      case status.FINALIZADO_SIN_META.id:
        return status.FINALIZADO_SIN_META.label;

      case status.PAUSADO.id:
        return status.PAUSADO.label;
      case status.PAUSADO2.id:
        return status.PAUSADO2.label;
      case status.RECHAZADO.id:
        return status.RECHAZADO.label;

      case status.NO_IMPLEMENTADO.id:
        return status.NO_IMPLEMENTADO.label;

      case status.FINALIZADO_SIN_REPORTE.id:
        return status.FINALIZADO_SIN_REPORTE.label;
      default:
        return "Totales";
    }
  };

  const getStatusClassName = (mStatus) => {
    switch (mStatus) {
      case status.CREADO.id:
        return status.CREADO.class;

      case status.INACTIVO.id:
        return status.INACTIVO.class;

      case status.ELIMINADO.id:
        return status.ELIMINADO.class;

      case status.MIX_APROBADO.id:
        return status.MIX_APROBADO.class;

      case status.EN_REVISION.id:
        return status.EN_REVISION.class;

      case status.APROBADO.id:
        return status.APROBADO.class;

      case status.MODIFICADO.id:
        return status.MODIFICADO.class;

      case status.EN_IMPLEMENTACION.id:
        return status.EN_IMPLEMENTACION.class;
      case status.ACTIVO.id:
        return status.ACTIVO.class;

      case status.PENDIENTE.id:
        return status.PENDIENTE.class;

      case status.FINALIZADO.id:
        return status.FINALIZADO.class;

      case status.FINALIZADO_SIN_META.id:
        return status.FINALIZADO_SIN_META.class;

      case status.PAUSADO.id:
        return status.PAUSADO.class;

      case status.RECHAZADO.id:
        return status.RECHAZADO.class;

      case status.NO_IMPLEMENTADO.id:
        return status.NO_IMPLEMENTADO.class;

      case status.FINALIZADO_SIN_REPORTE.id: //este desaparece
        return status.FINALIZADO_SIN_REPORTE.class;
      default:
        return status.EN_IMPLEMENTACION.class;
    }
  };

  const Medium = ({ id, label }) => {
    switch (id) {
      case 99:
        return <img src={facebook} width="25px" />;
      case 304:
        return null;
      case 439:
        return null;
      case 444:
        return null;
      case 454:
        return <img src={facebook} width="25px" />;
      case 130:
        return null;
      case 133:
        return <img src={google} width="25px" />;
      case 328:
        return <img src={youtube} width="25px" />;
      case 340:
        return <img src={linkedin} width="25px" />;
      case 397:
        return null;
      case 440:
        return null;
      case 453:
        return <img src={instagram} width="25px" />;
      default:
        return label;
    }
  };

  const [filterText, setFilterText] = useState("");

  const [filters, setFilters] = useState({});

  const [state, setState] = useState({
    data: 1,
    filter: "",
  });
  const [searchData, setSearchData] = useState([]);
  const changeView = () => {
    let select = document.getElementById("viewSelect");
    setState((prev) => ({
      ...prev,
      data: select.value,
    }));
  };

  function numberWithCommas(x) {
    let auxNumber = new Number(x).toFixed(2);
    return auxNumber.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  function numberWithCommasThree(x) {
    let auxNumber = new Number(x).toFixed(3);
    return auxNumber.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  function numberWithCommasTwoo(x) {
    let auxNumber = new Number(x).toString();
    return auxNumber.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  const medio = async (bod, caso, value) => {
    let medio = await API.getMediumBanner(bod);
    if (caso === "formato") {
      setMedioCh(medio.data.data);
    }
    setLoad(true);
    switch (caso) {
      case "medioPau":
        let names = [];
        medio.data.data.forEach((ele) => {
          names.push(ele.site);
        });
        setFiltered((prevData) => {
          const updateData = [...prevData];
          let index2 = prevData.findIndex((col) => col.id === value[0].id);
          updateData[index2] = {
            ...updateData[index2],
            name_region: value[0].value,
          };
          updateData[index2] = { ...updateData[index2], options: names };
          updateData[index2] = { ...updateData[index2], status: 14 };
          updateData[index2] = {
            ...updateData[index2],
            optionsMedium: medio.data.data,
          };
          updateData[index2] = {
            ...updateData[index2],
            id_region: value[0].column,
          };
          return updateData;
        });

        setLoad(false);

        break;
      case "formato":
        let nombres = [];
        medio.data.data.forEach((ele) => {
          nombres.push(ele.banner);
        });
        setFiltered((prev) => {
          const upsertData = [...prev];

          let index = prev.findIndex((col) => col.id === value[0].id);
          upsertData[index] = { ...upsertData[index], optionsBanner: nombres };
          upsertData[index] = { ...upsertData[index], medio: value[0].value };
          upsertData[index] = {
            ...upsertData[index],
            optionsB: medio.data.data,
          };
          return upsertData;
        });
        setLoad(false);
        break;
    }
    // props._showLoader(false)
  };

  const handleChangeCountry = async (value) => {
    let name = value[0].value;
    let countriesFIlter = props.countriesPwa.filter((ele) => ele.name === name);
    value[0].column = countriesFIlter[0].id_region;
    if (value) {
      let bod = {
        id_region: countriesFIlter[0].id_region,
        bandera: 1,
      };
      await editData([
        {
          data: value,
          column: "name_region",
          id: value[0].id,
          value: value[0].value,
          id_region: value[0].column,
          bod: bod,
        },
      ]);
    }
  };

  const showApproveConfirmation = async (campaignupd) => {
    var titulo = "¿Aprobar Pauta?";
    var message = `¿Está seguro que desea aprobar esta pauta?`;
    props._showConfirmationModal(titulo, message, async () => {
      try {
        setLoad(true);
        await API.updateCampaingStatus({ id: campaignupd.id, status: 5 });
        await props.refreshDataDynamo();
      } catch (error) {
        console.log("Hubo un error pwa", { error });
      } finally {
        setLoad(false);
      }
    });
  };

  const getAudiences = async (acc) => {
    setAudiences(props.audiences);
  };

  const showDeleteConfirmation = async (campaignupd) => {
    try {
      setLoad(true);
      Swal.fire({
        title: "",
        text: "Estas seguro de que deseas elminar la campaña?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Eliminar",
        cancelButtonText: "Cancelar",
      }).then(async (result) => {
        if (result.isConfirmed) {
          analytics.accionesTabla({
            accion: "Eliminar",
            ubicacion: "Dashboard",
          });
          await API.deletePautaLogOut({ id: campaignupd.id }).then(
            async (res2) => {
              let budgetF = props.budgets.filter(
                (ele) => ele.id === campaignupd.idBudget,
              );
              if (budgetF.length > 0) {
                let total =
                  new Number(budgetF[0].available) +
                  new Number(campaignupd.budget);
                const budgetsTransact = [
                  {
                    id: campaignupd.idBudget,
                    quantity: parseFloat(campaignupd.budget),
                    sum: true,
                    idCampaign: campaignupd.id,
                    user: "logout",
                  },
                ];
                await API.budgetTransaction({ budgets: budgetsTransact });
              }
              Swal.fire("Campaña eliminada correctamente", " ", "success");
            },
          );
          await props.refreshDataDynamo();
        }
      });
    } catch (error) {
      console.log("Error delete pauta", { error });
    } finally {
      setLoad(false);
    }
  };

  const intermediaria = (campaignup) => {
    localStorage.setItem("campaignAdd", null);
    // 3,6,12,13,16,17 estados no permitidos para actualizar
    // if (
    //   campaignup.status != 3 &&
    //   campaignup.status != 6 &&
    //   campaignup.status != 12 &&
    //   campaignup.status != 13 &&
    //   campaignup.status != 16 &&
    //   campaignup.status != 17
    // ) {
    //   let fechaIni = campaignup.fechaIni.replace(/\./g, "/");
    //   fechaIni = fechaIni.split("/");
    //   fechaIni = fechaIni[2] + "/" + fechaIni[1] + "/" + fechaIni[0];
    props._funcampaignupd(campaignup);
    setCampEdit(campaignup);
    setShowModal(true);
    // }
  };

  const getData = (fecha) => {
    if (fecha) {
      const fini = fecha.split(".");
      return `${fini[1]}/${fini[0]}/${fini[2]}`;
    } else {
      return new Date();
    }
  };

  const getTotalspent = (id) => {
    let filteredBudget = props.budget_spent.filter((x) => x.ID === id);
    let msgTotal = filteredBudget.reduce(function (prev, cur) {
      return prev + cur.budget_spent;
    }, 0);
    return msgTotal.toFixed(2);
  };

  const handleShowColumnChange = (column, show) => {
    props.utils.log("handleFilterTextChange");
    props.utils.log("column", column);
    props.utils.log("show", show);

    let index = columns.findIndex((col) => col.selector === column);

    let aux = columns.map((col) => col);
    if (aux[index]) {
      aux[index].omit = show;
      setColumns(aux);
      analytics.ShowHide({
        ubicacion: "Dashboard",
      });

      //To export
      let headers = [];
      let dataExcel = [];
      let dataExcel2 = [];
      let dataExcel3 = [];
      let selectors = [];

      var filteredColumns = columns.filter(function (col) {
        return col.omit === false;
      });

      filteredColumns.forEach((element) => {
        headers.push(element.name);
        selectors.push(element.tag);
      });

      dataExcel.push(headers);
      let data = [];
      let data2 = [];
      for (let i = 0; i < filteredData.length; i++) {
        data.push(Object.entries(filteredData[i]));
      }

      for (let i = 0; i < data.length; i++) {
        dataExcel2 = [];
        for (let j = 0; j < data[i].length; j++) {
          for (let k = 0; k < selectors.length; k++) {
            if (selectors[k] == data[i][j][0]) {
              dataExcel2.push(data[i][j][1]);
            }
          }
        }
        dataExcel3.push(dataExcel2);
        dataExcel.push(dataExcel2);
      }
      data2.push(data);
    }
  };
  const name = (name) => {
    setNameView(name);
  };
  const actualizarEstado = async (id, estado) => {
    setLoad(true);
    let body = {
      id: id,
      active: estado,
    };
    await API.updateViewActive(body);
    actualizarViews(false);
    setLoad(false);
  };
  const handleShowView = async (vista, show) => {
    let id = "";
    views.forEach((el) => {
      if (el.active == true) {
        id = el.id;
      }
    });
    if (id != "") actualizarEstado(id, false);
    actualizarEstado(vista, show);

    let dataFinal = [];
    let or = [];
    let na = [];
    let namesActualesColumns = [];
    let namesViews = [];
    columns.forEach((ele) => {
      namesActualesColumns.push(ele.selector);
    });
    views.forEach((ele) => {
      if (ele.id == vista) {
        ele.columns.forEach((elementos) => {
          na.push(elementos.name);
          if (show) {
            namesViews.push(elementos.selector);
            name(ele.name);
            handleShowColumnChange(elementos.selector, elementos.omit);
            if (namesActualesColumns.includes(elementos.selector)) {
              dataFinal.push(elementos);
            }
            // dataFinal.push(elementos)
          } else {
            if (elementos.selector == "ninguno") {
              handleShowColumnChange(elementos.selector, true);
            } else {
              handleShowColumnChange(elementos.selector, false);
            }
            setNameView("Lista");
          }
        });
      }
    });

    if (show) {
      namesActualesColumns.forEach((ele) => {
        if (namesViews.includes(ele)) {
        } else {
          handleShowColumnChange(ele, true);
        }
      });
      dataFinal.sort((a, b) => {
        return a.position - b.position;
      });
      dataFinal.forEach((element) => {
        or.push(element.id);
      });

      if (dataFinal.length > 0) {
        handleColumnSortChange(or);
      }
    }

    if (show == false) {
      let idCol = [
        "ninguno",
        "name",
        "year",
        "id",
        "id_sale",
        "status",
        "country",
        "medio",
        "banner",
        "purchase_unit",
        "meta",
        "columnaCompare",
        "budgetI",
        "budget",
        "budgetConsumido",
        "swc",
        "budgetSpend1",
        "sp_perc",
        "dias_campaña",
        "segmentation",
        "comentarios",
        "ctr",
        "photo",
        "leads",
        "event_responses",
        "installs",
        "estimated_ad_recallers",
        "frequency",
        "calificacion",
        "cpl",
        "cpi",
        "cpre",
        "cpa",
        "cpc",
        "cpv",
        "cpm",
        "cpf",
        "cprm",
        "prints",
        "clicks",
        "fans",
        "reach",
        "views",
        "video_playbacks_25",
        "video_playbacks_50",
        "video_playbacks_75",
        "video_playbacks_95",
        "video_playbacks_100",
        "adv",
        "postShares",
        "postComments",
        "postLikes",
      ];
      columns.forEach((el) => {
        if (!idCol.includes(el.id)) {
          idCol.push(el.id);
        }
        if (el.selector == "ninguno") {
          handleShowColumnChange(el.selector, true);
        } else {
          handleShowColumnChange(el.selector, false);
        }
      });
      handleColumnSortChange(idCol);
    }
  };

  const handleAddView = async (name) => {
    setLoad(true);
    let id = "";
    const apiUrl = "https://api.ipify.org?format=json";
    const responseGeo = await fetch(apiUrl);
    const data = await responseGeo.json();
    const sub = data.ip;

    views.forEach((el) => {
      if (el.active == true) {
        id = el.id;
      }
    });
    setNameView(name);
    let cols2 = columns;
    cols2.map((ele) => {
      ele.name = ele.nameCol;
    });
    if (id != "") actualizarEstado(id, false);
    let body = {
      id: name,
      name: name,
      component: "campaign",
      columns: cols2,
      account: account,
      user: sub,
      active: true,
    };
    await API.insertView(body);
    await actualizarViews(false);
    setLoad(false);
  };

  const handleDeleteView = async (name) => {
    setLoad(true);
    let id = "";
    views.forEach((el) => {
      if (el.active == true) {
        id = el.id;
      }
    });
    if (id != "" && id == name) {
      setNameView("Lista");
      let idCol = [
        "ninguno",
        "name",
        "year",
        "id",
        "id_sale",
        "status",
        "country",
        "medio",
        "banner",
        "purchase_unit",
        "meta",
        "columnaCompare",
        "budgetI",
        "budget",
        "budgetConsumido",
        "swc",
        "budgetSpend1",
        "sp_perc",
        "dias_campaña",
        "segmentation",
        "comentarios",
        "ctr",
        "photo",
        "leads",
        "event_responses",
        "installs",
        "estimated_ad_recallers",
        "frequency",
        "calificacion",
        "cpl",
        "cpi",
        "cpre",
        "cpa",
        "cpc",
        "cpv",
        "cpm",
        "cpf",
        "cprm",
        "prints",
        "clicks",
        "fans",
        "reach",
        "views",
        "video_playbacks_25",
        "video_playbacks_50",
        "video_playbacks_75",
        "video_playbacks_95",
        "video_playbacks_100",
        "adv",
        "postShares",
        "postComments",
        "postLikes",
      ];
      columns.forEach((el) => {
        if (el.id == "ninguno") {
          handleShowColumnChange(el.selector, true);
        } else {
          handleShowColumnChange(el.selector, false);
        }
        if (!idCol.includes(el.id)) {
          idCol.push(el.id);
        }
      });
      handleColumnSortChange(idCol);
    }
    await API.deleteView({ id: name });
    actualizarViews(false);
    setLoad(false);
  };
  const handleColumnSortChange = (positions) => {
    let aux = columns.map((col) => col);
    positions.forEach((columnId, position) => {
      let index = columns.findIndex((col) => col.id === columnId);
      if (index != -1) {
        props.utils.log("Index", index);
        aux[index].position = position;
      }
    });

    aux = _.orderBy(aux, ["position"]);
    props.utils.log("new columns", aux);
    analytics.Columnas({
      ubicacion: "Dashboard",
    });
    setColumns(aux);
  };

  const handleFilterPerColumn = async (value) => {
    if (value.length > 0) {
      setLoad(true);
      analytics.filtro({
        ubicacion: "Dashboard",
      });
      setFilterInfo([...value]);
      let dataToFilterr = [];
      if (searchData.length > 0) {
        dataToFilterr = searchData;
      } else {
        if (filtered.length > 0) {
          dataToFilterr = filtered;
        }
      }
      if (dataToFilterr.length > 0) {
        let dataToFilter = dataToFilterr;
        let dataFinal = [];
        let contStatus = 0;
        let contCountry = 0;
        let contMedio = 0;
        let contBanner = 0;
        let contPurchase = 0;
        let contCategorie = 0;
        value.forEach(async (el) => {
          if (el.column === "Estado") {
            let dataAlt = dataToFilterr.filter(
              (ele) => ele.searchable_status === el.value,
            );
            if (dataAlt.length > 0) {
              dataFinal = [...dataFinal, ...dataAlt];
            }
          }
          if (el.column === "Pais") {
            let dataAlt = dataToFilterr.filter(
              (ele) => ele.country === el.value,
            );
            if (dataAlt.length > 0) {
              dataFinal = [...dataFinal, ...dataAlt];
            }
          }
          if (el.column === "Medio") {
            let dataAlt = dataToFilterr.filter((ele) => ele.medio === el.value);
            if (dataAlt.length > 0) {
              dataFinal = [...dataFinal, ...dataAlt];
            }
          }
          if (el.column === "Banner") {
            let dataAlt = dataToFilterr.filter(
              (ele) => ele.banner === el.value,
            );
            if (dataAlt.length > 0) {
              dataFinal = [...dataFinal, ...dataAlt];
            }
          }
          if (el.column === "purchase_unit") {
            let dataAlt = dataToFilterr.filter(
              (ele) => ele.purchase_unit === el.value,
            );
            if (dataAlt.length > 0) {
              dataFinal = [...dataFinal, ...dataAlt];
            }
          }
          if (el.column === "ID") {
            let searchArray = [];
            const mAccount = _.find(props.accounts, { id: props.account });
            let idclient = mAccount?.eAdvertising;
            if (el.value.includes(",")) {
              const myArray = el.value.split(",");
              for (let i = 0; i < myArray.length; i++) {
                if (new Number(myArray[i]) > 0) {
                  let res = await API.findCampaignByMixDetail({
                    idclient: idclient,
                    idMixDetaill: myArray[i],
                  });
                  if (res.data.count > 0) {
                    let elementToPush;
                    let response = await API.getCampaignById(
                      res.data.campaigns[0].id_mix_detail,
                    );
                    let element = res.data.campaigns[0];
                    let arrayAux = filtered;
                    if (response.data.campaigns.length > 0) {
                      let found = response.data.campaigns[0];
                      let body = {
                        editable: true,
                        scr: element.calificacion,
                        fechaIni: element.fecha_inicio,
                        ctr: element.ctr?.toFixed(2),
                        cpr: element.cpr?.toFixed(2),
                        status: element.idStatus,
                        video_playbacks_75: element.reproducciones_video_75,
                        cpv: element.cpv?.toFixed(3),
                        orden_venta: element.orden_venta,
                        id_sale: element.orden_venta,
                        id_region: found.id_region,
                        name_region: found.name_region,
                        objetivo: found.objetivo,
                        segmentacion: found.publico
                          ? found.publico
                          : element.segmentacion,
                        dias_campaña: element.dias > 0 ? element.dias : 0,
                        comentarios: element.comentarios,
                        calificacion: element.calificacion,
                        //"fr": 6927,
                        searchable_status: element.status,
                        idBudget: found.idBudget,
                        searchable_fechafin: found.searchable_fechafin,
                        adv: element.duracion_promedio_video
                          ? element.duracion_promedio_video
                          : "00:00:00",
                        searchable_medio: found.searchable_medio,
                        id_ad_product: found.id_ad_product,
                        postNumber: found.postNumber,
                        net_budget: element.presupuesto_neto,
                        // "real_price": 0.47,
                        searchable_idMixDetaill: found.id,
                        searchable_name: found.name,
                        reach: element.reach,
                        account: found.account,
                        event_responses: element.respuesta_eventos,
                        postShares: element.post_shares,
                        photo: element.visualizacion_fotos,
                        video_playbacks_50: element.reproducciones_video_50,
                        video_playbacks_100: element.reproducciones_video_100,
                        spent_percent:
                          element.porcentaje_gastado > 0
                            ? element.porcentaje_gastado?.toFixed(2)
                            : "0.00",

                        budget: found.budget ?? found.budget.toFixed(2), // presupuesto neto
                        budgetNeto: element.presupuesto_neto,
                        budgetI:
                          element.presupuesto ?? element.presupuesto.toFixed(2),
                        banner: found.banner,
                        fechafin: element.fecha_fin,
                        id_medium: found.id_medium,
                        idMixDetaill: found.idMixDetaill,
                        // "cnr": 0,
                        searchable_fechaIni: found.searchable_fechaIni,
                        idMix: found.idMix,
                        video_playbacks_95: element.reproducciones_video_95,
                        url: element.url,
                        name: element.post,
                        idCampaign: found.idCampaign,
                        searchable_version: found.searchable_version,
                        clicks: element.clicks,
                        purchase_unit: element.tipo_compra,
                        medioo: found.medioo,
                        prints: element.impresiones,
                        id: found.id,

                        idMySql: element.id,
                        publico: found.publico,
                        swc: element.gastado_con_comision.toFixed(2),
                        segmentation: element.segmentacion,
                        // "age": "+18",
                        leads: element.leads,
                        version: found.version,
                        available: element.presupuesto_disponible,
                        budgetSinConsumir:
                          element.presupuesto_disponible?.toFixed(2),
                        postComments: element.post_comments,
                        installs: element.instalacion_aplicaciones,
                        frequency: element.frecuencia,
                        modification_date: found.modification_date,
                        views: element.views,
                        // "daily_budget": 0,
                        video_playbacks_25: element.reproducciones_video_25,
                        cpa: element.cpa?.toFixed(3),
                        cpc: element.cpc?.toFixed(2),
                        client: element.cliente,
                        post_likes: element.post_likes,
                        medio: found.medio,
                        idMixChange: found.idMixChange,
                        cpf: element.cpf?.toFixed(2),
                        cpi: element.cpi?.toFixed(2),
                        // "price": 0.5,
                        fans: element.fans,
                        cpm: element.cpm?.toFixed(2),
                        cprm: element.cost_per_estimated_ad_recallers,
                        estimated_ad_recallers: element.estimated_ad_recallers,
                        cpl: element.cpl?.toFixed(2),
                        budgetConsumido:
                          element.presupuesto_gastado?.toFixed(2),
                        budgetI:
                          element.presupuesto ?? element.presupuesto.toFixed(2),
                        budgetSinConsumir:
                          element.presupuesto_disponible?.toFixed(2),
                        availableAmount: found.availableAmount,
                        copy: found.copy,
                        observation: found.observation,
                        idAudience: found.idAudience,
                        nameForEmail: found.nameForEmail,
                        emailForEmail: found.emailForEmail,
                        categoriesSelected: found.categoriesSelected,
                        creativity: found.creativity,
                        country: found.name_region,
                        meta: element.goal,
                        columnaCompare: element.metric,
                      };
                      if (found.categoriesSelected) {
                        var categories = found.categoriesSelected;
                        const categoriesGruped = categories.reduce(
                          (groups, item) => ({
                            ...groups,
                            [item.nombrePadre]: [
                              ...(groups[item.nombrePadre] || []),
                              item,
                            ],
                          }),
                          {},
                        );

                        let valores = Object.values(categoriesGruped);
                        for (let j = 0; j < valores.length; j++) {
                          let catsString = "";
                          for (let k = 0; k < valores[j].length; k++) {
                            catsString =
                              catsString + valores[j][k].label + ", ";
                          }
                          let cats = { [valores[j][0].padre]: catsString };

                          body = { ...body, ...cats };
                        }
                      }
                      searchArray.push(body);
                    } else {
                      let body = {
                        editable: false,
                        name: element.name ? element.name : element.post,
                        fechaIni: element.fecha_inicio,
                        scr: element.calificacion,
                        ctr: element.ctr?.toFixed(2),
                        cpr: element.cpr?.toFixed(2),
                        status: element.idStatus,
                        video_playbacks_75: element.reproducciones_video_75,
                        video_playbacks_100: element.reproducciones_video_100,
                        dias_campaña: element.dias > 0 ? element.dias : 0,
                        cpv: element.cpv?.toFixed(3),
                        orden_venta: element.orden_venta,
                        country: element.pais,
                        segmentacion: element.segmentacion,
                        //"fr": 6927,
                        searchable_status: element.status,
                        idBudget: 0,
                        searchable_fechafin: "",
                        adv: element.duracion_promedio_video
                          ? element.duracion_promedio_video
                          : "00:00:00",
                        searchable_medio: "",
                        id_ad_product: 0,
                        postNumber: "",
                        net_budget: element.presupuesto_neto,
                        // "real_price": 0.47,
                        searchable_idMixDetaill: "",
                        searchable_name: "",
                        reach: element.reach,
                        account: "",
                        event_responses: element.respuesta_eventos,
                        postShares: element.post_shares,
                        photo: element.visualizacion_fotos,
                        video_playbacks_50: element.reproducciones_video_50,
                        spent_percent:
                          element.porcentaje_gastado > 0
                            ? element.porcentaje_gastado?.toFixed(2)
                            : "0.00",
                        budget:
                          element.presupuesto &&
                          new Number(element.presupuesto).toFixed(2),
                        budgetNeto: element.presupuesto_neto,
                        banner: element.banner,
                        fechafin: element.fecha_fin,
                        id_medium: 0,
                        idMixDetaill: element.id_mix_detail,
                        idMixDetaillActual: element.id_mix_detail,
                        // "cnr": 0,
                        searchable_fechaIni: "",
                        idMix: 0,
                        video_playbacks_95: element.reproducciones_video_95,
                        url: element.url,
                        name: element.post,
                        idCampaign: 0,
                        searchable_version: "",
                        clicks: element.clicks,
                        purchase_unit: element.tipo_compra,
                        medioo: element.medioo,
                        prints: element.impresiones,
                        id: element.id,

                        idMySql: element.id,
                        publico: element.segmentacion,
                        swc: element.gastado_con_comision.toFixed(2),
                        segmentation: element.segmentacion,
                        // "age": "+18",
                        leads: element.leads,
                        version: "",
                        budgetSinConsumir:
                          element.presupuesto_disponible?.toFixed(2),
                        available: element.presupuesto_disponible,
                        postComments: element.post_comments,
                        installs: element.instalacion_aplicaciones,
                        frequency: element.frecuencia,
                        modification_date: "",
                        views: element.views,
                        // "daily_budget": 0,
                        video_playbacks_25: element.reproducciones_video_25,
                        cpa: element.cpa?.toFixed(3),
                        cpc: element.cpc?.toFixed(2),
                        client: element.cliente,
                        post_likes: element.post_likes,
                        medio: element.medio,
                        idMixChange: 0,
                        cpf: element.cpf?.toFixed(2),
                        cpi: element.cpi?.toFixed(2),
                        // "price": 0.5,
                        fans: element.fans,
                        cpm: element.cpm?.toFixed(2),
                        cprm: element.cost_per_estimated_ad_recallers,
                        estimated_ad_recallers: element.estimated_ad_recallers,
                        cpl: element.cpl?.toFixed(2),
                        budgetConsumido:
                          element.presupuesto_gastado?.toFixed(2),
                        budgetI: new Number(element.presupuesto).toFixed(2),
                        id_sale: element.orden_venta,
                        meta: element.goal,
                        columnaCompare: element.metric,
                      };
                      searchArray.push(body);
                    }
                  }
                }
              }
              dataFinal = [...dataFinal, ...searchArray];
              let set = new Set(dataFinal.map(JSON.stringify));
              let arrSinDuplicaciones = Array.from(set).map(JSON.parse);
              setSearchFilterData([...arrSinDuplicaciones]);
            } else {
              let searchArray = [];
              const mAccount = _.find(props.accounts, { id: props.account });
              let idclient = mAccount?.eAdvertising;
              let res = await API.findCampaignByMixDetail({
                idclient: idclient,
                idMixDetaill: el.value,
              });
              if (res.data.count > 0) {
                let elementToPush;
                let response = await API.getCampaignById(
                  res.data.campaigns[0].id_mix_detail,
                );
                let element = res.data.campaigns[0];
                let arrayAux = filtered;
                if (response.data.campaigns.length > 0) {
                  let found = response.data.campaigns[0];
                  let body = {
                    editable: true,
                    scr: element.calificacion,
                    fechaIni: element.fecha_inicio,
                    ctr: element.ctr?.toFixed(2),
                    cpr: element.cpr?.toFixed(2),
                    status: element.idStatus,
                    video_playbacks_75: element.reproducciones_video_75,
                    cpv: element.cpv?.toFixed(3),
                    orden_venta: element.orden_venta,
                    id_sale: element.orden_venta,
                    id_region: found.id_region,
                    name_region: found.name_region,
                    objetivo: found.objetivo,
                    segmentacion: found.publico
                      ? found.publico
                      : element.segmentacion,
                    dias_campaña: element.dias > 0 ? element.dias : 0,
                    comentarios: element.comentarios,
                    calificacion: element.calificacion,
                    //"fr": 6927,
                    searchable_status: element.status,
                    idBudget: found.idBudget,
                    searchable_fechafin: found.searchable_fechafin,
                    adv: element.duracion_promedio_video
                      ? element.duracion_promedio_video
                      : "00:00:00",
                    searchable_medio: found.searchable_medio,
                    id_ad_product: found.id_ad_product,
                    postNumber: found.postNumber,
                    net_budget: element.presupuesto_neto,
                    // "real_price": 0.47,
                    searchable_idMixDetaill: found.id,
                    searchable_name: found.name,
                    reach: element.reach,
                    account: found.account,
                    event_responses: element.respuesta_eventos,
                    postShares: element.post_shares,
                    photo: element.visualizacion_fotos,
                    video_playbacks_50: element.reproducciones_video_50,
                    video_playbacks_100: element.reproducciones_video_100,
                    spent_percent:
                      element.porcentaje_gastado > 0
                        ? element.porcentaje_gastado?.toFixed(2)
                        : "0.00",

                    budget: found.budget ?? found.budget.toFixed(2), // presupuesto neto
                    budgetNeto: element.presupuesto_neto,
                    budgetI:
                      element.presupuesto ?? element.presupuesto.toFixed(2),
                    banner: found.banner,
                    fechafin: element.fecha_fin,
                    id_medium: found.id_medium,
                    idMixDetaill: found.idMixDetaill,
                    // "cnr": 0,
                    searchable_fechaIni: found.searchable_fechaIni,
                    idMix: found.idMix,
                    video_playbacks_95: element.reproducciones_video_95,
                    url: element.url,
                    name: element.post,
                    idCampaign: found.idCampaign,
                    searchable_version: found.searchable_version,
                    clicks: element.clicks,
                    purchase_unit: element.tipo_compra,
                    medioo: found.medioo,
                    prints: element.impresiones,
                    id: found.id,

                    idMySql: element.id,
                    publico: found.publico,
                    swc: element.gastado_con_comision.toFixed(2),
                    segmentation: element.segmentacion,
                    // "age": "+18",
                    leads: element.leads,
                    version: found.version,
                    available: element.presupuesto_disponible,
                    budgetSinConsumir:
                      element.presupuesto_disponible?.toFixed(2),
                    postComments: element.post_comments,
                    installs: element.instalacion_aplicaciones,
                    frequency: element.frecuencia,
                    modification_date: found.modification_date,
                    views: element.views,
                    // "daily_budget": 0,
                    video_playbacks_25: element.reproducciones_video_25,
                    cpa: element.cpa?.toFixed(3),
                    cpc: element.cpc?.toFixed(2),
                    client: element.cliente,
                    post_likes: element.post_likes,
                    medio: found.medio,
                    idMixChange: found.idMixChange,
                    cpf: element.cpf?.toFixed(2),
                    cpi: element.cpi?.toFixed(2),
                    // "price": 0.5,
                    fans: element.fans,
                    cpm: element.cpm?.toFixed(2),
                    cprm: element.cost_per_estimated_ad_recallers,
                    estimated_ad_recallers: element.estimated_ad_recallers,
                    cpl: element.cpl?.toFixed(2),
                    budgetConsumido: element.presupuesto_gastado?.toFixed(2),
                    budgetI:
                      element.presupuesto ?? element.presupuesto.toFixed(2),
                    budgetSinConsumir:
                      element.presupuesto_disponible?.toFixed(2),
                    availableAmount: found.availableAmount,
                    copy: found.copy,
                    observation: found.observation,
                    idAudience: found.idAudience,
                    nameForEmail: found.nameForEmail,
                    emailForEmail: found.emailForEmail,
                    categoriesSelected: found.categoriesSelected,
                    creativity: found.creativity,
                    country: found.name_region,
                    meta: element.goal,
                    columnaCompare: element.metric,
                  };
                  if (found.categoriesSelected) {
                    var categories = found.categoriesSelected;
                    const categoriesGruped = categories.reduce(
                      (groups, item) => ({
                        ...groups,
                        [item.nombrePadre]: [
                          ...(groups[item.nombrePadre] || []),
                          item,
                        ],
                      }),
                      {},
                    );

                    let valores = Object.values(categoriesGruped);
                    for (let j = 0; j < valores.length; j++) {
                      let catsString = "";
                      for (let k = 0; k < valores[j].length; k++) {
                        catsString = catsString + valores[j][k].label + ", ";
                      }
                      let cats = { [valores[j][0].padre]: catsString };

                      body = { ...body, ...cats };
                    }
                  }
                  searchArray.push(body);
                } else {
                  let body = {
                    editable: false,
                    name: element.name ? element.name : element.post,
                    fechaIni: element.fecha_inicio,
                    scr: element.calificacion,
                    ctr: element.ctr?.toFixed(2),
                    cpr: element.cpr?.toFixed(2),
                    status: element.idStatus,
                    video_playbacks_75: element.reproducciones_video_75,
                    video_playbacks_100: element.reproducciones_video_100,
                    dias_campaña: element.dias > 0 ? element.dias : 0,
                    cpv: element.cpv?.toFixed(3),
                    orden_venta: element.orden_venta,
                    country: element.pais,
                    segmentacion: element.segmentacion,
                    //"fr": 6927,
                    searchable_status: element.status,
                    idBudget: 0,
                    searchable_fechafin: "",
                    adv: element.duracion_promedio_video
                      ? element.duracion_promedio_video
                      : "00:00:00",
                    searchable_medio: "",
                    id_ad_product: 0,
                    postNumber: "",
                    net_budget: element.presupuesto_neto,
                    // "real_price": 0.47,
                    searchable_idMixDetaill: "",
                    searchable_name: "",
                    reach: element.reach,
                    account: "",
                    event_responses: element.respuesta_eventos,
                    postShares: element.post_shares,
                    photo: element.visualizacion_fotos,
                    video_playbacks_50: element.reproducciones_video_50,
                    spent_percent:
                      element.porcentaje_gastado > 0
                        ? element.porcentaje_gastado?.toFixed(2)
                        : "0.00",
                    budget:
                      element.presupuesto &&
                      new Number(element.presupuesto).toFixed(2),
                    budgetNeto: element.presupuesto_neto,
                    banner: element.banner,
                    fechafin: element.fecha_fin,
                    id_medium: 0,
                    idMixDetaill: element.id_mix_detail,
                    idMixDetaillActual: element.id_mix_detail,
                    // "cnr": 0,
                    searchable_fechaIni: "",
                    idMix: 0,
                    video_playbacks_95: element.reproducciones_video_95,
                    url: element.url,
                    name: element.post,
                    idCampaign: 0,
                    searchable_version: "",
                    clicks: element.clicks,
                    purchase_unit: element.tipo_compra,
                    medioo: element.medioo,
                    prints: element.impresiones,
                    id: element.id,

                    idMySql: element.id,
                    publico: element.segmentacion,
                    swc: element.gastado_con_comision.toFixed(2),
                    segmentation: element.segmentacion,
                    // "age": "+18",
                    leads: element.leads,
                    version: "",
                    budgetSinConsumir:
                      element.presupuesto_disponible?.toFixed(2),
                    available: element.presupuesto_disponible,
                    postComments: element.post_comments,
                    installs: element.instalacion_aplicaciones,
                    frequency: element.frecuencia,
                    modification_date: "",
                    views: element.views,
                    // "daily_budget": 0,
                    video_playbacks_25: element.reproducciones_video_25,
                    cpa: element.cpa?.toFixed(3),
                    cpc: element.cpc?.toFixed(2),
                    client: element.cliente,
                    post_likes: element.post_likes,
                    medio: element.medio,
                    idMixChange: 0,
                    cpf: element.cpf?.toFixed(2),
                    cpi: element.cpi?.toFixed(2),
                    // "price": 0.5,
                    fans: element.fans,
                    cpm: element.cpm?.toFixed(2),
                    cprm: element.cost_per_estimated_ad_recallers,
                    estimated_ad_recallers: element.estimated_ad_recallers,
                    cpl: element.cpl?.toFixed(2),
                    budgetConsumido: element.presupuesto_gastado?.toFixed(2),
                    budgetI: new Number(element.presupuesto).toFixed(2),
                    id_sale: element.orden_venta,
                    meta: element.goal,
                    columnaCompare: element.metric,
                  };
                  searchArray.push(body);
                }
              }
              dataFinal = [...dataFinal, ...searchArray];
              let set = new Set(dataFinal.map(JSON.stringify));
              let arrSinDuplicaciones = Array.from(set).map(JSON.parse);
              setSearchFilterData([...arrSinDuplicaciones]);
            }
          }
          if (el.column === "Orden de Venta") {
            let searchArray = [];
            const mAccount = _.find(props.accounts, { id: props.account });
            let idclient = mAccount?.eAdvertising;
            if (el.value.includes(",")) {
              const myArray = el.value.split(",");
              //getCampaignByIdSale
              //findCampaignByIdSale
              for (let i = 0; i < myArray.length; i++) {
                if (new Number(myArray[i] > 0)) {
                  let res = await API.findCampaignByIdSale({
                    idclient: idclient,
                    id_sale: myArray[i],
                  });
                  if (res.data.count > 0) {
                    let elementToPush;
                    let response = await API.getCampaignByIdSale({
                      idSale: res.data.campaigns[0].orden_venta,
                    });
                    let element = res.data.campaigns[0];
                    if (response.data.campaigns.length > 0) {
                      let found = response.data.campaigns[0];
                      let body = {
                        editable: true,
                        scr: element.calificacion,
                        fechaIni: element.fecha_inicio,
                        ctr: element.ctr?.toFixed(2),
                        cpr: element.cpr?.toFixed(2),
                        status: element.idStatus,
                        video_playbacks_75: element.reproducciones_video_75,
                        cpv: element.cpv?.toFixed(3),
                        orden_venta: element.orden_venta,
                        id_sale: element.orden_venta,
                        id_region: found.id_region,
                        name_region: found.name_region,
                        objetivo: found.objetivo,
                        segmentacion: found.publico
                          ? found.publico
                          : element.segmentacion,
                        dias_campaña: element.dias > 0 ? element.dias : 0,
                        comentarios: element.comentarios,
                        calificacion: element.calificacion,
                        //"fr": 6927,
                        searchable_status: element.status,
                        idBudget: found.idBudget,
                        searchable_fechafin: found.searchable_fechafin,
                        adv: element.duracion_promedio_video
                          ? element.duracion_promedio_video
                          : "00:00:00",
                        searchable_medio: found.searchable_medio,
                        id_ad_product: found.id_ad_product,
                        postNumber: found.postNumber,
                        net_budget: element.presupuesto_neto,
                        // "real_price": 0.47,
                        searchable_idMixDetaill: found.id,
                        searchable_name: found.name,
                        reach: element.reach,
                        account: found.account,
                        event_responses: element.respuesta_eventos,
                        postShares: element.post_shares,
                        photo: element.visualizacion_fotos,
                        video_playbacks_50: element.reproducciones_video_50,
                        video_playbacks_100: element.reproducciones_video_100,
                        spent_percent:
                          element.porcentaje_gastado > 0
                            ? element.porcentaje_gastado?.toFixed(2)
                            : "0.00",

                        budget: found.budget ?? found.budget.toFixed(2), // presupuesto neto
                        budgetNeto: element.presupuesto_neto,
                        budgetI:
                          element.presupuesto ?? element.presupuesto.toFixed(2),
                        banner: found.banner,
                        fechafin: element.fecha_fin,
                        id_medium: found.id_medium,
                        idMixDetaill: found.idMixDetaill,
                        // "cnr": 0,
                        searchable_fechaIni: found.searchable_fechaIni,
                        idMix: found.idMix,
                        video_playbacks_95: element.reproducciones_video_95,
                        url: element.url,
                        name: element.post,
                        idCampaign: found.idCampaign,
                        searchable_version: found.searchable_version,
                        clicks: element.clicks,
                        purchase_unit: element.tipo_compra,
                        medioo: found.medioo,
                        prints: element.impresiones,
                        id: found.id,

                        idMySql: element.id,
                        publico: found.publico,
                        swc: element.gastado_con_comision.toFixed(2),
                        segmentation: element.segmentacion,
                        // "age": "+18",
                        leads: element.leads,
                        version: found.version,
                        available: element.presupuesto_disponible,
                        budgetSinConsumir:
                          element.presupuesto_disponible?.toFixed(2),
                        postComments: element.post_comments,
                        installs: element.instalacion_aplicaciones,
                        frequency: element.frecuencia,
                        modification_date: found.modification_date,
                        views: element.views,
                        // "daily_budget": 0,
                        video_playbacks_25: element.reproducciones_video_25,
                        cpa: element.cpa?.toFixed(3),
                        cpc: element.cpc?.toFixed(2),
                        client: element.cliente,
                        post_likes: element.post_likes,
                        medio: found.medio,
                        idMixChange: found.idMixChange,
                        cpf: element.cpf?.toFixed(2),
                        cpi: element.cpi?.toFixed(2),
                        // "price": 0.5,
                        fans: element.fans,
                        cpm: element.cpm?.toFixed(2),
                        cprm: element.cost_per_estimated_ad_recallers,
                        estimated_ad_recallers: element.estimated_ad_recallers,
                        cpl: element.cpl?.toFixed(2),
                        budgetConsumido:
                          element.presupuesto_gastado?.toFixed(2),
                        budgetI:
                          element.presupuesto ?? element.presupuesto.toFixed(2),
                        budgetSinConsumir:
                          element.presupuesto_disponible?.toFixed(2),
                        availableAmount: found.availableAmount,
                        copy: found.copy,
                        observation: found.observation,
                        idAudience: found.idAudience,
                        nameForEmail: found.nameForEmail,
                        emailForEmail: found.emailForEmail,
                        categoriesSelected: found.categoriesSelected,
                        creativity: found.creativity,
                        country: found.name_region,
                        meta: element.goal,
                        columnaCompare: element.metric,
                      };
                      if (found.categoriesSelected) {
                        var categories = found.categoriesSelected;
                        const categoriesGruped = categories.reduce(
                          (groups, item) => ({
                            ...groups,
                            [item.nombrePadre]: [
                              ...(groups[item.nombrePadre] || []),
                              item,
                            ],
                          }),
                          {},
                        );

                        let valores = Object.values(categoriesGruped);
                        for (let j = 0; j < valores.length; j++) {
                          let catsString = "";
                          for (let k = 0; k < valores[j].length; k++) {
                            catsString =
                              catsString + valores[j][k].label + ", ";
                          }
                          let cats = { [valores[j][0].padre]: catsString };

                          body = { ...body, ...cats };
                        }
                      }
                      searchArray.push(body);
                    } else {
                      let body = {
                        editable: false,
                        name: element.name ? element.name : element.post,
                        fechaIni: element.fecha_inicio,
                        scr: element.calificacion,
                        ctr: element.ctr?.toFixed(2),
                        cpr: element.cpr?.toFixed(2),
                        status: element.idStatus,
                        video_playbacks_75: element.reproducciones_video_75,
                        video_playbacks_100: element.reproducciones_video_100,
                        dias_campaña: element.dias > 0 ? element.dias : 0,
                        cpv: element.cpv?.toFixed(3),
                        orden_venta: element.orden_venta,
                        country: element.pais,
                        segmentacion: element.segmentacion,
                        //"fr": 6927,
                        searchable_status: element.status,
                        idBudget: 0,
                        searchable_fechafin: "",
                        adv: element.duracion_promedio_video
                          ? element.duracion_promedio_video
                          : "00:00:00",
                        searchable_medio: "",
                        id_ad_product: 0,
                        postNumber: "",
                        net_budget: element.presupuesto_neto,
                        // "real_price": 0.47,
                        searchable_idMixDetaill: "",
                        searchable_name: "",
                        reach: element.reach,
                        account: "",
                        event_responses: element.respuesta_eventos,
                        postShares: element.post_shares,
                        photo: element.visualizacion_fotos,
                        video_playbacks_50: element.reproducciones_video_50,
                        spent_percent:
                          element.porcentaje_gastado > 0
                            ? element.porcentaje_gastado?.toFixed(2)
                            : "0.00",
                        budget:
                          element.presupuesto &&
                          new Number(element.presupuesto).toFixed(2),
                        budgetNeto: element.presupuesto_neto,
                        banner: element.banner,
                        fechafin: element.fecha_fin,
                        id_medium: 0,
                        idMixDetaill: element.id_mix_detail,
                        idMixDetaillActual: element.id_mix_detail,
                        // "cnr": 0,
                        searchable_fechaIni: "",
                        idMix: 0,
                        video_playbacks_95: element.reproducciones_video_95,
                        url: element.url,
                        name: element.post,
                        idCampaign: 0,
                        searchable_version: "",
                        clicks: element.clicks,
                        purchase_unit: element.tipo_compra,
                        medioo: element.medioo,
                        prints: element.impresiones,
                        id: element.id,

                        idMySql: element.id,
                        publico: element.segmentacion,
                        swc: element.gastado_con_comision.toFixed(2),
                        segmentation: element.segmentacion,
                        // "age": "+18",
                        leads: element.leads,
                        version: "",
                        budgetSinConsumir:
                          element.presupuesto_disponible?.toFixed(2),
                        available: element.presupuesto_disponible,
                        postComments: element.post_comments,
                        installs: element.instalacion_aplicaciones,
                        frequency: element.frecuencia,
                        modification_date: "",
                        views: element.views,
                        // "daily_budget": 0,
                        video_playbacks_25: element.reproducciones_video_25,
                        cpa: element.cpa?.toFixed(3),
                        cpc: element.cpc?.toFixed(2),
                        client: element.cliente,
                        post_likes: element.post_likes,
                        medio: element.medio,
                        idMixChange: 0,
                        cpf: element.cpf?.toFixed(2),
                        cpi: element.cpi?.toFixed(2),
                        // "price": 0.5,
                        fans: element.fans,
                        cpm: element.cpm?.toFixed(2),
                        cprm: element.cost_per_estimated_ad_recallers,
                        estimated_ad_recallers: element.estimated_ad_recallers,
                        cpl: element.cpl?.toFixed(2),
                        budgetConsumido:
                          element.presupuesto_gastado?.toFixed(2),
                        budgetI: new Number(element.presupuesto).toFixed(2),
                        id_sale: element.orden_venta,
                        meta: element.goal,
                        columnaCompare: element.metric,
                      };
                      searchArray.push(body);
                    }
                  }
                }
              }
              dataFinal = [...dataFinal, ...searchArray];
              let set = new Set(dataFinal.map(JSON.stringify));
              let arrSinDuplicaciones = Array.from(set).map(JSON.parse);
              setSearchFilterData([...arrSinDuplicaciones]);
            } else {
              let searchArray = [];
              const mAccount = _.find(props.accounts, { id: props.account });
              let idclient = mAccount?.eAdvertising;
              let res = await API.findCampaignByIdSale({
                idclient: idclient,
                id_sale: el.value,
              });
              if (res.data.count > 0) {
                let response = await API.getCampaignByIdSale({
                  idSale: res.data.campaigns[0].orden_venta,
                });
                let element = res.data.campaigns[0];
                if (response.data.campaigns.length > 0) {
                  let found = response.data.campaigns[0];
                  let body = {
                    editable: true,
                    scr: element.calificacion,
                    fechaIni: element.fecha_inicio,
                    ctr: element.ctr?.toFixed(2),
                    cpr: element.cpr?.toFixed(2),
                    status: element.idStatus,
                    video_playbacks_75: element.reproducciones_video_75,
                    cpv: element.cpv?.toFixed(3),
                    orden_venta: element.orden_venta,
                    id_sale: element.orden_venta,
                    id_region: found.id_region,
                    name_region: found.name_region,
                    objetivo: found.objetivo,
                    segmentacion: found.publico
                      ? found.publico
                      : element.segmentacion,
                    dias_campaña: element.dias > 0 ? element.dias : 0,
                    comentarios: element.comentarios,
                    calificacion: element.calificacion,
                    //"fr": 6927,
                    searchable_status: element.status,
                    idBudget: found.idBudget,
                    searchable_fechafin: found.searchable_fechafin,
                    adv: element.duracion_promedio_video
                      ? element.duracion_promedio_video
                      : "00:00:00",
                    searchable_medio: found.searchable_medio,
                    id_ad_product: found.id_ad_product,
                    postNumber: found.postNumber,
                    net_budget: element.presupuesto_neto,
                    // "real_price": 0.47,
                    searchable_idMixDetaill: found.id,
                    searchable_name: found.name,
                    reach: element.reach,
                    account: found.account,
                    event_responses: element.respuesta_eventos,
                    postShares: element.post_shares,
                    photo: element.visualizacion_fotos,
                    video_playbacks_50: element.reproducciones_video_50,
                    video_playbacks_100: element.reproducciones_video_100,
                    spent_percent:
                      element.porcentaje_gastado > 0
                        ? element.porcentaje_gastado?.toFixed(2)
                        : "0.00",

                    budget: found.budget ?? found.budget.toFixed(2), // presupuesto neto
                    budgetNeto: element.presupuesto_neto,
                    budgetI:
                      element.presupuesto ?? element.presupuesto.toFixed(2),
                    banner: found.banner,
                    fechafin: element.fecha_fin,
                    id_medium: found.id_medium,
                    idMixDetaill: found.idMixDetaill,
                    // "cnr": 0,
                    searchable_fechaIni: found.searchable_fechaIni,
                    idMix: found.idMix,
                    video_playbacks_95: element.reproducciones_video_95,
                    url: element.url,
                    name: element.post,
                    idCampaign: found.idCampaign,
                    searchable_version: found.searchable_version,
                    clicks: element.clicks,
                    purchase_unit: element.tipo_compra,
                    medioo: found.medioo,
                    prints: element.impresiones,
                    id: found.id,

                    idMySql: element.id,
                    publico: found.publico,
                    swc: element.gastado_con_comision.toFixed(2),
                    segmentation: element.segmentacion,
                    // "age": "+18",
                    leads: element.leads,
                    version: found.version,
                    available: element.presupuesto_disponible,
                    budgetSinConsumir:
                      element.presupuesto_disponible?.toFixed(2),
                    postComments: element.post_comments,
                    installs: element.instalacion_aplicaciones,
                    frequency: element.frecuencia,
                    modification_date: found.modification_date,
                    views: element.views,
                    // "daily_budget": 0,
                    video_playbacks_25: element.reproducciones_video_25,
                    cpa: element.cpa?.toFixed(3),
                    cpc: element.cpc?.toFixed(2),
                    client: element.cliente,
                    post_likes: element.post_likes,
                    medio: found.medio,
                    idMixChange: found.idMixChange,
                    cpf: element.cpf?.toFixed(2),
                    cpi: element.cpi?.toFixed(2),
                    // "price": 0.5,
                    fans: element.fans,
                    cpm: element.cpm?.toFixed(2),
                    cprm: element.cost_per_estimated_ad_recallers,
                    estimated_ad_recallers: element.estimated_ad_recallers,
                    cpl: element.cpl?.toFixed(2),
                    budgetConsumido: element.presupuesto_gastado?.toFixed(2),
                    budgetI:
                      element.presupuesto ?? element.presupuesto.toFixed(2),
                    budgetSinConsumir:
                      element.presupuesto_disponible?.toFixed(2),
                    availableAmount: found.availableAmount,
                    copy: found.copy,
                    observation: found.observation,
                    idAudience: found.idAudience,
                    nameForEmail: found.nameForEmail,
                    emailForEmail: found.emailForEmail,
                    categoriesSelected: found.categoriesSelected,
                    creativity: found.creativity,
                    country: found.name_region,
                    meta: element.goal,
                    columnaCompare: element.metric,
                  };
                  if (found.categoriesSelected) {
                    var categories = found.categoriesSelected;
                    const categoriesGruped = categories.reduce(
                      (groups, item) => ({
                        ...groups,
                        [item.nombrePadre]: [
                          ...(groups[item.nombrePadre] || []),
                          item,
                        ],
                      }),
                      {},
                    );

                    let valores = Object.values(categoriesGruped);
                    for (let j = 0; j < valores.length; j++) {
                      let catsString = "";
                      for (let k = 0; k < valores[j].length; k++) {
                        catsString = catsString + valores[j][k].label + ", ";
                      }
                      let cats = { [valores[j][0].padre]: catsString };

                      body = { ...body, ...cats };
                    }
                  }
                  searchArray.push(body);
                } else {
                  let body = {
                    editable: false,
                    name: element.name ? element.name : element.post,
                    fechaIni: element.fecha_inicio,
                    scr: element.calificacion,
                    ctr: element.ctr?.toFixed(2),
                    cpr: element.cpr?.toFixed(2),
                    status: element.idStatus,
                    video_playbacks_75: element.reproducciones_video_75,
                    video_playbacks_100: element.reproducciones_video_100,
                    dias_campaña: element.dias > 0 ? element.dias : 0,
                    cpv: element.cpv?.toFixed(3),
                    orden_venta: element.orden_venta,
                    country: element.pais,
                    segmentacion: element.segmentacion,
                    //"fr": 6927,
                    searchable_status: element.status,
                    idBudget: 0,
                    searchable_fechafin: "",
                    adv: element.duracion_promedio_video
                      ? element.duracion_promedio_video
                      : "00:00:00",
                    searchable_medio: "",
                    id_ad_product: 0,
                    postNumber: "",
                    net_budget: element.presupuesto_neto,
                    // "real_price": 0.47,
                    searchable_idMixDetaill: "",
                    searchable_name: "",
                    reach: element.reach,
                    account: "",
                    event_responses: element.respuesta_eventos,
                    postShares: element.post_shares,
                    photo: element.visualizacion_fotos,
                    video_playbacks_50: element.reproducciones_video_50,
                    spent_percent:
                      element.porcentaje_gastado > 0
                        ? element.porcentaje_gastado?.toFixed(2)
                        : "0.00",
                    budget:
                      element.presupuesto &&
                      new Number(element.presupuesto).toFixed(2),
                    budgetNeto: element.presupuesto_neto,
                    banner: element.banner,
                    fechafin: element.fecha_fin,
                    id_medium: 0,
                    idMixDetaill: element.id_mix_detail,
                    idMixDetaillActual: element.id_mix_detail,
                    // "cnr": 0,
                    searchable_fechaIni: "",
                    idMix: 0,
                    video_playbacks_95: element.reproducciones_video_95,
                    url: element.url,
                    name: element.post,
                    idCampaign: 0,
                    searchable_version: "",
                    clicks: element.clicks,
                    purchase_unit: element.tipo_compra,
                    medioo: element.medioo,
                    prints: element.impresiones,
                    id: element.id,

                    idMySql: element.id,
                    publico: element.segmentacion,
                    swc: element.gastado_con_comision.toFixed(2),
                    segmentation: element.segmentacion,
                    // "age": "+18",
                    leads: element.leads,
                    version: "",
                    budgetSinConsumir:
                      element.presupuesto_disponible?.toFixed(2),
                    available: element.presupuesto_disponible,
                    postComments: element.post_comments,
                    installs: element.instalacion_aplicaciones,
                    frequency: element.frecuencia,
                    modification_date: "",
                    views: element.views,
                    // "daily_budget": 0,
                    video_playbacks_25: element.reproducciones_video_25,
                    cpa: element.cpa?.toFixed(3),
                    cpc: element.cpc?.toFixed(2),
                    client: element.cliente,
                    post_likes: element.post_likes,
                    medio: element.medio,
                    idMixChange: 0,
                    cpf: element.cpf?.toFixed(2),
                    cpi: element.cpi?.toFixed(2),
                    // "price": 0.5,
                    fans: element.fans,
                    cpm: element.cpm?.toFixed(2),
                    cprm: element.cost_per_estimated_ad_recallers,
                    estimated_ad_recallers: element.estimated_ad_recallers,
                    cpl: element.cpl?.toFixed(2),
                    budgetConsumido: element.presupuesto_gastado?.toFixed(2),
                    budgetI: new Number(element.presupuesto).toFixed(2),
                    id_sale: element.orden_venta,
                    meta: element.goal,
                    columnaCompare: element.metric,
                  };
                  searchArray.push(body);
                }
                dataFinal = [...dataFinal, ...searchArray];
                let set = new Set(dataFinal.map(JSON.stringify));
                let arrSinDuplicaciones = Array.from(set).map(JSON.parse);
                setSearchFilterData([...arrSinDuplicaciones]);
              }
            }
          }
          if (
            el.column != "Orden de venta" &&
            el.column != "ID" &&
            el.column != "purchase_unit" &&
            el.column != "Medio" &&
            el.column != "Pais" &&
            el.column != "Estado" &&
            el.column != "Banner"
          ) {
            let dataAlt = dataToFilterr.filter((ele) => {
              for (let i = 0; i < columns1.length; i++) {
                if (
                  ele[columns1[i].id]
                    ?.toLowerCase()
                    .includes(el.value.toLowerCase())
                ) {
                  return ele[columns1[i].id]
                    ?.toLowerCase()
                    .includes(el.value.toLowerCase());
                }
              }
            });
            if (dataAlt.length > 0) {
              dataFinal = [...dataFinal, ...dataAlt];
            }
          }
        });
        let set = new Set(dataFinal.map(JSON.stringify));
        let arrSinDuplicaciones = Array.from(set).map(JSON.parse);
        setSearchFilterData([...arrSinDuplicaciones]);
      }
      setLoad(false);
    } else {
      setSearchFilterData([]);
      setFilterInfo([]);
    }
  };

  let filteredData = filtered?.filter((item) => {
    if (item?.name?.toLowerCase().includes(filterText.toLowerCase())) {
      return item.name.toLowerCase().includes(filterText.toLowerCase());
    } else if (
      item?.id?.toString()?.toLowerCase()?.includes(filterText.toLowerCase())
    ) {
      return item.id
        ?.toString()
        ?.toLowerCase()
        ?.includes(filterText.toLowerCase());
    } else if (
      item.medio?.toString().toLowerCase().includes(filterText.toLowerCase())
    ) {
      return item.medio
        ?.toString()
        ?.toLowerCase()
        ?.includes(filterText.toLowerCase());
    } else if (
      item.searchable_status
        ?.toString()
        ?.toLowerCase()
        ?.includes(filterText.toLowerCase())
    ) {
      return item.searchable_status
        ?.toString()
        ?.toLowerCase()
        ?.includes(filterText?.toLowerCase());
    } else if (
      item.publico
        ?.toString()
        ?.toLowerCase()
        ?.includes(filterText?.toLowerCase())
    ) {
      return item.publico
        .toString()
        .toLowerCase()
        .includes(filterText.toLowerCase());
    } else if (
      item.banner
        ?.toString()
        ?.toLowerCase()
        ?.includes(filterText?.toLowerCase())
    ) {
      return item.banner
        ?.toString()
        .toLowerCase()
        .includes(filterText?.toLowerCase());
    } else if (
      item.purchase_unit
        ?.toString()
        ?.toLowerCase()
        ?.includes(filterText?.toLowerCase())
    ) {
      return item.purchase_unit
        ?.toString()
        ?.toLowerCase()
        ?.includes(filterText?.toLowerCase());
    } else if (
      item.id_sale > 0 &&
      item.id_sale
        ?.toString()
        ?.toLowerCase()
        ?.includes(filterText?.toLowerCase())
    ) {
      return item.id_sale
        ?.toString()
        ?.toLowerCase()
        ?.includes(filterText.toLowerCase());
    } else {
      for (let i = 0; i < columns1.length; i++) {
        if (
          item[columns1[i].id]
            ?.toLowerCase()
            .includes(filterText?.toLowerCase())
        ) {
          return item[columns1[i].id]
            ?.toLowerCase()
            ?.includes(filterText?.toLowerCase());
        }
      }
    }
  });

  if (filteredData.length == 0 && search == false) {
    if (filterText > 0) {
      const mAccount = _.find(props.accounts, { id: props.account });
      let idclient = mAccount?.eAdvertising;
      API.findCampaignByMixDetail({
        idclient: idclient,
        idMixDetaill: filterText,
      })
        .then((res) => {
          if (res.data.count > 0) {
            setSearch(true);
            /* return res.data.campaigns[0] */
            let elementToPush;
            API.getCampaignById(res.data.campaigns[0].id_mix_detail)
              .then((response) => {
                let element = res.data.campaigns[0];
                let arrayAux = filtered;
                if (response.data.campaigns.length > 0) {
                  let found = response.data.campaigns[0];
                  let body = {
                    editable: true,
                    scr: element.calificacion,
                    fechaIni: element.fecha_inicio,
                    ctr: element.ctr?.toFixed(2),
                    cpr: element.cpr?.toFixed(2),
                    status: element.idStatus,
                    video_playbacks_75: element.reproducciones_video_75,
                    cpv: element.cpv?.toFixed(3),
                    orden_venta: element.orden_venta,
                    id_sale: element.orden_venta,
                    id_region: found.id_region,
                    name_region: found.name_region,
                    objetivo: found.objetivo,
                    segmentacion: found.publico
                      ? found.publico
                      : element.segmentacion,
                    dias_campaña: element.dias > 0 ? element.dias : 0,
                    comentarios: element.comentarios,
                    calificacion: element.calificacion,
                    //"fr": 6927,
                    searchable_status: element.status,
                    idBudget: found.idBudget,
                    searchable_fechafin: found.searchable_fechafin,
                    adv: element.duracion_promedio_video
                      ? element.duracion_promedio_video
                      : "00:00:00",
                    searchable_medio: found.searchable_medio,
                    id_ad_product: found.id_ad_product,
                    postNumber: found.postNumber,
                    net_budget: element.presupuesto_neto,
                    // "real_price": 0.47,
                    searchable_idMixDetaill: found.id,
                    searchable_name: found.name,
                    reach: element.reach,
                    account: found.account,
                    event_responses: element.respuesta_eventos,
                    postShares: element.post_shares,
                    photo: element.visualizacion_fotos,
                    video_playbacks_50: element.reproducciones_video_50,
                    video_playbacks_100: element.reproducciones_video_100,
                    spent_percent:
                      element.porcentaje_gastado > 0
                        ? element.porcentaje_gastado?.toFixed(2)
                        : "0.00",

                    budget: found.budget ?? found.budget.toFixed(2), // presupuesto neto
                    budgetNeto: element.presupuesto_neto,
                    budgetI:
                      element.presupuesto ?? element.presupuesto?.toFixed(2),
                    banner: found.banner,
                    fechafin: element.fecha_fin,
                    id_medium: found.id_medium,
                    idMixDetaill: found.idMixDetaill,
                    // "cnr": 0,
                    searchable_fechaIni: found.searchable_fechaIni,
                    idMix: found.idMix,
                    video_playbacks_95: element.reproducciones_video_95,
                    url: element.url,
                    name: element.post,
                    idCampaign: found.idCampaign,
                    searchable_version: found.searchable_version,
                    clicks: element.clicks,
                    purchase_unit: element.tipo_compra,
                    medioo: found.medioo,
                    prints: element.impresiones,
                    id: found.id,

                    idMySql: element.id,
                    publico: found.publico,
                    swc: element.gastado_con_comision?.toFixed(2),
                    segmentation: element.segmentacion,
                    // "age": "+18",
                    leads: element.leads,
                    version: found.version,
                    available: element.presupuesto_disponible,
                    budgetSinConsumir:
                      element.presupuesto_disponible?.toFixed(2),
                    postComments: element.post_comments,
                    installs: element.instalacion_aplicaciones,
                    frequency: element.frecuencia,
                    modification_date: found.modification_date,
                    views: element.views,
                    // "daily_budget": 0,
                    video_playbacks_25: element.reproducciones_video_25,
                    cpa: element.cpa?.toFixed(3),
                    cpc: element.cpc?.toFixed(2),
                    client: element.cliente,
                    post_likes: element.post_likes,
                    medio: found.medio,
                    idMixChange: found.idMixChange,
                    cpf: element.cpf?.toFixed(2),
                    cpi: element.cpi?.toFixed(2),
                    // "price": 0.5,
                    fans: element.fans,
                    cpm: element.cpm?.toFixed(2),
                    cprm: element.cost_per_estimated_ad_recallers,
                    estimated_ad_recallers: element.estimated_ad_recallers,
                    cpl: element.cpl?.toFixed(2),
                    budgetConsumido: element.presupuesto_gastado?.toFixed(2),
                    budgetI:
                      element.presupuesto ?? element.presupuesto?.toFixed(2),
                    budgetSinConsumir:
                      element.presupuesto_disponible?.toFixed(2),
                    availableAmount: found.availableAmount,
                    copy: found.copy,
                    observation: found.observation,
                    idAudience: found.idAudience,
                    nameForEmail: found.nameForEmail,
                    emailForEmail: found.emailForEmail,
                    categoriesSelected: found.categoriesSelected,
                    creativity: found.creativity,
                    country: found.name_region,
                    meta: element.goal,
                    columnaCompare: element.metric,
                  };
                  if (found.categoriesSelected) {
                    var categories = found.categoriesSelected;
                    const categoriesGruped = categories.reduce(
                      (groups, item) => ({
                        ...groups,
                        [item.nombrePadre]: [
                          ...(groups[item.nombrePadre] || []),
                          item,
                        ],
                      }),
                      {},
                    );

                    let valores = Object.values(categoriesGruped);
                    for (let j = 0; j < valores.length; j++) {
                      let catsString = "";
                      for (let k = 0; k < valores[j].length; k++) {
                        catsString = catsString + valores[j][k].label + ", ";
                      }
                      let cats = { [valores[j][0].padre]: catsString };

                      body = { ...body, ...cats };
                    }
                  }
                  setSearchData([body]);
                } else {
                  let body = {
                    editable: false,
                    name: element.name ? element.name : element.post,
                    fechaIni: element.fecha_inicio,
                    scr: element.calificacion,
                    ctr: element.ctr?.toFixed(2),
                    cpr: element.cpr?.toFixed(2),
                    status: element.idStatus,
                    video_playbacks_75: element.reproducciones_video_75,
                    video_playbacks_100: element.reproducciones_video_100,
                    dias_campaña: element.dias > 0 ? element.dias : 0,
                    cpv: element.cpv?.toFixed(3),
                    orden_venta: element.orden_venta,
                    country: element.pais,
                    segmentacion: element.segmentacion,
                    //"fr": 6927,
                    searchable_status: element.status,
                    idBudget: 0,
                    searchable_fechafin: "",
                    adv: element.duracion_promedio_video
                      ? element.duracion_promedio_video
                      : "00:00:00",
                    searchable_medio: "",
                    id_ad_product: 0,
                    postNumber: "",
                    net_budget: element.presupuesto_neto,
                    // "real_price": 0.47,
                    searchable_idMixDetaill: "",
                    searchable_name: "",
                    reach: element.reach,
                    account: "",
                    event_responses: element.respuesta_eventos,
                    postShares: element.post_shares,
                    photo: element.visualizacion_fotos,
                    video_playbacks_50: element.reproducciones_video_50,
                    spent_percent:
                      element.porcentaje_gastado > 0
                        ? element.porcentaje_gastado?.toFixed(2)
                        : "0.00",
                    budget:
                      element.presupuesto &&
                      new Number(element.presupuesto).toFixed(2),
                    budgetNeto: element.presupuesto_neto,
                    banner: element.banner,
                    fechafin: element.fecha_fin,
                    id_medium: 0,
                    idMixDetaill: element.id_mix_detail,
                    idMixDetaillActual: element.id_mix_detail,
                    // "cnr": 0,
                    searchable_fechaIni: "",
                    idMix: 0,
                    video_playbacks_95: element.reproducciones_video_95,
                    url: element.url,
                    name: element.post,
                    idCampaign: 0,
                    searchable_version: "",
                    clicks: element.clicks,
                    purchase_unit: element.tipo_compra,
                    medioo: element.medioo,
                    prints: element.impresiones,
                    id: element.id,

                    idMySql: element.id,
                    publico: element.segmentacion,
                    swc: element.gastado_con_comision.toFixed(2),
                    segmentation: element.segmentacion,
                    // "age": "+18",
                    leads: element.leads,
                    version: "",
                    budgetSinConsumir:
                      element.presupuesto_disponible?.toFixed(2),
                    available: element.presupuesto_disponible,
                    postComments: element.post_comments,
                    installs: element.instalacion_aplicaciones,
                    frequency: element.frecuencia,
                    modification_date: "",
                    views: element.views,
                    // "daily_budget": 0,
                    video_playbacks_25: element.reproducciones_video_25,
                    cpa: element.cpa?.toFixed(3),
                    cpc: element.cpc?.toFixed(2),
                    client: element.cliente,
                    post_likes: element.post_likes,
                    medio: element.medio,
                    idMixChange: 0,
                    cpf: element.cpf?.toFixed(2),
                    cpi: element.cpi?.toFixed(2),
                    // "price": 0.5,
                    fans: element.fans,
                    cpm: element.cpm?.toFixed(2),
                    cprm: element.cost_per_estimated_ad_recallers,
                    estimated_ad_recallers: element.estimated_ad_recallers,
                    cpl: element.cpl?.toFixed(2),
                    budgetConsumido: element.presupuesto_gastado?.toFixed(2),
                    budgetI: new Number(element.presupuesto).toFixed(2),
                    id_sale: element.orden_venta,
                    meta: element.goal,
                    columnaCompare: element.metric,
                  };
                  setSearchData([body]);

                  /* setFiltered(arrayAux) */
                }
              })
              .catch((err) => console.log("errDYNAMO", err));
          }
        })
        .catch((err) => {
          console.log("ErrorCampaignByMixDetaill", err);
        });
    } else {
    }
  }

  const filterCampaignsByDate = (campaigns, startDate, endDate) => {
    try {
      const [startDay, startMonth, startYear] = startDate.split(".");
      const [endDay, endMonth, endYear] = endDate.split(".");

      const startDateObj = new Date(`${startYear}-${startMonth}-${startDay}`);
      const endDateObj = new Date(`${endYear}-${endMonth}-${endDay}`);

      endDateObj.setHours(23, 59, 59, 999);

      const filteredCampaigns = campaigns.filter((campaign) => {
        const campaignDate = new Date(campaign.fecha);
        return campaignDate >= startDateObj && campaignDate <= endDateObj;
      });

      return filteredCampaigns;
    } catch (err) {
      console.log("Error al filtrar las campañas por fecha:", err);
      return [];
    }
  };

  const getCampaignsSearch = async (
    account,
    name,
    filter,
    statusFilter,
    eAdvertising,
  ) => {
    try {
      const [startDate, endDate] = name.split("|");

      const filteredCampaigns = filterCampaignsByDate(
        props.campaigns,
        startDate.trim(),
        endDate.trim(),
      );

      setFiltered(filteredCampaigns);
    } catch (err) {
      console.log("Error getCampaignsSearch:", err);
    }
  };

  const handleFilterTextChange = async (value) => {
    // statusFilter=0 no fechas
    // statusFilter=1 between fecha inicial y final
    // statusFilter=3 numeross
    let statusFilter = 0;
    let text = value;
    if (!value == "") {
      if (value?.includes("ALL")) {
        setLoad(true);
        setIsCustomDate(true);

        const found = props.accounts.find(
          (element) => element.id == props.currentAccount,
        );
        let limit = 2000;

        var camps = props.sendCampaignss;
        for (let i = 0; i < camps.length; i++) {
          if (camps[i].categoriesSelected) {
            var categories = camps[i].categoriesSelected;

            /* const grouped = groupBy(categories, cat => cat.nombrePadre); */
            const categoriesGruped = categories.reduce(
              (groups, item) => ({
                ...groups,
                [item.nombrePadre]: [...(groups[item.nombrePadre] || []), item],
              }),
              {},
            );

            let valores = Object.values(categoriesGruped);

            for (let j = 0; j < valores.length; j++) {
              let catsString = "";
              for (let k = 0; k < valores[j].length; k++) {
                catsString = catsString + valores[j][k].label + ", ";
              }
              let body = { [valores[j][0].padre]: catsString };

              camps[i] = { ...camps[i], ...body };
            }
          }
        }
        let firstCustomDate = camps[0].fechaIni.split(".");
        let lastCustomDate = camps[camps.length - 1].fechaIni.split(".");

        setCustomDates([
          {
            startDate: new Date(
              lastCustomDate[1] +
              "/" +
              lastCustomDate[0] +
              "/" +
              lastCustomDate[2],
            ),
            endDate: new Date(
              firstCustomDate[1] +
              "/" +
              firstCustomDate[0] +
              "/" +
              firstCustomDate[2],
            ),
            key: "selection",
          },
        ]);
        setDatesToShow({
          fechaIni:
            lastCustomDate[2] +
            "/" +
            lastCustomDate[1] +
            "/" +
            lastCustomDate[0],
          fechaFin:
            firstCustomDate[2] +
            "/" +
            firstCustomDate[1] +
            "/" +
            firstCustomDate[0],
        });
        setFiltered(camps);

        analytics.Fechas({
          ubicacion: "Dashboard",
        });
        setLoad(false);
      } else {
        try {
          let num = parseInt(text);
          if (num) {
            statusFilter = 3;
          }
        } catch {
          statusFilter = 0;
        }

        if (value.includes("|") && value.includes("+")) {
          statusFilter = 1;
          text = text.replaceAll("+", "");
          text = text.replaceAll(".", "/");
          text = text.replaceAll("-", "/");
          text = text.replaceAll("_", "/");
          const splited = text.split("|");
        }

        const found = props.accounts.find(
          (element) => element.id == props.currentAccount,
        );

        console.log(
          "Validando como esta llegando",
          props.currentAccount,
          text.toLowerCase(),
          props.filter,
          statusFilter,
          found.eAdvertising,
        );

        getCampaignsSearch(
          props.currentAccount,
          text.toLowerCase(),
          props.filter,
          statusFilter,
          found.eAdvertising,
        );
      }
    } else {
      setFiltered(sendCampaigns);
    }
  };

  //funcion que calcula las sumas nuevas segun filtro
  const newDataTotal = () => {
    let enviada = [];
    let filtrada =
      searchFilterData.length > 0
        ? searchFilterData
        : searchData.length > 0
          ? searchData
          : filteredData;
    let temporal = [];

    if (filtrada !== undefined) {
      let pagData = [];
      let presupuestoNeto = 0;
      let presupuestoInvertido = 0;
      let presupuestoConsumido = 0;
      let presupuestoComision = 0;
      let presupuestoSinConsumir = 0;
      let totalPorcentajePresupuestoConsumido = 0;
      let porcentajePresupuestoConsumido = 0;
      let contPresupuestoConsumido = 0;
      let visualizacionesImagen = 0;
      let eventResponses = 0;
      let appInstalations = 0;
      let frequency = 0;
      let totalFrequency = 0;
      let contFrequency = 0;
      let leads = 0;
      let cpf = 0;
      let totalCpf = 0;
      let contCpf = 0;
      let cpre = 0;
      let totalCpre = 0;
      let contCpre = 0;
      let cpi = 0;
      let totalCpi = 0;
      let contCpi = 0;
      let cpv = 0;
      let totalCpv = 0;
      let contCpv = 0;
      let ctr = 0;
      let totalCtr = 0;
      let contCtr = 0;
      let cpl = 0;
      let totalCpl = 0;
      let contCpl = 0;
      let cpa = 0;
      let totalCpa = 0;
      let contCpa = 0;
      let cpc = 0;
      let totalCpc = 0;
      let contCpc = 0;
      let cpm = 0;
      let totalCpm = 0;
      let contCpm = 0;
      let cprm = 0;
      let totalCprm = 0;
      let contCprm = 0;
      let prints = 0;
      let clicks = 0;
      let fans = 0;
      let reach = 0;
      let views = 0;
      let meta = 0;
      let video_playbacks_25 = 0;
      let video_playbacks_50 = 0;
      let video_playbacks_75 = 0;
      let video_playbacks_95 = 0;
      let video_playbacks_100 = 0;
      let adv = 0;
      let totalAdv = 0;
      let contAdv = 0;
      let postShares = 0;
      let postLikes = 0;
      let totalDiasCamp = 0;
      let diasCamp = 0;
      let contDiasCamp = 0;
      let comments = 0;
      let estimatedAdRecallers = 0;
      let percentage = 0;
      let contPercentage = 0;
      let totalPercentage = 0;
      let valueTotalPercentage = 0;
      for (let x = 0; x < filtrada.length; x++) {
        presupuestoNeto += new Number(
          filtrada[x].budgetNeto
            ? filtrada[x].budgetNeto
            : filtrada[x].net_budget,
        );

        if (filtrada[x].budget) {
          presupuestoInvertido += new Number(filtrada[x].budget);
        }

        let percentageValue = getPercentNumber(filtrada[x]);

        if (percentageValue != "NA") {
          percentage += new Number(percentageValue);
          contPercentage++;
        }

        let percentageTot = getPercentValue(filtrada[x]);
        if (percentageTot != "NA") {
          valueTotalPercentage += new Number(percentageTot);
        }

        if (new Number(filtrada[x].estimated_ad_recallers)) {
          estimatedAdRecallers += new Number(
            filtrada[x].estimated_ad_recallers,
          );
        }

        if (filtrada[x].meta != null) {
          meta += new Number(filtrada[x].meta);
        }

        if (filtrada[x].budgetConsumido > 0) {
          presupuestoConsumido += new Number(filtrada[x].budgetConsumido);
        } else {
          presupuestoConsumido += new Number(
            getTotalspent(filtrada[x].idMixDetaill),
          );
        }

        if (filtrada[x].swc) {
          presupuestoComision += new Number(filtrada[x].swc);
        }

        if (filtrada[x].budgetSinConsumir) {
          presupuestoSinConsumir += new Number(filtrada[x].budgetSinConsumir);
        }

        if (filtrada[x].photo > 0) {
          visualizacionesImagen += new Number(filtrada[x].photo);
        }

        if (filtrada[x].spent_percent) {
          porcentajePresupuestoConsumido += new Number(
            filtrada[x].spent_percent,
          );
          contPresupuestoConsumido++;
        }
        if (filtrada[x].cpf) {
          cpf += new Number(filtrada[x].cpf);
          contCpf++;
        }
        if (filtrada[x].cpre) {
          cpre += new Number(filtrada[x].cpre);
          contCpre++;
        }
        if (filtrada[x].cpi) {
          cpi += new Number(filtrada[x].cpi);
          contCpi++;
        }
        if (filtrada[x].cpv) {
          cpv += new Number(filtrada[x].cpv);
          contCpv++;
        }
        if (filtrada[x].ctr) {
          ctr += new Number(filtrada[x].ctr);
          contCtr++;
        }
        if (filtrada[x].leads) {
          leads += new Number(filtrada[x].leads);
        }
        if (filtrada[x].event_responses) {
          eventResponses += filtrada[x].event_responses;
        }
        if (filtrada[x].installs) {
          appInstalations += filtrada[x].installs;
        }
        if (filtrada[x].frequency) {
          frequency += filtrada[x].frequency;
          contFrequency++;
        }
        if (filtrada[x].cpl) {
          cpl += new Number(filtrada[x].cpl);
          contCpl++;
        }
        if (filtrada[x].cpa) {
          cpa += new Number(filtrada[x].cpa);
          contCpa++;
        }
        if (filtrada[x].cpc) {
          cpc += new Number(filtrada[x].cpc);
          contCpc++;
        }
        if (filtrada[x].cpm) {
          cpm += new Number(filtrada[x].cpm);
          contCpm++;
        }
        if (filtrada[x].prints) {
          prints += filtrada[x].prints;
        }
        if (filtrada[x].clicks) {
          clicks += filtrada[x].clicks;
        }
        if (filtrada[x].fans) {
          fans += filtrada[x].fans;
        }
        if (filtrada[x].reach) {
          reach += filtrada[x].reach;
        }
        if (filtrada[x].views) {
          views += filtrada[x].views;
        }
        if (filtrada[x].video_playbacks_25) {
          video_playbacks_25 += filtrada[x].video_playbacks_25;
        }
        if (filtrada[x].video_playbacks_50) {
          video_playbacks_50 += filtrada[x].video_playbacks_50;
        }
        if (filtrada[x].video_playbacks_75) {
          video_playbacks_75 += filtrada[x].video_playbacks_75;
        }
        if (filtrada[x].video_playbacks_95) {
          video_playbacks_95 += filtrada[x].video_playbacks_95;
        }
        if (filtrada[x].video_playbacks_100) {
          video_playbacks_100 += filtrada[x].video_playbacks_100;
        }
        if (filtrada[x].adv) {
          var a = filtrada[x].adv.split(":");
          var seconds = +a[0] * 60 * 60 + +a[1] * 60 + +a[2];
          adv += seconds;
          contAdv++;
        }
        if (filtrada[x].cprm) {
          cprm += new Number(filtrada[x].cprm);
          contCprm++;
        }
        if (filtrada[x].postShares) {
          postShares += filtrada[x].postShares;
        }
        if (filtrada[x].post_likes > 0) {
          postLikes += filtrada[x].post_likes;
        }
        if (filtrada[x].dias_campaña) {
          diasCamp += filtrada[x].dias_campaña;
          contDiasCamp++;
        }
        if (filtrada[x].postComments) {
          comments += filtrada[x].postComments;
        }
      }
      totalAdv = adv / contAdv;
      totalCpm = cpm / contCpm;
      totalCpc = cpc / contCpc;
      totalCpa = cpa / contCpa;
      totalCpl = cpl / contCpl;
      totalCtr = ctr / contCtr;
      totalCpv = cpv / contCpv;
      totalCpi = cpi / contCpi;
      totalCpre = cpre / contCpre;
      totalCpf = cpf / contCpf;
      totalCprm = cprm / contCprm;
      totalPorcentajePresupuestoConsumido =
        porcentajePresupuestoConsumido / contPresupuestoConsumido;
      totalFrequency = frequency / contFrequency;
      totalDiasCamp = diasCamp / contDiasCamp;
      if (contPercentage != 0) {
        totalPercentage = percentage / contPercentage;
      } else {
        totalPercentage = "NA";
      }
      setTotalRow([
        {
          /* "adv": totalAdv>0 ? totalAdv.toFixed(2) : 0, */
          adv:
            totalAdv > 0
              ? new Date(totalAdv * 1000).toISOString().substr(11, 8)
              : "00:00:00",
          ctr: totalCtr > 0 ? totalCtr.toFixed(2) : "0.00",
          cpc: totalCpc > 0 ? totalCpc.toFixed(2) : "0.00",
          estimated_ad_recallers: estimatedAdRecallers,
          cprm: totalCprm > 0 ? totalCprm : "0",
          photo: visualizacionesImagen,
          video_playbacks_50: video_playbacks_50,
          video_playbacks_25: video_playbacks_25,
          video_playbacks_75: video_playbacks_75,
          video_playbacks_95: video_playbacks_95,
          video_playbacks_100: video_playbacks_100,
          cpv: totalCpv > 0 ? totalCpv.toFixed(3) : "0.000",
          prints: prints,
          country: "",
          clicks: clicks,
          views: views,
          fr: 0,
          status: 100,
          searchable_status: "",
          idBudget: 0,
          swc: presupuestoComision.toFixed(2),
          budgetConsumido: presupuestoConsumido.toFixed(2),
          budgetI: presupuestoInvertido?.toFixed(2),
          budget: presupuestoInvertido?.toFixed(2),
          budgetNeto: presupuestoNeto.toFixed(2),
          budgetSinConsumir: presupuestoSinConsumir.toFixed(2),
          installs: appInstalations,
          spent_percent:
            totalPorcentajePresupuestoConsumido > 0
              ? totalPorcentajePresupuestoConsumido.toFixed(2)
              : "0,00",
          leads: leads > 0 ? leads : 0,
          reach: reach,
          event_responses: eventResponses > 0 ? eventResponses.toFixed(0) : 0,
          name: "Totales",
          metaTotales: meta,
          totalPercentage:
            totalPercentage != "NA"
              ? valueTotalPercentage.toFixed(0) +
              "/" +
              totalPercentage.toFixed(0) +
              "%105"
              : "NA",
          client: "0",
          post_likes: postLikes,
          medio: " ",
          idMixChange: 0,
          frequency: totalFrequency > 0 ? totalFrequency.toFixed(2) : 0,
          cpf: totalCpf > 0 ? totalCpf.toFixed(2) : "0.00",
          cpr: totalCpre > 0 ? totalCpre.toFixed(2) : "0.00",
          cpi: totalCpi > 0 ? totalCpi.toFixed(2) : "0.00",
          fans: fans,
          cpm: totalCpm > 0 ? totalCpm.toFixed(2) : "0.00",
          cpl: totalCpl > 0 ? totalCpl.toFixed(2) : "0.00",
          cpa: totalCpa > 0 ? totalCpa.toFixed(3) : "0.000",
          postShares: postShares,
          dias_campaña: totalDiasCamp > 0 ? totalDiasCamp.toFixed(0) : 0,
          postComments: comments,
        },
      ]);
    }
  };

  //useEffect para calculo de sumas
  useEffect(() => {
    newDataTotal();
  }, [filtered, filters, filterText, searchData, searchFilterData]);

  $(document).ready(function () {
    $(".CampaTabla1").on("scroll", function () {
      $(".CampaTabla2").scrollLeft($(this).scrollLeft());
    });
    $(".CampaTabla2").on("scroll", function () {
      $(".CampaTabla1").scrollLeft($(this).scrollLeft());
    });
  });

  const sendView = (datos, styles) => {
    // alert(state.data)
    if (state.data == 0) {
      return <Kanban campaigns={datos} />;
    } else if (state.data == 2) {
      var columnsMerged;
      columnsMerged = columns.concat(columns1);
      return <Calendar campaigns={datos} />;
    } else if (state.data == 1) {
      return (
        <>
          <Table
            columns={columns.length > 0 ? columns : newColumnsExa}
            campaigns={
              searchFilterData.length > 0
                ? searchFilterData
                : searchData.length > 0
                  ? searchData
                  : datos
            }
            groupedBy={groupedBy}
            customStyles={styles}
            // setFilteredData={setFiltered}
            expandale={false}
            setFilteredData={
              searchFilterData.length > 0 ? setSearchFilterData : setFiltered
            }
            className="tablaDashboard"
            setRowsPerPage={setRowsPerPage}
          />
          <ReactDataTable
            className="CampaTabla2"
            columns={columns}
            customStyles={styles}
            groupedBy={groupedBy}
            data={totalRow}
          />
        </>
      );
    }
  };

  if (!_.isEmpty(filters)) {
    searchData.length > 0
      ? filterBool === true && (
        <>
          {setSearchData([
            ...searchData.filter((item) => {
              let result = true;
              for (const filterKey in filters) {
                let response;
                let filter = filters[filterKey];
                let dataValue = item[filterKey];
                props.utils.log("key: ", filterKey);
                props.utils.log("filter: ", filter);
                props.utils.log("data value: ", dataValue);
                switch (filter.type) {
                  case "EQUALS":
                    response =
                      `${filter.value}`.toLowerCase() ===
                      `${dataValue}`.toLowerCase();
                    break;
                  case "INLIST":
                    response = filter.value[`${dataValue}`];
                    break;
                  case "GREATERTHAN":
                    response =
                      filter.value === -1 || dataValue > filter.value;
                    break;
                  case "LESSTHAN":
                    response =
                      filter.value === -1 || dataValue < filter.value;
                    break;
                  case "EQUALTHAN":
                    response =
                      filter.value === -1 || filter.value === dataValue;
                    break;
                  default:
                    response = `${dataValue}`
                      .toLowerCase()
                      .includes(`${filter.value}`.toLowerCase());
                    break;
                }
                result = result && response;
              }
              return result;
            }),
          ])}{" "}
          {setFilterBool(false)}
        </>
      )
      : (filteredData = filteredData.filter((item) => {
        let result = true;

        for (const filterKey in filters) {
          let response;
          let filter = filters[filterKey];
          let dataValue = item[filterKey];

          props.utils.log("key: ", filterKey);
          props.utils.log("filter: ", filter);
          props.utils.log("data value: ", dataValue);

          switch (filter.type) {
            case "EQUALS":
              response =
                `${filter.value}`.toLowerCase() ===
                `${dataValue}`.toLowerCase();
              break;
            case "INLIST":
              response = filter.value[`${dataValue}`];
              break;
            case "GREATERTHAN":
              response = filter.value === -1 || dataValue > filter.value;
              break;
            case "LESSTHAN":
              response = filter.value === -1 || dataValue < filter.value;
              break;
            case "EQUALTHAN":
              response = filter.value === -1 || filter.value === dataValue;
              break;
            default:
              response = `${dataValue}`
                .toLowerCase()
                .includes(`${filter.value}`.toLowerCase());
              break;
          }
          result = result && response;
        }
        return result;
      }));
  }
  let myContacts = [];

  const showModalCamp = () => { };

  const onChangeFilter = async (value) => {
    $(document).keydown(function (event) {
      if (event.which == "13" && document.type != "textarea") {
        event.preventDefault();
      }
    });

    const myArray = value.split(",");
    if (value != "") {
      analytics.busqueda({
        ubicacion: "Dashboard",
        busqueda: value,
      });
    }
    if (myArray.length > 1) {
      setDatesToShow({
        fechaIni: "",
        fechaFin: "",
      });
      setArraynIds(value);
      let filtrada =
        searchFilterData.length > 0
          ? searchFilterData
          : searchData.length > 0
            ? searchData
            : filteredData;
      let auxArray = [];
      const mAccount = _.find(props.accounts, { id: props.account });
      let idclient = mAccount?.eAdvertising;
      await myArray.forEach(async (el) => {
        let dataGet = filtrada.filter(
          (ele) => ele?.id?.toString() === el?.toString(),
        );

        if (dataGet.length > 0) {
          auxArray.push(dataGet[0]);
        } else {
          //buscar en bases de datos
          let res = await API.findCampaignByMixDetail({
            idclient: idclient,
            idMixDetaill: el,
          });
          if (res.data.count > 0) {
            let response = await API.getCampaignById(
              res.data.campaigns[0].id_mix_detail,
            );
            let element = res.data.campaigns[0];
            if (response.data.campaigns.length > 0) {
              let found = response.data.campaigns[0];
              let body = {
                editable: true,
                scr: element.calificacion,
                fechaIni: element.fecha_inicio,
                ctr: element.ctr?.toFixed(2),
                cpr: element.cpr?.toFixed(2),
                status: element.idStatus,
                video_playbacks_75: element.reproducciones_video_75,
                cpv: element.cpv?.toFixed(3),
                orden_venta: element.orden_venta,
                id_sale: element.orden_venta,
                id_region: found.id_region,
                name_region: found.name_region,
                objetivo: found.objetivo,
                segmentacion: found.publico
                  ? found.publico
                  : element.segmentacion,
                dias_campaña: element.dias > 0 ? element.dias : 0,
                comentarios: element.comentarios,
                calificacion: element.calificacion,
                //"fr": 6927,
                searchable_status: element.status,
                idBudget: found.idBudget,
                searchable_fechafin: found.searchable_fechafin,
                adv: element.duracion_promedio_video
                  ? element.duracion_promedio_video
                  : "00:00:00",
                searchable_medio: found.searchable_medio,
                id_ad_product: found.id_ad_product,
                postNumber: found.postNumber,
                net_budget: element.presupuesto_neto,
                // "real_price": 0.47,
                searchable_idMixDetaill: found.id,
                searchable_name: found.name,
                reach: element.reach,
                account: found.account,
                event_responses: element.respuesta_eventos,
                postShares: element.post_shares,
                photo: element.visualizacion_fotos,
                video_playbacks_50: element.reproducciones_video_50,
                video_playbacks_100: element.reproducciones_video_100,
                spent_percent:
                  element.porcentaje_gastado > 0
                    ? element.porcentaje_gastado?.toFixed(2)
                    : "0.00",

                budget: found.budget ?? found.budget.toFixed(2), // presupuesto neto
                budgetNeto: element.presupuesto_neto,
                budgetI: element.presupuesto ?? element.presupuesto.toFixed(2),
                banner: found.banner,
                fechafin: element.fecha_fin,
                id_medium: found.id_medium,
                idMixDetaill: found.idMixDetaill,
                // "cnr": 0,
                searchable_fechaIni: found.searchable_fechaIni,
                idMix: found.idMix,
                video_playbacks_95: element.reproducciones_video_95,
                url: element.url,
                name: element.post,
                idCampaign: found.idCampaign,
                searchable_version: found.searchable_version,
                clicks: element.clicks,
                purchase_unit: element.tipo_compra,
                medioo: found.medioo,
                prints: element.impresiones,
                id: found.id,

                idMySql: element.id,
                publico: found.publico,
                swc: element.gastado_con_comision.toFixed(2),
                segmentation: element.segmentacion,
                // "age": "+18",
                leads: element.leads,
                version: found.version,
                available: element.presupuesto_disponible,
                budgetSinConsumir: element.presupuesto_disponible?.toFixed(2),
                postComments: element.post_comments,
                installs: element.instalacion_aplicaciones,
                frequency: element.frecuencia,
                modification_date: found.modification_date,
                views: element.views,
                // "daily_budget": 0,
                video_playbacks_25: element.reproducciones_video_25,
                cpa: element.cpa?.toFixed(3),
                cpc: element.cpc?.toFixed(2),
                client: element.cliente,
                post_likes: element.post_likes,
                medio: found.medio,
                idMixChange: found.idMixChange,
                cpf: element.cpf?.toFixed(2),
                cpi: element.cpi?.toFixed(2),
                // "price": 0.5,
                fans: element.fans,
                cpm: element.cpm?.toFixed(2),
                cprm: element.cost_per_estimated_ad_recallers,
                estimated_ad_recallers: element.estimated_ad_recallers,
                cpl: element.cpl?.toFixed(2),
                budgetConsumido: element.presupuesto_gastado?.toFixed(2),
                budgetI: element.presupuesto ?? element.presupuesto.toFixed(2),
                budgetSinConsumir: element.presupuesto_disponible?.toFixed(2),
                availableAmount: found.availableAmount,
                copy: found.copy,
                observation: found.observation,
                idAudience: found.idAudience,
                nameForEmail: found.nameForEmail,
                emailForEmail: found.emailForEmail,
                categoriesSelected: found.categoriesSelected,
                creativity: found.creativity,
                country: found.name_region,
                meta: element.goal,
                columnaCompare: element.metric,
              };
              if (found.categoriesSelected) {
                var categories = found.categoriesSelected;
                const categoriesGruped = categories.reduce(
                  (groups, item) => ({
                    ...groups,
                    [item.nombrePadre]: [
                      ...(groups[item.nombrePadre] || []),
                      item,
                    ],
                  }),
                  {},
                );

                let valores = Object.values(categoriesGruped);
                for (let j = 0; j < valores.length; j++) {
                  let catsString = "";
                  for (let k = 0; k < valores[j].length; k++) {
                    catsString = catsString + valores[j][k].label + ", ";
                  }
                  let cats = { [valores[j][0].padre]: catsString };

                  body = { ...body, ...cats };
                }
              }
              auxArray.push(body);
              setSearchData([...auxArray]);
              setSearchDataAlt([...auxArray]);
            } else {
              let body = {
                editable: false,
                name: element.name ? element.name : element.post,
                fechaIni: element.fecha_inicio,
                scr: element.calificacion,
                ctr: element.ctr?.toFixed(2),
                cpr: element.cpr?.toFixed(2),
                status: element.idStatus,
                video_playbacks_75: element.reproducciones_video_75,
                video_playbacks_100: element.reproducciones_video_100,
                dias_campaña: element.dias > 0 ? element.dias : 0,
                cpv: element.cpv?.toFixed(3),
                orden_venta: element.orden_venta,
                country: element.pais,
                segmentacion: element.segmentacion,
                //"fr": 6927,
                searchable_status: element.status,
                idBudget: 0,
                searchable_fechafin: "",
                adv: element.duracion_promedio_video
                  ? element.duracion_promedio_video
                  : "00:00:00",
                searchable_medio: "",
                id_ad_product: 0,
                postNumber: "",
                net_budget: element.presupuesto_neto,
                // "real_price": 0.47,
                searchable_idMixDetaill: "",
                searchable_name: "",
                reach: element.reach,
                account: "",
                event_responses: element.respuesta_eventos,
                postShares: element.post_shares,
                photo: element.visualizacion_fotos,
                video_playbacks_50: element.reproducciones_video_50,
                spent_percent:
                  element.porcentaje_gastado > 0
                    ? element.porcentaje_gastado?.toFixed(2)
                    : "0.00",
                budget:
                  element.presupuesto &&
                  new Number(element.presupuesto).toFixed(2),
                budgetNeto: element.presupuesto_neto,
                banner: element.banner,
                fechafin: element.fecha_fin,
                id_medium: 0,
                idMixDetaill: element.id_mix_detail,
                idMixDetaillActual: element.id_mix_detail,
                // "cnr": 0,
                searchable_fechaIni: "",
                idMix: 0,
                video_playbacks_95: element.reproducciones_video_95,
                url: element.url,
                name: element.post,
                idCampaign: 0,
                searchable_version: "",
                clicks: element.clicks,
                purchase_unit: element.tipo_compra,
                medioo: element.medioo,
                prints: element.impresiones,
                id: element.id,

                idMySql: element.id,
                publico: element.segmentacion,
                swc: element.gastado_con_comision.toFixed(2),
                segmentation: element.segmentacion,
                // "age": "+18",
                leads: element.leads,
                version: "",
                budgetSinConsumir: element.presupuesto_disponible?.toFixed(2),
                available: element.presupuesto_disponible,
                postComments: element.post_comments,
                installs: element.instalacion_aplicaciones,
                frequency: element.frecuencia,
                modification_date: "",
                views: element.views,
                // "daily_budget": 0,
                video_playbacks_25: element.reproducciones_video_25,
                cpa: element.cpa?.toFixed(3),
                cpc: element.cpc?.toFixed(2),
                client: element.cliente,
                post_likes: element.post_likes,
                medio: element.medio,
                idMixChange: 0,
                cpf: element.cpf?.toFixed(2),
                cpi: element.cpi?.toFixed(2),
                // "price": 0.5,
                fans: element.fans,
                cpm: element.cpm?.toFixed(2),
                cprm: element.cost_per_estimated_ad_recallers,
                estimated_ad_recallers: element.estimated_ad_recallers,
                cpl: element.cpl?.toFixed(2),
                budgetConsumido: element.presupuesto_gastado?.toFixed(2),
                budgetI: new Number(element.presupuesto).toFixed(2),
                id_sale: element.orden_venta,
                meta: element.goal,
                columnaCompare: element.metric,
              };
              auxArray.push(body);
              setSearchData([...auxArray]);
              setSearchDataAlt([...auxArray]);
            }
          }
        }
      });

      setSearchData([...auxArray]);
      setSearchDataAlt([...auxArray]);
    } else {
      let arrayData = [];
      arrayData.push(value);
      setArraynIds("");
      setActualState({
        place: "busqueda",
        data: arrayData,
      });
      localStorage.setItem(
        "state",
        JSON.stringify({
          place: "busqueda",
          data: arrayData,
        }),
      );

      if (value == "") {
        setSearchData([]);
        setFilterText(value);
        setSearch(false);

        let fechaFin = new Date();
        let fechaIn = new Date();
        fechaIn.setDate(fechaIn.getDate() - 7);
        let fechaIni = new Date(fechaIn);
        setDatesToShow({
          fechaIni,
          fechaFin,
        });
      } else {
        setFilterText(value);
        setDatesToShow({
          fechaIni: "",
          fechaFin: "",
        });
      }
    }
  };

  const onChangeFilterCalendar = async (value) => {
    $(document).keydown(function (event) {
      if (event.which == "13" && document.type != "textarea") {
        event.preventDefault();
      }
    });
    const myArray = value.split(",");
    if (value != "") {
      analytics.busqueda({
        ubicacion: "Dashboard",
        busqueda: value,
      });
    }
    if (myArray.length > 1) {
      setDatesToShow({
        fechaIni: "",
        fechaFin: "",
      });
      setArraynIds(value);
      let filtrada = filteredData;
      let auxArray = [];
      const mAccount = _.find(props.accounts, { id: props.account });
      let idclient = mAccount?.eAdvertising;
      await myArray.forEach(async (el) => {
        let dataGet = filtrada.filter(
          (ele) => ele.id.toString() === el.toString(),
        );
        if (dataGet.length > 0) {
          auxArray.push(dataGet[0]);
        } else {
          //buscar en bases de datos
          let res = await API.findCampaignByMixDetail({
            idclient: idclient,
            idMixDetaill: el,
          });
          if (res.data.count > 0) {
            let response = await API.getCampaignById(
              res.data.campaigns[0].id_mix_detail,
            );
            let element = res.data.campaigns[0];
            if (response.data.campaigns.length > 0) {
              let found = response.data.campaigns[0];
              let body = {
                editable: true,
                scr: element.calificacion,
                fechaIni: element.fecha_inicio,
                ctr: element.ctr?.toFixed(2),
                cpr: element.cpr?.toFixed(2),
                status: element.idStatus,
                video_playbacks_75: element.reproducciones_video_75,
                cpv: element.cpv?.toFixed(3),
                orden_venta: element.orden_venta,
                id_sale: element.orden_venta,
                id_region: found.id_region,
                name_region: found.name_region,
                objetivo: found.objetivo,
                segmentacion: found.publico
                  ? found.publico
                  : element.segmentacion,
                dias_campaña: element.dias > 0 ? element.dias : 0,
                comentarios: element.comentarios,
                calificacion: element.calificacion,
                //"fr": 6927,
                searchable_status: element.status,
                idBudget: found.idBudget,
                searchable_fechafin: found.searchable_fechafin,
                adv: element.duracion_promedio_video
                  ? element.duracion_promedio_video
                  : "00:00:00",
                searchable_medio: found.searchable_medio,
                id_ad_product: found.id_ad_product,
                postNumber: found.postNumber,
                net_budget: element.presupuesto_neto,
                // "real_price": 0.47,
                searchable_idMixDetaill: found.id,
                searchable_name: found.name,
                reach: element.reach,
                account: found.account,
                event_responses: element.respuesta_eventos,
                postShares: element.post_shares,
                photo: element.visualizacion_fotos,
                video_playbacks_50: element.reproducciones_video_50,
                video_playbacks_100: element.reproducciones_video_100,
                spent_percent:
                  element.porcentaje_gastado > 0
                    ? element.porcentaje_gastado?.toFixed(2)
                    : "0.00",

                budget: found.budget ?? found.budget.toFixed(2), // presupuesto neto
                budgetNeto: element.presupuesto_neto,
                budgetI: element.presupuesto ?? element.presupuesto.toFixed(2),
                banner: found.banner,
                fechafin: element.fecha_fin,
                id_medium: found.id_medium,
                idMixDetaill: found.idMixDetaill,
                // "cnr": 0,
                searchable_fechaIni: found.searchable_fechaIni,
                idMix: found.idMix,
                video_playbacks_95: element.reproducciones_video_95,
                url: element.url,
                name: element.post,
                idCampaign: found.idCampaign,
                searchable_version: found.searchable_version,
                clicks: element.clicks,
                purchase_unit: element.tipo_compra,
                medioo: found.medioo,
                prints: element.impresiones,
                id: found.id,

                idMySql: element.id,
                publico: found.publico,
                swc: element.gastado_con_comision.toFixed(2),
                segmentation: element.segmentacion,
                // "age": "+18",
                leads: element.leads,
                version: found.version,
                available: element.presupuesto_disponible,
                budgetSinConsumir: element.presupuesto_disponible?.toFixed(2),
                postComments: element.post_comments,
                installs: element.instalacion_aplicaciones,
                frequency: element.frecuencia,
                modification_date: found.modification_date,
                views: element.views,
                // "daily_budget": 0,
                video_playbacks_25: element.reproducciones_video_25,
                cpa: element.cpa?.toFixed(3),
                cpc: element.cpc?.toFixed(2),
                client: element.cliente,
                post_likes: element.post_likes,
                medio: found.medio,
                idMixChange: found.idMixChange,
                cpf: element.cpf?.toFixed(2),
                cpi: element.cpi?.toFixed(2),
                // "price": 0.5,
                fans: element.fans,
                cpm: element.cpm?.toFixed(2),
                cprm: element.cost_per_estimated_ad_recallers,
                estimated_ad_recallers: element.estimated_ad_recallers,
                cpl: element.cpl?.toFixed(2),
                budgetConsumido: element.presupuesto_gastado?.toFixed(2),
                budgetI: element.presupuesto ?? element.presupuesto.toFixed(2),
                budgetSinConsumir: element.presupuesto_disponible?.toFixed(2),
                availableAmount: found.availableAmount,
                copy: found.copy,
                observation: found.observation,
                idAudience: found.idAudience,
                nameForEmail: found.nameForEmail,
                emailForEmail: found.emailForEmail,
                categoriesSelected: found.categoriesSelected,
                creativity: found.creativity,
                country: found.name_region,
                meta: element.goal,
                columnaCompare: element.metric,
              };
              if (found.categoriesSelected) {
                var categories = found.categoriesSelected;
                const categoriesGruped = categories.reduce(
                  (groups, item) => ({
                    ...groups,
                    [item.nombrePadre]: [
                      ...(groups[item.nombrePadre] || []),
                      item,
                    ],
                  }),
                  {},
                );

                let valores = Object.values(categoriesGruped);
                for (let j = 0; j < valores.length; j++) {
                  let catsString = "";
                  for (let k = 0; k < valores[j].length; k++) {
                    catsString = catsString + valores[j][k].label + ", ";
                  }
                  let cats = { [valores[j][0].padre]: catsString };

                  body = { ...body, ...cats };
                }
              }
              auxArray.push(body);
              setSearchData([...auxArray]);
              setSearchDataAlt([...auxArray]);
            } else {
              let body = {
                editable: false,
                name: element.name ? element.name : element.post,
                fechaIni: element.fecha_inicio,
                scr: element.calificacion,
                ctr: element.ctr?.toFixed(2),
                cpr: element.cpr?.toFixed(2),
                status: element.idStatus,
                video_playbacks_75: element.reproducciones_video_75,
                video_playbacks_100: element.reproducciones_video_100,
                dias_campaña: element.dias > 0 ? element.dias : 0,
                cpv: element.cpv?.toFixed(3),
                orden_venta: element.orden_venta,
                country: element.pais,
                segmentacion: element.segmentacion,
                //"fr": 6927,
                searchable_status: element.status,
                idBudget: 0,
                searchable_fechafin: "",
                adv: element.duracion_promedio_video
                  ? element.duracion_promedio_video
                  : "00:00:00",
                searchable_medio: "",
                id_ad_product: 0,
                postNumber: "",
                net_budget: element.presupuesto_neto,
                // "real_price": 0.47,
                searchable_idMixDetaill: "",
                searchable_name: "",
                reach: element.reach,
                account: "",
                event_responses: element.respuesta_eventos,
                postShares: element.post_shares,
                photo: element.visualizacion_fotos,
                video_playbacks_50: element.reproducciones_video_50,
                spent_percent:
                  element.porcentaje_gastado > 0
                    ? element.porcentaje_gastado?.toFixed(2)
                    : "0.00",
                budget:
                  element.presupuesto &&
                  new Number(element.presupuesto).toFixed(2),
                budgetNeto: element.presupuesto_neto,
                banner: element.banner,
                fechafin: element.fecha_fin,
                id_medium: 0,
                idMixDetaill: element.id_mix_detail,
                idMixDetaillActual: element.id_mix_detail,
                // "cnr": 0,
                searchable_fechaIni: "",
                idMix: 0,
                video_playbacks_95: element.reproducciones_video_95,
                url: element.url,
                name: element.post,
                idCampaign: 0,
                searchable_version: "",
                clicks: element.clicks,
                purchase_unit: element.tipo_compra,
                medioo: element.medioo,
                prints: element.impresiones,
                id: element.id,

                idMySql: element.id,
                publico: element.segmentacion,
                swc: element.gastado_con_comision.toFixed(2),
                segmentation: element.segmentacion,
                // "age": "+18",
                leads: element.leads,
                version: "",
                budgetSinConsumir: element.presupuesto_disponible?.toFixed(2),
                available: element.presupuesto_disponible,
                postComments: element.post_comments,
                installs: element.instalacion_aplicaciones,
                frequency: element.frecuencia,
                modification_date: "",
                views: element.views,
                // "daily_budget": 0,
                video_playbacks_25: element.reproducciones_video_25,
                cpa: element.cpa?.toFixed(3),
                cpc: element.cpc?.toFixed(2),
                client: element.cliente,
                post_likes: element.post_likes,
                medio: element.medio,
                idMixChange: 0,
                cpf: element.cpf?.toFixed(2),
                cpi: element.cpi?.toFixed(2),
                // "price": 0.5,
                fans: element.fans,
                cpm: element.cpm?.toFixed(2),
                cprm: element.cost_per_estimated_ad_recallers,
                estimated_ad_recallers: element.estimated_ad_recallers,
                cpl: element.cpl?.toFixed(2),
                budgetConsumido: element.presupuesto_gastado?.toFixed(2),
                budgetI: new Number(element.presupuesto).toFixed(2),
                id_sale: element.orden_venta,
                meta: element.goal,
                columnaCompare: element.metric,
              };
              auxArray.push(body);
              setSearchData([...auxArray]);
              setSearchDataAlt([...auxArray]);
            }
          }
        }
      });

      setSearchData([...auxArray]);
      setSearchDataAlt([...auxArray]);
    } else {
      let arrayData = [];
      arrayData.push(value);
      setArraynIds("");
      setActualState({
        place: "busqueda",
        data: arrayData,
      });
      localStorage.setItem(
        "state",
        JSON.stringify({
          place: "busqueda",
          data: arrayData,
        }),
      );

      if (value == "") {
        setSearchData([]);
        setFilterText(value);
        setSearch(false);

        let fechaFin = new Date();
        let fechaIn = new Date();
        fechaIn.setDate(fechaIn.getDate() - 7);
        let fechaIni = new Date(fechaIn);
        setDatesToShow({
          fechaIni,
          fechaFin,
        });
      } else {
        setFilterText(value);
        setDatesToShow({
          fechaIni: "",
          fechaFin: "",
        });
      }
    }
  };
  const refreshData = async () => {
    let estate = localStorage.getItem("state");
    let actualStatee = JSON.parse(estate);
    if (actualStatee.place === "dias") {
      props.getCampRefresh();
    }
    if (actualStatee.place === "mes") {
      setDatesToShow({
        fechaIni: actualStatee.data[5],
        fechaFin: actualStatee.data[6],
      });
      setTimeout(async () => {
        try {
          let mysqlCampaings;
          let response;
          let statusFilter = actualStatee.data[4];
          let acc = actualStatee.data[1];
          let name = actualStatee.data[2];
          let filter = actualStatee.data[3];
          if (actualStatee.data[4] == 1) {
            let limit = 200;
            let idclient = actualStatee.data[0];
            let fechaIni = actualStatee.data[5];
            let fechaFin = actualStatee.data[6];

            response = await API.getCampaignsSearch({
              statusFilter,
              account: acc,
              name,
              ...filter,
            });
            const response2 = await API.AllCampaignsNewMerged({
              account: acc,
              limit,
              idclient,
              fechaIni,
              fechaFin,
            });

            if (response2.data.error) {
              mysqlCampaings = [];
            } else {
              mysqlCampaings = response2.data.campaigns;
            }

            mysqlCampaings = response2.data.campaigns;
          } else {
            response = await API.getCampaignsSearch({
              statusFilter,
              account: acc,
              name,
              ...filter,
            });
          }

          const { campaigns, count } = response.data;
          if (mysqlCampaings) {
            var camps = mysqlCampaings;
            for (let i = 0; i < camps.length; i++) {
              let budgetAc = props.budgets?.find(
                (bud) => bud.id === camps[i].idBudget,
              );
              camps[i].budgetName = budgetAc ? budgetAc.title : "";

              if (camps[i].categoriesSelected) {
                var categories = camps[i].categoriesSelected;

                /* const grouped = groupBy(categories, cat => cat.nombrePadre); */
                const categoriesGruped = categories.reduce(
                  (groups, item) => ({
                    ...groups,
                    [item.nombrePadre]: [
                      ...(groups[item.nombrePadre] || []),
                      item,
                    ],
                  }),
                  {},
                );

                let valores = Object.values(categoriesGruped);

                for (let j = 0; j < valores.length; j++) {
                  let catsString = "";
                  for (let k = 0; k < valores[j].length; k++) {
                    catsString = catsString + valores[j][k].label + ", ";
                  }
                  let body = { [valores[j][0].padre]: catsString };

                  camps[i] = { ...camps[i], ...body };
                }
              }
            }
            setFiltered(camps);
            /* dispatch(addTotal(newCampaingsMerged.length))    
            dispatch(changePaginationTotal(newCampaingsMerged.length)) */
          } else {
            let auxArr = [];
            campaigns.forEach((campaign) => {
              /* 
              dispatch(addCampaign(campaign)) */
              auxArr.push(campaign);
            });
            setFiltered(auxArr);
            /* dispatch(addTotal(count))    
            dispatch(changePaginationTotal(count))*/
          }
        } catch (err) {
          console.log("error getCampaignsSearch", err);
        }
      }, 2000);
    }
    if (actualStatee.place === "busqueda") {
      if (searchData.length > 0) {
        setTimeout(() => {
          const mAccount = _.find(props.accounts, { id: props.account });
          let idclient = mAccount?.eAdvertising;
          API.findCampaignByMixDetail({
            idclient: idclient,
            idMixDetaill: actualStatee.data[0],
          })
            .then((res) => {
              if (res.data.count > 0) {
                setSearch(true);
                /* return res.data.campaigns[0] */
                let elementToPush;
                API.getCampaignById(res.data.campaigns[0].id_mix_detail)
                  .then((response) => {
                    let element = res.data.campaigns[0];
                    let arrayAux = filtered;
                    if (response.data.campaigns.length > 0) {
                      let found = response.data.campaigns[0];
                      let body = [
                        {
                          editable: true,
                          scr: element.calificacion,
                          fechaIni: element.fecha_inicio,
                          ctr: element.ctr,
                          cpr: element.cpr,
                          status: element.idStatus,
                          video_playbacks_75: element.reproducciones_video_75,
                          cpv: element.cpv,
                          orden_venta: element.orden_venta,
                          id_sale: element.orden_venta,
                          id_region: found.id_region,
                          name_region: found.name_region,
                          objetivo: found.objetivo,
                          segmentacion: found.publico
                            ? found.publico
                            : element.segmentacion,
                          dias_campaña: element.dias > 0 ? element.dias : 0,
                          comentarios: element.comentarios,
                          calificacion: element.calificacion,
                          //"fr": 6927,
                          searchable_status: element.status,
                          idBudget: found.idBudget,
                          searchable_fechafin: found.searchable_fechafin,
                          adv: element.duracion_promedio_video,
                          searchable_medio: found.searchable_medio,
                          id_ad_product: found.id_ad_product,
                          postNumber: found.postNumber,
                          net_budget: element.presupuesto_neto,
                          // "real_price": 0.47,
                          searchable_idMixDetaill: found.id,
                          searchable_name: found.name,
                          reach: element.reach,
                          account: found.account,
                          event_responses: element.respuesta_eventos,
                          postShares: element.post_shares,
                          photo: element.visualizacion_fotos,
                          video_playbacks_50: element.reproducciones_video_50,
                          video_playbacks_100: element.reproducciones_video_100,
                          spent_percent: element.porcentaje_gastado,
                          budget: found.budget, // presupuesto neto
                          budgetNeto: element.presupuesto_neto,
                          budgetI: element.presupuesto,
                          banner: found.banner,
                          fechafin: element.fecha_fin,
                          id_medium: found.id_medium,
                          idMixDetaill: found.idMixDetaill,
                          // "cnr": 0,
                          searchable_fechaIni: found.searchable_fechaIni,
                          idMix: found.idMix,
                          video_playbacks_95: element.reproducciones_video_95,
                          url: element.url,
                          name: element.post,
                          idCampaign: found.idCampaign,
                          searchable_version: found.searchable_version,
                          clicks: element.clicks,
                          purchase_unit: element.tipo_compra,
                          medioo: found.medioo,
                          prints: element.impresiones,
                          id: element.id,

                          idMySql: element.id,
                          publico: found.publico,
                          swc: element.gastado_con_comision,
                          segmentation: element.segmentacion,
                          // "age": "+18",
                          leads: element.leads,
                          version: found.version,
                          available: element.presupuesto_disponible,
                          budgetSinConsumir: element.presupuesto_disponible,
                          postComments: element.post_comments,
                          installs: element.instalacion_aplicaciones,
                          frequency: element.frecuencia,
                          modification_date: found.modification_date,
                          views: element.views,
                          // "daily_budget": 0,
                          video_playbacks_25: element.reproducciones_video_25,
                          cpa: element.cpa,
                          cpc: element.cpc,
                          client: element.cliente,
                          post_likes: element.post_likes,
                          medio: found.medio,
                          idMixChange: found.idMixChange,
                          cpf: element.cpf,
                          cpi: element.cpi,
                          // "price": 0.5,
                          fans: element.fans,
                          cpm: element.cpm,
                          cprm: element.cost_per_estimated_ad_recallers,
                          estimated_ad_recallers:
                            element.estimated_ad_recallers,
                          cpl: element.cpl,
                          budgetConsumido: element.presupuesto_gastado,
                          budgetI: element.presupuesto,
                          budgetSinConsumir: element.presupuesto_disponible,
                          availableAmount: found.availableAmount,
                          copy: found.copy,
                          observation: found.observation,
                          idAudience: found.idAudience,
                          nameForEmail: found.nameForEmail,
                          emailForEmail: found.emailForEmail,
                          categoriesSelected: found.categoriesSelected,
                          creativity: found.creativity,
                          country: found.name_region,
                          meta: element.goal,
                          columnaCompare: element.metric,
                        },
                      ];
                      if (found.categoriesSelected) {
                        var categories = found.categoriesSelected;
                        const categoriesGruped = categories.reduce(
                          (groups, item) => ({
                            ...groups,
                            [item.nombrePadre]: [
                              ...(groups[item.nombrePadre] || []),
                              item,
                            ],
                          }),
                          {},
                        );

                        let valores = Object.values(categoriesGruped);
                        for (let j = 0; j < valores.length; j++) {
                          let catsString = "";
                          for (let k = 0; k < valores[j].length; k++) {
                            catsString =
                              catsString + valores[j][k].label + ", ";
                          }
                          let cats = { [valores[j][0].padre]: catsString };

                          body = { ...body, ...cats };
                        }
                      }
                      setSearchData(body);
                    } else {
                      let body = [
                        {
                          editable: false,
                          name: element.name ? element.name : element.post,
                          fechaIni: element.fecha_inicio,
                          scr: element.calificacion,
                          ctr: element.ctr,
                          cpr: element.cpr,
                          status: element.idStatus,
                          video_playbacks_75: element.reproducciones_video_75,
                          video_playbacks_100: element.reproducciones_video_100,
                          dias_campaña: element.dias > 0 ? element.dias : 0,
                          cpv: element.cpv,
                          orden_venta: element.orden_venta,
                          country: element.pais,
                          segmentacion: element.segmentacion,
                          //"fr": 6927,
                          searchable_status: element.status,
                          idBudget: 0,
                          searchable_fechafin: "",
                          adv: element.duracion_promedio_video,
                          searchable_medio: "",
                          id_ad_product: 0,
                          postNumber: "",
                          net_budget: element.presupuesto_neto,
                          // "real_price": 0.47,
                          searchable_idMixDetaill: "",
                          searchable_name: "",
                          reach: element.reach,
                          account: "",
                          event_responses: element.respuesta_eventos,
                          postShares: element.post_shares,
                          photo: element.visualizacion_fotos,
                          video_playbacks_50: element.reproducciones_video_50,
                          spent_percent: element.porcentaje_gastado,
                          budget: element.presupuesto,
                          budgetNeto: element.presupuesto_neto,
                          banner: element.banner,
                          fechafin: element.fecha_fin,
                          id_medium: 0,
                          idMixDetaill: element.id_mix_detail,
                          idMixDetaillActual: element.id_mix_detail,
                          // "cnr": 0,
                          searchable_fechaIni: "",
                          idMix: 0,
                          video_playbacks_95: element.reproducciones_video_95,
                          url: element.url,
                          name: element.post,
                          idCampaign: 0,
                          searchable_version: "",
                          clicks: element.clicks,
                          purchase_unit: element.tipo_compra,
                          medioo: element.medioo,
                          prints: element.impresiones,
                          id: element.id,

                          idMySql: element.id,
                          publico: element.segmentacion,
                          swc: element.gastado_con_comision,
                          segmentation: element.segmentacion,
                          // "age": "+18",
                          leads: element.leads,
                          version: "",
                          budgetSinConsumir: element.presupuesto_disponible,
                          available: element.presupuesto_disponible,
                          postComments: element.post_comments,
                          installs: element.instalacion_aplicaciones,
                          frequency: element.frecuencia,
                          modification_date: "",
                          views: element.views,
                          // "daily_budget": 0,
                          video_playbacks_25: element.reproducciones_video_25,
                          cpa: element.cpa,
                          cpc: element.cpc,
                          client: element.cliente,
                          post_likes: element.post_likes,
                          medio: element.medio,
                          idMixChange: 0,
                          cpf: element.cpf,
                          cpi: element.cpi,
                          // "price": 0.5,
                          fans: element.fans,
                          cpm: element.cpm,
                          cprm: element.cost_per_estimated_ad_recallers,
                          estimated_ad_recallers:
                            element.estimated_ad_recallers,
                          cpl: element.cpl,
                          budgetConsumido: element.presupuesto_gastado,
                          budgetI: element.presupuesto,
                          budgetSinConsumir: element.presupuesto_disponible,
                          id_sale: element.orden_venta,
                          meta: element.goal,
                          columnaCompare: element.metric,
                        },
                      ];
                      setSearchData(body);
                      /* setFiltered(arrayAux) */
                    }
                  })
                  .catch((err) => console.log("errDYNAMO", err));
              }
            })
            .catch((err) => {
              console.log("ErrorCampaignByMixDetaill", err);
            });
        }, 2000);
      } else {
        let filterTextAux = filterText;
        props.getCampRefresh();
        setFilterText(filterTextAux);
      }
    }
  };

  const handleCalendarTextChange = (value) => {
    let ini = document.getElementById("start-date-input-button")?.children[1]
      ?.innerHTML;
    let fin = document.getElementById("end-date-input-button")?.children[0]
      ?.innerHTML;
    // setFilterText(value)
    handleFilterTextChange(value + "+");
  };
  const [nameGrouped, setnameGrouped] = useState("");
  const [nameSelectedGrouped, setnameSelectedGrouped] = useState("");
  const changeGrouped = (value) => {
    // setLoad(true)
    let opciones = optionss;
    setLoad(true);
    switch (value) {
      case "Por defecto":
        setnameGrouped("");
        opciones.splice(0, 0, "Por defecto");
        setnameSelectedGrouped("");
        break;
      case "Medio":
        opciones.splice(0, 0, "Medio");
        opciones.splice(1, 1, "Por defecto");
        setnameGrouped("medio");
        setnameSelectedGrouped("Medio");
        break;
      case "Pais":
        opciones.splice(0, 0, "Pais");
        opciones.splice(1, 1, "Por defecto");
        setnameGrouped("country");
        setnameSelectedGrouped("Pais");
        break;
      case "Tipo de compra":
        setnameSelectedGrouped("Tipo de compra");
        opciones.splice(0, 0, "Tipo de compra");
        opciones.splice(1, 1, "Por defecto");
        setnameGrouped("purchase_unit");
        break;
      case "Banner":
        opciones.splice(0, 0, "Banner");
        opciones.splice(1, 1, "Por defecto");
        setnameSelectedGrouped("Banner");
        setnameGrouped("banner");
        break;
      case "Estado":
        opciones.splice(0, 0, "Estado");
        opciones.splice(1, 1, "Por defecto");
        setnameSelectedGrouped("Estado");
        setnameGrouped("status");
        break;
      default:
        setnameSelectedGrouped("select");
        opciones.splice(0, 0, value);
        opciones.splice(1, 1, "Por defecto");
        let nameId = categoriesSorted.filter((ele) => ele.name == value);
        setnameGrouped(nameId[0].id);
        break;
    }
    let result = opciones.filter((item, idx) => {
      return opciones.indexOf(item) === idx;
    });
    setOptionss(result);
    setTimeout(() => {
      setLoad(false);
    }, 500);
  };
  let dataSelect =
    searchFilterData.length > 0
      ? searchFilterData
      : searchData.length > 0
        ? searchData
        : filteredData;
  // let data = searchFilterData.length > 0 ? searchFilterData : vacio ? [] : filteredData

  let catSelected = dataSelect.filter((ele) => {
    if (ele.categoriesSelected != undefined) {
      if (ele.categoriesSelected != "") {
        if (ele.categoriesSelected != 0) {
          return ele.categoriesSelected;
        }
      }
    }
  });

  const groupedCategorieSelect = (namePadre, titulo, idx) => {
    let datosAgrupados = [];
    let totales = [];
    catSelected.forEach((ele) => {
      ele.categoriesSelected.forEach((cats) => {
        if (cats.nombrePadre == namePadre && cats.label == titulo) {
          datosAgrupados.push(ele);
        }
      });
    });
    return (
      <>
        <h1>{titulo}</h1>

        <Table
          columns={columns}
          campaigns={datosAgrupados}
          groupedBy={groupedBy}
          customStyles={customStyles}
          setFilteredData={
            searchFilterData.length > 0 ? setSearchFilterData : setFiltered
          }
          className="tablaDashboard"
          setRowsPerPage={setRowsPerPage}
        />
      </>
    );
  };
  const groupedSelectData = (datos, filt) => {
    let datosAgrupados = [];
    let infoTotales = [];
    let nameGroupedd = "";
    filteredData.forEach((ele) => {
      if (namesCopy.includes(nameSelectedGrouped)) {
        if (ele[nameGrouped] == datos) {
          infoTotales.push(ele);
          datosAgrupados.push(ele);
        }
      } else {
        if (ele.categoriesSelected != "") {
          ele.categoriesSelected?.forEach((cats) => {
            if (cats.nombrePadre == nameGrouped) {
              nameGroupedd = ele.label;
              infoTotales.push(ele);
              datosAgrupados.push(ele);
            }
          });
        }
      }
    });
    let pagData = [];
    let presupuestoNeto = 0;
    let presupuestoInvertido = 0;
    let estimatedAdRecallers = 0;
    let presupuestoConsumido = 0;
    let presupuestoComision = 0;
    let presupuestoSinConsumir = 0;
    let totalPorcentajePresupuestoConsumido = 0;
    let porcentajePresupuestoConsumido = 0;
    let contPresupuestoConsumido = 0;
    let visualizacionesImagen = 0;
    let eventResponses = 0;
    let appInstalations = 0;
    let frequency = 0;
    let totalFrequency = 0;
    let contFrequency = 0;
    let leads = 0;
    let cpf = 0;
    let totalCpf = 0;
    let contCpf = 0;
    let cpre = 0;
    let totalCpre = 0;
    let contCpre = 0;
    let cpi = 0;
    let totalCpi = 0;
    let contCpi = 0;
    let cpv = 0;
    let totalCpv = 0;
    let contCpv = 0;
    let ctr = 0;
    let totalCtr = 0;
    let contCtr = 0;
    let cpl = 0;
    let totalCpl = 0;
    let contCpl = 0;
    let cpa = 0;
    let totalCpa = 0;
    let contCpa = 0;
    let cpc = 0;
    let totalCpc = 0;
    let contCpc = 0;
    let cpm = 0;
    let totalCpm = 0;
    let contCpm = 0;
    let cprm = 0;
    let totalCprm = 0;
    let contCprm = 0;
    let prints = 0;
    let clicks = 0;
    let fans = 0;
    let reach = 0;
    let views = 0;
    let meta = 0;
    let video_playbacks_25 = 0;
    let video_playbacks_50 = 0;
    let video_playbacks_75 = 0;
    let video_playbacks_95 = 0;
    let video_playbacks_100 = 0;
    let adv = 0;
    let totalAdv = 0;
    let contAdv = 0;
    let postShares = 0;
    let postLikes = 0;
    let totalDiasCamp = 0;
    let diasCamp = 0;
    let contDiasCamp = 0;
    let comments = 0;

    let percentage = 0;
    let contPercentage = 0;
    let totalPercentage = 0;
    let valueTotalPercentage = 0;
    let totales = [];
    for (let x = 0; x < datosAgrupados.length; x++) {
      presupuestoNeto += new Number(
        datosAgrupados[x].budget
          ? datosAgrupados[x].budget
          : datosAgrupados[x].net_budget,
      );

      let percentageValue = getPercentNumber(datosAgrupados[x]);

      if (percentageValue != "NA") {
        percentage += new Number(percentageValue);
        contPercentage++;
      }

      let percentageTot = getPercentValue(datosAgrupados[x]);
      if (percentageTot != "NA") {
        valueTotalPercentage += new Number(percentageTot);
      }

      if (new Number(datosAgrupados[x].budget) > 0) {
        presupuestoInvertido += new Number(datosAgrupados[x].budget);
      }

      if (datosAgrupados[x].meta != null) {
        meta += new Number(datosAgrupados[x].meta);
      }

      if (new Number(datosAgrupados[x].estimated_ad_recallers)) {
        estimatedAdRecallers += new Number(
          datosAgrupados[x].estimated_ad_recallers,
        );
      }

      if (datosAgrupados[x].budgetConsumido > 0) {
        presupuestoConsumido += new Number(datosAgrupados[x].budgetConsumido);
      } else {
        presupuestoConsumido += new Number(
          getTotalspent(datosAgrupados[x].idMixDetaill),
        );
      }

      if (datosAgrupados[x].swc) {
        presupuestoComision += new Number(datosAgrupados[x].swc);
      }

      if (datosAgrupados[x].budgetSinConsumir) {
        presupuestoSinConsumir += new Number(
          datosAgrupados[x].budgetSinConsumir,
        );
      }

      if (datosAgrupados[x].photo > 0) {
        visualizacionesImagen += new Number(datosAgrupados[x].photo);
      }

      if (datosAgrupados[x].spent_percent) {
        porcentajePresupuestoConsumido += new Number(
          datosAgrupados[x].spent_percent,
        );
        contPresupuestoConsumido++;
      }
      if (datosAgrupados[x].cprm) {
        cprm += new Number(datosAgrupados[x].cprm);
        contCprm++;
      }
      if (datosAgrupados[x].cpf) {
        cpf += new Number(datosAgrupados[x].cpf);
        contCpf++;
      }
      if (datosAgrupados[x].cpre) {
        cpre += new Number(datosAgrupados[x].cpre);
        contCpre++;
      }
      if (datosAgrupados[x].cpi) {
        cpi += new Number(datosAgrupados[x].cpi);
        contCpi++;
      }
      if (datosAgrupados[x].cpv) {
        cpv += new Number(datosAgrupados[x].cpv);
        contCpv++;
      }
      if (datosAgrupados[x].ctr) {
        ctr += new Number(datosAgrupados[x].ctr);
        contCtr++;
      }
      if (datosAgrupados[x].leads) {
        leads += new Number(datosAgrupados[x].leads);
      }
      if (datosAgrupados[x].event_responses) {
        eventResponses += new Number(datosAgrupados[x].event_responses);
      }
      if (datosAgrupados[x].installs) {
        appInstalations += new Number(datosAgrupados[x].installs);
      }
      if (datosAgrupados[x].frequency) {
        frequency += new Number(datosAgrupados[x].frequency);
        contFrequency++;
      }
      if (datosAgrupados[x].cpl) {
        cpl += new Number(datosAgrupados[x].cpl);
        contCpl++;
      }
      if (datosAgrupados[x].cpa) {
        cpa += new Number(datosAgrupados[x].cpa);
        contCpa++;
      }
      if (datosAgrupados[x].cpc) {
        cpc += new Number(datosAgrupados[x].cpc);
        contCpc++;
      }
      if (datosAgrupados[x].cpm) {
        cpm += new Number(datosAgrupados[x].cpm);
        contCpm++;
      }
      if (datosAgrupados[x].prints) {
        prints += new Number(datosAgrupados[x].prints);
      }
      if (datosAgrupados[x].clicks) {
        clicks += datosAgrupados[x].clicks;
      }
      if (datosAgrupados[x].fans) {
        fans += datosAgrupados[x].fans;
      }
      if (datosAgrupados[x].reach) {
        reach += datosAgrupados[x].reach;
      }
      if (datosAgrupados[x].views) {
        views += datosAgrupados[x].views;
      }
      if (datosAgrupados[x].video_playbacks_25) {
        video_playbacks_25 += datosAgrupados[x].video_playbacks_25;
      }
      if (datosAgrupados[x].video_playbacks_50) {
        video_playbacks_50 += datosAgrupados[x].video_playbacks_50;
      }
      if (datosAgrupados[x].video_playbacks_75) {
        video_playbacks_75 += datosAgrupados[x].video_playbacks_75;
      }
      if (datosAgrupados[x].video_playbacks_95) {
        video_playbacks_95 += datosAgrupados[x].video_playbacks_95;
      }
      if (datosAgrupados[x].video_playbacks_100) {
        video_playbacks_100 += datosAgrupados[x].video_playbacks_100;
      }
      if (datosAgrupados[x].adv) {
        var a = datosAgrupados[x].adv.split(":");
        var seconds = +a[0] * 60 * 60 + +a[1] * 60 + +a[2];
        adv += seconds;
        contAdv++;
      }
      if (datosAgrupados[x].postShares) {
        postShares += datosAgrupados[x].postShares;
      }
      if (datosAgrupados[x].post_likes > 0) {
        postLikes += datosAgrupados[x].post_likes;
      }
      if (datosAgrupados[x].dias_campaña) {
        diasCamp += datosAgrupados[x].dias_campaña;
        contDiasCamp++;
      }
      if (datosAgrupados[x].postComments) {
        comments += datosAgrupados[x].postComments;
      }
    }
    totalAdv = adv / contAdv;
    totalCpm = cpm / contCpm;
    totalCpc = cpc / contCpc;
    totalCpa = cpa / contCpa;
    totalCpl = cpl / contCpl;
    totalCtr = ctr / contCtr;
    totalCpv = cpv / contCpv;
    totalCpi = cpi / contCpi;
    totalCpre = cpre / contCpre;
    totalCpf = cpf / contCpf;
    totalCprm = cprm / contCprm;
    totalPorcentajePresupuestoConsumido =
      porcentajePresupuestoConsumido / contPresupuestoConsumido;
    totalFrequency = frequency / contFrequency;
    totalDiasCamp = diasCamp / contDiasCamp;
    if (contPercentage != 0) {
      totalPercentage = percentage / contPercentage;
    } else {
      totalPercentage = "NA";
    }
    totales = [
      {
        /*  "adv": totalAdv>0 ? totalAdv.toFixed(2) : 0, */
        adv:
          totalAdv > 0
            ? new Date(totalAdv * 1000).toISOString().substr(11, 8)
            : "00:00:00",
        ctr: totalCtr > 0 ? totalCtr.toFixed(2) : "0.00",
        cpc: totalCpc > 0 ? totalCpc.toFixed(2) : "0.00",
        estimated_ad_recallers: estimatedAdRecallers,
        cprm: totalCprm > 0 ? totalCprm : "0",
        photo: visualizacionesImagen,
        video_playbacks_50: video_playbacks_50,
        video_playbacks_25: video_playbacks_25,
        video_playbacks_75: video_playbacks_75,
        video_playbacks_95: video_playbacks_95,
        video_playbacks_100: video_playbacks_100,
        cpv: totalCpv > 0 ? totalCpv.toFixed(3) : "0.000",
        prints: prints,
        country: "",
        clicks: clicks,
        views: views,
        fr: 0,
        searchable_status: "",
        idBudget: 0,
        swc: presupuestoComision.toFixed(2),
        budgetConsumido: presupuestoConsumido.toFixed(2),
        budgetI: presupuestoInvertido?.toFixed(2),
        budget: presupuestoInvertido?.toFixed(2),
        status: 100,
        budgetSinConsumir: presupuestoSinConsumir.toFixed(2),
        installs: appInstalations,
        spent_percent:
          totalPorcentajePresupuestoConsumido > 0
            ? totalPorcentajePresupuestoConsumido.toFixed(2)
            : "0.00",
        leads: leads > 0 ? leads : 0,
        reach: reach,
        event_responses: eventResponses > 0 ? eventResponses.toFixed(0) : 0,
        name: "Totales",
        metaTotales: meta,
        totalPercentage:
          totalPercentage != "NA"
            ? valueTotalPercentage.toFixed(0) +
            "/" +
            totalPercentage.toFixed(0) +
            "%105"
            : "NA",
        client: "0",
        post_likes: postLikes,
        medio: "",
        idMixChange: 0,
        frequency: totalFrequency > 0 ? totalFrequency.toFixed(2) : 0,
        cpf: totalCpf > 0 ? totalCpf.toFixed(2) : "0.00",
        cpr: totalCpre > 0 ? totalCpre.toFixed(2) : "0.00",
        cpi: totalCpi > 0 ? totalCpi.toFixed(2) : "0.00",
        fans: fans,
        cpm: totalCpm > 0 ? totalCpm.toFixed(2) : "0.00",
        cpl: totalCpl > 0 ? totalCpl.toFixed(2) : "0.00",
        cpa: totalCpa > 0 ? totalCpa.toFixed(3) : "0.000",
        postShares: postShares,
        dias_campaña: totalDiasCamp > 0 ? totalDiasCamp.toFixed(0) : 0,
        postComments: comments,
      },
    ];
    return (
      <>
        {nameGrouped == "status" ? (
          <h1>{getStatusLabel(datos)}</h1>
        ) : (
          <h1>{datos}</h1>
        )}
        <Table
          columns={columns}
          campaigns={datosAgrupados}
          groupedBy={groupedBy}
          customStyles={customStyles}
          expandable={true}
          setFilteredData={
            searchFilterData.length > 0 ? setSearchFilterData : setFiltered
          }
          className="tablaDashboard"
          setRowsPerPage={setRowsPerPage}
        />

        <ReactDataTable
          className="CampaTabla2"
          columns={columns}
          groupedBy={groupedBy}
          data={totales}
        />
      </>
    );
  };

  const onChangeGoal = (e) => {
    let auxKpi = stateKpi;
    auxKpi.goal = e;
    setStateKpi({
      ...auxKpi,
    });
  };
  const onSaveModal2 = async () => {
    setLoad(true);
    let response = await API.updateGoal({
      id: stateKpi.id,
      goal: new Number(stateKpi.goal),
    });
    setLoad(false);
    setShowModal2(false);
    Swal.fire({
      title: "Confirmación",
      text: "Meta agregada con éxito!",
      icon: "success",
      showCancelButton: false,
      confirmButtonColor: "#3085d6",
      confirmButtonText: "Ok",
    });
    refreshData();
  };

  const getKpiName = (name) => {
    if (name === "post_likes") {
      return "Reacciones";
    } else if (name === "page_comments") {
      return "Comentarios";
    } else if (name === "page_shares") {
      return "Compartidos";
    } else if (name === "video_avg_time") {
      return "Duración promedio de vistas";
    } else if (name === "video_playbacks_100") {
      return "Reproducciones 100%";
    } else if (name === "video_playbacks_95") {
      return "Reproducciones 95%";
    } else if (name === "video_playbacks_75") {
      return "Reproducciones 75%";
    } else if (name === "video_playbacks_50") {
      return "Reproducciones 50%";
    } else if (name === "video_playbacks_25") {
      return "Reproducciones 25%";
    } else if (name === "views") {
      return "Vistas";
    } else if (name === "reach") {
      return "Alcance";
    } else if (name === "fans") {
      return "Fans";
    } else if (name === "clicks") {
      return "Clicks";
    } else if (name === "prints") {
      return "Impresiones";
    } else if (name === "frequency") {
      return "Frecuencia";
    } else if (name === "estimated_ad_recallers") {
      return "Reconocimiento de marca (personas)";
    } else if (name === "mobile_app_install") {
      return "Instalaciones";
    } else if (name === "event_responses") {
      return "Respuesta a eventos";
    } else if (name === "leads") {
      return "Leads";
    } else if (name === "photo_view") {
      return "Visualizaciones de imágenes";
    }
  };

  let filterTimeout;
  let nombres = [];
  let nombresCats = [];
  let dataFil = [];
  let dataCategoriesSelected = [];
  filteredData.forEach((ele) => {
    if (ele.banner) {
      let body = {
        nombrePadre: "Banner",
        name: ele["banner"],
      };
      dataFil.push(body);
    }
    if (ele.country) {
      let body = {
        nombrePadre: "Pais",
        name: ele["country"],
      };
      dataFil.push(body);
    }
    if (ele.status) {
      let body = {
        nombrePadre: "Estado",
        name: ele.searchable_status,
      };
      dataFil.push(body);
    }
    if (ele.purchase_unit) {
      let body = {
        nombrePadre: "Tipo de compra",
        name: ele["purchase_unit"],
      };
      dataFil.push(body);
    }
    if (ele.medio) {
      let body = {
        nombrePadre: "Medio",
        name: ele["medio"],
      };
      dataFil.push(body);
    }
    if (ele.categoriesSelected?.length > 0) {
      ele.categoriesSelected?.forEach((elCat) => {
        dataCategoriesSelected.push(elCat);
      });
    }
  });
  dataCategoriesSelected.forEach((ele) => {
    categoriesSorted.forEach((cate) => {
      if (cate.id == ele.padre) {
        let body = {
          nombrePadre: cate.name,
          name: ele.label,
        };
        dataFil.push(body);
      }
    });
  });

  const [showStepsWizard, setShowStepsWizard] = useState(false);

  if (props.campaigns)
    return (
      <>
        {load ? (
          <PageLoader />
        ) : props.campaigns?.length > 0 ? (
          <>
            <div className="content-menu contenedorDashboard">
              <div className="barraSuperior">
                <select
                  id="viewSelect"
                  onChange={changeView}
                  className="select-dashboard fa"
                >
                  <Tooltip
                    title="Vista de lista"
                    color="#002448"
                    placement="top"
                  >
                    <option className="fa" value="1">
                      {" "}
                      &#xf0ce; Vista de lista
                    </option>
                  </Tooltip>
                  <Tooltip
                    title="Vista de Kanban"
                    color="#002448"
                    placement="top"
                  >
                    <option className="fa" value="0">
                      {" "}
                      &#xf0db; Vista de Kanban{" "}
                    </option>
                  </Tooltip>
                  <Tooltip
                    title="Vista de calendario"
                    color="#002448"
                    placement="top"
                  >
                    <option className="fa" value="2">
                      {" "}
                      &#xf073; Vista de calendario
                    </option>
                  </Tooltip>
                </select>
                <DataTableUtils
                  id="data1"
                  action={() => {
                    const mCurrentAccount =
                      props.currentUser !== ""
                        ? props.currentAccount
                        : props.currentPWAAccount;

                    if (mCurrentAccount === "") {
                      Swal.fire({
                        title: "Cuenta Requerida",
                        text: "Para utilizar esta sección, debes crear una cuenta.",
                        icon: "warning",
                      }).then((response) => {
                        if (!response.isConfirmed) return;
                        if (props?.accounts?.length > 0) return;
                        //setShowStepsWizard(true);
                        props._nexStep(15);
                      });
                      return;
                    }
                    showModalCamp();
                    setShowModal(true);
                    analytics.Add({
                      ubicacion: "Dashboard",
                    });
                  }}
                  onChangeFilterText={(value) => {
                    setFilterText(value);
                    onChangeFilter(value);
                  }}
                  actionIcon={
                    <Tooltip
                      title="Crear campaña"
                      color="#002448"
                      placement="top"
                    >
                      <i className="fas fa-plus" />
                    </Tooltip>
                  }
                >
                  <Tooltip
                    title="Personalizar columnas"
                    placement="top"
                    color="#002448"
                  >
                    <div id="shareBtn">
                      <ShowColumn2
                        columns={columns}
                        showColumn={handleShowColumnChange}
                        showView={handleShowView}
                        onSortChange={handleColumnSortChange}
                        vistas={views}
                        setVista={(nombre) => {
                          analytics.accionesVista({
                            ubicacion: "Dashboard",
                            accion: "Agregar",
                          });
                          handleAddView(nombre);
                        }}
                        deleteView={(name) => {
                          analytics.accionesVista({
                            ubicacion: "Dashboard",
                            accion: "ELiminar",
                          });
                          handleDeleteView(name);
                        }}
                        nameAc={nameView}
                      />
                    </div>
                  </Tooltip>
                  <Tooltip
                    title="Filtrar y agrupar"
                    placement="top"
                    color="#002448"
                  >
                    <div className="Filtro2 filtro3">
                      <FilterColumn2
                        options={optionss}
                        changeGrouped={changeGrouped}
                        columns={newColumnsExa}
                        data={dataFil}
                        onFilterColumn={handleFilterPerColumn}
                        filterInfoCheck={filterInfo}
                        categories={namesCate}
                      />
                    </div>
                  </Tooltip>
                  <Tooltip
                    title="Seleccionar fecha"
                    color="#002448"
                    placement="top"
                  >
                    <div
                      onClick={() => setShowCalendar((prev) => !prev)}
                      ref={modalRefPwa}
                      className="boxCalendar"
                    >
                      <div className="boxStartEndDate">
                        <p className="startDate">
                          Del{" "}
                          {datesToShow.fechaIni != ""
                            ? new Date(datesToShow.fechaIni).toLocaleDateString(
                              "es-GT",
                            )
                            : datesToShow.fechaIni}
                        </p>
                        <p className="endDate">
                          {" "}
                          Hasta{" "}
                          {datesToShow.fechaFin != ""
                            ? new Date(datesToShow.fechaFin).toLocaleDateString(
                              "es-GT",
                            )
                            : datesToShow.fechaFin}
                        </p>
                      </div>
                      <div onClick={(e) => e.stopPropagation()}>
                        {showCalendar && (
                          <DateRangeFilter
                            onChangeFilterText={handleFilterTextChange}
                            onChange={handleFilterTextChange}
                            externalDates={isCustomDate}
                            onActivate={showCalendar}
                            customDates={customDates}
                          />
                        )}
                      </div>
                    </div>
                  </Tooltip>
                </DataTableUtils>
              </div>
              {sendView(filteredData, customStyles)}
              {nameSelectedGrouped == "select" &&
                state.data == 1 &&
                catSelected.map((ele) => (
                  <>
                    {ele.categoriesSelected.map((cats, idx) => {
                      if (
                        !nombresCats.includes(cats.label) &&
                        cats.padre == nameGrouped
                      ) {
                        nombresCats.push(cats.label);
                        return groupedCategorieSelect(
                          cats.nombrePadre,
                          cats.label,
                          idx,
                        );
                      }
                    })}
                  </>
                ))}
            </div>
            {showModal2 && (
              <div className="blur-div modalKpi">
                <div className="content-modal modalKpicontent">
                  <div className="closeModal-content">
                    <i
                      className="fas fa-times-circle close"
                      onClick={(e) => setShowModal2(false)}
                    ></i>
                  </div>
                  <div>
                    <h3 className="titleModal titleKPI"> Ingrese la meta</h3>
                  </div>

                  <div className="body-modal bodyModalComment">
                    <div className="row1">
                      <label className="label1">
                        Meta de {getKpiName(stateKpi.columnaCompare)}
                      </label>
                      <CurrencyInput
                        intlConfig={{ locale: "es-GT" }}
                        name="amount"
                        className="input1"
                        style={{
                          color: "#05252d",
                        }}
                        value={stateKpi.goal}
                        onValueChange={onChangeGoal}
                        placeholder="P. ejemplo 1000.25"
                      />
                    </div>
                  </div>

                  <div className="footer-Modal">
                    <div className="buttons-Modal-content">
                      <button
                        className="btnModal btnCancelarModal"
                        onClick={(e) => setShowModal2(false)}
                      >
                        Cancelar
                      </button>
                      <button
                        className="btnModal btnGuardarModal"
                        onClick={(e) => onSaveModal2()}
                      >
                        Guardar
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </>
        ) : (
          <>
            {props.currentAccount !== ""
              ?
              <Campana />
              : props.stepWizard.stepLogout === 14 &&
              <div className="form homeSinLogin">
                <div>
                  <p className="subTitle">Bienvenido a Let's Advertise</p>
                  <h1 className="title">Crea tu primera campaña</h1>
                  <a
                    href="#"
                    className="btn-plus"
                    onClick={() => {
                      const mCurrentAccount =
                        props.currentUser !== ""
                          ? props.currentAccount
                          : props.currentPWAAccount;

                      if (mCurrentAccount === "") {
                        Swal.fire({
                          title: "Cuenta Requerida",
                          text: "Para utilizar esta sección, debes crear una cuenta.",
                          icon: "warning",
                        }).then((response) => {
                          console.log('VCaldiadno las propiedades ', { props })
                          if (!response.isConfirmed) return;
                          if (props?.accounts?.length > 0) return;
                          props._nexStep(15);
                        });
                        return;
                      }
                    }}
                  >
                    <i className="fas fa-plus" />
                  </a>
                </div>
                <div></div>
              </div>
            }
          </>
        )}
        {showModal && (
          <div className="blur-div blurNewPauta">
            <div
              className={classnames("upsert-modal w-75 h-75 modalapauta")}
              style={{
                backgroundColor: "white",
                padding: "20px",
                borderRadius: "10px",
              }}
            >
              <ModalPauta
                isSupAdmin={true}
                permiss={permissionsBar}
                dataEnviada={dataEnviada}
                setShowModal={setShowModal}
                audiences={audiences}
                campaignupd={campEdit}
                setRefresh={() => refreshData()}
                refresh={refresh}
                _clearCampaignupd={props._clearCampaignupd}
              />
            </div>
          </div>
        )}
        {props.currentAccount === "" && <CampanaWithAccount />}
        {props.step == 1 && <CreateAccountLogOut clase={"component"} />}
      </>
    );
};

const DashboardPWA = (props) => {
  const [account, setAccount] = useState(props.currentAccount);
  const [banderaSpent, setBanderaSpent] = useState(0);
  const [counter, setCounter] = useState(0);
  const [infoData, setInfoData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [refresh, setRefresh] = useState(false);
  const [campsToSend, setCampsToSend] = useState([]);
  const [isAdmin, setIsAdmin] = useState(true);
  const [permissions, setPermissions] = useState({});

  const getCamp = async () => {
    try {
      setIsLoading(true);
      let names = [];
      const apiUrl = "https://api.ipify.org?format=json";
      const responseGeo = await fetch(apiUrl);
      const data = await responseGeo.json();
      const idUser = data.ip;
      let categories = await APILA.getCategories(props.currentAccount);
      let audiences = await APILA.getAudiences(account);
      let budgets = await APILA.getBudgets(account);
      let accounts = await APILA.getAccountById({ id: account });
      let countries = await API.getContryes();
      if (countries.data.pautas.length > 0) {
        countries.data.pautas.forEach((ele) => {
          names.push(ele.name);
        });
      }
      setInfoData({
        categories: categories.data.categories,
        audiences: audiences.data.audiences,
        budgets: budgets.data.budgets,
        accounts: accounts.data.account,
        allCampaigns: [],
        countries: names,
        countriesPwa: countries.data.pautas,
      });

      //Obtener campañas en base a la cuenta
      let campaigns = await API.AllCampsByAccountOnlyDynamo({
        account: account,
      });
      let options = campaigns.data.campaigns;
      options?.forEach((ele) => {
        let audienciaFilter = audiences.data.audiences.map(
          (audiences) => audiences.name,
        );
        const budget = budgets.data.budgets.find(
          (bud) => bud.id === ele.idBudget,
        );
        ele.options = [];
        ele.optionsBanner = [];
        ele.optionsMedium = [];
        ele.segmentation = audienciaFilter;
        ele.budgetName = budget?.title;
      });

      setCampsToSend(options);

      //fin obtener campañas

      setBanderaSpent(0);
      setCounter(0);

      if (banderaSpent == 0 && props.campaignsTotal != 0) {
        setBanderaSpent(1);
      }
      if (props.budget_spent) {
        if (props.budget_spent.length > 0) {
          if (
            counter == 0 &&
            props.campaignsTotal != 0 &&
            props.budget_spent.length == props.budget_spentTotal
          ) {
            setCounter(1);
          }
        }
      }
      setIsLoading(false);
    } catch (err) {
      console.log("ERRCAMPS", err);
      setIsLoading(false);
    }
  };

  const refreshDataDynamo = async () => {
    try {
      let campaigns = await API.AllCampsByAccountOnlyDynamo({
        account: account,
      });
      let options = campaigns.data.campaigns;
      setCampsToSend(options);
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    if (props.currentAccount !== "") setAccount(props.currentAccount);
  }, [props.currentAccount]);

  useEffect(() => {
    getCamp();
  }, [account, refresh]);

  return (
    <>
      <div>
        {isLoading ? (
          <PageLoader />
        ) : (
          <div className="content-dashboardPwa">
            <Bar
              // _getCampaigns={props._getCampaigns}
              _showLoader={props._showLoader}
              // _getCampaignsSearch={props._getCampaignsSearch}
              campaignupd={props.campaignupd}
              countries={infoData.countries}
              countriesPwa={infoData.countriesPwa}
              isAdmin={isAdmin}
              utils={props.utils}
              // clients={infoData.clients}
              accounts={infoData.accounts}
              campaigns={campsToSend}
              sendCampaignss={campsToSend}
              // allCampaings={infoData.allCampaigns}
              exportData={props.exportData}
              currentUser={props.currentUser}
              categories={infoData.categories}
              audiences={infoData.audiences}
              budgets={infoData.budgets}
              refreshDataDynamo={refreshDataDynamo}
              // _cleanCampaign={props._cleanCampaign}
              currentAccount={props.currentAccount}
              _funcampaignupd={props._funcampaignupd}
              _funcampaignupAdd={props._funcampaignupAdd}
              _clearCampaignupd={props._clearCampaignupd}
              currentPWAAccount={props.currentPWAAccount}
              showSelectAccountModal={props._showSelectAccountModal}
              _updateAccountMembers={props._updateAccountMembers}
              paymentInfo={props.payment}
              _addIdMixDetaill={props._addIdMixDetaill}
              _getUrlImages={props._getUrlImages}
              filter={props.filter}
              _showConfirmationModal={props._showConfirmationModal}
              // _aproveCampaign={props._aproveCampaign}
              account={account}
              _updateCampaign={props._updateCampaign}
              _cleanSteps={props._cleanSteps}
              _msgsuccess={props._msgsuccess}
              budget_spent={props.budget_spent}
              budget_spentTotal={props.budget_spentTotal}
              getCampRefresh={() => getCamp()}
              setLoading={setIsLoading}
              permissions={permissions}
              _nexStep={props._nexStep}
              campaignStep={props.campaignStep}
              stepWizard={props.campaignStepWizard}
            />
            <ModalAudience />
            <ModalDetaill />
            <ModalImage />
            <ModalRegister />
            <ModalCard />
            {props.step == 1 && <CreateAccountLogOut clase={"component"} />}
          </div>
        )}
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  step: state.userLogout.nextStepCategory,
  categories: state.categories.list,
  campaign: state.campaign,
  accounts: state.accounts.list,
  account: state.accounts.default,
  filter: state.campaigns.filter,
  campaigns: state.campaigns.list,
  campaignsTotal: state.campaigns.total,
  currentUser: state.app.current_user,
  users: state.users,
  currentAccount: state.accounts.default,
  currentPWAAccount: state.pwa.accounts.default,
  campaignupd: state.campaignupd,
  campaignupAdd: state.campaignupAdd,
  budget_spent: state.budget_spent.spent_budget,
  budget_spentTotal: state.budget_spent.total,
  paymentInfo: state.payment,
  allCampaings: state.allCampaings,
  exportData: state.exportData,
  mediumbanners: state.mediumbanners,
  campaignStep: state.campaign,
  campaignStepWizard: state.campaign,
});

const mapDispatchToProps = (dispatch) => ({
  _nexStep: (step) => dispatch(nextStepLogoutUser(step)),
  _showLoader: (show) => dispatch(showLoader(show)),
  _getUsersRole: (user) => dispatch(getUsersRole(user)),
  _cleanSteps: (step) => dispatch(cleanSteps(step)),
  _msgsuccess: () => dispatch(msgsuccess()),
  _addIdMixDetaill: (id) => dispatch(addIdMixDetaill(id)),
  _getUrlImages: (id, user, idmod, mod) =>
    dispatch(getUrlImages(id, user, idmod, mod)),
  _clearCampaignupd: () => dispatch(clearCampaignupd()),
  _funcampaignupd: (camp) => dispatch(funcampaignupd(camp)),
  _updateAccountMembers: (account, members) =>
    dispatch(updateAccountMembers(account, members)),
  _showSelectAccountModal: () =>
    dispatch(
      Modal.showAlertModal(
        "Cuenta Requerida",
        "Por favor selecciona una cuenta antes",
        Modal.MODAL_TYPE_WARNING,
        () => { },
      ),
    ),
  _showConfirmationModal: (title, message, callback) =>
    dispatch(
      Modal.showAlertModal(
        title,
        message,
        Modal.MODAL_TYPE_CONFIRMATION,
        callback,
      ),
    ),
  _updateCampaign: (
    nameToSend,
    emailToSend,
    id,
    name,
    medio,
    fechaIni,
    fechafin,
    objetivo,
    budget,
    publico,
    idMixDetaill,
    idMixChange,
    idMix,
    idCampaign,
    id_medium,
    medioo,
    id_ad_product,
    banner,
    estado,
    idBudget,
    account,
    creativity,
    observation,
    copy,
    id_sale,
    id_region,
    name_region,
    nameForEmail,
    emailForEmail,
    urls,
    availableAmount,
    newBudget,
    version,
    npost,
    budgets,
    campaigns,
    categories,
    idAudience,
    optionSelected,
    chosenTag,
    tagToEmail,
  ) =>
    dispatch(
      updateCampaign(
        nameToSend,
        emailToSend,
        id,
        name,
        medio,
        fechaIni,
        fechafin,
        objetivo,
        budget,
        publico,
        idMixDetaill,
        idMixChange,
        idMix,
        idCampaign,
        id_medium,
        medioo,
        id_ad_product,
        banner,
        estado,
        idBudget,
        account,
        creativity,
        observation,
        copy,
        id_sale,
        id_region,
        name_region,
        nameForEmail,
        emailForEmail,
        urls,
        availableAmount,
        newBudget,
        version,
        npost,
        budgets,
        campaigns,
        categories,
        idAudience,
        optionSelected,
        chosenTag,
        tagToEmail,
      ),
    ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withUtils(DashboardPWA));
