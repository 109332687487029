import { v4 } from "uuid";
import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { createCampaignup } from "redux/actions/dashboard.actions";
import { updateCurrentAccount } from "redux/actions/dashboard.actions";
import { setDefault } from "redux/actions/accounts.actions";
import { pwaSetDefault } from "redux/actions/pwa.actions";
import { showLoader } from "redux/actions/loader.actions";
import { setCurrentUser } from "redux/actions/app.actions";
import { getAccounts } from "redux/actions/accounts.actions";
import * as API from "helpers/api";
import * as APILA from "helpers/apiLA";
import { nextStepLogoutUser } from "redux/actions/nameCamp.actions";
import Swal from "sweetalert2";

const FinCamp = (props) => {
    const movePay = (e) => {
        e.preventDefault();
        props._saveSteps(22);
    };

    const run = async () => {
        props._showLoader(true);
        //obtenemos información de la campaña dummy
        const campaign = props.campaign;
        //obtenemos información de dirección IP para vincular todos los dummy data
        const apiUrl = "https://api.ipify.org?format=json";
        const responseGeo = await fetch(apiUrl);
        const data = await responseGeo.json();
        const idUser = data.ip;
        // Creamos la cuenta
        const idAccount = v4();
        const bodyAccount = {
            id: idAccount,
            name: campaign.accountName,
            phone: "",
            email: "",
            adress: "",
            members: [idUser],
            status: true,
            client: "",
            credit: 0,
            priorityRecord: {
                availableCritical: 5,
                availableUrgent: 5,
                countCritical: 1,
                countUrgent: 1,
            },
            configPriority: {
                critical: {
                    maxHours: 2,
                    minHours: 0,
                },
                normal: {
                    maxHours: 8,
                    minHours: 4,
                },
                urgent: {
                    maxHours: 4,
                    minHours: 2,
                },
            },
        };
        // await API.createAccount(bodyAccount);
        //Creación de categorías por defecto
        let catFacher = [];
        let categoriasPadre = new Set();
        let categoriesz = await API.getCategories(props.currentAccount);
        let cate = categoriesz.data.categories;
        if (cate.length === 0) {
            let defaultReponse = await APILA.getDefaultCategories({});
            let defaultCategories = defaultReponse.data.categories;
            let categoriaPadre = defaultCategories.filter((item) => {
                return item.padre == 0;
            });

            for (let i = 0; i < categoriaPadre.length; i++) {
                let idcate = v4();
                let bodycat = {
                    id: idcate,
                    idAWS: categoriaPadre[i].id,
                };

                catFacher.push(bodycat);
                categoriasPadre.add({
                    id: idcate,
                    name: categoriaPadre[i].name,
                    account: props.currentAccount,
                    padre: "97bc9ee5-36a0-455b-974c-fa7832c9c341",
                });
            }
            let categoriasPadreParsed = Array.from(categoriasPadre);
            await API.createCategoriesBatch({ categorias: categoriasPadreParsed })
            let cateSon = new Set();
            for (let y = 0; y < catFacher.length; y++) {
                let filtered = defaultCategories.filter((cat) => {
                    return cat.padre == catFacher[y].idAWS;
                });
                for (let z = 0; z < filtered.length; z++) {
                    let idcat = v4();
                    cateSon.add({
                        id: idcat,
                        name: filtered[z].name,
                        account: props.currentAccount,
                        padre: catFacher[y].id,
                    });
                }
            }
            const catHijasParsed = Array.from(cateSon);
            await API.createCategoriesBatch({ categorias: catHijasParsed })
        }
        //Creación del presupuesto
        let idbudget = v4();
        let idCampaign = v4(); // id de la pauta
        const today = new Date();
        const now = Date.now();
        const fechaHoy =
            today.getDate() +
            "-" +
            (today.getMonth() + 1) +
            "-" +
            today.getFullYear();
        let bodyBudget = {
            id: idbudget,
            tags: [],
            title: "Presupuesto_" + campaign.name,
            amount: Number(campaign.budget),
            status: 1,
            account: props.currentAccount,
            available: Number(campaign.budget),
            createAt: now,
            updateAt: now,
            padre: "",
            active: true,
            nombrePadre: "",
        };

        //Crear Público
        let idAudience = v4();
        let bodyAudience = {
            id: idAudience,
            name: campaign.tags.name,
            account: props.currentAccount,
            tags: campaign.tags.tags,
            tasgE: campaign.tags.tagsE,
        };

        if (props?.campaign?.tags?.id !== "") {
        } else {
            await API.createAudience(bodyAudience);
        }
        //Crear la pauta
        const bodyCampaign = {
            id: idCampaign,
            name: campaign.name,
            medio: campaign.medium,
            objetivo: "",
            status: 1,
            formato: {
                art: props?.campaign?.id_product?.banner,
                id_format: "",
            },
            fechaIni: campaign.iniDate,
            fechafin: campaign.endDate,
            budget: 0,
            publico: campaign.tags.tags.data,
            id_medium: campaign.id_medium,
            id_ad_product: campaign.id_product.id_ad_product,
            banner: campaign.id_product.banner,
            idBudget:
                Object.keys(props?.budgetInfo).length > 0
                    ? props?.budgetInfo.id
                    : idbudget,
            account: props.currentAccount,
            observation: campaign.observations,
            copy: campaign.copy,
            id_region: campaign.country.id_region,
            name_region: campaign.country.name,
            version: "",
            categoriesSelected: [],
            creativity: [],
            id_sale: "123456",
            priority: 3,
            idAudience:
                props?.campaign?.tags?.id !== ""
                    ? props?.campaign?.tags?.id
                    : idAudience,
            UserOutside: true,
        };
        if (Object.keys(props?.budgetInfo).length > 0) {
            const errorBudget = await budgetTransactionLets(
                0,
                props.campaign?.budget,
                bodyCampaign?.idBudget,
                bodyCampaign?.idBudget,
                idCampaign,
                "logout",
            );

            if (errorBudget) {
                return Swal.fire({
                    icon: "error",
                    title: "Ocurrió un error al crear la campaña",
                    showConfirmButton: true,
                });
            }
        } else {
            await API.createNewBudgetLogOut(bodyBudget);
            //await props._createNewBudgetLogOutFinalCamp(bodyBudget);
        }
        await APILA.createCampaign(bodyCampaign);
        await props._updateCurrentAccount(idUser, props.currentAccount);
        if (props.currentUser !== "") {
            await props._setDefaultAccount(props.currentAccount);
        } else {
            await props._pwaSetDefaultAccount(props.currentAccount);
        }

        props._showLoader(false);
        window.location.reload(true);
    };

    const budgetTransactionLets = async (
        prevBudget,
        budget,
        idBudget,
        prevIdBudget,
        idCampaign,
        user,
        account,
    ) => {
        let errorOccurred = false;
        try {
            const budgetsTransact = [];
            if (idBudget !== prevIdBudget) {
                budgetsTransact.push(
                    {
                        id: prevIdBudget,
                        quantity: prevBudget,
                        sum: true,
                        idCampaign,
                        user,
                    },
                    { id: idBudget, quantity: budget, sum: false, idCampaign, user },
                );
            } else {
                //! Mantiene el mismo presupuesto pero pudo cambiar la cantidad
                if (budget !== prevBudget) {
                    const difference = budget - prevBudget;
                    if (difference > 0) {
                        budgetsTransact.push({
                            id: idBudget,
                            account,
                            quantity: difference,
                            sum: false,
                            idCampaign,
                            user,
                        });
                    } else {
                        budgetsTransact.push({
                            id: idBudget,
                            account,
                            quantity: Math.abs(difference),
                            sum: true,
                            idCampaign,
                            user,
                        });
                    }
                }
            }
            if (budgetsTransact.length > 0) {
                await APILA.budgetTransaction({ budgets: budgetsTransact });
            }
        } catch (error) {
            errorOccurred = true;
        }

        return errorOccurred;
    };

    return (
        <div className={props.clase}>
            <h1 className="title">¡Bienvenido!</h1>
            <p className="subTitle">¿Deseas correr ahora mismo tu nueva campaña?</p>
            <div className="space-btns">
                <a
                    className="btn-end gray"
                    onClick={() => {
                        run();
                    }}
                    href="#"
                >
                    Quizá después
                </a>
                <a className="btn-end" href="#" onClick={movePay}>
                    Si, deseo añadir un metodo de pago
                </a>
            </div>
        </div>
    );
};

const mapStateToProps = (state) => ({
    campaign: state.campaign,
    currentAccount: state.accounts.default,
    currentUser: state.app.current_user,
    budgetInfo: state.budgets.budgetInfo,
});

const mapDispatchToProps = (dispatch) => ({
    _setCurrentUser: (user) => dispatch(setCurrentUser(user)),
    _pwaSetDefaultAccount: (account) => dispatch(pwaSetDefault(account)),
    _setDefaultAccount: (account) => dispatch(setDefault(account)),
    _updateCurrentAccount: (user, account) =>
        dispatch(updateCurrentAccount(user, account)),
    _showLoader: (value) => dispatch(showLoader(value)),
    _saveSteps: (step) => dispatch(nextStepLogoutUser(step)),
    _getAccounts: (client) => dispatch(getAccounts(client)),
    _createCampaignup: (
        name,
        medio,
        status,
        fechaIni,
        fechafin,
        objetivo,
        budget,
        category,
        publico,
        id_medium,
        id_ad_product,
        banner,
        user,
        observations,
        copy,
        id_region,
        name_region,
        email,
        emailName,
        accountName,
        step,
    ) =>
        dispatch(
            createCampaignup(
                name,
                medio,
                status,
                fechaIni,
                fechafin,
                objetivo,
                budget,
                category,
                publico,
                id_medium,
                id_ad_product,
                banner,
                user,
                observations,
                copy,
                id_region,
                name_region,
                email,
                emailName,
                accountName,
                step,
            ),
        ),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withRouter(FinCamp));
