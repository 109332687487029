import { connect } from 'react-redux'
import swal from 'sweetalert2'

import * as Modal from 'redux/actions/modal.actions'

const AlertModal = props => {
  const showInfo = () => {
    swal.fire({
      icon: 'info',
      title: props.title,
      html: props.message,
      onClose: props._hide,
      focusConfirm: false,
    })
  }

  const showError = () => {
    swal.fire({
      icon: 'error',
      title: props.title,
      html: props.message,
      onClose: props._hide,
      focusConfirm: false,
    })
  }

  const handleSuccess = () => {
    props._hide()
    props.callback()
  }

  const showSuccess = () => {
    swal.fire({
      icon: 'success',
      title: props.title,
      html: props.message,
      onClose: handleSuccess,
      focusConfirm: false,
    })
  }

  const showWarning = () => {
    swal.fire({
      icon: 'warning',
      title: props.title,
      html: props.message,
      onClose: props._hide,
      focusConfirm: false,
    })
  }

  const showConfirmation = () => {
    swal.fire({
      icon: 'warning',
      title: props.title,
      html:props.message,
      showCancelButton: true,
      focusCancel: false,
      focusConfirm: false,
      onClose: props._hide,
      confirmButtonColor: '#d33',
      cancelButtonText: 'Cancelar',
      confirmButtonText:'Confirmar',
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        props.callback()
      }
    })
  }

  if (props.isShowing) {
    switch(props.type) {
      case Modal.MODAL_TYPE_ERROR:
        showError()
        break
      case Modal.MODAL_TYPE_SUCCESS:
        showSuccess()
        break
      case Modal.MODAL_TYPE_WARNING:
        showWarning()
        break
      case Modal.MODAL_TYPE_CONFIRMATION:
        showConfirmation()
        break
      default:
        showInfo()
        break
    }
  }

  return ''
}

const mapStateToProps = state => ({
  isShowing: state.modal.isShowing,
  callback: state.modal.callback,
  title: state.modal.title,
  message: state.modal.message,
  type: state.modal.type
})

const mapDispatchToProps = dispatch => ({
  _hide: () => dispatch(Modal.hideAlertModal()),
})

export default connect(mapStateToProps, mapDispatchToProps)(AlertModal)
