import React, { useEffect, useState } from "react";
import { v4 } from "uuid";
import * as APILA from "helpers/apiLA";
import * as API from "helpers/api";
import Swal from "sweetalert2";
import _, { get } from "lodash";
import { withUtils } from "utils";
import { connect } from "react-redux";
import { showLoader } from "redux/actions/loader.actions";
import * as Modal from "redux/actions/modal.actions";
import PageLoader from "components/page_loader";
import ModalUsers from "../ModalUsers";
import ModalAccounts from "../ModalAccounts";
import { Tooltip } from "antd";

const AddAssigment = (props) => {
  const {
    permisos,
    setShowModalUsers,
    setShowModalAccounts,
    showModalAccounts,
    showModalUsers,
    getData,
    setShowModal,
    isAdmin,
    isSupAdmin,
    assignedUsersList,
  } = props;
  const [searchAccount, setSearchAccount] = useState("");
  const [searchUser, setSearchUser] = useState("");
  const [modalTypeView, setModalTypeView] = useState("Cuentas");
  const [dataRoles, setDataRoles] = useState([]);
  const [dataAccounts, setDataAccounts] = useState([]);
  const [dataUsers, setDataUsers] = useState([]);
  const [dataAccountsFilter, setDataAccountsFilter] = useState([]);
  const [dataUserFIlter, setDataUserFIlter] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setDataRoles(props.roleNames);
    setDataAccounts(props.accounts);
    setDataUsers(props.users);
    setDataAccountsFilter(props.accounts);
    setDataUserFIlter(props.users);
  }, [props.roleNames, props.users, props.accounts]);

  const onChangeTypeView = (value) => {
    setModalTypeView(value);
    setDataUsers((prevUsers) =>
      prevUsers.map((userElement) => {
        userElement.checked = false;
        return userElement;
      })
    );
    setDataAccounts((prevAccounts) =>
      prevAccounts.map((accountElement) => {
        accountElement.checked = false;
        return accountElement;
      })
    );
    setDataRoles((prevRoles) =>
      prevRoles.map((roleElement) => {
        roleElement.checked = false;
        return roleElement;
      })
    );
    setDataUserFIlter((prevUsers) =>
      prevUsers.map((userElement) => {
        userElement.checked = false;
        return userElement;
      })
    );
    setDataAccountsFilter((prevAccounts) =>
      prevAccounts.map((accountElement) => {
        accountElement.checked = false;
        return accountElement;
      })
    );
  };

  const filterAccounts = (value) => {
    const filteredAccounts = props.accounts.filter((account) => {
      return account.name.toLowerCase().includes(value.toLowerCase());
    });

    setSearchAccount(value);
    setDataAccounts(filteredAccounts);
  };

  const onChangeCheckAccount = (acc) => {
    if (modalTypeView === "Cuentas") {
      setDataAccounts((prevAccounts) =>
        prevAccounts.map((accElement) => ({
          ...accElement,
          checked: acc.checked === false && accElement.id === acc.id,
        }))
      );
      setDataAccountsFilter((prevAccounts) =>
        prevAccounts.map((accElement) => ({
          ...accElement,
          checked: acc.checked === false && accElement.id === acc.id,
        }))
      );
    } else {
      setDataAccounts((prevUsers) =>
        prevUsers.map((userElement) => {
          if (userElement.id === acc.id) {
            return { ...userElement, checked: !userElement.checked };
          }
          return userElement;
        })
      );
      setDataAccountsFilter((prevUsers) =>
        prevUsers.map((userElement) => {
          if (userElement.id === acc.id) {
            return { ...userElement, checked: !userElement.checked };
          }
          return userElement;
        })
      );
    }
  };

  const filterUsers = (texto) => {
    const dataUsers = props.users.filter((record) => {
      const values = Object.values(record);

      return values.some((value) =>
        String(value).toLowerCase().includes(texto.toLowerCase())
      );
    });
    dataUsers.forEach((user) => {
      const found = dataUserFIlter.find((ele) => ele.id === user.id);
      if (found) {
        user.checked = found.checked;
      }
    });
    setSearchUser(texto);
    setDataUsers(dataUsers);
  };

  const onChangeCheckUser = (user) => {
    if (modalTypeView === "Cuentas") {
      setDataUsers((prevUsers) =>
        prevUsers.map((userElement) => {
          if (userElement.id === user.id) {
            return { ...userElement, checked: !userElement.checked };
          }
          return userElement;
        })
      );
      setDataUserFIlter((prevUsers) =>
        prevUsers.map((userElement) => {
          if (userElement.id === user.id) {
            return { ...userElement, checked: !userElement.checked };
          }
          return userElement;
        })
      );
    } else {
      setDataUsers((prevUsers) =>
        prevUsers.map((userElement) => ({
          ...userElement,
          checked: user.checked === false && userElement.id === user.id,
        }))
      );
      setDataUserFIlter((prevUsers) =>
        prevUsers.map((userElement) => ({
          ...userElement,
          checked: user.checked === false && userElement.id === user.id,
        }))
      );
    }
  };

  const showError = (view) => {
    let error = false;

    const accountsSelected = dataAccounts.find((ele) => ele.checked);
    const usersSelected = dataUsers.find((ele) => ele.checked);
    const roleSelected = dataRoles.find((ele) => ele.checked);

    if (!accountsSelected) {
      error = true;
      Swal.fire({
        title: "Ups!",
        text: "Selecciona una cuenta.",
        icon: "error",
        showCancelButton: false,
        confirmButtonColor: "#3085d6",
        confirmButtonText: "Ok",
      });
    }
    if (!usersSelected) {
      error = true;

      Swal.fire({
        title: "Ups!",
        text: "Selecciona un usuario.",
        icon: "error",
        showCancelButton: false,
        confirmButtonColor: "#3085d6",
        confirmButtonText: "Ok",
      });
    }
    if (!roleSelected) {
      error = true;

      Swal.fire({
        title: "Ups!",
        text: "Selecciona un rol.",
        icon: "error",
        showCancelButton: false,
        confirmButtonColor: "#3085d6",
        confirmButtonText: "Ok",
      });
    }
    return error;
  };

  const onSave = async () => {
    const promises = [];
    try {
      setIsLoading(true);
      let filterAccounts = dataAccountsFilter;
      let filterUsers = dataUserFIlter;

      if (modalTypeView === "Cuentas") {
        if (showError(true)) {
          return;
        }
        const accountsSelected = filterAccounts.find(
          (ele) => ele.checked === true
        );
        const usersSelected = filterUsers.filter((ele) => ele.checked === true);
        const roleSelected = dataRoles.find((ele) => ele.checked === true);

        //si es agregar usuarios a cuentas
        //revisar si forman parte de la cuenta
        const accountFound = props.accounts.find(
          (ele) => ele.id === accountsSelected.id
        );
        if (accountFound) {
          const members = accountFound.members || [];
          usersSelected.forEach((user) => {
            if (!members.includes(user.id)) {
              members.push(user.id);
            }
          });
          promises.push(
            API.updateAccountMembers({
              id: accountFound.id,
              members: members,
            })
          );
        }

        // //Agregar permisos
        usersSelected.forEach((user) => {
          promises.push(assginedPermiss(accountFound, user, roleSelected));
        });

        await Promise.all(promises);
        await getData();
        setShowModal(false);

        Swal.fire({
          title: "Creado!",
          text: "Asignacion de roles creada correctamente.",
          icon: "success",
          showCancelButton: false,
          confirmButtonColor: "#3085d6",
          confirmButtonText: "Ok",
        });
      } else {
        //falta codigo de agregar cuentas a usuarios
        let accSelected = filterAccounts.filter((ele) => ele.checked);
        let usersSelected = filterUsers.find((ele) => ele.checked);
        let roleSelected = dataRoles.find((ele) => ele.checked);
        if (showError(false)) {
          return;
        }

        const accsEncountered = props.accounts.filter((ele) =>
          accSelected.map((acc) => acc.id).includes(ele.id)
        );

        accsEncountered.forEach((ele) => {
          const members = ele?.members || [];
          if (!members.includes(usersSelected.id)) {
            members.push(usersSelected.id);
            promises.push(
              API.updateAccountMembers({
                id: ele.id,
                members: members,
              })
            );
          }
          promises.push(assginedPermiss(ele, usersSelected, roleSelected));
        });

        await Promise.all(promises);
        await getData();
        setShowModal(false);

        Swal.fire({
          title: "Creado!",
          text: "Asignacion de roles creada correctamente.",
          icon: "success",
          showCancelButton: false,
          confirmButtonColor: "#3085d6",
          confirmButtonText: "Ok",
        });
      }
    } catch (error) {
      Swal.fire({
        title: "Ups!",
        text: "Este usuario cuenta con permisos.",
        icon: "error",
        showCancelButton: false,
        confirmButtonColor: "#3085d6",
        confirmButtonText: "Ok",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const getClientsOrder = (mAccounts, mUsers) => {
    const userss = mUsers.map((user) => ({
      email: user.email,
      id: user.id,
      checked: false,
      name: user.name,
      format: `${user.name} (${user.email})`,
    }));
    setDataUsers(userss);
    setDataUserFIlter(userss);
  };

  const assginedPermiss = async (accountFound, user, roleSelected) => {
    const findPermisos = _.get(
      await APILA.getPermitsByAccountAndUser({
        account: accountFound.id,
        user: user.id,
      }),
      "data.permisos[0]",
      null
    );
    if (findPermisos) {
      //Es el mismo rol
      if (findPermisos.role === roleSelected.id) return;

      const bodyInsert = {
        id: v4(),
        account: accountFound.id,
        user: user.id,
        role: roleSelected.name,
        idRol: roleSelected.id,
      };
      await APILA.insertPermisos(bodyInsert);
    } else {
      // aun no tiene permisos
      const body = {
        id: v4(),
        account: accountFound.id,
        user: user.id,
        role: roleSelected.name,
        idRol: roleSelected.id,
      };
      await APILA.insertPermisos(body);
    }
  };
  const elimUser = async (usuario) => {
    const assigned = assignedUsersList.filter((ele) => ele.user === usuario.id);

    // let title = "¿Está seguro que desea desactivar el usuario " + usuario.email + "cuenta con " + "?";
    const title = `¿Está seguro que desea desactivar el usuario ${usuario.email}? cuenta con ${assigned.length} asignaciones.`;

    if (isSupAdmin) {
      //Esta opcion quita accesos a las cuentas y desactiva el usuario aun que tenga permisos
      props._showConfirmationModal("Desactivar usuario", title, async () => {
        await APILA.disabledUser({ user: usuario.id });
        await APILA.elimAccess({ user: usuario.id });
        Swal.fire({
          title: "Eliminado!",
          text: "Usuario eliminado correctamente.",
          icon: "success",
          showCancelButton: false,
          confirmButtonColor: "#3085d6",
          confirmButtonText: "Ok",
        });
        getData();
      });
    }
  };

  const onChangeCheckRole = (rol) => {
    let dataMapping = dataRoles.map((roleElement) => {
      if (rol.checked === false) {
        roleElement.checked = roleElement.id === rol.id;
      } else {
        roleElement.checked = false;
      }

      return roleElement;
    });
    setDataRoles(dataMapping);
  };

  const deleteAccess = async (account) => {
    try {
      // setIsLoading(true);
      Swal.fire({
        title:
          "¿Está seguro que desea desactivar la cuenta " + account.name + "?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        confirmButtonText: "Si",
        cancelButtonText: "No",
      }).then(async (result) => {
        if (result.isConfirmed) {
          await APILA.deleteAccessAccount({ account: account.id });
          await getData();
          Swal.fire({
            title: "Eliminado!",
            text: "Cuenta desactivada correctamente.",
            icon: "success",
            showCancelButton: false,
            confirmButtonColor: "#3085d6",
            confirmButtonText: "Ok",
          });
        }
      });
    } catch (error) {
      console.log("ERROR", error);
    } finally {
      setIsLoading(false);
    }
  };

  const activateAccount = async (account) => {
    try {
      // setIsLoading(true);
      Swal.fire({
        title:
          "¿Está seguro que desea reactivar la cuenta " + account.name + "?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        confirmButtonText: "Si",
        cancelButtonText: "No",
      }).then(async (result) => {
        if (result.isConfirmed) {
          await APILA.activateAccount({ account: account.id });
          await getData();
          Swal.fire({
            title: "Activado!",
            text: "Cuenta activada correctamente.",
            icon: "success",
            showCancelButton: false,
            confirmButtonColor: "#3085d6",
            confirmButtonText: "Ok",
          });
        }
      });
    } catch (error) {
      console.log("ERROR", error);
    } finally {
      setIsLoading(false);
    }
  };

  const activateUser = (user) => {
    Swal.fire({
      title: "¿Está seguro que desea activar el usuario " + user.email + "?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      confirmButtonText: "Si",
      cancelButtonText: "No",
    }).then(async (result) => {
      if (result.isConfirmed) {
        await APILA.enabledUser({ user: user.id });
        Swal.fire({
          title: "Activado!",
          text: "Usuario activado correctamente.",
          icon: "success",
          showCancelButton: false,
          confirmButtonColor: "#3085d6",
          confirmButtonText: "Ok",
        });
        getData();
      }
    });
  };

  return (
    <>
      {isLoading ? (
        <PageLoader />
      ) : (
        <div className="blur-div ">
          <div className="content-modal contentModalRol modalAsigRol">
            <div>
              <h3 className="titleModal titleModalRol"> Asignación de roles</h3>
            </div>
            <div className="body-modal bodyRoles">
              <select
                value={modalTypeView}
                onChange={(e) => {
                  onChangeTypeView(e.target.value);
                }}
                placeholder="selecciona una opcción"
              >
                <option value="Cuentas">Acceso a una cuenta</option>
                <option value="Usuarios">Acceso a un usuario</option>
              </select>

              {modalTypeView === "Cuentas" ? (
                <>
                  <div className="boxRoles">
                    <div className="boxRoles__title">
                      <h5>
                        CUENTAS
                        {permisos.createAccount && (
                          <span
                            className="iconPlus"
                            onClick={() =>
                              setShowModalAccounts(!showModalAccounts)
                            }
                          >
                            +
                          </span>
                        )}
                      </h5>
                      <h5>
                        USUARIOS
                        {permisos.createUser && (
                          <span
                            className="iconPlus"
                            onClick={() => setShowModalUsers(!showModalUsers)}
                          >
                            +
                          </span>
                        )}
                      </h5>
                      <h5>ROLES</h5>
                    </div>
                    <div className="boxRoles__content">
                      <div className="box boxCuenta">
                        <input
                          type="search"
                          placeholder="Buscar cuenta"
                          value={searchAccount}
                          onChange={(e) => filterAccounts(e.target.value)}
                        />
                        {dataAccounts.map((acc) => {
                          return (
                            <div className="boxCheck">
                              <input
                                type="checkbox"
                                className="check"
                                onChange={() => {
                                  onChangeCheckAccount(acc);
                                }}
                                checked={acc.checked}
                              />
                              {acc.name}
                              {isSupAdmin && acc.active && (
                                <Tooltip
                                  color="#002448"
                                  placement="top"
                                  title="Desactivar cuenta"
                                  zIndex={99999999}
                                >
                                  <i
                                    className="fas fa-trash"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      deleteAccess(acc);
                                    }}
                                  />
                                </Tooltip>
                              )}
                              {isSupAdmin && !acc.active && (
                                <Tooltip
                                  color="#002448"
                                  placement="top"
                                  zIndex={99999999}
                                  title="Activar cuenta"
                                >
                                  <i
                                    className="fas fa-trash-restore-alt"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => activateAccount(acc)}
                                  />
                                </Tooltip>
                              )}
                            </div>
                          );
                        })}
                      </div>
                      <div className="box boxUsuario">
                        <input
                          type="search"
                          placeholder="Buscar usuario"
                          value={searchUser}
                          onChange={(e) => filterUsers(e.target.value)}
                        />
                        {dataUsers.map((user) => {
                          console.log("Usuario 2", user);

                          return (
                            <div className="boxCheck">
                              <input
                                type="checkbox"
                                className="check"
                                onChange={() => {
                                  onChangeCheckUser(user);
                                }}
                                checked={user.checked}
                              />
                              {user.format}
                              {permisos.deleteUser && user.enable && (
                                <Tooltip
                                  color="#002448"
                                  placement="top"
                                  title="Desactivar usuario"
                                  zIndex={99999999}
                                >
                                  <i
                                    className="fas fa-trash"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      elimUser(user);
                                    }}
                                  />
                                </Tooltip>
                              )}
                              {permisos.deleteUser && !user.enable && (
                                <Tooltip
                                  color="#002448"
                                  placement="top"
                                  title="Activar usuario"
                                  zIndex={99999999}
                                >
                                  <i
                                    className="fas fa-trash-restore-alt"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      activateUser(user);
                                    }}
                                  />
                                </Tooltip>
                              )}
                            </div>
                          );
                        })}
                      </div>
                      <div className="box boxRoles">
                        {dataRoles.map((rol) => {
                          return (
                            <div className="boxCheck">
                              <input
                                type="checkbox"
                                className="check"
                                onChange={() => {
                                  onChangeCheckRole(rol);
                                }}
                                checked={rol.checked}
                              />
                              {rol.name}
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div className="boxRoles">
                    <div className="boxRoles__title">
                      <h5>
                        USUARIOS
                        {permisos.createUser && (
                          <span
                            className="iconPlus"
                            onClick={() => setShowModalUsers(!showModalUsers)}
                          >
                            +
                          </span>
                        )}
                      </h5>
                      <h5>
                        CUENTAS
                        {permisos.createAccount && (
                          <span
                            className="iconPlus"
                            onClick={() =>
                              setShowModalAccounts(!showModalAccounts)
                            }
                          >
                            +
                          </span>
                        )}
                      </h5>
                      <h5>ROLES</h5>
                    </div>
                    <div className="boxRoles__content">
                      <div className="box boxCuenta">
                        <input
                          type="search"
                          placeholder="Buscar usuario"
                          value={searchUser}
                          onChange={(e) => filterUsers(e.target.value)}
                        />
                        {dataUsers.map((user) => {
                          console.log("Usuario", user);
                          return (
                            <div className="boxCheck">
                              <input
                                type="checkbox"
                                className="check"
                                onChange={() => {
                                  onChangeCheckUser(user);
                                }}
                                checked={user.checked}
                              />
                              {user.format}
                              {permisos.deleteUser && user.enable && (
                                <Tooltip
                                  color="#002448"
                                  placement="top"
                                  title="Desactivar usuario"
                                  zIndex={99999999}
                                >
                                  <i
                                    className="fas fa-trash"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      elimUser(user);
                                    }}
                                  />
                                </Tooltip>
                              )}
                              {permisos.deleteUser && !user.enable && (
                                <Tooltip
                                  color="#002448"
                                  placement="top"
                                  title="Activar usuario"
                                  zIndex={99999999}
                                >
                                  <i
                                    className="fas fa-trash-restore-alt"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      activateUser(user);
                                    }}
                                  />
                                </Tooltip>
                              )}
                            </div>
                          );
                        })}
                      </div>
                      <div
                        className="box boxUsuario"
                        style={{ position: "relative !important" }}
                      >
                        <input
                          type="search"
                          placeholder="Buscar cuenta"
                          value={searchAccount}
                          onChange={(e) => filterAccounts(e.target.value)}
                        />
                        {dataAccounts.map((acc) => {
                          return (
                            <div className="boxCheck">
                              <input
                                type="checkbox"
                                className="check"
                                onChange={() => {
                                  onChangeCheckAccount(acc);
                                }}
                                checked={acc.checked}
                              />
                              {acc.name}
                              {isSupAdmin && acc.active && (
                                <div>
                                  <Tooltip
                                    color="#002448"
                                    placement="top"
                                    title="Desactivar cuenta"
                                    zIndex={99999999}
                                  >
                                    <i
                                      className="fas fa-trash"
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        deleteAccess(acc);
                                      }}
                                    />
                                  </Tooltip>
                                </div>
                              )}
                              {isSupAdmin && !acc.active && (
                                <div>
                                  <Tooltip
                                    color="#002448"
                                    placement="top"
                                    title="Activar cuenta"
                                    zIndex={99999999}
                                  >
                                    <i
                                      className="fas fa-trash-restore-alt"
                                      style={{ cursor: "pointer" }}
                                      onClick={() => activateAccount(acc)}
                                    />
                                  </Tooltip>
                                </div>
                              )}
                            </div>
                          );
                        })}
                      </div>
                      <div className="box boxRoles">
                        {dataRoles.map((rol) => {
                          return (
                            <div className="boxCheck">
                              <input
                                type="checkbox"
                                className="check"
                                onChange={() => {
                                  onChangeCheckRole(rol);
                                }}
                                checked={rol.checked}
                              />
                              {rol.name}
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>

            <div className="footer-Modal">
              <div className="buttons-Modal-content">
                <button
                  className="btnModal btnCancelarModalRol btnCancelOS"
                  onClick={(e) => setShowModal(false)}
                >
                  Cancelar
                </button>
                <button
                  className="btnModal btnGuardarModalRol btnConfirmOS"
                  onClick={(e) => {
                    onSave();
                  }}
                >
                  Guardar
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {showModalUsers && (
        <ModalUsers
          usuariosAws={dataUsers}
          setShowModalUsers={setShowModalUsers}
          setRefresh={() => getData()}
          accounts={dataAccounts}
          users={dataUsers}
          getClientsOrder={(data, users) => getClientsOrder(data, users)}
          isAdmin={isAdmin}
        />
      )}
      {showModalAccounts && (
        <ModalAccounts
          setShowModalAccounts={setShowModalAccounts}
          showModalAccounts={showModalAccounts}
          setShowModal={setShowModal}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  clients: state.clients,
  currentUser: state.app.current_user,
  pwaAccounts: state.pwa.accounts.list,
  currentAccount: state.accounts.default,
  currentPWAAccount: state.pwa.accounts.default,
});
const mapDispatchToProps = (dispatch) => ({
  _showLoader: (show) => dispatch(showLoader(show)),
  _showConfirmationModal: (title, message, callback) =>
    dispatch(
      Modal.showAlertModal(
        title,
        message,
        Modal.MODAL_TYPE_CONFIRMATION,
        callback
      )
    ),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withUtils(AddAssigment));
