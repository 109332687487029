import '@fancyapps/fancybox'
import '@fancyapps/fancybox/dist/jquery.fancybox.min.css'
import '@easygosanet/react-datatable-utils/dist/style.css'
import 'react-dates/initialize'
import 'react-dates/lib/css/_datepicker.css'

import './assets/scss/theme.scss'

const Theme = props => props.children

export default Theme
