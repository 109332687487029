import React from "react";
// components
import Content from "components/template";
import MetaTags from "components/metatags";

const TemplateListPage = () => (
  <>
    <MetaTags title="Planificar" description="Página de planificación" />
    <Content />
  </>
);

export default TemplateListPage;
