import React, { useState, useEffect} from 'react'
import './ReloadPrompt.css'

// eslint-disable-next-line no-console

function ReloadPrompt(props) {
    const [offlineReady, setOfflineReady] = useState(false)
    const [needRefresh, setNeedRefresh] = useState(false)

    useEffect(() => {
      if(props.refresh){ 
        setNeedRefresh(true)
        setOfflineReady(false)
      }else{ 
        setNeedRefresh(false)
        setOfflineReady(true)
      }    
    }, [props.refresh])
    

    const buildDate = '__DATE__'

    const updateServiceWorker = (valor) => {
        window.location.reload(true);
        localStorage.setItem('version', 'cache eliminada Version.27.02.23.Lets')
    }


    const close = () => {
        setOfflineReady(false)
        setNeedRefresh(false)
    }

    return (
        <div className="ReloadPrompt-container">
            {(offlineReady || needRefresh)
                && <div className="ReloadPrompt-toast">
                    <div className="ReloadPrompt-message">
                        {offlineReady
                            ? <span>App lista para trabajar</span>
                            : <span>Nuevo contenido disponible, haga clic en el botón de recargar para actualizar.</span>
                        }
                    </div>
                    {needRefresh && <button id="refresh" className="ReloadPrompt-toast-button" onClick={() => updateServiceWorker(true)}>recargar</button>}
                    <button className="ReloadPrompt-toast-button" onClick={() => close()}>Cerrar</button>
                </div>
            }
            <div className="ReloadPrompt-date">{buildDate}</div>
        </div>
    )
}

export default ReloadPrompt
