import React from 'react'
import { Switch, Route, Redirect, withRouter } from 'react-router-dom'

// pages
import ListPage from './list'

const AdminReportPage = props => (
  <Switch>
    <Route path="*" component={ListPage} exact />
    {/* <Route path="*">
      <Redirect to={`${props.match.path}`} />
    </Route> */}  
  </Switch>
)

export default withRouter(AdminReportPage)
