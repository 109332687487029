import * as Actions from "../actions/digitalAssets.actions";

const initialState = {
  edit: {},
  assets: [],
  filter_text: "",
  options: [
    { value: 1, label: "Activos digitales" },
    { value: 2, label: "Información de la cuenta" },
  ],
  currentOption: { value: 1, label: "Activos digitales" },
  typesOfDigitalAssets: [],
  currentTypeDigitalAsset: Actions.initialTypeDigitalAsset,
};

const digitalAssetsREducer = (state = initialState, action) => {
  switch (action.type) {
    case Actions.DIGITAL_ASSETS_SET_EDIT: {
      return {
        ...state,
        edit: action.edit,
      };
    }
    case Actions.DIGITAL_ASSETS_REMOVE:
      return {
        ...state,
        assets: [...state.assets.filter((asset) => asset.id !== action.id)],
      };
    case Actions.DIGITAL_ASSETS_SET_CURRENT_INFO:
      return {
        ...state,
        currentOption: action.currentOption,
      };
    case Actions.DIGITAL_ASSETS_ADD_TOTAL_ASSETS:
      return {
        ...state,
        assets: action.assets,
      };
    case Actions.TYPES_DIGITAL_ASSETS:
      return {
        ...state,
        typesOfDigitalAssets: action.typesOfDigitalAssets,
      };
    case Actions.CURRENT_DIGITAL_ASSETS:
      return {
        ...state,
        currentTypeDigitalAsset: action.currentTypeDigitalAsset,
      };
    default:
      return state;
  }
};

export default digitalAssetsREducer;
