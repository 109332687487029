import React, { useState } from "react";
import "../assets/style.scss";
import Swal from "sweetalert2";
import { newEmailValidation } from "redux/actions/email.actions";
import { connect } from "react-redux";
import * as APILA from "helpers/apiLA";
import { withUtils } from "utils";
import { v4 } from "uuid";

const ModalEditRol = (props) => {
  const { setShowModal, refresh, setRefresh } = props;
  const data = props.data;
  const [name, setName] = useState(props.data.name);
  const [component, setComponent] = useState([...props.data.permissions]);
  const editPermiss = (name, option, value) => {
    let searchComponent = component;
    searchComponent.forEach((ele) => {
      if (ele.name == name) {
        ele[option] = value;
      }
    });
    setComponent([...searchComponent]);
  };
  console.log("Las props", props);
  const updateRol = async () => {
    let alerta = false;
    let mensaje = "";
    let icon = "";
    let titulo = "";
    if (name != "") {
      try {
        let body = {
          id: props.data.id,
          name: name,
          type:
            props.data.type == "Predeterminado"
              ? "Predeterminado"
              : "Personalizado",
          rolPadre: props.data.rolPadre,
          account: props.data.type == "Predeterminado"
            ? ""
            : props.currentAccount,
          permissions: component,
        };
        setRefresh(true);
        setShowModal(false);
        let insertRoles = await APILA.insertRoles(body);
        props._newEmailValidation(
          props.currentAccount,
          "Roles",
          body,
          props.currentUser,
          props.accounts,
          "Edición"
        );

        let bodyBitacora = {
          id: v4(),
          date: new Date(),
          account: props.currentAccount,
          action: 'se ha editado un rol',
          component: 'roles',
          data: body
        }
        await APILA.insertBitacora(bodyBitacora)

        console.log("El rol ingresado", insertRoles);
        alerta = true;
        mensaje = "Rol editado correctamente.";
        icon = "success";
        titulo = "Rol editado!";
        setRefresh(true);
      } catch (err) { }
    } else {
      alerta = true;
      titulo = "Ups!";
      mensaje = "Agrega un nombre al rol.";
      icon = "error";
    }

    if (alerta) {
      Swal.fire({
        title: titulo,
        text: mensaje,
        icon: icon,
        showCancelButton: false,
        confirmButtonColor: "#3085d6",
        confirmButtonText: "Ok",
      });
    }
  };
  const changeModal = () => {
    setShowModal(false);
    setRefresh(true);
  };
  return (
    <div className="blur-div">
      <div className="content-modal modalCreateRol">
        <div>
          <h3 className="titleModal titleModalRol">Editar permisos</h3>
        </div>

        <div className="body-modal modalAccount">
          <div className="rol-options">
            <h3>Rol</h3>
            <input
              type={"text"}
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </div>

          <div className="container-permiss">
            {component.map((component, idx) => (
              <div className="container-acc" key={idx}>
                <div className="names">{component.name}</div>
                <div className="container-options">
                  <div>
                    <input
                      type="checkbox"
                      checked={component.export}
                      onChange={() =>
                        editPermiss(component.name, "export", !component.export)
                      }
                    />
                    <span>Exportar</span>
                  </div>
                  <div>
                    <input
                      type="checkbox"
                      checked={component.view}
                      onChange={() =>
                        editPermiss(component.name, "view", !component.view)
                      }
                    />
                    <span>Ver</span>
                  </div>
                  <div>
                    <input
                      type="checkbox"
                      checked={component.edit}
                      onChange={() =>
                        editPermiss(component.name, "edit", !component.edit)
                      }
                    />
                    <span>Editar</span>
                  </div>
                  <div>
                    <input
                      type="checkbox"
                      checked={component.create}
                      onChange={() =>
                        editPermiss(component.name, "create", !component.create)
                      }
                    />
                    <span>Crear</span>
                  </div>
                  <div>
                    <input
                      type="checkbox"
                      checked={component.delete}
                      onChange={() =>
                        editPermiss(component.name, "delete", !component.delete)
                      }
                    />
                    <span>Eliminar</span>
                  </div>
                  {/* <div>
                    <input
                      type="checkbox"
                      checked={component.manageRoles}
                      onChange={() =>
                        editPermiss(
                          component.name,
                          "manageRoles",
                          !component.manageRoles
                        )
                      }
                    />
                    <span>Gestionar roles</span>
                  </div> */}
                  {component.name === 'Campañas' &&
                    <div>
                      <input
                        type="checkbox"
                        checked={component.approve}
                        onChange={() =>
                          editPermiss(
                            component.name,
                            "approve",
                            !component.approve
                          )
                        }
                      />
                      <span>Aprobar</span>
                    </div>
                  }
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className="footer-Modal">
          <div className="buttons-Modal-content">
            <button
              className="btnModal btnCancelarModalRol"
              onClick={(e) => changeModal()}
            >
              Cancelar
            </button>
            <button
              className="btnModal btnGuardarModalRol"
              onClick={() => updateRol()}
            >
              Guardar
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => ({
  clients: state.clients,
  accounts: state.accounts.list,
  currentUser: state.app.current_user,
  pwaAccounts: state.pwa.accounts.list,
  currentAccount: state.accounts.default,
  currentPWAAccount: state.pwa.accounts.default,
});

const mapDispatchToProps = (dispatch) => ({
  _newEmailValidation: (idAccount, modulo, data, usuario, accounts, action) =>
    dispatch(
      newEmailValidation(idAccount, modulo, data, usuario, accounts, action)
    ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withUtils(ModalEditRol));
