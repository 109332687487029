import React, { useEffect, useState, useRef, useCallback } from "react";
import { Tooltip, Tag, message } from "antd";
import PageLoader from "components/page_loader";
import ReactDataTable from "@easygosanet/react-datatable";
import { connect } from "react-redux";
import _ from "lodash";
import { createBudget } from "redux/actions/budgets.actions";
import { pwaUpsertBudget } from "redux/actions/pwa.actions";
import analytics from "../../../helpers/analytics";
import { v4 } from "uuid";
import * as APILA from "helpers/api";
import * as API from "helpers/apiLA";
import { FormGroup, Label, Input, FormFeedback } from "reactstrap";
import { Auth } from "aws-amplify";
import Swal from "sweetalert2";
// import "./style.scss";
import CurrencyInput from "react-currency-input-field";
import NumberFormat from "react-number-format";
import NumericFormat from "react-number-format";
import DataTableUtils, {
  Share,
  ShowColumn,
  FilterColumn,
  FilterColumn2,
  ShowColumn2,
} from "@easygosanet/react-datatable-utils";
// import {NumericFormat} from "react-number-format";
const idmod = 3;
const mod = "PRESUPUESTOS";

const ModalPresupuesto = (props) => {
  const [enviado, setEnviado] = useState(false);
  const [filterText, setFilterText] = useState("");
  const [invalid, setInvalid] = useState(false);
  const [dataLovs, setDataLovs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [tablaTemporal, setTablaTemporal] = useState([]);
  const [tablaTemporal2, setTablaTemporal2] = useState([]);
  const [data, setData] = useState([]);
  const [isInvalid, setIsInvalid] = useState(false);
  const [selectAll, setSelectAll] = useState({
    table1: false,
    table2: false,
  });
  const [data2, setData2] = useState([]);
  const [input, setInput] = useState({});
  const [dataTotalTable, setDataTotalTable] = useState([
    {
      presupuestoTotal: Number(0).toFixed(2),
      totalConsumido: Number(0).toFixed(2),
      totalReservado: Number(0).toFixed(2),
      totalDisponible: Number(0).toFixed(2),
    },
  ]);
  const [suma, setSuma] = useState(0);
  const [state, setState] = useState({
    id: "",
    name: "",
    tags: [],
    amount: 0,
    edit: false,
    createAt: 0,
    padre: "",
    idBudget: "",
    nombrePadre: "",
    fatherAvailable: 0,
    available: 0,
    reserveForLovs: [],
    budgetOld: false,
  });
  const [errors, setErrors] = useState({
    name: false,
    amount: false,
    errorBudgetMessage: "",
  });

  const getAllLovs = async () => {
    try {
      setLoading(true);
      const response = await APILA.getAllLovs({
        account: props.currentAccount,
      });
      setLoading(false);
      let lovs = response.data.data;
      let mix = [...data, ...lovs];
      let budgetAvailbaleCorrect = lovs.filter((item) => {
        return item.budgetAvailable > 0;
      });
      setData(budgetAvailbaleCorrect);
      setDataLovs(lovs);
    } catch (err) {
      console.log("Error en getAllLovs", err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getAllLovs();
  }, []);

  // const getAllLovsRef = useRef();

  // const getAllLovs = useCallback(async () => {
  //   try {
  //     setLoading(true);
  //     const response = await APILA.getAllLovs({
  //       account: props.currentAccount,
  //     });
  //     setLoading(false);
  //     let lovs = response.data.data;
  //     let mix = [...data, ...lovs];
  //     let budgetAvailbaleCorrect = lovs.filter((item) => {
  //       return item.budgetAvailable > 0;
  //     });
  //     setData(budgetAvailbaleCorrect);
  //     setDataLovs(lovs);
  //   } catch (err) {
  //     console.log("Error en getAllLovs", err);
  //   } finally {
  //     setLoading(false);
  //   }
  // }, []);

  // useEffect(() => {
  //   getAllLovsRef.current = getAllLovs;
  // }, [getAllLovs]);

  // useEffect(() => {
  //   if (getAllLovsRef.current) {
  //     getAllLovsRef.current();
  //   }
  // }, [getAllLovsRef]);

  const handleSuma = (e) => {
    let su = Object.values(input).reduce(
      (total, value) => total + Number(value),
      0
    );
    setSuma(su);
    // setState((prevState) => ({
    //   ...prevState,
    //   amount: Number(su),
    // }));
    let sumatoria = data2.reduce(
      (total, value) => total + Number(value.reserve),
      0
    );

    setDataTotalTable([
      {
        presupuestoTotal: Number(sumatoria).toFixed(2),
        totalConsumido: Number(0).toFixed(2),
        totalReservado: Number(0).toFixed(2),
        totalDisponible: Number(0).toFixed(2),
      },
    ]);
  };

  useEffect(() => {
    handleSuma();
  }, [input, suma]);

  const handleInput = (e) => {
    const { value, name } = e.target;
    if (name === "amount" && e.target.validity.valid) {
      setState((prev) => ({
        ...prev,
        [name]: Number(value),
      }));
    } else {
      setState((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
    setErrors((prev) => ({
      ...prev,
      [name]: _.isEmpty(value),
    }));
  };

  const validateNumber = (valor) => {
    let regex = /\d+\.?\d+/gm;
    // return /^\d*(\.\d+)?$/.test(valor);
    return regex.test(valor);
  };

  const handleChange = async (value, row) => {
    // const { value } = e.target;
    // if (value >= 0 && value <= row.budgetAvailable) {
    //   setIsInvalid((prevState) => ({
    //     ...prevState,
    //     [row.id]: false,
    //   }));
    //   setInput((prevState) => ({
    //     ...prevState,
    //     [row.id]: Number(value),
    //   }));

    //   setData2((prev) => {
    //     let datainput = [...prev];
    //     const idx = datainput.findIndex((item) => item.id === row.id);
    //     let newAvailable = datainput[idx].budgetAvailable;
    //     let newBudgetAvailable = 0;
    //     let reserveValue = parseFloat(datainput[idx].reserve) || 0;
    //     if (reserveValue < parseFloat(value)) {
    //       // Suma el disponible
    //       let found = parseFloat(newAvailable) + reserveValue;
    //       newBudgetAvailable = found - parseFloat(value);
    //     } else {
    //       if (parseFloat(value) >= 0) {
    //         let differenceValue = reserveValue - parseFloat(value);
    //         newBudgetAvailable = newAvailable + differenceValue;
    //       } else {
    //         newBudgetAvailable = newAvailable + reserveValue;
    //       }
    //     }
    //     const res = datainput.map((item) => {
    //       if (item.order_line_id_v6 === row.order_line_id_v6) {
    //         return {
    //           ...item,
    //           reserve: Number(value),
    //         };
    //       }
    //       return item;
    //     });
    //     if (parseFloat(value) > newAvailable) {
    //       setIsInvalid((prev) => ({
    //         ...prev,
    //         [row.id]: true,
    //       }));
    //     } else {
    //       let valorReserve = value >= 0 ? value : null;
    //       setIsInvalid((prev) => ({
    //         ...prev,
    //         [row.id]: false,
    //       }));
    //       datainput[idx] = { ...datainput[idx], reserve: valorReserve, budgetAvailable: newBudgetAvailable };
    //     }
    //     return datainput;
    //   });
    // } else {
    //   // alert("El valor no puede ser mayor al disponible");
    //   setIsInvalid((prevState) => ({
    //     ...prevState,
    //     [row.id]: true,
    //   }));
    //   row.reserve = 0;
    //   setInput((prevState) => ({
    //     ...prevState,
    //     [row.id]: 0,
    //   }));
    // }
    // console.log("Este es el value", value);
    setData2((prev) => {
      let datainput = [...prev];
      const idx = datainput.findIndex((item) => item.id === row.id);
      let newAvailable = datainput[idx].budgetAvailable;
      let newBudgetAvailable = 0;
      let reserveValue = parseFloat(datainput[idx].reserve) || 0;
      if (reserveValue < parseFloat(value)) {
        // Suma el disponible
        let found = parseFloat(newAvailable) + reserveValue;
        newBudgetAvailable = found - parseFloat(value);
      } else {
        if (parseFloat(value) >= 0) {
          let differenceValue = reserveValue - parseFloat(value);
          newBudgetAvailable = newAvailable + differenceValue;
        } else {
          newBudgetAvailable = newAvailable + reserveValue;
        }
      }
      if (newBudgetAvailable < 0) {
        setIsInvalid((prev) => ({
          ...prev,
          [row.id]: true,
        }));
      } else {
        let valorReserve = value >= 0 ? value : null;
        setIsInvalid((prev) => ({
          ...prev,
          [row.id]: false,
        }));
        datainput[idx] = {
          ...datainput[idx],
          reserve: valorReserve,
          budgetAvailable: newBudgetAvailable,
        };
      }
      return datainput;
    });
  };

  useEffect(() => {
    let amount = data2.reduce(
      (total, value) => total + Number(value.reserve),
      0
    );
    setState((prev) => ({
      ...prev,
      budgetOld: false,
      amount: Number(amount),
      available: Number(amount),
      // reserveForLovs: data2,
    }));
    setState((prev) => ({
      ...prev,
      reserveForLovs: data2.map((item) => ({
        order_line_id_v6: item.order_line_id_v6,
        // budgetAvailable: item.budgetAvailable,
        idsBudget: item.idsBudget,
        idsCampaign: item.idsCampaign,
        // orderNumber: item.orderNumber,
        // prevReserve: item.prevReserve,
        reserve: item.reserve,
        id: item.id,
      })),
    }));
  }, [data2]);

  const onSaveModal = async (e) => {
    e.preventDefault();
    setEnviado(true);
    if (data2.length === 0) {
      return Swal.fire({
        title: "Error",
        text: "No se puede crear un presupuesto sin líneas de orden de venta.",
        icon: "error",
        showCancelButton: false,
        confirmButtonColor: "#3085d6",
        confirmButtonText: "Ok",
      });
    }

    let reservedForLovs = state.reserveForLovs.map((ele) => ele.reserve);
    const invalidoReserve = reservedForLovs.some(
      (num) => num == 0 || num == undefined
    );
    if (
      state.name === "" ||
      reservedForLovs.includes(undefined) ||
      invalidoReserve
    ) {
      return Swal.fire({
        title: "Error",
        text: invalidoReserve
          ? "Lo reservado de la línea de orden de venta no puede ser cero o vacio."
          : "El nombre del presupuesto es requerido.",
        icon: "error",
        showCancelButton: false,
        confirmButtonColor: "#3085d6",
        confirmButtonText: "Ok",
      });
    }
    let budgetId = state.id;
    if (budgetId === "") {
      budgetId = v4();
    }
    for (let k = 0; k < data2.length; k++) {
      const elemento = data2[k];
      const found = dataLovs.find((ele) => elemento.id === ele.id);
      const newReserve =
        parseFloat(found.reserve) + parseFloat(elemento.reserve);
      const newAvailable =
        parseFloat(found.budgetAvailable) - parseFloat(elemento.reserve);
      const idsBudgets = found.idsBudget;
      idsBudgets.push(budgetId);
      await APILA.updateAvailableLov({
        id: elemento.id,
        budgetAvailable: newAvailable,
        reserve: newReserve,
        idsBudget: idsBudgets,
      });
    }

    analytics.modalesAcciones({
      accion: "Confirmar",
      ubicacion: "Presupuestos",
    });
    let fatherAvailable = 0;
    let availableAmount = 0;
    let prevBud = props.budgets.find((element) => element.id == state.id);
    if (prevBud) {
      if (prevBud.amount > state.amount) {
        let dif = state.amount - prevBud.amount;
        availableAmount = prevBud.available + dif;
      } else {
        let dif = prevBud.amount - state.amount;
        availableAmount = prevBud.available - dif;
      }
    }

    if (props.currentUser !== "") {
      const accountInfo = _.find(props.accounts, { id: props.currentAccount });
      Auth.currentAuthenticatedUser().then((userdata) => {
        console.log("Este es el state que va a redux", state);
        props._createBudget(
          budgetId,
          state.name,
          props.currentAccount,
          state.amount,
          state.tags,
          1,
          state.createAt,
          state.edit,
          state.padre,
          availableAmount,
          state.idBudget,
          state.nombrePadre,
          fatherAvailable,
          props.budgets,
          props.currentUser,
          idmod,
          mod,
          props.accounts,
          userdata.attributes.email,
          state.budgetOld,
          data2
        );
      });
    } else {
      Auth.currentAuthenticatedUser().then((userdata) => {
        console.log("Este es el state que va a redux pwa", state);
        props._pwaUpsertBudget(
          budgetId,
          state.name,
          props.currentPWAAccount,
          state.amount,
          state.tags,
          1,
          state.edit,
          state.padre,
          state.idBudget,
          state.nombrePadre,
          fatherAvailable,
          props.budgets,
          userdata.attributes.email,
          state.budgetOld
        );
      });
    }
    props.setShowModal(false);
  };

  function numberWithCommas1(x) {
    let auxNumber = new Number(x).toFixed(2);
    return auxNumber.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  //TODO:Columnas donde se hace el fetch de las LOV'S
  const columns1 = [
    {
      omit: false,
      name: (
        <input
          type="checkbox"
          checked={selectAll.table1}
          onChange={(e) => handleOnChangeCheckedTable1("all", e)}
        />
      ),
      cell: (row) => (
        <input
          type="checkbox"
          checked={row.checked}
          onChange={(e) => handleOnChangeCheckedTable1(row, e)}
        />
      ),
    },
    {
      omit: false,
      name: "No. Orden",
      selector: "orderNumber",
      sortable: true,
      type: "text",
      id: "orderNumber",
      tag: "orderNumber",
      visible: false,
      center: true,
      show: true,
      cell: (row) => row.orderNumber,
    },
    {
      omit: false,
      name: "LOV",
      selector: "lov",
      sortable: true,
      type: "text",
      id: "lov",
      tag: "lov",
      visible: false,
      center: true,
      show: true,
      cell: (row) => row.order_line_id_v6,
    },
    {
      omit: false,
      name: "Disponible",
      selector: "disponible",
      sortable: true,
      type: "text",
      id: "disponible",
      tag: "disponible",
      visible: false,
      center: true,
      show: true,
      cell: (row) => `$${numberWithCommas1(row.budgetAvailable)}`,
    },
    {
      omit: false,
      name: "Cliente",
      selector: "cliente",
      sortable: true,
      type: "text",
      id: "cliente",
      tag: "cliente",
      visible: false,
      center: true,
      show: true,
      cell: (row) => row.orderReference,
    },
    // {
    //   omit: false,
    //   name: "Descripción",
    //   selector: "descripcion",
    //   sortable: true,
    //   type: "text",
    //   id: "descripcion",
    //   tag: "descripcion",
    //   visible: false,
    //   center: true,
    //   show: true,
    //   cell: (row) => row.name,
    // },
  ];
  //Columnas donde se pasan las LOV'S para el presupuesto
  const columns2 = [
    {
      omit: false,
      name: (
        <input
          type="checkbox"
          checked={selectAll.table2}
          onChange={(e) => handleOnChangeCheckedTable2("all", e)}
        />
      ),
      cell: (row) => (
        <input
          type="checkbox"
          checked={row.checked}
          onChange={(e) => handleOnChangeCheckedTable2(row, e)}
        />
      ),
    },
    {
      omit: false,
      name: "No. Orden",
      selector: "orderNumber",
      sortable: true,
      type: "text",
      id: "orderNumber",
      tag: "orderNumber",
      visible: false,
      center: true,
      show: true,
      cell: (row) => row.orderNumber,
    },
    {
      omit: false,
      name: "LOV",
      selector: "lov",
      sortable: true,
      type: "text",
      id: "lov",
      tag: "lov",
      visible: false,
      center: true,
      show: true,
      cell: (row) => row.order_line_id_v6,
    },
    {
      omit: false,
      name: "Disponible",
      selector: "disponible",
      sortable: true,
      type: "text",
      id: "disponible",
      tag: "disponible",
      visible: false,
      center: true,
      show: true,
      cell: (row) => `$${numberWithCommas1(row.budgetAvailable)}`,
    },
    // {
    //   omit: false,
    //   name: "Estado",
    //   selector: "estado",
    //   sortable: true,
    //   type: "text",
    //   id: "estado",
    //   tag: "estado",
    //   visible: false,
    //   center: true,
    //   show: true,
    //   cell: (row) => row.state,
    // },
    {
      omit: false,
      name: "Cliente",
      selector: "cliente",
      sortable: true,
      type: "text",
      id: "cliente",
      tag: "cliente",
      visible: false,
      center: true,
      show: true,
      cell: (row) => row.orderReference,
    },
    // {
    //   omit: false,
    //   name: "Descripción",
    //   selector: "descripcion",
    //   sortable: true,
    //   type: "text",
    //   id: "descripcion",
    //   tag: "descripcion",
    //   visible: false,
    //   center: true,
    //   show: true,
    //   cell: (row) => row.name,
    // },
    {
      omit: false,
      name: "Reservar",
      selector: "reserva",
      sortable: true,
      type: "text",
      id: "reserva",
      tag: "reserva",
      visible: false,
      center: true,
      show: true,
      cell: (row) => {
        const formattedValue = row.reserve; //!== null ? row.reserve.toFixed(2) : "";
        return (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <FormGroup>
              {/* <NumericFormat
                onChange={(e) => handleChange(e, row)}
                value={formattedValue !== "" ? formattedValue : null}
                name="amount"
                allowLeadingZeros={true}
                className="inputReserva"
                decimalScale={2}
                allowNegative={false}
                decimalSeparator="."
                style={{ borderRadius: "5px", maxWidth: "100%" }}
                placeholder={`Máx: ${row.budgetAvailable}`}
              /> */}
              <CurrencyInput
                // min="0"
                intlConfig={{ locale: "es-GT", currency: "USD" }}
                prefix="$"
                // type='number'
                key={row.id}
                name="amount"
                step="0.01"
                //pattern='[0-9]*'
                className="input form-control"
                style={{
                  color: "#05252d",
                }}
                value={!isNaN(formattedValue) ? formattedValue : null}
                onValueChange={(e) => handleChange(e, row)}
                placeholder="P. ejemplo 1000.25"
                invalid={isInvalid[row.id]}
              />
              {isInvalid[row.id] && (
                <div
                  className="text-danger"
                  style={{ position: "relative", bottom: "50%", left: "0" }}
                >
                  <span style={{ fontSize: "11px" }}>
                    El reservado no puede ser mayor al disponible.
                  </span>
                </div>
              )}
            </FormGroup>
          </div>
        );
      },
    },
  ];
  //Columnas para los totales de cada presupuesto
  const columns3 = [
    {
      omit: false,
      name: "Presupuesto Total",
      selector: "totalBudget",
      sortable: true,
      type: "text",
      id: "totalBudget",
      tag: "totalBudget",
      visible: false,
      center: true,
      show: true,
      cell: (row) => {
        let result = data2.reduce(
          (total, value) => total + Number(value.reserve),
          0
        );
        return `$${result.toFixed(2)}`;
      },
    },
    {
      omit: false,
      name: "Total implementado",
      selector: "totalReserved",
      sortable: true,
      type: "text",
      id: "totalReserved",
      tag: "totalReserved",
      visible: false,
      center: true,
      show: true,
      cell: (row) => `$${row.totalReservado}`,
    },
    {
      omit: false,
      name: "Total consumido",
      selector: "totalConsumed",
      sortable: true,
      type: "text",
      id: "totalConsumed",
      tag: "totalConsumed",
      visible: false,
      center: true,
      show: true,
      cell: (row) => `$${row.totalConsumido}`,
    },
    {
      omit: false,
      name: "Total disponible",
      selector: "totalAvailable",
      sortable: true,
      type: "text",
      id: "totalAvailable",
      tag: "totalAvailable",
      visible: false,
      center: true,
      show: true,
      cell: (row) => `$${row.totalDisponible}`,
    },
  ];

  const moveSelectedRowsTable1 = () => {
    const filasSeleccionadas = tablaTemporal.filter((fila) => {
      if (
        fila.checked &&
        !data2.some((fila2) => fila.order_line_id_v6 === fila2.order_line_id_v6)
      ) {
        fila.checked = false;
        fila.prevReserve = fila.reserve;
        fila.reserve = 0;
        return fila;
      }
    });
    const filasRestantes = tablaTemporal
      .filter(
        (fila1) =>
          !data.some(
            (fila2) => fila1.order_line_id_v6 === fila2.order_line_id_v6
          )
      )
      .concat(
        data.filter(
          (fila2) =>
            !tablaTemporal.some(
              (fila1) => fila2.order_line_id_v6 === fila1.order_line_id_v6
            )
        )
      );

    //ELIMINAR ELEMENTOS REPETIDOS TABLA ORIGEN
    let uniqueArr = filasRestantes.filter((item, index) => {
      return (
        index ===
        filasRestantes.findIndex((obj) => {
          return JSON.stringify(obj) === JSON.stringify(item);
        })
      );
    });
    // Agregar las filas seleccionadas a la tabla de destino
    const tabla2Nueva = [...data2, ...filasSeleccionadas];
    setSelectAll((prev) => ({ ...prev, table1: false }));
    // Actualizar la tabla de origen
    setData(uniqueArr);

    // Actualizar la tabla de destino
    const {
      budgetAvailable,
      id,
      idsBudget,
      idsCampaign,
      orderNumber,
      order_line_id_v6,
      prevReserve,
      reserve,
    } = tabla2Nueva;
    setData2(tabla2Nueva);
    // setData2({budgetAvailable, id, idsBudget, idsCampaign, orderNumber, order_line_id_v6, prevReserve, reserve})
    // console.log("Reserva", tabla2Nueva);
    // setState((prev) => ({
    //   ...prev,
    //   reserveForLovs: tabla2Nueva.map((item) => ({
    //     order_line_id_v6: item.order_line_id_v6,
    //     budgetAvailable: item.budgetAvailable,
    //     state: item.state,
    //     order_partner_id: item.order_partner_id,
    //     name: item.name,
    //     reserve: item.reserve,
    //     id: item.id,
    //   })),
    // }));
    // console.log("stateAcutalizado", state);

    // Reiniciar la tabla temporal
    setTablaTemporal([]);
  };

  const moveSelectedRowsTable2 = () => {
    const filasSeleccionadas = tablaTemporal2.filter((fila) => {
      if (
        fila.checked &&
        !data.some((fila2) => fila.order_line_id_v6 === fila2.order_line_id_v6)
      ) {
        fila.checked = false;
        fila.prevReserve = fila.reserve;
        fila.reserve = fila.prevReserve;
        return fila;
      }
    });

    const filasRestantes = tablaTemporal2
      .filter(
        (fila1) =>
          !data2.some(
            (fila2) => fila1.order_line_id_v6 === fila2.order_line_id_v6
          )
      )
      .concat(
        data2.filter(
          (fila2) =>
            !tablaTemporal2.some(
              (fila1) => fila2.order_line_id_v6 === fila1.order_line_id_v6
            )
        )
      );
    //ELIMINAR ELEMENTOS REPETIDOS TABLA ORIGEN
    let uniqueArr = filasRestantes.filter((item, index) => {
      return (
        index ===
        filasRestantes.findIndex((obj) => {
          return JSON.stringify(obj) === JSON.stringify(item);
        })
      );
    });
    // console.log("uniquerArr", uniqueArr);
    setSelectAll((prev) => ({ ...prev, table2: false }));
    // Agregar las filas seleccionadas a la tabla de destino
    const tabla2Nueva = [...data, ...filasSeleccionadas];
    // Actualizar la tabla de origen
    setData2(uniqueArr);

    // Actualizar la tabla de destino
    setData(tabla2Nueva);

    // Reiniciar la tabla temporal
    setTablaTemporal2([]);
  };

  const handleRowCheckboxChange1 = (state) => {
    const filas = state.selectedRows.map((fila) => ({
      ...fila,
      checked: true,
    }));
    setTablaTemporal(filas);
  };

  const handleRowCheckboxChange2 = (state) => {
    const filas = state.selectedRows.map((fila) => ({
      ...fila,
      checked: true,
    }));
    setTablaTemporal2(filas);
  };

  const customStyles = {
    headCells: {
      style: {
        textAlign: "center",
        "&:nth-of-type(1)": {
          minWidth: "100px",
        },
        "&:nth-of-type(2n)": {
          backgroundColor: "transparent",
          borderRadius: "12px 12xp 0 0",
        },
      },
    },
    cells: {
      style: {
        "&:nth-of-type(1)": {
          minWidth: "100px",
        },
        "&:nth-of-type(2n)": {
          backgroundColor: "transparent",
        },
      },
    },
  };

  const handleSinc = async (e) => {
    e.preventDefault();
    setLoading(true);
    let name = props.accounts.find((ele) => ele.id === props.currentAccount);
    try {
      let initialSync = await API.sincPartial({
        account: props.currentAccount,
        name: name.name,
      });
    } catch (err) {
      console.log("Error con sincronizacion parcial", err);
    } finally {
      // getAllLovs();
      setLoading(false);
    }
  };

  const handleOnChangeCheckedTable1 = (row, e) => {
    e.persist();
    let { checked } = e.target;
    if (row === "all") {
      let dataActive = [...data];
      setSelectAll((prev) => ({ ...prev, table1: checked }));
      dataActive.forEach((ele) => (ele.checked = checked));
      setData(dataActive);
      setTablaTemporal(dataActive);
      // console.log("dataACtive", dataActive);
    } else {
      let newSelected = {
        ...row,
        checked: checked,
      };
      setData((prev) => {
        let upsertData = [...prev];
        let idx = upsertData.findIndex(
          (ele) => ele.order_line_id_v6 === newSelected.order_line_id_v6
        );
        upsertData[idx] = { ...upsertData[idx], checked: checked };
        return upsertData;
      });

      setTablaTemporal((prev) => {
        let upsertData = [...prev];
        if (!checked) {
          upsertData = upsertData.filter(
            (ele) => ele.order_line_id_v6 !== row.order_line_id_v6
          );
        } else {
          upsertData = [...upsertData, newSelected];
        }
        return upsertData;
      });
    }
  };

  const handleOnChangeCheckedTable2 = (row, e) => {
    e.persist();
    let { checked } = e.target;
    if (row === "all") {
      let dataActive = [...data2];
      setSelectAll((prev) => ({ ...prev, table2: checked }));
      dataActive.forEach((ele) => (ele.checked = checked));
      setData2(dataActive);
      setTablaTemporal2(dataActive);
      // console.log("dataACtive", dataActive);
    } else {
      let newSelected = {
        ...row,
        checked: checked,
      };
      setData2((prev) => {
        let upsertData = [...prev];
        let idx = upsertData.findIndex(
          (ele) => ele.order_line_id_v6 === newSelected.order_line_id_v6
        );
        upsertData[idx] = { ...upsertData[idx], checked: checked };
        return upsertData;
      });

      setTablaTemporal2((prev) => {
        let upsertData = [...prev];
        if (!checked) {
          upsertData = upsertData.filter(
            (ele) => ele.order_line_id_v6 !== row.order_line_id_v6
          );
        } else {
          upsertData = [...upsertData, newSelected];
        }
        return upsertData;
      });
    }
  };

  const conditionalRowStyles = [
    {
      when: (row) => row.checked,
      style: {
        backgroundColor: "#599ee87a", // Cambia aquí el color de resaltado
      },
    },
  ];

  function filterData(data, filterText) {
    const lowerCaseFilterText = filterText.toLowerCase();
    return data.filter(
      (buscador) =>
        (buscador.orderReference &&
          buscador.orderReference
            .toLowerCase()
            .includes(lowerCaseFilterText)) ||
        (buscador.order_line_id_v6 &&
          buscador.order_line_id_v6
            .toString()
            .toLowerCase()
            .includes(lowerCaseFilterText)) ||
        (buscador.orderNumber &&
          buscador.orderNumber.toLowerCase().includes(lowerCaseFilterText)) ||
        (buscador.budgetAvailable &&
          buscador.budgetAvailable
            .toString()
            .toLowerCase()
            .includes(lowerCaseFilterText))
    );
  }

  return (
    <div className="blur-div">
      <div className="content-modal">
        {loading ? (
          <PageLoader />
        ) : (
          <>
            <div className="header-modal">
              <h3 className="titleModal">Presupuestos</h3>
            </div>

            <div className="body-modal">
              <section className="body-part">
                {/* <button className="btnSync" onClick={(e) => handleSinc(e)}>
                  Sincronizar <i className="fas fa-sync-alt"></i>
                </button> */}
                <DataTableUtils
                  valueFilter={filterText || ""}
                  onChangeFilterText={(value) => {
                    setFilterText(value);
                  }}
                ></DataTableUtils>
                <div className="scroll-busqueda">
                  <ReactDataTable
                    className="budgetTable"
                    customStyles={customStyles}
                    conditionalRowStyles={conditionalRowStyles}
                    columns={columns1}
                    data={filterData(data, filterText)}
                    persistTableHead
                    pagination
                    noHeader
                  />
                </div>
              </section>
              <section className="Buttons">
                <button onClick={moveSelectedRowsTable1}>
                  <i className="fas fa-chevron-right"></i>
                </button>
                <button onClick={moveSelectedRowsTable2}>
                  <i className="fas fa-chevron-left"></i>
                </button>
              </section>
              <section className="body-part partTotales">
                <FormGroup>
                  <Label className="nameBudget">
                    Nombre del presupuesto
                    <Input
                      type="text"
                      name="name"
                      value={state.value}
                      onChange={handleInput}
                      invalid={enviado && state.name.length === 0}
                      placeholder="P. ejemplo presupuesto"
                    />
                    {enviado && state.name.length === 0 && (
                      <FormFeedback>
                        Ingresa un nombre para el presupuesto
                      </FormFeedback>
                    )}
                  </Label>
                </FormGroup>
                <div className="scroll-busqueda">
                  <ReactDataTable
                    className="budgetTable"
                    customStyles={customStyles}
                    conditionalRowStyles={conditionalRowStyles}
                    columns={columns2}
                    data={data2}
                    persistTableHead
                    pagination
                    noHeader
                  />
                </div>
                <ReactDataTable
                  className="budgetTable"
                  customStyles={customStyles}
                  columns={columns3}
                  data={dataTotalTable}
                  persistTableHead
                  // pagination
                  noHeader
                />
              </section>
            </div>

            <div className="footer-Modal">
              <Tooltip
                title="Descartar cambios"
                color="#002448"
                placement="top"
              >
                <button
                  className="btn btn_cancel btnCancelOS"
                  onClick={(e) => props.setShowModal(false)}
                >
                  Cancelar
                </button>
              </Tooltip>
              <Tooltip
                title="Descartar cambios"
                color="#002448"
                placement="top"
              >
                <button
                  onClick={onSaveModal}
                  className="btn btn_save btnConfirmOS"
                >
                  Guardar
                </button>
              </Tooltip>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  edit: state.budgets.edit,
  accounts: state.accounts.list,
  accountTags: state.accounts.tags,
  pwaBudgets: state.pwa.budgets.list,
  currentUser: state.app.current_user,
  currentAccount: state.accounts.default,
  currentPWAAccount: state.pwa.accounts.default,
  budgets: state.budgets.list,
});

const mapDispatchToProps = (dispatch) => ({
  _createBudget: (
    id,
    title,
    account,
    amount,
    tags,
    status,
    createAt,
    edit,
    padre,
    availableAmount,
    idBudget,
    nombrePadre,
    fatherAvailable,
    budgets,
    user,
    idmod,
    mod,
    accounts,
    userEmail,
    budgetOld,
    reserveForLovs
  ) =>
    dispatch(
      createBudget(
        id,
        title,
        account,
        amount,
        tags,
        status,
        createAt,
        edit,
        padre,
        availableAmount,
        idBudget,
        nombrePadre,
        fatherAvailable,
        budgets,
        user,
        idmod,
        mod,
        accounts,
        userEmail,
        budgetOld,
        reserveForLovs
      )
    ),
  _pwaUpsertBudget: (
    id,
    title,
    account,
    amount,
    tags,
    status,
    edit,
    userEmail,
    budgetOld,
    reserveForLovs
  ) =>
    dispatch(
      pwaUpsertBudget(
        id,
        title,
        account,
        amount,
        tags,
        status,
        edit,
        userEmail,
        budgetOld,
        reserveForLovs
      )
    ),
});

export default connect(mapStateToProps, mapDispatchToProps)(ModalPresupuesto);
