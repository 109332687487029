import * as ACTION from "../actions/userLogout.action"

const initialState = {
    nextStepCategory: 0,
    nameAccount: "",
    nameCategory: ""
}

export default (state = initialState, action) => {
    switch (action.type) {
        case ACTION.NEXT_STEP:
            return {
                ...state,
                nextStepCategory: action.step
            }
        case ACTION.SAVE_NAME:
            return {
                ...state,
                nameAccount: action.name
            }
        case ACTION.CLEART_STEPS:
            return {
                ...state,
                nextStepCategory: 0,
                nameAccount: "",
                nameCategory: ""
            }
        default:
            return state
    }
}