import React, { useEffect, useMemo, useState, useRef } from "react";
import _ from "lodash";
import { connect } from "react-redux";
import { v4 } from "uuid";
import { withRouter } from "react-router-dom";
import { newEmailValidation } from "redux/actions/email.actions";
import { Tooltip } from "antd";
// organization components
import Lovs from "./lovs";
import { DateRangeFilter } from "libs/dateRange";
import { NumberFormatEdit } from "@easygosanet/react-datatable";
import { Select } from "antd";
import DetailColEdit from "components/DetailCol";
import DataTableUtils, {
  Share,
  FilterColumn2,
  ShowColumn2,
} from "@easygosanet/react-datatable-utils";
import Swal from "sweetalert2";
import * as Modal from "redux/actions/modal.actions";
import { showLoader } from "redux/actions/loader.actions";
import { getClients } from "redux/actions/clients.actions";
import * as API from "helpers/apiLA";
import * as APILA from "helpers/api";
import { Auth } from "aws-amplify";
import { pwaDeleteBudget } from "redux/actions/pwa.actions";
import {
  getAccountTags,
  updateAccountMembers,
} from "redux/actions/accounts.actions";
import {
  isToday,
  isYesterday, parse,
  format, isValid
} from "date-fns";
import {
  setEdit,
  clearEdit,
  getBudgets,
  clearBudgets,
} from "redux/actions/budgets.actions";
import { getAllCampaigns } from "redux/actions/allCampaings.actions";
import { withUtils } from "utils";
import analytics from "../../helpers/analytics";
import PageLoader from "components/page_loader";
import ModalPresupuesto from "./modalPresupuestos/ModalPresupuestos";
import ModalUpsertOld from "./modal/ModalUpserstBudget";
import "./assets/css/styles.scss";
import TableView from "./pages/tableView";
import { IdComponent } from "./pages/idComponent";
import { sincPartialOdoo } from "redux/actions/odooOvs.actions";
import iconoSinc from "./assets/img/icono.png";
import iconoSincHover from "./assets/img/iconohover.png"

const idmod = 3;
const mod = "PRESUPUESTOS";
const { Option } = Select;

const StatusName = (value) => {
  let name = "";
  if (value === 1) {
    name = "Activo";
  }
  if (value === 2) {
    name = "Pendiente";
  }
  if (value === 3) {
    name = "Rechazado";
  }

  return name;
};
const ConflictingBudgets = (props) => {
  const orderDefault = [
    "title",
    "createAt",
    "updateAt",
    "amount",
    "availableC",
    "available",
    "status",
  ];
  const groupingOptions = [
    "Nombre",
    "Presupuesto total",
    "Presupuesto implementado",
    "Presupuesto disponible",
    "Lovs",
  ];

  const [state, setState] = useState(1);
  const [options, setOptions] = useState(groupingOptions);
  const [budgets, setBudgets] = useState([]);
  const [create, setCreate] = useState(false); //Identifica si esta actualizando o creando
  const [modalUpsertOld, setModalUpsertOld] = useState(false);
  const [connection, setConnection] = useState([]);
  const [nameView, setNameView] = useState("");
  const [totalColumn, setTotalColumn] = useState([]);
  const [account, setAccount] = useState(props.currentAccount);
  const [isLoading, setIsLoading] = useState(true);
  const [filterText, setFilterText] = useState("");
  const [filterInfo, setFilterInfo] = useState([]);
  const [budgetsFilter, setBudgetsFilter] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [views, setViews] = useState([]);
  const [elim, setElim] = useState(false);
  const [nameGrouped, setnameGrouped] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [activate, setActivate] = useState(false);
  const [permissions, setPermissions] = useState({
    approve: false,
    create: false,
    delete: false,
    edit: false,
    export: false,
    manageRoles: false,
    view: true,
  });
  const [datesToShow, setDatesToShow] = useState({
    fechaIni: "",
    fechaFin: "",
  });
  const [textSinc, setTextSinc] = useState(false);
  const modalRef = useRef();

  function numberWithCommas(x) {
    let auxNumber = new Number(x).toFixed(2);
    return auxNumber.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  const [columns, setColumns] = useState([
    {
      omit: false,
      name: "Nombre de presupuesto",
      selector: "title",
      sortable: true,
      type: "text",
      id: "title",
      tag: "title",
      visible: false,
      center: true,
      show: true,
      cell: (row) => {
        let eliminar = row.availableC === 0 ? true : false;
        return (
          <DetailColEdit
            key={row.id}
            text={row.title}
            id={"name"}
            deleteData={() => eliminarPresupuesto(row)}
            deleteV={eliminar}
            nameColumn={row}
            reActivar={true}
            activate={row.active}
            viewBudget={true}
            activeData={() => activateData(row)}
            edit={row.active ? row.permisos?.edit : false}
            onEditValue={updateBudget}
            accounts={props.accounts}
            budgetOld={row.budgetOld}
            // editable={row.permisos.edit}
            editable={false}
            editInfo={() => goToEditPage(row)}
            type={"text"}
            permissions={
              row.permisos
                ? { ...row.permisos, delete: row.permisos.delete && eliminar }
                : ""
            }
          />
        );
      },
    },
    {
      name: "ID de presupuesto",
      omit: false,
      selector: "id",
      sortable: true,
      id: "id",
      tag: "title",
      center: true,
      show: true,
      cell: (row) => <IdComponent text={row.id} />,
    },
    {
      omit: false,
      name: "Línea de OV",
      selector: "idLovs",
      id: "idLovs",
      tag: "idLovs",
      type: "text",
      sortable: true,
      show: true,
      center: true,
      cell: (row) =>
        row.idLovs && row.idLovs.length > 0
          ? row.idLovs.reduce((acc, ele, index) => {
            acc += ele;
            if (index !== row.idLovs.length - 1) {
              acc += ", ";
            }
            return acc;
          }, "")
          : "",
    },
    {
      omit: false,
      name: "Presupuesto total",
      selector: "amount",
      id: "amount",
      tag: "amount",
      sortable: true,
      type: "selectOp",
      show: true,
      center: true,
      minWidth: "220px",
      cell: (row) => {
        let valor = `$${numberWithCommas(row.amount)}`;
        return (
          <NumberFormatEdit
            prefix={"$"}
            thousandSeparator={true}
            value={valor}
            id={"budget"}
            displayType={"text"}
            tipo={"text"}
            editable={false}
            nameColumn={row}
            onEditValue={updateBudget}
          />
        );
      },
    },
    {
      omit: false,
      name: "Presupuesto implementado",
      selector: "availableC",
      id: "availableC",
      tag: "availableC",
      sortable: true,
      center: true,
      type: "selectOp",
      show: true,
      cell: (row) => `$${numberWithCommas(row.availableC)}`,
    },
    {
      omit: false,
      name: "Presupuesto disponible",
      selector: "available",
      id: "available",
      tag: "available",
      sortable: true,
      type: "selectOp",
      show: true,
      center: true,
      cell: (row) => `$${numberWithCommas(row.available)}`,
    },
    {
      omit: false,
      name: "Fecha de creación",
      id: "createAt",
      tag: "createAt",
      selector: "createAt",
      sortable: true,
      center: true,
      cell: (row) => formatDate(row.createAt),
    },
    {
      omit: false,
      name: "Última Actualización",
      id: "updateAt",
      tag: "updateAt",
      selector: "updateAt",
      sortable: true,
      center: true,
      cell: (row) => {
        //console.log("row.updateAt",row.updateAt);
        return formatDate(row.updateAt);
      },
    },
  ]);

  /**
   *
   * @deprecated
   * Modulo en desuso, ya no se utiliza por niveles y gestion de disponibles en ovs
   */
  const updateBudget = (row) => {
    console.warn("Metodo sin funcionalidad");
  };

  const eliminarPresupuesto = async (row) => {
    let count = await APILA.getCountBudgets({ idBudget: row.id });
    let countB = count.data.count;
    if (countB > 0) {
      Swal.fire({
        title: `Presupuesto asociado a ${countB} ${countB === 1 ? "campaña" : "campañas"
          }`,
        icon: "info",
        showCancelButton: false,
        confirmButtonColor: "#3085d6",
        confirmButtonText: "Ok",
      }).then((response) => {
        if (response.isConfirmed) {
          setIsLoading(false);
          return;
        }
      });
    }
    let title = `¿Está seguro que desea eliminar el presupuesto <strong>${row.title}</strong>?, esta acción retornará lo reservado a cada línea de orden de venta relacionado a este presupuesto.`;

    props._showConfirmationModal("¿Eliminar presupuesto?", title, async () => {
      try {
        setIsLoading(true);
        // let elim = await APILA.deleteBudget({ id: row.id });
        let elim = await APILA.updateBudget({ id: row.id, active: false });

        let bodyBitacora = {
          id: v4(),
          date: new Date(),
          account: account,
          action: "se ha eliminado un presupuesto",
          component: "budgets",
          data: row,
        };
        await API.insertBitacora(bodyBitacora);
        props._newEmailValidation(
          props.currentAccount,
          "Presupuestos",
          row,
          props.currentUser,
          props.accounts,
          "Eliminación"
        );
        try {
          //TODO:  AQUI ESTOY TRABAJANDO
          const response = await APILA.getAllLovs({
            account: props.currentAccount,
          });
          let data = response.data.data;
          let data2 = response.data.lovsConflict;
          let mix = data.concat(data2);
          for (let elemento of mix) {
            let found = row.reserveForLovs?.find(
              (item) => item.id === elemento.id
            );
            if (found) {
              let newBudgetAvailable =
                parseFloat(elemento.budgetAvailable) +
                parseFloat(found.reserve);
              let newReserve =
                parseFloat(elemento.reserve) - parseFloat(found.reserve);
              let idsBudget = elemento.idsBudget.filter(
                (item) => item !== row.id
              );
              await APILA.updateAvailableLov({
                id: elemento.id,
                budgetAvailable: newBudgetAvailable,
                reserve: newReserve,
                idsBudget: idsBudget,
              });
            }
          }
        } catch (err) {
          console.log("No tienen lovs", err);
        }
        Swal.fire({
          title: "Presupuesto eliminado!",
          text: "Presupuesto eliminado correctamente.",
          icon: "success",
          showCancelButton: false,
          confirmButtonColor: "#3085d6",
          confirmButtonText: "Ok",
        });
        props._getBudgets(account, props.currentUser, idmod, mod);
        setIsLoading(false);
      } catch (error) {
        Swal.fire({
          title: "Ups!",
          text: "Hubo un error al eliminar el presupuesto",
          icon: "error",
          showCancelButton: false,
          confirmButtonColor: "#3085d6",
          confirmButtonText: "Ok",
        });
      }
    });
  };

  useEffect(() => {
    setAccount(props.currentAccount);
    actualizarViews(true);
    setElim(true);
    getConnection();
    getUserPermissions();
    props._getBudgets(props.currentAccount, props.currentUser, idmod, mod);
    // const numberBudgetView = Number(localStorage.getItem('viewBudget'))
    // if (numberBudgetView > 0) {
    //   changeState(numberBudgetView);
    // }
  }, [props.currentAccount]);

  useEffect(() => {
    if (props.budgets.length > 0) {
      const budgetsSort = _.orderBy(
        props.budgets,
        ["updateAt"],
        ["desc", "asc"]
      ).filter((ele) => ele.status === 2);
      const budgetAvailable = budgetsSort.map((element) => ({
        ...element,
        availableC: +element.amount - +element.available,
      }));
      setBudgets(budgetAvailable);
      asignedBudgetsAgo(budgetAvailable); // Pasa el estado actualizado como argumento
    }
  }, [props.budgets]);

  const asignedBudgetsAgo = (updatedBudgets, startDate, endDate) => {
    if (startDate && endDate) {
      const budgetByDateRange = updatedBudgets.filter((ele) => {
        const createAtDate = new Date(ele.createAt);
        createAtDate.setHours(0, 0, 0, 0);
        return (
          createAtDate >= new Date(startDate) &&
          createAtDate <= new Date(endDate)
        );
      });

      setBudgetsFilter(budgetByDateRange);
    } else if (datesToShow.fechaIni !== "" && datesToShow.fechaFin !== "") {
      // const budgetBySevenDaysAgo = updatedBudgets.filter(ele => new Date(ele.createAt) >= new Date(datesToShow.fechaIni) && new Date(ele.createAt) <= new Date(datesToShow.fechaFin));
      const budgetByDateRange = updatedBudgets.filter((ele) => {
        const createAtDate = new Date(ele.createAt);
        createAtDate.setHours(0, 0, 0, 0);
        return (
          createAtDate >= new Date(datesToShow.fechaIni) &&
          createAtDate <= new Date(datesToShow.fechaFin)
        );
      });

      setBudgetsFilter(budgetByDateRange);
    } else {
      const sevenDaysAgo = new Date();
      sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 7);
      const currentDay = new Date();
      setDatesToShow({
        fechaIni: sevenDaysAgo,
        fechaFin: currentDay,
      });
      const budgetBySevenDaysAgo = updatedBudgets.filter(
        (ele) => new Date(ele.createAt) >= sevenDaysAgo
      );

      setBudgetsFilter(budgetBySevenDaysAgo);
    }
  };

  const getConnection = async () => {
    try {
      const response = await API.getConnectionsByAccount({
        account: props.currentAccount,
      });

      const connections = response.data.connections;
      setConnection(connections);
    } catch (error) {
      console.error("Error al obtener las conexiones:", error);
    }
  };

  const getUserPermissions = async () => {
    try {
      setIsLoading(true);

      const userLog = await Auth.currentAuthenticatedUser();
      const permisoAccResponse = await API.getPermitsByAccountAndUser({
        account: props.currentAccount,
        user: userLog.username,
      });
      const idRol = permisoAccResponse.data.permisos[0].idRol;
      const permisosResResponse = await API.getRoleNameById({ id: idRol });
      const permissAud = permisosResResponse.data.rol[0].permissions;

      const permissFn = permissAud.find((ele) => ele.name === "Presupuestos");

      if (!permissFn) {
        setDefaultPermissions();
        return;
      }

      setPermissions({
        approve: permissFn.approve,
        create: permissFn.create,
        delete: permissFn.delete,
        edit: permissFn.edit,
        export: permissFn.export,
        manageRoles: permissFn.manageRoles,
        view: permissFn.view,
      });
    } catch (error) {
      setDefaultPermissions();
    } finally {
      setIsLoading(false);
    }
  };

  const setDefaultPermissions = () => {
    setPermissions({
      approve: false,
      create: false,
      delete: false,
      edit: false,
      export: false,
      manageRoles: false,
      view: false,
    });
  };

  const actualizarViews = async (tipo) => {
    setElim(true);
    if (!Auth.user) return;
    const sub = Auth.user.signInUserSession.idToken.payload.sub;
    try {
      const viewsData = await API.getViewsByUser({
        account: props.currentAccount,
        user: sub,
        component: "budgets",
      });

      const dataVie = viewsData.data.views;
      setViews(dataVie);

      if (tipo) setFilterInfo([]);

      if (dataVie.length === 0) return setNameView("Lista");

      const vistaActiva = dataVie.find((ele) => ele.active);
      if (!vistaActiva) return;
      setNameView(vistaActiva.name);
      if (tipo) {
        const nombres = vistaActiva.columns.map((ele) => {
          handleShowColumnChange(ele.selector, ele.omit);
          return ele.id;
        });
        handleColumnSortChange(nombres);
      }
    } catch (error) {
      console.error("Error fetching views:", error);
    }
  };

  const goToEditPage = async (edit) => {
    props._setEdit(edit);
    analytics.accionesTabla({
      accion: "Editar",
      ubicacion: "Presupuestos",
    });
    setIsLoading(true);
    try {
      if (connection.length) {
        setOpenModal(true);
        setCreate(false);
        setModalUpsertOld(true);
      } else {
        await APILA.getAllLovs({
          account: props.currentAccount,
        });
        setOpenModal(true);
        setCreate(false);
        setModalUpsertOld(true);
      }
    } catch (err) {
      console.log("Error en los servicios", err);
    } finally {
      setIsLoading(false);
    }
  };

  const formatDate = (millis) => {
    let date = new Date(millis);
    const months = [
      "Ene",
      "Feb",
      "Mar",
      "Abr",
      "May",
      "Jun",
      "Jul",
      "Ago",
      "Sep",
      "Oct",
      "Nov",
      "Dic",
    ];

    return `${date.getDate()} - ${months[date.getMonth()]
      } - ${date.getFullYear()}`;
  };

  const handleCalendarFilter = (value) => {
    setDatesToShow({
      fechaFin: value.endDate,
      fechaIni: value.startDate,
    });
    asignedBudgetsAgo(budgets, value.startDate, value.endDate);
  };

  const handleFilterTextChange = (value) => {
    if (value != "") {
      analytics.busqueda({
        ubicacion: "Presupuestos",
        busqueda: filterText,
      });
    }
    setFilterText(value);
    if (value !== "") {
      const dataFilter = budgetsFilter.filter((item) => {
        if (item.title.toLowerCase().includes(value.toLowerCase())) {
          return item.title.toLowerCase().includes(value.toLowerCase());
        } else if (item.padre.toLowerCase().includes(value.toLowerCase())) {
          return item.padre.toLowerCase().includes(value.toLowerCase());
        } else if (value.toLowerCase().includes("Eliminado".toLowerCase())) {
          return !item.active;
        } else if (item.id.toLowerCase().includes(value.toLowerCase())) {
          return item.id.toLowerCase().includes(value.toLowerCase());
        }
      });
      setBudgetsFilter(dataFilter);
    } else {
      asignedBudgetsAgo(budgets);
    }
  };

  const handleShowColumnChange = (column, show) => {
    analytics.ShowHide({
      ubicacion: "Presupuestos",
    });
    let index = columns.findIndex((col) => col.id === column);
    let aux = columns.map((col) => col);
    if (aux[index]) {
      aux[index].omit = show;
      setColumns(aux);
    }
  };

  const handleShowView = async (vista, show) => {
    let nombres = [];
    let id = views.find((ele) => ele.active);
    if (id) actualizarEstado(id.id, false);
    actualizarEstado(vista, show);
    let vistaEnc = views.find((ele) => ele.id === vista);
    vistaEnc.columns.sort((a, b) => {
      return a.position - b.position;
    });
    vistaEnc.columns.forEach((ele) => {
      if (show) {
        nombres.push(ele.id);
        handleShowColumnChange(ele.selector, ele.omit);
      } else {
        handleShowColumnChange(ele.selector, false);
      }
    });
    if (show) {
      handleColumnSortChange(nombres);
    } else {
      handleColumnSortChange(orderDefault);
    }
  };

  const handleAddView = async (name) => {
    setIsLoading(true);
    let id = views.find((ele) => ele.active);
    let viewEnc = views.find((ele) => ele.name === name);
    let idVIew = "";
    if (viewEnc) idVIew = viewEnc.id;
    else idVIew = v4();
    if (id) actualizarEstado(id.id, false);
    let body = {
      id: idVIew,
      name: name,
      component: "budgets",
      columns: columns,
      account: account,
      user: props.currentUser,
      active: true,
    };
    await API.insertView(body);
    actualizarViews(false);
    setIsLoading(false);
  };

  const actualizarEstado = async (id, estado) => {
    setIsLoading(true);
    let body = {
      id: id,
      active: estado,
    };
    await API.updateViewActive(body);
    actualizarViews(false);
    setIsLoading(false);
  };

  const handleDeleteView = async (name) => {
    try {
      setIsLoading(true);
      const activeView = views.find((view) => view.active);
      if (activeView && activeView.id === name) {
        setNameView("lista");
        columns.forEach((column) => {
          handleShowColumnChange(column.selector, false);
        });
        handleColumnSortChange(orderDefault);
      }
      await API.deleteView({ id: name });
      actualizarViews(false);
    } catch (error) {
      console.error("Error deleting view:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const formatCellValue = (cellValue, selector) => {
    switch (selector) {
      case "createAt":
      case "updateAt":
        return formatDate(cellValue);
      case "status":
        return StatusName(cellValue);
      case "idLovs":
        return cellValue && cellValue.length > 0
          ? cellValue.map((ele) => ele).toString()
          : "";
      case "reserveForLovs":
        return "";
      default:
        return cellValue;
    }
  };

  const calculateTotal = (rows) => {
    let totalAmount = 0;
    let totalImplemented = 0;
    let totalAvailable = 0;
    rows.forEach((ele) => {
      if (new Number(ele.amount) > 0) {
        totalAmount += new Number(ele.amount);
      }
      if (new Number(ele.availableC) > 0) {
        totalImplemented += new Number(ele.availableC);
      }
      if (new Number(ele.available) > 0) {
        totalAvailable += new Number(ele.available);
      }
    });

    let totales = [
      {
        title: "Totales",
        id: "",
        amount: totalAmount,
        availableC: totalImplemented,
        available: totalAvailable,
        createAt: 0,
        updateAt: 0,
      },
    ];
    return totales;
  };

  const exportDataToExcel = () => {
    const headers = columns.filter((col) => !col.omit).map((col) => col.name);
    const selectors = columns.filter((col) => !col.omit).map((col) => col.tag);
    const dataExcel = [];

    if (!_.isEmpty(budgetsFilter)) {
      if (nameGrouped === "lovs") {
        let titlesUsed = new Set();

        budgetsFilter.forEach((ele) => {
          const idLovs = ele.idLovs;
          if (idLovs && idLovs.length > 0) {
            idLovs.forEach((idLov) => {
              if (!titlesUsed.has(idLov)) {
                titlesUsed.add(idLov);
                let dataGrouped = budgetsFilter.filter((element) =>
                  element.idLovs?.includes(idLov)
                );
                dataExcel.push([idLov]);
                dataExcel.push(headers);

                dataGrouped.forEach((item) => {
                  const excelData = selectors.map((selector) =>
                    formatCellValue(item[selector], selector)
                  );
                  dataExcel.push(excelData);
                });
                const totalData = calculateTotal(dataGrouped);
                if (totalData.length > 0) {
                  const excelTotalData = selectors.map((selector) =>
                    formatCellValue(totalData[0][selector], selector)
                  );
                  dataExcel.push(excelTotalData);
                }
              }
            });
          }
        });
      } else if (nameGrouped !== "") {
        let titlesUsed = [];
        budgetsFilter.map((elemento) => {
          let title =
            nameGrouped == "title"
              ? elemento[nameGrouped]
              : numberWithCommas(elemento[nameGrouped]);
          if (!titlesUsed.includes(title)) {
            dataExcel.push([], [title]);
            dataExcel.push(headers);
            let grouedData = budgetsFilter.filter((item) => {
              if (elemento[nameGrouped] === item[nameGrouped]) {
                return item;
              }
            });

            const excelData = selectors.map((selector) =>
              formatCellValue(elemento[selector], selector)
            );
            dataExcel.push(excelData);
            calculateTotal(grouedData).map((element) => {
              const excelData = selectors.map((selector) =>
                formatCellValue(element[selector], selector)
              );
              dataExcel.push(excelData);
            });
          }
        });
      } else {
        dataExcel.push(headers);
        budgetsFilter.forEach((element) => {
          const excelData = selectors.map((selector) =>
            formatCellValue(element[selector], selector)
          );
          dataExcel.push(excelData);
        });
        calculateTotal(budgetsFilter).map((element) => {
          const excelData = selectors.map((selector) =>
            formatCellValue(element[selector], selector)
          );
          dataExcel.push(excelData);
        });
      }
    }

    return dataExcel;
  };

  const handleColumnSortChange = (positions) => {
    let aux = columns.map((col) => col);

    positions.forEach((columnId, position) => {
      let index = columns.findIndex((col) => col.selector === columnId);
      if (index != -1) aux[index].position = position;
    });

    aux = _.orderBy(aux, ["position"]);

    setColumns(aux);
  };

  const handleFilterPerColumn = (value) => {
    if (value.length === 0 && filterInfo.length !== 0) {
      setFilterInfo([]);
      asignedBudgetsAgo(budgets);
      return;
    }

    analytics.filtro({
      ubicacion: "Presupuestos",
    });

    const filteredValues = value.filter(
      (el) =>
        typeof el.value !== "boolean" &&
        el.value !== "Igual" &&
        el.value !== "Mayor" &&
        el.value !== "Menor"
    );

    if (value.length === 0) return setFilterInfo(filteredValues);
    const dataFinal = [];

    filteredValues.forEach((el) => {
      let dataAlt = [];
      switch (el.column) {
        case "Nombre de presupuesto":
          dataAlt = budgets.filter((ele) =>
            ele.title.toLowerCase().includes(el.value.toLowerCase())
          );
          break;
        case "Línea de OV":
          dataAlt = budgets.filter(
            (ele) => Array.isArray(ele.idLovs) && ele.idLovs.includes(+el.value)
          );
          break;
        case "status":
          dataAlt = budgets.filter((ele) => ele.status === el.value);
          break;
        case "Presupuesto total":
          dataAlt = budgets.filter((ele) => {
            const amount = Number(ele.amount);
            return el.type === "Menor"
              ? amount < +el.value
              : el.type === "Igual"
                ? amount === +el.value
                : el.type === "Mayor"
                  ? amount > +el.value
                  : false;
          });
          break;
        case "Presupuesto implementado":
          dataAlt = budgets.filter((ele) => {
            const availableC = Number(ele.availableC);
            return el.type === "Menor"
              ? availableC < +el.value
              : el.type === "Igual"
                ? availableC === +el.value
                : el.type === "Mayor"
                  ? availableC > +el.value
                  : false;
          });
          break;
        case "Presupuesto disponible":
          dataAlt = budgets.filter((ele) => {
            const available = Number(ele.available);
            return el.type === "Menor"
              ? available < +el.value
              : el.type === "Igual"
                ? available === +el.value
                : el.type === "Mayor"
                  ? available > +el.value
                  : false;
          });
          break;
      }

      if (dataAlt.length > 0) {
        dataFinal.push(...dataAlt);
      }
    });

    const dataActiva = dataFinal.filter((ele) => ele.active !== false);
    const set = new Set(dataActiva.map(JSON.stringify));
    const arrSinDuplicaciones = Array.from(set).map(JSON.parse);

    setBudgetsFilter(arrSinDuplicaciones);
    setFilterInfo(filteredValues);
  };

  let cols = _.orderBy(columns, ["name"], ["asc", "desc"]);

  const agrupacionChange = () => {
    let titlesUsed = [];
    const elements = budgetsFilter.map((ele, idx) => {
      let title =
        nameGrouped == "title"
          ? ele[nameGrouped]
          : numberWithCommas(ele[nameGrouped]);
      if (!titlesUsed.includes(title)) {
        titlesUsed.push(title);
        let dataGrouped = budgetsFilter.filter(
          (element) => element[nameGrouped] === ele[nameGrouped]
        );

        return (
          <>
            <h1>{title}</h1>
            <TableView
              key={idx}
              columns={columns}
              setData={setBudgetsFilter}
              filterText={filterText}
              data={dataGrouped}
              permissions={permissions}
            />
            ;
          </>
        );
      }
    });
    return elements;
  };

  const handleGroupedChange = async (value) => {
    try {
      setIsLoading(true);

      analytics.agrupar({
        ubicacion: "Presupuestos",
      });

      let groupedBy = "";

      switch (value) {
        case "Nombre":
          groupedBy = "title";
          break;
        case "Fecha de creación":
          groupedBy = "createAt";
          break;
        case "Última actualización":
          groupedBy = "updateAt";
          break;
        case "Presupuesto total":
          groupedBy = "amount";
          break;
        case "Presupuesto implementado":
          groupedBy = "availableC";
          break;
        case "Presupuesto disponible":
          groupedBy = "available";
          break;
        case "Estado":
          groupedBy = "status";
          break;
        case "Lovs":
          groupedBy = "lovs";
          break;
        default:
          groupedBy = "";
          break;
      }

      setnameGrouped(groupedBy);

      const updatedOptions = [
        value,
        ...groupingOptions.filter((option) => option !== value),
      ];
      setOptions(updatedOptions);

      await new Promise((resolve) => setTimeout(resolve, 500));
    } catch (error) {
      console.error("Error grouped change:", error);
    } finally {
      setIsLoading(false);
    }
  };

  function obtenerHoraFormateada(fechaStr) {
    return fechaStr?.split(",")[1] || "";
  }

  function esFechaValida(fecha) {
    return !isNaN(fecha.getTime());
  }

  function obtenerFechaFormateada(fechaStr) {
    const formatoEsperado = /\d{4}-\d{2}-\d{2}/;
    if (!formatoEsperado.test(fechaStr?.split(",")[0])) {
      return format(new Date(), "dd/MM/yyyy HH:mm:ss");
    }
    const fechaCorto = parse(fechaStr?.split(",")[0], "yyyy-MM-dd", new Date());
    if (isValid(fechaCorto)) {
      return format(fechaCorto, "dd/MM/yyyy");
    } else {
      return format(new Date(), "dd/MM/yyyy HH:mm:ss");
    }
  }

  function determinarDia(fechaStr) {
    const formatoCorto = "yyyy-MM-dd";
    const formatoLargo = "dd/MM/yyyy, HH:mm:ss";

    // Intentar parsear la fecha en formato largo
    let fechaFormatoLargo = parse(fechaStr, formatoLargo, new Date());
    let validate = esFechaValida(fechaFormatoLargo);

    if (!validate) {
      // Si no se pudo parsear en formato largo, intentar en formato corto
      fechaFormatoLargo = parse(fechaStr, formatoCorto, new Date());
      validate = esFechaValida(fechaFormatoLargo);
    }

    if (isToday(fechaFormatoLargo)) {
      return "hoy " + obtenerHoraFormateada(fechaStr, formatoLargo);
    } else if (isYesterday(fechaFormatoLargo)) {
      return "ayer " + obtenerHoraFormateada(fechaStr, formatoLargo);
    } else {
      return obtenerFechaFormateada(
        fechaStr,
        validate ? formatoCorto : formatoLargo
      );
    }
  }

  useEffect(() => {
    function handleClickOutside(event) {
      if (modalRef.current && !modalRef.current.contains(event.target) && !event.target.closest('.boxCalendar')) {
        setActivate(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [modalRef]);

  const activateData = async (row) => {
    let title = "";
    title = `¿Está  seguro que desea activar el presupuesto <strong>"${row.title}"</strong>?`;
    props._showConfirmationModal("¿Activar presupuesto?", title, async () => {
      try {
        setIsLoading(true);
        if (row.reserveForLovs) {
          let response = await APILA.getAllLovs({
            account: props.currentAccount,
          });
          let data = response.data.data;
          for (let ele of data) {
            let found = row.reserveForLovs.find(
              (elemento) => elemento.id === ele.id
            );
            if (found !== undefined) {
              let newBudgetAvailable =
                parseFloat(ele.budgetAvailable) - parseFloat(found.reserve);
              let newReserve = Number(ele.reserve) + Number(found.reserve);
              await APILA.updateAvailableLov({
                id: found.id,
                budgetAvailable: newBudgetAvailable,
                reserve: newReserve,
                idsBudget: ele.idsBudget,
              });
            }
          }
        } else {
          console.log("No contiene reserveForLovs");
        }
        let activat = await APILA.updateBudget({ id: row.id, active: true });

        let bodyBitacora = {
          id: v4(),
          date: new Date(),
          account: account,
          action: "se ha activado un presupuesto",
          component: "budgets",
          data: row,
        };
        API.insertBitacora(bodyBitacora).then(() => {
          Swal.fire({
            title: "Presupuesto activado!",
            text: "Presupuesto activado correctamente.",
            icon: "success",
            showCancelButton: false,
            confirmButtonColor: "#3085d6",
            confirmButtonText: "Ok",
          });

          props._clearBudgets();
          setTimeout(() => {
            props._getClients(props.currentUser, idmod, mod);
            props._getBudgets(account, props.currentUser, idmod, mod);
            props._getAccountTags(account, props.currentUser, idmod, mod);
            props._getAllCampaigns(account, props.currentUser, idmod, mod);
            setFilterText("");
            setIsLoading(false);
          }, 500);
        });
        setIsLoading(false);
      } catch (error) {
        Swal.fire({
          title: "Ups!",
          text: "Hubo un error al activar el presupuesto",
          icon: "error",
          showCancelButton: false,
          confirmButtonColor: "#3085d6",
          confirmButtonText: "Ok",
        });
        setIsLoading(false);
      }
    });
  };

  const agrupacionLovs = () => {
    if (nameGrouped === "lovs") {
      let titlesUsed = [];
      const elements = budgetsFilter.flatMap((ele) => {
        const idLovs = ele.idLovs;
        if (idLovs && idLovs.length > 0) {
          return idLovs.map((idLov) => {
            if (!titlesUsed.includes(idLov)) {
              titlesUsed.push(idLov);
              let dataGrouped = budgetsFilter.filter((element) =>
                element.idLovs?.includes(idLov)
              );
              return (
                <>
                  <h1>{idLov}</h1>
                  <TableView
                    key={idLov}
                    columns={columns}
                    filterText={filterText}
                    setData={setBudgetsFilter}
                    data={dataGrouped}
                    permissions={permissions}
                  />
                </>
              );
            }
            return null; // Retornar null para elementos repetidos
          });
        }
        return null; // Retornar null para objetos sin idLovs
      });
      return elements;
    }
  };

  const viewChanges = useMemo(() => {
    switch (state) {
      case 2:
        return (
          <Lovs setIsLoading={setIsLoading} state={state} setState={setState} />
        );
      case 3:
        return (
          <Lovs setIsLoading={setIsLoading} state={state} setState={setState} />
        );
      default:
        if (nameGrouped === "lovs") return agrupacionLovs();
        else if (nameGrouped !== "") return agrupacionChange();
        else
          return (
            <TableView
              columns={columns}
              filterText={filterText}
              setData={setBudgetsFilter}
              data={budgetsFilter}
              permissions={permissions}
              setTotalColumn={setTotalColumn}
            />
          );
    }
  }, [state, nameGrouped, columns, budgetsFilter, permissions]);

  const changeState = async (value) => {
    props.setState(value);
    localStorage.setItem("viewBudget", value);
  };

  return (
    <>
      {isLoading ? (
        <PageLoader />
      ) : permissions.view ? (
        <>
          <div className="barraSuperior barraSuperiorBudget">
            <Select
              id="viewSelect"
              defaultValue={props.state}
              showSearch={false}
              onChange={(e) => changeState(e)}
              className="select-dashboard"
            >
              <Option value={1} className="fa textSelectBudget">
                <Tooltip
                  title="Vista de presupuestos"
                  placement="rightTop"
                  color="#002448"
                >
                  <div>
                    <i className="fa fa-table"></i> Vista de presupuestos{" "}
                  </div>
                </Tooltip>
              </Option>
              <Option value={4} className="fa textSelectBudget">
                <Tooltip
                  title="Presupuestos en conflictos"
                  placement="rightTop"
                  color="#002448"
                >
                  <div>
                    <i className="fa fa-table"></i> Presupuestos en conflictos{" "}
                  </div>
                </Tooltip>
              </Option>
              <Option value={3} className="fa textSelectBudget">
                <Tooltip
                  title="Vista de líneas de OV"
                  placement="rightTop"
                  color="#002448"
                >
                  <div>
                    <i className="fa fa-table"></i> Vista de líneas de OV{" "}
                  </div>
                </Tooltip>
              </Option>
              <Option value={2} className="fa textSelectBudget">
                <Tooltip
                  title="Líneas de OV en conflicto"
                  placement="rightTop"
                  color="#002448"
                >
                  <div>
                    <i className="fa fa-table"></i> Líneas de OV en conflicto{" "}
                  </div>
                </Tooltip>
              </Option>
            </Select>
            <DataTableUtils
              data={budgetsFilter}
              valueFilter={filterText || ""}
              action={null}
              actionIcon={null}
              onChangeFilterText={(value) => {
                handleFilterTextChange(value);
              }}
            >
              <Tooltip
                color="#002448"
                placement="top"
                title="Exportar información"
              >
                <div
                  onClick={() => {
                    analytics.Compartir({
                      ubicacion: "Presupuestos",
                    });
                  }}
                  className="ExportBtn"
                >
                  {permissions.export && (
                    <Share
                      data={exportDataToExcel()}
                      columns={columns}
                      isArray={true}
                      exportXLSX={true}
                      exportTXT={true}
                      exportDropbox={true}
                      exportDrive={true}
                      exportCSV={true}
                      printable={true}
                      exportPNG={true}
                      idImg={"root"}
                      appKey="2u78gno172idwbz"
                      exportLink={true}
                    />
                  )}
                </div>
              </Tooltip>
              <Tooltip
                color="#002448"
                placement="top"
                title="Personalizar columnas"
              >
                <div id="shareBtn">
                  <ShowColumn2
                    columns={columns}
                    showColumn={handleShowColumnChange}
                    showView={handleShowView}
                    onSortChange={(positions) => {
                      analytics.Columnas({
                        ubicacion: "Presupuestos",
                      });
                      handleColumnSortChange(positions);
                    }}
                    vistas={views}
                    // setVista={handleAddView}
                    // deleteView={handleDeleteView}
                    setVista={(nombre) => {
                      analytics.accionesVista({
                        ubicacion: "Presupuestos",
                        accion: "Agregar",
                      });
                      handleAddView(nombre);
                    }}
                    deleteView={(name) => {
                      analytics.accionesVista({
                        ubicacion: "Presupuestos",
                        accion: "ELiminar",
                      });
                      handleDeleteView(name);
                    }}
                    nameAc={nameView}
                  />
                </div>
              </Tooltip>
              <Tooltip
                color="#002448"
                placement="top"
                title="Filtrar y agrupar"
              >
                <div className="Filtro2 filtro3 ">
                  <FilterColumn2
                    columns={cols}
                    options={options}
                    data={budgetsFilter}
                    changeGrouped={handleGroupedChange}
                    onFilterColumn={(value) => handleFilterPerColumn(value)}
                    filterInfoCheck={filterInfo}
                    limpiarData={elim}
                    setLimpiarData={setElim}
                    categories={[]}
                  />
                </div>
              </Tooltip>
              <div className="boxCalendar" ref={modalRef} onClick={() => setActivate(!activate)}>
                <div className="boxStartEndDate">
                  <p className="startDate" onClick={() => setActivate(!activate)}>
                    Del {new Date(datesToShow.fechaIni).toLocaleDateString("es-GT")}
                  </p>
                  <p className="endDate" onClick={() => setActivate(!activate)}>
                    Hasta {new Date(datesToShow.fechaFin).toLocaleDateString("es-GT")}
                  </p>
                </div>
                <div className="container-icon-calendar">
                  <i className="fa fa-calendar" aria-hidden="true" style={{ color: "black" }} ></i>
                </div>
                {activate && (
                  <div onClick={(e) => e.stopPropagation()}>
                    <DateRangeFilter
                      onActivate={activate}
                      onChange={handleCalendarFilter}
                    />
                  </div>
                )}
              </div>
            </DataTableUtils>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
            }}
          >
            <a
              style={{
                fontSize: "18px",
                fontFamily: "Poppins",
                color: textSinc ? "#A689F9" : "#686868",
              }}
            >
              {" "}
              {textSinc
                ? "¡ERP actualizado! "
                : "Última sincronización "}{" "}
            </a>
            <a
              style={{
                fontSize: "18px",
                fontFamily: "Poppins",
                marginRight: "-15px",
                marginLeft: "10px",
                fontWeight: 600,
              }}
            >
              {determinarDia(props.lastSinc)}
            </a>
            <a
              href="#"
              onClick={async () => {
                await props._sincPartialOdoo(
                  props.currentAccount,
                  props.accounts
                );
                setTextSinc(true);
                setTimeout(() => {
                  setTextSinc(false);
                }, 30000);
              }}
              style={{
                marginLeft: "2%",
                fontSize: "18px",
                fontFamily: "Poppins",
              }}
              className="hover-container"
            >
              <img
                src={iconoSinc}
                alt="Icono Sinc"
                className="imagen-normal"
              />
              <img
                src={iconoSincHover}
                alt="Icono Sinc"
                className="imagen-hover"
              />
            </a>
          </div>
          <div className="Kanbang-content">
            <div className="List-category">{viewChanges}</div>
          </div>

          {!connection.length && modalUpsertOld && (
            <ModalUpsertOld
              modalUpsertOld={modalUpsertOld}
              setModalUpsertOld={setModalUpsertOld}
              create={create}
            />
          )}
          {showModal && connection.length && (
            <ModalPresupuesto
              setShowModal={setShowModal}
              showModal={showModal}
            />
          )}
        </>
      ) : (
        <h1>No tienes permisos</h1>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  lastSinc: state.odoo.lastSinc,
  clients: state.clients,
  budgets: state.budgets.list,
  accounts: state.accounts.list,
  account: state.accounts.default,
  pwaBudgets: state.pwa.budgets.list,
  currentUser: state.app.current_user,
  currentAccount: state.accounts.default,
  currentPWAAccount: state.pwa.accounts.default,
  allCampaigns: state.allCampaigns,
});

const mapDispatchToProps = (dispatch) => ({
  _clearEdit: () => dispatch(clearEdit()),
  _getClients: (user, idmod, mod) => dispatch(getClients(user, idmod, mod)),
  _setEdit: (edit) => dispatch(setEdit(edit)),
  _clearBudgets: () => dispatch(clearBudgets()),
  _showLoader: (show) => dispatch(showLoader(show)),
  _sincPartialOdoo: (account, accounts) =>
    dispatch(sincPartialOdoo(account, accounts)),
  _getBudgets: (account, user, idmod, mod) =>
    dispatch(getBudgets(account, user, idmod, mod)),
  _pwaDeleteBudget: (id) => dispatch(pwaDeleteBudget(id)),
  _getAllCampaigns: (account, user, idmod, mod) =>
    dispatch(getAllCampaigns(account, user, idmod, mod)),
  _getAccountTags: (account, user, idmod, mod) =>
    dispatch(getAccountTags(account, user, idmod, mod)),
  _updateAccountMembers: (account, members) =>
    dispatch(updateAccountMembers(account, members)),
  _showSelectAccountModal: () =>
    dispatch(
      Modal.showAlertModal(
        "Cuenta Requerida",
        "Por favor selecciona una cuenta antes",
        Modal.MODAL_TYPE_WARNING,
        () => { }
      )
    ),
  _showConfirmationModal: (title, message, callback) =>
    dispatch(
      Modal.showAlertModal(
        title,
        message,
        Modal.MODAL_TYPE_CONFIRMATION,
        callback
      )
    ),
  _newEmailValidation: (idAccount, modulo, data, usuario, accounts, action) =>
    dispatch(
      newEmailValidation(idAccount, modulo, data, usuario, accounts, action)
    ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withUtils(withRouter(ConflictingBudgets)));
