/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { withRouter, Link } from 'react-router-dom'
import { connect } from 'react-redux'

import ReactDataTable from '@easygosanet/react-datatable'

import './assets/css/styles.css'

import { getDatos, setProp } from 'redux/actions/fanpageWebHook.actions'
import { withUtils } from 'utils'

const Datos = (props) => {
  const consultar = (e) => {
    e.preventDefault()
    props.getDatos()
  }

  const columns = [
    {
      name: 'ID Pagina',
      selector: 'id',
      sortable: true,
      center: true,
    },
    {
      name: 'Mensaje',
      selector: 'message',
      sortable: true,
      center: true,
    },
    {
      name: 'Tipo',
      selector: 'type',
      sortable: true,
      center: true,
    },
    {
      name: 'Autor',
      selector: 'from',
      sortable: true,
      center: true,
      cell: (row) => {
        if (row.from) {
          return `${row.from.id} - ${row.from.name || ''}`
        }
        return ''
      },
    },
    {
      name: 'Fecha',
      selector: 'date',
      sortable: true,
      center: true,
    },
  ]

  return (
    <>
      <div>
        <Link to='/scan'>Regresar</Link>
      </div>
      <div>
        <button type='button' onClick={consultar}>
          Consultar
        </button>
        <div className='Kanbang-content'>
          <div className='List-category'>
            <ReactDataTable
              title='Datos Recientes'
              columns={columns}
              data={props.info}
              pagination
            />
          </div>
        </div>
      </div>
    </>
  )
}

const mapStateToProps = (state) => ({
  info: state.fanpageDatosWeb.info,
})

const mapDispatchToProps = {
  setProp,
  getDatos,
}

export default connect(mapStateToProps, mapDispatchToProps)(withUtils(withRouter(Datos)))
