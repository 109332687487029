/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { showLoader } from 'redux/actions/loader.actions'
import TextField from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'
import {
  FormGroup,
  Label,
  Input,
  FormFeedback,
} from 'reactstrap'
import {
  saveAWSUser,
  saveEmail,
  saveEmailName
} from 'redux/actions/medioCamp.actions'
import { saveSteps } from 'redux/actions/nameCamp.actions'
import { getCountries } from 'redux/actions/medioCamp.actions'
import * as Modal from 'redux/actions/modal.actions'
import { Auth } from 'aws-amplify'
import { v4 } from 'uuid'
import { setCurrentUser } from 'redux/actions/app.actions'
const MedioCamp = (props) => {
  const TAG = 'RegisterCamp'
  const [nameValue, setNameValue] = useState('')
  const [emailValue, setEmailValue] = useState('')
  const [phoneValue, setPhoneValue] = useState('')
  const [contryValue, setContryValue] = useState('')
  const [passwordValue, setPasswordValue] = useState('')

  useEffect(() => {
    // props._getMediumBanner('')
    // props._getCountries();
    if (props.campaignupd.length > 0) {
      // const bod = {
      //   id_medium: props.campaignupd[0].id_medium,
      // }
      // props._getMediumBanner(bod)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  const signUp = async e => {
    e.preventDefault()
    props._showLoader(true)
    let error = ''

    try {
      console.log(TAG, 'name', nameValue)
      console.log(TAG, 'phone', phoneValue)
      console.log(TAG, 'country', contryValue)
      console.log(TAG, 'username', emailValue)
      console.log(TAG, 'password', passwordValue)

      // let user = await Auth.signUp({
      //   username: emailValue,
      //   password: passwordValue,
      //   attributes: {
      //     name: nameValue,
      //     email: emailValue,
      //     // phone_number: phoneValue,
      //     // other custom attributes
      //     'custom:country': contryValue,
      //     'custom:phone': phoneValue,
      //   },
      // })      
      props._saveEmail(emailValue);
      props._saveEmailName(nameValue.trim());
      props._saveSteps(7)
      
      
    } catch (err) {
      let { message } = err
      error = message

      console.log(TAG, 'error signing up', err)
    }

    props._showLoader(false)

    if (error !== '') {
      props._showAlertModal('Error!', error, Modal.MODAL_TYPE_ERROR)
    }
  }
  const handleCountryChange = (event, value) => {
    console.log('handleInputChange')
    console.log('value country', value)

    if (value) {
      let bod = {
        id_region: value.id_region,
        bandera: 1
      }
      // setState(prev => ({
      //   ...prev,
      //   country: {
      //     id_region: value.id_region,
      //     name: value.name,
      //   } 
      // }))

      // setState(prev => ({
      //   ...prev,
      //   medio: {
      //     id_medium: '',
      //     site: '',
      //   } 
      // }))

      // setState(prev => ({
      //   ...prev,
      //   product:{id_ad_product: '', banner: ''}
      // }))
      props._getMediumBanner(bod)
    }  
  }
  const handleMediumChange = (event, value) => {
    console.log('handleInputChange')
    console.log('value', value)

    // if (value) {
    //   let bod = {
    //     id_medium: value.id_medium,
    //     id_region: state.country.id_region
    //   }
    //   // setState(prev => ({
    //   //   ...prev,
    //   //   medio: {
    //   //     id_medium: value.id_medium,
    //   //     site: value.site,
    //   //   } 
    //   // }))
    //   props._getMediumBanner(bod)
    // }  
  }
  const cambiomedium = (dato) => {
    if (dato) {
      props._saveproduct(dato.id_ad_product)
    }
  }

  const cambio2 = (dato) => {
    if (dato) {
      const bod = {
        id_medium: dato.id_medium,
      }

      props._saveIdmediumm(dato.id_medium)
      props._saveMediumm(dato.site)
      props._getMediumBanner(bod)
    }
  }

  const backName = (e) => {
    e.preventDefault()
    props._saveSteps(6)
  }

  const saveMetodo = (e) => {
    e.preventDefault()
    const inputMedium = document.getElementById('btnMedioCamp')
    const inputCountry = document.getElementById('btnCountry')
    
    if (inputMedium.value !== '' && inputCountry.value !== '') {
      props._saveSocials(inputMedium.value)
      props._saveCountries(inputCountry.value)
      props._saveSteps(3)
    } else {
      if(!inputMedium.value){
        inputMedium.style.borderBottom = '1px solid red'
        inputMedium.style.color = 'red'
      }
      if(!inputCountry.value){
        inputCountry.style.borderBottom = '1px solid red'
        inputCountry.style.color = 'red'
      }
    }
  }

  if (props.campaign.redSocial === null && props.campaignupd.length > 0) {
    props.campaign.redSocial = props.campaignupd[0].medio
  }

  let mediumList = props.mediumbanners
  let mediumListtype = props.mediumbannerstype

  let va = {}
  let vava = {}

  if (props.campaignupd.length > 0) {
    var medio = ''
    if (props.campaignupd[0].medioo !== null && props.campaignupd[0].medioo !== undefined && props.campaignupd[0].medioo !== '') {
      medio = props.campaignupd[0].medioo
    } else {
      medio = props.campaignupd[0].medio
    }

    va = {
      id_medium: props.campaignupd[0].id_medium,
      site: medio,
    }

    vava = {
      id_ad_product: props.campaignupd[0].id_ad_product,
      banner: props.campaignupd[0].banner,
    }
  }

  return (
    <div className='bodytwoStep'>
    <div className={props.clase}>
      <h1 className='title'>Creemos tu usuario y corramos tu pauta </h1>
      <br></br>
      
      <div className='oneColBody'>
          <FormGroup>
            <Label
              style={{
                color: '#05252d',
                fontWeight: 500,
                marginBottom: '5px',
              }}
            >
              Nombre completo
            </Label>
            <Input
              type='text'
              name='id_sale'
              className='input'
              value={nameValue}
              onChange={(e) => setNameValue(e.target.value)}
              placeholder='Ingresa tu nombre completo'
            />
            <FormFeedback>Ingresa un n&uacute;mero de ov</FormFeedback>
          </FormGroup>
      </div>
      <div className='oneColBody'>
          <FormGroup>
            <Label
              style={{
                color: '#05252d',
                fontWeight: 500,
                marginBottom: '5px',
              }}
            >
              Correo electrónico
            </Label>
            <Input
              type='text'
              name='id_sale'
              className='input'
              value={emailValue}
              onChange={(e) => setEmailValue(e.target.value)}
              placeholder='Ingresa tu correo electrónico'
            />
            <FormFeedback>Ingresa un n&uacute;mero de ov</FormFeedback>
          </FormGroup>
      </div>
      <div className='oneColBody'>
          <FormGroup>
            <Label
              style={{
                color: '#05252d',
                fontWeight: 500,
                marginBottom: '5px',
              }}
            >
              Numero teléfonico
            </Label>
            <Input
              type='text'
              name='id_sale'
              className='input'
              value={phoneValue}
              onChange={(e) => setPhoneValue(e.target.value)}
              placeholder='Ingresa tu numero telefonico'
            />
            <FormFeedback>Ingresa un n&uacute;mero de ov</FormFeedback>
          </FormGroup>
      </div>
      <div className='oneColBody'>
          <FormGroup>
            <Label
              style={{
                color: '#05252d',
                fontWeight: 500,
                marginBottom: '5px',
              }}
            >
              Elige un pa&iacute;s 
            </Label>
            <Autocomplete
              id='btnCountry'      
              
              //disableClearable     
              style={{ width: 300, height: 35 }}
              // value={state.country}
              options={props.countries}
              getOptionLabel={(option) => option.name}
              onChange={(event, newValue) => {
                setContryValue(newValue.name);
              }}
              
              
              renderInput={(params) => (
                <TextField {...params} label='País' variant='outlined' />
              )}
            />
            <FormFeedback>Elige un pais</FormFeedback>
          </FormGroup>
      </div>
      <div className='oneColBody'>
          <FormGroup>
            <Label
              style={{
                color: '#05252d',
                fontWeight: 500,
                marginBottom: '5px',
              }}
            >
              Ingresa tu nombre contraseña
            </Label>
            <Input              
              name='id_sale'
              className='input'
              type='password'
              value={passwordValue}
              onChange={(e) => setPasswordValue(e.target.value)}
              placeholder='Ingresa tu contrase&ntilde;a'
            />
            <FormFeedback>Ingresa un n&uacute;mero de ov</FormFeedback>
          </FormGroup>
      </div>
      

      <br></br>

      <div className='space-btns'>
        <a className='btn-camp gray' onClick={backName} href='#'>
          Volver
        </a>
        <a className='btn-camp' onClick={signUp} href='#' id='nextMedio'>
          Siguiente
        </a>
      </div>
    </div>
    </div>
  )
}

const mapStateToProps = (state) => ({
  campaign: state.campaign,
  campaignupd: state.campaignupd,
  mediumbanners: state.mediumbanners,
  mediumbannerstype: state.mediumbannerstype,
  countries:state.countryReducer,
  mediumbanners:state.mediumbanners
})

const mapDispatchToProps = (dispatch) => ({

  _saveSteps: (step) => dispatch(saveSteps(step)),
  _setCurrentUser: (user) => dispatch(setCurrentUser(user)),
  _showLoader: show => dispatch(showLoader(show)),
  _showAlertModal: (title, message, type, callback) => dispatch(Modal.showAlertModal(title, message, type, callback)),
  _saveAWSUser: (user) => dispatch(saveAWSUser(user)),
  _saveEmail: (email) => dispatch(saveEmail(email)),
  _saveEmailName: (email) => dispatch(saveEmailName(email)),
  
  _getCountries: () => dispatch(getCountries()),
  
})

export default connect(mapStateToProps, mapDispatchToProps)(MedioCamp)