import * as Logger from "utils/logger";
import * as Modal from "./modal.actions";
import { showLoader } from "./loader.actions";
import * as APILA from "helpers/apiLA";

export const DIGITAL_ASSETS_ADD_ONE = "DIGITAL_INFO:ADD:ONE";
export const DIGITAL_ASSETS_REMOVE = "DIGITAL_INFO:REMOVE:ONE";
export const DIGITAL_ASSETS_ADD_TOTAL_ASSETS = "DIGITAL_INFO:ADD:TOTAL:ASSETS";
export const TYPES_DIGITAL_ASSETS = "TYPE:DIGITAL:ADD:ASSETS";
export const DIGITAL_ASSETS_SET_EDIT = "DIGITAL_INFO:SET:EDIT";
export const CURRENT_DIGITAL_ASSETS = "CURRENT_DIGITAL_TYPE";
export const DIGITAL_ASSETS_SET_CURRENT_INFO =
  "ACCOUNT_INFO:SET:CURRENT:OPTION";

const TAG = "DigitalInfo:Actions";

export const getAssetsByClient =
  ({ account }) =>
  async (dispatch) => {
    try {
      const responseAssets = await APILA.getAssetsByClient({ account });
      const { assets } = responseAssets.data.assets;
      dispatch(setTotalAssets(assets));
      return assets || [];
    } catch (error) {
      console.log("Error en la ", { error });
    }
  };

const formatDataDigitalTypeAssets = (typesOfDigitalAssets) => {
  let formatData = typesOfDigitalAssets.map((type) => ({
    id: type.id,
    label: type?.name?.charAt(0).toUpperCase() + type?.name?.slice(1),
    labelParams: type.label,
    id_ad_status: type.id_ad_status,
    validation: type.validation,
  }));
  return formatData;
};

export const getTypesDigitalAssets = () => async (dispatch) => {
  try {
    const response = await APILA.getTypesDigitalAssets();
    const { typesOfDigitalAssets } = response.data;
    let formatData = formatDataDigitalTypeAssets(typesOfDigitalAssets);
    dispatch(addTyeDigitalAssets(formatData));
    let currentType = formatData.find((type) => type.id === 1);
    dispatch(currentTyeDigitalAssets(currentType));
  } catch (error) {
    console.log("error getTypesDigitalAssets", { error });
    Logger.log(TAG, "ERROR GET TYPES DIGITAL ASSETS", error);
  }
};

export const createNewDigitalAsset =
  ({ name, url, reference, medio, account }) =>
  async (dispatch) => {
    dispatch(showLoader(true));
    try {
      const response = await APILA.getCurrentIdEadvertising({ account });
      const body = {
        name,
        reference,
        url,
        id_client: response.data.eAdvertising,
        id_ad_status: 1,
        id_type: medio.id,
      };
      await APILA.insertDigitalAsset(body);
      const titleModal = "Informacion de la cuenta creada";
      const messageModal = "El activo digital se creo correctamente";
      dispatch(
        Modal.showAlertModal(
          titleModal,
          messageModal,
          Modal.MODAL_TYPE_SUCCESS,
          () => {}
        )
      );
    } catch (error) {
      Logger.log(TAG, "ERROR CREATE NEW ACCOUNT INFO", error);
      dispatch(
        Modal.showAlertModal(
          "Error!",
          "Ocurrio un error al crear la informacion de la cuenta",
          Modal.MODAL_TYPE_ERROR,
          () => {}
        )
      );
    } finally {
      dispatch(showLoader(false));
    }
  };

export const updateDigitalAsset =
  ({ id, name, reference, url, medio }) =>
  async (dispatch) => {
    dispatch(showLoader(true));
    try {
      const body = {
        id,
        name,
        reference,
        url,
        id_type: medio.id,
      };
      await APILA.updateDigitalAsset(body);
      const tittleModal = "Informacion de la cuenta actualizada";
      const messageModal = "El activo digital fue actualizado exitosamente";
      dispatch(
        Modal.showAlertModal(
          tittleModal,
          messageModal,
          Modal.MODAL_TYPE_SUCCESS,
          () => {}
        )
      );
    } catch (error) {
      Logger.log(TAG, "ERROR UPDATE ACCOUNT INFO", error);
      dispatch(
        Modal.showAlertModal(
          "Error!",
          "Ocurrio un error al actualizar la informacion de la cuenta",
          Modal.MODAL_TYPE_ERROR,
          () => {}
        )
      );
    } finally {
      dispatch(showLoader(false));
    }
  };

export const reactivateDigitalAsset =
  ({ id, status }) =>
  async (dispatch) => {
    dispatch(showLoader(true));
    try {
      await APILA.deleteDigitalAsset({ id, status });
      const tittleModal = "Informacion de la cuenta eliminada";
      const messageModal = "El activo digital fue eliminado exitosamente";
      dispatch(
        Modal.showAlertModal(
          tittleModal,
          messageModal,
          Modal.MODAL_TYPE_SUCCESS,
          () => {}
        )
      );
    } catch (error) {
      Logger.log(TAG, "ERROR DELETE ACCOUNT INFO", error);
      dispatch(
        Modal.showAlertModal(
          "Error!",
          "Ocurrio un error al eliminar la informacion de la cuenta",
          Modal.MODAL_TYPE_ERROR,
          () => {}
        )
      );
    } finally {
      dispatch(showLoader(false));
    }
  };

export const deleteDigitalAsset =
  ({ id, status }) =>
  async (dispatch) => {
    dispatch(showLoader(true));
    try {
      await APILA.deleteDigitalAsset({ id, status });
      const tittleModal = "Informacion de la cuenta eliminada";
      const messageModal = "El activo digital fue eliminado exitosamente";
      dispatch(
        Modal.showAlertModal(
          tittleModal,
          messageModal,
          Modal.MODAL_TYPE_SUCCESS,
          () => {}
        )
      );
    } catch (error) {
      Logger.log(TAG, "ERROR DELETE ACCOUNT INFO", error);
      dispatch(
        Modal.showAlertModal(
          "Error!",
          "Ocurrio un error al eliminar la informacion de la cuenta",
          Modal.MODAL_TYPE_ERROR,
          () => {}
        )
      );
    } finally {
      dispatch(showLoader(false));
    }
  };

export const initialTypeDigitalAsset = {
  id: 1,
  label: "Facebook",
  labelParams: "Url de la fan page",
  id_ad_status: 1,
  validation: '/^(ftp|http|https)://[^ "]+$/',
};

export const setCurrentOption = (currentOption) => ({
  type: DIGITAL_ASSETS_SET_CURRENT_INFO,
  currentOption,
});

export const setTotalAssets = (assets) => ({
  type: DIGITAL_ASSETS_ADD_TOTAL_ASSETS,
  assets,
});

export const removeDigitalAsset = (id) => ({
  type: DIGITAL_ASSETS_REMOVE,
  id,
});

const addTyeDigitalAssets = (typesOfDigitalAssets) => ({
  type: TYPES_DIGITAL_ASSETS,
  typesOfDigitalAssets,
});

export const currentTyeDigitalAssets = (currentTypeDigitalAsset) => ({
  type: CURRENT_DIGITAL_ASSETS,
  currentTypeDigitalAsset,
});
