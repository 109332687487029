import React, { useEffect, useState } from "react";
import moment from "moment";
import classnames from "classnames";
import { connect } from "react-redux";
import { DateRangePicker, PresetDateRangePicker } from "react-dates"; //react-dates
import {
  RangeDatePicker,
  registerLocale,
} from "react-google-flight-datepicker"; //google
import "react-google-flight-datepicker/dist/main.css"; //google

//import 'react-dates/initialize' //react-dates
//import 'react-dates/lib/css/_datepicker.css' //react-dates

import { saveDates } from "redux/actions/fechasCamp.actions";
import { saveSteps } from "redux/actions/nameCamp.actions";

import "moment/locale/fr";
import "moment/min/locales";
moment().month("Enero");
moment.locale("fr");

const DataPicker = (props) => {
  const [state, setState] = useState({
    startDate: null,
    endDate: null,
  });
  const [minDate, setMinDate] = useState(new Date());

  useEffect(() => {
    if (props.fechaIni !== "" && props.fechafin !== "") {
      setState((prev) => ({
        ...prev,
        startDate: moment(returnConvert(props.fechaIni)),
        endDate: moment(returnConvert(props.fechafin)),
      }));
    } else {
      setState((prev) => ({
        ...prev,
        startDate: null,
        endDate: null,
      }));
    }
    if (props.isActive) {
      setMinDate(moment(returnConvert(props.fechaIni)));
    }
  }, [props.fechaIni, props.fechafin, props.isActive]);

  const convert = (str) => {
    var date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [day, mnth, date.getFullYear()].join(".");
  };

  const returnConvert = (str) => {
    const date = str.split(".");
    return `${date[2]}-${date[1]}-${date[0]}`;
  };

  const cambio = () => {
    let content = document.getElementById("dataPick");
    content.style.borderBottom = "1px solid green";
  };

  const saveFecha = (ini, end) => {
    let fechaini = document.getElementById("iniDate");
    let fechafin = document.getElementById("finDate");
    let dataPick = document.getElementById("dataPick");
    if (ini && end) {
      fechaini = convert(ini);
      fechafin = convert(end);
      props._saveDates(convert(ini), convert(end));
      props.onChangeDate(convert(ini), convert(end));
      props._saveSteps(5);
    }
  };

  return (
    <div className={classnames("dataPick", props.className)} id="dataPick">
      <div className="contDate" id="contDate" onClick={cambio}>
        {/* <DateRangePicker
          startDate={state.startDate} // momentPropTypes.momentObj or null,
          startDateId="iniDate" // PropTypes.string.isRequired,
          endDate={state.endDate} // momentPropTypes.momentObj or null,
          endDateId="finDate" // PropTypes.string.isRequired,
          onDatesChange={({ startDate, endDate }) => {
            console.log('onDatesChange')
            setState({ startDate, endDate })
            saveFecha(startDate, endDate)
          }} // PropTypes.func.isRequired,
          focusedInput={state.focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
          onFocusChange={focusedInput => {
            console.log('onFocusChange')
            setState(prev => ({...prev, focusedInput }))
          }} // PropTypes.func.isRequired,
          displayFormat={() => "DD/MM/YYYY"}
          showClearDates= {true}
          reopenPickerOnClearDates={true}
          //<button></button>
        /> */}
        <RangeDatePicker
          startDate={state.startDate}
          endDate={state.endDate}
          onChange={(startDate, endDate) => saveFecha(startDate, endDate)}
          /*onFocusChange={focusedInput => {
            console.log('onFocusChange')
            setState(prev => ({...prev, focusedInput }))
          }} // PropTypes.func.isRequired,  
          */
          minDate={minDate}
          maxDate={new Date(2100, 0, 1)}
          dateFormat="DD/MM/YYYY"
          //monthFormat="MMM YYYY"
          startDatePlaceholder="Inicio"
          endDatePlaceholder="Fin"
          disabled={props.disabled}
          className="custom-range-datepicker" // Clase CSS personalizada
          startWeekDay="lunes"
          highlightToday
          locale="es-MX"
        />
        <i className="far fa-calendar-alt calen"></i>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  campaign: state.campaign,
  campaignupd: state.campaignupd,
});

const mapDispatchToProps = (dispatch) => ({
  _saveSteps: (step) => dispatch(saveSteps(step)),
  _saveDates: (iniDate, endDate) => dispatch(saveDates(iniDate, endDate)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DataPicker);
