import { useState, useEffect } from "react";
import DataTableUtils, {
  Share,
  ShowColumn2,
  FilterColumn2,
} from "@easygosanet/react-datatable-utils";
import { connect } from "react-redux";
import { v4 } from "uuid";
import analytics from "../../helpers/analytics";
import "../dashboardPwa/assets/css/select.module.css";
import { newEmailValidation } from "redux/actions/email.actions";
import ReactDataTable, {
  OptionsSelectedEdit,
  EditAnt,
  SimpleText,
} from "@easygosanet/react-datatable";
import * as Modal from "redux/actions/modal.actions";
import ModalAddData from "./modalAddData";
import Swal from "sweetalert2";
import "./style.scss";
import { withUtils } from "utils";
import * as APILA from "helpers/apiLA";
import PageLoader from "components/page_loader";
import _, { find } from "lodash";
import ModalEditData from "./modalEditData";
import { Tooltip } from "antd";
import DetailColEdit from "components/DetailCol";
import { useLocation, useHistory } from "react-router-dom";
import { Auth } from "aws-amplify";

const ConfigEmails = (props) => {

  /* Hooks para la navegacion y para el push del pathname */
  const location = useLocation()
  const history = useHistory()

  /* Instancias para obtener los valores */
  const currentUrl = new URLSearchParams(location.pathname)
  const currentSeach = new URLSearchParams(location.search)

  /* Obtencion de los parametros */
  const accountParams = location.pathname?.split('/configuracion/emails/account=')[1]?.split('&')[0]
  const pageParams = Number(currentUrl.get("page"))
  const searchParams = currentSeach.get('search_query')
  const [currentPage, setCurrentPage] = useState(pageParams || 1);

  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSuperAdmin, setIsSuperAdmin] = useState(false);
  const [account, setAccount] = useState(props.currentAccount);
  const [usersAws, setUsersAws] = useState([]);
  const [dataEdit, setdataEdit] = useState([]);
  const [politicas, setPoliticas] = useState([]);
  const [politicasBack, setPoliticasBack] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [modalEditData, setmodalEditData] = useState(false);
  const [permitsModule, setPermitsModule] = useState([]);
  const [permissions, setPermissions] = useState({
    approve: false,
    create: false,
    delete: false,
    edit: false,
    export: false,
    manageRoles: false,
    view: true,
  });

  const changeUrl = ({ pagination = pageParams }) => {
    const validateAccount = accountParams == undefined || accountParams == null || accountParams.length == 0 ? props.currentAccount : accountParams
    history.push({
      pathname: `/configuracion/emails/account=${validateAccount}&page=${pagination}`
    })
    if (location.search.length > 0) {
      history.push({
        search: location.search
      })
    }
  }

  const [list, setList] = useState([
    {
      id: "affair",
      name: "Asunto",
      omit: false,
      selector: "affair",
      sortable: true,
      center: true,
      tag: "affair",
      cell: (row) => (
        <DetailColEdit
          text={row.affair}
          accounts={[]}
          id={"name"}
          deleteData={() => eliminarPolitica(row)}
          deleteV={true}
          nameColumn={row}
          edit={true}
          onEditValue={() => {}}
          editInfo={() => cambioDataEdit(row)}
          editable={false}
          type={"text"}
          activate={true}
          permissions={row.permisos}
        />
      ),
    },
    {
      id: "default",
      omit: false,
      name: "Tipo de alerta",
      selector: "default",
      sortable: true,
      center: true,
      tag: "default",
      cell: (row) => (row.default === "Si" ? "Por defecto" : "personalizada"),
    },
    {
      id: "action",
      omit: false,
      name: "Acción",
      selector: "action",
      sortable: true,
      center: true,
      tag: "action",
      cell: (row) => (
        <OptionsSelectedEdit
          id={row.id}
          editable={false}
          data={["Creación", "Lectura", "Edición", "Eliminación"]}
          value={row.action}
          nameColumn={row}
          onEditValue={(e) => {
            e[0].nameColumn = "bodyConfig";
            e[0].col = "action";
            editData(e);
          }}
        />
      ),
    },
    {
      id: "channel",
      name: "Canal",
      omit: false,
      selector: "channel",
      sortable: true,
      center: true,
      tag: "channel",
    },

    {
      id: "module",
      omit: false,
      name: "Módulo",
      selector: "module",
      sortable: true,
      center: true,
      tag: "module",
    },
    {
      id: "restriction",
      name: "Usuarios de la plataforma",
      omit: false,
      selector: "restriction",
      sortable: true,
      center: true,
      tag: "restriction",
      cell: (row) => (
        <OptionsSelectedEdit
          id={row.id}
          editable={false}
          data={["Si", "No"]}
          value={row.restriction}
          nameColumn={row}
          onEditValue={(e) => {
            e[0].nameColumn = "bodyConfig";
            e[0].col = "restriction";
            editData(e);
          }}
        />
      ),
    },
    {
      id: "userByRol",
      omit: false,
      name: "Usuarios por rol",
      selector: "userByRol",
      sortable: true,
      center: true,
      tag: "userByRol",
      minWidth: "150px",

      cell: (row) => {
        return (
          <EditAnt
            nameColumn={row}
            id={row.id}
            edit={false}
            defaultValue={row.userByRol?.map((ele) => ele.label)}
            onEditValue={(e) => {
              editRoles(e);
            }}
            options={row.optionsRoles?.map((ele) => ele.label)}
            placeholder={"Selecciona una opción"}
            style={{ width: "150px" }}
            texto={
              row.userByRol.length !== 0
                ? lisData(row.userByRol, "label").length < 10
                  ? lisData(row.userByRol, "label")
                  : lisData(row.userByRol, "label").substr(0, 10) + "..."
                : "..."
            }
          />
        );
      },
    },

    {
      id: "date",
      omit: false,
      name: "Fecha de creación",
      selector: "date",
      sortable: true,
      center: true,
      tag: "date",
    },
  ]);

  useEffect(() => {
    getData();
    setAccount(props.currentAccount);
  }, [refresh, props.currentAccount]);

  const editRoles = (data) => {
    let { column, id, optionsSelect } = data[0];
    let rolAc = column.optionsRoles;
    let rolSelect = [];
    if (optionsSelect.length !== 0) {
      optionsSelect.forEach((ele) => {
        let rolEnc = rolAc.find((role) => role.label === ele);
        rolSelect.push(rolEnc);
      });
    }

    let valor = [
      {
        column: column,
        id: id,
        nameColumn: "bodyConfig",
        value: rolSelect,
        col: "userByRol",
      },
    ];
    editData(valor);
  };

  const editData = async (data) => {
    let title = "Está seguro que desea editar la política?";

    props._showConfirmationModal("Editar política", title, async () => {
      let { column, nameColumn, value, col } = data[0];

      try {
        if (nameColumn === "bodyConfig") {
          let configEmailState = [];
          let emailConfig = configEmailState.find(
            (ele) => ele.id === column.idConfigLetsEmail
          );

          let bodyConfig = {
            id: emailConfig.id,
            module: col === "module" ? value : emailConfig.module,
            channel: col === "channel" ? value : emailConfig.channel,
            action: col === "action" ? value : emailConfig.action,
            restriction:
              col === "restriction" ? value : emailConfig.restriction,
            userByRol: col === "userByRol" ? value : emailConfig.userByRol,
          };
          await APILA.insertConfigLetsEmail(bodyConfig);
          setRefresh(true);
        } else if (nameColumn === "configEmail") {
          let config = [];
          let emailConfig = config.find(
            (ele) => ele.id === column.idConfigEmail
          );

          let bodyConfigEmail = {
            id: emailConfig.id,
            sender: col === "sender" ? value : emailConfig.sender,
            recipients: col === "recipients" ? value : emailConfig.recipients,
            affair: col === "affair" ? value : emailConfig.affair,
            messajeBody:
              col === "messajeBody" ? value : emailConfig.messajeBody,
          };
          await APILA.insertConfigEmail(bodyConfigEmail);
          setRefresh(true);
        }
        Swal.fire({
          title: "Política actualizada!",
          text: "Política actualizada correctamente.",
          icon: "success",
          showCancelButton: false,
          confirmButtonColor: "#3085d6",
          confirmButtonText: "Ok",
        });
      } catch (error) {
        Swal.fire({
          title: "Ups!",
          text: "Hubo un error al actualizar la politica",
          icon: "error",
          showCancelButton: false,
          confirmButtonColor: "#3085d6",
          confirmButtonText: "Ok",
        });
      }
    });
  };

  const cambioDataEdit = (id) => {
    setdataEdit(id);
    setmodalEditData(true);
  };

  const lisData = (obj, name) => {
    let nombres = "";
    if (obj) {
      if (obj.length > 0) {
        obj.forEach((ele) => {
          if (nombres.length !== 0) {
            let mensaje = ", " + ele[name];
            nombres = nombres + mensaje;
          } else {
            nombres = ele[name];
          }
        });
      }
    }

    return nombres;
  };

  /*FIlter input searchBar */
  const handleFilterTextChange = (texto) => {
        if(texto !== "") {
      history.push({
        search: `search_query=${texto}`
      })
    }else {
      history.push({
        search: ""
      })
    }
    analytics.busqueda({
      ubicacion: "Alertas",
      busqueda: texto,
    });
    let filteredData = politicasBack.filter((item) => {
      if (item.action.toLowerCase().includes(texto.toLowerCase())) {
        return item.action.toLowerCase().includes(texto.toLowerCase());
      } else if (item.affair.toLowerCase().includes(texto.toLowerCase())) {
        return item.affair.toLowerCase().includes(texto.toLowerCase());
      } else if (item.channel.toLowerCase().includes(texto.toLowerCase())) {
        return item.channel.toLowerCase().includes(texto.toLowerCase());
      } else if (item.module.toLowerCase().includes(texto.toLowerCase())) {
        return item.module.toLowerCase().includes(texto.toLowerCase());
      }
      return false;
    });
    setPoliticas(filteredData);
  };

  const eliminarPolitica = (row) => {
    let title = "Esta seguro que desea eliminar la política?";
    props._showConfirmationModal("Eliminar política?", title, async () => {
      setIsLoading(true);
      try {
        await APILA.deleteValidacion({
          id: row.idValidations,
        });
        await APILA.deleteConfigEmail({
          id: row.idConfigEmail,
        });
        await APILA.deleteConfigLetsEmail({
          id: row.idConfigLetsEmail,
        });
        await APILA.deleteObjEmails({ id: row.id });
        let bodyBitacora = {
          id: v4(),
          date: new Date(),
          account: account,
          action: "se ha eliminado una politica",
          component: "configEmails",
          data: row,
        };
        await APILA.insertBitacora(bodyBitacora);
        analytics.accionesTabla({
          accion: "Eliminar politica",
          ubicacion: "Alertas",
        });
        Swal.fire({
          title: "Política eliminada!",
          text: "Política eliminada correctamente.",
          icon: "success",
          showCancelButton: false,
          confirmButtonColor: "#3085d6",
          confirmButtonText: "Ok",
        });
        setRefresh(true);
      } catch (error) {
        Swal.fire({
          title: "Ups!",
          text: "Hubo un error al eliminar la politica",
          icon: "error",
          showCancelButton: false,
          confirmButtonColor: "#3085d6",
          confirmButtonText: "Ok",
        });
      }

      setIsLoading(false);
    });
  };

  const exportDataToExcel = () => {
    let headers = [];
    let dataExcel = [];
    let selectors = [];
    let excelData = [];

    let filteredToColumn = list.filter(function (col) {
      return col.omit === false;
    });
    filteredToColumn.forEach((element) => {
      headers.push(element.name);
      selectors.push(element.tag);
    });
    dataExcel.push(headers);
    if (politicas.length > 0) {
      politicas?.forEach((element) => {
        excelData = [];
        for (let k = 0; k < selectors.length; k++) {
          excelData.push(
            selectors[k] === "id"
              ? element[selectors[k]]
              : selectors[k] === "action"
              ? element[selectors[k]]
              : selectors[k] === "affair"
              ? element[selectors[k]]
              : selectors[k] === "channel"
              ? element[selectors[k]]
              : selectors[k] === "module"
              ? element[selectors[k]]
              : selectors[k] === "restriction"
              ? element[selectors[k]]
                ? "si"
                : "no"
              : selectors[k] === "sender"
              ? element[selectors[k]]
              : selectors[k] === "userByRol"
              ? lisData(element.userByRol, "label")
              : selectors[k] === "recipients"
              ? lisData(element[selectors[k]], "label")
              : ""
          );
        }
        dataExcel.push(excelData);
      });
    }
    return dataExcel;
  };

  const handleShowColumnChange = (column, show) => {
    props.utils.log("handleFilterTextChange");
    props.utils.log("column", column);
    props.utils.log("show", show);

    let index = list.findIndex((col) => col.id === column);
    let aux = list.map((col) => col);
    aux[index].omit = show;
    setList(aux);
  };

  const handleColumnSortChange = (positions) => {
    props.utils.log("handleColumnSortChange");
    props.utils.log("positions", positions);

    let aux = list.map((col) => col);

    positions.forEach((columnId, position) => {
      let index = list.findIndex((col) => col.selector === columnId);
      props.utils.log("Index", index);
      aux[index].position = position;
    });

    aux = _.orderBy(aux, ["position"]);

    props.utils.log("new columns", aux);

    setList(aux);
  };

  const customStyles = {
    headCells: {
      style: {
        color: "#000",
        fontSize: "0.9rem",
        textAlign: "center",
        justifyContent: "center",
        backgroundColor: "#fff",
        width: "160px",
        "&:nth-of-type(2n)": {
          backgroundColor: "#eff0f2",
          borderRadius: "10px 10px 0 0",
        },
      },
    },
    cells: {
      style: {
        justifyContent: "center",
        backgroundColor: "#fff",
        "&:nth-of-type(1)": {
          justifyContent: "left",
        },
        "&:nth-of-type(2n)": {
          backgroundColor: "#eff0f2",
        },
      },
    },
  };

  // Función para obtener permisos por nombre de rol
  async function getPermissionsByRole(roleId) {
    const permisosRes = await APILA.getRoleNameById({ id: roleId });
    return permisosRes?.data?.rol[0]?.permissions || [];
  }

  // Función para filtrar permisos por nombre
  function filterPermissionsByName(permissions, name) {
    return permissions.find((ele) => ele.name === name);
  }

  // Función para obtener permisos por cuenta
  async function getPermissionsByAccount(user, account) {
    const permisoAcc = await APILA.getPermitsByUser({ user });
    const permisSupAdmin = permisoAcc.data.permits?.find(
      (ele) => ele.idRol === "Super Administrador"
    );
    const permisAccouunt = permisoAcc.data.permits?.find(
      (ele) => ele.account === account
    );
    const permissAud = await getPermissionsByRole(permisAccouunt?.idRol || "");
    setPermitsModule(permissAud);
    if (permisSupAdmin) {
      setIsSuperAdmin(true);
      const permissSupAdmin = await getPermissionsByRole(permisSupAdmin.idRol);
      return filterPermissionsByName(permissSupAdmin, "Configuración alertas");
    } else {
      const permisAccouunt = permisoAcc.data.permits?.find(
        (ele) => ele.account === account
      );
      const permissAud = await getPermissionsByRole(
        permisAccouunt?.idRol || ""
      );
      setPermitsModule(permissAud);
      return filterPermissionsByName(permissAud, "Configuración alertas");
    }
  }

  const clientsFormat = async () => {
    const getClients = _.get(
      await APILA.getAllAccountsAWS(),
      "data.clients",
      "[]"
    );

    const clients = getClients.map((client_response) => {
      const nameAttr = find(client_response.Attributes, { Name: "name" });
      const emailAttr = find(client_response.Attributes, { Name: "email" });

      const nameValue = nameAttr ? nameAttr.Value : "";
      const emailValue = emailAttr ? emailAttr.Value : "";

      const client = {
        id: client_response.Username,
        name: nameValue,
        email: emailValue,
        format: nameValue ? `${nameValue} (${emailValue})` : emailValue,
      };

      return client;
    });
    setUsersAws(clients);
    return clients;
  };

  async function fetchDataAndSetState(apiFunction, nameAtribute) {
    const response = await apiFunction;
    const data = _.get(response, `data.${nameAtribute}`, null);

    return data;
  }

  function filterDataById(dataArray, id) {
    return dataArray.find((ele) => ele.id === id);
  }

  function buildDataBody(
    configEmail,
    configLetsEmail,
    validations,
    objEmail,
    permissos
  ) {
    return objEmail.map((ele) => {
      const configEmailFilter = filterDataById(configEmail, ele.idConfigEmail);
      const configLetsEmailFilter = filterDataById(
        configLetsEmail,
        ele.idConfigLets
      );
      const validationsFilter = filterDataById(validations, ele.idValidations);

      return {
        affair: configEmailFilter.affair,
        messajeBody: configEmailFilter.messajeBody,
        recipients: configEmailFilter.recipients,
        sender: configEmailFilter.sender,
        idConfigEmail: configEmailFilter.id,
        action: configLetsEmailFilter.action,
        channel: configLetsEmailFilter.channel,
        idConfigLetsEmail: configLetsEmailFilter.id,
        module: configLetsEmailFilter.module,
        restriction: configLetsEmailFilter.restriction,
        userByRol: configLetsEmailFilter.userByRol,
        idValidations: validationsFilter.id,
        validations: validationsFilter.validations,
        optionsRoles: [],
        id: ele.id,
        permisos: permissos,
        default: ele.default ? "Si" : "No",
        isDefault: ele?.default ?? false,
        edit: ele?.edit ?? false,
        date: ele.date,
      };
    });
  }

  const getData = async () => {
    try {
      setIsLoading(true);
      const userLog = await Auth.currentAuthenticatedUser();
      const permissions = await getPermissionsByAccount(
        userLog.username,
        props.currentAccount
      );
      clientsFormat();
      setPermissions(permissions);

      const [conEmail, conLetsEmail, obEmail, vValidations] = await Promise.all(
        [
          fetchDataAndSetState(
            APILA.getConfigEmailByAccount({ account: props.currentAccount }),
            "data"
          ),
          fetchDataAndSetState(
            APILA.getConfigLetsEmailByAccount({
              account: props.currentAccount,
            }),
            "data"
          ),
          fetchDataAndSetState(
            APILA.getObjEmailsByAccount({ account: props.currentAccount }),
            "objEmail"
          ),
          fetchDataAndSetState(
            APILA.getValidacionesByAccount({ account: props.currentAccount }),
            "data"
          ),
        ]
      );
      let politicasFormat = [];
      if (obEmail.length > 0) {
        politicasFormat = buildDataBody(
          conEmail,
          conLetsEmail,
          vValidations,
          obEmail,
          permissions
        );
      }

      setPoliticas(politicasFormat);
      setPoliticasBack(politicasFormat);
      if (refresh) {
        setRefresh(false);
      }
    } catch (error) {
      console.log("EROREOPRJOKJ", error);
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <>
      {isLoading ? (
        <PageLoader />
      ) : permissions.view ? (
        <main className="main_emails">
          <div className="barraSuperior">
            <DataTableUtils
              data={[]}
              action={
                permissions.create
                  ? () => {
                      analytics.Add({
                        ubicacion: "Alertas",
                      });
                      setShowModal(true);
                    }
                  : null
              }
              actionIcon={
                permissions.create && (
                  <Tooltip color="#002448" placement="top" title="Crear alerta">
                    <i className="fas fa-plus" />
                  </Tooltip>
                )
              }
              onChangeFilterText={handleFilterTextChange}
            >
              <Tooltip
                color="#002448"
                placement="top"
                title="Exportar información"
              >
                <div
                  className="ExportBtn"
                  onClick={() =>
                    analytics.Compartir({
                      ubicacion: "Alertas",
                    })
                  }
                >
                  {permissions.export && (
                    <Share
                      contacts={[]}
                      data={exportDataToExcel()}
                      columns={list}
                      isArray={true}
                      exportXLSX={true}
                      exportTXT={true}
                      exportDropbox={true}
                      exportDrive={true}
                      exportCSV={true}
                      printable={true}
                      exportPNG={true}
                      exportLink={true}
                      idImg={"root"}
                      appKey="2u78gno172idwbz"
                    />
                  )}
                </div>
              </Tooltip>
              <Tooltip
                color="#002448"
                placement="top"
                title="Personalizar columnas"
              >
                <div id="shareBtn">
                  <ShowColumn2
                    columns={list}
                    showColumn={(data, data1) => {
                      analytics.ShowHide({
                        ubicacion: "Alertas",
                      });
                      handleShowColumnChange(data, data1);
                    }}
                    showView={() => {}}
                    onSortChange={(position) => {
                      handleColumnSortChange(position);
                      analytics.Columnas({ ubicacion: "Alertas" });
                    }}
                    vistas={[]}
                    setVista={() => {}}
                    deleteView={() => {}}
                    nameAc={"lista"}
                  />
                </div>
              </Tooltip>

              <Tooltip
                color="#002448"
                placement="top"
                title="Filtrar y agrupar"
              >
                <div className="Filtro2 filtro3">
                  <FilterColumn2 columns={list} />
                </div>
              </Tooltip>
            </DataTableUtils>
          </div>
          <section className="contaner-table">
            <ReactDataTable
              columns={list}
              data={politicas}
              persistTableHead
              customStyles={customStyles}
              pagination
              paginationComponentOptions={{
                rowsPerPageText: "Elementos por página",
                rangeSeparatorText: "de",
              }}
              noDataComponent={"No hay datos para mostrar"}
              //paginationComponentOptions={esp}
              //noHeader
            />
          </section>
          {showModal && (
            <ModalAddData
              refresh={setRefresh}
              account={account}
              isSuperAdmin={isSuperAdmin}
              permissions={permitsModule}
              usersAws={usersAws}
              showModal={showModal}
              setShowModal={setShowModal}
              currentAccount={props.currentAccount}
            />
          )}
          {modalEditData && (
            <ModalEditData
              currentAccount={props.currentAccount}
              data={dataEdit}
              isSuperAdmin={isSuperAdmin}
              permissions={permitsModule}
              refresh={setRefresh}
              showModal={setmodalEditData}
            />
          )}
        </main>
      ) : (
        <h1>No tienes permisos</h1>
      )}
    </>
  );
};
const mapStateToProps = (state) => ({
  currentUser: state.app.current_user,
  pwaAccounts: state.pwa.accounts.list,
  currentAccount: state.accounts.default,
  users: state.users,
  accounts: state.accounts.list,
  currentPWAAccount: state.pwa.accounts.default,
});
const mapDispatchToProps = (dispatch) => ({
  _showConfirmationModal: (title, message, callback) =>
    dispatch(
      Modal.showAlertModal(
        title,
        message,
        Modal.MODAL_TYPE_CONFIRMATION,
        callback
      )
    ),
  _newEmailValidation: (idAccount, modulo) =>
    dispatch(newEmailValidation(idAccount, modulo)),
});

// export default ConfigEmails;
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withUtils(ConfigEmails));
