import React from 'react'
import { Link } from "react-router-dom";

// components
import Datos from 'components/fanpageDatos'
import MetaTags from 'components/metatags'

const DatosPage = () => (
    <>
        <MetaTags title='Datos' description='Datos de páginas' />
        <Datos />
    </>
)

export default DatosPage
