import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import './assets/css/style.css'

import * as APILA from 'helpers/apiLA'
import { addIdMixDetaill } from 'redux/actions/modalDetail.actions'

const ModalDetaill = (props) => {
  const [pauta, setPauta] = useState({})

  useEffect(() => {
    
    const findPauta = async () => {
      try {
        const response = await APILA.findPauta({ idMixDetail: props.id })
        const { pauta } = response.data
        console.log("pauta ", pauta)
        if (pauta && pauta[0] != null) {
          let fechaIni=new Date(pauta[0].since);  
          pauta[0].since=`${fechaIni.getDate()}-${fechaIni.getMonth()+1}-${fechaIni.getFullYear()}`    
          let fechaFin=new Date(pauta[0].last);  
          pauta[0].last=`${fechaFin.getDate()}-${fechaFin.getMonth()+1}-${fechaFin.getFullYear()}`    
          console.log("pauta[0].spent_budget",pauta[0].spent_budget)
          if(pauta[0].spent_budget){
            console.log("pauta[0].spent_budget",pauta[0].spent_budget)
            pauta[0].spent_budget=pauta[0].spent_budget.toFixed(2)
            console.log("pauta[0].spent_budget",pauta[0].spent_budget)
          }
          console.log("pauta[0].budget",pauta[0].budget)
          // if(pauta[0].budget){
          //   pauta[0].budget=pauta[0].budget.toFixed(2)
          //   console.log("pauta[0].budget",pauta[0].budget)
          // }
          if(pauta[0].daily_budget){
            pauta[0].daily_budget=pauta[0].daily_budget.toFixed(2)
          }
          
          


          console.log("typeof", typeof(pauta[0].since))
          setPauta(pauta[0])
        }
      } catch (err) {
        console.log(err)
      }
    }

    if (props.id !== 0) findPauta()
  }, [props.id])

  const datos = (titulo, texto) => {
    if (texto && texto !== '' && texto !== '' && texto !== '00:00:00') {
      return (
        <div className='oneColBody'>
          <p>{titulo}</p>
          <input className='input' type='text' readOnly value={texto}></input>
        </div>
      )
    }
  }

  const closeModal = (e) => {
    if (e) e.preventDefault()
    window.$.fancybox.close()
  }

  return (
    <div
      id='detail-pauta-modal'
      style={{ display: 'none' }}
      className='upsert-modal w-75 h-75'
    >
      {props.id !== 0 ? (
        <div className='formOneStep'>
          <div className='titleOneStep'>
            <p>Detalles de Pauta</p>
          </div>
          <div className='bodyOneStep'>
            {datos('Nombre', pauta.name==null || pauta.name==0  ? "--": pauta.name)}
            {datos('Estado', pauta.status==null || pauta.status==0  ? "--": pauta.status)}
            {datos('Fecha de Inicio', pauta.since==null || pauta.since==0  ? "": pauta.since.replace("T00:00:00.000Z",""))}
            {datos('Fecha Fin', pauta.last==null || pauta.last==0  ? "": pauta.last.replace("T00:00:00.000Z",""))}            
            {datos('Version', pauta.version==null || pauta.version==0  ? "--": pauta.version)}
            {datos('Sitio', pauta.site==null || pauta.site==0  ? "--": pauta.site)}
            {datos('Banner', pauta.banner_type==null || pauta.banner_type==0  ? "--": pauta.banner_type)}
            {datos('Art', pauta.art==null || pauta.art==0  ? "--": pauta.art)}
            {datos('URL', pauta.url==null || pauta.url==0  ? "--": pauta.url)}
            {datos('PostNumber', pauta.purchase_unit==null || pauta.purchase_unit==0  ? "--": pauta.purchase_unit)}
            {datos('Purchase_unit', pauta.purchase_unit==null || pauta.purchase_unit==0  ? "--": pauta.purchase_unit)}
            {datos('Budget',pauta.budget==null || pauta.budget==0  ? "$0": "$"+pauta.budget)}
            {datos('Net Budget', pauta.net_budget  ? "$0": "$"+pauta.net_budget)}
            {datos('Spent Budget', pauta.spent_budget==null || pauta.spent_budget==0  ? "$0": "$"+pauta.spent_budget)}            
            {datos('Spent percent', pauta.spent_percent==null || pauta.spent_percent==0  ? "0%": pauta.spent_percent+"%")}
            {datos('SWC', pauta.swc==null || pauta.swc==0  ? "0": pauta.swc)}
            {datos('Available', pauta.available==null || pauta.available==0  ? "$0": "$"+pauta.available)}
            {datos('Days', pauta.days==null || pauta.days==0  ? "0": pauta.days)}
            {datos('Daily budget', pauta.daily_budget==null || pauta.daily_budget==0  ? "$0": "$"+pauta.daily_budget)}
            {datos('Goal', pauta.goal==null || pauta.goal==0  ? "0": pauta.goal)}
            {datos('Real price', pauta.real_price==null || pauta.real_price==0  ? "$0": "$"+pauta.real_price)}
            {datos('Segmentation', pauta.segmentation==null || pauta.segmentation==0  ? "--": pauta.segmentation)}
            {datos('Age', pauta.age==null || pauta.age==0  ? "--": pauta.age)}
            {datos('Product Name', pauta.name_product==null || pauta.name_product==0  ? "--": pauta.name_product)}
            {datos('Campaign Bbjectives', pauta.campaign_objectives==null || pauta.campaign_objectives==0  ? "--": pauta.campaign_objectives)}
            {datos('Mix Detail Objective', pauta.mix_detail_objective==null || pauta.mix_detail_objective==0  ? "--": pauta.mix_detail_objective)}
            {datos('Modification Date', pauta?.modification_date)}
            {datos('Photo', pauta.photo==null || pauta.photo==0  ? "0": pauta.photo)}
            {datos('Leads', pauta.leads==null || pauta.leads==0  ? "0": pauta.leads)}
            {datos('Event Responses', pauta.event_responses==null || pauta.event_responses==0  ? "0": pauta.event_responses)}
            {datos('Installs',  pauta.installs==null || pauta.installs==0  ? "0": pauta.installs)}
            {datos('Frequency', pauta.frequency==null || pauta.frequency==0  ? "0": pauta.frequency)}
            {datos('CPL', pauta.cpl==null || pauta.cpl==0  ? "0": pauta.cpl)}
            {datos('CPI', pauta.cpi==null || pauta.cpi==0  ? "0": pauta.cpi)}
            {datos('CPR', pauta.cpr==null || pauta.cpr==0  ? "0": pauta.cpr)}
            {datos('CPA', pauta.cpa==null || pauta.cpa==0  ? "0": pauta.cpa)}
            {datos('CPC', pauta.cpc==null || pauta.cpc==0  ? "0": pauta.cpc)}
            {datos('CPV', pauta.cpv==null || pauta.cpv==0  ? "0": pauta.cpv)}
            {datos('CPM', pauta.cpm==null || pauta.cpm==0  ? "0": pauta.cpm)}
            {datos('CPF', pauta.cpf==null || pauta.cpf==0  ? "0": pauta.cpf)}
            {datos('Comments', pauta.comments==null || pauta.comments==0  ? "--": pauta.cpf)}
            {datos('Prints', pauta.prints==null || pauta.prints==0  ? "0": pauta.prints)}
            {datos('Clicks', pauta.clicks==null || pauta.clicks==0  ? "0": pauta.clicks)}
            {datos('CTR', pauta.ctr==null || pauta.ctr==0  ? "0": pauta.ctr)}
            {datos('Fans', pauta.fans==null || pauta.fans==0  ? "0": pauta.fans)}
            {datos('Views', pauta.views==null || pauta.views==0  ? "0": pauta.views)}
            {datos('Reach', pauta.reach==null || pauta.reach==0  ? "0": pauta.reach)}
            {datos('Post likes', pauta.post_likes==null || pauta.post_likes==0  ? "0": pauta.post_likes)}
            {datos('Post Comments', pauta.postComments==null || pauta.postComments==0  ? "0": pauta.postComments)}
            {datos('Post Shares', pauta.postShares==null || pauta.postShares==0  ? "0": pauta.postShares)}
            {datos('Cost Per No Rebound', pauta.cost_per_no_rebound==null || pauta.cost_per_no_rebound==0  ? "0": pauta.cost_per_no_rebound)}
            {datos('Average Duration Video', pauta.average_duration_video==null || pauta.average_duration_video==0  ? "00:00:00": pauta.average_duration_video)}
            {datos('Video Playbacks 25', pauta.video_playbacks_25==null || pauta.video_playbacks_25==0  ? "0": pauta.video_playbacks_25)}
            {datos('Video Playbacks 50', pauta.video_playbacks_50==null || pauta.video_playbacks_50==0  ? "0": pauta.video_playbacks_50)}
            {datos('Video Playbacks 75', pauta.video_playbacks_75==null || pauta.video_playbacks_75==0  ? "0": pauta.video_playbacks_75)}
            {datos('Video Playbacks 95', pauta.video_playbacks_95==null || pauta.video_playbacks_95==0  ? "0": pauta.video_playbacks_95)}
            {datos('Video Playbacks 100', pauta.video_playbacks_100==null || pauta.video_playbacks_100==0  ? "0": pauta.video_playbacks_100)}
            {datos('Observations', pauta.observations==null || pauta.observations==0  ? "--": pauta.observations)}
          </div>
          <div className='footOneStep'>
            <a className='btnCancelOS' onClick={closeModal}>
              Cerrar
            </a>
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  )
}

const mapStateToProps = (state) => ({
  id: state.ModalDetaill,
})

const mapDispatchToProps = (dispatch) => ({
  _addIdMixDetaill: (id) => dispatch(addIdMixDetaill(id)),
})

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ModalDetaill))
