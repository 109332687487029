import { v4 } from "uuid";
import React, { useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { createCampaignup } from "redux/actions/dashboard.actions";
import { saveSteps } from "redux/actions/nameCamp.actions";
import { updateCurrentAccount } from "redux/actions/dashboard.actions";
import { setDefault } from "redux/actions/accounts.actions";
import { pwaSetDefault } from "redux/actions/pwa.actions";
import { showLoader } from "redux/actions/loader.actions";
import { setCurrentUser } from "redux/actions/app.actions";
import { getAccounts } from "redux/actions/accounts.actions";
import Dashboard from "../dashboard";
import * as API from "helpers/api";
import * as APILA from "helpers/apiLA";

const FinCamp = (props) => {
  const [redirect, setRedirect] = useState(0);

  const movePay = (e) => {
    e.preventDefault();
    props._saveSteps(8);
  };

  const run = async (e) => {
    props._showLoader(true);
    //obtenemos información de la campaña dummy
    const campaign = props.campaign;
    //obtenemos información de dirección IP para vincular todos los dummy data
    const apiUrl = "https://api.ipify.org?format=json";
    const responseGeo = await fetch(apiUrl);
    const data = await responseGeo.json();
    const idUser = data.ip;
    // Creamos la cuenta
    const idAccount = v4();
    const bodyAccount = {
      id: idAccount,
      name: campaign.accountName,
      phone: "",
      email: "",
      adress: "",
      members: [idUser],
      status: true,
      client: "",
      credit: 0,
      priorityRecord: {
        availableCritical: 5,
        availableUrgent: 5,
        countCritical: 0,
        countUrgent: 0,
      },
      configPriority: {
        critical: {
          maxHours: 2,
          minHours: 0,
        },
        normal: {
          maxHours: 8,
          minHours: 4,
        },
        urgent: {
          maxHours: 4,
          minHours: 2,
        },
      },
    };
    await API.createAccount(bodyAccount);
    //Creación de categorías por defecto
    let catFacher = [];
    let categoriasPadre = new Set();
    let defaultReponse = await APILA.getDefaultCategories({});
    let defaultCategories = defaultReponse.data.categories;
    let categoriaPadre = defaultCategories.filter((item) => {
      return item.padre == 0;
    });

    for (let i = 0; i < categoriaPadre.length; i++) {
      let idcate = v4();
      let bodycat = {
        id: idcate,
        idAWS: categoriaPadre[i].id,
      };

      catFacher.push(bodycat);
      categoriasPadre.add({
        id: idcate,
        name: categoriaPadre[i].name,
        account: bodyAccount.id,
        padre: "97bc9ee5-36a0-455b-974c-fa7832c9c341",
      });
    }
    let categoriasPadreParsed = Array.from(categoriasPadre);
    await API.createCategoriesBatch({ categorias: categoriasPadreParsed })
    let cateSon = new Set();
    for (let y = 0; y < catFacher.length; y++) {
      let filtered = defaultCategories.filter((cat) => {
        return cat.padre == catFacher[y].idAWS;
      });
      for (let z = 0; z < filtered.length; z++) {
        let idcat = v4();
        cateSon.add({
          id: idcat,
          name: filtered[z].name,
          account: bodyAccount.id,
          padre: catFacher[y].id,
        });
      }
    }
    const catHijasParsed = Array.from(cateSon);
    await API.createCategoriesBatch({ categorias: catHijasParsed })
    //Creación del presupuesto
    let idbudget = v4();
    const today = new Date();
    const now = Date.now();
    let bodyBudget = {
      id: idbudget,
      tags: [],
      title: "Presupuesto_" + campaign.accountName,
      amount: Number(campaign.budget),
      status: 1,
      account: idAccount,
      available: Number(campaign.budget),
      createAt: now,
      updateAt: now,
      padre: "",
      nombrePadre: "",
      active: true,
    };
    // await props._createNewBudgetLogOutFinalCamp(bodyBudget)
    await API.createNewBudgetLogOut(bodyBudget);

    //Crear Público
    let idAudience = v4();
    let bodyAudience = {
      id: idAudience,
      name: campaign.tags.name,
      account: idAccount,
      tags: campaign.tags.tags,
      tasgE: campaign.tags.tagsE,
    };
    await API.createAudience(bodyAudience);
    //Crear la pauta
    let idCampaign = v4();
    const bodyCampaign = {
      id: idCampaign,
      name: campaign.name,
      medio: campaign.medium,
      objetivo: "",
      status: 1,
      formato: {
        art: props?.campaign?.id_product?.banner,
        id_format: "",
      },
      fechaIni: campaign.iniDate,
      fechafin: campaign.endDate,
      budget: 0,
      publico: campaign.tags.tags.data,
      id_medium: campaign.id_medium,
      medioo: campaign.medium,
      id_ad_product: campaign.id_product.id_ad_product,
      banner: campaign.id_product.banner,
      idBudget: idbudget,
      account: idAccount,
      observation: campaign.observations,
      copy: campaign.copy,
      id_region: campaign.country.id_region,
      name_region: campaign.country.name,
      version: "",
      priority: 3,
      categoriesSelected: [],
      creativity: [],
      id_sale: "123456",
      idAudience: idAudience,
      UserOutside: true,
    };
    await APILA.createCampaign(bodyCampaign);
    await props._updateCurrentAccount(idUser, idAccount);
    if (props.currentUser !== "") {
      await props._setDefaultAccount(idAccount);
    } else {
      await props._pwaSetDefaultAccount(idAccount);
    }

    props._showLoader(false);
    window.location.reload(true);
  };

  if (redirect == 1) {
    return <Dashboard {...props} />;
  } else {
    return (
      <div className={props.clase}>
        <h1 className="title">¡Bienvenido!</h1>
        <p className="subTitle">¿Deseas correr ahora mismo tu nueva campaña?</p>
        <div className="space-btns">
          <a
            className="btn-end gray"
            onClick={() => {
              run();
            }}
            href="#"
          >
            Quizá después
          </a>
          <a className="btn-end" href="#" onClick={movePay}>
            Si, deseo añadir un metodo de pago
          </a>
        </div>
      </div>
    );
  }
};

const mapStateToProps = (state) => ({
  campaign: state.campaign,
  currentAccount: state.accounts.default,
  currentUser: state.app.current_user,
});

const mapDispatchToProps = (dispatch) => ({
  _setCurrentUser: (user) => dispatch(setCurrentUser(user)),
  _pwaSetDefaultAccount: (account) => dispatch(pwaSetDefault(account)),
  _setDefaultAccount: (account) => dispatch(setDefault(account)),
  _updateCurrentAccount: (user, account) =>
    dispatch(updateCurrentAccount(user, account)),
  _showLoader: (value) => dispatch(showLoader(value)),
  _saveSteps: (step) => dispatch(saveSteps(step)),
  _getAccounts: (client) => dispatch(getAccounts(client)),
  _createCampaignup: (
    name,
    medio,
    status,
    fechaIni,
    fechafin,
    objetivo,
    budget,
    category,
    publico,
    id_medium,
    id_ad_product,
    banner,
    user,
    observations,
    copy,
    id_region,
    name_region,
    email,
    emailName,
    accountName,
    step,
  ) =>
    dispatch(
      createCampaignup(
        name,
        medio,
        status,
        fechaIni,
        fechafin,
        objetivo,
        budget,
        category,
        publico,
        id_medium,
        id_ad_product,
        banner,
        user,
        observations,
        copy,
        id_region,
        name_region,
        email,
        emailName,
        accountName,
        step,
      ),
    ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(FinCamp));

