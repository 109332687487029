import React, { useEffect, useState } from "react";
import ViewTable from "components/viewTable";
import { v4 } from "uuid";
import {
  setEdit,
  getTemplates,
  setEditVisualizer,
  setEditActivate,
  addOneVisualizer,
  newTemplateForAccount,
  updateTemplateDynamo,
  editData,
  removeVIsualizer,
  editDates,
  sendDeployCampaigns,
  editCountry,
  editMediumData,
  upsertVisualizer,
  editValueBanner,
  editValueFormat,
  editSegmentation,
  refreshBudgets,
  setCurrentIdPautaTemplate,
  setImplementButton,
  getAudiences,
  setUserInfo,
  sendDeployCampaignLogout,
} from "redux/actions/templates.actions";
import _ from "lodash";
import classnames from "classnames";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withUtils } from "utils";
import { showLoader } from "redux/actions/loader.actions";
import { columnsVisualizer } from "config/columns";
import { Auth } from "aws-amplify";
import { Button } from "@material-ui/core";
import { Stack } from "@mui/material";
import ModalCreate from "./newTemplate";
import ModalAttachment from "./modalAttachment";
import UpsertTemplate from "./upsertTemplate";
import * as API from "helpers/api";
import { Tooltip } from "antd";
import { EditAnt } from "@easygosanet/react-datatable";
import Swal from "sweetalert2";
import { useHistory } from "react-router-dom";
import { generateLink } from "redux/actions/templateDeepLink.actions";
import CustomCellCountry from "./customCells/customCellCountry";
import CustomCellMedio from "./customCells/customCellMedio";
import CustomCellBanner from "./customCells/customCellBanner";
import CustomCellFormat from "./customCells/customCellFormat";
import CustomCellBudgetName from "./customCells/customCellBudgetName";
import CustomCellImplementation from "./customCells/customCellImplementation";
import CustomCellSegmentation from "./customCells/editCellSegmentation";
import CustomCell from "./customCells/customCell";
import CustomCellDates from "./customCells/customCellDates";
import { TOTALES } from "./constants";
import "./style.scss";

const DEFAULT = "default";

const ViewTemplates = (props) => {
  const historyDL = useHistory();
  const {
    filterText,
    setShowModal,
    columns,
    showModal,
    init,
    setDataBackup,
    data,
    setData,
    setCategoriesData,
  } = props;
  const [____, setCategories] = useState([]);
  let pinnedSelectors = [];
  const [customStyles, setCustomStyles] = useState({
    headCells: {
      style: {
        textAlign: "center",
        "&:nth-of-type(1)": {
          position: "sticky",
          zIndex: "3 !important",
          left: "0",
          backgroundColor: "#fff",
        },
        "&:nth-of-type(8)": {
          maxWidth: "300px",
        },
      },
    },
    cells: {
      textAlign: "center !important",
      style: {
        "&:nth-of-type(1)": {
          position: "sticky",
          zIndex: "3 !important",
          left: "0",
          backgroundColor: "#fff",
        },
        "&:nth-of-type(2n)": {
          zIndex: "2",
        },
      },
    },
  });
  const [modalInfo, setModalInfo] = useState({});
  const [modalCreate, setModalCreate] = useState(false);
  const [showModalAttachment, setShowModalAttachment] = useState(false);
  const [completeEdit, setCompleteEdit] = useState(false); // Mostrar opciones de edicion
  const [columnsSelect, setColumnsSelect] = useState([]);
  const [___, setVisualizerFilter] = useState({});

  const getDataUser = async () => {
    try {
      const userResponse = await Auth.currentAuthenticatedUser();
      props._setUserInfo(userResponse.attributes);
    } catch (error) {
      if (error === "The user is not authenticated") {
        props._setUserInfo({
          email: "logout",
        });
      }
    }
  };

  const getAllAudiences = async () => {
    await props._getAudiences(props.currentAccount);
  };

  const HeadIco = ({ title, position, width, selector }) => {
    const [pinned, setPinned] = useState(false);
    return (
      <div className={pinned === true ? "ContIco pineado" : "ContIco"}>
        <a
          className="fas fa-thumbtack"
          onClick={(e) => {
            onClickpin(e, position, width, selector);
            setPinned(!pinned);
          }}
        />
        {/* <i className='fas fa-sort-down'></i> */}
        <p className="nameTilte"> {title} </p>
      </div>
    );
  };

  const editAttachmentAction = (row) => {
    setShowModalAttachment(true);
    setModalInfo(row);
  };

  const mapColumns = {
    name: {
      heigth: "100px",
      position: 1,
      pinned: 1,
      style: {
        position: "sticky !important",
        left: 0,
        zIndex: "3 !important",
        textAlign: "center",
      },
      cell: (row) => (
        <CustomCell
          row={row}
          handleDeleteTemplate={handleDeleteTemplate}
          handleEditVisualiser={handleEditVisualiser}
          sendDuplicateRow={sendDuplicateRow}
          editData={editData}
          permissions={props.permissions}
          editAttachmentAction={() => editAttachmentAction(row)}
        />
      ),
    },
    year: {
      heigth: "100px",
      minWidth: "200px",
      name: (
        <HeadIco
          title={
            <Tooltip
              title={
                <Tooltip
                  title="Período en el que tu campaña está activa."
                  color="#002448"
                  placement="top"
                >
                  {" "}
                  Rango de fecha
                </Tooltip>
              }
              color="#002448"
              placement="top"
            >
              Rango de Fecha
            </Tooltip>
          }
          position={2}
          width={"250px"}
          selector={"year"}
        />
      ),
      position: 2,
      width: "250px",
      cell: (row) => <CustomCellDates row={row} editValueDates={editDates} />,
    },
    country: {
      minWidth: "200px",
      heigth: "100px",
      position: 3,
      name: (
        <HeadIco
          title={
            <Tooltip
              title={
                <Tooltip
                  title="País de segmentación."
                  color="#002448"
                  placement="top"
                >
                  {" "}
                  País
                </Tooltip>
              }
              color="#002448"
              placement="top"
            >
              País
            </Tooltip>
          }
          position={3}
          width={"250px"}
          selector={"country"}
        />
      ),
      cell: (row) => <CustomCellCountry row={row} editCountry={editCountry} />,
    },
    medio: {
      heigth: "100px",
      minWidth: "200px",
      position: 4,
      name: (
        <HeadIco
          title={
            <Tooltip
              title={
                <Tooltip
                  title="Medio en el que se activa la campaña."
                  color="#002448"
                  placement="top"
                >
                  {" "}
                  Medio
                </Tooltip>
              }
              color="#002448"
              placement="top"
            >
              Medio
            </Tooltip>
          }
          position={4}
          width={"250px"}
          selector={"medio"}
        />
      ),
      cell: (row) => (
        <CustomCellMedio
          row={row}
          editMediumData={editMediumData}
          completeEdit={completeEdit}
        />
      ),
    },
    banner: {
      minWidth: "200px",
      heigth: "100px",
      position: 5,
      name: (
        <HeadIco
          title={
            <Tooltip
              title="Tipo de objetivo de la campaña."
              color="#002448"
              placement="top"
            >
              Objetivo
            </Tooltip>
          }
          position={5}
          width={"250px"}
          selector={"banner"}
        />
      ),
      cell: (row) => (
        <CustomCellBanner
          row={row}
          editValueBanner={editValueBanner}
          completeEdit={completeEdit}
        />
      ),
    },
    formato: {
      minWidth: "200px",
      heigth: "100px",
      position: 6,
      name: (
        <HeadIco
          title={
            <Tooltip
              title="Tipo de formato de la campaña."
              color="#002448"
              placement="top"
            >
              Formato
            </Tooltip>
          }
          position={6}
          width={"250px"}
          selector={"formato"}
        />
      ),
      width: "200px",
      cell: (row) => (
        <CustomCellFormat
          row={row}
          editValueFormat={editValueFormat}
          completeEdit={completeEdit}
        />
      ),
    },
    budgetName: {
      minWidth: "200px",
      heigth: "100px",
      position: 7,
      name: (
        <HeadIco
          title={
            <Tooltip
              title="Nombre del presupuesto"
              color="#002448"
              placement="top"
            >
              Nombre del presupuesto
            </Tooltip>
          }
          position={7}
          width={"250px"}
          selector={"budgetName"}
        />
      ),
      cell: (row) => (
        <CustomCellBudgetName row={row} editValueBudgetName={editData} />
      ),
    },
    budgetI: {
      minWidth: "200px",
      heigth: "100px",
      position: 8,
      name: (
        <HeadIco
          title={
            <Tooltip
              title="Presupuesto aprobado."
              color="#002448"
              placement="top"
            >
              Presupuesto Invertido
            </Tooltip>
          }
          position={8}
          width={"250px"}
          selector={"budgetI"}
        />
      ),
      cell: (row) =>
        row.name !== TOTALES ? (
          <CustomCellImplementation row={row} editCel={editData} />
        ) : row.budget ? (
          <p>{`$${Number(row.budget).toFixed(2)}`}</p>
        ) : (
          <p>{`$0.00`}</p>
        ),
    },
    segmentation: {
      minWidth: "200px",
      heigth: "100px",
      position: 9,
      name: (
        <HeadIco
          title={
            <Tooltip
              title="Público objetivo al que tu campaña va dirigida."
              color="#002448"
              placement="top"
            >
              Públicos
            </Tooltip>
          }
          position={9}
          width={"250px"}
          selector={"segmentation"}
        />
      ),
      cell: (row) => (
        <CustomCellSegmentation row={row} editSegmentation={editSegmentation} />
      ),
    },
  };

  let columnsVisual = columnsVisualizer.map((element) => {
    const mappingElementColumn = mapColumns[element.id];
    if (mappingElementColumn) {
      return { ...element, ...mappingElementColumn };
    }
    return element;
  });

  useEffect(() => {
    if (!_.isEmpty(props.edit)) {
      setVisualizerFilter(props.edit);
      let columnsWithoutName = props.edit.previsualizer?.map((column) => {
        let { ...columnWithoutTag } = column;
        columnWithoutTag.budget = Number(columnWithoutTag.budget);
        columnWithoutTag.categoriesSelected = column?.categoriesSelected || [];
        return columnWithoutTag;
      });
      for (let i = 0; i < columnsWithoutName.length; i++) {
        if (columnsWithoutName[i].categoriesSelected) {
          var categories = columnsWithoutName[i].categoriesSelected;

          const categoriesGruped = categories.reduce(
            (groups, item) => ({
              ...groups,
              [item.nombrePadre]: [...(groups[item.nombrePadre] || []), item],
            }),
            {}
          );

          let valores = Object.values(categoriesGruped);

          for (let j = 0; j < valores.length; j++) {
            let catsString = "";
            for (let k = 0; k < valores[j].length; k++) {
              catsString += valores[j][k].label;
              if (k < valores[j].length - 1) {
                catsString += ",";
              }
            }
            catsString = catsString.trim().replace(/,\s*$/, "");
            let body = { [valores[j][0].padre]: catsString };
            columnsWithoutName[i] = { ...columnsWithoutName[i], ...body };
          }
        }
      }
      setData(columnsWithoutName || []);
      setDataBackup(columnsWithoutName || []);
    } else {
      setData([]);
      props._setImplementButton(false);
      setDataBackup([]);
    }
  }, [props.edit]);

  const refreshBudgets = async () => {
    await props._refreshBudgets(props.currentAccount);
  };

  useEffect(() => {
    if (props.currentAccount !== "") {
      refreshBudgets();
      getCategories();
      getDataUser();
      getAllAudiences();
    }
  }, [props.currentAccount]);

  const editData = async (
    previsualizerEdit,
    propsEdit,
    budgetsParams,
    dataUser
  ) => {
    setCompleteEdit(false);
    await props._editData(
      previsualizerEdit,
      propsEdit,
      dataUser,
      budgetsParams
    );
  };

  const editDates = async (valueDates, propsEdit, dataUser) => {
    await props._editDates(valueDates, propsEdit, dataUser);
  };

  const editCountry = async (valueCountryEdit, propsEdit, dataUser) => {
    await props._editCountry(valueCountryEdit, propsEdit, dataUser);
    setCompleteEdit(false);
    props._setCurrentIdPautaTemplate(valueCountryEdit[0].id?.id);
  };

  const editMediumData = async (
    editMedioValue,
    propsEdit,
    mediumBanner,
    dataUser
  ) => {
    await props._editMediumData(
      editMedioValue,
      propsEdit,
      mediumBanner,
      dataUser
    );
    setCompleteEdit(false);
  };

  const editValueBanner = async (
    editValueBanner,
    propsEdit,
    goalsTemplate,
    dataUser
  ) => {
    await props._editValueBanner(
      editValueBanner,
      propsEdit,
      goalsTemplate,
      dataUser
    );
    setCompleteEdit(false);
  };

  const editValueFormat = async (
    valueEdit,
    propsEdit,
    formatTemplate,
    dataUser
  ) => {
    await props._editValueFormat(
      valueEdit,
      propsEdit,
      formatTemplate,
      dataUser
    );
    setCompleteEdit(true);
  };

  const editSegmentation = async (values, propsEdit, audiences, dataUser) => {
    await props._editSegmentation(values, propsEdit, audiences, dataUser);
  };

  const removeDuplicates = (data) => {
    let set = new Set(data.map(JSON.stringify));
    let uniqueArray = Array.from(set).map(JSON.parse);
    return uniqueArray;
  };

  const updateCategoriesSelectedData = async (row, idRow) => {
    const { optionsSelect, value, column, id } = row[0];
    const categoriesData = [];
    const { categoriesSelected } = column;
    props._setEditActivate(true);
    setCategories((data) => {
      const findData = data.find(
        (ele) =>
          ele.padre === "97bc9ee5-36a0-455b-974c-fa7832c9c341" &&
          ele.name === value &&
          ele.active
      );

      const categoriesSelectedData = [];

      optionsSelect.forEach((element) => {
        const foundCategorie = data.find(
          (ele) => ele.name === element && ele.active
        );
        if (foundCategorie) {
          const body = {
            label: element,
            nombrePadre: findData.name,
            padre: findData.id,
            value: foundCategorie.id,
          };
          categoriesSelectedData.push(body);
        }
      });
      if (categoriesSelected.length > 0) {
        const categoriesFilter = categoriesSelected.filter(
          (ele) => ele.padre !== findData.id
        );
        categoriesSelectedData.push(...categoriesFilter);
      }

      const arrSinDuplicaciones = removeDuplicates(categoriesSelectedData);
      categoriesData.push(...arrSinDuplicaciones);

      setData((data) => {
        let prevData = [...data];
        let index = prevData.findIndex((ele) => ele.id === idRow);

        prevData[index] = {
          ...prevData[index],
          categoriesSelected: arrSinDuplicaciones,
          [id]:
            categoriesData.length > 0
              ? categoriesData.map((ele) => ele.label).join(", ")
              : undefined,
        };
        setVisualizerFilter((prev) => {
          props._upsertVisualizer(prev, prevData[index]);
          props._setImplementButton(true);
          return prev;
        });
        return prevData;
      });

      setDataBackup((prev) => {
        let prevData = [...prev];
        let index = prevData.findIndex((ele) => ele.id === idRow);

        const { categoriesSelected } = prevData[index];
        const optionsSelect = [...categoriesSelected, ...categoriesData];
        var arrSinDuplicaciones = optionsSelect?.filter(
          (x, i) => optionsSelect?.indexOf(x) === i
        );
        prevData[index] = {
          ...prevData[index],
          categoriesSelected: arrSinDuplicaciones,
          [id]:
            categoriesData.length > 0
              ? categoriesData.map((ele) => ele.label).join(", ")
              : undefined,
        };
        return prevData;
      });

      return data;
    });
  };

  const sendDuplicateRow = async (row, propsEdit) => {
    let nameRow = row.name;
    let newName = nameRow;

    const existingNames = propsEdit.previsualizer.map((item) => item.name);
    if (existingNames.includes(nameRow)) {
      let i = 1;
      while (existingNames.includes(newName)) {
        newName = `${nameRow} (Copia ${i})`;
        i++;
      }
    } else {
      newName = nameRow + " (Copia)";
    }

    const newRow = {
      ...row,
      id: v4(),
      name: newName,
    };

    const response = await props._addOneVisualizer(propsEdit, newRow);
    props._setEdit(response);
  };

  const getCategories = async () => {
    const currentAccountData = _.get(
      await API.getCategories(props.currentAccount),
      "data.categories",
      []
    );

    const categoriesActive = currentAccountData.filter((item) => item.active);
    setCategories(categoriesActive);
    const activeCategories = currentAccountData.filter(
      (item) =>
        item.active &&
        (item.padre === "97bc9ee5-36a0-455b-974c-fa7832c9c341" ||
          item.padre === "")
    );
    setCategoriesData(activeCategories);
    const sortedActiveCategories = _.orderBy(
      activeCategories,
      ["name"],
      ["asc"]
    );

    const columnsCategories = sortedActiveCategories.map((cat) => {
      const optionsCats = categoriesActive.filter(
        (ele) => ele.padre === cat.id && ele.active
      );
      const options = optionsCats.map((ele) => ele.name);

      const column = {
        id: cat.id,
        name: cat.name,
        selector: () => cat.id,
        // selector: cat.id,
        sortable: false,
        tag: cat.id,
        type: "text",
        center: true,
        minWidth: "285px",
        omit: false,
        nameCategory: cat.name,
        nameCol: cat.name,
        cell: (row) => {
          const value = row.categoriesSelected
            ? row.categoriesSelected
              .filter((ele) => ele.padre === cat.id)
              .map((ele) => ele.label)
            : [];
          if (row.name === "Pauta") {
          }
          if (row?.name === TOTALES) return <></>;
          return (
            <EditAnt
              nameColumn={row}
              id={cat.id}
              edit={true}
              defaultValue={value}
              onEditValue={(data) => updateCategoriesSelectedData(data, row.id)}
              options={options}
              placeholder="Selecciona una opción"
              style={{ width: "250px" }}
              texto={
                row[cat.id] !== undefined
                  ? row[cat.id].length < 10
                    ? row[cat.id]
                    : row[cat.id].substr(0, 10) + "..."
                  : "..."
              }
              optionalValue={cat.name}
            />
          );
        },
      };
      return column;
    });

    const newColums = [...columnsVisual, ...columnsCategories];
    props.setColumns(newColums);
    setColumnsSelect((prev) => [...prev, ...newColums]);
  };

  const handleEditVisualiser = (row) => {
    props._setEditVisualizer(row);
    setShowModal(true);
  };

  const handleCancelTemplate = async () => {
    return Swal.fire({
      title: "¿Cancelar edición?",
      text: "Esta acción cancelará los cambios realizados en la plantilla",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Confirmar",
      cancelButtonText: "Cancelar",
      bottonInvert: true,
    }).then((result) => {
      if (result.isConfirmed) {
        props._getTemplates(props.currentAccount, props.edit.id);
        props._setEditActivate(false);
      }
    });
  };

  const handleSendTemplate = () => {
    props._updateTemplateDynamo(props.edit, props.edit.previsualizer || []);
  };

  // Elimina la pauta
  const handleDeleteTemplate = (id, propsEdit) => {
    if (id) {
      Swal.fire({
        title: "¿Estás seguro?",
        text: "Esta acción eliminará el registro",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonText: "Cancelar",
        cancelButtonColor: "#d33",
        confirmButtonText: "Confirmar",
      }).then((result) => {
        if (result.isConfirmed) {
          props._removeVIsualizer(propsEdit, id);

          Swal.fire(
            "Eliminado",
            "El registro ha sido eliminado correctamente",
            "success"
          );
        }
      });
    }
  };

  const sendDeploy = () => {
    // if (props?.isIpv4) {
    //   props._sendDeployCampaignLogout(
    //     data,
    //     props.currentAccount,
    //     props.accounts,
    //     props.edit.id,
    //     props.edit
    //   );
    // } else {
    props._sendDeployCampaigns(
      data,
      props.currentAccount,
      props.accounts,
      props.edit.id,
      props.edit.account,
      props.edit.isDefaultByAccount,
      props.edit
    );
    //   }
  };

  const newTemplateInAccount = () => {
    setModalCreate(true);
  };

  const onChangePage = (page) => {
    props._generateLink(
      props.currentAccount,
      historyDL,
      page,
      props.currentTemplateId,
      ""
    );
  };

  useEffect(() => {
    // este los ordena para los pineados
    if (!init) {
      columnsSelect?.forEach((ele) => {
        const idx = columnsSelect.findIndex((column) => column?.id === ele?.id);
        if (idx !== -1) {
          columnsSelect[idx].omit = ele?.omit;
          columnsSelect[idx].position = ele?.position;
        }
      });
      columnsSelect.sort((a, b) => {
        return a?.position - b?.position;
      });
    }
  }, [columnsSelect]);

  const onClickpin = (e, _, __, selector) => {
    // parametro selector, selector o nombre de la columna a pinear o despinear
    // pinnedSelectors es un array de selector que nos indica las columnas pineadas, al despinearse se borra el nombre seleccionado
    if (e.stopPropagation && e.preventDefault) {
      e.stopPropagation();
      e.preventDefault();
    }

    props.setColumns((cols) => {
      let columnsArray = []; // Nuevo array de columnas que se seteara al state columns
      let isPinned = pinnedSelectors.find((el) => el === selector);

      // Columna ninguno y name siempre seran los primeras dos posiciones en el datatable
      // isPinned nos indica si la columna ya esta pineada
      if (isPinned) {
        //elemento a despinear
        let elementSelector = cols.find((el) => el.selector === selector);
        // se quita la posicion pineada
        const oldPin = elementSelector.pinned;
        elementSelector.pinned = null;

        const pinned = [];
        const notPinned = [];
        // se ajustan las posiciones pineadas
        cols.forEach((col) => {
          if (col.pinned === 0 || col.pinned > 0) {
            if (col.pinned > oldPin) {
              col.pinned--;
            }
            pinned.push(col);
          } else {
            notPinned.push(col);
          }
        });
        //quitando el selector de la lista de pineados
        pinnedSelectors = pinnedSelectors.filter(function (col) {
          return col !== selector;
        });

        pinned.sort((col1, col2) => col1.pinned - col2.pinned);
        notPinned.sort((col1, col2) => col1.position - col2.position);

        // nuevas columnas

        columnsArray = pinned.concat(notPinned);
      } else {
        // pinnedSelectors.length==0 nos indica si es primer pineo
        if (pinnedSelectors.length == 0) {
          //Agregando ninguno y name
          let res = cols[0];
          // let res2 = cols[1];
          columnsArray.push(res);
          // columnsArray.push(res2);

          // Recuperando la fila por selector y agregandolo al array de columnas nuevas
          let foundSelector = cols.find((el) => el.id === selector);
          foundSelector.pinned = columnsArray.length + 1;
          columnsArray.push(foundSelector);

          //Agregando el seletor al array de pineados
          pinnedSelectors.push(selector);

          //Agregando el resto de columnas
          for (let i = 0; i < cols.length; i++) {
            //Sino existe en el array de columna nuevas, lo agrega
            let foundPinned = columnsArray.find((el) => el.id === cols[i].id);
            if (!foundPinned) {
              columnsArray.push(cols[i]);
            }
          }
        } else {
          // no es primer pineo y no debe exceder de 5
          if (pinnedSelectors.length < 15) {
            //Agregando ninguno y name
            let res = cols[0];
            // let res2 = cols[1];
            columnsArray.push(res);
            //Agregando el nuevo pineaado
            pinnedSelectors.push(selector);

            //Agregando la lista de pineados al array de nuevas columnas
            for (let i = 0; i < pinnedSelectors.length; i++) {
              let foundSelector = cols.find(
                (el) => el.id === pinnedSelectors[i]
              );
              foundSelector.pinned = columnsArray.length + 1;
              columnsArray.push(foundSelector);
            }

            //Agregando los demas que no esten en el array de nuevas columnas
            for (let i = 0; i < cols.length; i++) {
              // Agrega la fila que no este dentro del array de nuevas columnas
              let foundColumns = columnsArray.find(
                (el) => el.id === cols[i].selector
              );
              if (!foundColumns) {
                columnsArray.push(cols[i]);
              }
            }
          } else {
            columnsArray = cols;
          }
        }
      }
      // estilos para dejar las columnas fijas
      const newStyles = columnsArray.reduce((obj, col, idx) => {
        if (col.pinned) {
          const width =
            obj.prev !== undefined
              ? document.querySelector(
                `div[data-column-id="${columnsArray[idx - 1].id
                }"][data-sort-id="${columnsArray[idx - 1].id}"]`
              ).parentElement.clientWidth
              : 0;
          const newWidth = (obj.prev || 0) + width;
          obj[`&:nth-of-type(${col.pinned})`] = {
            position: "sticky",
            zIndex: "9999 !important",
            left: `${newWidth}px`,
            backgroundColor: "#fff",
          };
          obj.prev = newWidth;
        }
        return obj;
      }, {});
      delete newStyles.prev;
      setCustomStyles({
        headCells: {
          style: newStyles,
        },
        cells: {
          style: newStyles,
        },
      });
      return columnsArray;
    });
  };

  return (
    <div className="content-menu contenedorDashboard">
      <div style={{ marginBottom: "10px" }} className="contTabla1">
        {data.length > 0 && (
          <>
            <ViewTable
              key={"key"}
              columns={columns}
              filterText={filterText}
              data={data}
              currentPage={props.currentPageParams}
              onChangePage={onChangePage}
              paginationDefaultPage={props.currentPageParams}
              setData={setData}
              customStyles={customStyles}
            />
            <Stack
              direction="row"
              spacing={2}
              justifyContent="space-between"
              alignItems="center"
            >
              {/* Contenedor para los otros botones alineados al inicio */}
              <Stack direction="row" spacing={2} justifyContent="flex-start">
                {props.editActivateTemplate && (
                  <>
                    <Button
                      variant="outlined"
                      onClick={handleCancelTemplate}
                      startIcon={
                        <i className="fa fa-ban" aria-hidden="true"></i>
                      }
                    >
                      Cancelar
                    </Button>
                    {props.edit.account === DEFAULT ? (
                      <Button
                        variant="contained"
                        onClick={newTemplateInAccount}
                        endIcon={
                          <i className="fa fa-check" aria-hidden="true"></i>
                        }
                      >
                        Crear nueva plantilla
                      </Button>
                    ) : (
                      <Button
                        variant="contained"
                        onClick={handleSendTemplate}
                        endIcon={
                          <i className="fa fa-check" aria-hidden="true"></i>
                        }
                      >
                        Guardar plantilla
                      </Button>
                    )}
                  </>
                )}
              </Stack>

              {/* Botón "Enviar a implementar" alineado al final */}
              <Stack direction="row" spacing={2} justifyContent="flex-end">
                {props.isButtonImplementDisabled && props.editActivateTemplate
                  ?
                  <Tooltip
                    title="Guarda los cambios pendientes antes de enviar a implementar."
                    placement="top"
                    color="#002448"
                    zIndex={999999999}
                  >
                    <div>
                      <Button
                        variant="contained"
                        onClick={sendDeploy}
                        endIcon={<i className="fa fa-check" aria-hidden="true"></i>}
                        disabled={props.isButtonImplementDisabled}
                      >
                        Enviar a implementar
                      </Button>
                    </div>
                  </Tooltip>
                  :
                  <Button
                    variant="contained"
                    onClick={sendDeploy}
                    endIcon={<i className="fa fa-check" aria-hidden="true"></i>}
                    disabled={props.isButtonImplementDisabled}
                  >
                    Enviar a implementar
                  </Button>}
              </Stack>
            </Stack>
          </>
        )}
      </div>
      {showModal && (
        <div className="blur-div blurNewPauta">
          <div
            className={classnames("upsert-modal w-75 h-75 modalapauta")}
            style={{
              backgroundColor: "white",
              padding: "20px",
              borderRadius: "10px",
            }}
          >
            <UpsertTemplate setShowModal={setShowModal} />
          </div>
        </div>
      )}
      {modalCreate && (
        <ModalCreate
          currentAccount={props.currentAccount}
          setShowModal={setModalCreate}
          edit={props.edit}
          newTemplateForAccount={props._newTemplateForAccount}
          setOption={props.setOption}
        />
      )}
      {showModalAttachment && (
        <div className="blur-div blurNewPauta">
          <div
            className={classnames("upsert-modal w-75 h-75 modalapauta")}
            style={{
              backgroundColor: "white",
              padding: "20px",
              borderRadius: "10px",
            }}
          >
            <ModalAttachment
              showModalAttachment={showModalAttachment}
              setShowModalAttachment={setShowModalAttachment}
              currentAccount={props.currentAccount}
              edit={props.edit}
              info={modalInfo}
              newTemplateForAccount={props._newTemplateForAccount}
            />
          </div>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  isIpv4: state.app.isIPV4,
  accounts: state.accounts.list,
  edit: state.templates.edit,
  editActivateTemplate: state.templates.editActivate,
  currentAccount: state.accounts.default,
  currentPageParams: state.templateDL.currentPage,
  currentTemplateId: state.templateDL.currentTemplate,
  isButtonImplementDisabled: state.templates.buttonImplement,
});

const mapDispatchToProps = (dispatch) => ({
  _sendDeployCampaignLogout: (
    visualizers,
    account,
    accounts,
    idTemplate,
    edit
  ) =>
    dispatch(
      sendDeployCampaignLogout(visualizers, account, accounts, idTemplate, edit)
    ),
  _showLoader: (show) => dispatch(showLoader(show)),
  _upsertVisualizer: (visualizer, template) =>
    dispatch(upsertVisualizer(visualizer, template)),
  _sendDeployCampaigns: (
    visualizers,
    account,
    accounts,
    idTemplate,
    accountTemplate,
    isDefaultByAccount,
    edit
  ) =>
    dispatch(
      sendDeployCampaigns(
        visualizers,
        account,
        accounts,
        idTemplate,
        accountTemplate,
        isDefaultByAccount,
        edit
      )
    ),
  _getTemplates: (account, id) => dispatch(getTemplates(account, id)),
  _setEdit: (edit) => dispatch(setEdit(edit)),
  _setEditVisualizer: (visualizer) => dispatch(setEditVisualizer(visualizer)),
  _setEditActivate: (edit) => dispatch(setEditActivate(edit)),
  _updateTemplateDynamo: (template, data) =>
    dispatch(updateTemplateDynamo(template, data)),
  _addOneVisualizer: (template, visualizer) =>
    dispatch(addOneVisualizer(template, visualizer)),
  _removeVIsualizer: (template, id) => dispatch(removeVIsualizer(template, id)),
  _newTemplateForAccount: (
    data,
    account,
    setOption,
    id,
    history,
    newTemplateCopy
  ) =>
    dispatch(
      newTemplateForAccount(
        data,
        account,
        setOption,
        id,
        history,
        newTemplateCopy
      )
    ),
  _editData: (edit, data, dataUser, budgets) =>
    dispatch(editData(edit, data, dataUser, budgets)),
  _editDates: (edit, data, dataUser) =>
    dispatch(editDates(edit, data, dataUser)),
  _editCountry: (edit, data, dataUser) =>
    dispatch(editCountry(edit, data, dataUser)),
  _editMediumData: (edit, data, banner, dataUser) =>
    dispatch(editMediumData(edit, data, banner, dataUser)),
  _editValueBanner: (edit, data, goals, dataUser) =>
    dispatch(editValueBanner(edit, data, goals, dataUser)),
  _editValueFormat: (edit, data, format, dataUser) =>
    dispatch(editValueFormat(edit, data, format, dataUser)),
  _editSegmentation: (edit, data, audiences, dataUser) =>
    dispatch(editSegmentation(edit, data, audiences, dataUser)),
  _generateLink: (currentAccount, history, page, templateId, filterText) =>
    dispatch(
      generateLink(currentAccount, history, page, templateId, filterText)
    ),
  _setCurrentIdPautaTemplate: (id) => dispatch(setCurrentIdPautaTemplate(id)),
  _setImplementButton: (value) => dispatch(setImplementButton(value)),
  _getAudiences: (account) => dispatch(getAudiences(account)),
  _setUserInfo: (user) => dispatch(setUserInfo(user)),
  _refreshBudgets: (account) => dispatch(refreshBudgets(account)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withUtils(withRouter(ViewTemplates)));
