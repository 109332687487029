import React, { useEffect, useState, useRef } from "react";
import _ from "lodash";
import classnames from "classnames";
import { descifrar } from "helpers/encrypt";
import status from "config/status";
import { newEmailValidation } from "redux/actions/email.actions";
import { connect } from "react-redux";
import { columnsDashboard, orderDefault, getStatusLabel } from "config/columns";
import DetailColEdit from "components/DetailCol";
import { parse, format, subDays } from "date-fns";
import { DateRangeFilter } from "libs/dateRange";
import { showLoader } from "redux/actions/loader.actions";
import DataTableUtils, {
  Share,
  ShowColumn2,
  FilterColumn2,
} from "@easygosanet/react-datatable-utils";
import { v4 } from "uuid";
import analytics from "../../helpers/analytics";
import ReactDataTable, { EditAnt } from "@easygosanet/react-datatable";
//imagenes
import "./assets/css/styles.scss";
import $ from "jquery";
import Swal from "sweetalert2";
//componentes
import Table from "./table";
import Kanban from "./kanban";
import Calendar from "./calendar";
import ModalDetaill from "components/ModalDetaill/ModalDetaill";
import ModalAudience from "components/audiences/modal/upsert_audience";
import ModalImage from "components/ModalImage/ModalImage";
import ModalRegister from "components/ModalRegister/ModalRegister";
import ModalCard from "components/ModalCard/ModalCard";
import * as Modal from "redux/actions/modal.actions";
import {
  funcampaignupd,
  clearCampaignupd,
  updateCampaign,
  msgsuccess,
  updateCampaignTable,
  approveCampaign,
  validateHoursAvailable,
  addCampaigns,
  alertMessage,
  listArrayCampaigns,
} from "redux/actions/dashboard.actions";
import { cleanSteps } from "redux/actions/nameCamp.actions";
import { updateAccountMembers } from "redux/actions/accounts.actions";
import { getUsersRole } from "redux/actions/users.actions";
import { withUtils } from "utils";
import { addIdMixDetaill } from "redux/actions/modalDetail.actions";
import { getUrlImages } from "redux/actions/modalImage.actions";
import * as API from "helpers/apiLA";
import * as APILA from "helpers/api";
import { find } from "lodash";
import { Auth } from "aws-amplify";
import ModalLink from "./ModalLink";
import ModalUpdatePriority from "./ModalUpdatePriority";
import ModalPauta from "components/modalPauta/ModalPauta";
import { Tooltip, Select } from "antd";
import { useParams, useHistory, useLocation } from "react-router-dom";
import { formatDashboardModel } from "utils/utilsModelCampaign";
import ModalMeta from "./modalMeta";
import { setIsSuperAdmin } from "redux/actions/app.actions";
import { useSelector, useDispatch } from "react-redux";
import {
  objectPermissions,
  allPeriodCampaignsDates,
  formatDate,
  formatDateToCustomString,
  unzip,
  getDateMinus7DaysFormatted,
  getCurrentDateFormatted,
} from "./constants";
import Axios from "axios";
import PageLoader from "components/page_loader";

const { Option } = Select;

/* import Bar from './bar' */
const idmod = 2;
const mod = "DASHBOARD";

localStorage.setItem(
  "state",
  JSON.stringify({
    place: "dias",
    data: [],
  })
);
let pinnedSelectors = [];
let pinnedHidden = [];
let pinnedHiddenShow = [];

const formatDateParams = (dateParams) => {
  if (!dateParams) return null;
  const [day, month, year] = dateParams.split("/");
  return `${year}/${month}/${day}`;
};

const Bar = (props) => {
  //DL
  const history = useHistory();
  const location = useLocation();
  const urlSplit = location.pathname.split("/campañas/account=");
  const queryParams = new URLSearchParams(location.pathname);

  // Vista dgeneral
  const valueVista = urlSplit[1]?.split("&")[1].split("=")[1];
  const defaultVista = isNaN(valueVista) ? 1 : Number(valueVista);

  // DL search input
  const currentUrl = new URLSearchParams(location.search);
  const searchInput = currentUrl.get("results");

  // DL current Page
  const pageParams = urlSplit[1]?.split("&")[4]?.split("=")[1];
  const [currentPage, setCurrentPage] = useState(parseInt(pageParams) || 1);

  // DL view value
  const viewValue = urlSplit[1]?.split("&")[3]?.split("=")[1];

  //DL group value
  const groupValue = urlSplit[1]?.split("&")[2].split("=")[1];

  //DL account value
  const accountValue = urlSplit[1]?.split("&")[0];

  const [backupData, setBackupData] = useState([]);
  const [colAct, setColAct] = useState([]);
  const [filteredValues, setFilteredValues] = useState([]);
  const [account, setAccount] = useState(props.currentAccount);
  const { sendCampaignss, categories, sharedView, audiences } = props;
  const [columns, setColumns] = useState([]);
  const [columns1, setColumns1] = useState([]);
  const [filterText, setFilterText] = useState("");
  const [showModalPriority, setShowModalPriority] = useState(false);
  const [state, setState] = useState({
    data: 1,
    filter: "",
  });
  const [groupedBy, setGroupedBy] = useState("ninguno");
  const [totalRow, setTotalRow] = useState();
  const [categoriesSorted, setCategoriesSorted] = useState([]);
  const [actualState, setActualState] = useState({});
  const [campEdit, setCampEdit] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showModal2, setShowModal2] = useState(false);
  const [valueFilterText, setValueFilterText] = useState(searchInput || "");
  const [arraynIds, setArraynIds] = useState("");
  const [nameView, setNameView] = useState("");
  const [views, setViews] = useState([]);
  const [viewCount, setviewCount] = useState(false);
  const [namesCate, setNamesCate] = useState([]);
  const [showAdd, setShowAdd] = useState(false);
  const [nameGrouped, setnameGrouped] = useState("");
  const [nameSelectedGrouped, setnameSelectedGrouped] = useState("");
  const [optionss, setOptionss] = useState([]);
  const [cargarView, setCargarView] = useState(false);
  const [colsBack, setColsBack] = useState([]);
  const [permissionsBar, setPermissionsBar] = useState(props.permissions);
  const [showCalendar, setShowCalendar] = useState(false);
  const [showModalLink, setShowModalLink] = useState(false);
  const [linkDataShare, setLinkDataShare] = useState({});

  const [stateKpi, setStateKpi] = useState({
    id: "",
    goal: 0,
  });
  const modalRef = useRef();

  // DL search dates
  const fechaIni = queryParams.get("initial_date");
  const dateInitialFormat =
    formatDateParams(fechaIni) || getCurrentDateFormatted();
  const parseInit = parse(fechaIni, "dd/MM/yyyy", new Date());
  const fechaFin = queryParams.get("final_date");
  const dateFinFormat =
    formatDateParams(fechaFin) || getDateMinus7DaysFormatted();
  const parseFin = parse(fechaFin, "dd/MM/yyyy", new Date());
  const [datesToShow, setDatesToShow] = useState({
    fechaIni: parseInit || "",
    fechaFin: parseFin || "",
  });

  // Dl
  const activeCategories = props.categories?.filter(
    (item) =>
      item.active &&
      (item.padre === "97bc9ee5-36a0-455b-974c-fa7832c9c341" ||
        item.padre === "")
  );

  const sortedActiveCategories = _.orderBy(activeCategories, ["name"], ["asc"]);

  const optionsDefault = [
    "Objetivo",
    "Medio",
    "Pais",
    "Tipo de compra",
    "Banner",
    "Estado",
    "Orden de venta",
    "Nombre de presupuesto",
    "formato",
  ];

  const sortedCatNames = sortedActiveCategories
    .map((cat) => cat.name)
    .concat(optionsDefault)
    .sort();

  const sortedCatNamesWithDefault = [...sortedCatNames];
  const params = new URLSearchParams(window.location.search);
  const filters = [];
  for (const [column, value] of params) {
    if (sortedCatNamesWithDefault.includes(column)) {
      filters.push({ column, value });
    }
  }
  // fin DL

  var dataEnviada = {
    account: account,
    user: props.currentAccount,
    idmod: idmod,
    mod: mod,
  };

  const [customStyles, setCustomStyles] = useState({
    headCells: {
      style: {
        textAlign: "center",
        "&:nth-of-type(1)": {
          position: "sticky",
          zIndex: "3 !important",
          left: "0",
          backgroundColor: "#fff",
        },
        "&:nth-of-type(8)": {
          maxWidth: "300px",
        },
      },
    },
    cells: {
      textAlign: "center !important",
      style: {
        "&:nth-of-type(1)": {
          position: "sticky",
          zIndex: "3 !important",
          left: "0",
          backgroundColor: "#fff",
        },
        "&:nth-of-type(2n)": {
          zIndex: "2",
        },
      },
    },
  });

  // Initial array DL
  const [filterInfo, setFilterInfo] = useState([...filters]);

  // Construct DL params
  const today = new Date();
  const formatToday = format(today, "dd/MM/yyyy");
  const sevenDaysAgo = subDays(new Date(), 7);
  const formattedDate = format(sevenDaysAgo, "dd/MM/yyyy");

  const changeUrl = ({
    viewParams = state.data,
    groupParams = groupValue || "default",
    pageParams = 1,
    viewColumnParams = viewValue || "default",
    inicial = formattedDate,
    final = formatToday,
  }) => {
    const validateAccount =
      accountValue === undefined || accountValue === null
        ? props.currentAccount
        : accountValue;

    const newPathname = `/campañas/account=${validateAccount}&view=${viewParams}&group=${groupParams}&viewColumn=${viewColumnParams}&page=${pageParams}&initial_date=${inicial}&final_date=${final}`;
    history.push({
      pathname: newPathname,
    });
    if (location.search.length > 0) {
      history.push({
        search: location.search,
      });
    }
  };

  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  // DL utils
  const dateInvalid = (date) => {
    // true si es invalida
    return isNaN(date.getTime());
  };

  function numberWithCommas1(x) {
    let auxNumber = new Number(x).toFixed(2);
    return auxNumber.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  const findCampaignByMixDetail = async (idclient, idMixDetail) => {
    try {
      const response = await API.findCampaignByMixDetail({
        idclient: idclient,
        idMixDetaill: idMixDetail,
      });
      return response.data.campaigns.length > 0
        ? response.data.campaigns[0]
        : null;
    } catch (error) {
      return null;
    }
  };

  const getCampaignById = async (idMix) => {
    try {
      const response = await API.getCampaignById(idMix);
      return response.data.campaigns.length > 0
        ? response.data.campaigns[0]
        : null;
    } catch (error) {
      return;
    }
  };

  const findCampaignByIdSale = async (idclient, id_sale) => {
    const response = await API.findCampaignByIdSale({
      idclient: idclient,
      id_sale: id_sale,
    });
    return response.data.campaigns.length > 0
      ? response.data.campaigns[0]
      : null;
  };

  const getCampaignByIdSale = async (idSale) => {
    const response = await API.getCampaignByIdSale({
      idSale: idSale,
    });
    return response.data.campaigns.length > 0
      ? response.data.campaigns[0]
      : null;
  };

  const audienceComponent1 = (idAudience) => {
    let audience = props.audiences?.filter((el) => el.id === idAudience);
    let audienceToShow = "";
    if (audience.length > 0) {
      audienceToShow = audience[0].name;
    }
    return audienceToShow;
  };

  const calculateTotal = (datosAgrupados) => {
    let presupuestoNeto = 0;
    let presupuestoInvertido = 0;
    let estimatedAdRecallers = 0;
    let presupuestoConsumido = 0;
    let presupuestoComision = 0;
    let presupuestoSinConsumir = 0;
    let totalPorcentajePresupuestoConsumido = 0;
    let porcentajePresupuestoConsumido = 0;
    let contPresupuestoConsumido = 0;
    let visualizacionesImagen = 0;
    let eventResponses = 0;
    let appInstalations = 0;
    let frequency = 0;
    let totalFrequency = 0;
    let contFrequency = 0;
    let leads = 0;
    let cpf = 0;
    let totalCpf = 0;
    let contCpf = 0;
    let cpre = 0;
    let totalCpre = 0;
    let contCpre = 0;
    let cpi = 0;
    let totalCpi = 0;
    let contCpi = 0;
    let cpv = 0;
    let totalCpv = 0;
    let contCpv = 0;
    let ctr = 0;
    let totalCtr = 0;
    let contCtr = 0;
    let cpl = 0;
    let totalCpl = 0;
    let contCpl = 0;
    let cpa = 0;
    let totalCpa = 0;
    let contCpa = 0;
    let cpc = 0;
    let totalCpc = 0;
    let contCpc = 0;
    let cpm = 0;
    let totalCpm = 0;
    let contCpm = 0;
    let cprm = 0;
    let totalCprm = 0;
    let contCprm = 0;
    let prints = 0;
    let clicks = 0;
    let fans = 0;
    let reach = 0;
    let views = 0;
    let meta = 0;
    let video_playbacks_25 = 0;
    let video_playbacks_50 = 0;
    let video_playbacks_75 = 0;
    let video_playbacks_95 = 0;
    let video_playbacks_100 = 0;
    let adv = 0;
    let totalAdv = 0;
    let contAdv = 0;
    let postShares = 0;
    let postLikes = 0;
    let totalDiasCamp = 0;
    let diasCamp = 0;
    let contDiasCamp = 0;
    let comments = 0;
    let budgetNeto = 0;
    let percentage = 0;
    let contPercentage = 0;
    let totalPercentage = 0;
    let valueTotalPercentage = 0;
    let totales = [];
    for (let x = 0; x < datosAgrupados.length; x++) {
      presupuestoNeto += new Number(
        datosAgrupados[x].budget
          ? datosAgrupados[x].budget
          : datosAgrupados[x].net_budget
      );

      let percentageValue = getPercentNumber(datosAgrupados[x]);

      if (percentageValue != "NA") {
        percentage += new Number(percentageValue);
        contPercentage++;
      }

      let percentageTot = getPercentValue(datosAgrupados[x]);
      if (percentageTot != "NA") {
        valueTotalPercentage += new Number(percentageTot);
      }

      if (new Number(datosAgrupados[x].budget) > 0) {
        presupuestoInvertido += new Number(datosAgrupados[x].budget);
      }

      if (new Number(datosAgrupados[x]?.budgetNeto) > 0) {
        budgetNeto += new Number(datosAgrupados[x]?.budgetNeto);
      }

      if (datosAgrupados[x].meta != null) {
        meta += new Number(datosAgrupados[x].meta);
      }

      if (new Number(datosAgrupados[x].estimated_ad_recallers)) {
        estimatedAdRecallers += new Number(
          datosAgrupados[x].estimated_ad_recallers
        );
      }

      if (datosAgrupados[x].budgetConsumido > 0) {
        presupuestoConsumido += new Number(datosAgrupados[x].budgetConsumido);
      } else {
        presupuestoConsumido += new Number(
          getTotalspent(datosAgrupados[x].idMixDetaill)
        );
      }

      if (datosAgrupados[x].swc) {
        presupuestoComision += new Number(datosAgrupados[x].swc);
      }

      if (datosAgrupados[x].budgetSinConsumir) {
        presupuestoSinConsumir += new Number(
          datosAgrupados[x].budgetSinConsumir
        );
      }

      if (datosAgrupados[x].photo > 0) {
        visualizacionesImagen += new Number(datosAgrupados[x].photo);
      }

      if (datosAgrupados[x].spent_percent) {
        porcentajePresupuestoConsumido += new Number(
          datosAgrupados[x].spent_percent
        );
        contPresupuestoConsumido++;
      }
      if (datosAgrupados[x].cprm) {
        cprm += new Number(datosAgrupados[x].cprm);
        contCprm++;
      }
      if (datosAgrupados[x].cpf) {
        cpf += new Number(datosAgrupados[x].cpf);
        contCpf++;
      }
      if (datosAgrupados[x].cpre) {
        cpre += new Number(datosAgrupados[x].cpre);
        contCpre++;
      }
      if (datosAgrupados[x].cpi) {
        cpi += new Number(datosAgrupados[x].cpi);
        contCpi++;
      }
      if (datosAgrupados[x].cpv) {
        cpv += new Number(datosAgrupados[x].cpv);
        contCpv++;
      }
      if (datosAgrupados[x].ctr) {
        ctr += new Number(datosAgrupados[x].ctr);
        contCtr++;
      }
      if (datosAgrupados[x].leads) {
        leads += new Number(datosAgrupados[x].leads);
      }
      if (datosAgrupados[x].event_responses) {
        eventResponses += new Number(datosAgrupados[x].event_responses);
      }
      if (datosAgrupados[x].installs) {
        appInstalations += new Number(datosAgrupados[x].installs);
      }
      if (datosAgrupados[x].frequency) {
        frequency += new Number(datosAgrupados[x].frequency);
        contFrequency++;
      }
      if (datosAgrupados[x].cpl) {
        cpl += new Number(datosAgrupados[x].cpl);
        contCpl++;
      }
      if (datosAgrupados[x].cpa) {
        cpa += new Number(datosAgrupados[x].cpa);
        contCpa++;
      }
      if (datosAgrupados[x].cpc) {
        cpc += new Number(datosAgrupados[x].cpc);
        contCpc++;
      }
      if (datosAgrupados[x].cpm) {
        cpm += new Number(datosAgrupados[x].cpm);
        contCpm++;
      }
      if (datosAgrupados[x].prints) {
        prints += new Number(datosAgrupados[x].prints);
      }
      if (datosAgrupados[x].clicks) {
        clicks += datosAgrupados[x].clicks;
      }
      if (datosAgrupados[x].fans) {
        fans += datosAgrupados[x].fans;
      }
      if (datosAgrupados[x].reach) {
        reach += datosAgrupados[x].reach;
      }
      if (datosAgrupados[x].views) {
        views += datosAgrupados[x].views;
      }
      if (datosAgrupados[x].video_playbacks_25) {
        video_playbacks_25 += datosAgrupados[x].video_playbacks_25;
      }
      if (datosAgrupados[x].video_playbacks_50) {
        video_playbacks_50 += datosAgrupados[x].video_playbacks_50;
      }
      if (datosAgrupados[x].video_playbacks_75) {
        video_playbacks_75 += datosAgrupados[x].video_playbacks_75;
      }
      if (datosAgrupados[x].video_playbacks_95) {
        video_playbacks_95 += datosAgrupados[x].video_playbacks_95;
      }
      if (datosAgrupados[x].video_playbacks_100) {
        video_playbacks_100 += datosAgrupados[x].video_playbacks_100;
      }
      if (datosAgrupados[x].adv) {
        var a = datosAgrupados[x].adv.split(":");
        var seconds = +a[0] * 60 * 60 + +a[1] * 60 + +a[2];
        adv += seconds;
        contAdv++;
      }
      if (datosAgrupados[x].postShares) {
        postShares += datosAgrupados[x].postShares;
      }
      if (datosAgrupados[x].post_likes > 0) {
        postLikes += datosAgrupados[x].post_likes;
      }
      if (datosAgrupados[x].dias_campaña) {
        diasCamp += datosAgrupados[x].dias_campaña;
        contDiasCamp++;
      }
      if (datosAgrupados[x].postComments) {
        comments += datosAgrupados[x].postComments;
      }
    }
    totalAdv = adv / contAdv;
    totalCpm = cpm / contCpm;
    totalCpc = cpc / contCpc;
    totalCpa = cpa / contCpa;
    totalCpl = cpl / contCpl;
    totalCtr = ctr / contCtr;
    totalCpv = cpv / contCpv;
    totalCpi = cpi / contCpi;
    totalCpre = cpre / contCpre;
    totalCpf = cpf / contCpf;
    totalCprm = cprm / contCprm;
    totalPorcentajePresupuestoConsumido =
      porcentajePresupuestoConsumido / contPresupuestoConsumido;
    totalFrequency = frequency / contFrequency;
    totalDiasCamp = diasCamp / contDiasCamp;
    if (contPercentage != 0) {
      totalPercentage = percentage / contPercentage;
    } else {
      totalPercentage = "NA";
    }
    totales = [
      {
        /*  "adv": totalAdv>0 ? totalAdv.toFixed(2) : 0, */
        adv:
          totalAdv > 0
            ? new Date(totalAdv * 1000).toISOString().substr(11, 8)
            : "00:00:00",
        ctr: totalCtr > 0 ? totalCtr.toFixed(2) : "0.00",
        cpc: totalCpc > 0 ? totalCpc.toFixed(2) : "0.00",
        estimated_ad_recallers: estimatedAdRecallers,
        cprm: totalCprm > 0 ? totalCprm : "0",
        photo: visualizacionesImagen,
        video_playbacks_50: video_playbacks_50,
        video_playbacks_25: video_playbacks_25,
        video_playbacks_75: video_playbacks_75,
        video_playbacks_95: video_playbacks_95,
        video_playbacks_100: video_playbacks_100,
        cpv: totalCpv > 0 ? totalCpv.toFixed(3) : "0.000",
        prints: prints,
        country: "",
        clicks: clicks,
        views: views,
        fr: 0,
        searchable_status: "",
        idBudget: 0,
        swc: presupuestoComision.toFixed(2),
        budgetConsumido: presupuestoConsumido.toFixed(2),
        budgetI: presupuestoInvertido?.toFixed(2),
        budget: presupuestoInvertido?.toFixed(2),
        budgetNeto: budgetNeto?.toFixed(2),
        status: 100,
        budgetSinConsumir: presupuestoSinConsumir.toFixed(2),
        installs: appInstalations,
        spent_percent:
          totalPorcentajePresupuestoConsumido > 0
            ? totalPorcentajePresupuestoConsumido.toFixed(2)
            : "0.00",
        leads: leads > 0 ? leads : 0,
        reach: reach,
        event_responses: eventResponses > 0 ? eventResponses.toFixed(0) : 0,
        name: "Totales",
        metaTotales: meta,
        totalPercentage:
          totalPercentage != "NA"
            ? valueTotalPercentage.toFixed(0) +
              "/" +
              totalPercentage.toFixed(0) +
              "%105"
            : "NA",
        client: "0",
        post_likes: postLikes,
        medio: "",
        idMixChange: 0,
        frequency: totalFrequency > 0 ? totalFrequency.toFixed(2) : 0,
        cpf: totalCpf > 0 ? totalCpf.toFixed(2) : "0.00",
        cpr: totalCpre > 0 ? totalCpre.toFixed(2) : "0.00",
        cpi: totalCpi > 0 ? totalCpi.toFixed(2) : "0.00",
        fans: fans,
        cpm: totalCpm > 0 ? totalCpm.toFixed(2) : "0.00",
        cpl: totalCpl > 0 ? totalCpl.toFixed(2) : "0.00",
        cpa: totalCpa > 0 ? totalCpa.toFixed(3) : "0.000",
        postShares: postShares,
        dias_campaña: totalDiasCamp > 0 ? totalDiasCamp.toFixed(0) : 0,
        postComments: comments,
      },
    ];

    return totales;
  };

  const HeadIco = ({ title, position, width, selector }) => {
    const [valor, setValor] = useState(false);
    useEffect(() => {
      setColumns((cols) => {
        if (pinnedHiddenShow.includes(selector)) {
          pinnedHiddenShow = pinnedHiddenShow.filter((ele) => ele !== selector);
          onClickpin("", "", "250px", selector);
          setValor(true);
        } else {
          let idx = cols.findIndex((ele) => ele.selector === selector);
          let pinned = cols[idx].hasOwnProperty("pinned")
            ? cols[idx].pinned !== null
              ? true
              : false
            : false;
          setViews((state) => {
            let active = state.find((ele) => ele.active);
            if (active && !cols[idx].first) {
              let colsPinned = active.columns.find(
                (ele) => ele.selector === selector && ele.pinned
              );
              if (colsPinned) {
                cols[idx].first = true;
                onClickpin(
                  "",
                  colsPinned.position,
                  colsPinned.width,
                  colsPinned.selector
                );
                setValor(true);
              }
            }
            return state;
          });
          if (pinned) {
            if (!valor) {
              setValor(true);
            }
          } else {
            if (valor) {
              setValor(false);
            }
          }
        }

        return cols;
      });
    }, [columns]);

    return (
      <div className={valor === true ? "ContIco pineado" : "ContIco"}>
        <a
          className="fas fa-thumbtack"
          onClick={(e) => {
            setValor(!valor);
            onClickpin(e, position, width, selector);
          }}
        />
        {/* <i className='fas fa-sort-down'></i> */}
        <p className="nameTilte"> {title} </p>
      </div>
    );
  };

  const onClickpin = async (e, position, width, selector) => {
    setShowAdd(true);
    if (e.stopPropagation && e.preventDefaul) {
      e.stopPropagation();
      e.preventDefault();
    }
    setColumns((cols) => {
      let columnsArray = []; // Nuevo array de columnas que se seteara al state columns
      let isPinned = pinnedSelectors.find((el) => el === selector);

      // Columna ninguno y name siempre seran los primeras dos posiciones en el datatable
      // isPinned nos indica si la columna ya esta pineada
      if (isPinned) {
        //elemento a despinear
        let elementSelector = cols.find((el) => el.selector === selector);
        // se quita la posicion pineada
        const oldPin = elementSelector.pinned;
        elementSelector.pinned = null;

        const pinned = [];
        const notPinned = [];
        // se ajustan las posiciones pineadas
        cols.forEach((col) => {
          if (col.pinned === 0 || col.pinned > 0) {
            if (col.pinned > oldPin) {
              col.pinned--;
            }
            pinned.push(col);
          } else {
            notPinned.push(col);
          }
        });
        //quitando el selector de la lista de pineados
        pinnedSelectors = pinnedSelectors.filter(function (col) {
          return col !== selector;
        });

        pinned.sort((col1, col2) => col1.pinned - col2.pinned);
        notPinned.sort((col1, col2) => col1.position - col2.position);

        columnsArray = pinned.concat(notPinned);
      } else {
        // pinnedSelectors.length==0 nos indica si es primer pineo
        if (pinnedSelectors.length == 0) {
          //Agregando ninguno y name
          let res = cols[0];
          let res2 = cols[1];
          columnsArray.push(res);
          columnsArray.push(res2);

          // Recuperando la fila por selector y agregandolo al array de columnas nuevas
          let foundSelector = cols.find((el) => el.selector === selector);
          foundSelector.pinned = columnsArray.length;
          columnsArray.push(foundSelector);

          //Agregando el seletor al array de pineados
          pinnedSelectors.push(selector);

          //Agregando el resto de columnas
          for (let i = 0; i < cols.length; i++) {
            //Sino existe en el array de columna nuevas, lo agrega
            let foundPinned = columnsArray.find(
              (el) => el.selector === cols[i].selector
            );
            if (!foundPinned) {
              columnsArray.push(cols[i]);
            }
          }
        } else {
          // no es primer pineo y no debe exceder de 5
          if (pinnedSelectors.length < 15) {
            //Agregando ninguno y name
            let res = cols[0];
            let res2 = cols[1];
            columnsArray.push(res);
            columnsArray.push(res2);
            //Agregando el nuevo pineaado
            pinnedSelectors.push(selector);

            //Agregando la lista de pineados al array de nuevas columnas
            for (let i = 0; i < pinnedSelectors.length; i++) {
              let foundSelector = cols.find(
                (el) => el.selector === pinnedSelectors[i]
              );
              foundSelector.pinned = columnsArray.length;
              columnsArray.push(foundSelector);
            }

            //Agregando los demas que no esten en el array de nuevas columnas
            for (let i = 0; i < cols.length; i++) {
              // Agrega la fila que no este dentro del array de nuevas columnas
              let foundColumns = columnsArray.find(
                (el) => el.selector === cols[i].selector
              );
              if (!foundColumns) {
                columnsArray.push(cols[i]);
              }
            }
          } else {
            columnsArray = cols;
          }
        }
      }
      // estilos para dejar las columnas fijas
      const newStyles = columnsArray.reduce((obj, col, idx) => {
        if (col.pinned) {
          const width =
            obj.prev !== undefined
              ? document.getElementById(`column-${columnsArray[idx - 1].id}`)
                  .parentElement.clientWidth
              : 0;
          const newWidth = (obj.prev || 0) + width;
          obj[`&:nth-of-type(${col.pinned})`] = {
            position: "sticky",
            zIndex: "9999 !important",
            left: `${newWidth}px`,
            backgroundColor: "#fff",
          };
          obj.prev = newWidth;
        }
        return obj;
      }, {});
      delete newStyles.prev;
      setCustomStyles({
        headCells: {
          style: newStyles,
        },
        cells: {
          style: newStyles,
        },
      });
      return columnsArray;
    });
  };

  const validateGeneral = async (id) => {
    let activeData = await API.getBitacoraByidActionAndMix({
      idMixDetail: id,
      action: "estado activo de la pauta",
    });
    return activeData.data.bitacoras;
  };

  const editData = async (value) => {
    let col = value[0].column; //nameColumn de la libreria
    let valor = value[0].value; //optionalValue de la libreria //
    let name = value[0].id; //id de la libreria
    let authorize = false;
    let title = "";
    if (name === "name_region")
      title = "Ten en cuenta que debes editar el medio";
    else if (name === "medio")
      title = "Ten en cuenta que debes editar el objetivo";
    else if (name === "banner")
      title = "Ten en cuenta que debes editar el formato";
    else title = "Está seguro que desea editar la campaña?";
    let optionsInactive = ["medio", "objetivo", "name_region", "formato"];

    try {
      if (!props.isSupAdmin) {
        if (col.status === 9) {
          Swal.fire({
            title: "¡En implementación!",
            text: "Tu campaña se esta implementando por lo que no puedes modificarla.",
            icon: "info",
            showCancelButton: false,
            confirmButtonColor: "#3085d6",
            confirmButtonText: "Ok",
          }).then(() => {
            return;
          });
        } else if (col.status === 10) {
          //activo
          if (optionsInactive.includes(name)) {
            Swal.fire({
              // title: "En implementación!",
              text: "Tu campaña esta en activo por lo que no puedes modificar el campo seleccionado.",
              icon: "info",
              showCancelButton: false,
              confirmButtonColor: "#3085d6",
              confirmButtonText: "Ok",
            });
          } else {
            authorize = true;
          }
        } else if (col.status === 18) {
          //pausado
          let data = await validateGeneral(col.id);
          if (data.length > 0 && optionsInactive.includes(name)) {
            Swal.fire({
              // title: "En implementación!",
              text: "Tu campaña ya estuvo en activo por lo que no puedes editar este campo.",
              icon: "info",
              showCancelButton: false,
              confirmButtonColor: "#3085d6",
              confirmButtonText: "Ok",
            });
          } else {
            authorize = true;
          }
        } else if (col.status === 12) {
          Swal.fire({
            // title: "En implementación!",
            text: "Tu campaña no se puede editar por su estado finalizado",
            icon: "info",
            showCancelButton: false,
            confirmButtonColor: "#3085d6",
            confirmButtonText: "Ok",
          });
        } else if (col.status === 13) {
          if (name === "budget") {
            authorize = true;
          } else {
            Swal.fire({
              text: "Solo puedes editar el campo de presupuesto invertido",
              icon: "info",
              showCancelButton: false,
              confirmButtonColor: "#3085d6",
              confirmButtonText: "Ok",
            });
          }
        } else if (col.status === 15) {
          //rechazado
          let data = await validateGeneral(col.id);
          if (data.length > 0 && optionsInactive.includes(name)) {
            Swal.fire({
              // title: "En implementación!",
              text: "Tu campaña ya estuvo en activo por lo que no puedes editar este campo.",
              icon: "info",
              showCancelButton: false,
              confirmButtonColor: "#3085d6",
              confirmButtonText: "Ok",
            });
          } else {
            authorize = true;
          }
        } else if (col.status === 16) {
          //No implementado
          let data = await validateGeneral(col.id);
          if (data.length > 0 && optionsInactive.includes(name)) {
            Swal.fire({
              // title: "En implementación!",
              text: "Tu campaña ya estuvo en activo por lo que no puedes editar este campo.",
              icon: "info",
              showCancelButton: false,
              confirmButtonColor: "#3085d6",
              confirmButtonText: "Ok",
            });
          } else {
            authorize = true;
          }
        } else if (col.status === 17) {
          Swal.fire({
            // title: "En implementación!",
            text: "Tu campaña no se puede editar",
            icon: "info",
            showCancelButton: false,
            confirmButtonColor: "#3085d6",
            confirmButtonText: "Ok",
          });
        } else if (col.status === 11) {
          let data = await validateGeneral(col.id);
          if (data.length > 0 && optionsInactive.includes(name)) {
            Swal.fire({
              // title: "En implementación!",
              text: "Tu campaña ya estuvo en activo por lo que no puedes editar este campo.",
              icon: "info",
              showCancelButton: false,
              confirmButtonColor: "#3085d6",
              confirmButtonText: "Ok",
            });
          } else {
            authorize = true;
          }
        } else if (col.status === 14) {
          let data = await validateGeneral(col.id);
          if (data.length > 0 && optionsInactive.includes(name)) {
            Swal.fire({
              // title: "En implementación!",
              text: "Tu campaña ya estuvo en activo por lo que no puedes editar este campo.",
              icon: "info",
              showCancelButton: false,
              confirmButtonColor: "#3085d6",
              confirmButtonText: "Ok",
            });
          } else {
            authorize = true;
          }
        } else {
          authorize = true;
        }
      } else {
        authorize = true;
      }

      if (authorize) {
        props._showConfirmationModal("Editar campaña", title, async () => {
          let peticiones = [
            APILA.getDefaultEtiqueta(),
            APILA.getEtiquetasByAccount({ account: props.currentAccount }),
            API.getCampaignById(value[0].column.id),
            API.getTempCampaigns({ account: account, id: value[0].column.id }),
            APILA.getBudgets(account),
            API.getCampaignById(col.id),
          ];

          let dataResponse = await Promise.all(peticiones);
          try {
            analytics.accionesTabla({
              accion: "Editar con doble clic",
              ubicacion: "Dashboard",
            });
            const responseDefault = dataResponse[0].data.defaultEtiqueta[0];
            const etiquetasAccount = dataResponse[1].data.etiquetas;
            const responseBudgets = dataResponse[4].data.budgets;
            const campaignEditable = dataResponse[5].data.campaigns[0];
            let aud = props.audiences;
            const mAccount = _.find(props.accounts, { id: account });
            let availableAmount = 0;
            let errorBudget = false;
            let errorBudgetMessage = "";
            let aux;
            let newBudget = 0;
            let budgetParsed = [];
            let validateProduct = true;
            let chosenTag;
            let etiquetaFound = false;

            const state = {
              id: campaignEditable.id,
              nombre: name === "name" ? valor : campaignEditable.name,
              medio: name === "formato" ? col.medio : campaignEditable.medio,
              fechaIni: name === "year" ? valor : campaignEditable.fechaIni,
              fechaFin:
                name === "year" ? value[0].end : campaignEditable.fechafin,
              objetivo: name === "objetivo" ? valor : campaignEditable.objetivo,
              status: campaignEditable.status,
              budget: name === "budget" ? valor : campaignEditable.budget,
              publico:
                name === "publico" ? valor.data : campaignEditable.publico,
              idMixDetail: campaignEditable.idMixDetaill,
              idMixChange: campaignEditable.idMixChange,
              idMix: campaignEditable.idMix,
              idCampaign:
                name === "idCampaign" ? valor : campaignEditable.idCampaign,
              idMedium:
                name === "formato"
                  ? col.bodyUpdateProduct.id_medium
                  : campaignEditable.id_medium,
              id_ad_product:
                name === "formato" ? valor : campaignEditable.id_ad_product,
              banner:
                name === "formato"
                  ? col.bodyUpdateProduct.name_medio
                  : campaignEditable.banner,
              idBudget: name === "idBudget" ? valor : campaignEditable.idBudget,
              creativity:
                name === "creativity" ? valor : campaignEditable.creativity,
              observation:
                name === "observation" ? valor : campaignEditable.observation,
              copy: name === "copy" ? valor : campaignEditable.copy,
              id_sale: name === "id_sale" ? valor : campaignEditable.id_sale,
              id_region:
                name === "formato"
                  ? col.bodyUpdateProduct.id_region
                  : campaignEditable.id_region,
              nameCountry:
                name === "formato"
                  ? col.bodyUpdateProduct.name_region
                  : campaignEditable.name_region,
              formato:
                name === "formato"
                  ? value[0].valueFormat
                  : campaignEditable.formato,
              idAudience:
                name === "publico"
                  ? value[0].idAudience
                  : campaignEditable.idAudience,
              categoriesSelected:
                name === "optionSelected"
                  ? valor
                  : campaignEditable.categoriesSelected,
            };

            budgetParsed = responseBudgets.map((item) => {
              return {
                ...budgetParsed,
                account: item.account,
                active: item.active,
                amount: item.amount,
                available: parseFloat(item.available),
                createAt: item.createAt,
                id: item.id,
                status: item.status,
                tags: item.tags,
                title: item.title,
                updateAt: item.updateAt,
              };
            });

            //Validacion de nuevo disponible
            if (state.budget > 0) {
              if (campaignEditable.idBudget === state.idBudget) {
                availableAmount =
                  Number(
                    budgetParsed.find((ele) => ele.id === state.idBudget)
                      .available
                  ) + Number(col.budget);
                props.utils.log("Presupuesto disponible", {
                  availableAmount,
                });
              } else {
                availableAmount = Number(
                  budgetParsed.find((ele) => ele.id === state.idBudget)
                    .available
                );
              }
              let nuevoBudgetOp = availableAmount - Number(state.budget);
              aux = state.budget;
              newBudget = nuevoBudgetOp;
            } else {
              availableAmount = Number(
                budgetParsed.find((ele) => ele.id === state.idBudget).available
              );
              let operacion = availableAmount - Number(state.budget);
              if (operacion >= 0) {
                newBudget = operacion;
              } else {
                aux = value;
              }
            }

            //Mensajes de validaciones para presupuestos
            if (Number(state.idBudget) > availableAmount) {
              errorBudget = true;
              errorBudgetMessage =
                "Monto incorrecto, el presupuesto ingresado es mayor al disponible. ";
            }
            if (Number(state.budget) < Number(col.swc)) {
              errorBudget = true;
              errorBudgetMessage =
                " Monto incorrecto, el presupuesto ingresado es menor al consumido. ";
            }
            if (state.budget < 0) {
              errorBudget = true;
              errorBudgetMessage = "Presupuesto no válido ";
            }
            if (state.status === 13) {
              if (Number(state.budget) > Number(col.swc)) {
                errorBudget = true;
                errorBudgetMessage =
                  "Monto incorrecto, el presupuesto ingresado es mayor al presupuesto consumido con comisión";
              }
              if (Number(state.budget) < Number(col.swc)) {
                errorBudget = true;
                errorBudgetMessage =
                  "Monto incorrecto, el presupuesto ingresado es menor al presupuesto consumido con comisión";
              }
            }
            let copyDataSeach = [];
            if (!errorBudget) {
              if (name === "name_region") {
                validateProduct = false;
                let countriesFilter = value[0].countryData;

                API.getMediumBanner({
                  id_region: countriesFilter.id_region,
                  bandera: 1,
                }).then((medio) => {
                  let nombres = medio.data.data.map((ele) => ele.site);
                  if (copyDataSeach.length > 0) {
                    setFilteredValues((prev) => {
                      let upsertData = [...prev];
                      let idx = upsertData.findIndex(
                        (ele) => ele.id === col.id
                      );
                      let bodyNew = {
                        name_region: valor,
                        id_region: value[0].id_region,
                        medio: true,
                        objetivo: true,
                        format: true,
                      };
                      upsertData[idx] = {
                        ...upsertData[idx],
                        options: nombres,
                        country: valor,
                        name_region: valor,
                        optionsMedium: medio.data.data,
                        bodyUpdateProduct: bodyNew,
                      };
                      return upsertData;
                    });
                  } else {
                    setFilteredValues((prev) => {
                      let upsertData = [...prev];
                      let idx = upsertData.findIndex(
                        (ele) => ele.id === col.id
                      );
                      let bodyNew = {
                        name_region: valor,
                        id_region: value[0].id_region,
                        medio: true,
                        objetivo: true,
                        format: true,
                      };
                      upsertData[idx] = {
                        ...upsertData[idx],
                        options: nombres,
                        country: valor,
                        name_region: valor,
                        optionsMedium: medio.data.data,
                        bodyUpdateProduct: bodyNew,
                      };

                      return upsertData;
                    });
                  }
                });
              } else if (name === "medio") {
                validateProduct = false;
                let optionsMediumFilter = col.optionsMedium.find(
                  (ele) => ele.site === value[0].value
                );
                API.getMediumBanner({
                  id_region: col.bodyUpdateProduct.id_region,
                  id_medium: optionsMediumFilter.id_medium,
                }).then((medio) => {
                  let nombres = medio.data.data.map((ele) => ele.banner);
                  if (copyDataSeach.length > 0) {
                    setFilteredValues((prev) => {
                      let upsertDate = [...prev];
                      let idx = upsertDate.findIndex(
                        (ele) => ele.id === col.id
                      );
                      upsertDate[idx] = {
                        ...upsertDate[idx],
                        optionsBanner: nombres,
                        medio: valor,
                        optionsBannerCom: medio.data.data,
                        bodyUpdateProduct: {
                          ...upsertDate[idx].bodyUpdateProduct,
                          name_medio: value[0].value,
                          medio: valor,
                          id_medium: optionsMediumFilter.id_medium,
                          medio: false,
                        },
                      };
                      return upsertDate;
                    });
                  } else {
                    setFilteredValues((prev) => {
                      let upsertDate = [...prev];
                      let idx = upsertDate.findIndex(
                        (ele) => ele.id === col.id
                      );
                      upsertDate[idx] = {
                        ...upsertDate[idx],
                        optionsBanner: nombres,
                        medio: valor,
                        optionsBannerCom: medio.data.data,
                        bodyUpdateProduct: {
                          ...upsertDate[idx].bodyUpdateProduct,
                          name_medio: value[0].value,
                          medio: valor,
                          id_medium: optionsMediumFilter.id_medium,
                          medio: false,
                        },
                      };
                      return upsertDate;
                    });
                  }
                });
              } else if (name === "banner") {
                validateProduct = false;
                let idBanner = col.optionsBannerCom.find(
                  (ele) => ele.banner === valor
                );
                API.getFormatData({
                  id_medium: col.bodyUpdateProduct.id_medium,
                  id_banner: idBanner ? idBanner.id_banner : "",
                  id_region: col.bodyUpdateProduct.id_region,
                }).then((data) => {
                  let nombres = data.data.data.map((ele) => ele.art);
                  if (copyDataSeach.length > 0) {
                    setFilteredValues((prev) => {
                      let upsertData = [...prev];
                      let idx = upsertData.findIndex(
                        (ele) => ele.id === col.id
                      );
                      upsertData[idx] = {
                        ...upsertData[idx],
                        formatosOptions: nombres,
                        banner: valor,
                        formatComplete: data.data.data,
                        bodyUpdateProduct: {
                          ...upsertData[idx].bodyUpdateProduct,
                          name_medio: valor,
                          objetivo: false,
                        },
                      };
                      return upsertData;
                    });
                  } else {
                    setFilteredValues((prev) => {
                      let upsertData = [...prev];
                      let idx = upsertData.findIndex(
                        (ele) => ele.id === col.id
                      );
                      upsertData[idx] = {
                        ...upsertData[idx],
                        formatosOptions: nombres,
                        banner: valor,
                        formatComplete: data.data.data,
                        bodyUpdateProduct: {
                          ...upsertData[idx].bodyUpdateProduct,
                          name_medio: valor,
                          objetivo: false,
                        },
                      };
                      return upsertData;
                    });
                  }
                });
              }
            }

            etiquetasAccount.forEach((el) => {
              if (el.state === 1) {
                chosenTag = el;
                etiquetaFound = true;
              }
            });

            if (etiquetaFound === false) {
              chosenTag = responseDefault;
            }

            if (!errorBudget) {
              let finalEtiqueta = "";

              chosenTag.columnsSelected.forEach((el) => {
                if (el.includes("categorie:")) {
                  let value = el.split(":");
                  let found = categories.filter(
                    (eleCat) => eleCat.name === value[1]
                  );
                  if (found.length > 0) {
                    if (state.categoriesSelected.length > 0) {
                      let foundOptionSel = state.categoriesSelected.filter(
                        (eleOption) => eleOption.padre === found[0].id
                      );
                      if (foundOptionSel.length > 0) {
                        foundOptionSel.forEach((eleCat) => {
                          finalEtiqueta = finalEtiqueta + eleCat.label + "_";
                        });
                      }
                    }
                  }
                } else if (el === "client") {
                  finalEtiqueta = finalEtiqueta + mAccount.name + "_";
                } else if (el === "publico") {
                  if (state.idAudience != undefined || state.idAudience != "") {
                    let audienceFound = aud.filter(
                      (eleAud) => eleAud.id === state.idAudience
                    );
                    if (audienceFound.length > 0) {
                      finalEtiqueta =
                        finalEtiqueta + audienceFound[0].name + "_";
                    }
                  }
                } else if (el === "medio") {
                  finalEtiqueta = finalEtiqueta + state.medio + "_";
                } else if (el === "banner") {
                  if (state.banner) {
                    finalEtiqueta = finalEtiqueta + state.banner + "_";
                  } else {
                    finalEtiqueta = finalEtiqueta + state.banner + "_";
                  }
                } else if (el === "fechaIni") {
                  let fechaBien = state.fechaIni.split(".");
                  finalEtiqueta =
                    finalEtiqueta +
                    fechaBien[0] +
                    "/" +
                    fechaBien[1] +
                    "/" +
                    fechaBien[2] +
                    "_";
                } else if (el === "fechafin") {
                  let fechaBien = state.fechaFin.split(".");
                  finalEtiqueta =
                    finalEtiqueta +
                    fechaBien[0] +
                    "/" +
                    fechaBien[1] +
                    "/" +
                    fechaBien[2] +
                    "_";
                } else if (el === "country") {
                  if (state.nameCountry) {
                    finalEtiqueta = finalEtiqueta + state.nameCountry + "_";
                  } else {
                    finalEtiqueta = finalEtiqueta + state.nameCountry + "_";
                  }
                } else if (el === "status") {
                  finalEtiqueta =
                    finalEtiqueta + getStatusLabel(state.status) + "_";
                } else if (el === "budget") {
                  finalEtiqueta =
                    finalEtiqueta + "$" + numberWithCommas1(state.budget) + "_";
                } else if (el === "swc") {
                  finalEtiqueta =
                    finalEtiqueta +
                    "$" +
                    numberWithCommas1(props.campaignupd.swc) +
                    "_";
                } else if (el === "id") {
                  finalEtiqueta = finalEtiqueta + col.id + "_";
                } else if (el === "name") {
                  finalEtiqueta = finalEtiqueta + state.nombre + "_";
                } else {
                  if (state[el]) {
                    finalEtiqueta = finalEtiqueta + state[el] + "_";
                  }
                }
              });
              finalEtiqueta = finalEtiqueta.substring(
                0,
                finalEtiqueta.length - 1
              );

              if (validateProduct) {
                const userData = await Auth.currentAuthenticatedUser();
                const { signInUserSession } = userData;
                const token = signInUserSession.accessToken.jwtToken;
                const response = await APILA.getProfileInfo(token);
                const attributes = response.data.data.UserAttributes;

                const nameE = find(attributes, { Name: "name" });
                const email = find(attributes, { Name: "email" });
                const nameToSend = nameE.Value;
                const emailToSend = email.Value;
                props._updateCampaignTable(
                  "nombre",
                  "correo",
                  state.id,
                  state.nombre,
                  state.medio,
                  state.fechaIni,
                  state.fechaFin,
                  state.objetivo,
                  state.budget,
                  state.publico,
                  state.idMixDetail,
                  state.idMixChange,
                  state.idMix,
                  state.idCampaign,
                  state.idMedium,
                  state.medio,
                  state.id_ad_product,
                  state.banner,
                  state.status,
                  state.idBudget,
                  mAccount,
                  state.creativity,
                  state.observation,
                  state.copy,
                  state.id_sale,
                  state.id_region,
                  state.nameCountry,
                  nameToSend,
                  emailToSend,
                  _.find(props.budgets, { id: state.idBudget }).available,
                  newBudget,
                  props.budgets,
                  state.idAudience,
                  state.categoriesSelected,
                  chosenTag,
                  finalEtiqueta,
                  state.formato,
                  dataResponse[2],
                  dataResponse[3]
                );
              }
            } else {
              Swal.fire({
                title: "Ups!",
                text: errorBudgetMessage,
                icon: "error",
                confirmButtonColor: "#3085d6",
                confirmButtonText: "Ok",
              });
            }
          } catch (error) {
            console.log("ERROR ENCONTRADO", error);
          }
        });
      }
    } catch (error) {
      console.log("EL ERROR EN DASHBOARD", error);
      props._showLoader(false);
    }
  };

  const chageFlagAction = (row) => {
    props._showLoader(true);
    setShowModal2(true);
    analytics.accionesTabla({
      accion: "Meta",
      ubicacion: "Dashboard",
    });
    setStateKpi({
      status: row.status,
      id: row.id,
      goal: row.meta > 0 ? row.meta : 0,
      columnaCompare: row.columnaCompare,
    });
    setTimeout(() => {
      props._showLoader(false);
    }, 1000);
  };

  const audienceComponent = (idAudience) => {
    let audience = props.audiences?.filter((el) => el.id === idAudience);
    let audienceToShow = "";
    if (audience.length > 0) {
      audienceToShow = audience[0].name;
    }
    return <>{audienceToShow}</>;
  };

  const columnMappings = {
    name: {
      cell: (row) => (
        <Tooltip title={row.name} color="#002448" placement="top">
          <DetailColEdit
            text={row.name}
            flag={true}
            activate={true}
            id={"name"}
            accounts={[]}
            flagAction={() => chageFlagAction(row)}
            deleteData={() => showDeleteConfirmation(row)}
            deleteV={permissionsBar.delete || props.isSupAdmin}
            nameColumn={row}
            check={
              row.status === status.PAUSADO.id ||
              row.status === status.CREADO.id
                ? true
                : false
            }
            prints={true}
            getPrints={() => {
              props._getUrlImages(
                row.idMixDetaill,
                props.currentUser,
                idmod,
                mod
              );
              analytics.accionesTabla({
                accion: "Imágenes",
                ubicacion: "Dashboard",
              });
              window.$.fancybox.open({
                src: "#detail-image-modal",
                //type: 'modal',
                opts: {
                  modal: true,
                  afterClose: () => {
                    props._getUrlImages(0);
                  },
                },
              });
            }}
            editInfo={() => intermediaria(row)}
            edit={true}
            onEditValue={editData}
            approveConfirmation={() => approveConfirmation(row)}
            // editable={permissionsBar.edit}
            editable={false}
            type={"text"}
            permissions={permissionsBar}
          />
        </Tooltip>
      ),
    },
    year: {
      name: (
        <HeadIco
          title={
            <Tooltip
              title="Período en el que tu campaña está activa."
              color="#002448"
              placement="top"
            >
              Rango de Fecha
            </Tooltip>
          }
          position={4}
          width={"250px"}
          selector={"year"}
        />
      ),
    },
    id: {
      name: (
        <HeadIco
          title={
            <Tooltip
              title="ID único de la campaña."
              color="#002448"
              placement="top"
            >
              ID
            </Tooltip>
          }
          position={5}
          width={"180px"}
          selector={"id"}
        />
      ),
    },
    id_sale: {
      name: (
        <HeadIco
          title={
            <Tooltip
              title="Orden de venta de donde sale el presupuesto de la campaña."
              color="#002448"
              placement="top"
            >
              Orden de Venta
            </Tooltip>
          }
          position={6}
          width={"190px"}
          selector={"id_sale"}
        />
      ),
    },
    status: {
      name: (
        <HeadIco
          title={
            <Tooltip
              title="Estado de la campaña."
              color="#002448"
              placement="top"
            >
              Estado
            </Tooltip>
          }
          position={7}
          width={"190px"}
          selector={"status"}
        />
      ),
    },
    country: {
      name: (
        <HeadIco
          title={
            <Tooltip
              title="País de segmentación."
              color="#002448"
              placement="top"
            >
              Pais
            </Tooltip>
          }
          position={8}
          width={"150px"}
          selector={"country"}
        />
      ),
    },
    medio: {
      name: (
        <HeadIco
          title={
            <Tooltip
              title="Medio en el que se activa la campaña."
              color="#002448"
              placement="top"
            >
              Medio
            </Tooltip>
          }
          position={9}
          width={"100px"}
          selector={"medio"}
        />
      ),
    },
    banner: {
      name: (
        <HeadIco
          title={
            <Tooltip
              title="Tipo de objetivo de la campaña."
              color="#002448"
              placement="top"
            >
              Objetivo
            </Tooltip>
          }
          position={10}
          width={"250px"}
          selector={"banner"}
        />
      ),
    },
    formato: {
      name: (
        <HeadIco
          title={
            <Tooltip
              title="Tipo de formato de la campaña."
              color="#002448"
              placement="top"
            >
              Formato
            </Tooltip>
          }
          position={11}
          width={"250px"}
          //   //textAlign="center"
          selector={"formato"}
        />
      ),
    },
    budgetName: {
      name: (
        <HeadIco
          title={
            <Tooltip
              title="Nombre del presupuesto"
              color="#002448"
              placement="top"
            >
              Nombre del presupuesto
            </Tooltip>
          }
          position={12}
          width={"250px"}
          selector={"budgetName"}
        />
      ),
    },
    purchase_unit: {
      name: (
        <HeadIco
          title={
            <Tooltip
              title="Método por el que pagas la campaña."
              color="#002448"
              placement="top"
            >
              Tipo de Compra
            </Tooltip>
          }
          position={13}
          width={"150px"}
          selector={"purchase_unit"}
        />
      ),
    },
    budgetI: {
      name: (
        <HeadIco
          title={
            <Tooltip
              title="Presupuesto aprobado."
              color="#002448"
              placement="top"
            >
              Presupuesto Invertido
            </Tooltip>
          }
          position={14}
          width={"150px"}
          selector={"budgetI"}
        />
      ),
    },
    budget: {
      name: (
        <HeadIco
          title={
            <Tooltip
              title="Presupuesto implementado en el medio."
              color="#002448"
              placement="top"
            >
              Presupuesto Neto
            </Tooltip>
          }
          position={15}
          width={"150px"}
          selector={"budget"}
        />
      ),
    },
    budgetConsumido: {
      name: (
        <HeadIco
          title={
            <Tooltip
              title="Presupuesto gastado en el medio."
              color="#002448"
              placement="top"
            >
              Presupuesto Consumido
            </Tooltip>
          }
          position={16}
          width={"150px"}
          selector={"budgetConsumido"}
        />
      ),
    },
    swc: {
      name: (
        <HeadIco
          title={
            <Tooltip
              title="Presupuesto gastado aprobado."
              color="#002448"
              placement="top"
            >
              Presupuesto Consumido con Comisión
            </Tooltip>
          }
          position={17}
          width={"150px"}
          selector={"swc"}
        />
      ),
    },
    sp_perc: {
      name: (
        <HeadIco
          title={
            <Tooltip
              title="Porcentaje del presupuesto aprobado que ha sido gastado en el medio."
              color="#002448"
              placement="top"
            >
              Porcentaje de Presupuesto Consumido
            </Tooltip>
          }
          position={18}
          width={"150px"}
          selector={"sp_perc"}
        />
      ),
    },
    dias_campaña: {
      name: (
        <HeadIco
          title={
            <Tooltip
              title="Número de días que tu campaña estará activa según fechas configuradas."
              color="#002448"
              placement="top"
            >
              Días de Campaña
            </Tooltip>
          }
          position={19}
          width={"150px"}
          selector={"dias_campaña"}
        />
      ),
    },
    segmentation: {
      name: (
        <HeadIco
          title={
            <Tooltip
              title="Público objetivo al que tu campaña va dirigida."
              color="#002448"
              placement="top"
            >
              Públicos
            </Tooltip>
          }
          position={20}
          width={"150px"}
          selector={"segmentation"}
        />
      ),
      cell: (row) => audienceComponent(row.idAudience),
    },
    comentarios: {
      name: (
        <HeadIco
          title={
            <Tooltip
              title="Detalles, información adicional, observaciones a tomar en cuenta en la configuración de tu campaña."
              color="#002448"
              placement="top"
            >
              Comentarios
            </Tooltip>
          }
          position={21}
          width={"150px"}
          selector={"comentarios"}
        />
      ),
    },
    ctr: {
      name: (
        <HeadIco
          title={
            <Tooltip
              title="Porcentaje de veces que las personas vieron tu anuncio e hicieron un clic."
              color="#002448"
              placement="top"
            >
              CTR
            </Tooltip>
          }
          position={22}
          width={"150px"}
          selector={"ctr"}
        />
      ),
    },
    photo: {
      name: (
        <HeadIco
          title={
            <Tooltip
              title="Número de veces que las personas hacen clic en las fotos para verlas en Facebook."
              color="#002448"
              placement="top"
            >
              Visualizaciones de imagen
            </Tooltip>
          }
          position={23}
          width={"150px"}
          selector={"photo"}
        />
      ),
    },
    leads: {
      name: (
        <HeadIco
          title={
            <Tooltip
              title="Número de clientes potenciales obtenido en tus anuncios."
              color="#002448"
              placement="top"
            >
              Leads
            </Tooltip>
          }
          position={24}
          width={"150px"}
          selector={"leads"}
        />
      ),
    },
    event_responses: {
      name: (
        <HeadIco
          title={
            <Tooltip
              title="Número de personas que respondieron ME INTERESA o ASISTIRÉ a tu evento de Facebook como resultado de tus anuncios de Facebook."
              color="#002448"
              placement="top"
            >
              Respuestas a Eventos
            </Tooltip>
          }
          position={25}
          width={"150px"}
          selector={"event_responses"}
        />
      ),
    },
    installs: {
      name: (
        <HeadIco
          title={
            <Tooltip
              title="Número de instalaciones de la app que se registraron como resultado de tus anuncios."
              color="#002448"
              placement="top"
            >
              Instalación de aplicaciones
            </Tooltip>
          }
          position={26}
          width={"150px"}
          selector={"installs"}
        />
      ),
    },
    estimated_ad_recallers: {
      name: (
        <HeadIco
          title={
            <Tooltip
              title="Número estimado de personas que es posible que recuerden haber visto tus anuncios, si se les pregunta, en un plazo de 2 días. Aplica en Facebook."
              color="#002448"
              placement="top"
            >
              Reconocimiento de marca (personas)
            </Tooltip>
          }
          position={27}
          width={"150px"}
          selector={"estimated_ad_recallers"}
        />
      ),
    },
    frequency: {
      name: (
        <HeadIco
          title={
            <Tooltip
              title="Promedio de veces que cada persona vio tu anuncio."
              color="#002448"
              placement="top"
            >
              Frecuencia
            </Tooltip>
          }
          position={28}
          width={"150px"}
          selector={"frequency"}
        />
      ),
    },
    calificacion: {
      name: (
        <HeadIco
          title={
            <Tooltip
              title="El anuncio se clasifica en relación con anuncios que compitieron por el mismo público en Facebook. La calidad se mide con las opiniones sobre tus anuncios y la experiencia después de hacer clic en la publicación."
              color="#002448"
              placement="top"
            >
              Calificación del anuncio
            </Tooltip>
          }
          position={29}
          width={"250px"}
          selector={"calificacion"}
        />
      ),
    },
    conversaciones_iniciadas: {
      name: (
        <HeadIco
          title={
            <Tooltip
              title="Conversaciones iniciadas."
              color="#002448"
              placement="top"
            >
              Conversaciones iniciadas
            </Tooltip>
          }
          position={32}
          width={"150px"}
          selector={"cpre"}
        />
      ),
    },
    cpl: {
      name: (
        <HeadIco
          title={
            <Tooltip
              title="Costo promedio por lead"
              color="#002448"
              placement="top"
            >
              CPL
            </Tooltip>
          }
          position={30}
          width={"250px"}
          selector={"cpl"}
        />
      ),
    },
    cpi: {
      name: (
        <HeadIco
          title={
            <Tooltip
              title="Costo promedio por instalación de app."
              color="#002448"
              placement="top"
            >
              CPI
            </Tooltip>
          }
          position={31}
          width={"250px"}
          selector={"cpi"}
        />
      ),
    },
    cpma: {
      name: (
        <HeadIco
          title={
            <Tooltip
              title="Costo por mil personas alcanzadas."
              color="#002448"
              placement="top"
            >
              CPMA
            </Tooltip>
          }
          position={32}
          width={"150px"}
          selector={"cpre"}
        />
      ),
    },
    cpci: {
      name: (
        <HeadIco
          title={
            <Tooltip
              title="Costo por conversación iniciada."
              color="#002448"
              placement="top"
            >
              CPCI
            </Tooltip>
          }
          position={32}
          width={"150px"}
          selector={"cpre"}
        />
      ),
    },
    cpre: {
      name: (
        <HeadIco
          title={
            <Tooltip
              title="Costo promedio por respuesta a evento."
              color="#002448"
              placement="top"
            >
              CPRE
            </Tooltip>
          }
          position={32}
          width={"150px"}
          selector={"cpre"}
        />
      ),
    },
    cpa: {
      name: (
        <HeadIco
          title={
            <Tooltip
              title="Costo promedio por interacción en la publicación: Clics, comentarios, compartidos, reacciones, visualizaciones de imagen, reproducciones de video."
              color="#002448"
              placement="top"
            >
              CPInt
            </Tooltip>
          }
          position={33}
          width={"150px"}
          selector={"cpa"}
        />
      ),
    },
    cpc: {
      name: (
        <HeadIco
          title={
            <Tooltip
              title="Costo promedio por clic."
              color="#002448"
              placement="top"
            >
              CPC
            </Tooltip>
          }
          position={34}
          width={"150px"}
          selector={"cpc"}
        />
      ),
    },
    cpv: {
      name: (
        <HeadIco
          title={
            <Tooltip
              title="Costo promedio por reproducción de video."
              color="#002448"
              placement="top"
            >
              CPV
            </Tooltip>
          }
          position={35}
          width={"150px"}
          selector={"cpv"}
        />
      ),
    },
    cpm: {
      name: (
        <HeadIco
          title={
            <Tooltip
              title="Costo promedio por mil impresiones."
              color="#002448"
              placement="top"
            >
              CPM
            </Tooltip>
          }
          position={36}
          width={"150px"}
          selector={"cpm"}
        />
      ),
    },
    cpf: {
      name: (
        <HeadIco
          title={
            <Tooltip
              title="Costo promedio por fan."
              color="#002448"
              placement="top"
            >
              CPF
            </Tooltip>
          }
          position={37}
          width={"150px"}
          selector={"cpf"}
        />
      ),
    },
    cprm: {
      name: (
        <HeadIco
          title={
            <Tooltip
              title="Costo promedio por reconocimiento de marca."
              color="#002448"
              placement="top"
            >
              CPRM
            </Tooltip>
          }
          position={38}
          width={"150px"}
          selector={"cprm"}
        />
      ),
    },
    prints: {
      name: (
        <HeadIco
          title={
            <Tooltip
              title="Número de veces que se mostraron tus anuncios."
              color="#002448"
              placement="top"
            >
              Impresiones
            </Tooltip>
          }
          position={39}
          width={"150px"}
          selector={"prints"}
        />
      ),
    },
    clicks: {
      name: (
        <HeadIco
          title={
            <Tooltip
              title="Número de clics en tus anuncios."
              color="#002448"
              placement="top"
            >
              Clics
            </Tooltip>
          }
          position={40}
          width={"150px"}
          selector={"clicks"}
        />
      ),
    },
    fans: {
      name: (
        <HeadIco
          title={
            <Tooltip
              title="Número de Me gusta de la página de Facebook."
              color="#002448"
              placement="top"
            >
              Fans
            </Tooltip>
          }
          position={41}
          width={"150px"}
          selector={"fans"}
        />
      ),
    },
    reach: {
      name: (
        <HeadIco
          title={
            <Tooltip
              title="Número de personas que vieron tus anuncios al menos una vez."
              color="#002448"
              placement="top"
            >
              Alcance
            </Tooltip>
          }
          position={42}
          width={"150px"}
          selector={"reach"}
        />
      ),
    },
    views: {
      name: (
        <HeadIco
          title={
            <Tooltip
              title="Número de veces que se reproduce tu video en un anuncio."
              color="#002448"
              placement="top"
            >
              Reproducciones de video
            </Tooltip>
          }
          position={43}
          width={"150px"}
          selector={"views"}
        />
      ),
    },
    video_playbacks_25: {
      name: (
        <HeadIco
          title={
            <Tooltip
              title="Número de veces que se reproduce tu video hasta el 25% de su duración."
              color="#002448"
              placement="top"
            >
              Reproducciones de video 25%
            </Tooltip>
          }
          position={44}
          width={"150px"}
          selector={"video_playbacks_25"}
        />
      ),
    },
    video_playbacks_50: {
      name: (
        <HeadIco
          title={
            <Tooltip
              title="Número de veces que se reproduce tu video hasta el 50% de su duración."
              color="#002448"
              placement="top"
            >
              Reproducciones de video 50%
            </Tooltip>
          }
          position={45}
          width={"150px"}
          selector={"video_playbacks_50"}
        />
      ),
    },
    video_playbacks_75: {
      name: (
        <HeadIco
          title={
            <Tooltip
              title="Número de veces que se reproduce tu video hasta el 75% de su duración."
              color="#002448"
              placement="top"
            >
              Reproducciones de video 75%
            </Tooltip>
          }
          position={46}
          width={"150px"}
          selector={"video_playbacks_75"}
        />
      ),
    },
    video_playbacks_95: {
      name: (
        <HeadIco
          title={
            <Tooltip
              title="Número de veces que se reproduce tu video hasta el 95% de su duración."
              color="#002448"
              placement="top"
            >
              Reproducciones de video 95%
            </Tooltip>
          }
          position={47}
          width={"150px"}
          selector={"video_playbacks_95"}
        />
      ),
    },
    video_playbacks_100: {
      name: (
        <HeadIco
          title={
            <Tooltip
              title="Número de veces que se reproduce tu video hasta el 100% de su duración."
              color="#002448"
              placement="top"
            >
              Reproducciones de video 100%
            </Tooltip>
          }
          position={48}
          width={"150px"}
          selector={"video_playbacks_100"}
        />
      ),
    },
    adv: {
      name: (
        <HeadIco
          title={
            <Tooltip
              title="Tiempo promedio de reproducción del video en tu anuncio."
              color="#002448"
              placement="top"
            >
              Tiempo promedio de reproducción del video
            </Tooltip>
          }
          position={49}
          width={"150px"}
          selector={"adv"}
        />
      ),
    },
    postShares: {
      name: (
        <HeadIco
          title={
            <Tooltip
              title="Número de veces que se compartió la publicación de Facebook."
              color="#002448"
              placement="top"
            >
              Veces que se compartió la publicación
            </Tooltip>
          }
          position={50}
          width={"150px"}
          selector={"postShares"}
        />
      ),
    },
    postComments: {
      name: (
        <HeadIco
          title={
            <Tooltip
              title="Número de comentarios en tus anuncios de Facebook."
              color="#002448"
              placement="top"
            >
              Comentarios en la publicación
            </Tooltip>
          }
          position={51}
          width={"150px"}
          selector={"postComments"}
        />
      ),
    },
    postLikes: {
      name: (
        <HeadIco
          title={
            <Tooltip
              title="Número de reacciones en tus anuncios de Facebook."
              color="#002448"
              placement="top"
            >
              Reaciones de la publicación
            </Tooltip>
          }
          position={52}
          width={"150px"}
          selector={"postLikes"}
        />
      ),
    },
  };

  let columnsExa = columnsDashboard.map((element) => {
    const mappingElementColumn = columnMappings[element.id];
    if (mappingElementColumn) {
      return { ...element, ...mappingElementColumn };
    }
    return element;
  });

  const editCategories = async (value) => {
    let { optionsSelect, id, column } = value[0];
    let catAt = column?.categoriesSelected
      ?.filter((ele) => ele.padre !== id)
      ?.map((cat) => cat.label)
      .filter(Boolean);

    if (catAt.length !== 0) {
      optionsSelect = [...optionsSelect, ...catAt];
    } else {
      optionsSelect = [...optionsSelect];
    }
    var arrSinDuplicaciones = optionsSelect?.filter(
      (x, i) => optionsSelect?.indexOf(x) === i
    );

    let categoriasSelected = [];
    let bodyCats = [];
    if (arrSinDuplicaciones.length !== 0) {
      arrSinDuplicaciones.forEach((ele) => {
        let catEnc = props.categories.find((cat) => cat.name === ele);
        categoriasSelected.push(catEnc);
      });
    }
    if (categoriasSelected.length !== 0) {
      categoriasSelected.forEach((ele) => {
        // let nombrePadre = ele.padre === id ? va
        let nombrePadre = props.categories.find(
          (cates) => cates.id === ele.padre
        );
        let body = {
          label: ele.name,
          nombrePadre: nombrePadre.name,
          padre: nombrePadre.id,
          value: ele.id,
        };
        bodyCats.push(body);
      });
    }

    let valor = [
      {
        id: "optionSelected",
        column: value[0].column,
        value: bodyCats,
      },
    ];
    editData(valor);
  };

  useEffect(() => {
    const activeCategories = props.categories?.filter(
      (item) =>
        item.active &&
        (item.padre === "97bc9ee5-36a0-455b-974c-fa7832c9c341" ||
          item.padre === "")
    );

    if (Object.entries(actualState).length === 0) {
      return;
    }

    const sortedActiveCategories = _.orderBy(
      activeCategories,
      ["name"],
      ["asc"]
    );
    setCategoriesSorted(sortedActiveCategories);

    if (sortedActiveCategories.length !== 0) {
      if (sortedActiveCategories[0].name !== "Categoria por defecto") {
        if (!cargarView && !viewCount) {
          setCargarView(true);
          actView();
        }
      } else {
        setCargarView(false);
      }
    }

    const columnsCategories = sortedActiveCategories.map((cat) => {
      const optionsCats = props.categories.filter(
        (ele) => ele.padre === cat.id && ele.active
      );
      const options = optionsCats.map((ele) => ele.name);

      const column = {
        id: cat.id,
        name: cat.name,
        selector: cat.id,
        sortable: false,
        tag: cat.id,
        type: "text",
        center: true,
        minWidth: "250px",
        omit: false,
        nameCol: cat.name,
        cell: (row) => {
          const value = row.categoriesSelected
            ? row.categoriesSelected
                .filter((ele) => ele.padre === cat.id)
                .map((ele) => ele.label)
            : [];

          return (
            <EditAnt
              nameColumn={row}
              id={cat.id}
              edit={false}
              defaultValue={value}
              onEditValue={editCategories}
              options={options}
              placeholder="Selecciona una opción"
              style={{ width: "250px" }}
              texto={
                row[cat.id] !== undefined
                  ? row[cat.id].length < 10
                    ? row[cat.id]
                    : row[cat.id].substr(0, 10) + "..."
                  : "..."
              }
              optionalValue={cat.name}
            />
          );
        },
      };
      return column;
    });

    const columnsCopy = [...columnsExa, ...columnsCategories];
    setColumns1(columnsCategories);
    setColsBack(columnsCopy);
    const optionsDefault = [
      "Objetivo",
      "Medio",
      "Pais",
      "Tipo de compra",
      "Banner",
      "Estado",
      "Orden de venta",
      "Nombre de presupuesto",
      "formato",
    ];
    const sortedCatNames = sortedActiveCategories
      .map((cat) => cat.name)
      .concat(optionsDefault)
      .sort();
    const sortedCatNamesWithDefault = [...sortedCatNames];
    if (optionss.length === 0) {
      setOptionss(sortedCatNamesWithDefault);
    }

    if (namesCate.length === 0) {
      setNamesCate(sortedCatNames.map((ele) => ele));
    }
  }, [props.categories, props.budget_spent, props.budgets, actualState]);

  const handleSelectColumn = (columnsSelect, colsReference) => {
    let currentNames = colsReference
      .map((ele) => ele?.selector)
      .filter(Boolean);

    let viewNames = columnsSelect.map((elementos) => {
      handleShowColumnChange(elementos.selector, elementos.omit);
      return elementos.selector;
    });

    currentNames.forEach((ele) => {
      if (!viewNames.includes(ele)) {
        handleShowColumnChange(ele, true);
      }
    });

    let ordenView = columnsSelect
      .filter((elementos) => currentNames.includes(elementos.selector))
      .sort((a, b) => a.position - b.position);

    handleColumnSortChange(ordenView.map((ele) => ele.id));
  };

  useEffect(() => {
    if (!cargarView) {
      return;
    }
    setColumns(colsBack);
    if (sharedView.id != undefined) {
      let columnsShared = sharedView.columns;
      setShowAdd(true);
      handleSelectColumn(columnsShared, colsBack);
      if (sharedView.dateRange !== null) {
        // handleCalendarTextChange(sharedView.dateRange);
      }
      if (sharedView.filteredText !== "") {
        setValueFilterText(sharedView.filteredText);
      }
    } else {
      // if (dateFilter) handleCalendarTextChange(dateFilter);
      let vistaAc = views.find((ele) => ele.active);
      setNameView(vistaAc?.name || "Lista");
      if (vistaAc) {
        handleSelectColumn(vistaAc.columns, colsBack);

        if (
          vistaAc.filteredText !== undefined &&
          vistaAc.filteredText !== null &&
          vistaAc.filteredText !== ""
        ) {
          setValueFilterText(vistaAc.filteredText);
        } else {
          if (vistaAc.dataRange !== undefined && vistaAc.dateRange !== null) {
            // handleCalendarTextChange(vistaAc.dateRange);
          }
        }
      }
    }
    setCargarView(false);
  }, [colAct, cargarView]);

  const actView = async () => {
    if (Auth.user) {
      let sub = Auth.user.signInUserSession.idToken.payload.sub;
      let viewsData = await API.getViewsByUser({
        account: account,
        user: sub,
        component: "campaign",
      });
      let dataVie = viewsData.data.views;
      if (viewValue !== "default") {
        let findIdView = dataVie.find(({ id }) => id === viewValue);
        if (!findIdView)
          return history.replace(
            `/campañas/account=${props.currentAccount}&view=list&group=default&viewColumn=default&page=1`
          );
      }
      if (dataVie.length === 0) {
        changeUrl({
          groupParams: groupValue,
          viewColumnParams: "default",
          pageParams: currentPage,
          viewParams: Number(defaultVista),
          inicial: dateInvalid(parseInit) ? formattedDate : fechaIni,
          final: dateInvalid(parseFin) ? formatToday : fechaFin,
        });
      } else {
        let activeView = dataVie.find((ele) => ele.active);
        if (activeView) {
          changeUrl({
            groupParams: groupValue,
            viewColumnParams: activeView.id,
            viewParams: Number(defaultVista),
            pageParams: currentPage,
            inicial: dateInvalid(parseInit) ? formattedDate : fechaIni,
            final: dateInvalid(parseFin) ? formatToday : fechaFin,
          });
        } else {
          changeUrl({
            groupParams: groupValue,
            pageParams: currentPage,
            viewColumnParams: "default",
            viewParams: Number(defaultVista),
            inicial: dateInvalid(parseInit) ? formattedDate : fechaIni,
            final: dateInvalid(parseFin) ? formatToday : fechaFin,
          });
        }
      }
      setViews(dataVie);
      setCargarView(true);
      setColAct(dataVie);
      setviewCount(true);
    }
  };

  const actualizarViews = async (tipo) => {
    let sub = "";
    try {
      sub = Auth.user.signInUserSession.idToken.payload.sub;
    } catch (error) {
      sub = props.currentUser;
    }
    let viewsData = await API.getViewsByUser({
      account: account,
      user: sub,
      component: "campaign",
    });
    let dataVie = viewsData.data.views;
    if (dataVie.length === 0) {
      changeUrl({
        groupParams: groupValue,
        viewColumnParams: "default",
        viewParams: Number(defaultVista),
        pageParams: currentPage,
        inicial: dateInvalid(parseInit) ? formattedDate : fechaIni,
        final: dateInvalid(parseFin) ? formatToday : fechaFin,
      });
    } else {
      let activeView = dataVie.find((ele) => ele.active);
      if (activeView) {
        changeUrl({
          groupParams: groupValue,
          viewColumnParams: activeView.id,
          viewParams: Number(defaultVista),
          pageParams: currentPage,
          inicial: dateInvalid(parseInit) ? formattedDate : fechaIni,
          final: dateInvalid(parseFin) ? formatToday : fechaFin,
        });
      } else {
        changeUrl({
          inicial: dateInvalid(parseInit) ? formattedDate : fechaIni,
          final: dateInvalid(parseFin) ? formatToday : fechaFin,
          groupParams: groupValue,
          viewColumnParams: "default",
          pageParams: currentPage,
          viewParams: Number(defaultVista),
        });
      }
    }
    setViews(dataVie);
    if (tipo) {
      setColAct(dataVie);
    }
  };

  useEffect(() => {
    setActualState({
      place: "dias",
      data: [],
    });

    let fechaFin = new Date();
    let fechaIn = new Date();
    fechaIn.setDate(fechaIn.getDate() - 7);
    const validateDateInit = dateInvalid(parseInit);
    const validateDateFin = dateInvalid(parseFin);
    let fechaIni = new Date(fechaIn);
    if (sharedView.id === undefined) {
      setDatesToShow({
        fechaIni: !validateDateInit ? parseInit : fechaIni,
        fechaFin: !validateDateFin ? parseFin : fechaFin,
      });
    } else {
      setDatesToShow({
        fechaIni: sharedView.dates.fechaIni,
        fechaFin: sharedView.dates.fechaFin,
      });
    }

    var camps = props.sendCampaignss;
    for (let i = 0; i < camps.length; i++) {
      if (camps[i].categoriesSelected) {
        var categories = camps[i].categoriesSelected;

        const categoriesGruped = categories.reduce(
          (groups, item) => ({
            ...groups,
            [item.nombrePadre]: [...(groups[item.nombrePadre] || []), item],
          }),
          {}
        );

        let valores = Object.values(categoriesGruped);

        for (let j = 0; j < valores.length; j++) {
          let catsString = "";
          for (let k = 0; k < valores[j].length; k++) {
            catsString += valores[j][k].label;
            if (k < valores[j].length - 1) {
              catsString += ", ";
            }
          }
          catsString = catsString.trim().replace(/,\s*$/, "");
          let body = { [valores[j][0].padre]: catsString };
          camps[i] = { ...camps[i], ...body };
        }
      }
    }
    if (camps.length) {
      let totales = calculateTotal(camps);
      setBackupData(camps);
      if (searchInput?.length > 0) {
      } else {
        setFilteredValues(camps);
      }
      setTotalRow(totales);
    }
    if (groupValue !== "default") {
      changeGrouped(groupValue);
    }
    //Fin categorias en columndas
  }, [sendCampaignss]);

  const getValueTotal = (row) => {
    let valueToGet;
    if (row?.columnaCompare === "post_likes") {
      valueToGet = 100 * new Number(row.post_likes);
    } else if (row?.columnaCompare === "page_comments") {
      valueToGet = 100 * new Number(row.postComments);
    } else if (row?.columnaCompare === "page_shares") {
      valueToGet = 100 * new Number(row.postShares);
    } else if (row?.columnaCompare === "video_avg_time") {
      valueToGet = 100 * new Number(row.adv);
    } else if (row?.columnaCompare === "video_playbacks_100") {
      valueToGet = 100 * new Number(row.video_playbacks_100);
    } else if (row?.columnaCompare === "video_playbacks_95") {
      valueToGet = 100 * new Number(row.video_playbacks_95);
    } else if (row?.columnaCompare === "video_playbacks_75") {
      valueToGet = 100 * new Number(row.video_playbacks_75);
    } else if (row?.columnaCompare === "video_playbacks_50") {
      valueToGet = 100 * new Number(row.video_playbacks_50);
    } else if (row?.columnaCompare === "video_playbacks_25") {
      valueToGet = 100 * new Number(row.video_playbacks_25);
    } else if (row?.columnaCompare === "views") {
      valueToGet = 100 * new Number(row.views);
    } else if (row?.columnaCompare === "reach") {
      valueToGet = 100 * new Number(row.reach);
    } else if (row?.columnaCompare === "fans") {
      valueToGet = 100 * new Number(row.fans);
    } else if (row?.columnaCompare === "clicks") {
      valueToGet = 100 * new Number(row.clicks);
    } else if (row?.columnaCompare === "prints") {
      valueToGet = 100 * new Number(row.prints);
    } else if (row?.columnaCompare === "frequency") {
      valueToGet = 100 * new Number(row.frequency);
    } else if (row?.columnaCompare === "estimated_ad_recallers") {
      valueToGet = 100 * new Number(row.estimated_ad_recallers);
    } else if (row?.columnaCompare === "mobile_app_install") {
      valueToGet = 100 * new Number(row.installs);
    } else if (row?.columnaCompare === "event_responses") {
      valueToGet = 100 * new Number(row.event_responses);
    } else if (row?.columnaCompare === "leads") {
      valueToGet = 100 * new Number(row.leads);
    } else if (row?.columnaCompare === "photo_view") {
      valueToGet = 100 * new Number(row.photo);
    } else {
      valueToGet = 0;
    }
    return valueToGet;
  };

  const getPercentValue = (row) => {
    if (row.meta > 0) return "NA";

    let valueToGet = getValueTotal(row);

    if (valueToGet === 0) return 0;
    if (!valueToGet) return "NA";
    let final = valueToGet / row.meta;
    return row.meta * (final / 100);
  };

  const getPercentNumber = (row) => {
    if (row.meta == 0) return "NA";

    let valueToGet = getValueTotal(row);

    if (valueToGet === 0) return 0;
    if (!valueToGet) return "NA";
    let final = valueToGet / row.meta;
    return final;
  };

  const getPercentExport = (row) => {
    if (row != undefined) return "NA";
    if (row?.meta == 0) return "NA";

    let valueToGet = getValueTotal(row);

    if (valueToGet === 0) return "0%";
    if (!valueToGet) return "NA";

    let final = valueToGet / row.meta;
    return row.meta * (final / 100) + "/" + final + "%";
  };

  const getTotalPercentage = (value) => {
    let valueSplitter = value.split("%");
    return valueSplitter[0] + "%";
  };

  function formatValor(sele, data) {
    const optionValidates = [
      "budgetI",
      "budget",
      "budgetConsumido",
      "swc",
      "budgetSinConsumir",
      "cpl",
      "cpr",
      "cpi",
      "cpc",
      "cpm",
      "cpf",
    ];
    //opciones con $ 3:
    const opcionsValites2 = ["cpa", "cpv", "cprm"];

    if (optionValidates.includes(sele)) {
      return "$ " + data[sele];
    } else if (opcionsValites2.includes(sele)) {
      return "$ 3:" + data[sele];
    } else if (sele === "columnaCompare") {
      return getPercentExport(data[sele]);
    } else if (sele === "segmentation") {
      return audienceComponent1(data[sele]);
    } else if (sele === "fechaIni" || sele === "fechaFin") {
      if (sele === "fechaFin") sele = "fechafin";
      return data[sele].replace(/\./g, "/");
    }
    return data[sele];
  }

  const exportDataToExcel = () => {
    let headers = [];
    let dataExcel = [];
    let selectors = [];
    let excelData = [];
    var filteredColumns = columns
      .filter(function (col) {
        return col?.omit === false || col?.excel === true;
      })
      .filter(Boolean);
    filteredColumns = filteredColumns.filter((ele) => ele.id !== "year");
    filteredColumns.forEach((element) => {
      headers.push(element.nameCol);
      selectors.push(element.tag);
    });

    //Este for es para data

    let dataExcelFilter = filteredData;
    if (dataExcelFilter.length > 0) {
      if (nameGrouped !== "" && nameSelectedGrouped !== "select") {
        //agrupacion de columnas ya definifidas
        const uniqueValues = [
          ...new Set(dataExcelFilter?.map((item) => item[nameGrouped])),
        ];
        uniqueValues.forEach((value) => {
          const isStatus = nameGrouped === "status";
          const headerValue = isStatus ? getStatusLabel(value) : value;
          dataExcel.push([headerValue], headers);

          const groupedData = dataExcelFilter.filter(
            (item) => item[nameGrouped] === value
          );

          if (uniqueValues.length > 0) {
            groupedData.forEach((data) => {
              const row = selectors.map((sele) => formatValor(sele, data));
              dataExcel.push(row);
            });
            let totales = calculateTotal(groupedData);
            let finalTotal = calculateTotalExcel(totales, selectors);
            dataExcel.push(finalTotal, []);
          } else {
            dataExcel.push(headers);
          }
        });
      } else if (nameSelectedGrouped === "select") {
        //agrupaciones por categorias
        const etiquetasUnicas = [
          ...new Set(
            catSelected?.flatMap((obj) =>
              obj?.categoriesSelected.map((cat) => {
                if (cat.padre === nameGrouped) {
                  return cat.label;
                }
              })
            )
          ),
        ].filter(Boolean);
        if (etiquetasUnicas.length > 0) {
          etiquetasUnicas.forEach((value) => {
            dataExcel.push([value], headers);
            let gropupedData = catSelected
              .map((obj) => {
                if (
                  obj.categoriesSelected.find(
                    (ele) => ele.padre === nameGrouped
                  )
                ) {
                  return obj;
                }
              })
              .filter(Boolean);
            //esta es la data con la que se hace el map
            gropupedData.forEach((data) => {
              const row = selectors.map((sele) => formatValor(sele, data));
              dataExcel.push(row);
            });

            let totales = calculateTotal(gropupedData);
            let finalTotal = calculateTotalExcel(totales, selectors);
            dataExcel.push(finalTotal, []);
          });
        } else {
          dataExcel.push(headers);
        }
      } else {
        dataExcel.push(headers);
        dataExcelFilter.forEach((element) => {
          excelData = [];
          for (let k = 0; k < selectors.length; k++) {
            excelData.push(
              selectors[k] == "budgetI"
                ? "$ " + element[selectors[k]]
                : selectors[k] == "budget"
                ? "$ " +
                  (element.budgetNeto ? element.budgetNeto : element.net_budget)
                : selectors[k] == "budgetConsumido"
                ? "$ " + element[selectors[k]]
                : selectors[k] == "swc"
                ? "$ " + element[selectors[k]]
                : selectors[k] == "budgetSinConsumir"
                ? "$ " + element[selectors[k]]
                : selectors[k] == "spent_percent"
                ? element[selectors[k]] + " %"
                : selectors[k] == "ctr"
                ? element[selectors[k]] + " %"
                : selectors[k] == "cpl"
                ? "$ " + element[selectors[k]]
                : selectors[k] == "cpi"
                ? "$ " + element[selectors[k]]
                : selectors[k] == "cpr"
                ? "$ " + element[selectors[k]]
                : selectors[k] == "cpa"
                ? "$ 3:" + element[selectors[k]]
                : selectors[k] == "cpc"
                ? "$ " + element[selectors[k]]
                : selectors[k] == "cpv"
                ? "$ 3:" + element[selectors[k]]
                : selectors[k] == "cpm"
                ? "$ " + element[selectors[k]]
                : selectors[k] == "cpf"
                ? "$ " + element[selectors[k]]
                : selectors[k] == "cprm"
                ? "$ 3:" + element[selectors[k]]
                : selectors[k] == "meta"
                ? element[selectors[k]]
                : selectors[k] == "columnaCompare"
                ? getPercentExport(element[selectors[k]])
                : selectors[k] == "estimated_ad_recallers"
                ? element[selectors[k]]
                : selectors[k] == "segmentation"
                ? audienceComponent1(element.idAudience)
                : selectors[k] == "fechaIni"
                ? element["fechaIni"].replace(/\./g, "/") + " "
                : selectors[k] == "fechaFin"
                ? element["fechafin"].replace(/\./g, "/") + " "
                : element[selectors[k]]
            );
          }
          dataExcel.push(excelData);
        });
        let totales = calculateTotal(dataExcelFilter);
        let finalTotal = calculateTotalExcel(totales, selectors);
        dataExcel.push(finalTotal);
      }
    } else {
      dataExcel.push(headers);
    }
    return dataExcel;
  };

  const calculateTotalExcel = (data, selectors) => {
    let totalesColumn = [];
    data?.forEach((element) => {
      for (let k = 0; k < selectors.length; k++) {
        totalesColumn.push(
          selectors[k] == "budgetI"
            ? "$ " + element[selectors[k]]
            : selectors[k] == "budget"
            ? "$ " + element.budget
            : selectors[k] == "installs"
            ? element.installs
            : selectors[k] == "budgetConsumido"
            ? "$ " + element[selectors[k]]
            : selectors[k] == "swc"
            ? "$ " + element[selectors[k]]
            : selectors[k] == "budgetSinConsumir"
            ? "$ " + element[selectors[k]]
            : selectors[k] == "spent_percent"
            ? element[selectors[k]] + " %"
            : selectors[k] == "ctr"
            ? element[selectors[k]] + " %"
            : selectors[k] == "cpl"
            ? "$ " + element[selectors[k]]
            : selectors[k] == "cpi"
            ? "$ " + element[selectors[k]]
            : selectors[k] == "cpr"
            ? "$ " + element[selectors[k]]
            : selectors[k] == "cpa"
            ? "$ 3:" + element[selectors[k]]
            : selectors[k] == "cpc"
            ? "$ " + element[selectors[k]]
            : selectors[k] == "cpv"
            ? "$ 3:" + element[selectors[k]]
            : selectors[k] == "cpm"
            ? "$ " + element[selectors[k]]
            : selectors[k] == "cpf"
            ? "$ " + element[selectors[k]]
            : selectors[k] == "cprm"
            ? "$ 3:" + element[selectors[k]]
            : selectors[k] == "meta"
            ? element["metaTotales"]
            : selectors[k] == "budgetNeto"
            ? element["budgetNeto"]
            : selectors[k] == "columnaCompare"
            ? getTotalPercentage(element["totalPercentage"])
            : selectors[k] == "estimated_ad_recallers"
            ? element[selectors[k]]
            : element[selectors[k]]
        );
      }
    });
    return totalesColumn;
  };

  const changeView = (value) => {
    const analyticsMappings = {
      0: "Vista de Kanban",
      1: "Vista de lista",
      2: "Vista de calendario",
    };

    analytics.opciones_vista({
      busqueda: analyticsMappings[value] || "Vista desconocida",
    });

    changeUrl({
      viewParams: value,
      inicial: dateInvalid(parseInit) ? formattedDate : fechaIni,
      final: dateInvalid(parseFin) ? formatToday : fechaFin,
      groupParams: groupValue,
      pageParams: currentPage,
      viewColumnParams: viewValue,
    });

    setState((prev) => ({
      ...prev,
      data: value,
    }));
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
    changeUrl({
      groupParams: groupValue,
      pageParams: page,
      viewColumnParams: viewValue,
      viewParams: Number(defaultVista),
      inicial: dateInvalid(parseInit) ? formattedDate : fechaIni,
      final: dateInvalid(parseFin) ? formatToday : fechaFin,
    });
  };

  const clean = async () => {
    //closeModal()
    props._cleanSteps();
    await refreshData();
    props._showLoader(false);
  };

  const getProfileInfo = async () => {
    try {
      const userData = await Auth.currentAuthenticatedUser();
      const token = userData.signInUserSession.accessToken.jwtToken;
      const response = await APILA.getProfileInfo(token);
      const attributes = response.data.data.UserAttributes;
      const nameAttribute = attributes.find(
        (attribute) => attribute.Name === "name"
      );
      const emailAttribute = attributes.find(
        (attribute) => attribute.Name === "email"
      );
    } catch (err) {
      console.log("getProfileInfo error:", err);
    }
  };

  function numberWithCommas(x) {
    let auxNumber = new Number(x).toFixed(2);
    return auxNumber.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  const approveConfirmation = async (campaignupd) => {
    let username = await Auth.currentAuthenticatedUser();
    let accounts = await APILA.getAccounts(username.username);
    let findAccount = accounts.data.accounts.find(
      (item) => item.id === campaignupd.account
    );
    analytics.accionesTabla({
      accion: "Aprobar",
      ubicacion: "Dashboard",
    });

    props._funcampaignupd(campaignupd);
    if (campaignupd.status === 1) {
      const responseModal = await props._validateHoursAvailable(
        props.currentAccount,
        accounts.data.accounts,
        campaignupd.fechaIni,
        campaignupd.priority,
        false
      );
      if (responseModal) {
        return setShowModalPriority(true);
      }
    }

    props._showConfirmationModal(
      "¿Aprobar Pauta?",
      `¿Está seguro que desea aprobar esta pauta?`,
      async () => {
        props._showLoader(true);

        const querys = [
          API.getCampaignById(campaignupd.idMixDetaill),
          APILA.getDefaultEtiqueta(),
          APILA.getEtiquetasByAccount({
            account: props.account,
          }),
          Auth.currentAuthenticatedUser(),
        ];
        const priorityMessages = {
          1: {
            available: findAccount.priorityRecord.availableCritical,
            count: findAccount.priorityRecord.countCritical,
            message: "crítica",
          },
          2: {
            available: findAccount.priorityRecord.availableUrgent,
            count: findAccount.priorityRecord.countUrgent,
            message: "urgente",
          },
        };

        const priorityData = priorityMessages[campaignupd.priority];
        if (campaignupd.priority !== 3) {
          if (
            priorityData &&
            (priorityData.available === priorityData.count ||
              priorityData.count > priorityData.available)
          ) {
            return {
              alert: Swal.fire({
                title: "Información",
                html: `Ya no contiene campañas disponibles con prioridad <strong>${priorityData.message}</strong>. Por favor, adquiere más campañas con esta prioridad.`,
                icon: "info",
                showCancelButton: false,
                confirmButtonColor: "#3085d6",
                confirmButtonText: "Ok",
              }),
              loader: props._showLoader(false),
            };
          } else if (campaignupd.priority !== 3) {
            await API.updatePriority({
              id: campaignupd.account,
              type: campaignupd.priority,
            });
          }
        }

        props._showLoader(true);

        const [responsee, responseDefault, etiquetasAccountt, userData] =
          await Promise.all(querys);

        let defaultCategoriee = responseDefault.data.defaultEtiqueta[0];
        let etiquetass = etiquetasAccountt.data.etiquetas;
        let chosenTagg;
        let etiquetaFoundd = false;

        const { username, signInUserSession } = userData;

        const token = signInUserSession.accessToken.jwtToken;
        const response = await APILA.getProfileInfo(token);

        const attributes = response.data.data.UserAttributes;

        const name = find(attributes, { Name: "name" });
        const email = find(attributes, { Name: "email" });

        const nameToSend = name?.Value ?? "";
        const emailToSend = email?.Value ?? "";

        etiquetass.forEach((el) => {
          if (el.state === 1) {
            chosenTagg = el;
            etiquetaFoundd = true;
          }
        });

        if (etiquetaFoundd === false) {
          chosenTagg = defaultCategoriee;
        }

        let finalEtiquetaa = "";

        chosenTagg.columnsSelected.forEach((el) => {
          if (el.includes("categorie:")) {
            let value = el.split(":");
            let found = props.categories.filter(
              (eleCat) => eleCat.name === value[1]
            );
            if (found.length > 0) {
              if (campaignupd?.categoriesSelected != undefined) {
                if (campaignupd?.categoriesSelected.length > 0) {
                  let foundOptionSel = campaignupd.categoriesSelected.filter(
                    (eleOption) => eleOption.padre === found[0].id
                  );
                  if (foundOptionSel.length > 0) {
                    foundOptionSel.forEach((eleCat) => {
                      finalEtiquetaa = finalEtiquetaa + eleCat.label + "_";
                    });
                  }
                }
              }
            }
          } else if (el === "client") {
            finalEtiquetaa = finalEtiquetaa + findAccount.name + "_";
          } else if (el === "publico") {
            if (campaignupd.idAudience != undefined) {
              let audienceFound = props.audiences.filter(
                (eleAud) => eleAud.id === campaignupd.idAudience
              );
              if (audienceFound.length > 0) {
                finalEtiquetaa = finalEtiquetaa + audienceFound[0].name + "_";
              }
            }
          } else if (el === "medio") {
            finalEtiquetaa = finalEtiquetaa + campaignupd.medio + "_";
          } else if (el === "banner") {
            finalEtiquetaa = finalEtiquetaa + campaignupd.banner + "_";
          } else if (el === "fechaIni") {
            let fechaBien = campaignupd.fechaIni.split(".");
            finalEtiquetaa =
              finalEtiquetaa +
              fechaBien[0] +
              "/" +
              fechaBien[1] +
              "/" +
              fechaBien[2] +
              "_";
          } else if (el === "fechafin") {
            let fechaBien = campaignupd.fechafin.split(".");
            finalEtiquetaa =
              finalEtiquetaa +
              fechaBien[0] +
              "/" +
              fechaBien[1] +
              "/" +
              fechaBien[2] +
              "_";
          } else if (el === "country") {
            if (campaignupd.country.name) {
              finalEtiquetaa = finalEtiquetaa + campaignupd.country.name + "_";
            } else {
              finalEtiquetaa = finalEtiquetaa + campaignupd.country + "_";
            }
          } else if (el === "budget") {
            finalEtiquetaa =
              finalEtiquetaa +
              "$" +
              numberWithCommas(campaignupd.budgetNeto) +
              "_";
          } else if (el === "swc") {
            finalEtiquetaa =
              finalEtiquetaa + "$" + numberWithCommas(campaignupd.swc) + "_";
          } else if (el === "status") {
            finalEtiquetaa = finalEtiquetaa + getStatusLabel(5) + "_";
          } else {
            if (campaignupd[el]) {
              finalEtiquetaa = finalEtiquetaa + campaignupd[el] + "_";
            }
          }
        });

        finalEtiquetaa = finalEtiquetaa.substring(0, finalEtiquetaa.length - 1);

        if (responsee.data.campaigns.length === 0) {
          props._showLoader(false);
          return Swal.fire({
            title: "Advertencia",
            text: "Esta campaña fue creada fuera de lets advertise, por favor contacta a soporte",
            icon: "warning",
            showCancelButton: false,
            confirmButtonColor: "#3085d6",
            confirmButtonText: "Ok",
          });
        }

        await props
          ._approveCampaign(
            campaignupd.id,
            campaignupd.idMixDetaill,
            campaignupd.name,
            campaignupd.fechaIni,
            campaignupd.fechafin,
            campaignupd.budget,
            campaignupd.id_ad_product,
            5,
            campaignupd.observation,
            campaignupd.copy,
            campaignupd.id_sale,
            campaignupd.name_region,
            campaignupd.objetivo,
            campaignupd.banner,
            campaignupd.medio,
            campaignupd.publico,
            campaignupd.nameForEmail,
            campaignupd.emailForEmail,
            campaignupd.urls,
            campaignupd.version,
            campaignupd.npost,
            responsee.data.campaigns[0],
            campaignupd.priority,
            props.currentAccount,
            campaignupd.idAudience,
            campaignupd.categoriesSelected,
            campaignupd.creativity,
            nameToSend,
            emailToSend,
            finalEtiquetaa,
            campaignupd.formato,
            chosenTagg,
            findAccount,
            searchInput,
            campaignupd.id_digital_asset
          )
          .then(() => {
            clean();
            // props._alertMessage(response.title, response.body)
            API.updateCampaignPriority({
              id: campaignupd.id,
            });
          });
        localStorage.setItem("nameAWS", "");
        localStorage.setItem("tempAWSUser", "");
        localStorage.setItem("account", "");
      }
    );
  };

  const showApproveConfirmation = async (campaignupd) => {
    let username = await Auth.currentAuthenticatedUser();
    let accounts = await APILA.getAccounts(username.username);
    let findAccount = accounts.data.accounts.find(
      (item) => item.id === campaignupd.account
    );
    analytics.accionesTabla({
      accion: "Aprobar",
      ubicacion: "Dashboard",
    });
    getProfileInfo();
    let titulo;
    let message;
    const mAccount = _.find(props.accounts, { id: props.account });

    let step;
    if (mAccount?.step == undefined) {
      step = 0;
    } else {
      step = mAccount.step;
    }
    switch (step) {
      case 1:
        titulo = "Información pendiente";
        message =
          "Es necesario crear una cuenta y asociar una tarjeta, ¿Desea completar la información? ";
        break;
      case 2:
        titulo = "Información pendiente";
        message =
          "Es necesario asociar una tarjeta, ¿Desea asociar una tarjeta? ";
        break;
      case 3:
        titulo = "Información pendiente";
        message = "Es necesario crear una cuenta, ¿Desea crear la cuenta? ";
        break;
      case 4:
        titulo = "¿Aprobar Pauta?";
        message = `¿Está seguro que desea aprobar esta pauta?`;
        break;
      default:
        titulo = "¿Aprobar Pauta?";
        message = `¿Está seguro que desea aprobar esta pauta?`;
    }
    props._showConfirmationModal(titulo, message, async () => {
      let availableUrgent = findAccount.priorityRecord.availableUrgent;
      let availableCritical = findAccount.priorityRecord.availableCritical;
      let countUrgent = findAccount.priorityRecord.countUrgent;
      let countCritical = findAccount.priorityRecord.countCritical;
      if (campaignupd.priority === 1 && availableCritical === countCritical) {
        return Swal.fire({
          title: "Información",
          html: "Ya no contiene campañas disponibles con prioridad <strong>crítica</strong>. Por favor, adquiere más campañas con esta prioridad.",
          icon: "info",
          showCancelButton: false,
          confirmButtonColor: "#3085d6",
          confirmButtonText: "Ok",
        });
      } else if (
        campaignupd.priority === 2 &&
        availableUrgent === countUrgent
      ) {
        return Swal.fire({
          title: "Información",
          html: "Ya no contiene campañas disponibles con prioridad <strong>urgente</strong>. Por favor, adquiere más campañas con esta prioridad.",
          icon: "info",
          showCancelButton: false,
          confirmButtonColor: "#3085d6",
          confirmButtonText: "Ok",
        });
      } else {
        if (campaignupd.priority !== 3) {
          await API.updatePriority({
            id: campaignupd.account,
            type: campaignupd.priority,
          });
        }
      }
      switch (step) {
        case 1:
          window.$.fancybox.open({
            src: "#new-create-cuenta",
            opts: {
              modal: true,
            },
          });
          break;
        case 2:
          window.$.fancybox.open({
            src: "#associate-card",
            opts: {
              modal: true,
            },
          });
          break;
        case 3:
          window.$.fancybox.open({
            src: "#new-create-cuenta",
            opts: {
              modal: true,
            },
          });
          break;
        case 4:
          props._showLoader(true);
          let response = await API.getCampaignById(campaignupd.idMixDetaill);

          let responseDefault = await APILA.getDefaultEtiqueta();
          let etiquetasAccount = await APILA.getEtiquetasByAccount({
            account: mAccount.id,
          });
          let defaultCategorie = responseDefault.data.defaultEtiqueta[0];
          let etiquetas = etiquetasAccount.data.etiquetas;
          let chosenTag;
          let etiquetaFound = false;

          etiquetas.forEach((el) => {
            if (el.state === 1) {
              chosenTag = el;
              etiquetaFound = true;
            }
          });

          if (etiquetaFound === false) {
            chosenTag = defaultCategorie;
          }

          let finalEtiqueta = "";
          chosenTag.columnsSelected.forEach((el) => {
            if (el.includes("categorie:")) {
              let value = el.split(":");
              let found = props.categories.filter(
                (eleCat) => eleCat.name === value[1]
              );
              if (found.length > 0) {
                if (campaignupd.categoriesSelected.length > 0) {
                  let foundOptionSel = campaignupd.categoriesSelected.filter(
                    (eleOption) => eleOption.padre === found[0].id
                  );
                  if (foundOptionSel.length > 0) {
                    foundOptionSel.forEach((eleCat) => {
                      finalEtiqueta = finalEtiqueta + eleCat.label + "_";
                    });
                  }
                }
              }
            } else if (el === "client") {
              finalEtiqueta = finalEtiqueta + mAccount.name + "_";
            } else if (el === "publico") {
              if (campaignupd.idAudience != undefined) {
                let audienceFound = props.audiences.filter(
                  (eleAud) => eleAud.id === campaignupd.idAudience
                );
                if (audienceFound.length > 0) {
                  finalEtiqueta = finalEtiqueta + audienceFound[0].name + "_";
                }
              }
            } else if (el === "medio") {
              finalEtiqueta = finalEtiqueta + campaignupd.medio + "_";
            } else if (el === "banner") {
              finalEtiqueta = finalEtiqueta + campaignupd.banner + "_";
            } else if (el === "fechaIni") {
              let fechaBien = campaignupd.fechaIni.split(".");
              finalEtiqueta =
                finalEtiqueta +
                fechaBien[0] +
                "/" +
                fechaBien[1] +
                "/" +
                fechaBien[2] +
                "_";
            } else if (el === "fechafin") {
              let fechaBien = campaignupd.fechafin.split(".");
              finalEtiqueta =
                finalEtiqueta +
                fechaBien[0] +
                "/" +
                fechaBien[1] +
                "/" +
                fechaBien[2] +
                "_";
            } else if (el === "country") {
              if (campaignupd.country.name) {
                finalEtiqueta = finalEtiqueta + campaignupd.country.name + "_";
              } else {
                finalEtiqueta = finalEtiqueta + campaignupd.country + "_";
              }
            } else if (el === "budget") {
              finalEtiqueta =
                finalEtiqueta +
                "$" +
                numberWithCommas(campaignupd.budgetNeto) +
                "_";
            } else if (el === "swc") {
              finalEtiqueta =
                finalEtiqueta + "$" + numberWithCommas(campaignupd.swc) + "_";
            } else if (el === "status") {
              finalEtiqueta = finalEtiqueta + getStatusLabel(5) + "_";
            } else {
              if (campaignupd[el]) {
                finalEtiqueta = finalEtiqueta + campaignupd[el] + "_";
              }
            }
          });
          finalEtiqueta = finalEtiqueta.substring(0, finalEtiqueta.length - 1);

          if (response.data.campaigns.length > 0) {
            Auth.currentAuthenticatedUser().then((userData) => {
              let { signInUserSession } = userData;
              let token = signInUserSession.accessToken.jwtToken;

              APILA.getProfileInfo(token)
                .then((response) => {
                  let attributes = response.data.data.UserAttributes;

                  let name = find(attributes, { Name: "name" });
                  let email = find(attributes, { Name: "email" });
                  let nameToSend = name.Value;
                  let emailToSend = email.Value;
                  props
                    ._updateCampaign(
                      nameToSend,
                      emailToSend,
                      campaignupd.id,
                      campaignupd.name,
                      campaignupd.medio,
                      campaignupd.fechaIni,
                      campaignupd.fechafin,
                      campaignupd.objetivo,
                      campaignupd.budget,
                      campaignupd.publico,
                      campaignupd.idMixDetaill,
                      campaignupd.idMixChange,
                      campaignupd.idMix,
                      campaignupd.idCampaign,
                      campaignupd.id_medium,
                      campaignupd.medioo,
                      campaignupd.id_ad_product,
                      campaignupd.banner,
                      5,
                      campaignupd.idBudget,
                      mAccount,
                      campaignupd.creativity,
                      campaignupd.observation,
                      campaignupd.copy,
                      campaignupd.id_sale,
                      campaignupd.id_region,
                      campaignupd.name_region,
                      campaignupd.nameForEmail,
                      campaignupd.emailForEmail,
                      campaignupd.urls,
                      campaignupd.availableAmount,
                      0,
                      campaignupd.version,
                      campaignupd.npost,
                      props.budgets,
                      props.campaigns,
                      props.categories, //categories
                      campaignupd.idAudience,
                      campaignupd.categoriesSelected,
                      chosenTag,
                      finalEtiqueta,
                      campaignupd.formato,
                      "",
                      campaignupd.priority
                    )
                    .then(() => {
                      clean();
                    });
                  localStorage.setItem("nameAWS", "");
                  localStorage.setItem("tempAWSUser", "");
                  localStorage.setItem("account", "");
                })
                .catch((err) => {
                  console.log("getProfileInfo", err);
                })
                .then(() => {
                  // props._showLoader(false)
                });
            });
          } else {
            props._showLoader(false);
            Swal.fire({
              title: "Advertencia",
              text: "Esta campaña fue creada fuera de lets advertise, por favor contacta a soporte",
              icon: "warning",
              showCancelButton: false,
              confirmButtonColor: "#3085d6",
              confirmButtonText: "Ok",
            });
          }

          break;
        default:
          props._showLoader(true);
          let responsee = await API.getCampaignById(campaignupd.idMixDetaill);

          let responseDefaultt = await APILA.getDefaultEtiqueta();
          let etiquetasAccountt = await APILA.getEtiquetasByAccount({
            account: mAccount.id,
          });
          let defaultCategoriee = responseDefaultt.data.defaultEtiqueta[0];
          let etiquetass = etiquetasAccountt.data.etiquetas;
          let chosenTagg;
          let etiquetaFoundd = false;

          etiquetass.forEach((el) => {
            if (el.state === 1) {
              chosenTagg = el;
              etiquetaFoundd = true;
            }
          });

          if (etiquetaFoundd === false) {
            chosenTagg = defaultCategoriee;
          }

          let finalEtiquetaa = "";
          chosenTagg.columnsSelected.forEach((el) => {
            if (el.includes("categorie:")) {
              let value = el.split(":");
              let found = props.categories.filter(
                (eleCat) => eleCat.name === value[1]
              );
              if (found.length > 0) {
                if (campaignupd?.categoriesSelected != undefined) {
                  if (campaignupd?.categoriesSelected.length > 0) {
                    let foundOptionSel = campaignupd.categoriesSelected.filter(
                      (eleOption) => eleOption.padre === found[0].id
                    );
                    if (foundOptionSel.length > 0) {
                      foundOptionSel.forEach((eleCat) => {
                        finalEtiquetaa = finalEtiquetaa + eleCat.label + "_";
                      });
                    }
                  }
                }
              }
            } else if (el === "client") {
              finalEtiquetaa = finalEtiquetaa + mAccount.name + "_";
            } else if (el === "publico") {
              if (campaignupd.idAudience != undefined) {
                let audienceFound = props.audiences.filter(
                  (eleAud) => eleAud.id === campaignupd.idAudience
                );
                if (audienceFound.length > 0) {
                  finalEtiquetaa = finalEtiquetaa + audienceFound[0].name + "_";
                }
              }
            } else if (el === "medio") {
              finalEtiquetaa = finalEtiquetaa + campaignupd.medio + "_";
            } else if (el === "banner") {
              finalEtiquetaa = finalEtiquetaa + campaignupd.banner + "_";
            } else if (el === "fechaIni") {
              let fechaBien = campaignupd.fechaIni.split(".");
              finalEtiquetaa =
                finalEtiquetaa +
                fechaBien[0] +
                "/" +
                fechaBien[1] +
                "/" +
                fechaBien[2] +
                "_";
            } else if (el === "fechafin") {
              let fechaBien = campaignupd.fechafin.split(".");
              finalEtiquetaa =
                finalEtiquetaa +
                fechaBien[0] +
                "/" +
                fechaBien[1] +
                "/" +
                fechaBien[2] +
                "_";
            } else if (el === "country") {
              if (campaignupd.country.name) {
                finalEtiquetaa =
                  finalEtiquetaa + campaignupd.country.name + "_";
              } else {
                finalEtiquetaa = finalEtiquetaa + campaignupd.country + "_";
              }
            } else if (el === "budget") {
              finalEtiquetaa =
                finalEtiquetaa +
                "$" +
                numberWithCommas(campaignupd.budgetNeto) +
                "_";
            } else if (el === "swc") {
              finalEtiquetaa =
                finalEtiquetaa + "$" + numberWithCommas(campaignupd.swc) + "_";
            } else if (el === "status") {
              finalEtiquetaa = finalEtiquetaa + getStatusLabel(5) + "_";
            } else {
              if (campaignupd[el]) {
                finalEtiquetaa = finalEtiquetaa + campaignupd[el] + "_";
              }
            }
          });
          finalEtiquetaa = finalEtiquetaa.substring(
            0,
            finalEtiquetaa.length - 1
          );

          if (responsee.data.campaigns.length > 0) {
            Auth.currentAuthenticatedUser().then((userData) => {
              let { signInUserSession } = userData;
              let token = signInUserSession.accessToken.jwtToken;

              APILA.getProfileInfo(token)
                .then((response) => {
                  let attributes = response.data.data.UserAttributes;

                  let name = find(attributes, { Name: "name" });
                  let email = find(attributes, { Name: "email" });
                  let nameToSend = name.Value;
                  let emailToSend = email.Value;
                  props
                    ._updateCampaign(
                      nameToSend,
                      emailToSend,
                      campaignupd.id,
                      campaignupd.name,
                      campaignupd.medio,
                      campaignupd.fechaIni,
                      campaignupd.fechafin,
                      campaignupd.objetivo,
                      campaignupd.budget,
                      campaignupd.publico,
                      campaignupd.idMixDetaill,
                      campaignupd.idMixChange,
                      campaignupd.idMix,
                      campaignupd.idCampaign,
                      campaignupd.id_medium,
                      campaignupd.medioo,
                      campaignupd.id_ad_product,
                      campaignupd.banner,
                      5,
                      campaignupd.idBudget,
                      mAccount,
                      campaignupd.creativity,
                      campaignupd.observation,
                      campaignupd.copy,
                      campaignupd.id_sale,
                      campaignupd.id_region,
                      campaignupd.name_region,
                      campaignupd.nameForEmail,
                      campaignupd.emailForEmail,
                      campaignupd.urls,
                      campaignupd.availableAmount,
                      0,
                      campaignupd.version,
                      campaignupd.npost,
                      props.budgets,
                      props.campaigns,
                      props.categories, //categories
                      campaignupd.idAudience,
                      campaignupd.categoriesSelected,
                      chosenTagg,
                      finalEtiquetaa,
                      campaignupd.formato,
                      "",
                      campaignupd.priority
                    )
                    .then(() => {
                      API.updateCampaignPriority({
                        id: campaignupd.id,
                      });
                      clean();
                    });
                  localStorage.setItem("nameAWS", "");
                  localStorage.setItem("tempAWSUser", "");
                  localStorage.setItem("account", "");
                })
                .catch((err) => {
                  console.log("getProfileInfo", err);
                })
                .then(() => {
                  // props._showLoader(false)
                });
            });
          } else {
            props._showLoader(false);
            Swal.fire({
              title: "Advertencia",
              text: "Esta campaña fue creada fuera de lets advertise, por favor contacta a soporte",
              icon: "warning",
              showCancelButton: false,
              confirmButtonColor: "#3085d6",
              confirmButtonText: "Ok",
            });
          }
      }
    });
  };

  const showDeleteConfirmation = async (campaignupd) => {
    props._showLoader(true);
    const userdata = await Auth.currentAuthenticatedUser();

    let activateGeneral = await API.getBitacoraByidActionAndMix({
      idMixDetail: campaignupd.id,
      action: "estado activo de la pauta",
    });

    props._showLoader(false);

    if (activateGeneral.data.bitacoras.length > 0) {
      Swal.fire({
        title: "Ups!",
        text: "Tu campaña ya estuvo en activo antes.",
        icon: "info",
        showCancelButton: false,
        confirmButtonColor: "#3085d6",
        confirmButtonText: "Ok",
      }).then(() => {
        return;
      });
    } else {
      Swal.fire({
        title: "",
        text: "Estas seguro de que deseas elminar la campaña?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Eliminar",
        cancelButtonText: "Cancelar",
      }).then(async (result) => {
        if (result.isConfirmed) {
          analytics.accionesTabla({
            accion: "Eliminar",
            ubicacion: "Dashboard",
          });
          const budgetsTransact = [
            {
              id: campaignupd.idBudget,
              quantity: parseFloat(campaignupd.budget),
              sum: true,
              idCampaign: campaignupd.id,
              user: userdata.attributes.email,
              account: props.currentAccount,
            },
          ];

          await Promise.all([
            API.budgetTransaction({ budgets: budgetsTransact }),
            API.updateCampaingStatus({
              id: campaignupd.id.toString(),
              status: 3,
            }),
            API.deletePauta({ idMix: campaignupd.idMixDetaill }),
          ]);

          await refreshData();
          Swal.fire("Campaña eliminada correctamente", " ", "success");
        }
      });
    }
  };

  const intermediaria = (campaignup) => {
    let campaignupd = campaignup;
    localStorage.setItem("campaignAdd", null);
    // 3,6,16 estados no permitidos para actualizar
    if (campaignupd.status != 3 && campaignupd.status != 6) {
      props._funcampaignupd(campaignupd);
      setCampEdit(campaignup);
      analytics.accionesTabla({
        accion: "Editar",
        ubicacion: "Dashboard",
      });
      setShowModal(true);
    }
  };

  const getTotalspent = (id) => {
    let filteredBudget = props.budget_spent.filter((x) => x.ID === id);
    let msgTotal = filteredBudget.reduce(function (prev, cur) {
      return prev + cur.budget_spent;
    }, 0);
    return msgTotal.toFixed(2);
  };

  const handleShowColumnChange = async (column, show) => {
    let index = columns.findIndex((col) => col.selector === column);
    let aux = columns.map((col) => col);
    if (aux[index]) {
      if (aux[index].selector === "year") {
        let idxFin = columns.findIndex((col) => col.selector === "fechaFin");
        let idxIni = columns.findIndex((col) => col.selector === "fechaIni");
        aux[idxFin].excel = !show;
        aux[idxIni].excel = !show;
      }
      aux[index].omit = show;
      if (show && aux[index].pinned) {
        pinnedHidden.push(aux[index].selector);
        onClickpin("", "", "", aux[index].selector);
      }
      if (pinnedHidden.includes(aux[index].selector) && !show) {
        onClickpin("", "", "", aux[index].selector);
      }
      setColumns(aux);
      analytics.ShowHide({
        ubicacion: "Dashboard",
      });

      //To export
      let headers = [];
      let dataExcel = [];
      let dataExcel2 = [];
      let dataExcel3 = [];
      let selectors = [];

      var filteredColumns = columns.filter(function (col) {
        return col.omit === false;
      });

      filteredColumns.forEach((element) => {
        headers.push(element.name);
        selectors.push(element.tag);
      });

      dataExcel.push(headers);
      let data = [];
      let data2 = [];
      for (let i = 0; i < filteredData.length; i++) {
        data.push(Object.entries(filteredData[i]));
      }

      for (let i = 0; i < data.length; i++) {
        dataExcel2 = [];
        for (let j = 0; j < data[i].length; j++) {
          for (let k = 0; k < selectors.length; k++) {
            if (selectors[k] == data[i][j][0]) {
              dataExcel2.push(data[i][j][1]);
            }
          }
        }
        dataExcel3.push(dataExcel2);
        dataExcel.push(dataExcel2);
      }

      data2.push(data);
    }
  };

  const actualizarEstado = async (id, estado) => {
    props._showLoader(true);
    let body = {
      id: id,
      active: estado,
    };
    await API.updateViewActive(body);
    actualizarViews();
    props._showLoader(false);
  };

  const handleShowView = async (vista, show) => {
    let nombres = [];
    let beforeView = views.find((ele) => ele.active);
    if (beforeView) {
      let colsPinned = columns.filter(
        (ele) => ele.pinned && ele.pinned !== 0 && ele.pinned !== 1
      );
      for (const element of colsPinned) {
        onClickpin("", element.position, element.width, element.selector);
      }
      actualizarEstado(beforeView.id, false);
    }
    actualizarEstado(vista, show);
    let vistaEnc = views.find((ele) => ele.id === vista);
    if (
      vistaEnc.filteredText !== undefined &&
      vistaEnc.filteredText !== null &&
      vistaEnc.filteredText !== ""
    ) {
      setValueFilterText(vistaEnc.filteredText);
    } else {
      setValueFilterText("");
      if (vistaEnc.dataRange !== undefined && vistaEnc.dateRange !== null) {
        // handleCalendarTextChange(vistaEnc.dateRange);
      } else {
        // if (fechaFilter) handleCalendarTextChange(fechaFilter);
      }
    }
    vistaEnc.columns.forEach(async (ele) => {
      if (show) {
        setNameView(vistaEnc.name);
        nombres.push(ele.id);
        const isExcludedColumn =
          ele.selector === "ninguno" ||
          ele.selector === "fechaIni" ||
          ele.selector === "fechaFin";
        if (!isExcludedColumn) {
          handleShowColumnChange(ele.selector, ele.omit);
          if (
            ele.pinned &&
            ele.pinned !== 0 &&
            ele.pinned !== 1 &&
            ele.pinned !== null
          ) {
            pinnedHiddenShow.push(ele.selector);
          }
        }
      } else {
        setNameView("lista");
        if (ele.selector !== "ninguno") {
          handleShowColumnChange(ele.selector, false);
        }
      }
    });

    if (show) handleColumnSortChange(nombres);
    else handleColumnSortChange(orderDefault);
  };

  const handleAddView = async (name) => {
    props._showLoader(true);
    if (sharedView.id !== undefined) {
      history.replace("/campañas");
    }
    let idView = "";
    let id = "";
    let sub = props.userAccount;

    let vistaAc = views.find((ele) => ele.active);
    if (vistaAc) id = vistaAc.id;
    let vistaEnc = views.find((ele) => ele.name === name);
    if (vistaEnc) idView = vistaEnc.id;
    else idView = v4();
    setNameView(name);
    let columnsWithoutName = columns.map((column) => {
      let { name, ...columnWithoutTag } = column;
      return columnWithoutTag;
    });

    if (id != "") actualizarEstado(id, false);
    let dataRange = localStorage.getItem("dateFilter");
    let body = {
      id: idView,
      name: name,
      component: "campaign",
      columns: columnsWithoutName,
      account: account,
      user: sub,
      active: true,
      filteredText: valueFilterText,
      dateRange: dataRange,
    };
    await API.insertView(body);
    await actualizarViews(false);
    props._showLoader(false);
  };

  const handleDeleteView = async (name) => {
    props._showLoader(true);
    let id = views.find((ele) => ele.active);
    if (id) {
      if (id.id === name) {
        setNameView("lista");
        columns.forEach((ele) => {
          if (
            ele.id === "ninguno" ||
            ele.id === "fechaIni" ||
            ele.id === "fechaFin"
          ) {
            handleShowColumnChange(ele.selector, true);
          } else {
            handleShowColumnChange(ele.selector, false);
          }
        });
        handleColumnSortChange(orderDefault);
      }
    }
    await API.deleteView({ id: name });
    actualizarViews(false);
    props._showLoader(false);
  };

  function validarRango(elements) {
    let colsPinnedSelector = columns
      .filter(
        (ele) =>
          ele.pinned &&
          ele.selector !== "ninguno" &&
          ele.selector !== "fechaIni" &&
          ele.selector !== "fechaFin" &&
          ele.selector !== "name"
      )
      .map((pinned) => pinned.selector);
    const arrary = elements
      .filter((ele) => ele !== "fechaIni" && ele !== "fechaFin")
      .slice(2, colsPinnedSelector.length + 2);

    colsPinnedSelector.sort();
    arrary.sort();
    return _.isEqual(colsPinnedSelector, arrary);
  }

  const handleColumnSortChange = (positions) => {
    if (!validarRango(positions)) return;
    setColumns((data) => {
      positions.forEach((columnId, position) => {
        let idx = data.findIndex((col) => col.id === columnId);
        if (idx != -1) {
          data[idx].position = position;
        } else {
        }
      });
      data = _.orderBy(data, ["position"]);
      return data;
    });
  };

  const handleFilterPerColumn = async (value) => {
    if (value.length > 0) {
      props._showLoader(true);
      analytics.filtro({
        ubicacion: "Dashboard",
      });
      setFilterInfo([...value]);
      let dataToFilterr = backupData;
      if (dataToFilterr.length > 0) {
        let dataFinal = [];
        value.forEach(async (el) => {
          if (el.column === "Estado") {
            let dataAlt = dataToFilterr.filter(
              (ele) => ele.searchable_status === el.value
            );
            if (dataAlt.length > 0) {
              dataFinal = [...dataFinal, ...dataAlt];
            }
          }
          if (el.column === "Pais") {
            let dataAlt = dataToFilterr.filter(
              (ele) => ele.name_region === el.value
            );
            if (dataAlt.length > 0) {
              dataFinal = [...dataFinal, ...dataAlt];
            }
          }
          if (el.column === "Formato") {
            let dataAlt = dataToFilterr.filter(
              (ele) => ele.formato === el.value
            );
            if (dataAlt.length > 0) {
              dataFinal = [...dataFinal, ...dataAlt];
            }
          }
          if (el.column === "Medio") {
            let dataAlt = dataToFilterr.filter((ele) => ele.medio === el.value);
            if (dataAlt.length > 0) {
              dataFinal = [...dataFinal, ...dataAlt];
            }
          }
          if (el.column === "Banner") {
            let dataAlt = dataToFilterr.filter(
              (ele) => ele.banner === el.value
            );
            if (dataAlt.length > 0) {
              dataFinal = [...dataFinal, ...dataAlt];
            }
          }
          if (el.column === "purchase_unit") {
            let dataAlt = dataToFilterr.filter(
              (ele) => ele.purchase_unit === el.value
            );
            if (dataAlt.length > 0) {
              dataFinal = [...dataFinal, ...dataAlt];
            }
          }
          if (el.column === "ID") {
            let searchArray = [];
            const mAccount = _.find(props.accounts, { id: props.account });
            let idclient = mAccount?.eAdvertising;
            if (el.value.includes(",")) {
              const myArray = el.value.split(",");
              for (let i = 0; i < myArray.length; i++) {
                if (new Number(myArray[i]) > 0) {
                  const [responseMySql, responseDynamo] = await Promise.all([
                    findCampaignByMixDetail(idclient, myArray[i]),
                    getCampaignById(myArray[i]),
                  ]);
                  if (responseMySql) {
                    const responseModelCampaign = formatDashboardModel(
                      responseMySql,
                      responseDynamo || null,
                      props?.budgets
                    );
                    searchArray.push(responseModelCampaign);
                  }
                }
              }
              dataFinal = [...dataFinal, ...searchArray];
              let set = new Set(dataFinal.map(JSON.stringify));
              let arrSinDuplicaciones = Array.from(set).map(JSON.parse);
              setFilteredValues([...arrSinDuplicaciones]);
            } else {
              let searchArray = [];
              const mAccount = _.find(props.accounts, { id: props.account });
              let idclient = mAccount?.eAdvertising;
              const [responseMySql, responseDynamo] = await Promise.all([
                findCampaignByMixDetail(idclient, el.value),
                getCampaignById(el.value),
              ]);
              if (responseMySql) {
                const responseModelCampaign = formatDashboardModel(
                  responseMySql,
                  responseDynamo || null,
                  props?.budgets
                );
                searchArray.push(responseModelCampaign);
              }
              dataFinal = [...dataFinal, ...searchArray];
              let set = new Set(dataFinal.map(JSON.stringify));
              let arrSinDuplicaciones = Array.from(set).map(JSON.parse);
              setFilteredValues([...arrSinDuplicaciones]);
            }
          }
          if (el.column === "Orden de Venta") {
            let searchArray = [];
            const mAccount = _.find(props.accounts, { id: props.account });
            let idclient = mAccount?.eAdvertising;
            if (el.value.includes(",")) {
              const myArray = el.value.split(",");

              for (let i = 0; i < myArray.length; i++) {
                if (new Number(myArray[i] > 0)) {
                  const [responseMySql, responseDynamo] = await Promise.all([
                    findCampaignByIdSale(idclient, myArray[i]),
                    getCampaignByIdSale(myArray[1]),
                  ]);

                  if (responseMySql) {
                    const responseModelCampaign = formatDashboardModel(
                      responseMySql,
                      responseDynamo || null,
                      props?.budgets
                    );
                    searchArray.push(responseModelCampaign);
                  }
                }
              }
              dataFinal = [...dataFinal, ...searchArray];
              let set = new Set(dataFinal.map(JSON.stringify));
              let arrSinDuplicaciones = Array.from(set).map(JSON.parse);
              setFilteredValues([...arrSinDuplicaciones]);
            } else {
              let searchArray = [];
              const mAccount = _.find(props.accounts, { id: props.account });
              let idclient = mAccount?.eAdvertising;

              const [responseMySql, responseDynamo] = await Promise.all([
                findCampaignByIdSale(idclient, el.value),
                getCampaignByIdSale(el.value),
              ]);

              if (responseMySql) {
                const responseModelCampaign = formatDashboardModel(
                  responseMySql,
                  responseDynamo || null,
                  props?.budgets
                );
                searchArray.push(responseModelCampaign);
              }
            }
          }
          if (el.column === "Nombre de presupuesto") {
            let dataAlt = dataToFilterr.filter(
              (ele) => ele.budgetName === el.value
            );
            if (dataAlt.length > 0) {
              dataFinal = [...dataFinal, ...dataAlt];
            }
          }
          if (
            el.column != "Orden de venta" &&
            el.column != "ID" &&
            el.column != "purchase_unit" &&
            el.column != "Medio" &&
            el.column != "Pais" &&
            el.column != "Estado" &&
            el.column != "Banner"
          ) {
            let dataAlt = dataToFilterr.filter((ele) => {
              for (let i = 0; i < columns1.length; i++) {
                if (
                  ele[columns1[i].id]
                    ?.toLowerCase()
                    .includes(el.value.toLowerCase())
                ) {
                  return ele[columns1[i].id]
                    ?.toLowerCase()
                    .includes(el.value.toLowerCase());
                }
              }
            });
            if (dataAlt.length > 0) {
              dataFinal = [...dataFinal, ...dataAlt];
            }
          }
        });
        let set = new Set(dataFinal.map(JSON.stringify));
        let arrSinDuplicaciones = Array.from(set).map(JSON.parse);
        setFilteredValues([...arrSinDuplicaciones]);
      }
      props._showLoader(false);
    } else {
      setFilterInfo([]);
      setFilteredValues(backupData);
    }
  };

  const toLower = (value) =>
    value?.toString().toLowerCase().includes(filterText.toLowerCase());

  let seen = new Set();
  let filteredData = filteredValues?.filter((item) => {
    // Verificamos las propiedades de item que más probablemente den false primero
    if (
      seen.has(item.id) || // Evita duplicados
      (!toLower(item?.name) &&
        !toLower(item?.idMixDetaill) &&
        !toLower(item?.medio) &&
        !toLower(item?.searchable_status) &&
        !toLower(item?.publico) &&
        !toLower(item?.banner) &&
        !toLower(item?.purchase_unit) &&
        !toLower(item?.budgetName) &&
        !toLower(item?.idBudget) &&
        !(item?.id_sale > 0 && toLower(item?.id_sale)) &&
        !columns1.some((column) => toLower(item[column.id])))
    ) {
      return false;
    }

    seen.add(item.id); // Marca este elemento como visto
    return true;
  });

  /**
   * Esta funcion solo se debe de ejeuctar cuando se esta filtrando por medio del
   *  calendario y que sea mejor a todo el periodo
   * @param {String} account : cuenta actual
   * @param {String} name : formato de las fechas
   * @param {Object} filter : objeto que contiene los filtrados
   * @param {Number} statusFilter : estado del filtrado
   * @param {Number} eAdvertising : id del cliente de la cuenta actual
   */
  const getCampaignsSearch = async (
    account,
    name,
    filter,
    statusFilter,
    eAdvertising
  ) => {
    try {
      props._showLoader(false);
      props._showLoader(true);
      let arrayDates = [];
      arrayDates.push(eAdvertising);
      arrayDates.push(account);
      arrayDates.push(name);
      arrayDates.push(filter);
      arrayDates.push(statusFilter);

      let mysqlCampaings;
      let response;
      if (statusFilter === 1) {
        let limit = 2000;
        let dates = name.split("|");
        let fechaI = dates[0].split("/");
        let fechaF = dates[1].split("/");
        let fechaIni = fechaI[2] + "/" + fechaI[1] + "/" + fechaI[0];
        let fechaFin = fechaF[2] + "/" + fechaF[1] + "/" + fechaF[0];
        setDatesToShow({
          fechaIni,
          fechaFin,
        });
        name = fechaIni + "|" + fechaFin;
        let idclient = eAdvertising;
        arrayDates.push(fechaIni);
        arrayDates.push(fechaFin);
        response = await API.getCampaignsSearch({
          statusFilter,
          account,
          name,
          ...filter,
        });

        const response2 = await Axios.post(
          "https://canfz6hwopdarle7qaa2vbvrye0rmnqi.lambda-url.us-east-1.on.aws/",
          {
            account,
            limit,
            idclient,
            fechaIni,
            fechaFin,
          }
        );
        // const response2 = await API.AllCampaignsNewMerged({
        //   account,
        //   limit,
        //   idclient,
        //   fechaIni,
        //   fechaFin,
        // });

        if (response2.data.error) {
          mysqlCampaings = [];
        } else {
          mysqlCampaings = response2.data.campaigns;
        }
      } else {
        response = await API.getCampaignsSearch({
          statusFilter,
          account,
          name,
          ...filter,
        });
      }
      setActualState({
        place: "mes",
        data: arrayDates,
      });
      localStorage.setItem(
        "state",
        JSON.stringify({
          place: "mes",
          data: arrayDates,
        })
      );
      const { campaigns } = response.data;
      if (mysqlCampaings) {
        var camps = mysqlCampaings;
        for (let i = 0; i < camps.length; i++) {
          let budgetAc = props.budgets?.find(
            (bud) => bud.id === camps[i].idBudget
          );

          camps[i].budgetName = budgetAc ? budgetAc.title : "";
          if (camps[i].categoriesSelected) {
            var categories = camps[i].categoriesSelected;

            const categoriesGruped = categories.reduce(
              (groups, item) => ({
                ...groups,
                [item.nombrePadre]: [...(groups[item.nombrePadre] || []), item],
              }),
              {}
            );
            let valores = Object.values(categoriesGruped);

            for (let j = 0; j < valores.length; j++) {
              let catsString = "";
              for (let k = 0; k < valores[j].length; k++) {
                catsString += valores[j][k].label;
                if (k < valores[j].length - 1) {
                  catsString += ", ";
                }
              }
              catsString = catsString.trim().replace(/,\s*$/, "");
              let body = { [valores[j][0].padre]: catsString };
              camps[i] = { ...camps[i], ...body };
            }
          }
        }
        if (searchInput !== null && filteredData.length > 0) {
          let nuevo = [...new Set(camps.map(JSON.stringify))];
          let newData = Array.from(nuevo).map(JSON.parse);

          const regex = /^\d{6}$/;
          let validate = valueFilterText.split(",") || [];

          // Validar si todos los valores en validate cumplen con el regex
          const allValid = validate.every((item) => regex.test(item.trim()));

          if (allValid) {
            // Aquí se verifica si hay exactamente una o más IDs válidas
            if (validate.length === 1 && regex.test(validate[0].trim())) {
              // Solo una ID válida
              setBackupData(newData);
              setFilteredValues(newData);
            } else if (validate.length > 0) {
              // Múltiples IDs válidas
              let copyArrayCampaign = [...newData];
              let arrayFilter = [];
              for (let i = 0; i < validate.length; i++) {
                const element = validate[i].trim();
                let index = copyArrayCampaign.findIndex(
                  (idPauta) => idPauta.id === element
                );
                if (index === -1) continue;
                arrayFilter.push(copyArrayCampaign[index]);
              }
              if (arrayFilter.length === 0) {
                // aca se llama a la funcio porque en no se encontraron los ids de
                onChangeFilterCalendar(valueFilterText);
              } else {
                setFilteredValues(arrayFilter);
              }
              setBackupData(newData);
            }
          } else {
            // Los IDs no son válidos
            setBackupData(newData);
            setFilteredValues(newData);
          }
        } else {
          setBackupData(camps);
          setFilteredValues(camps);
        }
        analytics.Fechas({
          ubicacion: "Dashboard",
        });
      } else {
        let auxArr = [];
        campaigns.forEach((campaign) => {
          auxArr.push(campaign);
        });
        setFilteredValues(camps);
        setBackupData(camps);
      }
    } catch (err) {
      console.log("error getsearchcampaigns", err);
    } finally {
      props._showLoader(false);
    }
  };

  //funcion que calcula las sumas nuevas segun filtro
  const newDataTotal = () => {
    let filtrada = filteredData;
    let totales = calculateTotal(filtrada);
    setTotalRow(totales);
  };

  // calculo de sumas
  useEffect(() => {
    newDataTotal();
  }, [filteredValues, filterText]);

  $(document).ready(function () {
    $(".CampaTabla1").on("scroll", function () {
      $(".CampaTabla2").scrollLeft($(this).scrollLeft());
    });
    $(".CampaTabla2").on("scroll", function () {
      $(".CampaTabla1").scrollLeft($(this).scrollLeft());
    });
  });

  const sendView = (datos, styles) => {
    if (state.data == 0) {
      return (
        <Kanban
          campaigns={datos}
          nameSelectedGrouped={nameSelectedGrouped}
          nameGrouped={nameGrouped}
          dataEnviada={dataEnviada}
          audiences={audiences}
          refreshData={() => refreshData()}
        />
      );
    } else if (state.data == 2) {
      var columnsMerged;
      columnsMerged = columns.concat(columns1);
      return (
        <Calendar
          campaigns={datos}
          dataEnviada={dataEnviada}
          audiences={audiences}
          refreshData={() => refreshData()}
        />
      );
    } else if (state.data == 1 && viewCount) {
      return (
        <>
          <Table
            columns={columns}
            campaigns={datos}
            groupedBy={groupedBy}
            handlePageChange={handlePageChange}
            customStyles={styles}
            expandale={false}
            setFilteredData={setFilteredValues}
            className="tablaDashboard"
          />
          <ReactDataTable
            className="CampaTabla2"
            columns={columns}
            customStyles={styles}
            groupedBy={groupedBy}
            data={totalRow}
          />
        </>
      );
    }
  };

  let myContacts = [];

  props.clients.map((client) => {
    if (
      client.id !== props.currentUser &&
      client.name !== "" &&
      account !== ""
    ) {
      const currentAccountInfo = _.find(props.accounts, { id: account });
      //props.utils.log('currentAccountInfo', currentAccountInfo)
      if (currentAccountInfo) {
        let members = currentAccountInfo.members || [];
        //props.utils.log('members', members)

        if (!members.includes(client.id)) {
          myContacts = [
            ...myContacts,
            {
              id: client.id,
              name: `${client.name} (${client.email})`,
            },
          ];
        }
      }
    }
  });

  const showModalCamp = () => {};

  const getInformacionAccount = async () => {
    let currentInformationAccunt = props.accounts.find(
      (account) => account.id === props.account
    );
    if (props.accounts.length === 0) {
      let username = await Auth.currentAuthenticatedUser();
      let accounts = await APILA.getAccounts(username.username);
      currentInformationAccunt = accounts.data.accounts.find(
        (account) => account.id === props.account
      );
    }
    return currentInformationAccunt;
  };

  const resetFilter = (value) => {
    history.replace({ search: "" });
    setFilteredValues(backupData);
    setFilterText(value);

    setDatesToShow({
      fechaIni: dateInitialFormat,
      fechaFin: dateFinFormat,
    });
  };

  const setTextFilterOnUrl = (value) => {
    let splitValue = value.split(",");
    let searchValue = splitValue.length > 1 ? value.replace(/\s/g, "") : value;

    history.replace({
      search: `search_query=&results=${searchValue}`,
    });

    setFilterText(value);
    setDatesToShow({
      fechaIni: "",
      fechaFin: "",
    });

    analytics.busqueda({
      ubicacion: "Dashboard",
      busqueda: value,
    });
    setFilterInfo([]);
  };

  const setPlaceInformation = (value) => {
    let arrayData = [];
    arrayData.push(value);
    setActualState({
      place: "busqueda",
      data: arrayData,
    });
    localStorage.setItem(
      "state",
      JSON.stringify({ place: "busqueda", data: arrayData })
    );
  };

  // searcher component
  const onChangeFilter = _.debounce(async (value) => {
    setShowAdd(true);
    setArraynIds("");
    document.addEventListener("keydown", (event) => {
      if (event.key === "Enter" && document.activeElement.type !== "textarea") {
        event.preventDefault();
      }
    });
    setValueFilterText(value);

    const valueArray = value.split(",");
    const multiValue = valueArray.length > 1;

    if (value === "") {
      resetFilter(value);
      return;
    } else {
      setTextFilterOnUrl(value);
    }

    if (!multiValue) {
      setPlaceInformation(value);
    }

    setDatesToShow({
      fechaIni: "",
      fechaFin: "",
    });

    setArraynIds(value);
    let currentDataFiltered = new Map(
      filteredData.map((pauta) => [pauta.id, pauta])
    );
    let arrayAuxiliar = [];
    let currentAccountInformation = await getInformacionAccount();
    let idclient = currentAccountInformation?.eAdvertising;
    const regex = /^\d{6}$/;

    if (valueArray.length === 1 && !regex.test(+value)) return;
    const arrayIdsNotFound = [];

    for (const el of valueArray) {
      if (!regex.test(+el)) break;
      const dataGet = currentDataFiltered.get(el);
      if (dataGet) {
        arrayAuxiliar.push(dataGet);
      } else {
        if (filteredData.find((ele) => ele.id === el.trim())) break;
        arrayIdsNotFound.push(el);
      }
    }

    if (arrayIdsNotFound.length > 0) {
      const responseMerge = await API.findCampaignAndMergeMysqlAndDynamo({
        arrayIdsCampaign: arrayIdsNotFound,
        idEadvertising: idclient,
        account: props?.currentAccount,
      });

      arrayAuxiliar.push(...responseMerge?.data?.searchArray);
    }
    setFilteredValues([...arrayAuxiliar]);
    if (arrayAuxiliar.length > 0) {
      setFilterText("");
    } else {
      setFilterText(valueFilterText);
    }
  }, 500);

  const onChangeFilterCalendar = async (value) => {
    $(document).keydown(function (event) {
      if (event.which == "13" && document.type != "textarea") {
        event.preventDefault();
      }
    });
    const myArray = value.split(",");
    if (value != "") {
      analytics.busqueda({
        ubicacion: "Dashboard",
        busqueda: value,
      });
    }
    if (myArray.length > 1) {
      setDatesToShow({
        fechaIni: "",
        fechaFin: "",
      });
      setArraynIds(value);
      let filtrada = filteredData;
      let auxArray = [];
      const mAccount = _.find(props.accounts, { id: props.account });
      let idclient = mAccount?.eAdvertising;
      let arrayIdsNotFound = [];
      for (const el of myArray) {
        let dataGet = filtrada.filter(
          (ele) => ele.id.toString() === el.toString()
        );
        if (dataGet.length > 0) {
          auxArray.push(dataGet[0]);
        } else {
          arrayIdsNotFound.push(el);
        }
      }
      if (arrayIdsNotFound.length > 0) {
        const responseMerge = await API.findCampaignAndMergeMysqlAndDynamo({
          arrayIdsCampaign: arrayIdsNotFound,
          idEadvertising: idclient,
          account: props?.currentAccount,
        });
        auxArray.push(...responseMerge?.data?.searchArray);
      }
      setFilteredValues([...auxArray]);
    } else {
      let arrayData = [];
      arrayData.push(value);
      setArraynIds("");
      setActualState({
        place: "busqueda",
        data: arrayData,
      });
      localStorage.setItem(
        "state",
        JSON.stringify({
          place: "busqueda",
          data: arrayData,
        })
      );

      if (value == "") {
        setFilterText(value);

        let fechaFin = new Date();
        let fechaIn = new Date();
        fechaIn.setDate(fechaIn.getDate() - 7);
        let fechaIni = new Date(fechaIn);
        setDatesToShow({
          fechaIni,
          fechaFin,
        });
      } else {
        setFilterText(value);
        setDatesToShow({
          fechaIni: "",
          fechaFin: "",
        });
      }
    }
  };

  const refreshDataSearch = async (value) => {
    const regex = /^\d{6}$/;
    if (!regex.test(+value)) return;
    let arrayAuxiliar = [];
    const mAccount = _.find(props.accounts, { id: props.account });
    let idclient = mAccount?.eAdvertising;
    const [responseMySql, responseDynamo] = await Promise.all([
      findCampaignByMixDetail(idclient, value),
      getCampaignById(value),
    ]);
    if (responseMySql) {
      const responseModelCampaign = formatDashboardModel(
        responseMySql,
        responseDynamo || null,
        props?.budgets
      );
      arrayAuxiliar.push(responseModelCampaign);
    }
    let newFilteredData = [...filteredValues];
    let indexCampaign = filteredValues.findIndex((pauta) => pauta.id === value);
    if (indexCampaign === -1) {
      props._listCampaigns({ approve: false, id: "" });
      return;
    }
    newFilteredData[indexCampaign] = arrayAuxiliar[0];
    setFilteredValues([...newFilteredData]);
    props._listCampaigns({ approve: false, id: "" });
  };

  useEffect(() => {
    // refresh de la pauta que se esta editando cuando hay busquedas en la url
    if (props.listCampaigns.approve) {
      refreshDataSearch(props.listCampaigns.id);
    }
  }, [props.listCampaigns.approve]);

  const refreshData = async (options) => {
    props.getCampRefresh(options);
    if (searchInput) {
      if (Object.keys(props.campaignupd).length === 0) return;
      if (!props?.campaignupd?.id) return;
      await refreshDataSearch(props.campaignupd.id);
    }
  };

  const changeGrouped = (value) => {
    analytics.agrupar({ ubicacion: "Dashboard" });

    const mapping = {
      "Por defecto": { grouped: "", selectedGrouped: "" },
      Objetivo: { grouped: "banner", selectedGrouped: "Objetivo" },
      Medio: { grouped: "medio", selectedGrouped: "Medio" },
      Pais: { grouped: "country", selectedGrouped: "Pais" },
      "Tipo de compra": {
        grouped: "purchase_unit",
        selectedGrouped: "Tipo de compra",
      },
      Banner: { grouped: "banner", selectedGrouped: "Banner" },
      Estado: { grouped: "status", selectedGrouped: "Estado" },
      "Orden de venta": {
        grouped: "id_sale",
        selectedGrouped: "Orden de venta",
      },
      "Nombre de presupuesto": {
        grouped: "budgetName",
        selectedGrouped: "Nombre de presupuesto",
      },
      formato: { grouped: "formato", selectedGrouped: "formato" },
    };

    if (mapping[value]) {
      const { grouped, selectedGrouped } = mapping[value];
      setnameGrouped(grouped);
      setnameSelectedGrouped(selectedGrouped);
    } else {
      setnameSelectedGrouped("");
      const nameId = categoriesSorted.find((ele) => ele.name === value);
      if (nameId) {
        setnameGrouped(nameId.id);
      }
    }
    if (value === "Por defecto") {
      changeUrl({
        groupParams: "default",
        pageParams: currentPage,
        viewParams: Number(defaultVista),
        inicial: dateInvalid(parseInit) ? formattedDate : fechaIni,
        final: dateInvalid(parseFin) ? formatToday : fechaFin,
        viewColumnParams: viewValue,
      });
    } else {
      changeUrl({
        pageParams: currentPage,
        groupParams: value,
        viewParams: Number(defaultVista),
        inicial: dateInvalid(parseInit) ? formattedDate : fechaIni,
        final: dateInvalid(parseFin) ? formatToday : fechaFin,
        viewColumnParams: viewValue,
      });
    }
  };

  // open-close calendar component
  useEffect(() => {
    function handleClickOutside(event) {
      if (
        modalRef.current &&
        !modalRef.current.contains(event.target) &&
        !event.target.closest(".boxCalendar")
      ) {
        setShowCalendar(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [modalRef]);

  let dataSelect = filteredData;

  let catSelected = dataSelect.filter((ele) => {
    if (ele.categoriesSelected != undefined) {
      if (ele.categoriesSelected != "") {
        if (ele.categoriesSelected != 0) {
          return ele.categoriesSelected;
        }
      }
    }
  });

  const groupedCategorieSelect = () => {
    const titlesUsed = new Set();
    const elements = [];

    catSelected.forEach((ele) => {
      ele.categoriesSelected.forEach((catSelect) => {
        if (
          catSelect.padre === nameGrouped &&
          !titlesUsed.has(catSelect.label)
        ) {
          titlesUsed.add(catSelect.label);

          const filteredCategories = ele.categoriesSelected.filter(
            (cat) => cat.padre === nameGrouped
          );
          let totales = calculateTotal(filteredCategories);

          elements.push(
            <>
              <h1>{catSelect.label}</h1>
              <Table
                columns={columns}
                campaigns={filteredCategories}
                groupedBy={groupedBy}
                customStyles={customStyles}
                expandable={true}
                setFilteredData={setFilteredValues}
                className="tablaDashboard"
              />
              <ReactDataTable
                className="CampaTabla2"
                columns={columns}
                customStyles={customStyles}
                groupedBy={groupedBy}
                data={totales}
              />
            </>
          );
        }
      });
    });

    return elements;
  };

  const groupedSelectData = () => {
    let titlesUsed = [];
    const elements = filteredData.map((ele, idx) => {
      let title =
        nameGrouped === "status"
          ? getStatusLabel(ele[nameGrouped])
          : ele[nameGrouped];
      if (!titlesUsed.includes(title)) {
        titlesUsed.push(title);
        let dataGrouped = filteredData.filter(
          (element) => element[nameGrouped] === ele[nameGrouped]
        );
        let totales = calculateTotal(dataGrouped);

        return (
          <>
            <h1>{title}</h1>
            <Table
              columns={columns}
              campaigns={dataGrouped}
              groupedBy={groupedBy}
              customStyles={customStyles}
              expandable={true}
              setFilteredData={setFilteredValues}
              className="tablaDashboard"
            />

            <ReactDataTable
              className="CampaTabla2"
              columns={columns}
              customStyles={customStyles}
              groupedBy={groupedBy}
              data={totales}
            />
          </>
        );
      }
    });
    return elements;
  };

  const onChangeGoal = (e) => {
    let auxKpi = stateKpi;
    auxKpi.goal = e;
    setStateKpi({
      ...auxKpi,
    });
  };

  const onSaveModal2 = async () => {
    props._showLoader(true);
    await API.updateGoal({
      id: stateKpi.id,
      goal: new Number(stateKpi.goal),
    });
    props._showLoader(false);
    setShowModal2(false);
    Swal.fire({
      title: "Confirmación",
      text: "Meta agregada con éxito!",
      icon: "success",
      showCancelButton: false,
      confirmButtonColor: "#3085d6",
      confirmButtonText: "Ok",
    });
    refreshData();
  };

  const getKpiName = (name) => {
    const kpiNames = {
      post_likes: "Reacciones",
      page_comments: "Comentarios",
      page_shares: "Compartidos",
      video_avg_time: "Duración promedio de vistas",
      video_playbacks_100: "Reproducciones 100%",
      video_playbacks_95: "Reproducciones 95%",
      video_playbacks_75: "Reproducciones 75%",
      video_playbacks_50: "Reproducciones 50%",
      video_playbacks_25: "Reproducciones 25%",
      views: "Vistas",
      reach: "Alcance",
      fans: "Fans",
      clicks: "Clicks",
      prints: "Impresiones",
      frequency: "Frecuencia",
      estimated_ad_recallers: "Reconocimiento de marca (personas)",
      mobile_app_install: "Instalaciones",
      event_responses: "Respuesta a eventos",
      leads: "Leads",
      photo_view: "Visualizaciones de imágenes",
    };
    return kpiNames[name];
  };
  let dataFil = [];
  let dataCategoriesSelected = [];

  backupData.forEach((ele) => {
    if (ele.banner) dataFil.push({ nombrePadre: "Objetivo", name: ele.banner });
    if (ele.name_region) {
      dataFil.push({ nombrePadre: "Pais", name: ele.country });
    }
    if (ele.status) {
      dataFil.push({ nombrePadre: "Estado", name: ele.searchable_status });
    }
    if (ele.purchase_unit) {
      dataFil.push({ nombrePadre: "Tipo de compra", name: ele.purchase_unit });
    }
    if (ele.medio) dataFil.push({ nombrePadre: "Medio", name: ele.medio });
    if (ele.budgetName)
      dataFil.push({
        nombrePadre: "Nombre de presupuesto",
        name: ele.budgetName,
      });
    if (ele.categoriesSelected?.length > 0) {
      dataCategoriesSelected = dataCategoriesSelected.concat(
        ele.categoriesSelected
      );
    }
    if (ele.formato)
      dataFil.push({ nombrePadre: "Formato", name: ele.formato });
  });

  dataCategoriesSelected.forEach((ele) => {
    const matchingCategory = categoriesSorted.find(
      (cate) => cate.id === ele.padre
    );
    if (matchingCategory) {
      dataFil.push({ nombrePadre: matchingCategory.name, name: ele.label });
    }
  });

  // calendar component (not load mount)
  const handleCalendarFilter = async (value) => {
    props._showLoader(true);
    const fechaInitial = new Date(value.startDate);
    const fechaFinal = new Date(value.endDate);
    const newFormat = format(fechaInitial, "dd/MM/yyyy");
    const newFormat2 = format(fechaFinal, "dd/MM/yyyy");
    changeUrl({
      groupParams: groupValue,
      pageParams: currentPage,
      viewColumnParams: viewValue,
      viewParams: Number(defaultVista),
      inicial: newFormat,
      final: newFormat2,
    });
    try {
      setDatesToShow({
        fechaFin: value.endDate,
        fechaIni: value.startDate,
      });
      let statusFilter = 0;

      const initDate = formatDateToCustomString(value.startDate);
      const finitDate = formatDateToCustomString(value.endDate);

      let valueDate = `${initDate}|${finitDate}+`;

      if (valueDate === "02.06.2000|31.12.2035+") {
        //Periodo completo
        const found = props.accounts.find(
          (element) => element.id === props.currentAccount
        );

        let limit = 2000;
        const response = await API.allPeriodCampaigns({
          account: props.account,
          limit,
          idclient: found.eAdvertising,
        });
        const decryptedData = unzip(response.data.campaigns);
        if (decryptedData.length === 0) return;
        const camps = decryptedData.filter(
          (campaign) => campaign.account !== ""
        );

        for (let i = 0; i < camps.length; i++) {
          let budgetAc = props.budgets?.find(
            (bud) => bud.id === camps[i].idBudget
          );

          camps[i].budgetName = budgetAc ? budgetAc.title : "";
          if (camps[i].categoriesSelected) {
            var categories = camps[i].categoriesSelected;

            const categoriesGruped = categories.reduce(
              (groups, item) => ({
                ...groups,
                [item.nombrePadre]: [...(groups[item.nombrePadre] || []), item],
              }),
              {}
            );

            let valores = Object.values(categoriesGruped);

            for (let j = 0; j < valores.length; j++) {
              let catsString = "";
              for (let k = 0; k < valores[j].length; k++) {
                catsString += valores[j][k].label;
                if (k < valores[j].length - 1) {
                  catsString += ", ";
                }
              }
              catsString = catsString.trim().replace(/,\s*$/, "");
              let body = { [valores[j][0].padre]: catsString };
              camps[i] = { ...camps[i], ...body };
            }
          }
        }

        if (searchInput !== null && filteredData.length > 0) {
          let nuevo = [...new Set(camps.map(JSON.stringify))];
          let newData = Array.from(nuevo).map(JSON.parse);

          const regex = /^\d{6}$/;
          let validate = valueFilterText.split(",") || [];

          // Validar si todos los valores en validate cumplen con el regex
          const allValid = validate.every((item) => regex.test(item.trim()));

          if (allValid) {
            // Aquí se verifica si hay exactamente una o más IDs válidas
            if (validate.length === 1 && regex.test(validate[0].trim())) {
              // Solo una ID válida
              setBackupData(newData);
              setFilteredValues(newData);
            } else if (validate.length > 0) {
              // Múltiples IDs válidas
              let copyArrayCampaign = [...newData];
              let arrayFilter = [];
              for (let i = 0; i < validate.length; i++) {
                const element = validate[i].trim();
                let index = copyArrayCampaign.findIndex(
                  (idPauta) => idPauta.id === element
                );
                if (index === -1) continue;
                arrayFilter.push(copyArrayCampaign[index]);
              }
              setFilteredValues(arrayFilter);
              setBackupData(newData);
            }
          } else {
            // Los IDs no son válidos
            setBackupData(newData);
            setFilteredValues(newData);
          }
        } else {
          setBackupData(camps);
          setFilteredValues(camps);
        }

        analytics.Fechas({
          ubicacion: "Dashboard",
        });
      } else {
        //Ramgo de fechas
        try {
          let num = parseInt(valueDate);
          if (num) {
            statusFilter = 3;
          }
        } catch {
          statusFilter = 0;
        }

        if (valueDate.includes("|") && valueDate.includes("+")) {
          statusFilter = 1;
          valueDate = valueDate.replaceAll("+", "");
          valueDate = valueDate.replaceAll(".", "/");
          valueDate = valueDate.replaceAll("-", "/");
          valueDate = valueDate.replaceAll("_", "/");
        }

        const found = props.accounts.find(
          (element) => element.id === props.currentAccount
        );

        getCampaignsSearch(
          props.currentAccount,
          valueDate.toLowerCase(),
          props.filter,
          statusFilter,
          found.eAdvertising
        );
      }
    } catch (error) {
      console.log("Error en filtrado de fechas", error);
    } finally {
      props._showLoader(false);
    }
  };

  const onGenerateLink = async () => {
    let username = await Auth.currentAuthenticatedUser();
    let accountInfo = await APILA.getAccountById({ id: account });
    let members = accountInfo.data.account[0].members;
    let responseClients = await API.getAllAccountsAWS();
    let { clients } = responseClients.data;
    let users = clients.filter((el) => members.includes(el.Username));
    let usersFinal = users.filter((ele) => ele.Username != username.username);
    let dataRange = localStorage.getItem("dateFilter");
    let id = v4();
    let url = window.location.origin + "/campañas/" + id;
    let body = {
      id: id,
      columns: columns,
      view: state.data,
      filteredText: valueFilterText,
      dateRange: dataRange,
      customStyles: customStyles,
      dates: datesToShow,
      data: [],
      totalRow: totalRow,
      userEmail: username.attributes.email,
      userName: username.attributes.name,
      userId: username.username,
      account: account,
      url: url,
    };
    setLinkDataShare({
      body: body,
      url: url,
      users: usersFinal,
    });
    setShowModalLink(true);
  };

  const [initialFetch, setInitialFetch] = useState(true);

  useEffect(() => {
    const currentCampaigns = props.campaigns;
    const regex = /^\d{6}$/;
    let validate = valueFilterText.split(",");
    if (
      valueFilterText &&
      validate.length === 1 &&
      !regex.test(+valueFilterText)
    ) {
      if (props.campaigns.length === 0) return;
      if (!initialFetch) return;
      setInitialFetch(false);
      setBackupData(currentCampaigns);
      setFilteredValues(currentCampaigns);
    }
  }, [props.campaigns, valueFilterText]);

  return (
    <>
      <div className="content-menu contenedorDashboard">
        <div className="barraSuperior">
          <Select
            id="viewSelect"
            defaultValue={state.data}
            showSearch={false}
            onChange={(e) => changeView(e)}
            className="select-dashboard"
          >
            <Option value={1} className="fa">
              <Tooltip
                title="Vista de lista"
                placement="rightTop"
                color="#002448"
              >
                <div>
                  <i className="fa fa-table"></i> Vista de lista{" "}
                </div>
              </Tooltip>
            </Option>
            <Option value={0} className="fa">
              <Tooltip
                title="Vista de Kanban"
                placement="rightTop"
                color="#002448"
              >
                <div>
                  <i className="fas fa-columns"></i> Vista de Kanban{" "}
                </div>
              </Tooltip>
            </Option>
            <Option value={2} className="fa">
              <Tooltip
                title="Vista de calendario"
                placement="rightTop"
                color="#002448"
              >
                <div>
                  <i className="fa fa-calendar"></i> Vista de calendario
                </div>
              </Tooltip>
            </Option>
          </Select>

          <DataTableUtils
            id="data1"
            // valueFilter={localStorage.getItem("filtroText") || valueFilterText}
            valueFilter={valueFilterText || ""}
            action={
              permissionsBar.create
                ? () => {
                    const mCurrentAccount =
                      props.currentUser !== ""
                        ? props.currentAccount
                        : props.currentPWAAccount;

                    if (mCurrentAccount === "") {
                      props._showSelectAccountModal();
                      return;
                    }

                    showModalCamp();
                    setShowModal(true);
                    analytics.Add({
                      ubicacion: "Dashboard",
                    });
                  }
                : null
            }
            actionIcon={
              permissionsBar.create && (
                <Tooltip title="Crear campaña" color="#002448" placement="top">
                  <i className="fas fa-plus" />
                </Tooltip>
              )
            }
            onChangeFilterText={(value) => {
              localStorage.setItem("filtroText", value);
              onChangeFilter(value);
            }}
            onKeyPress={(event) => {
              if (event.key === "Enter") {
                return event.preventDefault();
              }
            }}
            onKeyDown={(event) => {
              if (event.key === "Enter") {
                return event.preventDefault();
              }
            }}
          >
            <Tooltip
              title="Exportar información"
              color="#002448"
              placement="top"
            >
              <div
                onClick={() => {
                  analytics.Compartir({
                    ubicacion: "Dashboard",
                  });
                }}
                className="ExportBtn"
              >
                {permissionsBar.export && (
                  <Share
                    data={exportDataToExcel()}
                    columns={columns}
                    isArray={true}
                    exportXLSX={true}
                    exportTXT={true}
                    exportDropbox={true}
                    exportDrive={true}
                    exportCSV={true}
                    printable={true}
                    exportPNG={true}
                    exportLink={true}
                    openLink={() => onGenerateLink()}
                    idImg={"root"}
                    appKey="2u78gno172idwbz"
                  />
                )}
              </div>
            </Tooltip>
            <Tooltip
              title="Personalizar columnas"
              placement="top"
              color="#002448"
            >
              <div id="shareBtn">
                <ShowColumn2
                  columns={columns}
                  showColumn={handleShowColumnChange}
                  showView={handleShowView}
                  onSortChange={handleColumnSortChange}
                  showAdd={showAdd}
                  vistas={views}
                  setVista={(nombre) => {
                    analytics.accionesVista({
                      ubicacion: "Dashboard",
                      accion: "Agregar",
                    });
                    handleAddView(nombre);
                  }}
                  deleteView={(name) => {
                    analytics.accionesVista({
                      ubicacion: "Dashboard",
                      accion: "ELiminar",
                    });
                    handleDeleteView(name);
                  }}
                  nameAc={nameView}
                />
              </div>
            </Tooltip>
            <Tooltip title="Filtrar y agrupar" placement="top" color="#002448">
              <div className="Filtro2 filtro3">
                <FilterColumn2
                  options={optionss}
                  changeGrouped={changeGrouped}
                  columns={columnsExa}
                  data={dataFil}
                  onFilterColumn={handleFilterPerColumn}
                  filterInfoCheck={filterInfo}
                  categories={namesCate}
                  groupedDefault={groupValue}
                />
              </div>
            </Tooltip>
            <Tooltip title="Seleccionar fecha" color="#002448" placement="top">
              <div
                className="boxCalendar"
                onClick={() => setShowCalendar((prev) => !prev)}
                ref={modalRef}
              >
                <div className="boxStartEndDate">
                  <p className="startDate">
                    Del{" "}
                    {datesToShow.fechaIni !== ""
                      ? new Date(datesToShow.fechaIni).toLocaleDateString(
                          "es-GT"
                        )
                      : datesToShow.fechaIni}
                  </p>
                  <p className="endDate">
                    Hasta{" "}
                    {datesToShow.fechaFin !== ""
                      ? new Date(datesToShow.fechaFin).toLocaleDateString(
                          "es-GT"
                        )
                      : datesToShow.fechaFin}
                  </p>
                </div>
                <div className="container-icon-calendar">
                  <i
                    className="fa fa-calendar"
                    aria-hidden="true"
                    style={{ color: "black" }}
                  ></i>
                </div>
                {showCalendar && (
                  <div onClick={(e) => e.stopPropagation()}>
                    <DateRangeFilter
                      onActivate={showCalendar}
                      onChange={handleCalendarFilter}
                    />
                  </div>
                )}
              </div>
            </Tooltip>
          </DataTableUtils>
          {/* <button onClick={() => onGenerateLink()}>Modal!</button> */}
        </div>
        {/* Descomentar*/}
        {showModalLink && (
          <ModalLink
            setShowModalLink={setShowModalLink}
            data={linkDataShare}
            userId={props.userAccount}
          />
        )}
        {showModalPriority && (
          <ModalUpdatePriority
            setShowModalPriority={setShowModalPriority}
            setRefresh={() => refreshData()}
          />
        )}
        {filteredData.length > 0 ? (
          <>
            {nameGrouped !== "" &&
            nameSelectedGrouped !== "select" &&
            state.data === 1
              ? groupedSelectData()
              : nameSelectedGrouped === "select" && state.data === 1
              ? groupedCategorieSelect()
              : sendView(filteredData, customStyles)}
          </>
        ) : (
          <>
            <div className="form homeSinLogin">
              <div>
                <p className="subTitle">Bienvenido a Let's Advertise</p>
                <h1 className="title">
                  No hay campañas asignadas en los últimos 7 días, crea una
                  ahora!
                </h1>
                {permissionsBar.create && (
                  <a
                    href="#"
                    className="btn-plus"
                    onClick={() => {
                      const mCurrentAccount =
                        props.currentUser !== ""
                          ? props.currentAccount
                          : props.currentPWAAccount;

                      if (mCurrentAccount === "") {
                        props._showSelectAccountModal();
                        return;
                      }

                      showModalCamp();
                      setShowModal(true);
                      analytics.Add({
                        ubicacion: "Dashboard",
                      });
                    }}
                  >
                    <i className="fas fa-plus" />
                  </a>
                )}
              </div>
              <div></div>
            </div>
          </>
        )}
      </div>
      {showModal && (
        <div className="blur-div blurNewPauta">
          <div
            className={classnames("upsert-modal w-75 h-75 modalapauta")}
            style={{
              backgroundColor: "white",
              padding: "20px",
              borderRadius: "10px",
            }}
          >
            <ModalPauta
              isSupAdmin={props.isSupAdmin}
              permiss={permissionsBar}
              dataEnviada={dataEnviada}
              setShowModal={setShowModal}
              audiences={audiences}
              campaignupd={campEdit}
              campaignEditPriority={props.getCamps}
              setRefresh={() => refreshData()}
              _clearCampaignupd={props._clearCampaignupd}
            />
          </div>
        </div>
      )}
      {showModal2 && (
        <ModalMeta
          setShowModal2={setShowModal2}
          stateKpi={stateKpi}
          onSaveModal2={onSaveModal2}
          getKpiName={getKpiName}
          onChangeGoal={onChangeGoal}
          isSuperAdmin={props.isSupAdmin}
        />
      )}
    </>
  );
};

const Dashboard = (props) => {
  const { id } = useParams();
  const [account, setAccount] = useState(props.currentAccount);
  const [banderaSpent, setBanderaSpent] = useState(0);
  const [counter, setCounter] = useState(0);
  const [infoData, setInfoData] = useState({});
  const [isSupAdmin, setIsSupAdmin] = useState(false);
  const [permissions, setPermissions] = useState({});
  const [sharedView, setSharedView] = useState([]);
  const dispatch = useDispatch();
  const campsToSend = useSelector((state) => state.campaigns.filteredCampaigns);
  const queryParams = new URLSearchParams(window.location.pathname);
  const fechaIni = queryParams.get("initial_date");
  const dateInitialFormat = formatDateParams(fechaIni);
  const fechaFin = queryParams.get("final_date");
  const dateFinFormat = formatDateParams(fechaFin);
  const [listAccounts, setListAccounts] = useState([]);

  const permiso = async () => {
    try {
      let sub = Auth.user.signInUserSession.idToken.payload.sub;
      let body = {
        account: props.currentAccount,
        user: sub,
      };
      API.getPermitsByUser({
        user: sub,
      }).then((data) => {
        let permisSupAdmin = data.data.permits?.find(
          (ele) => ele.idRol === objectPermissions.superAdmin
        );
        if (permisSupAdmin) {
          props._isSuperAdmin(true);
          setIsSupAdmin(true);
        }
      });
      let respuesta = await API.getPermitsByAccountAndUser(body);
      let permisos = await API.getRoleNameById({
        id: respuesta.data.permisos[0].idRol,
      });

      for (let permiso of permisos.data.rol[0].permissions) {
        if (permiso.name === objectPermissions.campaignModule) {
          setPermissions(permiso);
        }
      }
    } catch (err) {
      console.log("Validando los error", { err });
    }
  };

  const getCamp = async (options) => {
    try {
      props._showLoader(true);
      dispatch(listArrayCampaigns([]));
      let username = await Auth.currentAuthenticatedUser();
      const getData = Promise.all([
        API.getContryes(),
        APILA.getCategories(props.currentAccount),
        APILA.getAudiences(props.currentAccount),
        APILA.getBudgets(props.currentAccount),
        APILA.getAccounts(username.username),
      ]);
      const [countries, categories, audiences, budgets, accounts] =
        await getData;
      setListAccounts(accounts?.data?.accounts || []);
      const nombres = countries.data.pautas.map((ele) => ele.name);
      const audienciasActivas = audiences.data.audiences.filter(
        (ele) => ele.active
      );
      const namesAud = audienciasActivas.map((ele) => ele.name);

      setInfoData({
        countries: countries.data.pautas,
        categories: categories.data.categories,
        audiences: audiences.data.audiences,
        budgets: budgets.data.budgets,
        allCampaigns: [],
        countriesNames: nombres,
        audiencesNames: namesAud,
      });
      let validateView = id?.includes("account=");
      if (id !== undefined && !validateView) {
        const response = await API.getSharedView({ id: id });
        setSharedView(response.data.sharedView);
      }
      const selectedAccount = _.find(accounts.data.accounts, {
        id: props.currentAccount,
      });
      const campaignsLimit = 2000;
      let clientID = selectedAccount?.eAdvertising;
      const currentDate = new Date();
      const lastWeekStartDate = new Date();
      lastWeekStartDate.setDate(lastWeekStartDate.getDate() - 7);
      const formattedStartDate = formatDate(lastWeekStartDate);
      const formattedEndDate = formatDate(currentDate);

      const fechaFin = dateFinFormat || formattedEndDate;
      const fechaIni = dateInitialFormat || formattedStartDate;

      let mysqlApiResponse;
      let mysqlCampaigns;
      if (
        fechaFin === allPeriodCampaignsDates.fechaFin &&
        fechaIni === allPeriodCampaignsDates.fechaIni
      ) {
        const responseAllPeriod = await API.allPeriodCampaigns({
          account: selectedAccount.id,
          limit: campaignsLimit,
          idclient: selectedAccount?.eAdvertising,
        });
        mysqlCampaigns = unzip(responseAllPeriod?.data?.campaigns);
      } else {
        mysqlApiResponse = await Axios.post(
          "https://canfz6hwopdarle7qaa2vbvrye0rmnqi.lambda-url.us-east-1.on.aws/",
          {
            account: selectedAccount.id,
            limit: campaignsLimit,
            idclient: clientID,
            fechaFin,
            fechaIni,
          }
        );
        // mysqlApiResponse = await API.AllCampaignsNewMerged({
        //   account: selectedAccount.id,
        //   limit: campaignsLimit,
        //   idclient: clientID,
        //   fechaFin,
        //   fechaIni,
        // });
        mysqlCampaigns = mysqlApiResponse?.data?.campaigns;
      }
      // Procesar la respuesta de MySQL
      if (mysqlApiResponse?.data?.error) {
        mysqlCampaigns = [];
      }

      const filteredCampaigns = mysqlCampaigns.filter(
        (campaign) => campaign.account !== ""
      );

      filteredCampaigns.forEach((campaign) => {
        const budget = budgets.data.budgets.find(
          (bud) => bud.id === campaign.idBudget
        );
        campaign.budgetName = budget?.title;

        if (options) {
          if (options.refer === "region" && campaign.id === options.id) {
            campaign.options = options.nombres;
            campaign.optionsMedium = options.medio.data.data;
          } else if (options.refer === "medio" && campaign.id === options.id) {
            campaign.optionsBanner = options.nombres;
            campaign.optionsBannerCom = options.medios;
          } else if (options.refer === "format" && campaign.id === options.id) {
            campaign.formatosOptions = options.nombres;
            campaign.formatComplete = options.formatos;
          }
        }
      });

      dispatch(listArrayCampaigns(filteredCampaigns));
      setBanderaSpent(0);
      setCounter(0);

      if (banderaSpent === 0 && props.campaignsTotal !== 0) {
        setBanderaSpent(1);
      }

      if (props.budget_spent && props.budget_spent.length > 0) {
        if (
          counter === 0 &&
          props.campaignsTotal !== 0 &&
          props.budget_spent.length === props.budget_spentTotal
        ) {
          setCounter(1);
        }
      }
    } catch (err) {
      props._showLoader(false);
    } finally {
      setTimeout(() => {
        props._showLoader(false);
      }, 2000);
    }
  };

  useEffect(() => {
    setAccount(props.currentAccount);
    setPermissions({});
    permiso();
    setAccount(props.currentAccount);
    localStorage.setItem("campaignAdd", null);
  }, [props.currentAccount]);

  useEffect(() => {
    if (props.currentAccount === "") return;
    getCamp();
  }, [props.currentAccount]);

  return (
    <>
      {permissions.view ? (
        <div className="content-dashboard">
          <Bar
            getCamps={campsToSend}
            sharedView={sharedView}
            _approveCampaign={props._approveCampaign}
            clients={props.clients}
            listAccounts={listAccounts}
            campaignupd={props.campaignupd}
            utils={props.utils}
            audiencesNames={infoData.audiencesNames}
            accounts={props.accounts}
            campaigns={campsToSend}
            _newEmailValidation={props._newEmailValidation}
            sendCampaignss={campsToSend}
            exportData={props.exportData}
            isSupAdmin={isSupAdmin}
            userAccount={props.currentUser}
            currentUser={account}
            namesCountries={infoData.countriesNames}
            categories={infoData.categories}
            audiences={infoData.audiences || []}
            budgets={infoData.budgets}
            currentAccount={props.currentAccount}
            _funcampaignupd={props._funcampaignupd}
            _funcampaignupAdd={props._funcampaignupAdd}
            _clearCampaignupd={props._clearCampaignupd}
            currentPWAAccount={props.currentPWAAccount}
            _updateCampaignTable={props._updateCampaignTable}
            showSelectAccountModal={props._showSelectAccountModal}
            _updateAccountMembers={props._updateAccountMembers}
            paymentInfo={props.payment}
            _validateHoursAvailable={props._validateHoursAvailable}
            _addIdMixDetaill={props._addIdMixDetaill}
            _getUrlImages={props._getUrlImages}
            filter={props.filter}
            _showConfirmationModal={props._showConfirmationModal}
            countries={infoData.countries}
            account={account}
            _descrifrar={props._descrifrar}
            _updateCampaign={props._updateCampaign}
            _cleanSteps={props._cleanSteps}
            _msgsuccess={props._msgsuccess}
            budget_spent={props.budget_spent}
            budget_spentTotal={props.budget_spentTotal}
            getCampRefresh={(e) => getCamp(e)}
            _showLoader={props._showLoader}
            permissions={permissions}
            listCampaigns={props.listCampaigns}
            _listCampaigns={props._listAddCampaign}
            _alertMessage={props._alertMessage}
            // setCampsToSend={setCampsToSend}
          />
          <ModalAudience />
          <ModalDetaill />
          <ModalImage />
          <ModalRegister />
          <ModalCard />
        </div>
      ) : (
        permissions.view !== undefined &&
        permissions.view === false && (
          <h1
            style={{
              marginTop: "8rem",
              marginLeft: "3rem",
              position: "absolute",
            }}
          >
            No tienes permisos
          </h1>
        )
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  clients: state.clients,
  categories: state.categories.list,
  campaign: state.campaign,
  accounts: state.accounts.list,
  account: state.accounts.default,
  filter: state.campaigns.filter,
  isLoading: state.app.isLoading,
  campaigns: state.campaigns.list,
  campaignsTotal: state.campaigns.total,
  currentUser: state.app.current_user,
  users: state.users,
  currentAccount: state.accounts.default,
  currentPWAAccount: state.pwa.accounts.default,
  campaignupd: state.campaignupd,
  campaignupAdd: state.campaignupAdd,
  budget_spent: state.budget_spent.spent_budget,
  budget_spentTotal: state.budget_spent.total,
  paymentInfo: state.payment,
  allCampaings: state.allCampaings,
  exportData: state.exportData,
  mediumbanners: state.mediumbanners,
  listCampaigns: state.campaigns.campaigns,
});

const mapDispatchToProps = (dispatch) => ({
  _alertMessage: (title, body) => dispatch(alertMessage(title, body)),
  _isSuperAdmin: (admin) => dispatch(setIsSuperAdmin(admin)),
  _descrifrar: (texto, password) => dispatch(descifrar(texto, password)),
  _listAddCampaign: (campaigns) => dispatch(addCampaigns(campaigns)),
  _showLoader: (show) => dispatch(showLoader(show)),
  _validateHoursAvailable: (
    currentAccount,
    accounts,
    startDate,
    priority,
    edit
  ) =>
    dispatch(
      validateHoursAvailable(
        currentAccount,
        accounts,
        startDate,
        priority,
        edit
      )
    ),
  _getUsersRole: (user) => dispatch(getUsersRole(user)),
  _cleanSteps: (step) => dispatch(cleanSteps(step)),
  _msgsuccess: () => dispatch(msgsuccess()),
  _addIdMixDetaill: (id) => dispatch(addIdMixDetaill(id)),
  _getUrlImages: (id, user, idmod, mod) =>
    dispatch(getUrlImages(id, user, idmod, mod)),
  _clearCampaignupd: () => dispatch(clearCampaignupd()),
  _funcampaignupd: (camp) => dispatch(funcampaignupd(camp)),
  _updateAccountMembers: (account, members) =>
    dispatch(updateAccountMembers(account, members)),
  _showSelectAccountModal: () =>
    dispatch(
      Modal.showAlertModal(
        "Cuenta Requerida",
        "Por favor selecciona una cuenta antes",
        Modal.MODAL_TYPE_WARNING,
        () => {}
      )
    ),
  _showConfirmationModal: (title, message, callback) =>
    dispatch(
      Modal.showAlertModal(
        title,
        message,
        Modal.MODAL_TYPE_CONFIRMATION,
        callback
      )
    ),
  _newEmailValidation: (idAccount, modulo, data, usuario, accounts, action) =>
    dispatch(
      newEmailValidation(idAccount, modulo, data, usuario, accounts, action)
    ),
  _updateCampaignTable: (
    nameToSend,
    emailToSend,
    id,
    name,
    medio,
    fechaIni,
    fechafin,
    objetivo,
    budget,
    publico,
    idMixDetaill,
    idMixChange,
    idMix,
    idCampaign,
    idMedium,
    medioo,
    id_ad_product,
    banner,
    estado,
    idBudget,
    account,
    creativity,
    observation,
    copy,
    id_sale,
    id_region,
    name_region,
    nameForEmail,
    emailForEmail,
    availableAmount,
    newBudget,
    budgets,
    idAudience,
    optionSelected,
    chosenTag,
    finalEtiqueta,
    formato,
    campaign,
    responseTemp
  ) =>
    dispatch(
      updateCampaignTable(
        nameToSend,
        emailToSend,
        id,
        name,
        medio,
        fechaIni,
        fechafin,
        objetivo,
        budget,
        publico,
        idMixDetaill,
        idMixChange,
        idMix,
        idCampaign,
        idMedium,
        medioo,
        id_ad_product,
        banner,
        estado,
        idBudget,
        account,
        creativity,
        observation,
        copy,
        id_sale,
        id_region,
        name_region,
        nameForEmail,
        emailForEmail,
        availableAmount,
        newBudget,
        budgets,
        idAudience,
        optionSelected,
        chosenTag,
        finalEtiqueta,
        formato,
        campaign,
        responseTemp
      )
    ),
  _approveCampaign: (
    id,
    idMixDetaill,
    name,
    fechaIni,
    fechafin,
    budget,
    id_ad_product,
    status,
    observation,
    copy,
    id_sale,
    name_region,
    objetivo,
    banner,
    medio,
    publico,
    nameForEmail,
    emailForEmail,
    urls,
    version,
    npost,
    prevCampaign,
    priority,
    currentAccount,
    idAudience,
    categoriesSelected,
    creativity,
    nameToSend,
    emailToSend,
    emailToTag,
    formato,
    chosenTagg,
    findAccount,
    searchInput,
    id_digital_asset
  ) =>
    dispatch(
      approveCampaign(
        id,
        idMixDetaill,
        name,
        fechaIni,
        fechafin,
        budget,
        id_ad_product,
        status,
        observation,
        copy,
        id_sale,
        name_region,
        objetivo,
        banner,
        medio,
        publico,
        nameForEmail,
        emailForEmail,
        urls,
        version,
        npost,
        prevCampaign,
        priority,
        currentAccount,
        idAudience,
        categoriesSelected,
        creativity,
        nameToSend,
        emailToSend,
        emailToTag,
        formato,
        chosenTagg,
        findAccount,
        searchInput,
        id_digital_asset
      )
    ),
  _updateCampaign: (
    nameToSend,
    emailToSend,
    id,
    name,
    medio,
    fechaIni,
    fechafin,
    objetivo,
    budget,
    publico,
    idMixDetaill,
    idMixChange,
    idMix,
    idCampaign,
    id_medium,
    medioo,
    id_ad_product,
    banner,
    estado,
    idBudget,
    account,
    creativity,
    observation,
    copy,
    id_sale,
    id_region,
    name_region,
    nameForEmail,
    emailForEmail,
    urls,
    availableAmount,
    newBudget,
    version,
    npost,
    budgets,
    campaigns,
    categories,
    idAudience,
    optionSelected,
    chosenTag,
    tagToEmail,
    formato,
    campAc,
    priority
  ) =>
    dispatch(
      updateCampaign(
        nameToSend,
        emailToSend,
        id,
        name,
        medio,
        fechaIni,
        fechafin,
        objetivo,
        budget,
        publico,
        idMixDetaill,
        idMixChange,
        idMix,
        idCampaign,
        id_medium,
        medioo,
        id_ad_product,
        banner,
        estado,
        idBudget,
        account,
        creativity,
        observation,
        copy,
        id_sale,
        id_region,
        name_region,
        nameForEmail,
        emailForEmail,
        urls,
        availableAmount,
        newBudget,
        version,
        npost,
        budgets,
        campaigns,
        categories,
        idAudience,
        optionSelected,
        chosenTag,
        tagToEmail,
        formato,
        campAc,
        priority
      )
    ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withUtils(Dashboard));
