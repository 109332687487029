import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import { savetags } from "redux/actions/tags.actions";
import { saveSteps } from "redux/actions/nameCamp.actions";
import { savePublicName } from "redux/actions/campana.actions";
import { createNewAudience } from "redux/actions/audiences.actions";
import { clearAccounts, getAccounts } from "redux/actions/accounts.actions";
import { FormGroup, Label, Input, FormFeedback } from "reactstrap";
import Swal from "sweetalert2";
import _ from "lodash";
const Publico = (props) => {
  const [errors, setErrors] = useState({
    name: false,
    tags: false,
    tagsE: false,
  });
  const initialState = {
    id: "",
    name: "",
    newTag: "",
    newTagE: "",
    edit: false,
    createAt: 0,
    tags: { data: [] },
    tagsE: { data: [] },
  };
  const [state, setState] = useState(props?.campaign?.tags || initialState);
  const [errorTag, setErrorTag] = useState(false);

  const agregarTag = () => {
    let cont = document.getElementsByClassName("tagCont");
    let tags = document.getElementsByClassName("tag");

    if (cont.length < 8) {
      tags[cont.length + 1].classList.add("color");
    }
  };

  const handleInputPublicChange = (e) => {
    let input = document.getElementById("btnPublicoCamp");

    if (input.value === "") {
      input.style.borderBottom = "1px solid red";
      input.style.color = "red";
      input.placeholder = "Describa su público";
    } else {
      input.style.borderBottom = "1px solid #05252d";
      input.style.color = "#05252d";
      input.placeholder = "P. ejemplo mujeres de 18 a 25 años con hijos";
    }

    let name = e.target.name;
    let value = e.target.value;

    setState((prev) => ({
      ...prev,
      [name]: value,
    }));

    setErrors((prev) => ({
      ...prev,
      [name]: _.isEmpty(value),
    }));
  };

  const handleInputChange = (e) => {
    let input = document.getElementById("btnNamePublico");

    if (input.value === "") {
      input.style.borderBottom = "1px solid red";
      input.style.color = "red";
      input.placeholder = "Ingrese el nombre de su público";
    } else {
      input.style.borderBottom = "1px solid #05252d";
      input.style.color = "#05252d";
      input.placeholder = "P. ejemplo madres solteras";
    }

    let name = e.target.name;
    let value = e.target.value;

    setState((prev) => ({
      ...prev,
      [name]: value,
    }));

    setErrors((prev) => ({
      ...prev,
      [name]: _.isEmpty(value),
    }));
  };

  const removeTag = (tag) => {
    let tags = state.tags;
    let data = tags.data.filter((s) => s !== tag);
    tags.data = data;

    setState((prev) => ({
      ...prev,
      tags: tags,
    }));
  };

  const backObjetivo = (e) => {
    e.preventDefault();
    props._saveSteps(5);
  };

  const savePublico = (e) => {
    console.log("State", state);
    let inputPublico = document.getElementById("btnPublicoCamp");
    let inputNombrePublico = document.getElementById("btnNamePublico");

    if (inputNombrePublico.value == "") {
      inputNombrePublico.style.borderBottom = "1px solid red";
      inputNombrePublico.style.color = "red";
      inputNombrePublico.placeholder = "Ingrese el nombre de su público";
    } else {
      inputNombrePublico.style.borderBottom = "1px solid #05252d";
      inputNombrePublico.style.color = "#05252d";
      inputNombrePublico.placeholder = "P. ejemplo madres solteras";
    }

    if (state.tags.data.length <= 0 || state.name == "") {
      inputPublico.style.borderBottom = "1px solid red";
      inputPublico.style.color = "red";
      inputPublico.placeholder = "Describa su público";
    } else {
      inputPublico.style.borderBottom = "1px solid #05252d";
      inputPublico.style.color = "#05252d";
      inputPublico.placeholder = "P. ejemplo mujeres de 18 a 25 años con hijos";

      e.preventDefault();
      props._savetags(state);
      // props._saveSteps(7)
      props._saveSteps(12);
    }
  };

  const submitTag = async (e) => {
    if (e.key == "Enter") {
      let tags = state.tags;
      let tagFound = [];
      tagFound = state.tags?.data.filter((el) => el == state.newTag);
      if (tagFound == undefined) {
        tagFound = [];
      }
      console.log("Tagf", tags);
      if (tags == undefined) {
        tags = { data: [] };
      }
      if (tagFound.length === 0) {
        if (state.newTag !== "") {
          tags.data.push(state.newTag);
        }

        setState((prev) => ({
          ...prev,
          tags: tags,
          newTag: "",
        }));
      } else {
        setErrorTag(true);
      }
    }
  };
  const submitTagExcluyente = (e) => {
    if (e.key == "Enter") {
      let tagsE = state.tagsE;

      let tagFound = [];
      tagFound = state.tagsE?.data.filter((el) => el == state.newTagE);
      if (tagFound == undefined) {
        tagFound = [];
      }
      if (tagsE == undefined) {
        tagsE = { data: [] };
      }
      if (tagFound.length === 0) {
        if (state.newTagE !== "") {
          tagsE.data.push(state.newTagE);
        }

        setState((prev) => ({
          ...prev,
          tagsE: tagsE,
          newTagE: "",
        }));
      } else {
        setErrorTag(true);
      }
    }
  };

  //Excluyentes
  const removeTagE = (tagE) => {
    let tagsE = state.tagsE;
    let data = tagsE.data.filter((s) => s !== tagE);
    tagsE.data = data;

    setState((prev) => ({
      ...prev,
      tagsE: tagsE,
    }));
  };
  return (
    <div className={props.clase}>
      <h1 className="title">
        ¡Casi terminamos! <br /> Ahora define tus públicos
      </h1>

      <div className="bodyOneStep bodyOneStepPublico ">
        <div className="twoColBody">
          <FormGroup>
            <Label
              style={{
                color: "#05252d",
                fontWeight: 500,
                marginBottom: "5px",
              }}
            >
              Ponle un nombre a tu p&uacute;blico
            </Label>
            <Input
              required
              id="btnNamePublico"
              type="text"
              name="name"
              className="input"
              value={state.name}
              onChange={handleInputChange}
              placeholder="P. ejemplo madres solteras"
              invalid={errors.name}
            />
            <FormFeedback>Ingresa un nombre</FormFeedback>
          </FormGroup>
        </div>

        <div className="twoColBody">
          <FormGroup>
            <Label
              style={{
                color: "#05252d",
                fontWeight: 500,
                marginBottom: "5px",
              }}
            >
              Describe tu p&uacute;blico y presiona <b>Enter</b> para añadirlo
            </Label>
            <Input
              required={true}
              type="text"
              id="btnPublicoCamp"
              name="newTag"
              className="input"
              value={state.newTag}
              onKeyDown={submitTag}
              onChange={handleInputPublicChange}
              placeholder="P. ejemplo mujeres de 18 a 25 años con hijos"
            />

            <div className="tagsContent tagsCont1" id="tagsContent">
              {state.tags &&
                state.tags.data &&
                state.tags.data.map((tag) => (
                  <div key={tag} className="tagCont">
                    <p>{tag}</p>
                    <i
                      onClick={() => removeTag(tag)}
                      className="fas fa-times"
                    />
                  </div>
                ))}
            </div>
            <FormFeedback>Ingrese las descripciones de su público</FormFeedback>
          </FormGroup>
        </div>
        <div className="twoColBody">
          <FormGroup>
            <Label
              style={{
                color: "#05252d",
                fontWeight: 500,
                marginBottom: "5px",
              }}
            >
              Describe tu p&uacute;blico Excluido y presiona <b>Enter</b> para
              añadirlo
            </Label>
            <Input
              type="text"
              name="newTagE"
              id="btnPublicoCampE"
              className="input"
              value={state.newTagE}
              onKeyDown={submitTagExcluyente}
              onChange={handleInputPublicChange}
              placeholder="P. ejemplo padres de 18 a 25 años con hijos"
            />
            <div className="tagsContent tagsCont1" id="tagsContent">
              {state.tagsE &&
                state.tagsE.data &&
                state.tagsE.data.map((tagE) => (
                  <div key={tagE} className="tagCont">
                    <p>{tagE}</p>
                    <i
                      onClick={() => removeTagE(tagE)}
                      className="fas fa-times"
                    />
                  </div>
                ))}
            </div>
          </FormGroup>
        </div>
      </div>

      <div className="space-btns">
        <a className="btn-camp gray" onClick={backObjetivo} href="#">
          Volver
        </a>
        <a className="btn-camp" onClick={savePublico} href="#">
          Siguiente
        </a>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  campaign: state.campaign,
  accounts: state.accounts.list,
  campaignupd: state.campaignupd,
});

const mapDispatchToProps = (dispatch) => ({
  _savetags: (tags) => dispatch(savetags(tags)),
  _saveSteps: (step) => dispatch(saveSteps(step)),
  _clearAccounts: () => dispatch(clearAccounts()),
  _getAccounts: (client) => dispatch(getAccounts(client)),
  _savePublicName: (publicName) => dispatch(savePublicName(publicName)),
  _createAudience: (id, name, account, tags) =>
    dispatch(createNewAudience(id, name, account, tags)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Publico);
