import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { showLoader } from "redux/actions/loader.actions";
import DataTableUtils, {
  Share,
  ShowColumn2,
  FilterColumn2,
} from "@easygosanet/react-datatable-utils";
import { Tooltip, Select } from "antd";
import _ from "lodash";
import { withUtils } from "utils";
import analytics from "../../helpers/analytics";
import {
  upsertTemplate,
  setEdit,
  getTemplates,
  clearEdit,
  updateTemplateVisualizer,
  updateNameTemplate,
  asyncGetNameRegion,
  getAudiences,
  uploadTemplateEmpty,
  newTemplateForAccount,
  alertEdit,
  deleteTemplate,
  convertTemplateDefault,
  setImplementButton,
} from "redux/actions/templates.actions";
import {
  generateLink,
  initialLink,
} from "redux/actions/templateDeepLink.actions";
import * as APILA from "helpers/apiLA";
import {
  columnsVisualizer,
  orderDefaultTemplates as orderDefault,
} from "config/columns";
import ViewTemplates from "./selectableTemplate";
import { setEditActivate } from "redux/actions/templates.actions";
import { utils, read } from "xlsx";
import { getCategories } from "redux/actions/categories.actions";
import { useHistory, withRouter } from "react-router-dom";
import Swal from "sweetalert2";
import CreateAccountLogOut from "components/flowLogOutUser/createAccount";
import { nextStep } from "redux/actions/userLogout.action";
import { Auth } from "aws-amplify";
import ModalConfirmUploadTempleta from "./modalUploadTemplate";
import ModalPauta from "./upsertTemplate";
import classnames from "classnames";
import { v4 } from "uuid";
import Buttons from "./buttons";
import { parse, isAfter, isValid } from "date-fns";
import "./style.scss";
const { Option } = Select;
const CURRENT_MODULE = "templates";

const Content = (props) => {
  const fileInputRef = useRef(null);
  const historyDL = useHistory();
  const currentAccount =
    window.location.pathname?.split("/account=")[1]?.split("&")[0] ||
    props.currentAccount;
  const [data, setData] = useState([]);
  const [columnsSelect, setColumnsSelect] = useState([]);
  const [templateInfo, setTemplateInfo] = useState([]);
  const [filterText, setFilterText] = useState(props.currentFilterText || "");
  const [showModal, setShowModal] = useState(false);
  const [state, setState] = useState(0);
  const [option, setOption] = useState("1");
  const [dataUser, setDataUser] = useState({});
  const [init, setInit] = useState(true);
  const [dataBackup, setDataBackup] = useState([]);
  const [initText, setInitText] = useState(true);
  const [filterInfo, setFilterInfo] = useState([]);
  const [categoriesData, setCategoriesData] = useState([]);
  const [nameCamp, setNameCamp] = useState(false);
  const [showModalConfirmUpload, setShowModalConfirmUpload] = useState(false);
  const [confirmUploadExcel, setConfirmUploadExcel] = useState(false);
  const [showModalPauta, setShowModalPauta] = useState(false);
  const [views, setViews] = useState([]);
  const [nameView, setNameView] = useState("");
  const [permissions, setPermissions] = useState({
    approve: false,
    create: false,
    delete: false,
    edit: false,
    export: false,
    manageRoles: false,
    view: false,
  });
  const [urlTemplate, setUrlTemplate] = useState("");

  useEffect(() => {
    if (props.currentAccount !== "") {
      props._getTemplates(props.currentAccount, "");
      props._getCategories(props.currentAccount);
      getAllAudiences();
      actualizarViews(false);
      assignedPermissions();
      let response = validateUrl(currentAccount, props.accounts);
      let validateFilterText =
        props.currentFilterText !== "" ? props.currentFilterText : "";
      response
        ? props._generateLink(
          currentAccount,
          historyDL,
          props.currentPageParams,
          props.currentIdTemplate,
          validateFilterText
        )
        : props._initialLink(props.currentAccount, historyDL);
      setOption("1");
    }
  }, [props.currentAccount]);

  const assignedPermissions = async () => {
    try {
      props._showLoader(true);
      let sub = Auth.user.signInUserSession.idToken.payload.sub;

      const permissions = await APILA.getPermissionsByModule({
        module: "Planificación",
        user: sub,
        account: props.currentAccount,
      });
      setPermissions(permissions.data.permissions);
    } catch (error) {
      console.log("Error en assignedPermissions", error);
    } finally {
      props._showLoader(false);
    }
  };

  const validateUrl = (id, arr) => {
    let validate = false;
    let found = arr?.find((acc) => acc.id === id);
    if (found) {
      validate = true;
    }
    return validate;
  };

  const countries = async () => {
    await props._asyncGetCountries();
  };

  useEffect(() => {
    // Filtrar las plantillas por defecto y no por defecto
    let templatesDefaultByAccount = props?.templates?.filter(
      (template) => template.isDefaultByAccount
    );
    let templatesNotDefaultByAccount = props?.templates?.filter(
      (template) => !template.isDefaultByAccount
    );

    // Ordenamos cada grupo alfabeticamente
    templatesDefaultByAccount.sort((a, b) => a.name.localeCompare(b.name));
    templatesNotDefaultByAccount.sort((a, b) => a.name.localeCompare(b.name));

    // Combinar los dos grupos, asegurando que los por defecto estén primero
    let sortedTemplates = [
      ...templatesDefaultByAccount,
      ...templatesNotDefaultByAccount,
    ];

    // Unshift al inicio del array
    sortedTemplates.unshift({
      id: "1",
      name: "Selecciona una plantilla",
    });

    setTemplateInfo(sortedTemplates);

    if (
      props?.templates.length > 1 &&
      init &&
      props.currentIdTemplate !== "1"
    ) {
      setInit(false);
      opcionSelectParams(props.currentIdTemplate, sortedTemplates);
    }
  }, [props.templates]);

  const getAllAudiences = async () => {
    await props._getAudiences(props.currentAccount);
  };

  useEffect(() => {
    getDataUser();
    countries();
  }, []);

  const getDataUser = async () => {
    const userResponse = await Auth.currentAuthenticatedUser();
    setDataUser(userResponse.attributes);
  };

  const generateTemplate = async (name, value) => {
    if (Object.entries(props.edit).length === 0) {
      await props._upsertTemplate(
        value,
        props.currentAccount,
        name,
        setOption,
        "",
        historyDL,
        false
      );
    } else {
      await props._upsertTemplate(
        value,
        props.currentAccount,
        props.edit.name,
        setOption,
        props.edit.id,
        historyDL,
        true
      );
    }
  };

  const generateTemplateEmpty = async (format) => {
    await props._uploadTemplateEmpty(
      props.currentAccount,
      "Plantilla vacia",
      setOption,
      historyDL,
      false,
      format
    );
  };

  const handleChangeOptionSelect = (id) => {
    if (props.editActivateTemplate) {
      const activate = props._alertEdit(
        props.editActivateTemplate,
        props.currentAccount
      );
      if (activate === false) return;
      setFilterText("");
    }
    if (id === "1") {
      setOption("1");
      props._clearEdit();
      setFilterText("");
      props._generateLink(
        props.currentAccount,
        historyDL,
        props.currentPageParams,
        "1",
        ""
      );
    } else {
      setFilterText("");
      props._generateLink(
        props.currentAccount,
        historyDL,
        props.currentPageParams,
        id,
        ""
      );
      props._clearEdit();
      const editData = templateInfo.find((ele) => ele.id === id);
      props._setEdit(editData);
      setOption(id);
      setState(1);
    }
  };

  const opcionSelectParams = async (id, templates) => {
    if (id === "1") {
      setOption("1");
      props._clearEdit();
      props._generateLink(
        currentAccount,
        historyDL,
        props.currentPageParams,
        "1",
        props.currentFilterText
      );
    } else {
      props._clearEdit();
      let response = validateUrl(id, templates);
      if (!response) {
        props._initialLink(props.currentAccount, historyDL);
        return;
      }
      props._generateLink(
        currentAccount,
        historyDL,
        props.currentPageParams,
        id,
        props.currentFilterText
      );
      const editData = templates?.find((ele) => ele.id === id);
      props._setEdit(editData);
      setOption(id);
      setState(1);
    }
  };

  useEffect(() => {
    if (dataBackup.length > 0 && props.currentFilterText !== "" && initText) {
      filterTextParams(props.currentFilterText);
      setInitText(false);
    }
  }, [dataBackup]);

  const filterTextParams = (text) => {
    const filteredRecords = dataBackup.filter((record) => {
      const values = [
        record.name,
        record.id_sale,
        record.name_region,
        record.medio,
        record.banner,
        record.formato,
        record.budget_name,
        record.budget,
      ];
      return values.some((valueRes) =>
        String(valueRes).toLowerCase().includes(filterText.toLowerCase())
      );
    });
    setData(filteredRecords);
    props._generateLink(
      currentAccount,
      historyDL,
      props.currentPageParams,
      props.currentIdTemplate,
      text
    );
  };

  const updNameTemplate = async () => {
    if (Object.keys(props.edit).length > 0) {
      let nameTemplate = "";
      return Swal.fire({
        title: "¿Desea actualizar el nombre de la plantilla?",
        input: "text",
        inputAttributes: {
          autocapitalize: "off",
        },
        inputValue: props.edit?.name,
        showCancelButton: true,
        confirmButtonText: "Confirmar",
        confirmButtonColor: "#3085d6",
        bottonInvert: true,
        cancelButtonText: "Cancelar",
        preConfirm: (nameInput) => {
          if (!nameInput) {
            Swal.showValidationMessage("El nombre no puede ser vacio.");
          } else {
            nameTemplate = nameInput;
          }
        },
      }).then(async (result) => {
        if (result.isConfirmed) {
          await props._updateNameTemplate(nameTemplate, props.edit, dataUser);
        }
      });
    }
  };

  const exportDataToExcel = () => {
    let headers = [
      "name",
      "fechaIni",
      "fechaFin",
      "name_region",
      "medio",
      "objetivo",
      "formato",
      "budget_name",
      "budget",
      "audience_name",
    ];
    let selectors = [
      "name",
      "fechaIni",
      "fechaFin",
      "name_region",
      "medio",
      "objetivo",
      "formato",
      "budget_name",
      "budget",
      "audience_name",
    ];
    let dataExcel = [];

    dataExcel.push(headers);

    categoriesData.forEach((ele) => {
      headers.push(ele.name);
      selectors.push(ele.id);
    });
    if (dataBackup && dataBackup.length > 0) {
      dataBackup.forEach((ele) => {
        const excelData = selectors.map((selector) =>
          selector === "name"
            ? ele.name
            : selector === "fechaIni"
              ? ele.fechaIni
              : selector === "fechaFin"
                ? ele.fechaFin
                : selector === "name_region"
                  ? ele.name_region
                  : selector === "medio"
                    ? ele.medio
                    : selector === "objetivo"
                      ? ele.banner
                      : selector === "formato"
                        ? ele.formato
                        : selector === "budget_name"
                          ? ele.budget_name
                          : selector === "budget"
                            ? ele.budget
                            : selector === "audience_name"
                              ? ele.audience_name
                              : ele[selector]
        );
        dataExcel.push(excelData);
      });
    }
    return dataExcel;
  };

  const handleFileChange = (e) => {
    if (!e.target.files) return;
    const file = e.target.files[0];
    if (!file?.name) return;
    const fileName = file.name;
    if (file) {
      const reader = new FileReader();
      // *onLoad = luego de cargar
      reader.onload = (event) => {
        const data = event.target.result;
        // * retorna binarios y los convertimos
        const sheet = read(data, { type: "binary" });
        const firstSheetName = sheet.SheetNames[0];
        const dateExcel = sheet.Sheets[firstSheetName];
        const jsonData = utils.sheet_to_json(dateExcel);
        generateTemplate(fileName, jsonData);
      };
      // *leemos el archivo
      reader.readAsArrayBuffer(file);
      const fileSelect = document.querySelector(".input-file-excel");
      fileSelect.value = ""; // * si cancelamos nos permite volver a cargar el mismo archivo
    }
  };

  const handleFilterText = (text) => {
    setFilterText(text);
    if (text === "") {
      setData(dataBackup);
      props._generateLink(
        props.currentAccount,
        historyDL,
        props.currentPageParams,
        props.currentIdTemplate,
        ""
      );
    } else {
      props._generateLink(
        props.currentAccount,
        historyDL,
        props.currentPageParams,
        props.currentIdTemplate,
        text
      );
    }
  };

  const handleShowColumnChange = (column, show) => {
    let index = columnsSelect.findIndex((col) => col.selector === column);
    let aux = columnsSelect.map((col) => col);
    if (index === -1) {
      // * columnas de las categorias
      let index = columnsSelect.findIndex((col) => col.id === column);
      let aux = columnsSelect.map((col) => col);
      aux[index].omit = show;
      return;
    }
    // if (index === -1) return;
    aux[index].omit = show;
    setColumnsSelect(aux);
  };

  const handleColumnSortChange = (positions) => {
    setColumnsSelect((data) => {
      positions.forEach((columnId, position) => {
        let idx = data.findIndex((col) => col.id === columnId);
        if (idx != -1) {
          data[idx].position = position;
        }
      });
      data = _.orderBy(data, ["position"]);
      return data;
    });
  };

  const toLower = (value) =>
    value?.toString().toLowerCase().includes(filterText.toLowerCase());

  let filteredData = data?.filter(
    (item) =>
      toLower(item?.name) ||
      toLower(item.idMixDetaill) ||
      toLower(item.medio) ||
      toLower(item.searchable_status) ||
      toLower(item.publico) ||
      toLower(item.banner) ||
      toLower(item.purchase_unit) ||
      toLower(item.budgetName) ||
      toLower(item.idBudget) ||
      (item.id_sale > 0 && toLower(item.id_sale)) ||
      columnsSelect.some((column) => {
        const value = item[column.id];
        return value && toLower(value);
      })
  );

  const importFormatAction = async () => {
    try {
      props._showLoader(true);
      const data = await APILA.getTemplateDefault();
      const url = data.data.url;
      props._showLoader(false);
      window.open(url, "_blank", "noopener");

      return null;
    } catch (error) {
      console.log("Error en importFormatAction", error);
    }
  };


  const handleFilterPerColumn = async (value) => {
    const newValue = value.filter((el) => el.value !== "");

    if (newValue.length > 0) {
      props._showLoader(true);
      analytics.filtro({
        ubicacion: "Dashboard",
      });
      setFilterInfo([...newValue]);
      let dataToFilterr = dataBackup;
      if (dataToFilterr.length > 0) {
        let dataFinal = [];
        newValue.forEach(async (el) => {
          if (el.column === "Estado") {
            let dataAlt = dataToFilterr.filter(
              (ele) => ele.searchable_status === el.value
            );
            if (dataAlt.length > 0) {
              dataFinal = [...dataFinal, ...dataAlt];
            }
          }
          if (el.column === "Pais") {
            let dataAlt = dataToFilterr.filter(
              (ele) => ele.name_region === el.value
            );
            if (dataAlt.length > 0) {
              dataFinal = [...dataFinal, ...dataAlt];
            }
          }
          if (el.column === "Formato") {
            let dataAlt = dataToFilterr.filter(
              (ele) => ele.formato === el.value
            );
            if (dataAlt.length > 0) {
              dataFinal = [...dataFinal, ...dataAlt];
            }
          }
          if (el.column === "Medio") {
            let dataAlt = dataToFilterr.filter((ele) => ele.medio === el.value);
            if (dataAlt.length > 0) {
              dataFinal = [...dataFinal, ...dataAlt];
            }
          }
          if (el.column === "Banner") {
            let dataAlt = dataToFilterr.filter(
              (ele) => ele.banner === el.value
            );
            if (dataAlt.length > 0) {
              dataFinal = [...dataFinal, ...dataAlt];
            }
          }
          if (el.column === "purchase_unit") {
            let dataAlt = dataToFilterr.filter(
              (ele) => ele.purchase_unit === el.value
            );
            if (dataAlt.length > 0) {
              dataFinal = [...dataFinal, ...dataAlt];
            }
          }
          if (el.column === "Nombre de presupuesto") {
            let dataAlt = dataToFilterr.filter(
              (ele) => ele.budget_name === el.value
            );
            if (dataAlt.length > 0) {
              dataFinal = [...dataFinal, ...dataAlt];
            }
          }
          if (el.column === "Nombre") {
            let dataAlt = dataToFilterr.filter((ele) =>
              ele.name.toLowerCase().includes(el.value.toLowerCase())
            ); // ele.name === el.value);
            if (dataAlt.length > 0) {
              dataFinal = [...dataFinal, ...dataAlt];
            }
          }
        });
        let set = new Set(dataFinal.map(JSON.stringify));
        let arrSinDuplicaciones = Array.from(set).map(JSON.parse);
        setData([...arrSinDuplicaciones]);
      }
      props._showLoader(false);
    } else {
      setFilterInfo([]);
      setData(dataBackup);
    }
  };

  const dataFil = [];

  dataBackup.forEach((ele) => {
    if (ele.name_region) {
      dataFil.push({ nombrePadre: "Pais", name: ele.name_region });
    }
    if (ele.medio) dataFil.push({ nombrePadre: "Medio", name: ele.medio });
    if (ele.budgetName)
      dataFil.push({
        nombrePadre: "Nombre de presupuesto",
        name: ele.budgetName,
      });
    if (ele.banner) {
      dataFil.push({ nombrePadre: "Objetivo", name: ele.banner });
    }
    if (ele.budget_name) {
      dataFil.push({
        nombrePadre: "Nombre de presupuesto",
        name: ele.budget_name,
      });
    }
    if (ele.formato) {
      dataFil.push({ nombrePadre: "Formato", name: ele.formato });
    }
  });

  const handleLabelClick = () => {
    setShowModalConfirmUpload(true);
    initialize();
  };

  function initialize() {
    document.body.onfocus = checkIt;
  }

  // * verificamos si cancelaron la subida de archivos para poder mostrar el otro input de tipo text
  function checkIt() {
    if (!fileInputRef.current?.value?.length) {
      setConfirmUploadExcel(false);
      document.body.onfocus = null;
    }
  }

  const deleteTemplate = async () => {
    try {
      if (Object.keys(props.edit).length > 0) {
        Swal.fire({
          title: "¿Estás seguro?",
          text: "¿Deseas eliminar la plantilla actual?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Confirmar",
          cancelButtonText: "Cancelar",
        }).then(async (result) => {
          if (!result.isConfirmed) return;
          await props._deleteCurrentTemplate(
            props.edit.id,
            props.currentAccount
          );
          setOption("1");
        });
      }
    } catch (error) {
      throw new Error(error);
    }
  };

  const convertTemplateDefault = () => {
    try {
      if (props.editActivateTemplate) {
        Swal.fire({
          title: "Tienes cambios sin guardar",
          text: "Guarda tus cambios para convertir esta plantilla a una predeterminada.",
          icon: "warning",
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          bottonInvert: true,
          showCancelButton: false,
          confirmButtonText: "Aceptar",
        });
        return;
      }

      if (
        Object.keys(props.edit).length > 0 &&
        props.edit?.account !== "default"
      ) {
        Swal.fire({
          title: "¿Estás seguro?",
          text: "¿Deseas convertir esta plantilla en tu plantilla predeterminada?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Confirmar",
          cancelButtonText: "Cancelar",
          bottonInvert: true,
        }).then(async (result) => {
          if (!result.isConfirmed) return;
          await props._convertTemplateDefault(
            props.currentAccount,
            props.edit.id
          );
        });
      }
    } catch (error) { }
  };

  const actualizarEstado = async (id, estado) => {
    try {
      props._showLoader(true);
      let body = {
        id: id,
        active: estado,
      };
      await APILA.updateViewActive(body);
      actualizarViews(false);
    } catch (error) {
      throw new Error(error);
    } finally {
      props._showLoader(false);
    }
  };

  const handleAddView = async (name) => {
    props._showLoader(true);
    let id = views.find((ele) => ele.active);
    if (id) actualizarEstado(id.id, false);
    let viewEnc = views.find((ele) => ele.name === name);
    let idVIew = "";
    if (viewEnc) idVIew = viewEnc.id;
    else idVIew = v4();
    let columns = columnsSelect.map((item) => {
      return {
        id: item.id,
        position: item.position,
        omit: item.omit,
        pinned: item.pinned,
        selector: item.selector,
        center: item.center,
        maxWidth: item.maxWidth,
        minWidth: item.minWidth,
        // name: item.name,
        nameCol: item.nameCol,
        show: item.show,
        sortable: item.sortable,
        style: item.style,
        tag: item.tag,
        visible: item.visible,
        type: item.type,
        selector: item.selector,
      };
    });
    try {
      let body = {
        id: idVIew,
        name,
        component: CURRENT_MODULE,
        columns: columns,
        account: props.currentAccount,
        user: props.currentUser,
        active: true,
      };
      await APILA.insertView(body);
      actualizarViews(true);
    } catch (error) {
      throw new Error(error);
    } finally {
      props._showLoader(false);
    }
  };

  const handleDeleteView = async (name) => {
    try {
      props._showLoader(true);
      let id = views.find((ele) => ele.active);
      if (id) {
        if (id.id === name) {
          setNameView("lista");
          columnsSelect.forEach((ele) => {
            handleShowColumnChange(ele.selector, true);
          });
          handleColumnSortChange(orderDefault);
        }
      }
      await APILA.deleteView({ id: name });
      actualizarViews();
    } catch (error) {
      throw new Error(error);
    } finally {
      props._showLoader(false);
    }
  };

  const handleShowView = async (vista, show) => {
    let nombres = [];
    let id = views.find((ele) => ele.active);
    if (id) actualizarEstado(id.id, false);
    actualizarEstado(vista, show);
    let vistaEnc = views.find((ele) => ele.id === vista);
    vistaEnc.columns.sort((a, b) => {
      return a.position - b.position;
    });
    vistaEnc.columns.forEach((ele) => {
      if (show) {
        nombres.push(ele.id);
        handleShowColumnChange(ele.selector || ele.id, ele.omit);
      } else {
        handleShowColumnChange(ele.selector || ele.id, false);
      }
    });
    if (show) {
      handleColumnSortChange(nombres);
    } else {
      handleColumnSortChange(orderDefault);
    }
  };

  const actualizarViews = async (tipo) => {
    try {
      props._showLoader(true);
      if (Auth.user) {
        let sub = Auth.user.signInUserSession.idToken.payload.sub;
        let viewsData = await APILA.getViewsByUser({
          account: props.currentAccount,
          user: sub,
          component: CURRENT_MODULE,
        });
        setViews(viewsData.data.views);
        if (viewsData.data.views.length === 0) {
          setNameView("lista");
        } else {
          let vistaActiva = viewsData.data.views.find((ele) => ele.active);
          if (vistaActiva) {
            setNameView(vistaActiva.name);
            if (tipo) {
              let nombres = [];
              vistaActiva.columns.forEach((ele) => {
                nombres.push(ele.id);
                handleShowColumnChange(ele.selector, ele.omit);
              });
              handleColumnSortChange(nombres);
            }
          } else {
            columnsSelect.forEach((ele) => {
              handleShowColumnChange(ele.selector, ele.omit);
            });
            handleColumnSortChange(orderDefault);
            setNameView("lista");
          }
        }
      }
    } catch (error) {
      throw new Error(error);
    } finally {
      props._showLoader(false);
    }
  };

  useEffect(() => {
    if (confirmUploadExcel && fileInputRef.current) {
      fileInputRef.current.click();
    }
  }, [confirmUploadExcel]);

  const isDateInvalid = (fechaIni, fechaFin) => {
    const parsedFechaIni = parse(fechaIni, "dd.MM.yyyy", new Date());
    const parsedFechaFin = parse(fechaFin, "dd.MM.yyyy", new Date());

    if (!isValid(parsedFechaIni) || !isValid(parsedFechaFin)) {
      return true;
    }

    return (
      fechaIni === "" ||
      fechaFin === "" ||
      fechaIni === null ||
      fechaFin === null ||
      isAfter(parsedFechaIni, parsedFechaFin)
    );
  };

  useEffect(() => {
    if (!_.isEmpty(props.edit)) {
      props.edit.previsualizer?.forEach((column) => {
        if (
          !column?.id_medium ||
          !column?.id_region ||
          !column?.id_banner ||
          !column?.id_format ||
          !column?.idBudget ||
          !column?.idAudience ||
          !column?.budget ||
          !column?.medio ||
          !column?.objetivo ||
          !column?.banner ||
          !column?.formato ||
          !column?.name_region ||
          !column?.fechaFin ||
          !column?.fechaIni ||
          // !column?.id_ad_product ||
          isDateInvalid(column.fechaIni, column.fechaFin, column)
        ) {
          props._setImplementButton(true);
        } else {
          props._setImplementButton(props.editActivateTemplate)
          // if (!props.editActivateTemplate) props._setImplementButton(false);
        }
      });
    }
  }, [props.edit]);

  if (!permissions.view) {
    return (
      <h1
        style={{
          marginTop: "8rem",
          marginLeft: "3rem",
          position: "absolute",
        }}
      >
        No tienes permisos
      </h1>
    );
  }

  if (props.isIpv4) {
    return (
      <>
        {templateInfo.length > 1 ? (
          <>
            <div className="content-dashboard">
              <div className="content-menu contenedorDashboard">
                <div className="barraSuperior">
                  <Select
                    id="viewSelect"
                    value={option}
                    showSearch={true}
                    onChange={handleChangeOptionSelect}
                    className="select-dashboard"
                  >
                    <Option value={"1"} className="fa">
                      <Tooltip
                        title="Vista de lista"
                        placement="rightTop"
                        color="#002448"
                      >
                        <div>
                          <i className="fa fa-table"></i> Selecciona una
                          plantilla{" "}
                        </div>
                      </Tooltip>
                    </Option>
                    {templateInfo.map((ele, idx) => (
                      <Option value={ele.id} key={idx} className="fa">
                        <Tooltip
                          title="Vista de lista"
                          placement="rightTop"
                          color="#002448"
                        >
                          <span>
                            <i className="fa fa-table"></i> {ele.name}{" "}
                          </span>
                        </Tooltip>
                      </Option>
                    ))}
                  </Select>
                  {Object.keys(props.edit).length > 0 &&
                    props.edit?.account !== "default" && (
                      <Tooltip
                        title="Actualizar nombre de la plantilla actual"
                        placement="top"
                        color="#002448"
                      >
                        <button
                          style={{
                            border: "none",
                            background: "none",
                            boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.2)",
                            color: "#02025b",
                          }}
                          onClick={updNameTemplate}
                        >
                          <i
                            className="fas fa-edit text-blue "
                            style={{ fontSize: "24px" }}
                          ></i>
                        </button>
                      </Tooltip>
                    )}
                  <DataTableUtils
                    data={[]}
                    action={
                      permissions.create === false || _.isEmpty(props.edit)
                        ? null
                        : () => setShowModal(true)
                    }
                    actionIcon={
                      Object.entries(props.edit).length !== 0 &&
                        permissions.create ? (
                        <Tooltip
                          color="#002448"
                          placement="top"
                          title="Crear campaña"
                        >
                          <i className="fas fa-plus" />
                        </Tooltip>
                      ) : null
                    }
                    onChangeFilterText={(value) => {
                      handleFilterText(value);
                    }}
                    valueFilter={filterText || ""}
                  >
                    <Tooltip
                      color="#002448"
                      placement="top"
                      title="Exportar información"
                    >
                      <div className="ExportBtn">
                        {permissions.export && (
                          <Share
                            optionsText={{
                              exportXLSX: "Exportar esta plantilla",
                              importXLSX: "Importar esta plantilla",
                            }}
                            filename="plantilla"
                            data={exportDataToExcel()}
                            columns={[]}
                            isArray={true}
                            importFormat={true}
                            exportTemplate={true}
                            exportXLSX={true}
                            exportTXT={true}
                            exportDropbox={false}
                            importFormatAction={importFormatAction()}
                            importTemplate={generateTemplate}
                            exportDrive={true}
                            exportCSV={true}
                            printable={true}
                            exportPNG={true}
                            exportLink={true}
                            idImg={"root"}
                            urlTemplate={
                              "https://admintpp-my.sharepoint.com/:x:/r/personal/rayner_lets-advertise_com/Documents/Let%27s%20Advertise/Let%27s%20Advertise%20Ads/Secci%C3%B3n%20Planificar/Formato%20importaci%C3%B3n%20mix%20de%20medios%20-%20Let%27s%20Advertise%20ADS.xlsx?d=wcfee2a7299544767825446ae159c8d2d&csf=1&web=1&e=DoagPi"
                            }
                            appKey="2u78gno172idwbz"
                          />
                        )}
                      </div>
                    </Tooltip>
                    <Tooltip
                      color="#002448"
                      placement="top"
                      title="Personalizar columnas"
                    >
                      <div id="shareBtn">
                        <ShowColumn2
                          columns={columnsSelect}
                          vistas={[]}
                          showColumn={handleShowColumnChange}
                          onSortChange={handleColumnSortChange}
                        />
                      </div>
                    </Tooltip>

                    <Tooltip
                      color="#002448"
                      placement="top"
                      title="Filtrar y agrupar"
                    >
                      <div className="Filtro2">
                        <FilterColumn2
                          options={[]}
                          changeGrouped={() => { }}
                          columns={columnsVisualizer}
                          data={dataFil}
                          onFilterColumn={handleFilterPerColumn}
                          filterInfoCheck={filterInfo}
                          categories={[
                            "Objetivo",
                            "Medio",
                            "Pais",
                            "Nombre de presupuesto",
                            "Formato",
                          ]}
                        />
                      </div>
                    </Tooltip>
                  </DataTableUtils>
                </div>
                <ViewTemplates
                  state={state}
                  data={filteredData}
                  columns={columnsSelect}
                  init={init}
                  setDataBackup={setDataBackup}
                  filterText={filterText}
                  setData={setData}
                  setColumns={setColumnsSelect}
                  setShowModal={setShowModal}
                  showModal={showModal}
                  setCategoriesData={setCategoriesData}
                  permissions={permissions}
                />
                {Object.entries(props.edit).length === 0 && (
                  <div className="form homeSinLogin">
                    <div>
                      <p className="subTitle">Bienvenido a Let's Advertise</p>
                      <h1 className="title">Crea tu primera plantilla</h1>
                      <label
                        htmlFor="fileInput"
                        className="btn-plus"
                        style={{ width: "4rem", cursor: "pointer" }}
                      >
                        <i className="fas fa-plus" />
                      </label>
                      <input
                        type="file"
                        id="fileInput"
                        accept=".csv,.xls,.xlsx"
                        style={{ display: "none" }}
                        className="input-file-excel"
                        onChange={(e) => {
                          const mCurrentAccount =
                            props.currentUser !== ""
                              ? props.currentAccount
                              : props.currentPWAAccount;

                          if (mCurrentAccount === "") {
                            Swal.fire({
                              title: "Cuenta Requerida",
                              text: "Para utilizar esta sección, debes crear una cuenta.",
                              icon: "warning",
                            }).then((response) => {
                              if (!response.isConfirmed) return;
                              if (props?.accounts.length > 0) return;
                              props._nexStep(1);
                            });
                            return;
                          }
                          handleFileChange(e);
                        }}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
            {props.step == 1 && (
              <CreateAccountLogOut
                open={nameCamp}
                clase={"component"}
                setNameCamp={setNameCamp}
              />
            )}
          </>
        ) : (
          <>
            <div className="form homeSinLogin">
              <div>
                <p className="subTitle">Bienvenido a Let's Advertise</p>
                <h1 className="title">Crea tu primera plantilla</h1>
                <label
                  htmlFor="fileInput"
                  className="btn-plus"
                  style={{ width: "4rem", cursor: "pointer" }}
                >
                  <i className="fas fa-plus" />
                </label>
                <input
                  type="file"
                  id="fileInput"
                  accept=".csv,.xls,.xlsx"
                  style={{ display: "none" }}
                  className="input-file-excel"
                  onChange={(e) => {
                    const mCurrentAccount =
                      props.currentUser !== ""
                        ? props.currentAccount
                        : props.currentPWAAccount;

                    if (mCurrentAccount === "") {
                      Swal.fire({
                        title: "Cuenta Requerida",
                        text: "Para utilizar esta sección, debes crear una cuenta.",
                        icon: "warning",
                      }).then((response) => {
                        if (!response.isConfirmed) return;
                        if (props?.accounts.length > 0) return;
                        props._nexStep(1);
                      });
                      return;
                    }
                    handleFileChange(e);
                  }}
                />
              </div>
            </div>
            3
          </>
        )}
      </>
    );
  }

  return (
    <>
      <div className="content-templates">
        <div className="content-menu contenedorDashboard">
          <div className="barraSuperior">
            <Tooltip
              title="Vista de lista"
              placement="rightTop"
              color="#002448"
            >
              <Select
                showSearch
                id="viewSelect"
                value={option}
                optionFilterProp="children"
                onChange={handleChangeOptionSelect}
                className="select-templates-selector"
                style={{
                  textAlign: "center",
                  width: "30%",
                  borderRadius: "15px",
                }}
              >
                {templateInfo.map((ele, idx) => (
                  <Option
                    value={ele.id}
                    key={idx}
                    className="fa"
                    style={{ marginRight: "10px" }}
                  >
                    {ele?.isDefaultByAccount && (
                      <i
                        style={{ marginRight: "10px" }}
                        className="fa fa-table"
                      ></i>
                    )}
                    {ele.name}
                  </Option>
                ))}
              </Select>
            </Tooltip>
            <>
              {Object.keys(props.edit).length > 0 && (
                <Buttons
                  updNameTemplate={updNameTemplate}
                  deleteTemplate={deleteTemplate}
                  convertTemplateDefault={convertTemplateDefault}
                  templateInformation={props.edit}
                  permission={permissions}
                />
              )}
            </>
            <div style={{ width: "100%" }}>
              <DataTableUtils
                data={[]}
                // showTooltip={true}
                //Dame una validacion con ternario que valide si props.edit es vacio o permission.create es falso, si es falso que no muestre el boton de crear campaña
                action={
                  permissions.create === false || _.isEmpty(props.edit)
                    ? null
                    : () => setShowModal(true)
                }
                actionIcon={
                  Object.entries(props.edit).length !== 0 &&
                    permissions.create ? (
                    <Tooltip
                      color="#002448"
                      placement="top"
                      title="Crear campaña"
                    >
                      <i className="fas fa-plus" />
                    </Tooltip>
                  ) : null
                }
                onChangeFilterText={(value) => {
                  handleFilterText(value);
                }}
                valueFilter={filterText || ""}
              >
                <Tooltip
                  color="#002448"
                  placement="top"
                  title="Exportar información"
                >
                  <div className="ExportBtn__templates">
                    {permissions.export && (
                      <Share
                        optionsText={{
                          exportXLSX: "Descargar esta plantilla",
                          importXLSX: "Importar una plantilla",
                        }}
                        importFormatAction={importFormatAction}
                        data={exportDataToExcel()}
                        columns={[]}
                        isArray={true}
                        importFormat={true}
                        exportTemplate={true}
                        exportXLSX={true}
                        exportTXT={true}
                        exportDropbox={false}
                        importTemplate={generateTemplate}
                        exportDrive={true}
                        exportCSV={true}
                        printable={true}
                        exportPNG={true}
                        exportLink={true}
                        idImg={"root"}
                        appKey="2u78gno172idwbz"
                      />
                    )}
                  </div>
                </Tooltip>

                <Tooltip
                  color="#002448"
                  placement="top"
                  title="Personalizar columnas"
                >
                  <div id="shareBtn">
                    <ShowColumn2
                      columns={columnsSelect}
                      showColumn={handleShowColumnChange}
                      showView={handleShowView}
                      onSortChange={handleColumnSortChange}
                      vistas={views || []}
                      setVista={(nombre) => {
                        handleAddView(nombre);
                      }} // Para crear una vista
                      deleteView={(name) => {
                        handleDeleteView(name);
                      }}
                      nameAc={nameView}
                    />
                  </div>
                </Tooltip>

                <Tooltip
                  color="#002448"
                  placement="top"
                  title="Filtrar y agrupar"
                >
                  <div className="Filtro2">
                    <FilterColumn2
                      options={[]}
                      changeGrouped={() => { }}
                      columns={columnsVisualizer}
                      data={dataFil}
                      onFilterColumn={handleFilterPerColumn}
                      filterInfoCheck={filterInfo}
                      categories={[
                        "Objetivo",
                        "Medio",
                        "Pais",
                        "Nombre de presupuesto",
                        "Formato",
                      ]}
                    />
                  </div>
                </Tooltip>
              </DataTableUtils>
            </div>
          </div>
          <ViewTemplates
            state={state}
            data={filteredData}
            columns={columnsSelect}
            init={init}
            setDataBackup={setDataBackup}
            filterText={filterText}
            setData={setData}
            setColumns={setColumnsSelect}
            setShowModal={setShowModal}
            showModal={showModal}
            setCategoriesData={setCategoriesData}
            setOption={setOption}
            permissions={permissions}
          />
          {Object.entries(props.edit).length === 0 && permissions.create && (
            <div className="form homeSinLogin__">
              <div style={{ marginBottom: "rem" }}>
                <p className="subTitle">Bienvenido a Let's Advertise</p>
                <h1 className="title">Crea tu primera plantilla</h1>
                <label
                  onClick={() => {
                    handleLabelClick();
                  }}
                  htmlFor="fileInput"
                  className="btn-plus"
                  style={{ width: "4rem", cursor: "pointer" }}
                >
                  <i className="fas fa-plus" />
                </label>
                <input
                  type="text"
                  id="fileInput-Text"
                  style={{ display: "none" }}
                  className="input-file-excel"
                />
              </div>
            </div>
          )}
        </div>
      </div>
      {props.step == 1 && (
        <CreateAccountLogOut
          open={nameCamp}
          clase={"component"}
          setNameCamp={setNameCamp}
        />
      )}
      {showModalConfirmUpload && (
        <ModalConfirmUploadTempleta
          setConfirmUploadExcel={setConfirmUploadExcel}
          showModal={showModalConfirmUpload}
          closeModal={() => setShowModalConfirmUpload(false)}
          generateTemplateEmpty={generateTemplateEmpty}
          setShowModalPauta={setShowModalPauta}
          handleFileChange={handleFileChange}
        />
      )}
      {showModalPauta && (
        <div className="blur-div blurNewPauta">
          <div
            className={classnames("upsert-modal w-75 h-75 modalapauta")}
            style={{
              backgroundColor: "white",
              padding: "20px",
              borderRadius: "10px",
            }}
          >
            <ModalPauta
              createTemplateEmpty={generateTemplateEmpty}
              setShowModal={setShowModalPauta}
              module={"template"}
            />
          </div>
        </div>
      )}
    </>
  );
};
const mapStateToProps = (state) => ({
  step: state.userLogout.nextStepCategory,
  isIpv4: state.app.isIPV4,
  clients: state.clients,
  accounts: state.accounts.list,
  currentUser: state.app.current_user,
  templates: state.templates.list,
  editActivateTemplate: state.templates.editActivate,
  edit: state.templates.edit,
  pwaAccounts: state.pwa.accounts.list,
  users: state.users,
  currentAccount: state.accounts.default,
  currentIdTemplate: state.templateDL.currentTemplate,
  currentPageParams: state.templateDL.currentPage,
  currentFilterText: state.templateDL.filterText,
});

const mapDispatchToProps = (dispatch) => ({
  _setImplementButton: (value) => dispatch(setImplementButton(value)),
  _alertEdit: (edit, account) => dispatch(alertEdit(edit, account)),
  _uploadTemplateEmpty: (
    account,
    name,
    setOption,
    history,
    isSelected,
    format
  ) =>
    dispatch(
      uploadTemplateEmpty(account, name, setOption, history, isSelected, format)
    ),
  _convertTemplateDefault: (currentAccount, idTemplate) =>
    dispatch(convertTemplateDefault(currentAccount, idTemplate)),
  _deleteCurrentTemplate: (idTemplate, currentAccount) =>
    dispatch(deleteTemplate(idTemplate, currentAccount)),
  _newTemplateForAccount: (data, account) =>
    dispatch(newTemplateForAccount(data, account)),
  _nexStep: (step) => dispatch(nextStep(step)),
  _showLoader: (show) => dispatch(showLoader(show)),
  _getCategories: (account, user) => dispatch(getCategories(account, user)),
  _setEditActivate: (edit) => dispatch(setEditActivate(edit)),
  _upsertTemplate: (body, account, name, setOption, id, history, isSelected) =>
    dispatch(
      upsertTemplate(body, account, name, setOption, id, history, isSelected)
    ),
  _getTemplates: (account, id) => dispatch(getTemplates(account, id)),
  _setEdit: (edit) => dispatch(setEdit(edit)),
  _clearEdit: () => dispatch(clearEdit()),
  _updateTemplateVisualizer: (template, data) =>
    dispatch(updateTemplateVisualizer(template, data)),
  _updateNameTemplate: (newName, edit, userData) =>
    dispatch(updateNameTemplate(newName, edit, userData)),
  _generateLink: (currentAccount, history, page, templateId, filterText) =>
    dispatch(
      generateLink(currentAccount, history, page, templateId, filterText)
    ),
  _asyncGetCountries: () => dispatch(asyncGetNameRegion()),
  _initialLink: (account, history) => dispatch(initialLink(account, history)),
  _getAudiences: (account) => dispatch(getAudiences(account)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withUtils(withRouter(Content)));
