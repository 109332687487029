import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import './assets/css/styles.css'

const Home = props => {

  const titulo = step =>{
    if (step == 1) {
      return (<div className="title-view ene-view" id="Contitulo"><p id="titulo">a</p></div>)
    }else{
      return(<div className="title-view " id="Contitulo">{props.campaign.name}</div>)
    }
  }

  const medio = step =>{
    
    if (step == 1 && !props.campaign.redSocial) {
      return (<div className="red-view dis-view" id="ConMedio"><p id="medio">a</p></div>)
    }
    else if(step == 2) {
      return(<div className="red-view ene-view" id="ConMedio"><p id="medio">a</p></div>)
    }else{
      return(<div className="red-view" id="ConMedio"><p id="medio">{props.campaign.redSocial}</p></div>)
    }
  }

  const fechas = step =>{
    if (step < 3 && !props.campaign.iniDate) {
      return (
        <div className="fecha-view">
          <p className="dis-view dat" id="iniDate">a</p>
          <p className="dis-view dat" id="finDate">a</p>
        </div>
      )
    }
    else if(step == 3) {
      return(
        <div className="fecha-view">
          <p className="dat insert ene-view" id="iniDate">a</p>
          <p className="dat insert ene-view" id="finDate">a</p>
        </div>
      )
    }else{
      return(
        <div className="fecha-view">
          <p className="dat insert" id="iniDate">{props.campaign.iniDate}</p>
          <p className="dat insert" id="finDate">{props.campaign.endDate}</p>
        </div>
      )
    }
  }

  const objetivo = step =>{
    if (step < 4 && !props.campaign.objetivo) {
      return (<div className="dis-view title-view"><p>a</p></div>)
    }
    else if(step == 4) {
      return(<div className="dis-view title-view ene-view"><p>a</p></div>)
    }else{
      return(<div className="title-view"><p>{props.campaign.objetivo}</p></div>)
    }
  }

  const tags = step =>{
    if (step < 5) {
      return (
        <div className="fecha-view">
            <p className="dis-view tag">a</p><p className="dis-view tag">a</p><p className="dis-view tag">a</p>
            <p className="dis-view tag">a</p><p className="dis-view tag">a</p><p className="dis-view tag">a</p>
            <p className="dis-view tag">a</p><p className="dis-view tag">a</p><p className="dis-view tag">a</p>
        </div>
      )
    }
    else{
      return(
        <div className="fecha-view">
            <p className="dis-view tag color">a</p><p className="dis-view tag">a</p><p className="dis-view tag">a</p>
            <p className="dis-view tag">a</p><p className="dis-view tag">a</p><p className="dis-view tag">a</p>
            <p className="dis-view tag">a</p><p className="dis-view tag">a</p><p className="dis-view tag">a</p>
        </div>
      )
    }
  }

  return(
    <div className="right-form">
      <div className="view-camp">
          {titulo(props.campaign.step)}
          {medio(props.campaign.step)}
          {fechas(props.campaign.step)}
          {objetivo(props.campaign.step)}
          {tags(props.campaign.step)}
      </div>
    </div>
  )
}

const mapStateToProps = state => ({
  campaign: state.campaign,
})

export default connect(mapStateToProps)(withRouter(Home))

//export default 
