import React from 'react'

// components
import MetaTags from '../../components/metatags'
import Home from '../../components/home'
import SignInForm from '../../components/auth/signin_form'

const SignIn = props => (
  <>
    <MetaTags title='Inicio de Sesión' description='Página de inicio de sesión' />
    <Home {...props}/>
    {/* <SignInForm {...props} /> */}
  </>
)

export default SignIn
