import * as Actions from "../actions/odooOvs.actions";

const initialState = {
  isLoading: false,
  currentOvs: [],
  portal_name: "lets-advertise",
  lastSinc: "",
};

export default (state = initialState, action) => {
  switch (action.type) {
    case Actions.APP_LOADED:
      return {
        ...state,
        isLoading: false,
      };
    case Actions.APP_SET_CURRENT_ODOO:
      return {
        ...state,
        currentOvs: action.odoo,
      };
    case Actions.APP_CLEAR_CURRENT_ODOO:
      return {
        ...state,
        currentOvs: "",
      };
    case Actions.APP_SET_LAST_SINC:
      return {
        ...state,
        lastSinc: action.lastSinc,
      };
    default:
      return state;
  }
};
