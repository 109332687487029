import { useState } from "react";
import PageLoader from "components/page_loader";
import DataTableUtils, {
  Share,
  ShowColumn2,
  ShowColumn,
  FilterColumn,
  FilterColumn2,
} from "@easygosanet/react-datatable-utils";
import ReactDataTable, {
  OptionsSelectedEdit,
  OptionNewEdit,
  EditAnt,
  SimpleText,
} from "@easygosanet/react-datatable";
import { Tooltip } from "antd";
import ModalAsociationIds from "./ModalAsociationIds";

const AsociationIds = (props) => {
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const list = [
    {
      id: "column1",
      omit: false,
      name: "Column1",
      selector: "column1",
      visible: false,
      sortable: true,
      center: true,
      tag: "column1",
      cell: (row) => <p>Dato 1</p>,
    },
    {
      id: "column2",
      omit: false,
      name: "Column2",
      selector: "column2",
      visible: false,
      sortable: true,
      center: true,
      tag: "column2",
      cell: (row) => <p>Dato 1</p>,
    },
  ];

  const customStyles = {
    headCells: {
      style: {
        color: "#000",
        fontSize: "0.9rem",
        textAlign: "center",
        justifyContent: "center",
        backgroundColor: "#fff",
        width: "160px",
        "&:nth-of-type(2n)": {
          backgroundColor: "#eff0f2",
          borderRadius: "10px 10px 0 0",
        },
      },
    },
    cells: {
      style: {
        justifyContent: "center",
        backgroundColor: "#fff",
        "&:nth-of-type(1)": {
          justifyContent: "left",
        },
        "&:nth-of-type(2n)": {
          backgroundColor: "#eff0f2",
        },
      },
    },
  };

  return (
    <main className="main__IntegracionOdoo main__asociationIds">
      {loading ? (
        <PageLoader />
      ) : (
        <>
          <div className="barraSuperior">
            <select
              defaultValue={"1"}
              value={props.value}
              onChange={(e) => props.setValue(e.currentTarget.value)}
            >
              <option value={"1"}>Conexión ERP</option>
            </select>
            <DataTableUtils
              data={[]}
              action={() => {
                setShowModal(true);
              }}
              actionIcon={
                <Tooltip color="#002448" placement="top" title="Asociar IDs">
                  <i className="fas fa-plus" />
                </Tooltip>
              }
            //onChangeFilterText={handleFilterTextChange}
            >
              <Tooltip
                color="#002448"
                placement="top"
                title="Exportar información"
              >
                <div className="ExportBtn">
                  <Share />
                </div>
              </Tooltip>
              <Tooltip
                color="#002448"
                placement="top"
                title="Personalizar columnas"
              >
                <div id="shareBtn">
                  <ShowColumn columns={list} />
                </div>
              </Tooltip>
              <Tooltip
                color="#002448"
                placement="top"
                title="Filtrar y agrupar"
              >
                <div className="Filtro2 filtro3">
                  <FilterColumn columns={list} />
                </div>
              </Tooltip>
            </DataTableUtils>
          </div>

          <section className="main_emails_sectionTable">
            <ReactDataTable
              columns={list}
              //data={filteredData}
              persistTableHead
              customStyles={customStyles}
              pagination
              paginationComponentOptions={{
                rowsPerPageText: "Elementos por página",
                rangeSeparatorText: "de",
              }}
              noDataComponent={"No hay datos para mostrar"}
            //paginationComponentOptions={esp}
            //noHeader
            />
          </section>
          {showModal && (
            <ModalAsociationIds
              showModal={showModal}
              setShowModal={setShowModal}
            />
          )}
        </>
      )}
    </main>
  );
};

export default AsociationIds;
