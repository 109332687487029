import React, { useState } from "react";
import Swal from "sweetalert2";
import { FormGroup, Label, Input, FormFeedback } from "reactstrap";
import { v4 } from "uuid";
import { useHistory } from "react-router-dom";

const ModalCreate = (props) => {
  const { setShowModal, currentAccount, edit, newTemplateForAccount } = props;
  const [nameValue, setNameValue] = useState("");
  const history = useHistory();
  const [errorsName, setErrorsName] = useState(false);

  const saveChagnes = async () => {
    try {
      if (nameValue === "") {
        setErrorsName(true);
        return;
      }
      let newTemplate = edit;
      let id = v4();
      newTemplate.name = nameValue;
      let newTemplateCopy = true
      await newTemplateForAccount(newTemplate, currentAccount, props.setOption, id, history, newTemplateCopy);
    } catch (error) {
      Swal.fire("Ups!", "Hubo un error!", "error");
    } finally {
      setShowModal(false);
    }
  };

  const handleInputChange = (e) => {
    const { value } = e.target;
    setNameValue(value);
    if (value === "") {
      setErrorsName(true);
    } else {
      setErrorsName(false);
    }
  };

  return (
    <div className="blur-div ">
      <div className="content-modal contentModalRolUser">
        <div className="closeModal-content">
          <i
            className="fas fa-times-circle close"
            onClick={(e) => setShowModal(false)}
          ></i>
        </div>
        <div>
          <h3 className="titleModal titleModalRol"> Creación de plantilla</h3>
        </div>
        <div className="body-modal modalUsers">
          <FormGroup>
            <Label
              style={{
                color: "#05252d",
                fontWeight: 500,
                marginBottom: "5px",
              }}
            >
              Nombre de plantilla
            </Label>
            <Input
              type="text"
              value={nameValue}
              onChange={(e) => handleInputChange(e)}
              invalid={errorsName}
              placeholder="Ingresa el nombre"
            />
            <FormFeedback>Ingresa un nombre valido</FormFeedback>
          </FormGroup>
        </div>

        <div className="footer-Modal">
          <div className="buttons-Modal-content">
            <button
              className="btnModal btnCancelarModalRol"
              onClick={(e) => setShowModal(false)}
            >
              Cancelar
            </button>
            <button
              className="btnModal btnGuardarModalRol"
              onClick={saveChagnes}
            >
              Guardar
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalCreate;
