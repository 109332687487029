import { useState, useEffect, useRef, isValidElement } from "react";
import PageLoader from "components/page_loader";
import "./style.scss";
import foto from "./perfil.png";
import ReactDataTable from "@easygosanet/react-datatable";
import Select from "libs/select";
import Swal from "sweetalert2";
import * as APILA from "helpers/apiLA"
const ModalLink = ({ setShowModalLink, data, userId}) => {
  const [columns, setColumns] = useState([
    {
      omit: false,
      name: "Usuario",
      selector: "userName",
      id: "userName",
      tag: "userName",
      sortable: true,
      minWidth: "15rem",
      center: true,
    },
    {
      omit: false,
      name: "Texto filtrado",
      selector: "filteredText",
      // type:'text',
      id: "filteredText",
      tag: "filteredText",
      sortable: true,
      center: true,
      minWidth: "15rem",
      grow: 0,
    },
    {
      omit: false,
      name: "Rango de fecha",
      selector: "dateRange",
      id: "dateRange",
      tag: "dateRange",
      minWidth: "15rem",
      sortable: true,
      center: true,
      grow: 0,
    },
    {
      omit: false, 
      name: "Destinatarios", 
      selector: "membersShared", 
      id: "recipients",
      tag: "recipients",
      minWidth: "15rem",
      sortable: true,
      center: true,
      grow: 0,
    }
  ]);
  const [loading, setLoading] = useState(false);
  const [dataShared, setDataShared] = useState([])
  const [users,setUsers]= useState([])
  const [usersSelected,setUsersSelected]=useState([])
  useEffect(()=>{
    if(data.users){
      let array=[]
      data.users.forEach(ele=>{
        array.push({
          value: ele.Username,
          label: ele.Attributes[ele.Attributes.length-1].Value
        })
      })
      console.log('users',array)
      setUsers(array);
    }
    getShared()
  },[data])

  const getShared = async () => {
    setLoading(true)
    console.log('userId', userId);
    let sharedViews = await APILA.getSharedAuthor({userId: userId})
    sharedViews = sharedViews.data.sharedViews

    sharedViews.forEach((ele) => {
      ele.membersShared = ele.membersShared
        .map((member) =>
          member.userEmail.length > 20
            ? member.userEmail.substring(0, 20) + "..."
            : member.userEmail
        )
        .join(", ");
    });
    console.log('respuesta', sharedViews);

    setDataShared(sharedViews)
    setLoading(false)
  }

  const onChangeuser=(option,e)=>{  
    console.log('option,e',option,e)
    
    let found= data.users.filter(ele=>ele.Username=== option.value)
    let userName=''
    let userEmail= ''

    found[0].Attributes.forEach(ele=>{
      if(ele.Name==='name'){
        userName= ele.Value
      }
      if(ele.Name==='email'){
        userEmail= ele.Value
      }
    })

    let usersSel= usersSelected;
    usersSel.push({
      id: found[0].Username,
      userName: userName,
      userEmail: userEmail
    });

    let without= users.filter(ele=>ele.value != option.value)
    setUsersSelected([...usersSel])
    setUsers([...without])
  }

  const handleClick=async()=>{
    if(usersSelected.length>0){
      try {
            setLoading(true)
          let dataBody= data.body;
          let columns=[]
          dataBody.columns.forEach(eleColumn=>{
            let eleColumnNew={
              ...eleColumn,
              name: eleColumn.nameCol
            }
            columns.push(eleColumnNew)
          })
          console.log('dataBody',columns)
          dataBody.columns= columns
    let bodyToSend={}
    bodyToSend={
      ...dataBody,
      membersShared: usersSelected
    }
    console.log('body',bodyToSend)
    let response= await APILA.insertSharedView(bodyToSend);
    console.log('Response',response);
    setLoading(true);
    Swal.fire("Vista compartida correctamente.", " ", "success");
    setShowModalLink(false);

      } catch (error) {
       console.log('Error',error) 
      }
    
    }else{
      Swal.fire({
        title: "Advertencia",
        text: "Por favor agrega al menos un usuario a compartir.",
        icon: "warning",
        showCancelButton: false,
        confirmButtonColor: "#3085d6",
        confirmButtonText: "Ok",
      });
    }
    
  }

  return (
    <div className="blur-div">
      {
        loading ? <PageLoader/>
        : 
        <div className="content-modal modalLink">
        <div className="header-modal">
          <h3 className="titleModal">Compartir Link</h3>
        </div>
        <div className="body-modal">
          <div className="contenedor1">
          <Select
              options={users}
              value={""}
              /* isDisabled={disabled.budget} */
              placeholder="Seleccionar usuario"
              onChange={onChangeuser}
              /* className={classnames({
                "is-invalid": errors.idBudget,
              })} */
            />
            <h6>Personas con acceso</h6>
            <ul className="listaUsuarios">
              <li className="item-lista">
               {/*  <img src={foto} alt="foto de perfil" /> */}
                <div>
                  <p className="userName">{data.body.userName}</p>
                  <p className="email">{data.body.userEmail}</p>
                </div>
                <span className="rol">Propietario</span>
              </li>
              {
                usersSelected.map(eleUser=>{

                  return (
                    <li className="item-lista">
                     <div>
                       <p className="userName">{eleUser.userName}</p>
                       <p className="email">{eleUser.userEmail}</p>
                     </div>
                     <span className="rol">Compartido</span>
                   </li>
                  )
                })
              }
            </ul>
            <h6>Listado de compartidos</h6>
                <ReactDataTable
                  className="creativityTable"
                  columns={columns}
                  data={dataShared}
                  persistTableHead
                  pagination
                />
          </div>
          {/* <div className="contenedor2">
            <h6>Enlace</h6>
            <div className="containerGeneral">
              <i className="fas fa-globe-americas"></i>
              <a href="#">Link para compartir</a>
              <button>Copiar Link</button>
            </div>
          </div> */}
        </div>
        <div className="footer-Modal">
          <button
            className="btn btn_cancel btnCancelOS"
            onClick={(e) => setShowModalLink(false)}
          >
            Cancelar
          </button>
          <button className="btn btn_save btnConfirmOS" onClick={handleClick}>Agregar</button>
        </div>
      </div>
      }
     
    </div>
  );
};

export default ModalLink;
