import { combineReducers } from "redux";

import AppReducer from "./app.reducer";
import PWAReducer from "./pwa.reducer";
import ModalReducer from "./modal.reducer";
import LoaderReducer from "./loader.reducer";
import StatusReducer from "./status.reducer";
import ReportReducer from "./report.reducer";
import ClientsReducer from "./clients.reducer";
import BudgetsReducer from "./budgets.reducer";
import ProfileReducer from "./profile.reducer";
import CampaignReducer from "./campana.reducer";
import CreativeReducer from "./creative.reducer";
import AccountsReducer from "./accounts.reducer";
import AudiencesReducer from "./audiences.reducer";
import CampaignsReducer from "./dashboard.reducer";
import CategoriesReducer from "./categories.reducer";
import MediumBannerReducer from "./medioCamp.reducer";
import CampaignupdReducer from "./dashboardupd.reducer";
import CampaignupAddReducer from "./dashboardupdAdd.reducer";
import ModalDetaillReducer from "./modalDetail.reducer";
import ModalImageReducer from "./modalImage.reducer";
import PautaReducer from "./objectCampainpauta.reducer";
import campaignsbudget from "./dashboardBudget.reducer";
import fanpageDatosReducer from "./fanpageDatos.reducer";
import ObjectCampainReducer from "./objectCampain.reducer";
import fanpageAsociarReducer from "./fanpageAsociar.reducer";
import fanpageMessageReducer from "./fanpageMessage.reducer";
import fanpageDatosWebReducer from "./fanpageWebHook.reducer";
import MediumBannertypeReducer from "./mediumMedioCamp.reducer";
import filesPautaReducer from "./files.reducer";
import countryReducer from "./country.reducer";
import budget_spent from "./budget_spent.reducer";
import allCampaings from "./allCampaings.reducer";
import clients_mysql from "./clients_mysql.reducer";
import getUsersRole from "./users.reducer";
import getLogs from "./logs.reducer";
import cards from "./payment.reducer";
import exportedData from "./exportData.reducer";
import templatesReducer from "./templates.reducer";
import permisos from "./permissions.reducer";
import odooReducer from "./odooOvs.reducer";
import tableLevelsReducer from "./tableLevels.reducer";
import templateDeepLinkReducer from "./templateDeepLink.reducer";
import scheduleReducer from "./schedule.reducer";
import userLogoutReducer from "./userLogout.reducer";
import digitalAssetsReducer from "./digitalAssets.reducer";
import accountInformation from "./accountInfomation.reducer";

const rootReducer = combineReducers({
  app: AppReducer,
  templates: templatesReducer,
  pauta: PautaReducer,
  modal: ModalReducer,
  tableLevelsReducer: tableLevelsReducer,
  odoo: odooReducer,
  loader: LoaderReducer,
  report: ReportReducer,
  status: StatusReducer,
  profile: ProfileReducer,
  budgets: BudgetsReducer,
  clients: ClientsReducer,
  accounts: AccountsReducer,
  creative: CreativeReducer,
  campaign: CampaignReducer,
  campaigns: CampaignsReducer,
  audiences: AudiencesReducer,
  categories: CategoriesReducer,
  campaignupd: CampaignupdReducer,
  campaignupAdd: CampaignupAddReducer,
  objcampain: ObjectCampainReducer,
  campaignsbudget: campaignsbudget,
  ModalDetaill: ModalDetaillReducer,
  ModalImage: ModalImageReducer,
  fanpageDatos: fanpageDatosReducer,
  mediumbanners: MediumBannerReducer,
  fanpageAsociar: fanpageAsociarReducer,
  fanpageMensajes: fanpageMessageReducer,
  fanpageDatosWeb: fanpageDatosWebReducer,
  mediumbannerstype: MediumBannertypeReducer,
  pwa: PWAReducer,
  filesPautaReducer: filesPautaReducer,
  countryReducer: countryReducer,
  budget_spent: budget_spent,
  allCampaings: allCampaings,
  clients_mysql: clients_mysql,
  users: getUsersRole,
  logs: getLogs,
  payment: cards,
  exportData: exportedData,
  templateDL: templateDeepLinkReducer,
  permisos: permisos,
  schedule: scheduleReducer,
  userLogout: userLogoutReducer,
  digitalAssets: digitalAssetsReducer,
  accountInformation: accountInformation,
});

export default rootReducer;
