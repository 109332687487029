import React, { useState, useEffect } from "react";
import _, { find } from "lodash";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withUtils } from "utils";
import classnames from "classnames";
import { showLoader } from "redux/actions/loader.actions";
import { FormGroup, Label, Input, FormFeedback } from "reactstrap";
import DataPicker from "../../dataPicker";
import { Auth } from "aws-amplify";
import { Tooltip } from "antd";
import Select from "libs/select";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import * as API from "helpers/apiLA";
import * as APILA from "helpers/api";
import CurrencyInput from "react-currency-input-field";
import "../../modalPauta/assets/css/styles.scss";
import {
  setEditVisualizer,
  upsertVisualizer,
  clearEditVisualizer,
  setEdit,
  clearEdit,
} from "redux/actions/templates.actions";
import makeAnimated from "react-select/animated";
import { addOneVisualizer } from "redux/actions/templates.actions";
import { v4 } from "uuid";
import MySelect from "../../CampOneStep/MySelect";
import DropZone from "libs/dropzone";
import { components } from "react-select";
import * as STORAGE from "helpers/storage";
import Swal from "sweetalert2";
import imgPdf from "components/CampOneStep/assets/img/imgPdf.png";
import imgWord from "components/CampOneStep/assets/img/imgWord.png";
import imgExcel from "components/CampOneStep/assets/img/imgExcel.png";
import imgPp from "components/CampOneStep/assets/img/imgPp.png";
import imgTxt from "components/CampOneStep/assets/img/imgTxt.png";
import imgRar from "components/CampOneStep/assets/img/imgRar.png";
import imgZip from "components/CampOneStep/assets/img/imgZip.png";
import imgMp4 from "components/CampOneStep/assets/img/imgMp4.png";

const UpsertTemplate = (props) => {
  const [optionSelected, setOptionSelected] = useState([]);
  const { setShowModal, editVisualizer } = props;
  const [categoriesSelected, setCategoriesSelected] = useState([]);
  const [mediumBanner, setMediumBanner] = useState([]);
  const [budgetsGeneral, setBudgetsGeneral] = useState([]);
  const [budgets, setBudgets] = useState([]);
  const [formatsOptions, setFormatsOptions] = useState([]);
  const [goalOptions, setGoalOptions] = useState([]);
  const [nameForEmail, setNameForEmail] = useState("");
  const [emailForEmail, setEmailForEmail] = useState("");
  const [categories, setCategories] = useState([]);
  const [images, setImages] = useState([]);
  const [state, setState] = useState({
    edit: false,
    name: "",
    publico: [],
    fechaIni: "",
    fechaFin: "",
    publicoE: [],
    idAudience: 0,
    id_sale: "",
    product: { id_ad_product: 0, banner: "", id_banner: "" },
    country: { id_region: "", name: "" },
    medio: { id_medium: "", site: "" },
    formato: { id_format: "", art: "" },
    budget: 0,
    idBudget: "",
    creativity: [],
    id: "",
    copy: "",
    observation: "",
  });
  const [audiences, setAudiences] = useState([]);
  const [errors, setErrors] = useState({
    name: false,
    // id_sale: false,
    id_ad_product: false,
    medio: false,
    formato: false,
    idBudget: false,
    errorBudgetMessage: true,
    errorBudget: true,
    publico: false,
    fechaIni: false,
    fechafin: false,
  });
  const [countries, setCountries] = useState([]);
  useEffect(() => {
    getData();
    const validarFecha = (fecha) => (validarFormatoFecha(fecha) ? fecha : "");
    if (!_.isEmpty(editVisualizer)) {
      const countrytemp = {
        id_region: editVisualizer.id_region ?? 3,
        name: editVisualizer.name_region ?? "Guatemala",
      };
      const fechaFin = validarFecha(editVisualizer?.fechaFin);
      const fechaIni = validarFecha(editVisualizer?.fechaIni);
      setState({
        country: countrytemp,
        name: editVisualizer.name,
        publico: editVisualizer.publico ?? [],
        publicoE: editVisualizer.publicoE ?? [],
        idAudience: editVisualizer.idAudience ?? 0,
        budget: editVisualizer.budget,
        fechaFin: fechaFin, //editVisualizer.fechaFin ?? "",
        fechaIni: fechaIni, //editVisualizer.fechaIni ?? "",
        edit: true,
        id: editVisualizer.id === "" ? v4() : editVisualizer.id,
        formato: editVisualizer.formato
          ? {
              art: editVisualizer.formato,
              id_format: editVisualizer.id_format,
            }
          : { art: "", id_format: "" },
        id_sale: editVisualizer.id_sale ?? "",
        product: {
          id_ad_product: editVisualizer.id_ad_product,
          banner: editVisualizer.banner,
          id_banner: editVisualizer.id_banner,
        },
        medio: {
          id_medium: editVisualizer.id_medium || 0,
          site: editVisualizer.medio || 0,
        },
        idBudget: editVisualizer.idBudget,
      });
      setOptionSelected(editVisualizer.categoriesSelected);
    } else {
      setState({
        edit: false,
        name: "",
        publico: [],
        publicoE: [],
        fechaFin: "",
        fechaIni: "",
        idAudience: 0,
        id_sale: 0,
        product: { id_ad_product: 0, banner: "" },
        country: { id_region: "", name: "" },
        medio: { id_medium: "", site: "" },
        formato: { id_format: "", art: "" },
        budget: 0,
        idBudget: "",
        id: v4(),
      });
    }
  }, []);

  function validarFormatoFecha(fecha) {
    const patronFecha = /^\d{1,2}\.\d{1,2}\.\d{4}$/;

    return patronFecha.test(fecha);
  }

  const getCurrentListCategory = (id) => {
    let currentListOption = [];

    categories.forEach((element) => {
      if (element.padre === id) {
        let item = {
          value: element.id,
          label: element.name,
          padre: id,
        };
        currentListOption.push(item);
      }
    });

    return currentListOption;
  };

  const getData = async () => {
    props._showLoader(true);

    try {
      const [
        countriesResponse,
        budgetsResponse,
        audiencesResponse,
        responseAuth,
        categoriesResponse,
      ] = await Promise.all([
        API.getContryes(),
        APILA.getBudgets(props.currentAccount),
        APILA.getAudiences(props.currentAccount),
        Auth.currentAuthenticatedUser(),
        APILA.getCategories(props.currentAccount),
      ]);

      const countriesData = countriesResponse.data.pautas;
      const budgetsData = budgetsResponse.data.budgets;
      const dataAudiences = audiencesResponse.data.audiences.map((ele) => {
        return { ...ele, value: ele.id, label: ele.name };
      });
      const categories = categoriesResponse.data.categories;
      let datosFilter = categories?.filter((item) => item.active);
      setCategories(datosFilter);
      let activeCategoriesPadre = datosFilter?.filter(
        (item) => item.padre === "97bc9ee5-36a0-455b-974c-fa7832c9c341"
      );
      let catsSorted = _.orderBy(
        activeCategoriesPadre,
        ["name"],
        ["asc", "desc"]
      );
      setCategoriesSelected(catsSorted);
      // dataAudiences.push({ value: 0, label: "Agregar nuevo publico" });
      const formattedCountries = countriesData;
      setAudiences(dataAudiences);
      const formattedBudgets = budgetsData.map((budget) => {
        const available = numberWithCommas(
          myRound(parseFloat(budget.available))
        );
        const amount = numberWithCommas(parseFloat(budget.amount).toFixed(2));
        const label = `${budget.title}: $${amount} (Disponible: $${available})`;

        return {
          value: budget.id,
          label,
          isDisabled:
            budget.budgetOld === true || !budget.active || budget.status === 2
              ? true
              : false,
        };
      });
      setBudgetsGeneral(budgetsData);

      const sortedBudgets = _.orderBy(
        formattedBudgets,
        ["isDisabled"],
        ["asc"]
      );
      let token = responseAuth.signInUserSession.accessToken.jwtToken;

      const responseProfileInfo = await APILA.getProfileInfo(token);
      const attributes = responseProfileInfo.data.data.UserAttributes;
      let nameFind = find(attributes, { Name: "name" });
      let emailFind = find(attributes, { Name: "email" });
      if (nameFind) setNameForEmail(nameFind.Value);
      if (emailFind) setEmailForEmail(emailFind.Value);

      setCountries(formattedCountries);
      setBudgets(sortedBudgets);
    } catch (error) {
      console.log("Error data", error);
    } finally {
      props._showLoader(false);
    }
  };

  function myRound(num, dec) {
    var exp = Math.pow(10, dec || 2);
    return parseInt(num * exp, 10) / exp;
  }
  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  const handleChangeLabel = (e) => {
    e.persist();
    const { name, value } = e.target;
    if (name === "id_sale") Number(value);
    setState((prev) => ({ ...prev, [name]: value }));
  };

  const handleChangeCountry = async (event, value) => {
    props._showLoader(true);
    if (value) {
      setState((prev) => ({
        ...prev,
        country: {
          id_region: value.id_region,
          name: value.name,
        },
        medio: {
          id_medium: "",
          site: "",
        },
        product: { id_ad_product: "", banner: "", id_banner: "" },
        formato: {
          art: "",
          id_format: "",
        },
      }));
      const medio = await API.getMediumBanner({
        id_region: value.id_region,
        bandera: 1,
      });
      setMediumBanner(medio.data.data);
    }

    props._showLoader(false);
  };

  const handleChangeMedium = async (event, value) => {
    props._showLoader(true);

    setState((prev) => ({
      ...prev,
      medio: {
        id_medium: value.id_medium,
        site: value.site,
      },
    }));
    const medio = await API.getMediumBanner({
      id_medium: value.id_medium,
      id_region: state.country.id_region,
    });
    setGoalOptions(medio.data.data);
    props._showLoader(false);
  };
  const handleChangeGoal = async (event, value) => {
    props._showLoader(true);

    const formats = await API.getFormatData({
      id_medium: state.medio.id_medium,
      id_banner: value.id_banner,
      id_region: state.country.id_region,
    });
    setFormatsOptions(formats.data.data);
    setState((prev) => ({
      ...prev,
      product: {
        id_ad_product: value.id_ad_product,
        banner: value.banner,
        id_banner: value.id_banner,
      },
    }));
    props._showLoader(false);
  };
  const handleChangeFormat = (evemt, value) => {
    setState((prev) => ({
      ...prev,
      formato: { id_format: value.id_format, art: value.art },
      product: {
        id_ad_product: value.id_ad_product,
        banner: state.product.banner,
        id_banner: state.product.id_banner,
      },
    }));
  };

  const handleOptionBudget = (value) => {
    setState((prev) => ({ ...prev, idBudget: value.value }));
  };
  let currentBudget = budgets.filter(
    (budget) => budget.value === state.idBudget
  );

  const handleBudgetInputChange = (value) => {
    setState((prev) => ({
      ...prev,
      budget: value,
    }));
  };

  const closeModal = () => {
    setState({
      edit: false,
      name: "",
      id_sale: 0,
      idAudience: 0,
      publico: [],
      publicoE: [],
      product: { id_ad_product: 0, banner: "" },
      country: { id_region: "", name: "" },
      medio: { id_medium: "", site: "" },
      fechaFin: "",
      fechaIni: "",
      formato: { id_format: "", art: "" },
      budget: 0,
      idBudget: "",
      id: "",
    });
    setShowModal(false);
    props._clearEditVisualizer();
  };

  const areValidateImputs = () => {
    const mErrors = {
      name: state.name === "",
      // id_sale: state.id_sale === "",
      id_ad_product: state.product.banner === "",
      medio: state.medio === "",
      formato: state.formato.art === "",
      idBudget:
        state.idBudget === "" ||
        !budgetsGeneral.some((ele) => ele.id === state.idBudget),
      errorBudget: state.idBudget === "",
      publico: state.idAudience === 0,
      fechaIni: state.fechaIni === "",
      fechaFin: state.fechaFin === "",
    };

    setErrors((prev) => ({
      ...prev,
      ...mErrors,
    }));

    console.log("errors", mErrors);

    return !_.some(mErrors, (e) => e);
  };

  const animatedComponents = makeAnimated();

  const MultiValue = (props) => (
    <components.MultiValue {...props}>
      <span>{props.data.label}</span>
    </components.MultiValue>
  );

  const handleChange = (selected, id, padre) => {
    try {
      _.isEmpty(selected);
      if (_.isEmpty(optionSelected)) {
        let selectedAux = [];
        if (selected.length > 0) {
          selected.forEach((el) => {
            selectedAux.push({
              label: el.label,
              padre: el.padre,
              nombrePadre: padre.name,
              value: el.value,
            });
          });
        } else {
          selectedAux = [
            {
              label:
                selected[selected.length !== 0 ? selected.length - 1 : 0].label,
              padre:
                selected[selected.length !== 0 ? selected.length - 1 : 0].padre,
              nombrePadre: padre.name,
              value:
                selected[selected.length !== 0 ? selected.length - 1 : 0].value,
            },
          ];
        }

        setOptionSelected(selectedAux);
      } else {
        let testArray;
        if (selected == null) {
          let auxArray = optionSelected.filter((el) => {
            return el.padre !== id;
          });
          testArray = auxArray;
        } else {
          let selectedAux = [];
          if (selected.length > 0) {
            selected.forEach((el) => {
              selectedAux.push({
                label: el.label,
                padre: el.padre,
                nombrePadre: padre.name,
                value: el.value,
              });
            });
          } else {
            selectedAux = [
              {
                label:
                  selected[selected.length !== 0 ? selected.length - 1 : 0]
                    .label,
                padre:
                  selected[selected.length !== 0 ? selected.length - 1 : 0]
                    .padre,
                nombrePadre: padre.name,
                value:
                  selected[selected.length !== 0 ? selected.length - 1 : 0]
                    .value,
              },
            ];
          }

          let auxArray = optionSelected.filter((el) => {
            return el.padre !== id;
          });
          auxArray.forEach((el) => {
            selectedAux.push(el);
          });
          testArray = selectedAux;
        }
        setOptionSelected(testArray);
      }
    } catch {}
  };

  const getCategoryOptionSelected = (id) => {
    let currentListOption = [];
    if (!_.isEmpty(optionSelected)) {
      optionSelected.forEach((element) => {
        if (element.padre === id) {
          currentListOption.push(element);
        }
      });
    }

    return currentListOption;
  };

  const saveChanges = async () => {
    props._showLoader(true);
    try {
      if (!areValidateImputs()) return;
      props._clearEdit();
      const format = {
        id: state.id,
        name: state.name,
        medio: state.medio.site,
        fechaIni: state.fechaIni,
        fechaFin: state.fechaFin,
        objetivo: state.product.banner,
        budget_name: budgetsGeneral.find((ele) => ele.id === state.idBudget)
          .title,
        budget: parseFloat(state.budget),
        publico: state.publico,
        id_medium: state.medio.id_medium,
        id_ad_product: state.product.id_ad_product,
        id_banner: state.product.id_banner,
        id_format: state.formato.id_format,
        banner: state.product.banner,
        estado: 1,
        idBudget: state.idBudget,
        creativity: state.creativity || [],
        id_sale: Number(state.id_sale),
        observation: state.observation,
        account: props.currentAccount,
        copy: state.copy,
        urls: "",
        id_region: state.country.id_region,
        name_region: state.country.name,
        nameForEmail: nameForEmail,
        emailForEmail: emailForEmail,
        version: "",
        idAudience: state.idAudience,
        audience_name:
          state.idAudience === 0
            ? ""
            : audiences.find((ele) => ele.id === state.idAudience).name,
        categoriesSelected: optionSelected,
        formato: state.formato.art, //
        priority: 3,
      };

      if (state.edit) {
        const visualizer = await props._upsertVisualizer(props.edit, format);
        props._setEdit(visualizer);
      } else {
        if (props.module === "template") {
          await props.createTemplateEmpty(format);
          props.setShowModal(false);
          return;
        }
        const response = await props._addOneVisualizer(props.edit, format);
        props._setEdit(response);
      }

      closeModal();
    } catch (error) {
      console.log("Error al actualizar", error);
    } finally {
      props._showLoader(false);
    }
  };

  const handleChangeAudiece = async (value) => {
    if (value === null) {
      setState((prev) => ({
        ...prev,
        idAudience: 0,
        publico: [],
        publicoE: [],
      }));
    } else {
      if (value.value === 0) {
        window.$.fancybox.open({
          src: "#new-audience-modal",
          opts: {
            modal: true,
            afterClose: async function (instance, current) {
              console.info("Regresando a OneStep!");
              const audiencesResponse = await APILA.getAudiences(
                props.currentAccount
              );
              const dataAudiences = audiencesResponse.data.audiences.map(
                (ele) => {
                  return { ...ele, value: ele.id, label: ele.name };
                }
              );
              dataAudiences.push({ value: 0, label: "Agregar nuevo publico" });
              setAudiences(dataAudiences);
            },
          },
        });
      } else {
        const found = audiences.find((element) => element.id === value.value);
        setState((prev) => ({
          ...prev,
          defaultPublic: found.tags.data,
          idAudience: found.id,
          publico: found.tags.data,
          publicoE: found.tagsE?.data,
        }));
      }
    }
  };
  let currentAudience = audiences.filter(
    (pub) => pub.value === state.idAudience
  );

  const handleDateChange = (startDate, endDate) => {
    setState((prev) => ({
      ...prev,
      fechaIni: startDate,
      fechaFin: endDate,
    }));
  };

  const handleUpload = async (files) => {
    props._showLoader(true);
    try {
      let imagesArr = state.creativity ? state.creativity : [];
      let arrayAux = [];
      for (const file of files) {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        const result = await new Promise((resolve, reject) => {
          reader.onloadend = () => resolve(reader.result);
          reader.onerror = reject;
        });
        console.log("Validando lo que le mandasmos al servcicio de files", {
          name: file.name,
          file: result,
          type: file.type,
        });
        const uploadedFile = await STORAGE.uploadFile(
          file.name,
          // result,
          file.type
        );
        const { presignedUrl } = uploadedFile;
        await fetch(presignedUrl, {
          method: "PUT",
          body: file,
        });
        const url = await STORAGE.listFiles(uploadedFile.key);
        let taip;
        if (
          uploadedFile.key.includes("pdf") ||
          uploadedFile.key.includes("xls") ||
          uploadedFile.key.includes("docx") ||
          uploadedFile.key.includes("csv")
        ) {
          taip = "Document";
        } else {
          taip = "Image";
        }
        const newImage = {
          id: v4(),
          name: uploadedFile.key,
          link: url,
          account: props.currentAccount,
          type: taip,
        };
        imagesArr.push(newImage);
        setState((prev) => ({
          ...prev,
          creativity: imagesArr,
        }));
        if (state.edit) {
          props._validateCampg(
            props.campaignupd,
            { ...state, creativity: imagesArr },
            props.accounts,
            false
          );
        }
        setImages(imagesArr);
        Swal.fire({
          title: "Confirmación",
          text: "Archivo subido con éxito",
          icon: "success",
          showCancelButton: false,
          confirmButtonColor: "#3085d6",
          confirmButtonText: "Ok",
        });
      }
    } catch (error) {
      console.log("Error subiendo los archivos", error);
    }
    props._showLoader(false);
  };

  const remove = (file) => {
    const newArr = images.filter((img) => img.name !== file.name);
    setImages(newArr);
    setState((prev) => ({
      ...prev,
      creativity: newArr,
    }));
  };

  const docType = (file) => {
    let fileExt = file.name.split(".");
    let getExtension = fileExt[1].toLowerCase();
    if (file.type == "Image") {
      let imgExt = ["jpg", "jpeg", "png", "gif"];
      if (imgExt.includes(getExtension)) {
        var img = `https://marketing-suite-files-bucket150012-dev.s3.amazonaws.com/public/${file.name}`;
      } else if (getExtension == "rar") {
        var img = imgRar;
      } else if (getExtension == "zip") {
        var img = imgZip;
      } else if (getExtension == "mp4") {
        var img = imgMp4;
      } else if (getExtension == "txt") {
        var img = imgTxt;
      } else if (getExtension == "pptx" || getExtension == "ppt") {
        var img = imgPp;
      } else {
        var img = `https://marketing-suite-files-bucket150012-dev.s3.amazonaws.com/public/sin+imagen.jpg`;
      }
    } else {
      if (getExtension == "pdf") {
        var img = imgPdf;
      } else if (getExtension == "docx" || getExtension == "doc") {
        var img = imgWord;
      } else if (getExtension == "xlsx" || getExtension == "xls") {
        var img = imgExcel;
      } else {
        var img = `https://marketing-suite-files-bucket150012-dev.s3.amazonaws.com/public/sin+imagen.jpg`;
      }
    }
    return img;
  };

  const handleFileChange = (files) => {};

  const Option = (props) => {
    return (
      <div>
        <components.Option {...props}>
          <input
            type="checkbox"
            checked={props.isSelected}
            onChange={() => null}
          />{" "}
          <label>{props.label}</label>
        </components.Option>
      </div>
    );
  };

  const handleObservationChange = (e, editor) => {
    let name = e.target.name;
    let value = e.target.value;
    setState((prev) => ({
      ...prev,
      [name]: value,
    }));
    // if (state.edit) {
    // props._validateCampg(
    //   props.campaignupd,
    //   { ...state, [name]: value },
    //   props.accounts,
    //   false
    // );
    // }
  };

  const handleCopyChange = (e, editor) => {
    let name = e.target.name;
    let value = e.target.value;
    setState((prev) => ({
      ...prev,
      [name]: value,
    }));
    // props._validateCampg(
    //   props.campaignupd,
    //   { ...state, [name]: value },
    //   props.accounts,
    //   false
    // );
  };

  return (
    <>
      <div
        id="new-pauta-modal"
        style={{ display: "none" }}
        className={classnames("upsert-modal w-85 h-70 ModalPauta")}
      ></div>
      <div className="titleOneStep w-100 bodyModalPauta">
        <p>
          {" "}
          {state.edit ? "Actualizemos esta pauta." : "Creemos una nueva pauta."}
        </p>
      </div>
      <div className="bodyOneStep w-100 bodyModalPauta">
        <div className="oneColBody" id="cname">
          <FormGroup>
            <Label
              style={{
                color: "#05252d",
                fontWeight: 500,
                marginBottom: "5px",
              }}
            >
              Ponle un nombre a tu campa&ntilde;a
            </Label>
            <Input
              type="text"
              name="name"
              className="input"
              value={state.name}
              onChange={handleChangeLabel}
              disabled={false}
              placeholder="P. ejemplo campaña de día de la madre"
              invalid={errors.name}
            />
            <FormFeedback>Ingresa un nombre</FormFeedback>
          </FormGroup>
        </div>

        <div className="oneColBody" id="cfecha">
          <FormGroup>
            <Label className="texto_corregido">Elige un rango de fechas</Label>
            <DataPicker
              onChangeDate={handleDateChange}
              disabled={false}
              fechaIni={state.fechaIni} //{""}
              fechafin={state.fechaFin} //{""}
              className={classnames({
                "is-invalid": errors.fechaIni || errors.fechafin,
              })}
            />
            <FormFeedback>Ingresa un rango de fechas</FormFeedback>
          </FormGroup>
        </div>
        {categoriesSelected.length > 0 &&
          categoriesSelected?.map((element, idx) => {
            if (
              element.id &&
              element.padre === "97bc9ee5-36a0-455b-974c-fa7832c9c341" &&
              element.active === true
            ) {
              return (
                <div id={element.id} key={idx} className="contSelect">
                  <div className="oneColBody">
                    <FormGroup>
                      <Label
                        style={{
                          color: "#05252d",
                          fontWeight: 500,
                          marginBottom: "5px",
                        }}
                      >
                        {element.name}
                      </Label>
                      <MySelect
                        placeholder="Selecciona una categoria de producto"
                        options={getCurrentListCategory(element.id)}
                        isMulti={true}
                        isDisabled={false}
                        closeMenuOnSelect={false}
                        hideSelectedOptions={false}
                        components={{
                          Option,
                          MultiValue,
                          animatedComponents,
                        }}
                        onChange={(e) => handleChange(e, element.id, element)}
                        allowSelectAll={true}
                        value={getCategoryOptionSelected(element.id)}
                      />
                    </FormGroup>
                  </div>
                </div>
              );
            }
          })}

        <div className="oneColBody" id="cpais">
          <FormGroup>
            <Label
              style={{
                color: "#05252d",
                fontWeight: 500,
                marginBottom: "5px",
              }}
            >
              Elige un pa&iacute;s donde pautar
            </Label>
            <Autocomplete
              options={countries.length > 0 ? countries : []}
              value={state.country.id_region === "" ? null : state.country}
              onChange={handleChangeCountry}
              getOptionLabel={(option) => option.name}
              style={{ height: 35 }}
              getOptionSelected={(option, value) =>
                option.id_region === value?.id_region
              }
              className={classnames({
                "is-invalid": errors.medio,
              })}
              renderInput={(params) => (
                <TextField {...params} label="País" variant="outlined" />
              )}
            />
            <FormFeedback>Elige un pais</FormFeedback>
          </FormGroup>
        </div>
        <div className="oneColBody" id="cmedio">
          <FormGroup>
            <Label
              style={{
                color: "#05252d",
                fontWeight: 500,
                marginBottom: "5px",
              }}
            >
              Elige un medio donde pautar
            </Label>
            <Autocomplete
              value={state.medio}
              options={mediumBanner}
              getOptionSelected={(option, value) => option.id === value.id}
              onChange={handleChangeMedium}
              getOptionLabel={(option) => option.site}
              style={{ height: 35 }}
              className={classnames({
                "is-invalid": errors.medio,
              })}
              renderInput={(params) => (
                <TextField {...params} label="Medio" variant="outlined" />
              )}
            />
            <FormFeedback>Ingresa un medio donde pautar</FormFeedback>
          </FormGroup>
        </div>
        <div className="oneColBody" id="cformato">
          <FormGroup>
            <Label
              style={{
                color: "#05252d",
                fontWeight: 500,
                marginBottom: "5px",
              }}
            >
              Elige un objetivo para pautar
            </Label>
            <Autocomplete
              value={state.product}
              options={goalOptions}
              getOptionSelected={(option, value) => option.id === value.id}
              onChange={handleChangeGoal}
              getOptionLabel={(option) => option.banner}
              style={{ height: 35 }}
              className={classnames({
                "is-invalid": errors.id_ad_product,
              })}
              renderInput={(params) => (
                <TextField {...params} label="Objetivo" variant="outlined" />
              )}
            />
            <FormFeedback>Ingresa un objetivo para pautar</FormFeedback>
          </FormGroup>
        </div>
        <div className="oneColBody" id="cname">
          <FormGroup>
            <Label
              style={{
                color: "#05252d",
                fontWeight: 500,
                marginBottom: "5px",
              }}
            >
              Elige un formato para pautar
            </Label>
            <Autocomplete
              getOptionSelected={(option, value) => option.id === value.id}
              value={state.formato}
              options={formatsOptions}
              onChange={handleChangeFormat}
              getOptionLabel={(option) => option.art}
              style={{ height: 35 }}
              className={classnames({
                "is-invalid": errors.formato,
              })}
              renderInput={(params) => (
                <TextField {...params} label="Formato" variant="outlined" />
              )}
            />
            <FormFeedback>Ingresa un formato para pautar</FormFeedback>
          </FormGroup>
        </div>
        <div className="oneColBody" id="cpresupuesto">
          <FormGroup>
            <Label
              style={{
                color: "#05252d",
                fontWeight: 500,
                marginBottom: "5px",
              }}
            >
              Selecciona un presupuesto para la campa&ntilde;a
            </Label>
            <Select
              options={budgets}
              value={currentBudget || null}
              placeholder="Mi presupuesto"
              onChange={handleOptionBudget}
              className={classnames({
                "is-invalid": errors.idBudget,
              })}
            />
            <FormFeedback>Selecciona un presupuesto</FormFeedback>
          </FormGroup>
        </div>
        <div className="oneColBody" id="cinversion">
          <FormGroup>
            <Label
              style={{
                color: "#05252d",
                fontWeight: 500,
                marginBottom: "5px",
              }}
            >
              Ingresa la inversión
            </Label>
            <div
              className={classnames("d-flex align-items-center", {
                "is-invalid": errors.errorBudgetMessage,
              })}
            >
              <CurrencyInput
                intlConfig={{ locale: "es-GT", currency: "USD" }}
                prefix="$"
                name="amount"
                className="input form-control"
                style={{
                  color: "#05252d",
                }}
                value={state.budget}
                onValueChange={(e) => handleBudgetInputChange(e)}
                placeholder="P. ejemplo 1000.25"
                invalid={errors.errorBudget}
              />
            </div>
            <FormFeedback>{errors.errorBudgetMessage}</FormFeedback>
          </FormGroup>
        </div>
        <div className="oneColBody">
          <FormGroup>
            <Label
              style={{
                color: "#05252d",
                fontWeight: 500,
                marginBottom: "5px",
              }}
            >
              Observaciones
            </Label>
            <Input
              type="textarea"
              rows={7}
              onChange={handleObservationChange}
              value={state.observation}
              name="observation"
            />
          </FormGroup>
        </div>

        <div className="oneColBody">
          <FormGroup>
            <Label
              style={{
                color: "#05252d",
                fontWeight: 500,
                marginBottom: "5px",
              }}
            >
              Copy
            </Label>
            <Input
              type="textarea"
              rows={7}
              onChange={handleCopyChange}
              value={state.copy}
              name="copy"
            />
          </FormGroup>
        </div>
        <div className="twoColBody">
          <FormGroup>
            <Label
              style={{
                color: "#05252d",
                fontWeight: 500,
                marginBottom: "5px",
              }}
            >
              Elige un público
            </Label>
            <div onClick={() => {}}>
              <Select
                options={audiences}
                value={currentAudience}
                placeholder="Selector de publicos"
                onChange={handleChangeAudiece}
                className={classnames({
                  "is-invalid": errors.publico || "",
                })}
              />
              <FormFeedback>
                Debe definir o elegir un nombre de público y este debe tener
                hashtags asociados
              </FormFeedback>
            </div>
          </FormGroup>
        </div>
        <div className="twoColBody">
          <div>
            <div className="img-content-creativy">
              {images.map((file) => (
                <div className="imagenCreati mb-5">
                  <i
                    onClick={() => {
                      remove(file);
                    }}
                    className="fas fa-times"
                  ></i>
                  {
                    <>
                      <img
                        style={{ display: "flex", margin: "auto" }}
                        className="img-fluid h-100"
                        src={docType(file)}
                      />{" "}
                      <p>{file.name}</p>
                    </>
                  }
                </div>
              ))}
            </div>
            <DropZone onUpload={handleUpload} onChange={handleFileChange} />
          </div>
        </div>
      </div>
      <div className="footOneStep w-100 footOneStepModal">
        <Tooltip title="Descartar cambios" color="#002448" placement="top">
          <a
            href="#"
            className="btnCancel firstBtn"
            onClick={() => closeModal()}
          >
            Cancelar
          </a>
        </Tooltip>
        <Tooltip title="Guardar cambios" color="#002448" placement="top">
          <a className="btnConfirm secondBtn" onClick={() => saveChanges()}>
            Confirmar
          </a>
        </Tooltip>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  accounts: state.accounts.list,
  currentUser: state.app.current_user,
  templates: state.templates.list,
  edit: state.templates.edit,
  editVisualizer: state.templates.editVisualizer,
  users: state.users,
  currentAccount: state.accounts.default,
});
const mapDispatchToProps = (dispatch) => ({
  _showLoader: (show) => dispatch(showLoader(show)),
  _setEditVisualizer: (edit) => dispatch(setEditVisualizer(edit)),
  _upsertVisualizer: (template, visualizer) =>
    dispatch(upsertVisualizer(template, visualizer)),
  _clearEditVisualizer: () => dispatch(clearEditVisualizer()),
  _setEdit: (edit) => dispatch(setEdit(edit)),
  _clearEdit: () => dispatch(clearEdit()),
  _addOneVisualizer: (template, visualizer) =>
    dispatch(addOneVisualizer(template, visualizer)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withUtils(withRouter(UpsertTemplate)));
