export const APP_LOADED = 'APP:LOADED'
export const APP_SET_CURRENT_PERMISSIONS = 'APP:SET:CURRENT:PERMISSIONS'
export const APP_CLEAR_CURRENT_PERMISSIONS = 'APP:CLEAR:CURRENT:PERMISSIONS'

export const appLoaded = () => ({ type: APP_LOADED })

export const setCurrentPermissions = (permisos) => ({
    type: APP_SET_CURRENT_PERMISSIONS,
    permisos,
})

export const clearCurrentPermissions = () => ({
    type: APP_CLEAR_CURRENT_PERMISSIONS,
})
