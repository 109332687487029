const NameParams = {
  url: "url",
  reference: "reference",
  name: "name",
};

export const validateInput = ({ value, name, currentTypeDigitalAsset }) => {
  let hasError = false;
  let errorMessage = "";
  let pattern = currentTypeDigitalAsset.validation.slice(1, -1);
  const regex_validate_url = new RegExp(pattern);
  const formatValue = value?.trim();

  switch (name) {
    case NameParams.url:
      if (!formatValue) {
        hasError = true;
        errorMessage = "Campo requerido.";
      } else if (!regex_validate_url.test(formatValue)) {
        hasError = true;
        errorMessage = "Ingresa el formato correcto.";
      } else {
        hasError = false;
        errorMessage = "";
      }
      break;
    case NameParams.name:
      if (!formatValue) {
        hasError = true;
        errorMessage = "El nombre es requerido.";
      } else {
        hasError = false;
        errorMessage = "";
      }
      break;
    case NameParams.reference:
      if (!formatValue) {
        hasError = true;
        errorMessage = "La referencia es requerida.";
      } else {
        hasError = false;
        errorMessage = "";
      }
      break;
    default:
      break;
  }
  return { errorMessage, hasError };
};
