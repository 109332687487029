/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { connect } from "react-redux";

import "./assets/css/styles.css";
import { FormGroup, Label, Input, FormFeedback } from "reactstrap";
import { saveNames, saveSteps } from "redux/actions/nameCamp.actions";
import { saveNameAccount } from "redux/actions/medioCamp.actions";

const NameCamp = (props) => {
  const key = (e) => {
    let input = document.getElementById("btnNameCamp2");
    input.style.borderBottom = "1px solid gray";
    input.classList.remove("require");
    //input.placeholder = "P. ejemplo campaña de día de la madre";
    input.placeholder = "Ej. Bono 14, Verano, Día del padre";
  };
  const keyAccount = (e) => {
    let input = document.getElementById("btnNameAccount");
    input.style.borderBottom = "1px solid gray";
    input.classList.remove("require");
    //input.placeholder = "P. ejemplo día de la madre";
    input.placeholder = "Ej. Adidas, Burger King, Norma";
  };

  const saveName = (e) => {
    e.preventDefault();
    let name = document.getElementById("btnNameCamp2");
    let nameAccount = document.getElementById("btnNameAccount");
    if (name.value.trim().length > 0 && nameAccount.value.trim().length > 0) {
      props._saveNames(name.value);
      props._saveNameAccount(nameAccount.value);
      props._saveSteps(2);
    } else {
      if (!name.value) {
        name.style.borderBottom = "1px solid red";
        name.placeholder = "Este texto es requerido";
        name.classList.add("require");
      }
      if (!nameAccount.value) {
        nameAccount.style.borderBottom = "1px solid red";
        nameAccount.placeholder = "Este texto es requerido";
        nameAccount.classList.add("require");
      }
    }
  };

  let name = document.getElementById("btnNameCamp2");
  let nameAccount = document.getElementById("btnNameAccount");
  let namesave = "";
  let accountnamesave = "";

  if (
    props.campaign.name === null &&
    props.campaign.name === undefined &&
    props.campaignupd.length > 0
  ) {
    //props.campaign.name = props.campaignupd[0].name
    namesave = props.campaignupd[0].name;
    accountnamesave = props.campaignupd[0].accountName;
    if (name !== null && name !== undefined) {
      name.value = props.campaignupd[0].name;
      nameAccount.value = props.campaignupd[0].accountName;
    }
  } else {
    if (props.campaign.name !== null && props.campaign.name !== undefined) {
      console.log("#$#$#$#$#$#$ entra if de else");
      namesave = props.campaign.name;
      accountnamesave = props.campaign.accountName;
      if (name !== null && name !== undefined) {
        name.value = props.campaign.name;
        nameAccount.value = props.campaign.accountName;
      }
    } else {
      if (name !== null && name !== undefined) {
        name.value = "";
      }
    }
  }

  return (
    <div className={props.clase}>
      <h1 className="title">Nombra tu primera campaña digital</h1>
      <div className="oneColBody">
        <FormGroup>
          <Label
            style={{
              color: "#05252d",
              fontWeight: 500,
              marginBottom: "5px",
            }}
          >
            Nombre de cuenta
          </Label>
          <Input
            type="text"
            className="input"
            id="btnNameAccount"
            autoComplete="off"
            defaultValue={accountnamesave}
            onKeyDown={(e) => keyAccount(e)}
            placeholder="Ej. Adidas, Burger King, Norma"
          />
          <FormFeedback>Ingresa un nombre</FormFeedback>
        </FormGroup>
      </div>
      <div className="oneColBody">
        <FormGroup>
          <Label
            style={{
              color: "#05252d",
              fontWeight: 500,
              marginBottom: "5px",
            }}
          >
            Nombre de tu pauta
          </Label>
          <Input
            type="text"
            className="input"
            id="btnNameCamp2"
            autoComplete="off"
            defaultValue={namesave}
            onKeyDown={(e) => key(e)}
            placeholder="Ej. Bono 14, Verano, Día del padre"
          />
          <FormFeedback>Ingresa un nombre</FormFeedback>
        </FormGroup>
      </div>

      <div className="rigth-btn">
        <a className="btn-camp" onClick={saveName} href="#" id="nextName">
          Siguiente
        </a>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  campaign: state.campaign,
  campaignupd: state.campaignupd,
});

const mapDispatchToProps = (dispatch) => ({
  _saveNames: (name) => dispatch(saveNames(name)),
  _saveNameAccount: (nameA) => dispatch(saveNameAccount(nameA)),
  _saveSteps: (step) => dispatch(saveSteps(step)),
});

export default connect(mapStateToProps, mapDispatchToProps)(NameCamp);
