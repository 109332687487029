import React, { useEffect, useState } from 'react'
import _ from 'lodash'
import { connect } from 'react-redux'

// organization components
import ReactDataTable from '@easygosanet/react-datatable'
import DataTableUtils, {
  Share,
  ShowColumn,
  FilterColumn,
} from '@easygosanet/react-datatable-utils'

import * as Modal from 'redux/actions/modal.actions'
import { showLoader } from 'redux/actions/loader.actions'
import {
  setEdit,
  clearEdit,
  showModal,
  deleteStatus,
  getStatusList,
} from 'redux/actions/status.actions'

import { arrayStatus as globalStatus } from 'config/status'
import { withUtils } from 'utils'

const DetailCol = ({ text, className, onClick }) => (
  <div className='firstColl w-100'>
    <div onClick={() => onClick()} className={className}>
      <i className='fas fa-edit'></i>
    </div>
    <p className='name-fc'>{text}</p>
  </div>
)

const Actions = ({ onClick }) => (
  <div onClick={() => onClick()} className='secondColl orange cursor-pointer'>
    Eliminar
  </div>
)

const Content = (props) => {
  const [filterText, setFilterText] = useState('')
  const [filters, setFilters] = useState({})
  const [columns, setColumns] = useState([
    {
      name: 'Nombre',
      selector: 'name',
      sortable: true,
      type: 'text',
      grow: 1,
      style: {
        color: '#02025b',
      },
      cell: (row) => (
        <DetailCol
          text={row.name}
          onClick={() => goToEditPage(row)}
          className='edit-fc'
        />
      ),
    },
    {
      name: 'Color',
      selector: 'color',
      sortable: true,
      center: true,
      grow: 1,
      style: {
        fontWeight: 'bold',
      },
      cell: (row) => (
        <div>{row.color}</div>
      ),
    },
    {
      name: 'Asociaciones',
      selector: 'associations',
      sortable: true,
      center: true,
      grow: 2,
      style: {
        fontWeight: 'bold',
      },
      cell: (row) => getAssociationsLabel(row.associations),
    },
    {
      name: '',
      selector: 'botones',
      sortable: false,
      button: true,
      grow: 1,
      cell: (row) => (
        <Actions onClick={() => deleteStatus(row.id, row.name)} />
      ),
    },
  ])

  useEffect(() => {
    props._getStatusList()
  }, [])

  let statusList = _.sortBy(props.statusList, ['createAt'])
  
  const goToEditPage = (edit) => {
    props._setEdit(edit)
    props._showModal(true)

    window.$.fancybox.open({
      src: '#upsert-status-modal',
      //type: 'modal',
      opts: {
        modal: true,
        afterClose: () => {
          props._showModal(false)
          props._clearEdit()
        }
      },
    })
  }

  const getAssociationsLabel = (associations) => {
    let result = []

    if (associations) {
      associations.forEach((assoc) => {
        const status = _.find(globalStatus, { id: assoc })
        if (status) {
          result.push(status.label)
        }
      })

      return result.join(', ')
    } else {
      return ''
    }
  }
  
  const deleteStatus = (id, name) => {
    props._showConfirmationModal('Eliminar Estado?', `Esta seguro que desea eliminar el estado ${name}`, () => {
      props._deleteStatus(id)
    })
  }

  const handleFilterTextChange = (value) => {
    props.utils.log('handleFilterTextChange')
    props.utils.log('value', value)
    setFilterText(value)
  }

  const handleShowColumnChange = (column, show) => {
    props.utils.log('handleFilterTextChange')
    props.utils.log('column', column)
    props.utils.log('show', show)

    let index = columns.findIndex((col) => col.selector === column)
    let aux = columns.map((col) => col)
    aux[index].omit = show
    setColumns(aux)
  }

  const handleColumnSortChange = (positions) => {
    props.utils.log('handleColumnSortChange')
    props.utils.log('positions', positions)

    let aux = columns.map((col) => col)

    positions.forEach((columnId, position) => {
      let index = columns.findIndex((col) => col.selector === columnId)
      props.utils.log('Index', index)
      aux[index].position = position
    })

    aux = _.orderBy(aux, ['position'])

    props.utils.log('new columns', aux)

    setColumns(aux)
  }

  const handleFilterPerColumn = (column, type, value) => {
    props.utils.log('handleFilterPerColumn')
    props.utils.log('column', column)
    props.utils.log('type', type)
    props.utils.log('value', value)
    setFilters((prev) => ({ ...prev, [column]: { type, value } }))
  }

  const handleShareContact = (contact) => {
    props.utils.log('handleShareContact')
    props.utils.log('contact', contact)
  }

  let filteredData = statusList.filter((item) =>
    item.name.toLowerCase().includes(filterText.toLowerCase())
  )

  if (!_.isEmpty(filters)) {
    filteredData = filteredData.filter((item) => {
      let result = true

      for (const filterKey in filters) {
        let response
        let filter = filters[filterKey]
        let dataValue = item[filterKey]

        props.utils.log('key', filterKey)
        props.utils.log('filter', filter)
        props.utils.log('data value', dataValue)

        switch (filter.type) {
          case 'EQUALS':
            response =
              `${filter.value}`.toLowerCase() === `${dataValue}`.toLowerCase()
            break
          case 'INLIST':
            response = filter.value[`${dataValue}`]
            break
          case 'GREATERTHAN':
            response = filter.value === -1 || dataValue > filter.value
            break
          case 'LESSTHAN':
            response = filter.value === -1 || dataValue < filter.value
            break
          case 'EQUALTHAN':
            response = filter.value === -1 || filter.value === dataValue
            break
          default:
            response = `${dataValue}`
              .toLowerCase()
              .includes(`${filter.value}`.toLowerCase())
            break
        }

        result = result && response
      }

      return result
    })
  }

  return (
    <>
      <div className='d-flex w-100'>
        <DataTableUtils
          data={statusList}
          action={() => {
            props._showModal(true)
            window.$.fancybox.open({
              src: '#upsert-status-modal',
              data: 'upsert-status',
              //type: 'modal',
              opts: {
                modal: true,
                afterClose: () => {props._showModal(false)},
              },
            })
          }}
          actionIcon={<i className='fas fa-plus' />}
          onChangeFilterText={handleFilterTextChange}
        >
          <Share
            data={columns}
            contacts={[]}
            onShareContact={handleShareContact}
          />
          <ShowColumn
            columns={columns}
            showColumn={handleShowColumnChange}
            onSortChange={handleColumnSortChange}
          />
          <FilterColumn
            columns={columns}
            onFilterColumn={handleFilterPerColumn}
          />
        </DataTableUtils>
      </div>

      <div className='Kanbang-content'>
        <div className='List-category'>
          <ReactDataTable
            columns={columns}
            data={filteredData}
            persistTableHead
            pagination
            noHeader
          />
        </div>
      </div>
    </>
  )
}

const mapStateToProps = (state) => ({
  accounts: state.accounts.list,
  statusList: state.status.list,
  currentAccount: state.accounts.default,
})

const mapDispatchToProps = (dispatch) => ({
  _clearEdit: () => dispatch(clearEdit()),
  _setEdit: (edit) => dispatch(setEdit(edit)),
  _showModal: (show) => dispatch(showModal(show)),
  _showLoader: (show) => dispatch(showLoader(show)),
  _deleteStatus: (id) => dispatch(deleteStatus(id)),
  _getStatusList: (account) => dispatch(getStatusList(account)),
  _showConfirmationModal: (title, message, callback) =>
    dispatch(
      Modal.showAlertModal(
        title,
        message,
        Modal.MODAL_TYPE_CONFIRMATION,
        callback
      )
    ),
})

export default connect(mapStateToProps, mapDispatchToProps)(withUtils(Content))