import { APP_CLEAR_CURRENT_USER } from '../actions/app.actions'
import * as Actions from '../actions/budgets.actions'

const initialState = {
  edit: {},
  list: [],
  filter_text: '',
  budgetInfo: {}
}

export default (state = initialState, action) => {
  switch (action.type) {
    case Actions.BUDGETS_SET_EDIT:
      return {
        ...state,
        edit: action.edit,
      }
    case Actions.BUDGETS_CLEAR_EDIT:
      return {
        ...state,
        edit: {},
      }
    case Actions.BUDGETS_CLEAR:
      return {
        ...state,
        list: [],
      }
    case Actions.BUDGETS_ADD_ONE:
      return {
        ...state,
        list: [
          ...state.list.filter((budget) => budget.id !== action.budget.id),
          action.budget,
        ],
      }
    case Actions.BUDGETS_ADD_TOTAL:
      const updatedBudgets = action.budgets.map(element => ({
        ...element,
        available: parseFloat(element.available)
      }));
      return {
        ...state,
        list: updatedBudgets
      };
    case Actions.BUDGETS_SET_INFO:
      return {
        ...state,
        budgetInfo: action.budgetInfo
      }
    case APP_CLEAR_CURRENT_USER:
      return initialState
    default:
      return state
  }
}
