import React, { useState, useEffect } from "react";
import _ from "lodash";
import { connect } from "react-redux";
import { Auth } from "aws-amplify";

import Select from "libs/select";

import { withUtils } from "utils";
import { setDefault } from "redux/actions/accounts.actions";
import { pwaSetDefault } from "redux/actions/pwa.actions";
import { updateCurrentAccount } from "redux/actions/dashboard.actions";
import analytics from "../../../helpers/analytics";
import { useHistory, useLocation } from "react-router-dom";
import { format, startOfMonth, endOfMonth } from "date-fns";
import { Tooltip } from "antd";
import axios from "axios";
import * as API from "helpers/api";
// import * as APILA from "helpers/apiLA"
const TAG = "AccountSelector";
var isInit = true;
const AccountSelector = (props) => {

  // Hooks url
  const history = useHistory();
  const location = useLocation();

  // Filter at date

  // ! Dates for campaigns
  const dateInitial = format(new Date().setDate(new Date().getDate() - 7), "dd/MM/yyyy")
  const dateFinal = format(new Date(), "dd/MM/yyyy")

  // ! Dates for budgets
  const today = new Date();
  const firstDayOfMonth = format(startOfMonth(today), "dd/MM/yyyy");
  const lastDayOfMonth = format(endOfMonth(today), "dd/MM/yyyy");

  const [accountsIp, setAccountsIp] = useState([]);
  const [userId, setUserId] = useState("");
  const getAccountName = (accountId, clientId, owner, name) => {
    if (props.currentAccount === "") return name;
    // let permisos = await APILA.getPermitsByUser({user: props.currentUser})
    // console.log('Los permisos de esta ceunta', permisos);
    let acc = _.find(props.accounts, { id: accountId });
    let client = _.find(props.clients, { id: clientId });
    // console.log("La data del acc" , acc);
    return acc
      ? owner
        ? acc.name
        : // : `${name} - ${client ? client.name : ''}`
        `${name}`
      : name;
  };
  useEffect(() => {
    getAccounts();
  }, [isInit]);
  const getAccounts = async () => {
    if (isInit) {
      const apiUrl = 'https://api.ipify.org?format=json';
      const responseGeo = await fetch(apiUrl);
      const data = await responseGeo.json();
      const idUser = data?.ip
      setUserId(idUser);
      let response = await API.getAccounts(idUser);
      setAccountsIp(response.data.accounts);
      isInit = false;
    }
  };

  // ? Cambios en la url para deeplinking
  const changeUrl = (idCuenta) => {
    const currentUrl = new URLSearchParams(location.pathname)
    const viewBudget = currentUrl.get('view_budget') ?? 1
    const path = location.pathname.split('/')[1]
    const pathwithoutsplit = location.pathname?.split('/account=')[0]
    if (location.search.length > 0) {
      history.push({
        search: ''
      })
    }
    console.log('location desde el account del selector', { idCuenta, path, pathwithoutsplit, location })
    if (path === "publicos") {
      history.replace(`/publicos/account=${idCuenta}&view=list&group=default&page=1`)
    } else if (path === "categorias") {
      history.replace(`/categorias/account=${idCuenta}&view=list&group=default`)
    } else if (path === "campañas") {
      history.replace(`/campañas/account=${idCuenta}&view=1&group=default&viewColumn=default&page=1&initial_date=${dateInitial}&final_date=${dateFinal}`)
    } else if (path === "reportes") {
      history.replace(`/reportes/account=${idCuenta}&view=list&group=default`)
    } else if (path === "reportes") {
      history.replace(`/reportes/account=${idCuenta}&view=list&group=default&viewColumns=default&page=1`)
    } else if (pathwithoutsplit === '/configuracion/configuracionSemanal') {
      history.replace(`/configuracion/configuracionSemanal/account=${idCuenta}`)
    } else if (pathwithoutsplit === '/configuracion/configuracionPriority') {
      history.replace(`/configuracion/configuracionPriority/account=${idCuenta}`)
    } else if (pathwithoutsplit === '/configuracion/etiquetas') {
      history.replace(`/configuracion/etiquetas/account=${idCuenta}&page=1`)
    } else if (pathwithoutsplit === '/configuracion/accounts') {
      history.replace(`/configuracion/accounts/account=${idCuenta}&page=1`)
    } else if (pathwithoutsplit === '/configuracion/roles') {
      history.replace(`/configuracion/roles/account=${idCuenta}&view=list&group=default`)
    } else if (pathwithoutsplit === '/configuracion/asignacion-roles') {
      history.replace(`/configuracion/asignacion-roles/account=${idCuenta}&page=1`)
    } else if (pathwithoutsplit === '/configuracion/emails') {
      history.replace(`/configuracion/emails/account=${idCuenta}&page=1`)
    } else if (pathwithoutsplit === '/configuracion/integrationOdoo') {
      history.replace(`/configuracion/integrationOdoo/account=${idCuenta}&page=1`)
    } else if (pathwithoutsplit === '/configuracion/informacionCuenta') {
      history.replace(`/configuracion/informacionCuenta/account=${idCuenta}&view_selector=1&page=1`)
    } else if (pathwithoutsplit === '/presupuestos') {
      if (viewBudget === '4') {
        history.replace(`/presupuestos/account=${idCuenta}&view_budget=${viewBudget}&view=list&page=1&initial_date=${firstDayOfMonth}&final_date=${lastDayOfMonth}`)
      } else {
        history.replace(`/presupuestos/account=${idCuenta}&view_budget=${viewBudget}&view=list&group=default&page=1&group=default&initial_date=${firstDayOfMonth}&final_date=${lastDayOfMonth}`)
      }

    }
  }

  const handleAccountChange = (option) => {
    console.log('Selector de la cuenta', option)
    analytics.Click_Navbar_filtro(option.value);
    props._updateCurrentAccount(props.currentUser, option.value);
    if (props.currentUser !== "") {
      props._setDefaultAccount(option.value);
      changeUrl(option.value)
    } else {
      props._pwaSetDefaultAccount(option.value);
    }
  };

  let accounts = [];
  let mAccounts = [];

  if (props.currentUser !== "") {
    if (props.isIpv4) {
      let cuentas = props.accounts
      mAccounts = _.sortBy(cuentas, ["name"]);
    } else {
      let cuentas = props.accounts?.filter(ele => props?.accountsByUser?.includes(ele.id))
      mAccounts = _.sortBy(cuentas, ["name"]);
    }
    // mAccounts = _.sortBy(props.accounts, ["name"]);
  } else if (props.pwaAccounts.length > 0) {
    mAccounts = props.pwaAccounts;
  } else {
    mAccounts = accountsIp;
  }

  mAccounts.map((acc) => {
    accounts = [
      ...accounts,
      {
        value: acc.id,
        label: getAccountName(acc.id, acc.client, acc.owner, acc.name),
      },
    ];
  });
  const mCurrentAccount =
    props.currentUser !== "" ? props.currentAccount : props.currentPWAAccount;

  const currentAccount = accounts.filter(
    (acc) => acc.value === mCurrentAccount
  );


  return (
    <form autoComplete="off ">
      <div className="account-selector-container mx-auto formTooltip">

        <Select
          placeholder="Selector de cliente"
          onChange={handleAccountChange}
          value={currentAccount}
          options={accounts}
        />
        <span className="tooltipSelect"> Seleccionar cuenta </span>
      </div>
    </form>
  );
};

const mapStateToProps = (state) => ({
  clients: state.clients,
  isIpv4: state.app.isIPV4,
  accounts: state.accounts.list,
  currentUser: state.app.current_user,
  pwaAccounts: state.pwa.accounts.list,
  currentAccount: state.accounts.default,
  currentPWAAccount: state.pwa.accounts.default,
});

const mapDispatchToProps = (dispatch) => ({
  _setDefaultAccount: (account) => dispatch(setDefault(account)),
  _pwaSetDefaultAccount: (account) => dispatch(pwaSetDefault(account)),
  _updateCurrentAccount: (user, account) =>
    dispatch(updateCurrentAccount(user, account)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withUtils(AccountSelector));
