import { find } from "lodash";
import { v4 } from "uuid";
import * as API from "helpers/api";
import * as APILA from "helpers/apiLA";
import * as Logger from "utils/logger";
import { showLoader } from "./loader.actions";

export const CLIENTS_CLEAR = "CLIENTS:CLEAR";
export const CLIENTS_ADD_ONE = "CLIENTS:ADD:ONE";
export const CLIENTS_ADD_LIST = "CLIENTS:ADD:LIST";

const TAG = "CLIENTS:ACTION";

export const getClients = (user, idmod, mod) => (dispatch) => {
  dispatch(clearClients());
  dispatch(showLoader(true));
  // API.getClients() // llamada a marketing suite anterior
  APILA.getAllAccountsAWS() // nueva llamada para traer usuarios a aws
    .then((response) => {
      insertLog(
        "",
        "getClients",
        "",
        response.data,
        user,
        "Trae listado de usuarios de cognito , /getClients",
        idmod,
        mod
      );
      let { clients } = response.data;

      clients.forEach((client_response) => {
        let nameAttr = find(client_response.Attributes, { Name: "name" });
        let emailAttr = find(client_response.Attributes, { Name: "email" });

        let client = {
          id: client_response.Username,
          name: nameAttr ? nameAttr.Value : "",
          email: emailAttr ? emailAttr.Value : "",
        };

        dispatch({
          type: CLIENTS_ADD_ONE,
          client,
        });
      });
    })
    .catch((err) => {
      Logger.log(TAG, "getClients", err);
    })
    .then(() => {
      dispatch(showLoader(false));
    });
};

async function insertLog(
  account,
  action,
  request,
  response,
  user,
  obs,
  idmod,
  mod
) {
  let idlog = v4();
  let now = Date.now();
  let logRequest = {
    id: idlog,
    source: "Lets-Advertise",
    timestamp: now,
    action: action,
    user: user,
    role: account,
    idmodulo: idmod,
    modulo: mod,
    request: request,
    response: response,
    observation: obs,
  };
  const responseLog = await APILA.insertLogLine(logRequest);
}
export const clearClients = () => (dispatch) => {
  dispatch({
    type: CLIENTS_CLEAR,
  });
};

export const addClient = (client) => (dispatch) => {
  dispatch({
    type: CLIENTS_ADD_ONE,
    client,
  });
};

export const addClientList = (clients) => (dispatch) => {
  dispatch({
    type: CLIENTS_ADD_LIST,
    clients,
  });
};
