/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useLayoutEffect } from "react";
import { connect } from "react-redux";
import "./assets/css/styles.css";
import { getCategories } from "redux/actions/categories.actions";
import Campana from "../campana";
import TermsAndCondition from "components/auth/termsAndCondition";
import Dashboard from "../dashboard";
import { setCurrentUser } from "redux/actions/app.actions";
import { getAccounts } from "redux/actions/accounts.actions";
import { showLoader } from "redux/actions/loader.actions";
import { clearCampaigns } from "redux/actions/dashboard.actions";
import * as API from "helpers/api";
import DashboardPwa from "components/dashboardPwa";

const Home = (props) => {
  const [counter, setCounter] = useState(0);
  const [isTemp, setIstemp] = useState(false);
  const [hasData, setHasData] = useState(false);

  const handleAdd = (e) => {
    e.preventDefault();
    setCounter(1);
  };

  useLayoutEffect(() => {
    props._clearCampaigns();
    try {
      const tempAWSUser = localStorage.getItem("tempAWSUser");
      const account = localStorage.getItem("account");
      if (tempAWSUser && account) {
        props._getAccounts(tempAWSUser);
        props._setCurrentUser(tempAWSUser);
        props._getCategories(props.currentAccount);
        // setIstemp(true);
      } else {
        getDataByIp();
      }
    } catch (err) {
      console.log("localstorage err ", err);
    }
  }, []);

  const getDataByIp = async () => {
    props._showLoader(true);
    //obtenemos información de dirección IP para vincular todos los dummy data
    try {
      const apiUrl = "https://api.ipify.org?format=json";
      const responseGeo = await fetch(apiUrl);
      const data = await responseGeo.json();
      const idUser = data?.ip;
      let response = await API.getAccounts(idUser);
      if (response.data.accounts.length > 0) {
        setHasData(true);
      }
      props._showLoader(false);
    } catch {
      props._showLoader(false);
    }
  };

  const componentes = ({ counter }) => {
    let pathname = window.location.pathname;
    if (pathname === "/privacy-policy") {
      return <TermsAndCondition {...props} />;
    } else {
      if (hasData) {
        return <DashboardPwa {...props} />;
      } else if (counter === 0 && props.campaign.step === 1) {
        if (isTemp) {
          return (
            <>
              <Dashboard {...props} />
            </>
          );
        } else {
          return (
            <div className="form homeSinLogin">
              <div>
                <p className="subTitle">Bienvenido a Let's Advertise</p>
                <h1 className="title">Crea tu primera campaña</h1>
                <a href="#" className="btn-plus" onClick={handleAdd}>
                  <i className="fas fa-plus" />
                </a>
              </div>
              <div></div>
            </div>
          );
        }
      } else {
        return <Campana {...props} />;
      }
    }
  };
  return componentes({ counter });
};

const mapStateToProps = (state) => ({
  campaign: state.campaign,
  currentAccount: state.accounts.default,
});

const mapDispatchToProps = (dispatch) => ({
  _setCurrentUser: (user) => dispatch(setCurrentUser(user)),
  _getCategories: (account, user) => dispatch(getCategories(account, user)),
  _showLoader: (value) => dispatch(showLoader(value)),
  _clearCampaigns: () => dispatch(clearCampaigns()),
  _getAccounts: (client) => dispatch(getAccounts(client)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Home);
