import { saveName, saveStep, cleanCampaign, nextStepLogout } from './campana.actions'

export const saveNames = (name) => dispatch => {
    dispatch(saveName(name))
}

export const saveSteps = (step) => dispatch => {
    dispatch(saveStep(step))
}

export const cleanSteps = () => dispatch => {
    dispatch(cleanCampaign())
}

export const nextStepLogoutUser = (name) => dispatch => {
    dispatch(nextStepLogout(name))
}