import React, { useEffect, useState, useRef } from "react";
import { columnsOptions, validateTotals } from "config/columns";
import ReactDataTable, { NumberFormatEdit } from "@easygosanet/react-datatable";
import { getDataLevels } from "redux/actions/tableLevels.actions";
import * as APILA from "helpers/apiLA";
import { connect } from "react-redux";
import { getOdooOvsByOrderNumber } from "redux/actions/odooOvs.actions";
import { withUtils } from "utils";
import { showLoader } from "redux/actions/loader.actions";
import _ from "lodash";
import { getCampaignByIdMix } from "redux/actions/dashboard.actions";
import { getTotalsData } from "redux/actions/tableLevels.actions";

const customStyles2 = {
  rows: {
    style: {
      fontSize: "14px",
      fontWeight: "normal",
      border: "none !important",
      backgroundColor: "#ececec !important",
    },
  },
  headRow: {
    style: {
      backgroundColor: "#f2f2f2",
      fontSize: "16px",
      border: "none !important",
      backgroundColor: "#ececec !important",
      // maxWidth: "auto !important",
      // minWidth: "auto !important",
    },
  },
  cells: {
    style: {
      padding: "8px",
      border: "none !important",
      backgroundColor: "#ececec !important",
      maxWidth: "none !important",
      minWidth: "none !important",
    },
  },
  headCells: {
    style: {
      backgroundColor: "#05252d !important",
      fontWeight: "bold !important",
      display: "flex !important",
      alignItems: "center !important",
      justifyContent: "center !important",
      width: "120px",
      height: "40px",
      borderRadius: "20px !important",
      maxWidth: "none !important",
      minWidth: "none !important",
      // border: "2px solid grey",
      margin: "10px",
      color: "#fff",
      boxShadow: "0px 9px 8px -3px rgba(0,0,0,0.1)",
    },
    content: {
      style: {
        backgroundColor: "#ff642e !important",
        borderRadius: "50% !important",
        width: "100%  !important",
        height: "100% !important",
        display: "flex !important",
        alignItems: "center !important",
        justifyContent: "center !important",
        color: "#05252d",
      },
    },
  },
};

const Level2 = (props) => {
  const [data, setData] = useState([]);
  const [totales, setTotales] = useState([]);
  useEffect(() => {
    getData();
  }, []);

  const tableRef = useRef(null);
  const [heightForTitle, setHeightForTitle] = useState();
  useEffect(() => {
    if (tableRef.current) {
      const altura = tableRef.current.offsetHeight;
      setHeightForTitle(altura);
      console.log("Altura del componente:", altura);
    }
  }, []);

  const getData = async () => {
    const response = await props._getDataLevels(
      props.accounts,
      props,
      props.data.typeData,
      props.currentAccount,
      ""
    );
    setData(response);
    setTotales([
      props._getTotalsData(
        columnsOptions[props.data.typeData].columns,
        response
      ),
    ]);
  };

  props.addTitle(columnsOptions[props.data.typeData].name);

  return (
    <div className="levels__expandable-component-only">
      <div>
        <div className="levels__title levels__second-title">
          <div className="level__title-container level__second-title-container">
            <i className="levels__arrow levels__arrow-up"></i>
            <span style={{ textAlign: "center" }}>
              {columnsOptions[props.data.typeData].name}
            </span>
          </div>

          <div className="level__footer-container">
            <hr className="level__footer-separator" />
            <span className="level__footer-total">
              Total<i className="fa fa-plus"></i>
            </span>
          </div>
        </div>
      </div>
      <div>
        <ReactDataTable
          ref={tableRef}
          columns={columnsOptions[props.data.typeData].columns}
          data={data}
          customStyles={customStyles2}
          persistTableHead
        />
        <ReactDataTable
          noTableHead={true}
          columns={columnsOptions[props.data.typeData].columns}
          customStyles={customStyles2}
          groupedBy={""}
          data={totales}
        />
      </div>
    </div>
  );
};
const mapStateToProps = (state) => ({
  clients: state.clients,
  budgets: state.budgets.list,
  accounts: state.accounts.list,
  currentAccount: state.accounts.default,
});

const mapDispatchToProps = (dispatch) => ({
  _showLoader: (show) => dispatch(showLoader(show)),
  _getCampaignByIdMix: (idCampaign, idCLient, budgets, idBudget) =>
    dispatch(getCampaignByIdMix(idCampaign, idCLient, budgets, idBudget)),
  _getOdooOvsByOrderNumber: (orderNumber) =>
    dispatch(getOdooOvsByOrderNumber(orderNumber)),
  _getDataLevels: (accounts, props, typeData, currentAccount, father) =>
    dispatch(getDataLevels(accounts, props, typeData, currentAccount, father)),
  _getTotalsData: (columns, data) => dispatch(getTotalsData(columns, data)),
});
export default connect(mapStateToProps, mapDispatchToProps)(withUtils(Level2));
