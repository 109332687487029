import React from "react";
import { SimpleText } from "./simpleText";
import { useSelector } from "react-redux";
import { TOTALES } from "../constants";

const CustomCellSegmentation = ({ row, editSegmentation }) => {
    const propsEdit = useSelector((state) => state.templates.edit);
    const currentAudiences = useSelector((state) => state.templates.listAudiences);
    const dataUser = useSelector((state) => state.templates.userInfo);

    let labelAudience = currentAudiences?.map((audi) => audi.label);
    let currentAudience = currentAudiences?.find(
        (audi) => audi.value === row?.idAudience
    );
    const validateError = !row?.idAudience && row?.name !== TOTALES ? true : false;

    if (row?.name === TOTALES) return <></>;

    return (
        <div style={{ display: "flex", alignItems: "center" }}>
            {validateError && (
                <i
                    className="fa fa-exclamation-circle text-danger"
                    aria-hidden="true"
                    style={{ marginRight: "8px" }}
                ></i>
            )}
            <SimpleText
                value={currentAudience?.label || ""}
                type={"select"}
                onEditValue={(value) => editSegmentation(value, propsEdit, currentAudiences, dataUser)}
                editable={true}
                id={{ id: row.id, campo: "audience" }}
                nameColumn={row}
                editCell={validateError}
                dataSelect={labelAudience || []}
            />
        </div>
    );
}

export default CustomCellSegmentation;