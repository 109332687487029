import React, { useEffect, useState } from "react";
import { columnsOptions } from "config/columns";
import ReactDataTable, { NumberFormatEdit } from "@easygosanet/react-datatable";
import * as APILA from "helpers/apiLA";
import { connect } from "react-redux";
import { withUtils } from "utils";
import { showLoader } from "redux/actions/loader.actions";
import { getDataLevels } from "redux/actions/tableLevels.actions";
import { getTotalsData } from "redux/actions/tableLevels.actions";
import "./assets/styles.scss";

import Level2 from "./level2";
import _ from "lodash";

const customStyles2 = {
  rows: {
    style: {
      fontSize: "14px",
      fontWeight: "normal",
      border: "none !important",
      backgroundColor: "white !important",
    },
  },
  headRow: {
    style: {
      backgroundColor: "#f2f2f2",
      fontSize: "16px",
      border: "none !important",
      backgroundColor: "white !important",
    },
  },
  cells: {
    style: {
      padding: "8px",
      border: "none !important",
      backgroundColor: "white !important",
    },
  },
  headCells: {
    style: {
      backgroundColor: "#ff642e !important",
      fontWeight: "bold !important",
      display: "flex !important",
      alignItems: "center !important",
      justifyContent: "center !important",
      width: "120px",
      height: "40px",
      borderRadius: "20px !important",
      // border: "2px solid grey",
      color: "#fff",
      margin: "10px",
      boxShadow: "0px 9px 8px -3px rgba(0,0,0,0.1)",
    },
    content: {
      style: {
        backgroundColor: "#ff642e !important",
        borderRadius: "50% !important",
        width: "100%  !important",
        height: "100% !important",
        display: "flex !important",
        alignItems: "center !important",
        justifyContent: "center !important",
      },
    },
  },
};

const Level1 = (props) => {
  const [data, setData] = useState([]);
  const [columns, setColumns] = useState([]);
  const [totales, setTotales] = useState([]);
  const [titleTable, setTitleTable] = useState("");
  const { id } = props.data;
  useEffect(() => {
    getData();
  }, []);
  const generateColumns = (columnsOptions, showSecondLevelTable) => {
    return columnsOptions.map((ele) => {
      if (ele.id === "idsCampaign") {
        return {
          ...ele,
          cell: (row) => (
            <div onClick={() => showSecondLevelTable(row, ele.id)}>
              {Array.isArray(row?.idsCampaign)
                ? row.idsCampaign.join(", ")
                : ""}
            </div>
          ),
        };
      } else if (ele.id === "orderNumber") {
        return {
          ...ele,
          cell: (row) => (
            <div
              onClick={() => {
                showSecondLevelTable(row, ele.id);
              }}
            >
              {row.orderNumber}
            </div>
          ),
        };
      }
      return ele;
    });
  };

  const getData = async () => {
    const response = await props._getDataLevels(
      props.accounts,
      props,
      props.data.typeData,
      props.currentAccount,
      id
    );
    setData(response);
    setTotales([
      props._getTotalsData(
        columnsOptions[props.data.typeData].columns,
        response
      ),
    ]);
    setColumns(
      generateColumns(
        columnsOptions[props.data.typeData].columns,
        showSecondLevelTable
      )
    );
  };

  const showSecondLevelTable = (row, type) => {
    setData((data) => {
      let updateData = [...data];
      const indexToUpdate = updateData.findIndex((item) => item.id === row.id);
      updateData[indexToUpdate].typeData = type;
      updateData[indexToUpdate].defaultExpanded =
        !updateData[indexToUpdate].defaultExpanded;
      return updateData;
    });
  };

  const viewExpanded = (row) => {
    return row.defaultExpanded;
  };

  const addTitle = (title) => {
    setTitleTable(title);
  };

  return (
    <div className="levels__expandable-component">
      <div className="levels__main-container">
        <div className="levels__title">
          <div className="levels__title-complete-column">
            <div className="level__title-header">
              <i className="levels__arrow levels__arrow-up"></i>
              <span style={{ textAlign: "center" }}>
                {columnsOptions[props.data.typeData]?.name}
              </span>
            </div>
            <div className="level__footer-container">
              <hr className="level__footer-separator" />
              <span className="level__footer-total">
                Total<i className="fa fa-plus"></i>
              </span>
            </div>
          </div>
        </div>
        {/* {titleTable !== "" ? (
          <div className="levels__title levels__second-title">
            <div className="level__title-container level__second-title-container">
              <i className="levels__arrow levels__arrow-up"></i>
              <span style={{ textAlign: "center" }}>{titleTable}</span>
            </div>

            <div className="level__footer-container">
              <hr className="level__footer-separator" />
              <span className="level__footer-total">
                Total<i className="fa fa-plus"></i>
              </span>
            </div>
          </div>
        ) : null} */}
      </div>
      <div
        style={{
          flex: "1",
          flexDirection: "flex=start",
          border: "1px solid #000",
        }}
      >
        <ReactDataTable
          columns={columns}
          data={data}
          customStyles={customStyles2}
          persistTableHead
          expandableRows
          expandableRowExpanded={(row) => viewExpanded(row)}
          expandableRowsComponent={(props) => (
            <Level2 {...props} addTitle={addTitle} id={id} />
          )}
          // expandableRowsComponent={
          //   <Level2 {...props} addTitle={addTitle} id={id} />
          // }
          expandableRowsHideExpander={true}
        />
        <ReactDataTable
          noTableHead={true}
          columns={columns}
          customStyles={customStyles2}
          groupedBy={""}
          data={totales}
        />
      </div>
    </div>
  );
};
const mapStateToProps = (state) => ({
  clients: state.clients,
  accounts: state.accounts.list,
  currentAccount: state.accounts.default,
});

const mapDispatchToProps = (dispatch) => ({
  _showLoader: (show) => dispatch(showLoader(show)),
  _getDataLevels: (accounts, props, typeData, currentAccount, father) =>
    dispatch(getDataLevels(accounts, props, typeData, currentAccount, father)),
  _getTotalsData: (columns, data) => dispatch(getTotalsData(columns, data)),
});
export default connect(mapStateToProps, mapDispatchToProps)(withUtils(Level1));
