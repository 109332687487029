/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { FormGroup, Label, Input, FormFeedback } from "reactstrap";
import {
  saveSocials,
  getMediumBanner,
  saveproduct,
  saveMediumm,
  saveIdmediumm,
  saveCountries,
} from "redux/actions/medioCamp.actions";
import { saveSteps } from "redux/actions/nameCamp.actions";
import { getCountries } from "redux/actions/medioCamp.actions";
const MedioCamp = (props) => {
  const [country, setCountry] = useState(props?.campaign?.country || "");
  const [medium, setMedium] = useState({
    site: props?.campaign?.medium || "",
    id_medium: props?.campaign?.id_medium || "",
  });
  const [formato, setFormato] = useState(props?.campaign?.id_product || {});
  useEffect(() => {
    // props._getMediumBanner('')
    props._getCountries();
    if (props.campaignupd.length > 0) {
      // const bod = {
      //   id_medium: props.campaignupd[0].id_medium,
      // }
      // props._getMediumBanner(bod)
    }
  }, []);

  const inputCountryRef = useRef(null);

  const inputMediumRef = useRef(null);

  const inputFormatRef = useRef(null);


  const [labelCountry, setLabelCountry] = useState({
    label: "Ej. Guatemala, Estados Unidos",
    success: true,
  });

  const [mediumLabel, setMediumLabel] = useState({
    label: "Ej. Facebook, Google Display",
    success: true,
  });

  const [format, setFormat] = useState({
    label: "Ej. Alcance, Lightbox ",
    success: true,
  });

  const handeInputCountryRef = () => {
    setMedium({});
    setFormato({});

    if (inputCountryRef.current && inputCountryRef.current.value !== "") {
      inputCountryRef.current.style.borderBottom = "1px solid #05252d";
      inputCountryRef.current.style.color = "unset";
      setLabelCountry({
        label: "Ej. Guatemala, Estados Unidos",
        success: true,
      });

      setMediumLabel({
        label: "Ej. Facebook, Google Display",
        success: true,
      });

      
      setFormat({
        label: "Ej. Alcance, Lightbox ",
        success: true,
      });

    } else {
      setLabelCountry({
        label: "Por favor, selecciona un país",
        success: false,
      });

      setMediumLabel({
        label: "Ej. Facebook, Google Display",
        success: true,
      });


      setFormat({
        label: "Ej. Alcance, Lightbox ",
        success: true,
      });


      inputCountryRef.current.style.borderBottom = "1px solid red";
      inputCountryRef.current.style.color = "red";
    }
  };

  const handeInputMediumRef = () => {
    setFormato({});
    if (inputMediumRef.current && inputMediumRef.current.value !== "") {
      inputMediumRef.current.style.borderBottom = "1px solid #05252d";
      inputMediumRef.current.style.color = "unset";
      setMediumLabel({
        label: "Ej. Facebook, Google Display",
        success: true,
      });
    } else {
      setMediumLabel({
        label: "Por favor, selecciona un medio",
        success: false,
      });
      inputMediumRef.current.style.borderBottom = "1px solid red";
      inputMediumRef.current.style.color = "red";
    }
  };


  
  const handleInputFormatChange = () => {
    setFormato({});
    if (inputFormatRef.current && inputFormatRef.current.value !== "") {
      inputFormatRef.current.style.borderBottom = "1px solid #05252d";
      inputFormatRef.current.style.color = "unset";

      setFormat({
        label: "Ej. Alcance, Lightbox ",
        success: true,
      });
    } else {
      setFormat({
        label: "Por favor, selecciona un formato",
        success: false,
      });
      inputFormatRef.current.style.borderBottom = "1px solid red";
      inputFormatRef.current.style.color = "red";
    }
  };


  const handleCountryChange = (event, value) => {
    setFormato({});
    setMedium({});
    const inputCountry = document.getElementById("btnCountry");

    if (value) {
      setMediumLabel({
        label: "Ej. Facebook, Google Display",
        success: true,
      });

      setLabelCountry({
        label: "Ej. Guatemala, Estados Unidos",
        success: true,
      });

      setFormat({
        label: "Ej. Alcance, Lightbox ",
        success: true,
      });

      inputCountry.style.borderBottom = "1px solid #05252d";
      inputCountry.style.color = "unset";

      let bod = {
        id_region: value.id_region,
        bandera: 1,
      };
      let countrybod = {
        id_region: value.id_region,
        name: value.name,
      };
      setCountry(countrybod);
      props._getMediumBanner(bod);
    } else {
      setLabelCountry({
        label: "Por favor, selecciona un país",
        success: false,
      });

      setMediumLabel({
        label: "Ej. Facebook, Google Display",
        success: true,
      });

      setFormat({
        label: "Ej. Alcance, Lightbox ",
        success: true,
      });

      inputCountry.style.borderBottom = "1px solid red";
      inputCountry.style.color = "red";

      setCountry(value);
    }
  };
  const handleMediumChange = (event, value) => {
    setFormato({});
    const inputMedium = document.getElementById("btnMedioCamp");

    if (value) {
      setMediumLabel({
        label: "Ej. Facebook, Google Display",
        success: true,
      });

      setFormat({
        label: "Ej. Alcance, Lightbox ",
        success: true,
      });

      inputMedium.style.borderBottom = "1px solid #05252d";
      inputMedium.style.color = "unset";

      let bod = {
        id_medium: value.id_medium,
        id_region: country.id_region,
      };
      setMedium({
        site: value.site,
        id_medium: value.id_medium,
      });
      props._getMediumBanner(bod);
      props._saveIdmediumm(value.id_medium);
      props._saveMediumm(value.site);
    } else {
      setMediumLabel({
        label: "Por favor, selecciona un medio",
        success: false,
      });

      setFormat({
        label: "Ej. Alcance, Lightbox ",
        success: true,
      });
      inputMedium.style.borderBottom = "1px solid red";
      inputMedium.style.color = "red";

      setMedium(value);
    }
  };

  const cambiomedium = (dato) => {
    if (dato) {
      props._saveproduct(dato.id_ad_product);
    }
  };

  const cambio2 = (dato) => {
    if (dato) {
      const bod = {
        id_medium: dato.id_medium,
      };

      props._saveIdmediumm(dato.id_medium);
      props._saveMediumm(dato.site);
      props._getMediumBanner(bod);
    }
  };

  const backName = (e) => {
    e.preventDefault();
    props._saveSteps(1);
  };

  const handleMediumTypeChange = (event, value) => {
    setFormato({});
    const input = document.getElementById("btnMedioCampFormat");
    if (value) {
      setFormat({
        label: "Ej. Alcance, Lightbox ",
        success: true,
      });

      input.style.borderBottom = "1px solid #05252d";
      input.style.color = "unset";
      let bod = {
        banner: value.banner,
        id_ad_product: value.id_ad_product,
      };
      setFormato(bod);
      setFormato(bod);
      props._saveproduct(bod);
    } else {
      input.style.borderBottom = "1px solid red";
      input.style.color = "red";

      setFormat({
        label: "Por favor, selecciona un formato",
        success: false,
      });
    }
  };

  const saveMetodo = (e) => {
    e.preventDefault();
    const inputMedium = document.getElementById("btnMedioCamp");
    const inputCountry = document.getElementById("btnCountry");
    const input = document.getElementById("btnMedioCampFormat");

    if (
      inputMedium.value !== "" &&
      inputCountry.value !== "" &&
      input.value !== ""
    ) {
      props._saveSocials("");
      props._saveCountries(country);
      props._saveSteps(3);
    } else {
      if (!inputMedium.value) {
        inputMedium.style.borderBottom = "1px solid red";
        inputMedium.style.color = "red";
        setMediumLabel({
          label: "Por favor, selecciona un medio",
          success: false,
        });
      }
      if (!inputCountry.value) {
        inputCountry.style.borderBottom = "1px solid red";
        inputCountry.style.color = "red";
        setLabelCountry({
          label: "Por favor, selecciona un país",
          success: false,
        });
      }

      if (!input.value) {
        input.style.borderBottom = "1px solid red";
        input.style.color = "red";
        setFormat({
          label: "Por favor, selecciona un formato",
          success: false,
        });
      }
    }
  };
  if (props.campaign.redSocial === null && props.campaignupd.length > 0) {
    props.campaign.redSocial = props.campaignupd[0].medio;
  }

  let mediumList = props.mediumbanners;
  let mediumListtype = props.mediumbannerstype;

  let va = {};
  let vava = {};

  if (props.campaignupd.length > 0) {
    var medio = "";
    if (
      props.campaignupd[0].medioo !== null &&
      props.campaignupd[0].medioo !== undefined &&
      props.campaignupd[0].medioo !== ""
    ) {
      medio = props.campaignupd[0].medioo;
    } else {
      medio = props.campaignupd[0].medio;
    }

    va = {
      id_medium: props.campaignupd[0].id_medium,
      site: medio,
    };

    vava = {
      id_ad_product: props.campaignupd[0].id_ad_product,
      banner: props.campaignupd[0].banner,
    };
  }

  return (
    <div className={props.clase}>
      <h1 className="title">¿Dónde se realizará tu campaña? </h1>
      <br></br>
      <div className="oneColBody">
        <FormGroup>
          <Label
            style={{
              color: "#05252d",
              fontWeight: 500,
              marginBottom: "5px",
            }}
          >
            Elige un pa&iacute;s donde pautar
          </Label>
          <Autocomplete
            id="btnCountry"
            value={country}
            options={props.countries}
            onChange={handleCountryChange}
            getOptionLabel={(option) => option.name || ""}
            style={{ width: 300, height: 35 }}
            renderInput={(params) => (
              <TextField
              {...params}
              //label="Ej. Guatemala, Estados Unidos"
              label={labelCountry.label}
              variant="outlined"
              inputRef={inputCountryRef}
              onChange={handeInputCountryRef}
            />
            )}
          />
          <FormFeedback>Elige un pais</FormFeedback>
        </FormGroup>
      </div>
      <div className="oneColBody">
        <FormGroup>
          <Label
            style={{
              color: "#05252d",
              fontWeight: 500,
              marginBottom: "5px",
            }}
          >
            Elige un medio donde pautar
          </Label>
          <Autocomplete
            // value={medium}
            id="btnMedioCamp"
            options={labelCountry.success ? props.mediumbanners : []}
            onChange={handleMediumChange}
            value={medium}
            getOptionLabel={(option) => option.site || ""}
            // style={{ width: 300, height: 35 }}
            // className={classnames({
            //   'is-invalid': errors.medio,
            // })}
            renderInput={(params) => (
              <TextField
              {...params}
              label={mediumLabel.label}
              variant="outlined"
              inputRef={inputMediumRef}
              onChange={handeInputMediumRef}
            />
            )}
          />
          <FormFeedback>Ingresa un medio donde pautar</FormFeedback>
        </FormGroup>
      </div>
      <div className="oneColBody">
        <FormGroup>
          <Label
            style={{
              color: "#05252d",
              fontWeight: 500,
              marginBottom: "5px",
            }}
          >
            Elige un formato donde pautar
          </Label>
          <Autocomplete
            // value={medium}
            value={formato}
            id="btnMedioCampFormat"
            options={
              mediumLabel.success && labelCountry.success
                ? props.mediumbannerstype
                : []
            }
            onChange={handleMediumTypeChange}
            getOptionLabel={(option) => option.banner || ""}
            // style={{ width: 300, height: 35 }}
            // className={classnames({
            //   'is-invalid': errors.medio,
            // })}
            renderInput={(params) => (
              // <TextField {...params} label="Formato" variant="outlined" />
              <TextField
                {...params}
                label={format.label}
                variant="outlined"
                inputRef={inputFormatRef}
                onChange={handleInputFormatChange}
              />
            )}
          />
          <FormFeedback>Ingresa un medio donde pautar</FormFeedback>
        </FormGroup>
      </div>
      <br></br>

      <div className="space-btns">
        <a className="btn-camp gray" onClick={backName} href="#">
          Volver
        </a>
        <a className="btn-camp" onClick={saveMetodo} href="#" id="nextMedio">
          Siguiente
        </a>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  campaign: state.campaign,
  campaignupd: state.campaignupd,
  mediumbanners: state.mediumbanners,
  mediumbannerstype: state.mediumbannerstype,
  countries: state.countryReducer,
});

const mapDispatchToProps = (dispatch) => ({
  _saveSteps: (step) => dispatch(saveSteps(step)),
  _saveSocials: (name) => dispatch(saveSocials(name)),
  _saveCountries: (country) => dispatch(saveCountries(country)),

  _saveMediumm: (medium) => dispatch(saveMediumm(medium)),
  _saveproduct: (product) => dispatch(saveproduct(product)),
  _getMediumBanner: (bdy) => dispatch(getMediumBanner(bdy)),
  _saveIdmediumm: (idmedium) => dispatch(saveIdmediumm(idmedium)),
  _getCountries: () => dispatch(getCountries()),
});

export default connect(mapStateToProps, mapDispatchToProps)(MedioCamp);
