import React from "react";

// components
import MetaTags from "components/metatags";
import Content from "components/audiences";
import UpsertAudienceModal from "components/audiences/modal/upsert_audience";

const AudienceListPage = () => (
  <>
    <MetaTags title="Let's Advertise Ads - Públicos y exclusiones" description="Let's Advertise Ads te permite llegar a tu audiencia de forma eficiente a través de todos los medios de publicidad. Registra tus públicos y exclusiones." />
    {/* <MetaTags title="Audiencias" description="Página de audiencias" /> */}

    <div className="content-audience">
      <Content />
    </div>

    <UpsertAudienceModal />
  </>
);

export default AudienceListPage;
