import React from "react";
import { useSelector } from "react-redux";
import { SimpleText } from "./simpleText";

const CustomCellImplementation = ({ row, editCel }) => {
  const propsEdit = useSelector((state) => state.templates.edit);
  const idBudgetImplement = useSelector(
    (state) => state.templates.errorsImplement
  );
  const dataUser = useSelector((state) => state.templates.userInfo);


  let editCell =
    row?.budget === ""
      ? true
      : idBudgetImplement?.map(id => id.id)?.includes(row?.idBudget);


  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      {editCell && (
        <i
          className="fa fa-exclamation-circle text-danger"
          aria-hidden="true"
          style={{ marginRight: "8px" }}
        ></i>
      )}
      <div style={{ display: "flex", alignItems: "center" }}>
        <SimpleText
          value={`$${Number(row.budget).toFixed(2) || 0.00}`}
          type={"text"}
          onEditValue={(value) => editCel(value, propsEdit, [], dataUser)}
          editable={true}
          id={{ id: row.idBudget, campo: "budgetI" }}
          editCell={editCell}
          nameColumn={row}
        />
      </div>
    </div>
  );
};

export default CustomCellImplementation;
