import * as Logger from 'utils/logger'

export const FILE_ADD_FILE = 'FILES:ADD'
export const FILE_CLEAR = 'FILES:CREAR'
export const FILE_REMOVE_FILE = 'FILES:REMOVE'

export const saveFilesPauta = files => dispatch => {
    /*files.forEach(file => {
        dispatch(addFiles(file))
    })*/
    dispatch(addFiles(files))

}

export const clearFiles = () => ({
  type: FILE_CLEAR
})

const addFiles = file => {
    return dispatch => {
      dispatch({
        type: FILE_ADD_FILE,
        file
      })
    }
  }
