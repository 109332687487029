import React from 'react'
import { connect } from 'react-redux'
import { Helmet } from 'react-helmet'
import PropTypes from 'prop-types'

const MetaTags = props => (
  <Helmet>
    <title>
      {/* {props.title} | {props.portal_name} */}
      {props.title ? props.title : props.portal_name}
    </title>
    <meta name="description" content={props.description} />
    {props.children}
  </Helmet>
)

MetaTags.propTypes = {
  title: PropTypes.string.isRequired,
}

const mapStateToProps = state => ({
  portal_name: state.app.portal_name,
  company_name: state.app.company_name,
})

export default connect(mapStateToProps)(MetaTags)