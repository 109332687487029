import { useEffect, useState } from "react";
import ActivosDigitales from "./ActivosDigitales";
import InformacionCuenta from "./InformacionCuenta";
import * as Modal from "redux/actions/modal.actions";
import { showLoader } from "redux/actions/loader.actions";
import { connect, useDispatch } from "react-redux";
import { Auth } from "aws-amplify";
import * as APILA from "helpers/apiLA";
import {
  getAssetsByClient,
  deleteDigitalAsset,
  getTypesDigitalAssets,
} from "redux/actions/digitalAssets.actions";
import PageLoader from "components/page_loader";

const objectPermissions = {
  superAdmin: "Super Administrador",
  settingsAccount: "Configuración de la cuenta",
};

const Cuentas = (props) => {
  const dispatch = useDispatch();

  const [permissions, setPermissions] = useState({
    approve: false,
    create: false,
    delete: false,
    edit: false,
    export: false,
    manageRoles: false,
    view: false,
  });

  const [isLoading, setIsLoading] = useState(false);

  const permisos = async () => {
    try {
      setIsLoading(true);
      let sub = Auth.user.signInUserSession.idToken.payload.sub;
      let permisosUser = await APILA.getPermitsByUser({
        user: sub,
      });
      let permisSupAdmin = permisosUser.data.permits?.find(
        (ele) => ele.idRol === objectPermissions.superAdmin
      );
      if (permisSupAdmin) {
        let permisosResponse = await APILA.getRoleNameById({
          id: permisSupAdmin.idRol,
        });
        let permisosRoles = permisosResponse.data.rol[0].permissions;
        let permissFn = permisosRoles.find(
          (ele) => ele.name === objectPermissions.settingsAccount
        );
        setPermissions(() => {
          return {
            view: permissFn.view,
            approve: permissFn.approve,
            create: permissFn.create,
            delete: permissFn.delete,
            edit: permissFn.edit,
            export: permissFn.export,
            manageRoles: permissFn.manageRoles,
          };
        });
        setIsLoading(false);
        return permissFn;
      } else {
        let permisAccouunt = permisosUser.data.permits?.find(
          (ele) => ele.account === props.currentAccount
        );
        let idRol = permisAccouunt.idRol;
        let permisosResponse = await APILA.getRoleNameById({ id: idRol });
        let permissFn = permisosResponse.data.rol[0].permissions.filter(
          (ele) => ele.name === objectPermissions.settingsAccount
        );
        setPermissions(() => {
          return {
            view: permissFn[0].view,
            approve: permissFn[0].approve,
            create: permissFn[0].create,
            delete: permissFn[0].delete,
            edit: permissFn[0].edit,
            export: permissFn[0].export,
            manageRoles: permissFn[0].manageRoles,
          };
        });
        isLoading(false);
        return permissFn[0];
      }
    } catch (err) {
      setPermissions(() => {
        return {
          approve: true,
          create: true,
          delete: true,
          edit: true,
          export: true,
          manageRoles: true,
          view: true,
        };
      });
      setIsLoading(false);
      console.log("err permits", err);
    }
  };

  useEffect(() => {
    dispatch(getTypesDigitalAssets());
  }, []);

  useEffect(() => {
    if (!props.currentAccount) return;
    permisos();
  }, [props.currentAccount]);

  const currentUrl = new URLSearchParams(window.location.pathname);
  const valueVistaSelector = Number(currentUrl.get("view_selector"));
  const [value, setValue] = useState(valueVistaSelector || 1);

  let mapComponent = {
    1: (
      <ActivosDigitales
        permissions={permissions}
        value={value}
        setValue={setValue}
      />
    ),
    2: (
      <InformacionCuenta
        permissions={permissions}
        value={value}
        setValue={setValue}
      />
    ),
  };

  return <>{isLoading ? <PageLoader /> : <>{mapComponent[value]}</>}</>;
};

const mapStateToProps = (state) => ({
  currentAccount: state.accounts.default,
});

const mapDispatchToProps = (dispatch) => ({
  _showLoader: (show) => dispatch(showLoader(show)),
  _showConfirmationModal: (title, message, callback) =>
    dispatch(
      Modal.showAlertModal(
        title,
        message,
        Modal.MODAL_TYPE_CONFIRMATION,
        callback
      )
    ),
  _getAssetsByClient: ({ account }) => dispatch(getAssetsByClient({ account })),
  _deleteDigitalAsset: ({ id }) => dispatch(deleteDigitalAsset({ id })),
});

export default connect(mapStateToProps, mapDispatchToProps)(Cuentas);
