import { v4 } from "uuid";
import { Auth } from "aws-amplify";
import _ from "lodash";
import * as API from "helpers/api";
import * as APILA from "helpers/apiLA";
import * as Logger from "utils/logger";
import * as Modal from "./modal.actions";
import { showLoader } from "./loader.actions";
import { getBudgets } from "./budgets.actions";
import { upsertCategory } from "./categories.actions";
import { clearFiles } from "./files.actions";
import Swal from "sweetalert2";
import status, { arrayPriority } from "config/status";
import { formatDashboardModel } from "utils/utilsModelCampaign";
import { insertLogTemplate } from "./templates.actions";

export const CAMPAIGNS_UPD = "CAMPAIGNS:UPD";
export const CAMPAIGNS_UPADD = "CAMPAIGNS_UPADD";

export const CAMPAIGNS_CLEAR = "CAMPAIGNS:CLEAR";
export const CAMPAIGNS_BUDGET = "CAMPAIGNS:BUDG";
export const CAMPAIGNS_ADD_ONE = "CAMPAIGNS:ADD:ONE";
export const CAMPAIGNS_ADD_TOTAL = "CAMPAIGNS:ADD:TOTAL";
export const CAMPAIGNS_UPD_CLEAR = "CAMPAIGNS:UPDCLEAR";
export const CAMPAIGNS_UPADD_CLEAR = "CAMPAIGNS_UPADD_CLEAR";

export const CAMPAIGNS_BUDGET_CLEAR = "CAMPA IGNS:BUDGCLEAR";
export const CAMPAIGNS_UPDATE_STATUS = "CAMPAIGNS:UPDATE:STATUS";

export const CAMPAIGNS_CHANGE_TOTAL = "CAMPAIGNS:TOTAL:CHANGE";
export const CAMPAIGNS_CHANGE_LOADING = "CAMPAIGNS:LOADING:CHANGE";
export const CAMPAIGNS_CHANGE_FILTER_TEXT = "CAMPAIGNS:FILTER:TEXT:CHANGE";
export const CAMPAIGNS_CHANGE_FILTER_SORT = "CAMPAIGNS:FILTER:SORT:CHANGE";
export const CAMPAIGNS_CHANGE_FILTER_PAGE = "CAMPAIGNS:FILTER:PAGE:CHANGE";
export const CAMPAIGNS_LIST_ARRAY_CAMPAIGNS = "CAMPAIGNS:LIST:ARRAY";
export const CAMPAIGNS_CHANGE_FILTER_PAGINATION =
  "CAMPAIGNS:FILTER:PAGINATION:CHANGE";
export const CAMPAIGNS_CHANGE_PAGINATION_PER_PAGE =
  "CAMPAIGNS:PAGINATION:CHANGE";
export const ADD_CAMPAIGNS = "CAMPAIGNS:ADD_CAMPAIGNS";
export const ADD_CAMPAIGNS_FILTERED = "CAMPAIGNS:ADD_CAMPAIGNS_FILTERED";

const messageAlert =
  "¡Hola! Para garantizar tu solicitud, elige otra prioridad o prográmala para el siguiente día hábil.";
const iconImage =
  "https://marketing-suite-files-bucket90024-production.s3.amazonaws.com/icons/clock2.png";

const TAG = "Dashboard:Actions";

export const msgsPersonalizado = (text, type) => (dispatch) => {
  Logger.log(TAG, "recibio", type);

  if (type == "error") {
    dispatch(
      Modal.showAlertModal("Error", text, Modal.MODAL_TYPE_ERROR, () => {})
    );
  } else if (type == "success") {
    dispatch(
      Modal.showAlertModal("Exito", text, Modal.MODAL_TYPE_SUCCESS, () => {})
    );
  } else {
    dispatch(
      Modal.showAlertModal("Información", text, Modal.MODAL_TYPE_INFO, () => {})
    );
  }
};

export const alertMessage = (title, body) => (dispatch) => {
  dispatch(
    Modal.showAlertModal(title, body, Modal.MODAL_TYPE_SUCCESS, () => {})
  );
};

export const createCampaignTemplateLogout =
  (
    id,
    name,
    medio,
    fechaIni,
    fechafin,
    objetivo,
    budget,
    publico,
    id_medium,
    medioo,
    id_ad_product,
    banner,
    estado,
    idBudget,
    account,
    creativity,
    id_sale,
    observation,
    copy,
    urls,
    availableAmount,
    id_region,
    name_region,
    nameForEmail,
    emailForEmail,
    version,
    npost,
    budgets,
    campaigns,
    idAudience,
    categoriesSelected,
    formato,
    priority
  ) =>
  async (dispatch) => {
    const statusCampaign = {
      1: "creado",
      2: "cancelado",
      5: "aprobado",
      6: "en revision",
      9: "en implementacion",
      10: "activo",
      11: "en revsion",
      12: "finalizado",
      14: "pausado",
      15: "en revision",
      16: "cancelado",
      17: "finalizado",
    };
    let searchable_status;
    if (estado) {
      searchable_status = statusCampaign[estado];
    }

    let body = {
      id,
      name,
      medio,
      status: estado,
      fechaIni,
      fechafin,
      objetivo,
      budget,
      publico,
      id_medium,
      medioo,
      id_ad_product,
      banner,
      idBudget,
      account: account.id,
      creativity,
      id_sale,
      observation,
      copy,
      id_region,
      name_region,
      nameForEmail,
      emailForEmail,
      version,
      npost,
      idAudience,
      searchable_version: version.toLowerCase(),
      searchable_name: name.toLowerCase(),
      searchable_medio: medio.toLowerCase(),
      searchable_status,
      categoriesSelected,
      formato,
      priority,
    };

    try {
      let available = 0;
      let idResponse;
      await APILA.createCampaign(body);
      dispatch(getBudgets(account.id));
      return idResponse;
    } catch (err) {
      Logger.log(TAG, "error createCampaing", err);
      dispatch(
        Modal.showAlertModal(
          "Error!",
          "Ocurrió un error al crear la campaña",
          Modal.MODAL_TYPE_ERROR
        )
      );
    }
  };

export const createCampaignLogOut =
  (
    id,
    name,
    medio,
    fechaIni,
    fechafin,
    objetivo,
    budget,
    publico,
    id_medium,
    medioo,
    id_ad_product,
    banner,
    estado,
    idBudget,
    account,
    creativity,
    id_sale,
    observation,
    copy,
    urls,
    id_region,
    name_region,
    nameForEmail,
    emailForEmail,
    version,
    npost,
    budgets,
    idAudience,
    categoriesSelected,
    formato,
    priority
  ) =>
  async (dispatch) => {
    dispatch(showLoader(true));

    const statusCampaign = {
      1: "creado",
      2: "cancelado",
      5: "aprobado",
      6: "en revision",
      9: "en implementacion",
      10: "activo",
      11: "en revsion",
      12: "finalizado",
      14: "pausado",
      15: "en revision",
      16: "cancelado",
      17: "finalizado",
    };

    let searchable_status;
    if (estado) {
      searchable_status = statusCampaign[estado];
    }

    let body = {
      id,
      name,
      medio,
      status: estado,
      fechaIni,
      fechafin,
      objetivo,
      budget,
      publico,
      id_medium,
      medioo,
      id_ad_product,
      banner,
      idBudget,
      account: account.id,
      creativity,
      id_sale,
      observation,
      copy,
      id_region,
      name_region,
      nameForEmail,
      emailForEmail,
      version,
      npost,
      idAudience,
      searchable_version: version.toLowerCase(),
      searchable_name: name.toLowerCase(),
      searchable_medio: medio.toLowerCase(),
      searchable_status,
      categoriesSelected,
      formato,
      priority,
    };

    const foundBudget = budgets.find((ele) => ele.id === body.idBudget);
    try {
      if (estado !== 1) return;
      //Calcular disponible, solo es por requerimiento de un servicio en la lambda (No modifica disponible de presupuesto)

      const parsedFoundBudget = parseFloat(foundBudget.available);
      const parsedBudget = parseFloat(budget);

      let available = 0;

      if (!isNaN(parsedFoundBudget) && !isNaN(parsedBudget)) {
        available = (parsedFoundBudget - parsedBudget).toFixed(2);
      }
      const budgetsTransact = [
        {
          id: idBudget,
          quantity: parseFloat(parsedBudget.toFixed(2)),
          sum: false,
          idCampaign: id,
          user: emailForEmail,
          account: account.id,
        },
      ];
      await APILA.budgetTransaction({ budgets: budgetsTransact });
      await APILA.createCampaign(body);
      dispatch(msgsuccess());
      dispatch(getBudgets(account.id));
    } catch (error) {
      Logger.log(TAG, "error createCampaing", error);
      let detailError = `Ocurrió un error al crear la campaña`;

      if (
        error.response &&
        error.response.data &&
        error.response.data.messageError &&
        error.response.data.messageError ===
          "La actualizacion excede los limites"
      ) {
        detailError = `El presupuesto asociado no cuenta con disponible de: $${budget}`;
      }

      dispatch(
        Modal.showAlertModal("Error!", detailError, Modal.MODAL_TYPE_ERROR)
      );
      throw new Error("Error");
    } finally {
      dispatch(showLoader(false));
    }
  };

export const updateCampaignModalLogout =
  (
    nameToSend,
    emailToSend,
    id,
    name,
    medio,
    fechaIni,
    fechafin,
    objetivo,
    budget,
    publico,
    idMixDetaill,
    idMixChange,
    idMix,
    idCampaign,
    id_medium,
    id_ad_product,
    banner,
    estado,
    idBudget,
    account,
    creativity,
    observation,
    copy,
    id_sale,
    id_region,
    name_region,
    nameForEmail,
    emailForEmail,
    urls,
    version,
    npost,
    budgets,
    idAudience,
    optionSelected,
    tagToEmail,
    formato,
    campAc,
    priority
  ) =>
  async (dispatch) => {
    dispatch(showLoader(true));
    try {
      const promises = [];
      let searchable_status = "pausado";
      let countEdit = 0;
      let changes = {};

      const [responseData, responseTemporal] = await Promise.all([
        getData(APILA.getCampaignById(idMixDetaill), "data.campaigns[0]"),
        getData(
          APILA.getTempCampaigns({
            account: account.id,
            id: id,
          }),
          "data.campaings[0]"
        ),
      ]);

      console.log("Response data", responseData);
      let prevStatus;
      let prevIdBudget;
      let prevBudget = parseFloat(budget); //Se inicializa con el valor del presupuesto seleccionado
      const budgetPauta = parseFloat(budget); //Presupuesto de la campa;ana
      let finalEtiqueta = "";

      if (responseData) {
        prevStatus = responseData?.status;
        prevIdBudget = responseData?.idBudget;
        prevBudget = parseFloat(responseData?.budget) ?? 0;
      }

      const errorBudget = await budgetTransactionLets(
        prevBudget,
        budgetPauta,
        idBudget,
        prevIdBudget,
        id,
        emailForEmail,
        account.id
      );

      if (errorBudget) {
        return dispatch(
          Modal.showAlertModal(
            "Error!",
            "Ocurrió un error al actualizar la campaña",
            Modal.MODAL_TYPE_ERROR
          )
        );
      }
      if (responseTemporal) {
        countEdit = responseTemporal.countEdit ?? 0;
      }

      let body = {
        id, //esta
        name, //esta
        medio, //esta
        status: estado, // !
        fechaIni, //esta
        fechafin, //esta
        objetivo, //esta
        budget, //esta
        publico, //esta
        idMixDetaill, //esta
        idMixChange,
        idMix,
        idCampaign,
        id_medium,
        medioo: medio,
        id_ad_product,
        banner, //esta
        idBudget,
        account: account?.id,
        creativity, //esta
        observation, //esta
        copy, //esta
        id_sale, //esta
        id_region,
        name_region, //esta
        nameForEmail, //esta
        emailForEmail, //esta
        urls, //esta
        version, //esta
        npost, //esta
        idAudience, //esta
        countEdit: countEdit,
        categoriesSelected: optionSelected, //esta
        searchable_name: name.toLowerCase(), //esta
        searchable_idMixDetaill: idMixDetaill.toString(), //esta
        searchable_version: version?.toLowerCase(), //esta
        searchable_medio: medio.toLowerCase(), //esta
        searchable_status,
        formato,
        priority, //esta
      };

      if (validateChangeStatus(body, responseData)) {
        body.status = estado;
      } else {
        body.status = campAc?.status ?? prevStatus;
        console.log("Mantiene el que esta");
      }

      if (campAc.status === 1 && estado === 14) {
        body.status = 1;
        console.log("ACA", prevStatus);
      }
      promises.push(APILA.updateCampaign(body));
      body.idCamp = v4();
      body.notified = false;
      await Promise.all(promises);
      localStorage.setItem("nameAWS", "");
      localStorage.setItem("tempAWSUser", "");
      localStorage.setItem("account", "");
    } catch (error) {
    } finally {
      dispatch(showLoader(false));
    }
  };

export const msgsuccess = (campaignupd) => (dispatch) => {
  dispatch(
    Modal.showAlertModal(
      "Solicitud Enviada",
      "Su campaña fue enviado exitosamente.",
      Modal.MODAL_TYPE_SUCCESS,
      () => {}
    )
  );
};

export const funcampaignupd = (campaignupd) => ({
  type: CAMPAIGNS_UPD,
  campaignupd,
});
export const funcampaignupAdd = (campaignuupAdd) => ({
  type: CAMPAIGNS_UPADD,
  campaignuupAdd,
});

export const updateCampaingStatus =
  (campaingId, status, prevStatus) => async (dispatch) => {
    Logger.log(TAG, "updateCampaignStatus");

    dispatch(showLoader(true));

    const body = {
      id: campaingId,
      status,
    };

    try {
      const response = await APILA.updateCampaignStatus(body);

      Logger.log(TAG, "updateCampaignStatus", response);
      dispatch(
        Modal.showAlertModal(
          "Solicitud Enviada",
          "Su campaña fue enviado exitosamente.",
          Modal.MODAL_TYPE_SUCCESS,
          () => {}
        )
      );
      dispatch(updateStatus(campaingId, status));
    } catch (err) {
      Logger.log(TAG, "error updateCampaignStatus", err);
      dispatch(
        Modal.showAlertModal(
          "Error!",
          "Ocurrió un error al actualizar la campaña",
          Modal.MODAL_TYPE_ERROR
        )
      );
      dispatch(updateStatus(campaingId, prevStatus));
    }

    dispatch(showLoader(false));
  };

export const getCampaignByIdMix =
  (idCampaign, idCLient, budgets, idBudget) => async (dispatch) => {
    try {
      dispatch(showLoader(true));

      const [responseMySql, responseDynamo] = await Promise.all([
        findCampaignByMixDetail(idCLient, idCampaign),
        getCampaignById(idCampaign),
      ]);
      if (responseMySql) {
        const responseModelCampaign = formatDashboardModel(
          responseMySql,
          responseDynamo || null,
          budgets
        );
        // console.log("asdlkjnhasdlikhnsadlkijlkdas", {
        //   idBudget,
        //   campaign: responseModelCampaign.idBudget,
        // });
        if (
          responseModelCampaign.idBudget !== 0 &&
          responseModelCampaign.idBudget === idBudget
        ) {
          return [responseModelCampaign];
        }
      }
    } catch (error) {
      console.log("Hubo un error", error);
      return [];
    } finally {
      dispatch(showLoader(false));
    }
  };

const findCampaignByMixDetail = async (idclient, idMixDetail) => {
  try {
    const response = await APILA.findCampaignByMixDetail({
      idclient: idclient,
      idMixDetaill: idMixDetail,
    });
    return response.data.campaigns.length > 0
      ? response.data.campaigns[0]
      : null;
  } catch (error) {
    return null;
  }
};

const getCampaignById = async (idMix) => {
  try {
    const response = await APILA.getCampaignById(idMix);
    return response.data.campaigns.length > 0
      ? response.data.campaigns[0]
      : null;
  } catch (error) {
    return;
  }
};

const getAvailableAmountFather = (
  idbud,
  budgets,
  campaigns,
  idcamp,
  amount,
  availableAmount
) => {
  let ava = 0;
  let prevCamp;
  if (campaigns) {
    prevCamp = campaigns?.find((i) => i.id === idcamp);
  }

  console.log("updateCampaign prevCamp ", prevCamp);
  console.log("updateCampaign amount ", amount);
  console.log("updateCampaign availableAmount ", availableAmount);

  if (idbud === prevCamp?.idBudget) {
    console.log("updateCampaign NO cambio de presupuesto ");
    if (prevCamp.budget == amount) {
      console.log("updateCampaign el presupuesto es el mismo");
    } else {
      ava = availableAmount + prevCamp.budget - amount;
      console.log("updateCampaign el presupuesto vario ", ava);
      if (ava == 0) {
        API.updateBudget({ id: idbud, available: "0" });
      } else {
        API.updateBudget({ id: idbud, available: ava });
      }
    }
  } else {
    console.log("updateCampaign cambio de presupuesto ");
    console.log("budgets", budgets);
    const prevBudget = budgets.find((i) => i.id === prevCamp?.idBudget);
    let pad = 0;
    let avass = 0;
    if (prevBudget) {
      pad = prevBudget.padre;
      avass = prevBudget.available;
    }
    const prevPadre = budgets.find((i) => i.id === pad);

    let newava = avass + prevCamp?.budget;
    if (prevBudget == 0) {
      API.updateBudget({ id: prevBudget.id, available: "0" });
    } else if (prevBudget != 0) {
      API.updateBudget({ id: prevBudget.id, available: newava });
    }

    ava = availableAmount - amount;
    console.log("updateCampaign el presupuesto vario ", ava);
    if (ava === 0) {
      API.updateBudget({ id: idbud, available: "0" });
    } else {
      API.updateBudget({ id: idbud, available: ava });
    }

    console.log("updateCampaign prevBudget ", prevBudget);
    console.log("updateCampaign prevPadre ", prevPadre);
  }

  let actualAva = 0;

  // let found = budgets.find(function(x, index) {
  //   if(x.id == idfather)
  //     return true;
  // });

  // console.log("updateCampaign prevBud ",prevBud)

  // budgets.map((bud) => {
  //   if(bud.id===idfather){
  //     actualAva=actualAva+bud.available;
  //   }
  // })
  // let availableAmount=state.amount-actualAva;
  // let availableAmount=actualAva;

  console.log("handleUpsert actualAva", actualAva);
  // console.log("handleUpsert availableAmount",availableAmount)
  return availableAmount;
};

export const updateCurrentAccount = (user, account) => async (dispatch) => {
  // dispatch(showLoader(true));

  const body = {
    user,
    account,
  };

  try {
    const response = await APILA.updateCurrentAccount(body);
  } catch (err) {
    Logger.log(TAG, "error updateCurrentAccount", err);
  }

  // dispatch(showLoader(false));
};

const getStatusLabel = (mStatus) => {
  switch (mStatus) {
    case status.CREADO.id:
      return status.CREADO.label;

    case status.INACTIVO.id:
      return status.INACTIVO.label;

    case status.ELIMINADO.id:
      return status.ELIMINADO.label;

    case status.MIX_APROBADO.id:
      return status.MIX_APROBADO.label;

    case status.EN_REVISION.id:
      return status.EN_REVISION.label;

    case status.APROBADO.id:
      return status.APROBADO.label;

    case status.MODIFICADO.id:
      return status.MODIFICADO.label;

    case status.EN_IMPLEMENTACION.id:
      return status.EN_IMPLEMENTACION.label;
    case status.ACTIVO.id:
      return status.ACTIVO.label;

    case status.PENDIENTE.id:
      return status.PENDIENTE.label;

    case status.FINALIZADO.id:
      return status.FINALIZADO.label;

    case status.FINALIZADO_SIN_META.id:
      return status.FINALIZADO_SIN_META.label;

    case status.PAUSADO.id:
      return status.PAUSADO.label;

    case status.RECHAZADO.id:
      return status.RECHAZADO.label;

    case status.NO_IMPLEMENTADO.id:
      return status.NO_IMPLEMENTADO.label;

    case status.FINALIZADO_SIN_REPORTE.id:
      return status.FINALIZADO_SIN_REPORTE.label;
    default:
      return "Totales";
  }
};
function numberWithCommas(x) {
  let auxNumber = new Number(x).toFixed(2);
  return auxNumber.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}
export const updateCampaignTable =
  (
    nameToSend,
    emailToSend,
    id,
    name,
    medio,
    fechaIni,
    fechafin,
    objetivo,
    budget,
    publico,
    idMixDetaill,
    idMixChange,
    idMix,
    idCampaign,
    idMedium,
    medioo,
    id_ad_product,
    banner,
    estado,
    idBudget,
    account,
    creativity,
    observation,
    copy,
    id_sale,
    id_region,
    name_region,
    nameForEmail,
    emailForEmail,
    availableAmount,
    newBudget,
    budgets,
    idAudience,
    optionSelected,
    chosenTag,
    finalEtiqueta,
    formato,
    campaign,
    responseTemp
  ) =>
  async (dispatch) => {
    try {
      let body = "";
      let title = "";
      let prevStatus;
      let prevIdBudget;
      let prevBudget;
      let bandera = 0;
      let searchable_status;
      if (estado) {
        switch (estado) {
          case 1:
            searchable_status = "creado";
            break;
          case 2:
            searchable_status = "cancelado";
            break;
          case 5:
            searchable_status = "aprobado";
            break;
          case 6:
            searchable_status = "en revision";
            break;
          case 9:
            searchable_status = "en implementacion";
            break;
          case 10:
            searchable_status = "activo";
            break;
          case 11:
            searchable_status = "en revsion";
            break;
          case 12:
            searchable_status = "finalizado";
            break;
          case 14:
            searchable_status = "pausado";
            break;
          case 15:
            searchable_status = "en revision";
            break;
          case 16:
            searchable_status = "cancelado";
            break;
          case 17:
            searchable_status = "finalizado";
            break;
        }
      }
      let state = {
        id,
        name,
        medio,
        status: estado === 1 ? 1 : estado === 13 ? 12 : estado === 17 ? 17 : 14,
        fechaIni,
        fechafin,
        objetivo,
        budget,
        publico,
        idMixDetaill,
        idMixChange,
        idMix,
        idCampaign,
        id_medium: idMedium,
        medioo,
        id_ad_product,
        banner,
        idBudget,
        account: account.id,
        creativity,
        observation,
        copy: copy ? copy : "",
        id_sale,
        id_region,
        name_region,
        nameForEmail,
        emailForEmail,
        url: [],
        availableAmount,
        version: "",
        npost: "",
        idAudience,
        countEdit: 0,
        categoriesSelected: optionSelected ? optionSelected : [],
        searchable_name: name.toLowerCase(),
        searchable_idMixDetaill: idMixDetaill.toString(),
        searchable_version: "",
        searchable_medio: medio.toLowerCase(),
        searchable_status,
        formato,
      };
      console.log("stateEnDashboardActions", state);
      let campaignObt = campaign;
      let responseTemp2 = responseTemp;
      if (responseTemp2.data.campaigns) {
        if (responseTemp2.data.campaings[0].countEdit) {
          state.countEdit = responseTemp2.data.campaings[0].countEdit;
        }
      }
      if (campaignObt) {
        prevStatus = campaignObt.data.campaigns[0]?.status;
        prevIdBudget = campaignObt.data.campaigns[0]?.idBudget;
        prevBudget = campaignObt.data.campaigns[0]?.budget;
      }
      // state.notified = false;
      //data bitacora
      const data = {
        id,
        idMixDetaill,
        name,
        fechaIni,
        fechafin,
        budget,
        id_ad_product,
        id_publico: null,
        id_ad_status:
          estado === 1 ? 1 : estado === 13 ? 12 : estado === 17 ? 17 : 14,
        comments: observation,
        copy,
        id_sale,
        name_region,
        objetivo,
        banner,
        medio,
        publico: publico === "null" ? [] : publico,
        copy: copy ? copy : "",
        observation,
        nameForEmail,
        emailForEmail,
        urls: [],
        version: "",
        npost: "",
        changes: {},
        prev_nombre_categoria: "",
        account: account.id,
        idAudience,
        categoriesSelected: optionSelected ? optionSelected : [],
        searchable_name: name.toLowerCase(),
        searchable_idMixDetaill: idMixDetaill.toString(),
        searchable_version: "",
        searchable_medio: medio.toLowerCase(),
        searchable_status,
        creativity,
        nameToSend,
        countEdit: state.countEdit,
        emailToSend,
        emailToTag: finalEtiqueta,
        prevTag: finalEtiqueta,
        formato: formato,
        changedInTag: false,
      };

      var fi = data.fechaIni.split(".");
      var nfi = fi[2] + "-" + fi[1] + "-" + fi[0];
      var ff = data.fechafin.split(".");
      var nff = ff[2] + "-" + ff[1] + "-" + ff[0];

      let version = " ",
        campaignName = " ";
      if (data.categoriesSelected) {
        data.categoriesSelected?.forEach((el) => {
          if (el.nombrePadre === "Campaña") {
            campaignName = el.label;
          }
          if (el.nombrePadre === "Mix") {
            version = el.label;
          }
        });
      }

      //dataEditLet
      const dataToEditLet = {
        accountInfo: {
          name: account.name,
          email: account.email,
          phone: account.phone,
          address: account.address,
        },
        id: data.idMixDetaill,
        name: campaignName,
        fecha_inicio: nfi,
        fecha_fin: nff,
        budget: data.budget,
        id_ad_product: data.id_ad_product,
        id_publico: data.id_publico,
        id_ad_status: data.id_ad_status,
        comments: data.comments,
        id_sale: data.id_sale,
        name_region: data.name_region,
        objetivo: data.objetivo,
        banner: data.banner,
        medio: data.medio,
        publico: data.publico.join(","),
        copy: data.copy,
        observation: data.observation,
        nameForEmail: data.nameForEmail,
        emailForEmail: data.emailForEmail,
        id_sale: data.id_sale,
        urls: "",
        version: version,
        n_post: data.name,
        changes: data.changes,
        prevCampaign: data.prevCampaign != undefined ? data.prevCampaign : null,
        newCampaing: data.newCampaing,
        creativity: data.creativity,
        categoriesSelected: data.categoriesSelected,
        nameToSend: data.nameToSend,
        emailToSend: data.emailToSend,
        emailToTag: data.emailToTag,
        prevTag: data.prevTag,
        countEdit: data.countEdit,
        formato: data.formato,
        changedInTag: data.changedInTag,
        priority: data.priority,
      };
      console.log("DATA DE ADVERTSING", dataToEditLet);
      let bodie = {
        id: v4(),
        idMixDetaill: id,
        idBudget: idBudget,
        budget: budget,
        available: availableAmount,
        date: new Date(),
        account: account.id,
        nameForEmail,
        emailForEmail,
        data: data,
        prevStatus,
        action:
          estado === 5
            ? "se ha aprobado una campaña"
            : "se ha editado una campaña",
        component: "campaign",
      };
      console.log("bodie", bodie);
      APILA.insertBitacora(bodie);
      if (prevIdBudget === idBudget) {
        console.log("EL PRESUPUESTO ES EL MISMO");
        if (prevBudget === budget) {
          console.log("BUDGET ES EL MISMO");
          bandera = 1;
        } else {
          console.log("BUDGET ES DIFERENTE");
          bandera = 0;
        }
      } else {
        console.log("EL PRESUPUESTO ES DIFERENTE");
        bandera = 1;
        let foundPrevBudget = budgets.filter((el) => el.id === prevIdBudget);
        if (foundPrevBudget.length > 0) {
          let finalPrevBudget =
            new Number(foundPrevBudget[0].available) + new Number(prevBudget);
          let finalNewBudget = new Number(availableAmount) - new Number(budget);
          API.updateBudget({ id: prevIdBudget, available: finalPrevBudget });
          API.updateBudget({ id: idBudget, available: finalNewBudget });
        }
      }

      if (bandera === 0) {
        if (newBudget == 0) {
          API.updateBudget({ id: idBudget, available: "0" });
        } else {
          console.log("El nuevo budget", newBudget);
          API.updateBudget({ id: idBudget, available: newBudget });
        }
      }
      let arrayObject = [
        APILA.updateCampaign(state),
        APILA.editpautalet(dataToEditLet),
      ];
      // // await  APILA.updateCampaign(state)
      // // await APILA.editpautalet(dataToEditLet)
      Promise.all(arrayObject)
        .then(() => {
          console.log("todo bien");
          title = "Campaña editada";
          body = "Su campaña fue editada exitosamente.";
          dispatch(
            Modal.showAlertModal(
              title,
              body,
              Modal.MODAL_TYPE_SUCCESS,
              () => {}
            )
          );
        })
        .catch((err) => {
          title = "Error!";
          body = "Ocurrió un error al actualizar la campaña";
          dispatch(
            Modal.showAlertModal(title, body, Modal.MODAL_TYPE_ERROR, () => {})
          );
          console.log("Error en dashboard.actions ", err);
        });
    } catch (error) {
      console.log("El error dashboardactions", error);
    }
  };

//Actualiza ids de lovs
const updateIdsCampaignLovs = (id, idBudget, prevIdBudget, budgets) => {
  if (idBudget === prevIdBudget) return; //Mismo valor
  const foundBudget = budgets.find((ele) => ele.id === idBudget);
  const prevBudgetLov = budgets.find((ele) => ele.id === prevIdBudget);
  if (foundBudget && foundBudget.reserveForLovs) {
    foundBudget.reserveForLovs.map((ele) => {
      let idsCampaign = ele?.idsCampaign || [];
      idsCampaign.push(id);
      API.updateIdsCampaign({
        id: ele.id,
        idsCampaign: idsCampaign,
      });
    });
  }
  if (prevBudgetLov && prevBudgetLov.reserveForLovs) {
    prevBudgetLov.reserveForLovs.map((ele) => {
      let idsCampaign = ele?.idsCampaign || [];
      idsCampaign.filter((idss) => idss !== id);
      API.updateIdsCampaign({
        id: ele.id,
        idsCampaign: idsCampaign,
      });
    });
  }
};
//Status del searchableStatus
const getSearchableStatus = (estado) => {
  const options = {
    1: "creado",
    2: "cancelado",
    5: "aprobado",
    6: "en revision",
    9: "en implementacion",
    10: "activo",
    11: "en revision",
    12: "finalizado",
    14: "pausado",
    15: "en revision",
    16: "cancelado",
    17: "finalizado",
  };
  return options[estado] || "";
};
//Valida si hubieron cambios en algun atributo del objeto
const validateChangeStatus = (campaign, prevCampaign) => {
  const changeCreativity = _.isEqual(
    campaign.creativity,
    prevCampaign.creativity
  );

  return (
    campaign.fechaIni !== prevCampaign.fechaIni ||
    campaign.fechafin !== prevCampaign.fechafin ||
    campaign.name_region !== prevCampaign.name_region ||
    campaign.medio !== prevCampaign.medio ||
    campaign.banner !== prevCampaign.banner ||
    campaign?.formato?.art !== prevCampaign?.formato?.art ||
    // campaign.priority !== prevCampaign.priority ||
    campaign.idBudget !== prevCampaign.idBudget ||
    campaign.budget != prevCampaign.budget ||
    campaign.observation !== prevCampaign.observation ||
    campaign.copy !== prevCampaign.copy ||
    campaign.idAudience !== prevCampaign.idAudience ||
    !changeCreativity
  );
};
//Genera la propiedad changes de bitacotas, se usa para mandar correos o uso de peps
const getChangesBitacora = (
  prevCampaign,
  campaign,
  finalEtiqueta,
  tagToEmail
) => {
  let changes = {
    nameChanged: prevCampaign.name != campaign.name,
    medioChanged: prevCampaign.medio != campaign.medio,
    formatChange: prevCampaign?.formato != campaign.formato,
    // priorityChanged: prevCampaign?.priority != campaign.priority,
    id_saleChanged: prevCampaign.id_sale != campaign.id_sale,
    fechaIniChanged: prevCampaign.fechaIni != campaign.fechaIni,
    fechafinChanged: prevCampaign.fechafin != campaign.fechafin,
    idBudgetChanged: prevCampaign.idBudget != campaign.idBudget,
    bannerChanged: prevCampaign.banner != campaign.banner,
    objetivoChanged: prevCampaign.objetivo != campaign.objetivo,
    observationChanged: prevCampaign.observation != campaign.observation,
    copyChanged: prevCampaign.copy != campaign.copy,
    countryChanged: prevCampaign.name_region != campaign.name_region,
    publicoChanged:
      JSON.stringify(prevCampaign.publico) != JSON.stringify(campaign.publico),
    creativitieChanged: false,
    optionSelectedChanged: false,
    tagChanged: false,
    budgetChanged: prevCampaign.budget != campaign.budget,
  };

  if (tagToEmail !== finalEtiqueta) changes.tagChanged = true; //Las etiquetas son diferentes

  if (
    prevCampaign?.categoriesSelected?.length !==
    campaign.categoriesSelected.length
  ) {
    changes.optionSelectedChanged = true;
  } else if (
    prevCampaign?.categoriesSelected?.length ===
    campaign.categoriesSelected.length
  ) {
    for (let i = 0; i < prevCampaign.categoriesSelected.length; i++) {
      if (
        prevCampaign.categoriesSelected[i].label !=
        campaign.categoriesSelected[i].label
      ) {
        changes.optionSelectedChanged = true;
        break;
      }
    }
  }

  if (prevCampaign.creativity.length != campaign.creativity.length) {
    changes.creativitieChanged = true;
  } else if (prevCampaign.creativity.length == campaign.creativity.length) {
    for (let i = 0; i < prevCampaign.creativity.length; i++) {
      if (prevCampaign.creativity[i].name != campaign.creativity[i].name) {
        changes.creativitieChanged = true;
        break;
      }
    }
  }
  return changes;
};
//Valida la variable changes
const validateChanges = (changes) => {
  return !_.some(changes, (e) => e);
};
//Flujo de peps para la aprobacion de pautas
const upsertQueueApprove = async (id, count, approval, editor) => {
  console.log("Si entra en approve queue");
  const response = await APILA.getQueueById({
    idMixDetaill: id,
    idCategory: 1,
  });
  if (response.data.queue.length > 0) {
    const currentQueue = response.data.queue[response.data.queue.length - 1];
    if (currentQueue.id_user === null) {
      await APILA.deleteQueue({ id: currentQueue.id });

      await APILA.insertQueue({
        idMixDetaill: id,
        requestType: 2,
        version: count,
        editor,
        approval,
      });
    } else if (currentQueue.id_user != null && currentQueue.date_end === null) {
      await APILA.editQueue({
        id: currentQueue.id,
        date_end: new Date().toISOString(),
        version: count,
      });
      await APILA.insertQueue({
        idMixDetaill: id,
        requestType: 2,
        version: count,
        editor,
        approval,
      });
    } else {
      await APILA.insertQueue({
        idMixDetaill: id,
        requestType: 2,
        version: count,
        editor,
        approval,
      });
    }
  } else {
    await APILA.insertQueue({
      idMixDetaill: id,
      requestType: 1,
      version: count,
      editor,
      approval,
    });
  }
};
//Flujo de peps para la edicion de pautas
const upsertQueueEdit = async (id, count) => {
  const response = await APILA.getQueueById({
    idMixDetaill: id,
    idCategory: 1,
  });
  if (response.data.queue.length > 0) {
    let currentQueue = response.data.queue[response.data.queue.length - 1];
    if (currentQueue.id_user != null && currentQueue.date_end === null) {
      await APILA.editQueue({
        id: currentQueue.id,
        date_end: new Date().toISOString(),
        version: count,
      });
      await APILA.insertQueue({
        idMixDetaill: id,
        requestType: 2,
        version: count,
      });
    } else if (currentQueue.id_user === null && currentQueue.date_end != null) {
      await APILA.deleteQueue({ id: currentQueue.id });
      await APILA.insertQueue({
        idMixDetaill: id,
        requestType: 2,
        version: count,
      });
    } else if (currentQueue.id_user != null && currentQueue.date_end != null) {
      await APILA.insertQueue({
        idMixDetaill: id,
        requestType: 2,
        version: count,
      });
    } else {
      await APILA.insertQueue({
        idMixDetaill: id,
        requestType: 2,
        version: count,
      });
    }
  } else {
    await APILA.insertQueue({
      idMixDetaill: id,
      requestType: 2,
      version: count,
    });
  }
};
//Manejo de disponibles en edicion de pautas
const upsertBudets = async (
  account,
  prevIdBudget,
  idBudget,
  estado,
  prevBudget,
  budget
) => {
  const getBudgets = await API.getBudgets(account);
  const datosBudgets = getBudgets.data.budgets;

  if (prevIdBudget === idBudget && estado !== 5) {
    //El presupuesto es el mismo
    if (Number(prevBudget) !== Number(budget)) {
      //el presupuesto cambio
      const updateBudget = datosBudgets.find((ele) => ele.id === idBudget);
      const availableAmountTotal =
        Number(updateBudget.available) + Number(prevBudget);
      const newAvailable = availableAmountTotal - budget;
      updateBudgetAvailable(idBudget, newAvailable);
    }
  } else if (estado !== 5) {
    //Se valida que no sea una campa;a que se esta aprobando con el estaus 5
    //Le cambiaron el presupuesto con el que lo asociaron
    const updateBudget = datosBudgets.find((ele) => ele.id === idBudget);
    const prevBudgetUpdate = datosBudgets.find(
      (ele) => ele.id === prevIdBudget
    );
    const newAvailable = Number(updateBudget.available) - Number(budget);
    const backAvailable =
      Number(prevBudgetUpdate.available) + Number(prevBudget);
    updateBudgetAvailable(prevIdBudget, backAvailable);
    updateBudgetAvailable(idBudget, newAvailable);
  }
};

//Ingreso de bitacora
const insertBitacoraCampaign = async (body, data, estado, prevStatus) => {
  let bodie = {
    id: v4(),
    idMixDetaill: body.id,
    idBudget: body.idBudget,
    budget: body.budget,
    available: body.availableAmount,
    date: new Date(),
    account: body.account,
    nameForEmail: body.nameForEmail,
    emailForEmail: body.emailForEmail,
    data: data,
    prevStatus,
    action:
      estado === 5 ? "se ha aprobado una campaña" : "se ha editado una campaña",
    component: "campaign",
  };
  await APILA.insertBitacora(bodie);
};

const getMaxHours = (priority, accounts, currentAccount) => {
  const findAccount = accounts.find((ele) => ele.id === currentAccount);
  const valuePriority = arrayPriority.find((item) => item.id === priority);
  const priorityName = valuePriority.nameAccount;
  const maxHours = findAccount.configPriority[priorityName].maxHours;
  return maxHours;
};

export const validateCampg =
  (oldVersion, updatedVadersion, accounts, approve) => async (dispatch) => {
    try {
      dispatch(showLoader(true));
      Swal.close();
      const maxHours = getMaxHours(
        updatedVadersion.priority,
        accounts,
        updatedVadersion.account
      );
      // const { config, available } = await getConfigByAccount(
      //   updatedVadersion.priority,
      //   convertirFecha(oldVersion.fechaIni),
      //   updatedVadersion.account,
      //   accounts
      // );

      const medio = {
        id_medium: oldVersion.id_medium || 0,
        site: oldVersion.medioo ? oldVersion.medioo : oldVersion.medio || 0,
      };
      const medioNew = {
        id_medium: updatedVadersion.id_medium || 0,
        site: updatedVadersion.medioo
          ? updatedVadersion.medioo
          : updatedVadersion.medio || 0,
      };
      const banner = {
        id_ad_product: oldVersion.id_ad_product,
        banner: oldVersion.banner,
      };
      const bannerNew = {
        id_ad_product: updatedVadersion.id_ad_product,
        banner: updatedVadersion.product.banner,
      };

      const oldVersionFormat = {
        ...oldVersion,
        name_region: oldVersion.name_region,
        medio: medio.site,
        banner: banner.banner,
        formato: { art: oldVersion.formato },
      };
      const newVersion = {
        ...updatedVadersion,
        name_region: updatedVadersion.country.name,
        medio: medioNew.site.site,
        banner: bannerNew.banner,
      };
      const changeStatus = validateChangeStatus(newVersion, oldVersionFormat);
      if (approve) {
        Toast.fire({
          showConfirmButton: false,
          imageUrl: iconImage,
          customClass: {
            image: "custom-image-class", // Clase CSS personalizada para la imagen
          },
          text: `Tu campaña será implementada en un máximo de ${maxHours} horas.`,
        });
      } else if (changeStatus) {
        // cambia de estatus y envia a peps
        Toast.fire({
          showConfirmButton: false,
          imageUrl: iconImage,
          customClass: {
            image: "custom-image-class", // Clase CSS personalizada para la imagen
          },
          text: `Tu campaña será implementada en un máximo de ${maxHours} horas.`,
        });
      }
    } catch (error) {
      console.log("Hubo un error", error);
    } finally {
      dispatch(showLoader(false));
    }
  };

export const validateHoursAvailable =
  (currentAccount, accounts, startDate, priority, edit) => async (dispatch) => {
    try {
      dispatch(showLoader(true));
      Swal.close();
      const isTodaySelected = evaluarFecha(startDate);

      const { config, available } = await getConfigByAccount(
        priority,
        convertirFecha(startDate),
        currentAccount,
        accounts
      );

      if (!available && priority !== 1) {
        // console.log(
        //   "No hay horarios disponibles para hoy. Para proceder crea la pauta con la prioridad de crítico."
        // );
      }

      if (config.freeDay && priority !== 1 && isTodaySelected) {
        Toast.fire({
          imageUrl: iconImage,
          customClass: {
            image: "custom-image-class", // Clase CSS personalizada para la imagen
          },
          text: messageAlert,
          showConfirmButton: false,
          // text: `El día seleccionado es una fecha no laboral. Por favor, selecciona otro día o establece la prioridad en crítico.`,
        });

        // if (!edit) {
        return true;
        // }
      }

      if (!available && priority === 1) {
        const toastPromise = Toast.fire({
          showConfirmButton: false,
          imageUrl: iconImage,
          customClass: {
            image: "custom-image-class", // Clase CSS personalizada para la imagen
          },
          text: `Tu campaña será implementada en un máximo de ${config.maxHours} horas.`,
        });
        // if (!edit) {
        // await toastPromise;
        return false;
        // }
      }

      if (available && priority === 1) {
        const toastPromise = Toast.fire({
          showConfirmButton: false,
          imageUrl: iconImage,
          customClass: {
            image: "custom-image-class", // Clase CSS personalizada para la imagen
          },
          text: `Tu campaña será implementada en un máximo de ${config.maxHours} horas.`,
        });
        // if (!edit) {
        // await toastPromise;
        return false;
        // }
      }
      if (isTodaySelected) {
        const esValida = evaluarHora(config.hours, config.minutes, config);
        if (!esValida) {
          const dataResponse = await handlePriorityEscalation(
            startDate,
            priority,
            currentAccount,
            accounts
          );
          return dataResponse;
        } else {
          const toastPromise = Toast.fire({
            showConfirmButton: false,
            imageUrl: iconImage,
            customClass: {
              image: "custom-image-class", // Clase CSS personalizada para la imagen
            },
            text: `Tu campaña será implementada en un máximo de ${config.maxHours} horas.`,
          });
        }
      } else {
        const { diffDays, totalHours } = await getWorkingHours(
          new Date(),
          convertirFecha(startDate),
          priority,
          currentAccount,
          accounts
        );
        console.log("first", { diffDays, totalHours });
        if (diffDays < 3) {
          if (totalHours >= config.sumarHoras) {
            const toastPromise = Toast.fire({
              showConfirmButton: false,
              imageUrl: iconImage,
              customClass: {
                image: "custom-image-class", // Clase CSS personalizada para la imagen
              },
              text: `Tu campaña será implementada en un máximo de ${config.maxHours} horas.`,
            });
          } else {
            const toastPromise = Toast.fire({
              imageUrl: iconImage,
              customClass: {
                image: "custom-image-class", // Clase CSS personalizada para la imagen
              },
              showConfirmButton: false,
              text: messageAlert,
              // text: `No hay suficientes horas para crear la pauta. Para proceder crea la pauta con la prioridad de crítico.`,
            });
            if (!edit) {
              // await toastPromise;
              return true;
            }
          }
        } else {
          const toastPromise = Toast.fire({
            showConfirmButton: false,
            imageUrl: iconImage,
            customClass: {
              image: "custom-image-class", // Clase CSS personalizada para la imagen
            },
            text: `Tu campaña será implementada en un máximo de ${config.maxHours} horas.`,
          });
        }
      }
    } catch (error) {
      console.log("Hubo un error", error);
    } finally {
      dispatch(showLoader(false));
    }
  };

//Alertas de tipo toast
const Toast = Swal.mixin({
  toast: true,
  position: "top",
  // iconColor: "white",
  customClass: {
    popup: "colored-toast",
  },
  text: "Tu campaña sera implementada en un maximo de una hora",
});

//Valida las horas habiles entre dos fechas

const getWorkingHours = async (
  startDate,
  endDate,
  priority,
  currentAccount,
  accounts
) => {
  let totalHours = 0;
  let diffDays = 0;
  // Iterar sobre cada día entre startDate y endDate
  endDate.setHours(23, 59, 59, 999);

  for (
    let currentDate = new Date(startDate);
    currentDate <= endDate;
    currentDate.setDate(currentDate.getDate() + 1)
  ) {
    // Obtener la configuración y la disponibilidad para la cuenta en la fecha actual
    const { config, available } = await getConfigByAccount(
      priority,
      currentDate,
      currentAccount,
      accounts
    );
    // Verificar si el día actual es laborable y si hay disponibilidad
    console.log("hoursLeft for", config, available, currentDate);

    if (!config.freeDay && available) {
      // Si es un día laborable y hay disponibilidad, sumar las horas disponibles
      totalHours += config.hoursLeft;
      diffDays++;
    }
  }

  return { totalHours, diffDays };
};

//Proceso de escalamiento de prioridades
async function handlePriorityEscalation(
  startDate,
  priority,
  currentAccount,
  accounts
) {
  const escalatePriority = priority === 1 ? 1 : priority - 1;
  const { config: nuevaConfig, available: nuevaAvailable } =
    await getConfigByAccount(
      escalatePriority,
      convertirFecha(startDate),
      currentAccount,
      accounts
    );
  console.log("response 2", { nuevaConfig, nuevaAvailable });
  const newValidate = evaluarHora(
    nuevaConfig.hours,
    nuevaConfig.minutes,
    nuevaConfig
  );

  let icon = "info";
  let title = "Info";
  let text = "";

  if (escalatePriority === 3 && nuevaAvailable) {
    text =
      "Te recomendamos cambiar la prioridad de la pauta a urgente para proceder con la creación de la pauta.";
    icon = "alert";
  } else if (escalatePriority === 2 && nuevaAvailable) {
    if (newValidate) {
      icon = "alert";
      text =
        "Te recomendamos cambiar la prioridad de la pauta a urgente para proceder con la creación de la pauta.";
    } else {
      icon = "alert";
      text =
        "1 Te recomendamos cambiar la prioridad de la pauta a critico para proceder con la creación de la pauta.";
    }
  } else if (escalatePriority === 2 && !nuevaAvailable) {
    icon = "alert";
    text =
      "2 Te recomendamos cambiar la prioridad de la pauta a critico para proceder con la creación de la pauta.";
  } else if (escalatePriority === 1) {
    text =
      "3 Te recomendamos cambiar la prioridad de la pauta a critico para proceder con la creación de la pauta.";
  }

  // Muestra la alerta
  // Toast.fire({
  //   icon: icon,
  //   title: title,
  //   text: text,
  // });
  Toast.fire({
    imageUrl: iconImage,
    customClass: {
      image: "custom-image-class", // Clase CSS personalizada para la imagen
    },
    showConfirmButton: false,
    text: messageAlert,
    // text: `No hay suficientes horas para crear la pauta. Para proceder crea la pauta con la prioridad de crítico.`,
  });
  return true;
}

//Valida horarios habiles en cuestion de si es posible crear una nueva pauta (Cuando se selecciona una fecha de inicio igual que el dia actual)
function evaluarHora(currentHours, currentMinutes, config) {
  const horaFinalCondicional = currentHours + config.sumarHoras;
  const isValidHour =
    currentHours >= config.horaInicio && horaFinalCondicional <= config.horaFin;
  const isValidMinutes =
    horaFinalCondicional === config.horaFin
      ? currentMinutes <= config.minuteFinish
      : currentHours === config.horaInicio
      ? currentMinutes >= config.minuteInitial
      : true;

  return isValidHour && isValidMinutes;
}

//Evalua si la fecha seleccionada es la misma que la actual
function evaluarFecha(fechaSeleccionada) {
  const dateSelected = convertirFecha(fechaSeleccionada);
  const dateCurrent = new Date();
  const isSameDate = dateSelected.toDateString() === dateCurrent.toDateString();
  return isSameDate;
}

//Funcion que convierte la fecha seleccionada a un formato de fecha aceptado por la API
function convertirFecha(fechaString) {
  const partes = fechaString.split(".");
  return new Date(partes[2], partes[1] - 1, partes[0]);
}

const getConfigSchedule = async () => {
  const currentAccountData = _.get(
    await APILA.findScheduleById({
      id: "a75a9dab-c8d2-45cc-aefc-0ee0679cc089",
    }),
    "data.response",
    []
  );
  return currentAccountData;
};

export const getConfigScheduleGlobal = () => async (dispatch) => {
  try {
    // dispatch(showLoader(true));
    const currentAccountData = await getConfigSchedule();
    return currentAccountData;
  } catch (error) {
    console.log("Hubo un error", error);
  } finally {
    // dispatch(showLoader(false));
  }
};

//dame una funcion la cual me retorne el nombre del dia actual con todo minusculas, español y con acentos
function getDayName(dateSelected) {
  const day = dateSelected.getDay();

  const days = [
    "domingo",
    "lunes",
    "martes",
    "miércoles",
    "jueves",
    "viernes",
    "sábado",
  ];
  return days[day];
}

function convertTo24h(timeString) {
  const [hours, minutes] = timeString.split(":");

  const hour = parseInt(hours);
  const minute = parseInt(minutes);

  const meridian = timeString.slice(-2).toUpperCase();

  let hour24h = hour;
  if (meridian === "PM" && hour !== 12) {
    hour24h += 12;
  }

  return `${hour24h.toString().padStart(2, "0")}:${minute
    .toString()
    .padStart(2, "0")}`;
}
const isToday = (date) => {
  const today = new Date();
  return (
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
  );
};

async function getConfigByAccount(
  priority,
  dateSelected,
  currentAccount,
  accounts
) {
  const findAccount = accounts.find((ele) => ele.id === currentAccount);
  const format = await getConfigSchedule();
  const dayName = getDayName(dateSelected);
  const freeDays = format.freeDays;
  const workDay = format.workDays.find((item) => item.day === dayName);
  const timeInitial = convertTo24h(workDay.timeInitial);
  const timeFinish = convertTo24h(workDay.timeFinish);
  const isTodaySelected = isToday(dateSelected);

  const valuePriority = arrayPriority.find((item) => item.id === priority);
  const priorityName = valuePriority.nameAccount;
  const date = dateSelected;
  const today = new Date();
  const hours = isTodaySelected ? today.getHours() : 0;
  const minutes = isTodaySelected ? today.getMinutes() : 0;
  const formattedToday = `${("0" + date.getDate()).slice(-2)}/${(
    "0" +
    (date.getMonth() + 1)
  ).slice(-2)}/${date.getFullYear()}`;
  console.log(
    "format",
    findAccount.configPriority[priorityName],
    priorityName,
    valuePriority.nameAccount,
    findAccount
  );
  let hoursLeft = 0;
  if (workDay.checked && !freeDays.includes(formattedToday)) {
    if (isTodaySelected) {
      const endTime = new Date();
      endTime.setHours(
        Number(timeFinish.split(":")[0]),
        Number(timeFinish.split(":")[1]),
        0
      );
      const differenceInMs = endTime - date;
      hoursLeft = Math.max(Math.floor(differenceInMs / (1000 * 60 * 60)), 0);
    } else {
      hoursLeft =
        Number(timeFinish.split(":")[0]) - Number(timeInitial.split(":")[0]);
      console.log(
        "hoursLeft calculate",
        Number(timeFinish.split(":")[0]),
        Number(timeInitial.split(":")[0]),
        hoursLeft
      );
    }
  }

  const config = {
    horaInicio: Number(timeInitial.split(":")[0]),
    horaFin: Number(timeFinish.split(":")[0]),
    finFormat: workDay.timeFinish,
    minuteInitial: Number(timeInitial.split(":")[1]),
    minuteFinish: Number(timeFinish.split(":")[1]),
    sumarHoras:
      findAccount.configPriority[priorityName].minHours +
      (findAccount.configPriority[priorityName].maxHours -
        findAccount.configPriority[priorityName].minHours),
    hours,
    minutes,
    maxHours: findAccount.configPriority[priorityName].maxHours,
    minHours: findAccount.configPriority[priorityName].minHours,
    freeDay: freeDays.includes(formattedToday),
    hoursLeft,
  };
  return { config, available: workDay.checked };
}

// TODO: Edicion de fechas y prioridades
export const editCampaign = (id, since, last, priority) => async (dispatch) => {
  try {
    dispatch(showLoader(true));
    var fi = since.split(".");
    var nfi = fi[2] + "-" + fi[1] + "-" + fi[0];
    var ff = last.split(".");
    var nff = ff[2] + "-" + ff[1] + "-" + ff[0];

    await APILA.updatePriorityAndDate({
      id: id,
      since: nfi,
      last: nff,
      priority: priority,
    });
    await APILA.updatePriorityAndDateDynamo({
      id: id,
      since: since,
      last: last,
      priority: priority,
    });
  } catch (error) {
  } finally {
    dispatch(showLoader(false));
  }
};

//TODO: APROBACION DE CAMPAÑAS

export const approveCampaign =
  (
    id,
    idMixDetaill,
    name,
    fechaIni,
    fechafin,
    budget,
    id_ad_product,
    status,
    observation,
    copy,
    id_sale,
    name_region,
    objetivo,
    banner,
    medio,
    publico,
    nameForEmail,
    emailForEmail,
    urls,
    version,
    npost,
    prevCampaign,
    priority,
    currentAccount,
    idAudience,
    categoriesSelected,
    creativity,
    nameToSend,
    emailToSend,
    emailToTag,
    formato,
    chosenTagg,
    mAccount,
    searchInput,
    id_digital_asset
  ) =>
  async (dispatch) => {
    //Objeto de actualizacion
    const promises = [];
    let searchable_status = "aprobado";
    let newCampaing = false; // Se usa para validar si es una implementacion nueva
    let countEdit = 0;
    let finalEtiqueta = "";
    let changedInTag = false;
    let changes = {};

    const data = {
      id,
      idMixDetaill,
      name,
      fechaIni,
      fechafin,
      budget,
      id_ad_product,
      id_publico: null,
      id_ad_status: status, //conservationStatus ? backStatus : estado,
      comments: observation, //: observation, //observation+" -----"+copy,
      copy,
      id_sale,
      name_region,
      objetivo,
      banner,
      medio,
      publico,
      observation,
      nameForEmail,
      emailForEmail,
      urls,
      version,
      npost,
      prevCampaign,
      priority: priority,
      changes,
      prev_nombre_categoria: "",
      account: currentAccount,
      idAudience,
      categoriesSelected,
      searchable_name: name.toLowerCase(),
      searchable_idMixDetaill: idMixDetaill.toString(),
      searchable_version: version.toLowerCase(),
      searchable_medio: medio.toLowerCase(),
      searchable_status,
      newCampaing,
      creativity,
      nameToSend,
      countEdit: countEdit,
      emailToSend,
      emailToTag,
      prevTag: finalEtiqueta,
      formato: formato,
      changedInTag: changedInTag,
      approve: true,
      id_digital_asset,
    };

    const responseTemporalCampaign = await APILA.getTempCampaigns({
      account: currentAccount,
      id: id,
    }); //Campaign temporal
    // await APILA.updateCampaingStatus({ id: id, status: 5 });
    let campaignsTemp = responseTemporalCampaign.data.campaings;

    if (responseTemporalCampaign.data.campaings.length > 0) {
      countEdit = responseTemporalCampaign.data.campaings[0].countEdit ?? 0;
    }
    if (prevCampaign && prevCampaign.status === 1) {
      data.newCampaing = true;
      prevCampaign.idCamp = v4();
      prevCampaign.notified = false;
      // await APILA.upsertTempCampaigns(prevCampaign);
    } else {
      countEdit++;
      data.countEdit = countEdit;
    }

    if (!_.isEmpty(campaignsTemp)) {
      for (let i = 0; i < campaignsTemp.length; i++) {
        campaignsTemp[i].notified = true;
        await APILA.upsertTempCampaigns(campaignsTemp[i]);
      }
      data.prevCampaign = campaignsTemp[0];
      data.changes = generateChanges(
        campaignsTemp[0],
        emailToTag,
        "",
        data
      ).changes;
      changes = generateChanges(campaignsTemp[0], emailToTag, "", data).changes;
      console.log(
        "Data",
        generateChanges(campaignsTemp[0], emailToTag, "", data).changes
      );
    }
    chosenTagg.columnsSelected.forEach((element) => {
      if (element === "publico") {
        if (changes.publicoChanged === true) {
          changedInTag = true;
        } else if (element === "medio") {
          if (changes.medioChanged === true) {
            changedInTag = true;
          }
        } else if (element === "banner") {
          if (changes.bannerChanged === true) {
            changedInTag = true;
          }
        } else if (element === "fechaIni") {
          if (changes.fechaIniChanged === true) {
            changedInTag = true;
          }
        } else if (element === "fechafin") {
          if (changes.fechafinChanged === true) {
            changedInTag = true;
          }
        } else if (element === "country") {
          if (changes.countryChanged === true) {
            changedInTag = true;
          }
        } else if (element === "budget") {
          if (changes.budgetChanged === true) {
            changedInTag = true;
          }
        }
      }
    });

    promises.push(
      APILA.upsertTempCampaigns({
        ...data,
        status: 5,
        idCamp: v4(),
        countEdit: countEdit,
        notified: false,
      })
    );

    insertBitacora(
      prevCampaign.idBudget,
      budget,
      0,
      currentAccount,
      nameForEmail,
      emailForEmail,
      data,
      "se ha aprobado una campaña",
      priority,
      "campaign",
      id
    );

    promises.push(sendUpdateCamping(data, mAccount, 5, dispatch));
    promises.push(
      upsertQueueApprove(id, countEdit, emailToSend, emailForEmail)
    );
    const response = await Promise.all(promises);
    const alertMessage = response[2];
    console.log("validando la respuesta", { alertMessage });
    if (searchInput) {
      dispatch(addCampaigns({ approve: true, id }));
    }
    return alertMessage;
  };

export const updateCampaignModal =
  (
    nameToSend,
    emailToSend,
    id,
    name,
    medio,
    fechaIni,
    fechafin,
    objetivo,
    budget,
    publico,
    idMixDetaill,
    idMixChange,
    idMix,
    idCampaign,
    id_medium,
    id_ad_product,
    banner,
    estado,
    idBudget,
    account,
    creativity,
    observation,
    copy,
    id_sale,
    id_region,
    name_region,
    nameForEmail,
    emailForEmail,
    urls,
    version,
    npost,
    budgets,
    idAudience,
    optionSelected,
    tagToEmail,
    formato,
    campAc,
    priority,
    id_digital_asset,
    name_digital_asset
  ) =>
  async (dispatch) => {
    dispatch(showLoader(true));
    try {
      const promises = [];
      let searchable_status = "pausado";
      let countEdit = 0;
      let changes = {};

      const [responseData, responseTemporal] = await Promise.all([
        getData(APILA.getCampaignById(idMixDetaill), "data.campaigns[0]"),
        getData(
          APILA.getTempCampaigns({
            account: account.id,
            id: id,
          }),
          "data.campaings[0]"
        ),
      ]);

      console.log("Response data", responseData);
      let prevStatus;
      let prevIdBudget;
      let prevBudget = parseFloat(budget); //Se inicializa con el valor del presupuesto seleccionado
      const budgetPauta = parseFloat(budget); //Presupuesto de la campa;ana
      let finalEtiqueta = "";

      if (responseData) {
        prevStatus = responseData?.status;
        prevIdBudget = responseData?.idBudget;
        prevBudget = parseFloat(responseData?.budget) ?? 0;
      }

      console.log("Budgget", { prevBudget, budgetPauta });
      const errorBudget = await budgetTransactionLets(
        prevBudget,
        budgetPauta,
        idBudget,
        prevIdBudget,
        id,
        emailForEmail,
        account.id
      );

      if (errorBudget) {
        return dispatch(
          Modal.showAlertModal(
            "Error!",
            "Ocurrió un error al actualizar la campaña",
            Modal.MODAL_TYPE_ERROR
          )
        );
      }

      upsertIdsLovs(idBudget, prevIdBudget, budgets, id);

      if (responseTemporal) {
        countEdit = responseTemporal.countEdit ?? 0;
      }

      let body = {
        id, //esta
        name, //esta
        medio, //esta
        status: estado, // !
        fechaIni, //esta
        fechafin, //esta
        objetivo, //esta
        budget, //esta
        publico, //esta
        idMixDetaill, //esta
        idMixChange,
        idMix,
        idCampaign,
        id_medium,
        medioo: medio,
        id_ad_product,
        banner, //esta
        idBudget,
        account: account?.id,
        creativity, //esta
        observation, //esta
        copy, //esta
        id_sale, //esta
        id_region,
        name_region, //esta
        nameForEmail, //esta
        emailForEmail, //esta
        urls, //esta
        version, //esta
        npost, //esta
        idAudience, //esta
        countEdit: countEdit,
        categoriesSelected: optionSelected, //esta
        searchable_name: name.toLowerCase(), //esta
        searchable_idMixDetaill: idMixDetaill.toString(), //esta
        searchable_version: version?.toLowerCase(), //esta
        searchable_medio: medio.toLowerCase(), //esta
        searchable_status,
        formato,
        priority, //esta
        id_digital_asset,
        name_digital_asset,
      };

      if (validateChangeStatus(body, responseData)) {
        body.status = estado;
      } else {
        body.status = campAc?.status ?? prevStatus;
        console.log("Mantiene el que esta");
      }

      if (campAc.status === 1 && estado === 14) {
        body.status = 1;
        console.log("ACA", prevStatus);
      }
      promises.push(APILA.updateCampaign(body));
      body.idCamp = v4();
      body.notified = false;
      let dataEadvertising = {
        ...body,
        changes,
        id_ad_status: body.status,
        comments: observation,
        newCampaing: false,
        nameToSend,
        emailToSend,
        prevTag: finalEtiqueta,
        changedInTag: false,
        emailToTag: tagToEmail,
        approve: false,
        id_digital_asset,
      };

      insertBitacora(
        idBudget,
        budget,
        0,
        account.id,
        nameForEmail,
        emailForEmail,
        dataEadvertising,
        "se ha editado una campaña",
        priority,
        "campaign",
        id
      );
      promises.push(
        sendUpdateCamping(dataEadvertising, account, body.status, dispatch)
      );
      await Promise.all(promises);
      localStorage.setItem("nameAWS", "");
      localStorage.setItem("tempAWSUser", "");
      localStorage.setItem("account", "");
    } catch (error) {
    } finally {
      dispatch(showLoader(false));
    }
  };

const getData = async (query, selector) => {
  const response = _.get(await query, selector);
  return response;
};

const upsertIdsLovs = (idBudget, prevIdBudget, budgets, id) => {
  if (idBudget !== prevIdBudget) {
    const foundBudget = budgets.find((ele) => ele.id === idBudget);
    const prevBudgetLov = budgets.find((ele) => ele.id === prevIdBudget);

    if (foundBudget && foundBudget.reserveForLovs) {
      foundBudget.reserveForLovs.forEach((ele) => {
        let idsCampaign = [...new Set(ele?.idsCampaign || []), id];
        API.updateIdsCampaign({
          id: ele.id,
          idsCampaign: idsCampaign,
        });
      });
    }
    if (prevBudgetLov && prevBudgetLov.reserveForLovs) {
      prevBudgetLov.reserveForLovs.forEach((ele) => {
        let idsCampaign = ele?.idsCampaign || [];
        idsCampaign.filter((idss) => idss !== id);
        API.updateIdsCampaign({
          id: ele.id,
          idsCampaign: idsCampaign,
        });
      });
    }
  }
};

const budgetTransactionLets = async (
  prevBudget,
  budget,
  idBudget,
  prevIdBudget,
  idCampaign,
  user,
  account
) => {
  let errorOccurred = false;
  try {
    const budgetsTransact = [];

    if (idBudget !== prevIdBudget) {
      budgetsTransact.push(
        {
          id: prevIdBudget,
          account,
          quantity: prevBudget,
          sum: true,
          idCampaign,
          user,
        },
        {
          id: idBudget,
          account,
          quantity: budget,
          sum: false,
          idCampaign,
          user,
        }
      );
    } else {
      //! Mantiene el mismo presupuesto pero pudo cambiar la cantidad
      if (budget !== prevBudget) {
        const difference = budget - prevBudget;
        if (difference > 0) {
          budgetsTransact.push({
            id: idBudget,
            quantity: difference,
            sum: false,
            idCampaign,
            user,
            account,
          });
        } else {
          budgetsTransact.push({
            id: idBudget,
            quantity: Math.abs(difference),
            sum: true,
            idCampaign,
            user,
            account,
          });
        }
      }
    }
    console.log("user", budgetsTransact);

    if (budgetsTransact.length > 0) {
      await APILA.budgetTransaction({ budgets: budgetsTransact });
    }
  } catch (error) {
    errorOccurred = true;
  }

  return errorOccurred;
};

const hasChanged = (prev, current) => {
  return JSON.stringify(prev) !== JSON.stringify(current);
};

const generateChanges = (prevCampaign, emailToTag, finalEtiqueta, data) => {
  const {
    name,
    medio,
    formato,
    id_sale,
    fechaIni,
    fechafin,
    budget,
    banner,
    objetivo,
    observation,
    copy,
    name_region,
    publico,
    creativity,
    categoriesSelected,
    id_digital_asset,
  } = data;

  const nameChanged = prevCampaign.name !== name;
  const digitalAssetChanged =
    prevCampaign?.id_digital_asset !== id_digital_asset;
  const medioChanged = prevCampaign.medio !== medio;
  const formatChange = prevCampaign?.formato !== formato;
  const id_saleChanged = prevCampaign.id_sale !== id_sale;
  const fechaIniChanged = prevCampaign.fechaIni !== fechaIni;
  const fechafinChanged = prevCampaign.fechafin !== fechafin;
  const idBudgetChanged = prevCampaign.budget !== budget;
  const bannerChanged = prevCampaign.banner !== banner;
  const objetivoChanged = prevCampaign.objetivo !== objetivo;
  const observationChanged = prevCampaign.observation !== observation;
  const copyChanged = prevCampaign.copy !== copy;
  const countryChanged = prevCampaign.name_region !== name_region;
  const publicoChanged = hasChanged(prevCampaign.publico, publico);
  let creativitieChanged = hasChanged(prevCampaign.creativity, creativity);
  let optionSelectedChanged = false;
  let tagChanged = false;

  if (emailToTag !== finalEtiqueta) {
    console.log("Las etiquetas son diferentes");
    tagChanged = true;
  }

  if (prevCampaign?.categoriesSelected?.length !== categoriesSelected.length) {
    optionSelectedChanged = true;
  } else {
    for (let i = 0; i < prevCampaign.categoriesSelected.length; i++) {
      if (
        prevCampaign.categoriesSelected[i].label !== categoriesSelected[i].label
      ) {
        optionSelectedChanged = true;
        break;
      }
    }
  }

  let budgetChanged = prevCampaign.budget !== budget;

  const changes = {
    nameChanged,
    medioChanged,
    id_saleChanged,
    fechaIniChanged,
    fechafinChanged,
    idBudgetChanged,
    // priorityChanged,
    bannerChanged,
    objetivoChanged,
    formatChange,
    observationChanged,
    copyChanged,
    countryChanged,
    publicoChanged,
    creativitieChanged,
    budgetChanged,
    optionSelectedChanged,
    tagChanged,
    digitalAssetChanged,
  };
  return { changes };
};

/**
 *
 * @deprecated
 * Esta funcion se mantendra como respaldo de los flujos que generaba el proceso de actualizacion/aprobacion/creacion de pautas
 */
export const updateCampaign =
  (
    nameToSend,
    emailToSend,
    id,
    name,
    medio,
    fechaIni,
    fechafin,
    objetivo,
    budget,
    publico,
    idMixDetaill,
    idMixChange,
    idMix,
    idCampaign,
    id_medium,
    medioo,
    id_ad_product,
    banner,
    estado,
    idBudget,
    account,
    creativity,
    observation,
    copy,
    id_sale,
    id_region,
    name_region,
    nameForEmail,
    emailForEmail,
    urls,
    availableAmount,
    newBudget,
    version,
    npost,
    budgets,
    campaigns,
    categories,
    idAudience,
    optionSelected,
    chosenTag,
    tagToEmail,
    formato,
    campAc,
    priority
  ) =>
  async (dispatch) => {
    dispatch(showLoader(true));
    console.warn(
      "¡Este método está marcado como deprecado! Se recomienda no utilizarlo."
    );

    console.log("Campaigns", campaigns);
    const nameE = localStorage.getItem("nameAWS");
    const emailE = localStorage.getItem("emailAWS");
    let prevStatus;
    let prevIdBudget;
    let prevBudget;
    let bandera = 0;
    let finalEtiqueta = "";
    if (campaigns) {
      let response = await APILA.getCampaignById(idMixDetaill);
      console.log("response Campaign", response.data.campaigns[0]);
      /* prevStatus=campaigns?.find(element => element.id == id)?.status; */
      prevStatus = response.data.campaigns[0]?.status;
      prevIdBudget = response.data.campaigns[0]?.idBudget;
      prevBudget = response.data.campaigns[0]?.budget;
    }
    let newCampaing = undefined;

    if (idBudget === prevIdBudget) {
      console.log("Es el mismo valor");
    } else {
      //hay que modificar los valores de las lovs
      console.log("Budgets", budgets);
      const foundBudget = budgets.find((ele) => ele.id === idBudget);
      const prevBudgetLov = budgets.find((ele) => ele.id === prevIdBudget);
      if (foundBudget && foundBudget.reserveForLovs) {
        foundBudget.reserveForLovs.map((ele) => {
          let idsCampaign = ele?.idsCampaign || [];
          idsCampaign.push(id);
          console.log("Actualiza", ele.order_line_id_v6, idsCampaign);
          API.updateIdsCampaign({
            id: ele.id,
            idsCampaign: idsCampaign,
          });
        });
      }
      if (prevBudgetLov && prevBudgetLov.reserveForLovs) {
        prevBudgetLov.reserveForLovs.map((ele) => {
          let idsCampaign = ele?.idsCampaign || [];
          idsCampaign.filter((idss) => idss !== id);
          console.log("Actualiza", ele.order_line_id_v6, idsCampaign);
          API.updateIdsCampaign({
            id: ele.id,
            idsCampaign: idsCampaign,
          });
        });
      }
      console.log("Presupuestos para actualizar", {
        foundBudget,
        prevBudgetLov,
      });
    }

    /* const categorieslabel= await parseCategoriesLabel(category, account, dispatch)
                const categories = await parseCategories(category, account, dispatch) */
    console.log("updateCampaign estado", estado);
    console.log("updateCampaign id", id);
    console.log("updateCampaign name", name);
    console.log("updateCampaign medio", medio);
    console.log("updateCampaign fechaIni", fechaIni);
    console.log("updateCampaign fechafin", fechafin);
    console.log("updateCampaign objetivo", objetivo);
    console.log("updateCampaign budget", budget);
    console.log("updateCampaign publico", publico);
    console.log("updateCampaign idMixDetaill", idMixDetaill);
    console.log("updateCampaign idMixChange", idMixChange);
    console.log("updateCampaign idMix", idMix);
    console.log("updateCampaign idCampaign", idCampaign);
    console.log("updateCampaign id_medium", id_medium);
    console.log("updateCampaign medioo", medioo);
    console.log("updateCampaign id_ad_product", id_ad_product);
    console.log("updateCampaign banner", banner);
    console.log("updateCampaign estado", estado);
    console.log("updateCampaign idBudget", idBudget);
    console.log("updateCampaign account", account);
    console.log("updateCampaign creativity", creativity);
    console.log("updateCampaign observation", observation);
    console.log("updateCampaign copy", copy);
    console.log("updateCampaign id_sale", id_sale);
    console.log("updateCampaign id_region", id_region);
    console.log("updateCampaign name_region", name_region);
    console.log("updateCampaign nameForEmail", nameForEmail);
    console.log("updateCampaign emailForEmail", emailForEmail);
    console.log("updateCampaign urls", urls);
    console.log("updateCampaign availableAmount", availableAmount);
    console.log("updateCampaign newBudget", newBudget);
    console.log("updateCampaign version", version);
    console.log("updateCampaign npost", npost);
    console.log("updateCampaign budgets", budgets);
    console.log("updateCampaign campaigns", campaigns);
    console.log("updateCampaign version update", version);
    console.log("updateCampaign npost update", npost);
    console.log("updateCampaign budgets update", budgets);
    console.log("updateCampaign categories update", categories);
    console.log("updateCampaign idAudience update", idAudience);
    console.log("updateCampaign optionSelected update", optionSelected);
    console.log("updateCampaign optionSelected nameToSend", nameToSend);
    console.log("updateCampaign optionSelected emailToSend", emailToSend);
    console.log("updateCampaign formato", formato);
    console.log("estado ", estado);
    console.log("prevStatus ", prevStatus);
    console.log("CHOSENTAG", chosenTag);
    console.log("EtiquetaActual", tagToEmail);

    if (prevStatus == 1 && estado == 14) {
      //Se le reescribe el estado de editado a creado al no estar aprobado aun
      console.log("reescribiendo estado");
      estado = 1;
    }
    console.log("estado despues ", estado);
    // 1  Creado
    // 10 Activo
    // 14     Puasado (editado)
    // 5  Aprobado
    let searchable_status;
    if (estado) {
      switch (estado) {
        case 1:
          searchable_status = "creado";
          break;
        case 2:
          searchable_status = "cancelado";
          break;
        case 5:
          searchable_status = "aprobado";
          break;
        case 6:
          searchable_status = "en revision";
          break;
        case 9:
          searchable_status = "en implementacion";
          break;
        case 10:
          searchable_status = "activo";
          break;
        case 11:
          searchable_status = "en revsion";
          break;
        case 12:
          searchable_status = "finalizado";
          break;
        case 14:
          searchable_status = "pausado";
          break;
        case 15:
          searchable_status = "en revision";
          break;
        case 16:
          searchable_status = "cancelado";
          break;
        case 17:
          searchable_status = "finalizado";
          break;
      }
    }

    let cE = 0;

    let conservationStatus = false;
    let backStatus = 0;

    //#region COMPROBACION DE CAMBIOS EN LOS CAMPOS PARA EMAIL
    //#endregion
    let body = {
      id,
      name,
      medio,
      status: estado,
      fechaIni,
      fechafin,
      objetivo,
      budget,
      publico,
      idMixDetaill,
      idMixChange,
      idMix,
      idCampaign,
      id_medium,
      medioo,
      id_ad_product,
      banner,
      idBudget,
      account: account?.id,
      creativity,
      observation,
      copy,
      id_sale,
      id_region,
      name_region,
      nameForEmail,
      emailForEmail,
      urls,
      availableAmount,
      version,
      npost,
      idAudience,
      countEdit: 0,
      categoriesSelected: optionSelected,
      searchable_name: name.toLowerCase(),
      searchable_idMixDetaill: idMixDetaill.toString(),
      searchable_version: version?.toLowerCase(),
      searchable_medio: medio.toLowerCase(),
      searchable_status,
      formato,
      priority,
    };
    console.log("BODYDOBO", body);
    const responseTemp = await APILA.getTempCampaigns({
      account: account.id,
      id: id,
    });
    if (responseTemp.data.campaings.length > 0) {
      if (responseTemp.data.campaings[0].countEdit)
        body.countEdit = responseTemp.data.campaings[0].countEdit;
      console.log("value ce", body.countEdit);
      console.log("value ce 2", responseTemp.data.campaings[0]);
    }
    try {
      console.log("body updateCampaign AWS", body);
      // dispatch(newEmailValidation(body.account, "Das"))
      if (!_.isEmpty(campAc) && estado === 14) {
        console.log("campa actual", {
          names: { name, contrValidation: campAc.name },
          id_sales: { id_sale, contrValidation: campAc.id_sale },
          optionSelected: {
            optionSelected,
            contrValidation: campAc.categoriesSelected,
          },
          camps: { campAc },
        });
        const changeCreativity = _.isEqual(creativity, campAc.creativity);

        if (
          fechaIni !== campAc.fechaIni ||
          fechafin !== campAc.fechafin ||
          name_region !== campAc.country ||
          medio !== campAc.medio ||
          banner !== campAc.banner ||
          formato.art !== campAc.formato ||
          // priority !== campAc.priority ||
          idBudget !== campAc.idBudget ||
          budget !== campAc.budget ||
          observation !== campAc.observation ||
          copy !== campAc.copy ||
          idAudience !== campAc.idAudience ||
          !changeCreativity
        ) {
          console.log("cambia de estatus");
          conservationStatus = false;
          const response = await APILA.updateCampaign(body);
          console.log("cambia su estatus al otro modo", response);
        } else {
          console.log("no cambia de estatus");
          conservationStatus = true;
          backStatus = campAc.status;
          let bodyInsert = {
            ...body,
            status: backStatus,
          };
          const response = await APILA.updateCampaign(bodyInsert);
          console.log("mantiene su estatus", response);
        }
        // if()
        // if (
        //   name === campAc.name &&
        //   id_sale === campAc.id_sale &&
        //   optionSelected === campAc.categoriesSelected
        // ) {
        //   //funcionamiento normal
        //   conservationStatus = false;
        //   const response = await APILA.updateCampaign(body);
        //   console.log("cambia su estatus al otro modo", response);
        // } else {
        //   //mantiene su estatus
        //   conservationStatus = true;
        //   backStatus = campAc.status;
        //   let bodyInsert = {
        //     ...body,
        //     status: backStatus,
        //   };
        //   const response = await APILA.updateCampaign(bodyInsert);
        //   console.log("mantiene su estatus", response);
        // }
      } else {
        const response = await APILA.updateCampaign(body);
        console.log("asd", response);
      }
      let changes = {};
      let prevCampaign;
      console.log("prevStatus ", prevStatus);
      console.log("status", estado);
      let changedInTag = false;
      if (prevStatus == 1 && estado == 5) {
        // body.countEdit = body.countEdit + 1
        newCampaing = true;
        console.log("prevStatus==1");
        // console.log("Prev 11");
        body.idCamp = v4();
        body.notified = false;
        console.log("prevStatus==1 && estado==5", body);
        const responseInsert = await APILA.upsertTempCampaigns(body);
        console.log("Value ce 3", responseInsert);
        // se manda a enviar el correo sin cambios
      } else {
        if (estado == 14) {
          //se manda correo de la edicion
          body.idCamp = v4();
          body.notified = false;
          console.log("Estado 5 ", body);
          //const responseTemp = await APILA.upsertTempCampaigns(body) s
        }
        if (estado == 5) {
          let body2 = {
            account: account.id,
            id: id,
          };
          const responseTemp = await APILA.getTempCampaigns(body2);
          let campaignsTemp = responseTemp.data.campaings;
          console.log("CampTemps", campaignsTemp);
          ////etiqueta
          let audiences = await API.getAudiences(account.id);
          let aud = audiences.data.audiences;
          //// se manda a actualizar nnuevo campo
          console.log("responseTemp else ", responseTemp);
          console.log("body else ", body);
          console.log("campaignsTemp ", campaignsTemp);
          if (!_.isEmpty(campaignsTemp)) {
            for (let i = 0; i < campaignsTemp.length; i++) {
              campaignsTemp[i].notified = true;
              console.log("element ", campaignsTemp[i]);
              let resp = await APILA.upsertTempCampaigns(campaignsTemp[i]);
            }
            prevCampaign = campaignsTemp[0];
            console.log("prevCampaign=campaignsTemp[0]", prevCampaign, formato);
            // prevCampaign = campaigns.find(element => element.id == id);
            let nameChanged = prevCampaign.name != name ? true : false;
            let medioChanged = prevCampaign.medio != medio ? true : false;
            let formatChange = prevCampaign?.formato != formato ? true : false;
            // let priorityChanged =
            // prevCampaign?.priority != priority ? true : false;
            let id_saleChanged = prevCampaign.id_sale != id_sale ? true : false;
            let fechaIniChanged =
              prevCampaign.fechaIni != fechaIni ? true : false;
            let fechafinChanged =
              prevCampaign.fechafin != fechafin ? true : false;
            let idBudgetChanged = prevCampaign.budget != budget ? true : false;
            let bannerChanged = prevCampaign.banner != banner ? true : false;
            let objetivoChanged =
              prevCampaign.objetivo != objetivo ? true : false;
            let observationChanged =
              prevCampaign.observation != observation ? true : false;
            let copyChanged = prevCampaign.copy != copy ? true : false;
            let countryChanged =
              prevCampaign.name_region != name_region ? true : false;
            let publicoChanged =
              JSON.stringify(prevCampaign.publico) != JSON.stringify(publico)
                ? true
                : false;
            let creativitieChanged = false;
            let optionSelectedChanged = false;
            let tagChanged = false;

            if (tagToEmail === finalEtiqueta) {
              console.log("LAS ETIQUETAS SON IGUALES");
            } else {
              console.log("Las etiquetas son diferentes");
              tagChanged = true;
            }

            if (
              prevCampaign?.categoriesSelected?.length != optionSelected.length
            ) {
              optionSelectedChanged = true;
            } else if (
              prevCampaign.categoriesSelected.length == optionSelected.length
            ) {
              for (let i = 0; i < prevCampaign.categoriesSelected.length; i++) {
                if (
                  prevCampaign.categoriesSelected[i].label !=
                  optionSelected[i].label
                ) {
                  optionSelectedChanged = true;
                }
              }
            } else {
              optionSelectedChanged = false;
            }

            if (prevCampaign.creativity.length != creativity.length) {
              creativitieChanged = true;
            } else if (prevCampaign.creativity.length == creativity.length) {
              for (let i = 0; i < prevCampaign.creativity.length; i++) {
                if (prevCampaign.creativity[i].name != creativity[i].name) {
                  creativitieChanged = true;
                }
              }
            } else {
              creativitieChanged = false;
            }
            let budgetChanged = prevCampaign.budget != budget ? true : false;
            changes = {
              nameChanged,
              medioChanged,
              id_saleChanged,
              fechaIniChanged,
              fechafinChanged,
              idBudgetChanged,
              // priorityChanged,
              bannerChanged,
              objetivoChanged,
              formatChange,
              observationChanged,
              copyChanged,
              countryChanged,
              publicoChanged,
              creativitieChanged,
              budgetChanged,
              optionSelectedChanged,
              tagChanged,
            };
          }
          console.log("Changes", changes);
          // insertando la informacion de la ultima campaña aprobado
          newCampaing = false;

          chosenTag.columnsSelected.forEach((element) => {
            if (element === "publico") {
              if (changes.publicoChanged === true) {
                changedInTag = true;
              } else if (element === "medio") {
                if (changes.medioChanged === true) {
                  changedInTag = true;
                }
              } else if (element === "banner") {
                if (changes.bannerChanged === true) {
                  changedInTag = true;
                }
              } else if (element === "fechaIni") {
                if (changes.fechaIniChanged === true) {
                  changedInTag = true;
                }
              } else if (element === "fechafin") {
                if (changes.fechafinChanged === true) {
                  changedInTag = true;
                }
              } else if (element === "country") {
                if (changes.countryChanged === true) {
                  changedInTag = true;
                }
              } else if (element === "budget") {
                if (changes.budgetChanged === true) {
                  changedInTag = true;
                }
              }
            }
          });

          if (
            changes.medioChanged === false &&
            changes.fechaIniChanged === false &&
            changes.fechafinChanged === false &&
            changes.idBudgetChanged === false &&
            changes.bannerChanged === false &&
            changes.objetivoChanged === false &&
            changes.observationChanged === false &&
            // changes.priorityChanged === false &&
            changes.copyChanged === false &&
            changes.formatChange === false &&
            changes.countryChanged === false &&
            changes.publicoChanged === false &&
            changes.creativitieChanged === false &&
            changes.budgetChanged === false
          ) {
          } else {
            body.countEdit = body.countEdit + 1;
          }
          body.idCamp = v4();
          body.notified = false;
          console.log("prevStatus==1 && estado==5", body);
          const responseInsert = await APILA.upsertTempCampaigns(body);
        }
        // if(prevStatus==5){
        // }
      }
      // if(estado==5){
      // }else if(estado==1){
      //   console.log('updateCampaign no actualiza campos del email ');
      // }
      console.log("newCampaing", newCampaing);
      const data = {
        id,
        idMixDetaill,
        name,
        fechaIni,
        fechafin,
        budget,
        id_ad_product,
        id_publico: null,
        id_ad_status: conservationStatus ? backStatus : estado,
        comments: observation, //observation+" -----"+copy,
        copy,
        id_sale,
        name_region,
        objetivo,
        banner,
        medio,
        publico,
        copy,
        observation,
        nameForEmail,
        emailForEmail,
        urls,
        version,
        npost,
        prevCampaign,
        priority: priority,
        changes,
        prev_nombre_categoria: "",
        account: account.id,
        idAudience,
        categoriesSelected: optionSelected,
        searchable_name: name.toLowerCase(),
        searchable_idMixDetaill: idMixDetaill.toString(),
        searchable_version: version.toLowerCase(),
        searchable_medio: medio.toLowerCase(),
        searchable_status,
        newCampaing,
        creativity,
        nameToSend,
        countEdit: body.countEdit,
        emailToSend,
        emailToTag: tagToEmail,
        prevTag: finalEtiqueta,
        formato: formato,
        changedInTag: changedInTag,
      };
      console.log("La data que envio", data);
      let bodie = {
        id: v4(),
        idMixDetaill: id,
        idBudget: idBudget,
        budget: budget,
        available: availableAmount,
        date: new Date(),
        account: account.id,
        nameForEmail,
        emailForEmail,
        data: data,
        prevStatus,
        action:
          estado === 5
            ? "se ha aprobado una campaña"
            : "se ha editado una campaña",
        component: "campaign",
      };
      await APILA.insertBitacora(bodie);
      console.log("updateCampaign body mysql", data);
      const tempAWSUser = localStorage.getItem("tempAWSUser");
      console.log("updateCampaign tempAWSUser", tempAWSUser);
      if (tempAWSUser) {
        console.log("updateCampaign sendCampToEAdvertise");
        dispatch(
          Modal.showAlertModal(
            "Solicitud Enviada",
            "Su campaña fue enviado exitosamente.",
            Modal.MODAL_TYPE_SUCCESS
          )
        );
        sendCampToEAdvertise(data, account, dispatch, true).then(async () => {
          if (
            changes.fechaIniChanged == false ||
            changes.fechaIniChanged == true
          ) {
            if (
              changes.medioChanged === false &&
              changes.fechaIniChanged === false &&
              changes.fechafinChanged === false &&
              changes.idBudgetChanged === false &&
              changes.bannerChanged === false &&
              changes.objetivoChanged === false &&
              // changes.priorityChanged === false &&
              changes.observationChanged === false &&
              changes.copyChanged === false &&
              changes.formatChange === false &&
              changes.countryChanged === false &&
              changes.publicoChanged === false &&
              changes.creativitieChanged === false &&
              changes.budgetChanged === false
            ) {
            } else {
              console.log("El estado em dashboardActions 1", estado);
              if (estado === 5) {
                let response = await APILA.getQueueById({
                  idMixDetaill: id,
                  idCategory: 1,
                });
                if (response.data.queue.length > 0) {
                  let eleQueue =
                    response.data.queue[response.data.queue.length - 1];
                  if (eleQueue.id_user === null) {
                    await APILA.deleteQueue({ id: eleQueue.id });
                    await APILA.insertQueue({
                      idMixDetaill: id,
                      requestType: 2,
                      version: body.countEdit,
                    });
                  } else if (
                    eleQueue.id_user != null &&
                    eleQueue.date_end === null
                  ) {
                    //aqui
                    await APILA.editQueue({
                      id: eleQueue.id,
                      date_end: new Date().toISOString(),
                      version: body.countEdit,
                    });
                    await APILA.insertQueue({
                      idMixDetaill: id,
                      requestType: 2,
                      version: body.countEdit,
                    });
                  } else {
                    await APILA.insertQueue({
                      idMixDetaill: id,
                      requestType: 2,
                      version: body.countEdit,
                    });
                  }
                } else {
                  await APILA.insertQueue({
                    idMixDetaill: id,
                    requestType: 1,
                    version: null,
                  });
                }
              }
              if (estado == 14) {
                let response = await APILA.getQueueById({
                  idMixDetaill: id,
                  idCategory: 1,
                });
                console.log("responseQueue", response.data.queue);
                if (response.data.queue.length > 0) {
                  console.log("Existe Queue");
                  let eleQueue =
                    response.data.queue[response.data.queue.length - 1];
                  if (eleQueue.id_user != null && eleQueue.date_end === null) {
                    console.log("USER ACTUALIZAR REGISTRO Y CREAR NUEVO");
                    await APILA.editQueue({
                      id: eleQueue.id,
                      date_end: new Date().toISOString(),
                      version: body.countEdit,
                    });
                    await APILA.insertQueue({
                      idMixDetaill: id,
                      requestType: 2,
                      version: body.countEdit,
                    });
                  } else if (
                    eleQueue.id_user === null &&
                    eleQueue.date_end != null
                  ) {
                    console.log("USER ELIMINAR REGISTRO Y CREAR NUEVO");
                    await APILA.deleteQueue({ id: eleQueue.id });
                    await APILA.insertQueue({
                      idMixDetaill: id,
                      requestType: 2,
                      version: body.countEdit,
                    });
                  } else if (
                    eleQueue.id_user != null &&
                    eleQueue.date_end != null
                  ) {
                    console.log("Insertar Nueva");
                    await APILA.insertQueue({
                      idMixDetaill: id,
                      requestType: 2,
                      version: body.countEdit,
                    });
                  } else {
                    await APILA.insertQueue({
                      idMixDetaill: id,
                      requestType: 2,
                      version: body.countEdit,
                    });
                  }
                } else {
                  console.log("No Existe queue, Insertar Nueva");
                  await APILA.insertQueue({
                    idMixDetaill: id,
                    requestType: 2,
                    version: body.countEdit,
                  });
                }
              }
            }
          } else {
            if (estado === 5) {
              let response = await APILA.getQueueById({
                idMixDetaill: id,
                idCategory: 1,
              });
              if (response.data.queue.length > 0) {
                let eleQueue =
                  response.data.queue[response.data.queue.length - 1];
                if (eleQueue.id_user === null) {
                  await APILA.deleteQueue({ id: eleQueue.id });
                  await APILA.insertQueue({
                    idMixDetaill: id,
                    requestType: 2,
                    version: body.countEdit,
                  });
                } else if (
                  eleQueue.id_user != null &&
                  eleQueue.date_end === null
                ) {
                  await APILA.editQueue({
                    id: eleQueue.id,
                    date_end: new Date().toISOString(),
                    version: body.countEdit,
                  });
                  await APILA.insertQueue({
                    idMixDetaill: id,
                    requestType: 2,
                    version: body.countEdit,
                  });
                }
              } else {
                await APILA.insertQueue({
                  idMixDetaill: id,
                  requestType: 1,
                  version: null,
                });
              }
            }
          }

          let getBudgets = await API.getBudgets(account.id);
          let datosBudgets = getBudgets.data.budgets;

          if (prevIdBudget === idBudget && estado !== 5) {
            //El presupuesto es el mismo
            if (Number(prevBudget) !== Number(budget)) {
              //el presupuesto cambio
              const updateBudget = datosBudgets.find(
                (ele) => ele.id === idBudget
              );
              const availableAmountTotal =
                Number(updateBudget.available) + Number(prevBudget);
              const newAvailable = availableAmountTotal - budget;
              updateBudgetAvailable(idBudget, newAvailable);
            }
          } else if (estado !== 5) {
            //Se valida que no sea una campa;a que se esta aprobando con el estaus 5
            //Le cambiaron el presupuesto con el que lo asociaron
            const updateBudget = datosBudgets.find(
              (ele) => ele.id === idBudget
            );
            const prevBudgetUpdate = datosBudgets.find(
              (ele) => ele.id === prevIdBudget
            );
            const newAvailable =
              Number(updateBudget.available) - Number(budget);
            const backAvailable =
              Number(prevBudgetUpdate.available) + Number(prevBudget);
            updateBudgetAvailable(prevIdBudget, backAvailable);
            updateBudgetAvailable(idBudget, newAvailable);
          }
        });
      } else {
        console.log("Las cantidades en el presupuseto 1", budget);
        console.log("Las cantidades en el presupuseto 2", idBudget);
        console.log("Las cantidades en el presupuseto 3", newBudget);
        console.log("Las cantidades en el presupuseto 1", budgets);

        console.log("updateCampaign sendUpdateCamping");
        sendUpdateCamping(data, account, estado, dispatch).then(async () => {
          console.log("changes2", changes);
          if (
            changes.fechaIniChanged == false ||
            changes.fechaIniChanged == true
          ) {
            if (
              changes.medioChanged === false &&
              changes.fechaIniChanged === false &&
              changes.fechafinChanged === false &&
              changes.idBudgetChanged === false &&
              changes.bannerChanged === false &&
              changes.objetivoChanged === false &&
              changes.observationChanged === false &&
              changes.copyChanged === false &&
              changes.formatChange === false &&
              changes.countryChanged === false &&
              changes.publicoChanged === false &&
              changes.creativitieChanged === false &&
              changes.budgetChanged === false
            ) {
            } else {
              console.log("El estado em dashboardActions 2", estado);
              if (estado === 5) {
                let response = await APILA.getQueueById({
                  idMixDetaill: id,
                  idCategory: 1,
                });
                console.log("Response queue", response);
                if (response.data.queue.length > 0) {
                  console.log("Entre al if");
                  let eleQueue =
                    response.data.queue[response.data.queue.length - 1];
                  console.log("ELe queue", eleQueue);
                  if (eleQueue.id_user === null) {
                    console.log("Primer if ele");
                    await APILA.deleteQueue({ id: eleQueue.id });

                    await APILA.insertQueue({
                      idMixDetaill: id,
                      requestType: 2,
                      version: body.countEdit,
                    });
                  } else if (
                    eleQueue.id_user != null &&
                    eleQueue.date_end === null
                  ) {
                    console.log("El else if queue");
                    await APILA.editQueue({
                      id: eleQueue.id,
                      date_end: new Date().toISOString(),
                      version: body.countEdit,
                    });
                    await APILA.insertQueue({
                      idMixDetaill: id,
                      requestType: 2,
                      version: body.countEdit,
                    });
                  } else {
                    await APILA.insertQueue({
                      idMixDetaill: id,
                      requestType: 2,
                      version: body.countEdit,
                    });
                  }
                } else {
                  await APILA.insertQueue({
                    idMixDetaill: id,
                    requestType: 1,
                    version: body.countEdit,
                  });
                }
              }
              if (estado === 14) {
                //aqui se edita
                let response = await APILA.getQueueById({
                  idMixDetaill: id,
                  idCategory: 1,
                });
                console.log("responseQueue", response.data.queue);
                if (response.data.queue.length > 0) {
                  console.log("Existe Queue");
                  let eleQueue =
                    response.data.queue[response.data.queue.length - 1];
                  if (eleQueue.id_user != null && eleQueue.date_end === null) {
                    console.log("USER ACTUALIZAR REGISTRO Y CREAR NUEVO");
                    await APILA.editQueue({
                      id: eleQueue.id,
                      date_end: new Date().toISOString(),
                      version: body.countEdit,
                    });
                    await APILA.insertQueue({
                      idMixDetaill: id,
                      requestType: 2,
                      version: body.countEdit,
                    });
                  } else if (
                    eleQueue.id_user === null &&
                    eleQueue.date_end != null
                  ) {
                    console.log("USER ELIMINAR REGISTRO Y CREAR NUEVO");
                    await APILA.deleteQueue({ id: eleQueue.id });
                    await APILA.insertQueue({
                      idMixDetaill: id,
                      requestType: 2,
                      version: body.countEdit,
                    });
                  } else if (
                    eleQueue.id_user != null &&
                    eleQueue.date_end != null
                  ) {
                    console.log("Insertar Nueva");

                    await APILA.insertQueue({
                      idMixDetaill: id,
                      requestType: 2,
                      version: body.countEdit,
                    });
                  } else {
                    await APILA.insertQueue({
                      idMixDetaill: id,
                      requestType: 2,
                      version: body.countEdit,
                    });
                  }
                } else {
                  console.log("No Existe queue, Insertar Nueva");
                  await APILA.insertQueue({
                    idMixDetaill: id,
                    requestType: 2,
                    version: body.countEdit,
                  });
                }
              }
            }
          } else {
            console.log("aca?");
            if (estado === 5) {
              let response = await APILA.getQueueById({
                idMixDetaill: id,
                idCategory: 1,
              });
              if (response.data.queue.length > 0) {
                let eleQueue =
                  response.data.queue[response.data.queue.length - 1];
                if (eleQueue.id_user === null) {
                  await APILA.deleteQueue({ id: eleQueue.id });

                  await APILA.insertQueue({
                    idMixDetaill: id,
                    requestType: 2,
                    version: body.countEdit,
                  });
                } else if (
                  eleQueue.id_user != null &&
                  eleQueue.date_end === null
                ) {
                  await APILA.editQueue({
                    id: eleQueue.id,
                    date_end: new Date().toISOString(),
                    version: body.countEdit,
                  });
                  await APILA.insertQueue({
                    idMixDetaill: id,
                    requestType: 2,
                    version: body.countEdit,
                  });
                }
              } else {
                await APILA.insertQueue({
                  idMixDetaill: id,
                  requestType: 1,
                  version: body.countEdit,
                });
              }
            }
          }
          let getBudgets = await API.getBudgets(account.id);
          let datosBudgets = getBudgets.data.budgets;

          if (prevIdBudget === idBudget && estado !== 5) {
            //El presupuesto es el mismo
            if (Number(prevBudget) !== Number(budget)) {
              //el presupuesto cambio
              const updateBudget = datosBudgets.find(
                (ele) => ele.id === idBudget
              );
              const availableAmountTotal =
                Number(updateBudget.available) + Number(prevBudget);
              const newAvailable = availableAmountTotal - budget;
              updateBudgetAvailable(idBudget, newAvailable);
            }
          } else if (estado !== 5) {
            //Se valida que no sea una campa;a que se esta aprobando con el estaus 5
            //Le cambiaron el presupuesto con el que lo asociaron
            const updateBudget = datosBudgets.find(
              (ele) => ele.id === idBudget
            );
            const prevBudgetUpdate = datosBudgets.find(
              (ele) => ele.id === prevIdBudget
            );
            const newAvailable =
              Number(updateBudget.available) - Number(budget);
            const backAvailable =
              Number(prevBudgetUpdate.available) + Number(prevBudget);
            updateBudgetAvailable(prevIdBudget, backAvailable);
            updateBudgetAvailable(idBudget, newAvailable);
          }
        });
      }
      localStorage.setItem("nameAWS", "");
      localStorage.setItem("tempAWSUser", "");
      localStorage.setItem("account", "");
    } catch (err) {
      console.log("EL error ", err);
      Logger.log(TAG, "error createCampaing", err);
      dispatch(
        Modal.showAlertModal(
          "Error!",
          "Ocurrió un error al actualizar la campaña",
          Modal.MODAL_TYPE_ERROR
        )
      );
    }
    dispatch(showLoader(false));
  };

const updateBudgetAvailable = async (id, available, amountTotal) => {
  if (+available >= -1 && +available <= 0) {
    available = 0;
  }
  const insertAvailable = available === 0 ? "0" : available;
  if (Number(amountTotal) === available && available) {
    API.updateBudget({ id: id, available: insertAvailable, status: 1 });
  } else if (available) {
    API.updateBudget({ id: id, available: insertAvailable });
  }
};

export const updateCampaignFinalized =
  (
    nameToSend,
    emailToSend,
    id,
    name,
    medio,
    fechaIni,
    fechafin,
    objetivo,
    budget,
    publico,
    idMixDetaill,
    idMixChange,
    idMix,
    idCampaign,
    id_medium,
    medioo,
    id_ad_product,
    banner,
    estado,
    idBudget,
    account,
    creativity,
    observation,
    copy,
    id_sale,
    id_region,
    name_region,
    nameForEmail,
    emailForEmail,
    urls,
    availableAmount,
    newBudget,
    version,
    npost,
    budgets,
    campaigns,
    categories,
    idAudience,
    optionSelected,
    chosenTag,
    tagToEmail
  ) =>
  async (dispatch) => {
    dispatch(showLoader(true));
    let searchable_status = "finalizado";
    let prevIdBudget;
    let prevBudget;
    let prevCampaign;
    let changes = {};
    let newCampaing = undefined;
    let finalEtiqueta = "";

    let body = {
      id,
      name,
      medio,
      status: 12,
      fechaIni,
      fechafin,
      objetivo,
      budget,
      publico,
      idMixDetaill,
      idMixChange,
      idMix,
      idCampaign,
      id_medium,
      medioo,
      id_ad_product,
      banner,
      idBudget,
      account: account?.id,
      creativity,
      observation,
      copy,
      id_sale,
      id_region,
      name_region,
      nameForEmail,
      emailForEmail,
      urls,
      availableAmount,
      version,
      npost,
      idAudience,
      countEdit: 0,
      categoriesSelected: optionSelected,
      searchable_name: name.toLowerCase(),
      searchable_idMixDetaill: idMixDetaill.toString(),
      searchable_version: version?.toLowerCase(),
      searchable_medio: medio.toLowerCase(),
      searchable_status,
    };
    if (campaigns) {
      let response = await APILA.getCampaignById(idMixDetaill);
      prevIdBudget = response.data.campaigns[0]?.idBudget;
      prevBudget = response.data.campaigns[0]?.budget;
    }
    const budgetPauta = parseFloat(budget); //Presupuesto de la campa;ana
    const errorBudget = await budgetTransactionLets(
      prevBudget,
      budgetPauta,
      idBudget,
      prevIdBudget,
      id,
      emailForEmail,
      account.id
    );

    if (errorBudget) {
      return dispatch(
        Modal.showAlertModal(
          "Error!",
          "Ocurrió un error al actualizar la campaña",
          Modal.MODAL_TYPE_ERROR
        )
      );
    }
    await APILA.updateCampaign(body);

    let body2 = {
      account: account.id,
      id: id,
    };
    const responseTemp = await APILA.getTempCampaigns(body2);
    let campaignsTemp = responseTemp?.data?.campaings;

    if (!_.isEmpty(campaignsTemp)) {
      prevCampaign = campaignsTemp[0];
      let nameChanged = prevCampaign.name != name ? true : false;
      let medioChanged = prevCampaign.medio != medio ? true : false;
      let id_saleChanged = prevCampaign.id_sale != id_sale ? true : false;
      let fechaIniChanged = prevCampaign.fechaIni != fechaIni ? true : false;
      let fechafinChanged = prevCampaign.fechafin != fechafin ? true : false;
      let idBudgetChanged = prevCampaign.budget != budget ? true : false;
      let bannerChanged = prevCampaign.banner != banner ? true : false;
      let objetivoChanged = prevCampaign.objetivo != objetivo ? true : false;
      let observationChanged =
        prevCampaign.observation != observation ? true : false;
      let copyChanged = prevCampaign.copy != copy ? true : false;
      let countryChanged =
        prevCampaign.name_region != name_region ? true : false;
      let publicoChanged =
        JSON.stringify(prevCampaign.publico) != JSON.stringify(publico)
          ? true
          : false;
      let creativitieChanged = false;
      let optionSelectedChanged = false;
      let tagChanged = false;
      let budgetChanged = prevCampaign.budget != budget ? true : false;

      changes = {
        nameChanged,
        medioChanged,
        id_saleChanged,
        fechaIniChanged,
        fechafinChanged,
        idBudgetChanged,
        bannerChanged,
        objetivoChanged,
        observationChanged,
        copyChanged,
        countryChanged,
        publicoChanged,
        creativitieChanged,
        budgetChanged,
        optionSelectedChanged,
        tagChanged,
      };
    }

    const data = {
      id,
      idMixDetaill,
      name,
      fechaIni,
      fechafin,
      budget,
      id_ad_product,
      id_publico: null,
      id_ad_status: estado,
      comments: observation, //observation+" -----"+copy,
      copy,
      id_sale,
      name_region,
      objetivo,
      banner,
      medio,
      publico,
      copy,
      observation,
      nameForEmail,
      emailForEmail,
      urls,
      version,
      npost,
      prevCampaign,
      changes,
      prev_nombre_categoria: "",
      account: account.id,
      idAudience,
      categoriesSelected: optionSelected,
      searchable_name: name.toLowerCase(),
      searchable_idMixDetaill: idMixDetaill.toString(),
      searchable_version: version.toLowerCase(),
      searchable_medio: medio.toLowerCase(),
      searchable_status,
      newCampaing,
      creativity,
      nameToSend,
      countEdit: body.countEdit,
      emailToSend,
      emailToTag: tagToEmail,
      prevTag: finalEtiqueta,
    };
    let bodie = {
      id: v4(),
      idMixDetaill: id,
      idBudget: idBudget,
      budget: budget,
      available: availableAmount,
      date: new Date(),
      account: account.id,
      nameForEmail,
      emailForEmail,
      data: data,
      action:
        estado === 5
          ? "se ha aprobado una campaña"
          : "se ha editado una campaña",
      component: "campaign",
    };
    APILA.insertBitacora(bodie);
    sendCamptFinalizedEadvertise(data, account, dispatch).then(() => {
      dispatch(showLoader(false));
    });
  };

export const exportCampaignAWS =
  (
    id,
    name,
    medio,
    fechaIni,
    fechafin,
    objetivo,
    budget,
    category,
    publico,
    idMixDetaill,
    idMixChange,
    idMix,
    idCampaign,
    id_medium,
    medioo,
    id_ad_product,
    banner,
    estado,
    idBudget,
    account,
    creativity,
    observation,
    copy,
    id_sale,
    id_region,
    name_region,
    nameForEmail,
    emailForEmail,
    urls,
    availableAmount,
    newBudget,
    version,
    npost,
    budgets,
    campaigns,
    categories,
    idAudience,
    optionSelected
  ) =>
  async (dispatch) => {
    dispatch(showLoader(true));
    let prevCampaign = undefined;
    let changes = undefined;
    let newCampaing = undefined;
    const categorieslabel = await parseCategoriesLabel(
      category,
      account,
      dispatch
    );
    const categories = await parseCategories(category, account, dispatch);
    //#region LOGS
    console.log("exportCampaignAWS estado", estado);
    console.log("exportCampaignAWS id", id);
    console.log("exportCampaignAWS name", name);
    console.log("exportCampaignAWS medio", medio);
    console.log("exportCampaignAWS fechaIni", fechaIni);
    console.log("exportCampaignAWS fechafin", fechafin);
    console.log("exportCampaignAWS objetivo", objetivo);
    console.log("exportCampaignAWS budget", budget);
    console.log("exportCampaignAWS publico", publico);
    console.log("exportCampaignAWS idMixDetaill", idMixDetaill);
    console.log("exportCampaignAWS idMixChange", idMixChange);
    console.log("exportCampaignAWS idMix", idMix);
    console.log("exportCampaignAWS idCampaign", idCampaign);
    console.log("exportCampaignAWS id_medium", id_medium);
    console.log("exportCampaignAWS medioo", medioo);
    console.log("exportCampaignAWS id_ad_product", id_ad_product);
    console.log("exportCampaignAWS banner", banner);
    console.log("exportCampaignAWS estado", estado);
    console.log("exportCampaignAWS idBudget", idBudget);
    console.log("exportCampaignAWS account", account);
    console.log("exportCampaignAWS creativity", creativity);
    console.log("exportCampaignAWS observation", observation);
    console.log("exportCampaignAWS copy", copy);
    console.log("exportCampaignAWS id_sale", id_sale);
    console.log("exportCampaignAWS id_region", id_region);
    console.log("exportCampaignAWS name_region", name_region);
    console.log("exportCampaignAWS nameForEmail", nameForEmail);
    console.log("exportCampaignAWS emailForEmail", emailForEmail);
    console.log("exportCampaignAWS urls", urls);
    console.log("exportCampaignAWS availableAmount", availableAmount);
    console.log("exportCampaignAWS newBudget", newBudget);
    console.log("exportCampaignAWS version", version);
    console.log("exportCampaignAWS npost", npost);
    console.log("exportCampaignAWS budgets", budgets);
    console.log("exportCampaignAWS campaigns", campaigns);
    Logger.log("exportCampaignAWS categorieslabel", categorieslabel);
    Logger.log("exportCampaignAWS categoriesvalues", categories);
    console.log("exportCampaignAWS version update", version);
    console.log("exportCampaignAWS npost update", npost);
    console.log("exportCampaignAWS budgets update", budgets);
    console.log("exportCampaignAWS campaigns update", campaigns);
    console.log("exportCampaignAWS idAudience update", idAudience);
    console.log("exportCampaignAWS optionSelected update", optionSelected);

    console.log("estado ", estado);
    // 1        Creado
    // 10        Activo
    // 14         Puasado (editado)
    // 5         Aprobado
    let searchable_status;
    if (estado) {
      switch (estado) {
        case 1:
          searchable_status = "creado";
          break;
        case 2:
          searchable_status = "cancelado";
          break;
        case 5:
          searchable_status = "aprobado";
          break;
        case 6:
          searchable_status = "en revision";
          break;
        case 9:
          searchable_status = "en implementacion";
          break;
        case 10:
          searchable_status = "activo";
          break;
        case 11:
          searchable_status = "en revsion";
          break;
        case 12:
          searchable_status = "finalizado";
          break;
        case 14:
          searchable_status = "pausado";
          break;
        case 15:
          searchable_status = "en revision";
          break;
        case 16:
          searchable_status = "cancelado";
          break;
        case 17:
          searchable_status = "finalizado";
          break;
      }
    }

    let body = {
      id,
      name,
      medio,
      status: estado,
      fechaIni,
      fechafin,
      objetivo,
      budget,
      publico,
      idMixDetaill,
      idMixChange,
      idMix,
      idCampaign,
      id_medium,
      medioo,
      id_ad_product,
      banner,
      idBudget,
      account: account.id,
      creativity,
      observation,
      copy,
      id_sale,
      id_region,
      name_region,
      nameForEmail,
      emailForEmail,
      urls,
      availableAmount,
      version,
      npost,
      idAudience,
      categoriesSelected: optionSelected,
      searchable_name: name.toLowerCase(),
      searchable_idMixDetaill: idMixDetaill.toString(),
      searchable_version: version.toLowerCase(),
      searchable_medio: medio.toLowerCase(),
      searchable_status,
    };
    try {
      console.log("body updateCampaign AWS", body);
      const response = await APILA.updateCampaign(body);
      console.log("updateCampaign AWS response", response);

      //  let changes={};
      //  let prevCampaign
      // console.log("prevStatus ",prevStatus)
      // console.log("status",estado)

      // if(prevStatus==1 && estado==5){
      //   newCampaing=true;
      //   console.log("prevStatus==1")

      //   body.idCamp=v4()
      //   body.notified=false;
      //   console.log("prevStatus==1 && estado==5", body)
      //   const responseInsert= await APILA.upsertTempCampaigns(body)
      //   // se manda a enviar el correo sin cambios

      // }else{
      //   if(estado==14){
      //     //se manda correo de la edicion
      //     body.idCamp=v4()
      //     body.notified=false;
      //     console.log("Estado 5 ", body)
      //     const responseTemp = await APILA.upsertTempCampaigns(body)
      //     console.log("Estado 5 resp",responseTemp)
      //   }
      //   if(estado==5){
      //     let body2={
      //       "account": account.id,
      //        id
      //     }
      //     const responseTemp = await APILA.getTempCampaigns(body2)
      //     let campaignsTemp=responseTemp.data.campaings;
      //     //// se manda a actualizar nnuevo campo
      //     console.log("responseTemp else ", responseTemp)
      //     console.log("body else ", body)
      //     console.log("campaignsTemp ", campaignsTemp)

      //     if(!_.isEmpty(campaignsTemp)){
      //       for (let i = 0; i < campaignsTemp.length; i++) {
      //         campaignsTemp[i].notified=true;
      //         console.log("element " , campaignsTemp[i])
      //         let resp = await APILA.upsertTempCampaigns(campaignsTemp[i])
      //       }

      //       prevCampaign=campaignsTemp[0]
      //       console.log("prevCampaign=campaignsTemp[0]" , prevCampaign)
      //       // prevCampaign = campaigns.find(element => element.id == id);
      //       let nameChanged = prevCampaign.name!=name ? true : false;
      //       let medioChanged= prevCampaign.medio!=medio ? true : false;
      //       let id_saleChanged=  prevCampaign.id_sale!=id_sale ? true : false;
      //       let fechaIniChanged=prevCampaign.fechaIni!=fechaIni ? true : false;
      //       let fechafinChanged=prevCampaign.fechafin!=fechafin ? true : false;
      //       let idBudgetChanged=prevCampaign.budget!=budget ? true : false;
      //       let bannerChanged=prevCampaign.banner!=banner ? true : false;
      //       let objetivoChanged=prevCampaign.objetivo!=objetivo ? true : false;
      //       let observationChanged=prevCampaign.observation!=observation ? true : false;
      //       let copyChanged=prevCampaign.copy!=copy ? true : false;
      //       let countryChanged=prevCampaign.name_region!=name_region ? true : false;
      //       let categoryChanged=JSON.stringify(prevCampaign.category) != JSON.stringify(categories) ? true : false;
      //       let publicoChanged=JSON.stringify(prevCampaign.publico) != JSON.stringify(publico) ? true : false;
      //       console.log(categoryChanged);
      //       changes={
      //         nameChanged,
      //         medioChanged,
      //         id_saleChanged,
      //         fechaIniChanged,
      //         fechafinChanged,
      //         idBudgetChanged,
      //         bannerChanged,
      //         objetivoChanged,
      //         observationChanged,
      //         copyChanged,
      //         countryChanged,
      //         categoryChanged,
      //         publicoChanged
      //       }
      //     }

      //     // insertando la informacion de la ultima campaña aprobado
      //     newCampaing=false;
      //     body.idCamp=v4()
      //     body.notified=false;
      //     console.log("prevStatus==1 && estado==5", body)
      //     const responseInsert= await APILA.upsertTempCampaigns(body)
      //   }
      //   // if(prevStatus==5){

      //   // }
      // }
      // // if(estado==5){

      // // }else if(estado==1){
      // //   console.log('updateCampaign no actualiza campos del email ');
      // // }
      // console.log("newCampaing",newCampaing)
      // Logger.log('updateCampaign AWS response', response)

      // const data = {
      //   id,
      //   idMixDetaill,
      //   name,
      //   fechaIni,
      //   fechafin,
      //   budget,
      //   id_ad_product,
      //   id_publico:null,
      //   id_ad_status: estado,
      //   comments: observation,//observation+" -----"+copy,
      //   copy,
      //   id_sale,
      //   name_region,
      //   objetivo,
      //   banner,
      //   medio,
      //   publico,
      //   copy,
      //   observation,
      //   nameForEmail,
      //   emailForEmail,
      //   id_sale,
      //   category: categories,
      //   nombre_categoria: categorieslabel,
      //   urls,
      //   version,
      //   npost,
      //   prevCampaign,
      //   changes,
      //   prev_nombre_categoria: "",
      //   account:account.id,
      //   idAudience,
      //   categoriesSelected:optionSelected,
      //   searchable_name:name.toLowerCase(),
      //   searchable_idMixDetaill:idMixDetaill.toString(),
      //   searchable_version:version.toLowerCase(),
      //   searchable_medio:medio.toLowerCase(),
      //   searchable_status,
      //   newCampaing
      // }

      // console.log("updateCampaign body mysql", data)

      // localStorage.setItem("nameAWS", "");
      // localStorage.setItem("tempAWSUser", "");
      // localStorage.setItem("account", "");
    } catch (err) {
      Logger.log(TAG, "error createCampaing", err);
      dispatch(
        Modal.showAlertModal(
          "Error!",
          "Ocurrió un error al actualizar la campaña",
          Modal.MODAL_TYPE_ERROR
        )
      );
    }
    dispatch(showLoader(false));
  };

const sendUpdateCamping = async (
  campaign,
  account,
  estado,
  dispatch,
  showAlert
) => {
  console.log("sendUpdateCamping aaa", campaign);
  var fi = campaign.fechaIni.split(".");
  var nfi = fi[2] + "-" + fi[1] + "-" + fi[0];
  var ff = campaign.fechafin.split(".");
  var nff = ff[2] + "-" + ff[1] + "-" + ff[0];

  let version = " ",
    campaignName = " ";
  if (campaign.categoriesSelected) {
    campaign.categoriesSelected?.forEach((el) => {
      if (el.nombrePadre === "Campaña") {
        campaignName = el.label;
      }
      if (el.nombrePadre === "Mix") {
        version = el.label;
      }
    });
  }

  //if (campaign.idMixDetaill && campaign.idMixChange && campaign.idMix && campaign.idCampaign) {

  let digitalAsset = "";
  if (campaign.id_digital_asset) {
    const response = await APILA.getDigitalAssetById({
      id: campaign.id_digital_asset,
    });
    digitalAsset = response?.data?.digitalAsset[0]?.url || "";
  }

  const data = {
    accountInfo: {
      name: account.name,
      email: account.email,
      phone: account.phone,
      address: account.address,
    },
    id: campaign.idMixDetaill,
    name: campaignName,
    fecha_inicio: nfi,
    fecha_fin: nff,
    budget: campaign.budget,
    id_ad_product: campaign.id_ad_product,
    id_publico: campaign.id_publico,
    id_ad_status: campaign.id_ad_status,
    comments: campaign.comments,
    id_sale: campaign.id_sale,
    name_region: campaign.name_region,
    objetivo: campaign.objetivo,
    banner: campaign.banner,
    medio: campaign.medio,
    publico: campaign.publico.join(","),
    copy: campaign.copy,
    observation: campaign.observation,
    nameForEmail: campaign.nameForEmail,
    emailForEmail: campaign.emailForEmail,
    id_sale: campaign.id_sale,
    urls: "",
    version: version,
    n_post: campaign.name,
    changes: campaign.changes,
    prevCampaign:
      campaign.prevCampaign != undefined ? campaign.prevCampaign : null,
    newCampaing: campaign.newCampaing,
    creativity: campaign.creativity,
    categoriesSelected: campaign.categoriesSelected,
    nameToSend: campaign.nameToSend,
    emailToSend: campaign.emailToSend,
    emailToTag: campaign.emailToTag,
    prevTag: campaign.prevTag,
    countEdit: campaign.countEdit,
    formato: campaign.formato,
    changedInTag: campaign.changedInTag,
    priority: campaign.priority,
    approve: campaign.approve,
    accountId: account.id,
    id_digital_asset:
      campaign.id_digital_asset !== "" ? campaign.id_digital_asset : null,
    digitalAsset,
  };
  // console.log("estado", estado)
  console.log("Body A editPautlet", data);
  let title = "";
  let body = "";
  try {
    const response = await APILA.editpautalet(data);
    console.log("", response);

    if (response.data !== 777) {
      title = estado == 5 ? "Solicitud Enviada" : "Campaña editada";
      body =
        estado == 5
          ? "Su campaña fue enviada exitosamente."
          : "Su campaña fue editada exitosamente.";
      console.log("Se edito correctamente");
    }
  } catch (err) {
    title = "Error!";
    body = "Ocurrió un error al actualizar la campaña";
    console.log("Error en dashboard.actions ", err);
  }

  if (!showAlert) {
    dispatch(
      Modal.showAlertModal(title, body, Modal.MODAL_TYPE_SUCCESS, () => {})
    );
  }
  return { title, body };
};

const parseCategoriesLabel = async (
  array,
  account,
  dispatch,
  idaccount,
  padre
) => {
  const result = [];
  console.log("extraer label de categorias", array);
  if (array) {
    const categoriesCreated = array.filter((category) => !category.__isNew__);
    const categoriesToCreate = array.filter((category) => category.__isNew__);
    Logger.log(TAG, "categoriesCreated", categoriesCreated);
    Logger.log(TAG, "categoriesToCreate", categoriesToCreate);

    categoriesCreated.forEach((category) => {
      if (!category.label) {
        result.push(category);
      } else {
        result.push(category.label);
      }
    });

    for (const category of categoriesToCreate) {
      const categoryId = v4();
      if (account.id) {
        await dispatch(
          upsertCategory(categoryId, category.label, account.id, false, padre)
        );
      } else {
        await dispatch(
          upsertCategory(categoryId, category.label, idaccount, false, padre)
        );
      }

      result.push(categoryId);
    }
  }

  return result;
};
const parseCategories = async (array, account, dispatch, idaccount, padre) => {
  const result = [];
  console.log("array de categorias", array);
  // result.push(array);
  if (array) {
    const categoriesCreated = array.filter((category) => !category.__isNew__);
    const categoriesToCreate = array.filter((category) => category.__isNew__);
    Logger.log(TAG, "categoriesCreated", categoriesCreated);
    Logger.log(TAG, "categoriesToCreate", categoriesToCreate);

    categoriesCreated.forEach((category) => {
      if (!category.value) {
        result.push(category);
      } else {
        result.push(category.value);
      }
    });

    for (const category of categoriesToCreate) {
      const categoryId = v4();
      let upsertResponse;
      if (account.id) {
        upsertResponse = await dispatch(
          upsertCategory(categoryId, category.label, account.id, false, padre)
        );
      } else {
        upsertResponse = await dispatch(
          upsertCategory(categoryId, category.label, idaccount, false, padre)
        );
      }

      console.log("parseCategories upsertResponse", upsertResponse);

      result.push(categoryId);
    }
  }

  return result;
};

//!validar el proceso de sendCampToEAdvertisee
export const createCampaignTemplate =
  (campaigns, account, emailForEmail) => async (dispatch) => {
    try {
      dispatch(showLoader(true));

      const query = {
        account: account,
        campaigns: campaigns,
      };

      const response = await APILA.createPautaTransaction(query);

      dispatch(
        insertLogTemplate(
          "createCampaignTemplate insert",
          3,
          "TEMPLATES",
          "Campaña creada",
          campaigns,
          response,
          account,
          "Lets-Advertise",
          emailForEmail
        )
      );
      return response.data.campaigns.map((campaign) => campaign.id);
    } catch (error) {
      console.log("Error en la creación de campaña", error);
      dispatch(
        insertLogTemplate(
          "createCampaignTemplate",
          3,
          "TEMPLATES",
          "Error en la creación de campaña",
          {},
          error,
          account,
          "Lets-Advertise",
          emailForEmail
        )
      );
      throw new Error("Error");
    } finally {
      dispatch(showLoader(false));
    }
  };

const updateIdsCampaignsInOv = async ({
  id_mix_detail,
  budgets,
  account,
  id_budget,
}) => {
  try {
    const response = await APILA.getIdsCampaignOvs({ account });
    console.log("response", response);
    let found = budgets.find((budget) => budget.id === id_budget);
    if (found) {
      found.reserveForLovs.forEach(async (ovId) => {
        let foundOv = response?.data?.data?.find((ov) => ov.id === ovId.id);
        if (foundOv) {
          let newArrayIds = [
            ...new Set(foundOv.idsCampaign || []),
            id_mix_detail,
          ];
          await API.updateIdsCampaign({
            id: ovId.id,
            idsCampaign: newArrayIds,
          });
        }
      });
    }
  } catch (error) {
    console.log(
      "Error en la actualizacion de idsCampign en ovs (template)",
      error
    );
  }
};

const addIdsCamp = (idBudget, budgets, idPauta) => {
  const foundBudget = budgets.find((bud) => bud.id === idBudget);
  if (foundBudget && foundBudget.reserveForLovs) {
    foundBudget.reserveForLovs.forEach((id) => {
      let idsCamp = [...new Set(id?.idsCampaign || []), idPauta];
      API.updateIdsCampaign({
        id: id.id,
        idsCampaign: idsCamp,
      });
    });
  }
};

export const createCampaign =
  (
    id,
    name,
    medio,
    fechaIni,
    fechafin,
    objetivo,
    budget,
    publico,
    id_medium,
    medioo,
    id_ad_product,
    banner,
    estado,
    idBudget,
    account,
    creativity,
    id_sale,
    observation,
    copy,
    urls,
    id_region,
    name_region,
    nameForEmail,
    emailForEmail,
    version,
    npost,
    budgets,
    idAudience,
    categoriesSelected,
    formato,
    priority,
    finalEtiqueta,
    id_digital_asset,
    name_digital_asset
  ) =>
  async (dispatch) => {
    dispatch(showLoader(true));
    try {
      const bodyComplete = {
        account: account.id,
        iniDate: fechaIni.replace(/\./g, "-"),
        endDate: fechafin.replace(/\./g, "-"),
        budget: parseFloat(budget),
        appProduct: id_ad_product,
        observation: observation,
        idMedium: id_medium,
        segmentation: publico.join(", "),
        n_post: name,
        campaign_name: finalEtiqueta,
        id_priority: priority,
        medio: medio,
        medioo: medioo,
        objetivo: objetivo,
        publico: publico,
        banner: banner,
        idBudget: idBudget,
        creativity: creativity,
        copy: copy,
        id_region: id_region,
        name_region: name_region,
        nameForEmail: nameForEmail,
        emailForEmail: emailForEmail,
        idAudience: idAudience,
        categoriesSelected: categoriesSelected,
        formato: formato,
        id_digital_asset: id_digital_asset !== "" ? id_digital_asset : null,
        name_digital_asset,
      };

      console.log("bodyComplete", bodyComplete);
      const query = {
        account: account.id,
        campaigns: [bodyComplete],
      };

      await APILA.createPautaTransaction(query);
      dispatch(msgsuccess());
    } catch (error) {
      Logger.log(TAG, "error createCampaing", error);
      let detailError = `Ocurrió un error al crear la campaña`;

      if (
        error.response &&
        error.response.data &&
        error.response.data.messageError &&
        error.response.data.messageError ===
          "La actualizacion excede los limites"
      ) {
        detailError = `El presupuesto asociado no cuenta con disponible de: $${budget}`;
      }

      dispatch(
        Modal.showAlertModal("Error!", detailError, Modal.MODAL_TYPE_ERROR)
      );
      throw new Error("Error");
    } finally {
      dispatch(showLoader(false));
    }
  };
const insertBitacora = (
  idBudget,
  budget,
  available,
  account,
  name,
  email,
  data,
  action,
  priority,
  component,
  idMixDetaill
) => {
  let bodie = {
    id: v4(),
    idBudget: idBudget,
    budget: budget,
    available: available,
    date: new Date(),
    account: account.id,
    nameForEmail: name,
    emailForEmail: email,
    data: data,
    action: action,
    priority,
    component,
    idMixDetaill,
  };
};

// ? Actualizacion del creacion de campana
const sendCampToEAdvertisee = async (
  campaign,
  account,
  dispatch,
  notlogauth,
  body
) => {
  const fi = campaign.fechaIni.split(".");
  const nfi = fi[2] + "-" + fi[1] + "-" + fi[0];
  const ff = campaign.fechafin.split(".");
  const nff = ff[2] + "-" + ff[1] + "-" + ff[0];

  let mixDetail, mix, camp, mixChange;
  console.log("Este es el body desde el acction", body);
  console.log("Este es el body desde el acction", body.idMixDetaill);
  console.log("Este es el body desde el acction", body.id);
  console.log("Este es el body desde el acction", mixDetail);
  console.log("Este es el body desde el acction", mix);
  console.log("Este es el body desde el acction", camp);
  console.log("Este es el body desde el acction", mixChange);
  let version = " ",
    campaignName = "";

  if (campaign.categoriesSelected) {
    campaign.categoriesSelected?.forEach((el) => {
      if (el.nombrePadre === "Campaña") {
        campaignName = el.label;
      }
      if (el.nombrePadre === "Mix") {
        version = el.label;
      }
    });
  }

  try {
    console.log("sendCampToEAdvertise account ", account);
    let userId;
    if (notlogauth != "") {
      userId = await getUserId(account.email, campaign.nameForEmail, "Test1");
      console.log("userId", userId);
    } else {
      const userData = await Auth.currentAuthenticatedUser();
      console.log("userData ", userData);

      const email = userData.attributes.email;
      console.log(email);

      userId = await getUserId(email, "Test", "Test1");
      console.log("userId", userId);
    }

    console.log("result$", "pauta_Eadvertising" + campaign.urls);
    const data = {
      name: campaignName, //campaign.name cambia a campaign.version nombre de categoria padre por defecto
      clientAppSocial: {
        id: account.eAdvertising || 0,
        email: account.email || "",
      },
      accountInfo: {
        name: account.name,
        email: account.email,
        phone: account.phone || "",
        address: account.address || "",
      },
      appProduct: campaign.id_ad_product,
      adminAppSocialId: 509,
      monthYear: nfi,
      iniDate: nfi,
      idMedium: campaign.id_medium,
      endDate: nff,
      buget: campaign.budget,
      idSale: campaign.id_sale || 123456,
      observation: campaign.observation,
      copy: campaign.copy,
      medio: campaign.medio,
      banner: campaign.banner,
      objetivo: campaign.objetivo,
      segmentation: campaign.publico.join(","),
      urls: campaign.urls,
      payment: {
        name: "saul",
        nit: "12345678",
        address: "2 calle residencial el prado 2",
        email: "herviasg@gmail.com",
        phone: "45963958",
      },
      name_region: campaign.name_region,
      nombre_categoria: campaign.nombre_categoria,
      available: campaign.available,
      nameForEmail: campaign.nameForEmail,
      emailForEmail: campaign.emailForEmail,
      version: version,
      n_post: campaign.name,
      formato: campaign.formato,
      id_priority: body.priority,
      campaign_name: campaign.campaign_name,
    };
    console.log("sendCampToEAdvertise ", data);
    const createPautaResponse = await APILA.createpauta(data);

    mix = createPautaResponse.data.mix;
    camp = createPautaResponse.data.campaing;
    mixDetail = createPautaResponse.data.mixDetail;
    mixChange = createPautaResponse.data.mixChange;

    if (!account.eAdvertising) {
      updateAccountEAdvertising(account.id, createPautaResponse.data.idClient);
    }

    body.id = mixDetail.toString();
    body.idMix = mix;
    body.idMix = mix;
    body.idCampaign = camp;
    body.idMixChange = mixChange;
    body.idMixDetaill = mixDetail;
    body.searchable_idMixDetaill = mixDetail.toString();
    let cadena = body.observation;
    cadena = cadena.split("-----");
    body.observation = cadena[0];

    console.log("body to AWS", body);
    await APILA.createCampaign(body);
    dispatch(clearFiles());
    /* window.location.reload(true); */

    dispatch(addCampaign(body));
    return mixDetail.toString();
  } catch (err) {
    console.log(err);
    Logger.log(TAG, "error createCampaing", err);
    dispatch(
      Modal.showAlertModal(
        "Error!",
        "Ocurrió un error al crear la campaña",
        Modal.MODAL_TYPE_ERROR
      )
    );
  }

  dispatch(showLoader(false));
};

export const createCampaignup =
  (
    name,
    medio,
    status,
    fechaIni,
    fechafin,
    objetivo,
    budget,
    category,
    publico,
    id_medium,
    id_ad_product,
    banner,
    user,
    observations,
    copy,
    id_region,
    name_region,
    email,
    emailName,
    accountName,
    step,
    card
  ) =>
  async (dispatch) => {
    dispatch(showLoader(true));

    let catFacher = [];
    let titulo;
    let mensaje = "Su campaña fue creada exitosamente";
    let now = Date.now();
    let idaccount = v4();
    let idcampaign = v4();
    console.log("createCampaignup creando");
    console.log("createCampaignup step ", step);
    console.log("createCampaignup card ", card);

    try {
      if (user) {
        let account = {
          id: idaccount,
          email,
          name,
          address: "Ciudad",
          step,
        };

        // step 1 , no tiene cuenta activa y no tiene tarjetas asociadas
        // step 2 , tiene cuenta activa y no tiene tarjeta asociadas
        // step 3 , no tiene cuenta activa y tiene tarjetas asociadas
        // step 4 , tiene cuenta activa y tarjetas asociadas
        let body = {
          id: idaccount,
          name: accountName,
          members: [], //members
          status: true,
          updateAt: now,
          client: user,
          credit: 0,
          createAt: now,
          step,
        };

        let accountResponse = await API.createAccount(body);
        console.log("accountResponse response", accountResponse);
        if (accountResponse.status == 200) {
          if (card) {
            card.client = idaccount;
            const isdone = addpaymentup(card);
            if (!isdone) {
              return;
            } else {
              mensaje =
                "Su campaña fue creada exitosamente y se agrego su metodo de pago";
            }
          }

          let defaultReponse = await APILA.getDefaultCategories(body);
          console.log("default Response", defaultReponse);
          let defaultCategories = defaultReponse.data.categories;
          let categoriaPadre = defaultCategories.filter((item) => {
            return item.padre == 0;
          });

          for (let i = 0; i < categoriaPadre.length; i++) {
            let idcate = v4();
            let bodycat = {
              id: idcate,
              idAWS: categoriaPadre[i].id,
            };
            catFacher.push(bodycat);
            await dispatch(
              upsertCategory(
                idcate,
                categoriaPadre[i].name,
                idaccount,
                false,
                "97bc9ee5-36a0-455b-974c-fa7832c9c341",
                true
              )
            );
          }

          console.log("catFacher ", catFacher);
          dispatch(showLoader(true));
          for (let y = 0; y < catFacher.length; y++) {
            let filtered = defaultCategories.filter((cat) => {
              return cat.padre == catFacher[y].idAWS;
            });
            for (let z = 0; z < filtered.length; z++) {
              let idcat = v4();
              let now = Date.now();
              const bodycat = {
                id: idcat,
                name: filtered[z].name,
                account: "prueba",
                padre: catFacher[y].id,
                tipo: "prueba",
                updateAt: now,
                createAt: now,
                active: true,
              };
              await dispatch(
                upsertCategory(
                  idcat,
                  filtered[z].name,
                  idaccount,
                  false,
                  catFacher[y].id,
                  true
                )
              );
            }
          }
          dispatch(showLoader(false));
          //#region body cat
          let idcat = v4();
          const catRequest = {
            id: idcat,
            name: "Categoria general",
            account: idaccount,
            padre: "97bc9ee5-36a0-455b-974c-fa7832c9c341",
            tipo: "padre",
          };
          //#endregion

          //#region presupuesto
          let idbudget = v4();
          let reqBuget = {
            id: idbudget,
            title: "Presupuesto general " + name,
            amount: budget,
            account: idaccount,
            status: 1,
            available: budget,
            createAt: now,
            updateAt: now,
          };

          //#endregion
          //#region body audience
          const tagBody = {
            data: publico,
          };
          let idAudience = v4();
          const bodyAudience = {
            id: idAudience,
            name: name + " publico",
            account: idaccount,
            tags: tagBody,
          };

          let categoriesnames = [];
          let categoriesid = [];
          //#endregion
          for (const cat of category) {
            const categoryId = v4();
            categoriesid.push(categoryId);
            categoriesnames.push(cat.label);
            await dispatch(
              upsertCategory(
                categoryId,
                cat.label,
                idaccount,
                false,
                "97bc9ee5-36a0-455b-974c-fa7832c9c341",
                true
              )
            );
          }
          console.log("Categories name", categoriesnames);
          console.log("Categories id", categoriesid);
          // const categorieslabel= await parseCategoriesLabel(category, "", dispatch,idaccount,"")
          // const categories = await parseCategories(category, "", dispatch,idaccount,"97bc9ee5-36a0-455b-974c-fa7832c9c341	")
          // Logger.log('categorieslabel', categorieslabel)
          // Logger.log('categoriesvalues', categories)

          // let [responseCat, responseBudget,responseAudience] = await Promise.all([API.createCategory(catRequest), API.createBudget(reqBuget),
          //   await API.createAudience(bodyAudience)]);
          let [responseBudget, responseAudience] = await Promise.all([
            API.createBudget(reqBuget),
            await API.createAudience(bodyAudience),
          ]);

          // console.log("responseCat response " , responseCat)
          console.log("responseBudget response ", responseBudget);
          console.log("responseAudience   response ", responseAudience);
          let version, npost;
          if (category.length > 1) {
            version = categoriesnames[1];
            npost = categoriesnames[0];
          } else {
            version = categoriesnames[0];
            npost = "Categoria por defecto";
          }
          const bodyCampaingAWS = {
            id: idcampaign,
            name,
            medio,
            status,
            fechaIni,
            fechafin,
            objetivo,
            budget,
            category: categoriesid,
            publico,
            id_medium,
            medioo: medio,
            id_ad_product,
            banner,
            idBudget: idbudget,
            account: idaccount,
            creativity: [],
            id_sale: 0,
            observation: observations,
            copy,
            id_region,
            name_region,
            nameForEmail: emailName,
            emailForEmail: email,
            version,
            npost,
            available: 0,
            searchable_name: name.toLowerCase(),
            searchable_version: version.toLowerCase(),
          };
          console.log("bodyCampaingAWS ", bodyCampaingAWS);
          const responseAWS = await APILA.createCampaign(bodyCampaingAWS);
          const body_eadvertise = {
            id: idcampaign,
            name, // nombre categoria padre
            medio,
            status,
            fechaIni,
            fechafin,
            objetivo,
            budget,
            category: categoriesid,
            publico,
            id_medium,
            medioo: medio,
            id_ad_product,
            banner,
            idBudget: idbudget,
            account: idaccount,
            creativity: [],
            id_sale: 41545543,
            observation: observations,
            copy,
            urls: "",
            id_region,
            name_region,
            nombre_categoria: categoriesnames,
            available: 0,
            nameForEmail: emailName,
            emailForEmail: email,
            version,
            npost,
            searchable_name: name.toLowerCase(),
            searchable_version: version.toLowerCase(),
          };
          localStorage.setItem("account", idaccount);
          dispatch(showLoader(false));
          console.log("body_eadvertise HTML", body_eadvertise);
          // sendCampToEAdvertise(body_eadvertise, account, dispatch,true)
        }
      }
    } catch (err) {
      dispatch(showLoader(false));
      console.log("ERROR, dashboard.actions createCampaignup ", err);
    }

    // const body = {
    //   id,
    //   name,
    //   medio,
    //   status: estado,
    //   fechaIni,
    //   fechafin,
    //   objetivo,
    //   budget,
    //   category: categories,
    //   publico,
    //   id_medium,
    //   medioo,
    //   id_ad_product,
    //   banner,
    //   idBudget,
    //   account: account.id,
    //   creativity,
    //   id_sale,
    //   observation,
    //   copy,
    //   id_region,
    //   name_region,
    //   nameForEmail,
    //   emailForEmail,
    //   version,
    //   npost
    // }

    // Logger.log(TAG, 'body', body)

    // try {
    //   const response = await APILA.createCampaign(body)

    //   Logger.log(TAG, 'createdCampaing', response);
    //   console.log('availableAmount createCampaign', availableAmount);
    //   console.log('budget createCampaign', publico);
    //     let ava=availableAmount - budget
    //     if(ava===0){
    //       ava="0"
    //     }
    //     let available= availableAmount - budget

    //   if (estado === 1) {
    //     let obbser = observation//observation+" ----- "+copy
    //     if(urls !== ""){
    //       obbser = obbser
    //     }
    //     const body_eadvertise = {
    //       id,
    //       name, // nombre categoria padre
    //       medio,
    //       status: estado,
    //       fechaIni,
    //       fechafin,
    //       objetivo,
    //       budget,
    //       category: categories,
    //       publico,
    //       id_medium,
    //       medioo,
    //       id_ad_product,
    //       banner,
    //       idBudget,
    //       account: account.id,
    //       creativity,
    //       id_sale,
    //       observation:obbser,
    //       copy,
    //       urls,
    //       id_region,
    //       name_region,
    //       nombre_categoria: categorieslabel,
    //       available,
    //       nameForEmail,
    //       emailForEmail,
    //       version,
    //       npost
    //     }
    //     console.log("body_eadvertise HTML",body_eadvertise)
    //     sendCampToEAdvertise(body_eadvertise, account, dispatch)
    //   }

    //   dispatch(getBudgets(account.id))
    // } catch (err) {
    //   Logger.log(TAG, 'error createCampaing', err)
    //   dispatch(
    //     Modal.showAlertModal(
    //       'Error!',
    //       'Ocurrió un error al crear la campaña',
    //       Modal.MODAL_TYPE_ERROR
    //     )
    //   )
    // }
    dispatch(
      Modal.showAlertModal(
        "Pauta creada",
        mensaje,
        Modal.MODAL_TYPE_SUCCESS,
        () => {}
      )
    );
    dispatch(showLoader(false));
  };

async function addpaymentup(card) {
  console.log("addpayment paymentbody ", card);

  try {
    const responseAccount = await APILA.upsertPaymentInfo(card);
    console.log("addpayment paymentbody", responseAccount);
    if (responseAccount.status == 200) {
      return true;
    } else {
      return false;
    }
  } catch {
    return false;
  }
}

export const createAccountModal = (awsbody, account) => async (dispatch) => {
  dispatch(showLoader(true));
  const prevStep = account.step;
  let newStep;
  if (prevStep == 1) {
    newStep = 2;
  }
  // if(prevStep==2){
  //   newStep=2;
  // }

  if (prevStep == 3) {
    newStep = 4;
  }

  // step 1 , no tiene cuenta activa y no tiene tarjetas asociadas
  // step 2 , tiene cuenta activa y no tiene tarjeta asociadas
  // step 3 , no tiene cuenta activa y tiene tarjetas asociadas
  // step 4 , tiene cuenta activa y tarjetas asociadas

  console.log("createAccountModal awsbody ", awsbody);
  console.log("createAccountModal account ", account);
  try {
    let user = await Auth.signUp({
      username: awsbody.username,
      password: awsbody.password,
      attributes: {
        name: awsbody.name,
        email: awsbody.username,
        // phone_number: phoneValue,
        // other custom attributes
        "custom:country": awsbody.country,
        "custom:phone": awsbody.phone,
      },
    });
    console.log("createAccountModal awsresponse ", user);
    if (user.userSub) {
      account.step = newStep;
      account.client = user.userSub;
      account.email = awsbody.username;
      console.log("createAccountModal ", account);
      const responseAccount = await APILA.updateAccount(account);
      console.log("createAccountModal responseAccount", responseAccount);
      // localStorage.setItem("tempAWSUser", "");
      // localStorage.setItem("account", "");
      dispatch(
        Modal.showAlertModal(
          "Cuenta creada",
          "Su cuenta fue creada exitosamente",
          Modal.MODAL_TYPE_SUCCESS,
          () => {}
        )
      );
    }
  } catch (err) {
    let { message } = err;
    dispatch(
      Modal.showAlertModal("Error", message, Modal.MODAL_TYPE_SUCCESS, () => {})
    );
  }
};

export const addpayment = (paymentbody, account) => async (dispatch) => {
  dispatch(showLoader(true));
  // const prevStep=account.step;
  let newStep;
  if (account.step == 1) {
    newStep = 3;
  }
  if (account.step == 2) {
    newStep = 4;
  }
  if (account.step == 3) {
    newStep = 4;
  }

  // step 1 , no tiene cuenta activa y no tiene tarjetas asociadas
  // step 2 , tiene cuenta activa y no tiene tarjeta asociadas
  // step 3 , no tiene cuenta activa y tiene tarjetas asociadas
  // step 4 , tiene cuenta activa y tarjetas asociadas

  console.log("addpayment paymentbody ", paymentbody);
  console.log("addpayment account ", account);

  try {
    const responsePayment = await APILA.upsertPaymentInfo(paymentbody);
    console.log("addpayment paymentbody", responsePayment);
    if (responsePayment.status == 200) {
      account.step = newStep;
      console.log("addpayment account ", account);
      const responseAccount = await APILA.updateAccount(account);
      console.log("addpayment responseAccount ", responseAccount);
    }
    // localStorage.setItem("tempAWSUser", "");
    // localStorage.setItem("account", "");
    dispatch(
      Modal.showAlertModal(
        "Tarjeta registrada",
        "Su tarjeta fue registrada exitosamente",
        Modal.MODAL_TYPE_SUCCESS,
        () => {}
      )
    );
  } catch (err) {
    let { message } = err;
    dispatch(
      Modal.showAlertModal("Error", message, Modal.MODAL_TYPE_SUCCESS, () => {})
    );
  }

  dispatch(showLoader(false));
};

const getUserId = async (email, firstName, lastName) => {
  // const data = {
  //   phone: '0',
  //   email: email,
  //   username: email,
  //   rol: 'ROLE_ADMIN',
  //   lastName: lastName,
  //   password: 'Passw0rd',
  //   firstName: firstName,
  // }

  // try {
  //   const response = await APILA.findUserByEmail(data)
  //   if (response.data.existe) {
  //     console.log(response.data.idUser)
  //     return response.data.idUser
  //   } else {
  //     const res = await APILA.newUser(data)
  //     return res.data.user
  //   }
  // } catch (err) {
  //   // this.showError()
  //   console.log(err)
  //   return err
  // }
  return 509;
};

// function createUserAWS(req) {
//   console.log("createCampaignup createUserAWS")

//   let promesa = new Promise((resolve, reject) => {
//     let dat={
//       username: "dguerrerotpp@gmail.com",
//       password: "Entraya1!",
//       attributes: {
//         name: "Diego Guerrero",
//         email: "dguerrerotpp@gmail.com",
//         // phone_number: phoneValue,
//         // other custom attributes
//         'custom:country': "Guatemala",
//         'custom:phone': "41545543",
//       },
//     }

//     Auth.signUp(dat, (err, data) => {
//       if(err){
//         reject(err)
//       }else{
//         console.log("createUserAWS response" , data)
//         resolve(data)
//       }
//     })

//   })
//   console.log("FIN createUserAWS")
//   return promesa;
// }
const sendCampToEAdvertise = async (
  campaign,
  account,
  dispatch,
  notlogauth
) => {
  const fi = campaign.fechaIni.split(".");
  const nfi = fi[2] + "-" + fi[1] + "-" + fi[0];
  const ff = campaign.fechafin.split(".");
  const nff = ff[2] + "-" + ff[1] + "-" + ff[0];

  let mixDetail, mix, camp, mixChange;
  let version = " ",
    campaignName = " ";
  console.log("Se actualizo algo", campaign);
  campaign.categoriesSelected?.forEach((el) => {
    if (el.nombrePadre === "Campaña") {
      campaignName = el.label;
    }
    if (el.nombrePadre === "Mix") {
      version = el.label;
    }
  });

  try {
    console.log("sendCampToEAdvertise account ", account);
    let userId;
    if (notlogauth) {
      userId = await getUserId(account.email, campaign.nameForEmail, "Test1");
      console.log("userId", userId);
    } else {
      const userData = await Auth.currentAuthenticatedUser();
      console.log("userData ", userData);

      const email = userData.attributes.email;
      console.log(email);

      userId = await getUserId(email, "Test", "Test1");
      console.log("userId", userId);
    }

    console.log("result$", "pauta_Eadvertising" + campaign.urls);
    const data = {
      name: campaignName, //campaign.name cambia a campaign.version nombre de categoria padre por defecto
      clientAppSocial: {
        id: account.eAdvertising || 0,
        email: account.email || "",
      },
      accountInfo: {
        name: account.name,
        email: account.email,
        phone: account.phone || "",
        address: account.address || "",
      },
      appProduct: campaign.id_ad_product,
      adminAppSocialId: 509,
      monthYear: nfi,
      iniDate: nfi,
      endDate: nff,
      buget: campaign.budget,
      idSale: campaign.id_sale || 123456,
      observation: campaign.observation,
      copy: campaign.copy,
      medio: campaign.medio,
      banner: campaign.banner,
      objetivo: campaign.objetivo,
      segmentation: campaign.publico.join(","),
      urls: campaign.urls,
      formato: campaign.formato,
      changedInTag: campaign.changedInTag,
      payment: {
        name: "saul",
        nit: "12345678",
        address: "2 calle residencial el prado 2",
        email: "herviasg@gmail.com",
        phone: "45963958",
      },
      name_region: campaign.name_region,
      nombre_categoria: campaign.nombre_categoria,
      available: campaign.available,
      nameForEmail: campaign.nameForEmail,
      emailForEmail: campaign.emailForEmail,
      version: version,
      n_post: campaign.name,
      creativity: campaign.creativity,
      id_priority: campaign.priority,
    };
    console.log("sendCampToEAdvertise ", data);
    const createPautaResponse = await APILA.createpauta(data);

    mix = createPautaResponse.data.mix;
    camp = createPautaResponse.data.campaing;
    mixDetail = createPautaResponse.data.mixDetail;
    mixChange = createPautaResponse.data.mixChange;

    if (!account.eAdvertising) {
      updateAccountEAdvertising(account.id, createPautaResponse.data.idClient);
    }

    campaign.idMix = mix;
    campaign.idMix = mix;
    campaign.idCampaign = camp;
    campaign.idMixChange = mixChange;
    campaign.idMixDetaill = mixDetail;
    campaign.searchable_idMixDetaill = mixDetail.toString();
    let cadena = campaign.observation;
    cadena = cadena.split("-----");
    campaign.observation = cadena[0];

    await APILA.updateCampaign(campaign);

    dispatch(clearFiles());

    /*  window.location.reload(true); */
    dispatch(addCampaign(campaign));
  } catch (err) {
    console.log(err);
    Logger.log(TAG, "error createCampaing", err);
    dispatch(
      Modal.showAlertModal(
        "Error!",
        "Ocurrió un error al crear la campaña",
        Modal.MODAL_TYPE_ERROR
      )
    );
  }

  dispatch(showLoader(false));
};

const sendCamptFinalizedEadvertise = async (campaign, account, dispatch) => {
  var fi = campaign.fechaIni.split(".");
  var nfi = fi[2] + "-" + fi[1] + "-" + fi[0];
  var ff = campaign.fechafin.split(".");
  var nff = ff[2] + "-" + ff[1] + "-" + ff[0];

  let version = " ",
    campaignName = " ";
  if (campaign.categoriesSelected) {
    campaign.categoriesSelected?.forEach((el) => {
      if (el.nombrePadre === "Campaña") {
        campaignName = el.label;
      }
      if (el.nombrePadre === "Mix") {
        version = el.label;
      }
    });
  }

  //if (campaign.idMixDetaill && campaign.idMixChange && campaign.idMix && campaign.idCampaign) {

  const data = {
    accountInfo: {
      name: account.name,
      email: account.email,
      phone: account.phone,
      address: account.address,
    },
    id: campaign.idMixDetaill,
    name: campaignName,
    fecha_inicio: nfi,
    fecha_fin: nff,
    budget: campaign.budget,
    id_ad_product: campaign.id_ad_product,
    id_publico: campaign.id_publico,
    id_ad_status: campaign.id_ad_status,
    comments: campaign.comments,
    id_sale: campaign.id_sale,
    name_region: campaign.name_region,
    objetivo: campaign.objetivo,
    banner: campaign.banner,
    medio: campaign.medio,
    publico: campaign.publico.join(","),
    copy: campaign.copy,
    observation: campaign.observation,
    nameForEmail: campaign.nameForEmail,
    emailForEmail: campaign.emailForEmail,
    id_sale: campaign.id_sale,
    urls: "",
    version: version,
    n_post: campaign.name,
    changes: campaign.changes,
    prevCampaign:
      campaign.prevCampaign != undefined ? campaign.prevCampaign : null,
    newCampaing: campaign.newCampaing,
    creativity: campaign.creativity,
    categoriesSelected: campaign.categoriesSelected,
    nameToSend: campaign.nameToSend,
    emailToSend: campaign.emailToSend,
    emailToTag: campaign.emailToTag,
    prevTag: campaign.prevTag,
    countEdit: campaign.countEdit,
    formato: campaign.formato,
  };
  console.log("Body A editPautlet", data);
  let title = "";
  let body = "";
  try {
    const response = await APILA.editFinal(data);
    console.log("RESEDITPAUTALET", response);
    /*  let response
      fetch('http://localhost:3004/backend/letsadvertise/editpautalet',{
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
      })
      .then(response => response.json())
      .then(data => console.log('RESEDITPAUTLET',data)); */

    if (response.data !== 777) {
      title = "Solicitud Enviada";
      body = "Su campaña fue enviado exitosamente.";
      console.log("Se edito correctamente");
      /* window.location.reload(true); */
    }
  } catch (err) {
    title = "Error!";
    body = "Ocurrió un error al actualizar la campaña";
    console.log("Error en dashboard.actions ", err);
  }

  dispatch(
    Modal.showAlertModal(title, body, Modal.MODAL_TYPE_SUCCESS, () => {})
  );
};

export const aproveCampaign =
  (mixChange, account, filter) => async (dispatch) => {
    dispatch(showLoader(true));
    try {
      const userData = await Auth.currentAuthenticatedUser();

      const email = userData.attributes.email;
      console.log(email);

      const userId = await getUserId(email, "Test", "Test1");

      const body = {
        id: mixChange,
        idUser: userId,
      };

      const reviewResponse = await APILA.review(body);

      const bod = {
        id: reviewResponse.data.mix_change,
        idUser: userId,
      };

      await APILA.aprobe(bod);

      dispatch(
        Modal.showAlertModal(
          "Pauta Aprobada",
          "Su pauta fue aprobada exitosamente.",
          Modal.MODAL_TYPE_SUCCESS,
          async () => {
            await APILA.updatePautasLets();
            dispatch(getCampaigns(account, filter));
          }
        )
      );
    } catch (err) {
      Logger.log(TAG, "error aproveCampaign", err);
      dispatch(
        Modal.showAlertModal(
          "Error!",
          "Ocurrió un error al aprobar la pauta",
          Modal.MODAL_TYPE_ERROR
        )
      );
    }

    dispatch(showLoader(false));
  };

const updateAccountEAdvertising = async (id, eAdvertising) => {
  const body = {
    id,
    eAdvertising,
  };

  try {
    const response = await API.updateAccount(body);
    Logger.log(TAG, "updateAccount", response);
  } catch (err) {
    Logger.log(TAG, "error updateAccount", err);
  }
};

export const getCampaigns =
  (account, filter, user, idmod, mod, idclient) => async (dispatch) => {
    dispatch(showLoader(true));
    dispatch(changePaginationLoading(true));
    dispatch(clearCampaigns());
    console.log("getCampaigns account", account);
    console.log("getCampaigns filter", filter);
    console.log("getCampaigns user", user);
    console.log("getCampaigns mod", mod);
    console.log("getCampaigns idclient", idclient);
    try {
      let limit = 2000;
      let responseMysql;
      let countMysql;
      let fechaFin = "2022/03/29";
      let fechaIni = "2021/03/03";
      let mysqlCampaings;
      const response = await APILA.getCampaigns({ account, ...filter });
      console.log(
        "getCampaigns log",
        account,
        "getCampaings",
        { account, ...filter },
        response.data,
        user,
        "Trae todas las cuentas, /campaign/myCampaigns"
      );

      await insertLog(
        account,
        "getCampaings",
        { account, ...filter },
        response.data,
        user,
        "Trae todas las cuentas, /campaign/myCampaigns",
        idmod,
        mod
      );
      if (idclient) {
        responseMysql = await APILA.getMyCampaings({
          account,
          limit,
          idclient,
          fechaFin,
          fechaIni,
        });
        countMysql = responseMysql.data.count;
        mysqlCampaings = responseMysql.data.campaigns;
      }

      Logger.log(TAG, "getCampaings", response);
      // Logger.log(TAG, 'getMyCampaings', responseMysql)
      const { campaigns, count } = response.data;

      // mysqlCampaings  = undefined

      let newCampaingsMerged = [];

      if (campaigns && mysqlCampaings) {
        console.log("Haciendo merge");
        mysqlCampaings.forEach((element) => {
          console.log("element ", element);

          let found = campaigns.find((aws) => aws.idMixDetaill == element.id);
          console.log("found", found);
          if (found) {
            let body = {
              copy: found.copy,
              observation: found.observation,
              category: found.category,
              editable: true,
              fechaIni: found.fechaIni,
              ctr: element.ctr,
              cpr: element.cpr,
              status: element.idStatus,
              objetivo: found.objetivo,
              video_playbacks_75: element.reproducciones_video_75,
              cpv: element.cpv,
              country: element.country,
              //"fr": 6927,
              searchable_status: found.searchable_status,
              idBudget: found.idBudget,
              searchable_fechafin: found.searchable_fechafin,
              adv: element.duracion_promedio_video,
              searchable_medio: found.searchable_medio,
              id_ad_product: found.id_ad_product,
              postNumber: found.postNumber,
              net_budget: element.presupuesto_neto,
              // "real_price": 0.47,
              searchable_idMixDetaill: found.searchable_idMixDetaill,
              searchable_name: found.searchable_name,
              reach: element.reach,
              account: found.account,
              event_responses: element.respuesta_eventos,
              postShares: element.post_shares,
              photo: element.visualizacion_fotos,
              video_playbacks_50: element.reproducciones_video_50,
              spent_percent: element.porcentaje_gastado,
              budget: element.presupuesto_neto, // presupuesto neto
              budgetI: element.presupuesto,
              banner: found.banner,
              fechafin: found.fechafin,
              id_medium: found.id_medium,
              idMixDetaill: found.idMixDetaill,
              // "cnr": 0,
              searchable_fechaIni: found.searchable_fechaIni,
              idMix: found.idMix,
              video_playbacks_95: element.reproducciones_video_95,
              url: element.url,
              name: found.name,
              idCampaign: found.idCampaign,
              searchable_version: found.searchable_version,
              clicks: element.clicks,
              purchase_unit: element.tipo_compra,
              medioo: found.medioo,
              prints: element.impresiones,
              id: found.id,
              publico: found.publico,
              categoriesSelected: found.categoriesSelected,
              idAudience: found.idAudience,
              swc: element.gastado_con_comision,
              segmentation: element.segmentacion,
              // "age": "+18",
              leads: element.leads,
              version: found.version,
              available: element.presupuesto_disponible,
              budgetSinConsumir: element.presupuesto_disponible,
              postComments: element.post_comments,
              installs: element.instalacion_aplicaciones,
              frequency: element.frecuencia,
              modification_date: found.modification_date,
              views: element.views,
              // "daily_budget": 0,
              video_playbacks_25: element.reproducciones_video_25,
              cpa: element.cpa,
              cpc: element.cpc,
              client: element.cliente,
              post_likes: element.post_likes,
              medio: found.medio,
              idMixChange: found.idMixChange,
              cpf: element.cpf,
              cpi: element.cpi,
              // "price": 0.5,
              fans: element.fans,
              cpm: element.cpm,
              cpl: element.cpl,
              budgetConsumido: element.presupuesto_gastado,
              budgetI: element.presupuesto,
              budgetSinConsumir: element.presupuesto_disponible,
              id_sale: found.id_sale,
            };
            newCampaingsMerged.push(body);
          } else {
            let body = {
              objetivo: element.objetivo_pauta,
              id_sale: element.orden_venta,
              editable: false,
              fechaIni: element.fecha_inicio,
              ctr: element.ctr,
              cpr: element.cpr,
              status: element.idStatus,
              video_playbacks_75: element.reproducciones_video_75,
              cpv: element.cpv,
              country: element.country,
              //"fr": 6927,
              searchable_status: "",
              idBudget: 0,
              searchable_fechafin: "",
              id_medium: element.id_medium,
              medioo: element.medio,
              medio: element.medio,
              adv: element.duracion_promedio_video,
              searchable_medio: "",
              id_ad_product: element.id_ad_product,
              postNumber: "",
              idMixChange: element.idMixChange,
              idCampaign: element.idCampaign,
              net_budget: element.presupuesto_neto,
              // "real_price": 0.47,
              searchable_idMixDetaill: "",
              searchable_name: "",
              reach: element.reach,
              account: "",
              event_responses: element.respuesta_eventos,
              postShares: element.post_shares,
              photo: element.visualizacion_fotos,
              video_playbacks_50: element.reproducciones_video_50,
              spent_percent: element.porcentaje_gastado,
              budget: element.presupuesto,
              banner: element.banner,
              fechafin: element.fecha_fin,
              idMixDetaill: element.id,
              // "cnr": 0,
              searchable_fechaIni: "",
              idMix: element.idMix,
              video_playbacks_95: element.reproducciones_video_95,
              url: element.url,
              name: element.campaign,
              searchable_version: "",
              clicks: element.clicks,
              purchase_unit: element.tipo_compra,

              prints: element.impresiones,
              id: element.id,
              publico: [],
              swc: element.gastado_con_comision,
              segmentation: element.segmentacion,
              // "age": "+18",
              leads: element.leads,
              version: "",
              budgetSinConsumir: element.presupuesto_disponible,
              available: element.presupuesto_disponible,
              postComments: element.post_comments,
              installs: element.instalacion_aplicaciones,
              frequency: element.frecuencia,
              modification_date: "",
              views: element.views,
              // "daily_budget": 0,
              video_playbacks_25: element.reproducciones_video_25,
              cpa: element.cpa,
              cpc: element.cpc,
              client: element.cliente,
              post_likes: element.post_likes,
              medio: element.medio,
              idMixChange: element.idMixChange,
              cpf: element.cpf,
              cpi: element.cpi,
              // "price": 0.5,
              fans: element.fans,
              cpm: element.cpm,
              cpl: element.cpl,
              budgetConsumido: element.presupuesto_gastado,
              budgetI: element.presupuesto,
              budgetSinConsumir: element.presupuesto_disponible,
            };
            newCampaingsMerged.push(body);
          }
        });

        newCampaingsMerged.filter((campaign) => {
          /*CAMPAIGNS_ADD_ONE */

          dispatch(addCampaign(campaign));
        });
        dispatch(changePaginationTotal(countMysql));
      } else {
        console.log("por default");
        campaigns.filter((campaign) => {
          campaign.editable = true;
          /*CAMPAIGNS_ADD_ONE */
          dispatch(addCampaign(campaign));
        });
        dispatch(changePaginationTotal(count));
      }
    } catch (err) {
      Logger.log(TAG, "error getCampaigns", err);
    }
    dispatch(changePaginationLoading(false));
    dispatch(showLoader(false));
  };

export const getCampaignsSearch =
  (account, name, filter, statusFilter, eAdvertising) => async (dispatch) => {
    dispatch(showLoader(true));
    dispatch(changePaginationLoading(true));
    dispatch(clearCampaigns());
    try {
      let mysqlCampaings;

      let response;
      // console.log(response2)
      console.log("account ", account);
      if (statusFilter == 1) {
        console.log("name ", name);
        let dates = name.split("|");
        let fechaI = dates[0].split("/");
        let fechaF = dates[1].split("/");
        let fechaIni = fechaI[2] + "/" + fechaI[1] + "/" + fechaI[0];
        let fechaFin = fechaF[2] + "/" + fechaF[1] + "/" + fechaF[0];
        name = fechaIni + "|" + fechaFin;
        let idclient = eAdvertising;
        response = await APILA.getCampaignsSearch({
          statusFilter,
          account,
          name,
          ...filter,
        });
        const response2 = await APILA.findCampaingsByFilters({
          statusFilter,
          account,
          name,
          fechaIni,
          fechaFin,
          idclient,
          ...filter,
        });
        console.log("response2 ", response2);
        mysqlCampaings = response2.data;
      } else {
        response = await APILA.getCampaignsSearch({
          statusFilter,
          account,
          name,
          ...filter,
        });
      }
      const { campaigns, count } = response.data;

      Logger.log(TAG, "getCampaignsSearch", response);
      console.log("mysqlCampaings ", mysqlCampaings);
      let newCampaingsMerged = [];
      let presupuInvertido = 0;
      let presupuNeto = 0;
      let presupuConsumido = 0;
      let presupuestoSinConsumir = 0;
      let presupuestoConComision = 0;
      if (campaigns && mysqlCampaings) {
        mysqlCampaings.forEach((element) => {
          console.log("element ", element);

          let found = campaigns.find((aws) => aws.idMixDetaill == element.id);
          console.log("found", found);
          if (found) {
            let body = {
              editable: true,
              fechaIni: found.fechaIni,
              ctr: element.ctr,
              cpr: element.cpr,
              status: 12,
              video_playbacks_75: element.reproducciones_video_75,
              cpv: element.cpv,
              country: element.country,
              //"fr": 6927,
              searchable_status: found.searchable_status,
              idBudget: found.idBudget,
              searchable_fechafin: found.searchable_fechafin,
              adv: element.duracion_promedio_video,
              searchable_medio: found.searchable_medio,
              id_ad_product: found.id_ad_product,
              postNumber: found.postNumber,
              net_budget: element.presupuesto_neto,
              // "real_price": 0.47,
              searchable_idMixDetaill: found.searchable_idMixDetaill,
              searchable_name: found.searchable_name,
              reach: element.reach,
              account: found.account,
              event_responses: element.respuesta_eventos,
              postShares: element.post_shares,
              photo: element.visualizacion_fotos,
              video_playbacks_50: element.reproducciones_video_50,
              spent_percent: element.porcentaje_gastado,
              budget: element.presupuesto_neto, // presupuesto neto
              budgetI: element.presupuesto,
              banner: found.banner,
              fechafin: found.fechafin,
              id_medium: found.id_medium,
              idMixDetaill: found.idMixDetaill,
              // "cnr": 0,
              searchable_fechaIni: found.searchable_fechaIni,
              idMix: found.idMix,
              video_playbacks_95: element.reproducciones_video_95,
              url: element.url,
              name: found.name,
              idCampaign: found.idCampaign,
              searchable_version: found.searchable_version,
              clicks: element.clicks,
              purchase_unit: element.tipo_compra,
              medioo: found.medioo,
              prints: element.impresiones,
              id: found.id,
              publico: found.publico,
              swc: element.gastado_con_comision,
              segmentation: element.segmentacion,
              // "age": "+18",
              leads: element.leads,
              version: found.version,
              available: element.presupuesto_disponible,
              budgetSinConsumir: element.presupuesto_disponible,
              postComments: element.post_comments,
              installs: element.instalacion_aplicaciones,
              frequency: element.frecuencia,
              modification_date: found.modification_date,
              views: element.views,
              // "daily_budget": 0,
              video_playbacks_25: element.reproducciones_video_25,
              cpa: element.cpa,
              cpc: element.cpc,
              client: element.cliente,
              post_likes: element.post_likes,
              medio: found.medio,
              idMixChange: found.idMixChange,
              cpf: element.cpf,
              cpi: element.cpi,
              // "price": 0.5,
              fans: element.fans,
              cpm: element.cpm,
              cpl: element.cpl,
              budgetConsumido: element.presupuesto_gastado,
              budgetI: element.presupuesto,
              budgetSinConsumir: element.presupuesto_disponible,
            };
            newCampaingsMerged.push(body);
          } else {
            let body = {
              editable: false,
              fechaIni: "",
              ctr: element.ctr,
              cpr: element.cpr,
              status: 12,
              video_playbacks_75: element.reproducciones_video_75,
              cpv: element.cpv,
              country: element.country,
              //"fr": 6927,
              searchable_status: "",
              idBudget: 0,
              searchable_fechafin: "",
              adv: element.duracion_promedio_video,
              searchable_medio: "",
              id_ad_product: 0,
              postNumber: "",
              net_budget: element.presupuesto_neto,
              // "real_price": 0.47,
              searchable_idMixDetaill: "",
              searchable_name: "",
              reach: element.reach,
              account: "",
              event_responses: element.respuesta_eventos,
              postShares: element.post_shares,
              photo: element.visualizacion_fotos,
              video_playbacks_50: element.reproducciones_video_50,
              spent_percent: element.porcentaje_gastado,
              budget: element.presupuesto,
              banner: element.banner,
              fechafin: "",
              id_medium: 0,
              idMixDetaill: element.id,
              // "cnr": 0,
              searchable_fechaIni: "",
              idMix: 0,
              video_playbacks_95: element.reproducciones_video_95,
              url: element.url,
              name: element.campaign,
              idCampaign: 0,
              searchable_version: "",
              clicks: element.clicks,
              purchase_unit: element.tipo_compra,
              medioo: element.medioo,
              prints: element.impresiones,
              id: element.id,
              publico: element.segmentacion,
              swc: element.gastado_con_comision,
              segmentation: element.segmentacion,
              // "age": "+18",
              leads: element.leads,
              version: "",
              budgetSinConsumir: element.presupuesto_disponible,
              available: element.presupuesto_disponible,
              postComments: element.post_comments,
              installs: element.instalacion_aplicaciones,
              frequency: element.frecuencia,
              modification_date: "",
              views: element.views,
              // "daily_budget": 0,
              video_playbacks_25: element.reproducciones_video_25,
              cpa: element.cpa,
              cpc: element.cpc,
              client: element.cliente,
              post_likes: element.post_likes,
              medio: element.medio,
              idMixChange: 0,
              cpf: element.cpf,
              cpi: element.cpi,
              // "price": 0.5,
              fans: element.fans,
              cpm: element.cpm,
              cpl: element.cpl,
              budgetConsumido: element.presupuesto_gastado,
              budgetI: element.presupuesto,
              budgetSinConsumir: element.presupuesto_disponible,
            };
            newCampaingsMerged.push(body);
          }
          presupuInvertido = presupuInvertido + parseFloat(element.presupuesto);
          presupuNeto = presupuNeto + parseFloat(element.presupuesto_neto);
          presupuConsumido =
            presupuConsumido + parseFloat(element.presupuesto_gastado);
          presupuestoSinConsumir =
            presupuestoSinConsumir + parseFloat(element.presupuesto_disponible);
          presupuestoConComision =
            presupuestoConComision + parseFloat(element.gastado_con_comision);
        });
        console.log("presupuInvertido ", presupuInvertido);
        console.log("presupuNeto ", presupuNeto);
        console.log("presupuConsumido ", presupuConsumido);
        console.log("presupuestoConComision ");

        if (newCampaingsMerged.length > 0) {
          let body = {
            fechaIni: "",
            ctr: 0,
            cpr: 0,
            status: 0,
            video_playbacks_75: 0,
            cpv: 0,
            country: "",
            fr: 0,
            searchable_status: "",
            idBudget: 0,
            swc: presupuestoConComision,
            budgetConsumido: presupuConsumido,
            budgetI: presupuInvertido,
            budget: presupuNeto,
            budgetSinConsumir: presupuestoSinConsumir,
            // "searchable_fechafin": element.searchable_fechafin,
            // "adv": found.duracion_promedio_video,
            // "searchable_medio": element.searchable_medio,
            // "id_ad_product": element.id_ad_product,
            // "postNumber": element.postNumber,
            // "net_budget": found.presupuesto_neto,
            // // "real_price": 0.47,
            // "searchable_idMixDetaill": element.searchable_idMixDetaill,
            // "searchable_name": element.searchable_name,
            // "reach": found.reach,
            // "account": element.account,
            // "event_responses": found.respuesta_eventos,
            // "postShares": found.post_shares,
            // "photo": found.visualizacion_fotos,
            // "video_playbacks_50": found.reproducciones_video_50,
            // "spent_percent": found.porcentaje_gastado,
            // "budget": found.presupuesto,
            // "banner": element.banner,
            // "fechafin": element.fechafin,
            // "id_medium": 0,
            // "idMixDetaill": element.idMixDetaill,
            // "cnr": 0,
            // "searchable_fechaIni": element.searchable_fechaIni,
            // "idMix": element.idMix,
            // "video_playbacks_95": found.reproducciones_video_95,
            // "url": found.url,
            name: "TOTALES",
            // "idCampaign": element.idCampaign,
            // "searchable_version": element.searchable_version,
            // "clicks": found.clicks,
            // "purchase_unit": found.tipo_compra,
            // "medioo": element.medioo,
            // "prints": found.impresiones,
            id: 0,
            // "publico": element.publico,
            // "swc": found.gastado_con_comision,
            // "segmentation": found.segmentacion,
            // // "age": "+18",
            // "leads": found.leads,
            // "version": element.version,
            // "available": element.available,
            // "postComments": found.post_comments,
            // "installs": found.instalacion_aplicaciones,
            // "frequency": found.frecuencia,
            // "modification_date": element.modification_date,
            // "views": found.views,
            // // "daily_budget": 0,
            // "video_playbacks_25": found.reproducciones_video_25,
            // "cpa": found.cpa,
            // "cpc": found.cpc,
            client: "0",
            post_likes: 0,
            medio: "",
            idMixChange: 0,
            cpf: 0,
            cpi: 0,
            // "price": 0.5,
            fans: 0,
            cpm: 0,
            cpl: 0,
          };
          newCampaingsMerged.push(body);
        }
        console.log("newCampaingsMerged ", newCampaingsMerged);
        newCampaingsMerged.forEach((campaign) => {
          dispatch(addCampaign(campaign));
        });

        dispatch(addTotal(newCampaingsMerged.length));
        dispatch(changePaginationTotal(newCampaingsMerged.length));
      } else {
        campaigns.forEach((campaign) => {
          dispatch(addCampaign(campaign));
        });
        dispatch(addTotal(count));
        dispatch(changePaginationTotal(count));
      }
    } catch (err) {
      Logger.log(TAG, "error getCampaignsSearch", err);
    }
    dispatch(changePaginationLoading(false));
    dispatch(showLoader(false));
  };

export const clearCampaigns = () => ({
  type: CAMPAIGNS_CLEAR,
});

export const clearCampaignsBudget = () => ({
  type: CAMPAIGNS_BUDGET_CLEAR,
});

export const clearCampaignupd = () => ({
  type: CAMPAIGNS_UPD_CLEAR,
});
export const clearCampaignupAdd = () => ({
  type: CAMPAIGNS_UPADD_CLEAR,
});

export const updatePautasLets = () => async (dispatch) => {
  Logger.log(TAG, "updatePautasLets");

  try {
    await APILA.updatePautasLets();
  } catch (err) {
    Logger.log(TAG, "error updatePautasLets", err);
  }
};

export const getCampaignsBudget = (id_budget) => async (dispatch) => {
  try {
    const response = await APILA.getCampaigns();

    const { campaigns } = response.data;

    dispatch(clearCampaignsBudget());
    let monto = 0;

    campaigns.forEach((campaign) => {
      if (campaign.idBudget != undefined && campaign.idBudget == id_budget) {
        if (campaign.budget != undefined && campaign.budget > 0) {
          monto = monto + parseInt(campaign.budget);
        }

        let campaignsbudget = {
          monto: monto,
          id_budget: id_budget,
        };

        dispatch(clearCampaignsBudget());
        dispatch(addCampaignBudget(campaignsbudget));
      } else {
        let campaignsbudget = {
          monto: monto,
          id_budget: id_budget,
        };

        dispatch(clearCampaignsBudget());
        dispatch(addCampaignBudget(campaignsbudget));
      }
    });
  } catch (err) {
    Logger.log(TAG, "error getCampaigns", err);
  }

  dispatch(showLoader(false));
};

export const addBudget = (id_budget) => (dispatch) => {
  const campaignsbudget = {
    monto: 0,
    id_budget: id_budget,
  };

  dispatch(clearCampaignsBudget());
  dispatch(addCampaignBudget(campaignsbudget));
};

async function insertLog(
  account,
  action,
  request,
  response,
  user,
  obs,
  idmod,
  mod
) {
  let idlog = v4();
  let now = Date.now();
  let logRequest = {
    id: idlog,
    source: "Lets-Advertise",
    timestamp: now,
    action: action,
    user: user,
    role: account,
    idmodulo: idmod,
    modulo: mod,
    request: request,
    response: response,
    observation: obs,
  };
  const responseLog = await APILA.insertLogLine(logRequest);
}
export const changePaginationPerPage = (paginationPerPage) => ({
  type: CAMPAIGNS_CHANGE_PAGINATION_PER_PAGE,
  paginationPerPage,
});

export const changePaginationTotal = (total) => ({
  type: CAMPAIGNS_CHANGE_TOTAL,
  total,
});

export const changePaginationLoading = (loading) => ({
  type: CAMPAIGNS_CHANGE_LOADING,
  loading,
});

export const changeSortFilter = (sortDirection, column) => ({
  type: CAMPAIGNS_CHANGE_FILTER_SORT,
  sortDirection,
  column,
});

export const changePageFilter = (page) => ({
  type: CAMPAIGNS_CHANGE_FILTER_PAGE,
  page,
});

export const changePaginationFilter = (paginationPerPage) => ({
  type: CAMPAIGNS_CHANGE_FILTER_PAGINATION,
  paginationPerPage,
});

export const changeTextFilter = (text) => ({
  type: CAMPAIGNS_CHANGE_FILTER_TEXT,
  text,
});

const addCampaign = (campaign) => ({
  type: CAMPAIGNS_ADD_ONE,
  campaign,
});

const addTotal = (total) => ({
  type: CAMPAIGNS_ADD_TOTAL,
  total,
});

const updateStatus = (id, status) => ({
  type: CAMPAIGNS_UPDATE_STATUS,
  id,
  status,
});

const addCampaignBudget = (campaignsbudget) => ({
  type: CAMPAIGNS_BUDGET,
  campaignsbudget,
});

export const addCampaigns = (campaigns) => ({
  type: ADD_CAMPAIGNS,
  campaigns,
});

export const listArrayCampaigns = (filteredCampaigns) => ({
  type: CAMPAIGNS_LIST_ARRAY_CAMPAIGNS,
  filteredCampaigns,
});
