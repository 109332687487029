import { APP_CLEAR_CURRENT_USER } from '../actions/app.actions'
import * as Actions from '../actions/budget_spent.actions'

const initialState = {
  total: 0,  
  spent_budget: []
}

export default (state = initialState, action) => {
  switch (action.type) {
    case Actions.CLEAN_BUDGET_SPENT:
      return {
        ...state,
        spent_budget: [],
        total: 0,
      }
    case Actions.ADD_BUDGET_SPENT:
       return {...state, spent_budget:action.budget_spent}
    case Actions.BUDGET_CHANGE_TOTAL:
    return {
      ...state,
      total: action.total,
    }
    case APP_CLEAR_CURRENT_USER:
      return initialState
    default:
      return state
  }
}