import React, { useState, useEffect } from 'react'
import _ from 'lodash'
import classnames from 'classnames'
import { connect } from 'react-redux'
import { v4 } from 'uuid'
import analytics from 'helpers/analytics'
import {
  FormGroup,
  Label,
  Input,
  FormFeedback,
} from 'reactstrap'

import * as Modal from 'redux/actions/modal.actions'
import { upsertReport } from 'redux/actions/report.actions'
import { withUtils } from 'utils'
import { Auth } from "aws-amplify";
const TAG = 'UpsertReport'

const UpsertReport = (props) => {
  const [errors, setErrors] = useState({ name: false, link: false })
  const [state, setState] = useState({
    id: '',
    name: '',
    link: '',
    edit: false,
  })

  useEffect(() => {
    if (!_.isEmpty(props.edit)) {
      let newState = {
        edit: true,
        id: props.edit.id,
        name: props.edit.name,
        link: props.edit.link,
      }

      setState(prev => ({
        ...prev,
        ...newState,
      }))
    } else {
      setState({
        id: '',
        name: '',
        link: '',
        edit: false,
      })
    }
  }, [props.edit])

  const handleInputChange = (e) => {
    let name = e.target.name
    let value = e.target.value

    setState(prev => ({
      ...prev,
      [name]: value
    }))

    setErrors((prev) => ({
      ...prev,
      [name]: _.isEmpty(value),
    }))
  }

  const closeModal = (e) => {
    if (e) e.preventDefault()
    analytics.modalesAcciones({
      accion: 'Cancelar',
      ubicacion: 'Reportes'
    })
    props.utils.log(TAG, 'clearState')
    window.$.fancybox.close()
    setState({
      id: '',
      name: '',
      link: '',
      edit: false,
    })
  }

  const areValidInputs = () => {
    const mErrors = {
      name: state.name === '',
      link: state.link === '',
    }

    setErrors((prev) => ({
      ...prev,
      ...mErrors,
    }))

    return !_.some(mErrors, (e) => e)
  }

  const handleUpsert = (e) => {
    e.preventDefault()

    if (!areValidInputs()) return

    let reportId = state.id

    if (reportId === '') {
      reportId = v4()
    }
    Auth.currentAuthenticatedUser()
      .then((userdata) => {
        props._upsertReport(
          reportId,
          state.name,
          state.link,
          props.currentAccount,
          state.edit,
          props.accounts,
          props.currentUser,
          userdata.attributes.email,
          userdata.attributes.sub
        )
      })
      .catch((error) => {
        console.log('create/update error reports', error)
        if (error === 'The user is not authenticated') {
          props._upsertReport(
            reportId,
            state.name,
            state.link,
            props.currentAccount,
            state.edit,
            props.accounts,
            props.currentUser,
            "logout",
            "logout"
          )
        }
      })
    closeModal()
  }

  return (
    <div
      id='upsert-report-modal'
      style={{ display: 'none' }}
      className={classnames('upsert-modal')}
    >
      <div className='foot-mnc'>
        <div className='formOneStep'>
          <div className='titleOneStep'>
            <p className='h3'>
              {state.edit
                ? 'Actualizemos tu reporte'
                : 'Creemos un nuevo reporte'}
            </p>
          </div>

          <div className='bodyOneStep'>
            <div className='twoColBody'>
              <FormGroup>
                <Label
                  style={{
                    color: '#05252d',
                    fontWeight: 500,
                    marginBottom: '5px',
                  }}
                >
                  Ponle un nombre a tu reporte
                </Label>
                <Input
                  type='text'
                  name='name'
                  className='input'
                  value={state.name}
                  onChange={handleInputChange}
                  placeholder='P. ejemplo madres solteras'
                  invalid={errors.name}
                />
                <FormFeedback>Ingresa un nombre</FormFeedback>
              </FormGroup>
            </div>

            <div className='twoColBody'>
              <FormGroup>
                <Label
                  style={{
                    color: '#05252d',
                    fontWeight: 500,
                    marginBottom: '5px',
                  }}
                >
                  Ingresa el enlace de tu reporte
                </Label>
                <Input
                  type='text'
                  name='link'
                  className='input'
                  value={state.link}
                  onChange={handleInputChange}
                  placeholder='P. ejemplo https://madressolteras.com'
                  invalid={errors.link}
                />
                <FormFeedback>Ingresa un enlace</FormFeedback>
              </FormGroup>
            </div>
          </div>

          <div className='footOneStep mt-3'>
            <a href='#' className='btnCancelOS' onClick={closeModal}>
              Cancelar
            </a>

            <a href='#' className='btnConfirmOS' onClick={handleUpsert}>
              Confirmar
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => ({
  edit: state.report.edit,
  reports: state.report.list,
  currentUser: state.app.current_user,
  accounts: state.accounts.list,
  currentAccount: state.accounts.default,
})

const mapDispatchToProps = (dispatch) => ({
  _showAlertModal: (title, message, type, callback) =>
    dispatch(Modal.showAlertModal(title, message, type, callback)),
  _upsertReport: (id, name, link, account, edit, accounts, user, userEmail) =>
    dispatch(upsertReport(id, name, link, account, edit, accounts, user, userEmail)),
})

export default connect(mapStateToProps, mapDispatchToProps)(withUtils(UpsertReport))