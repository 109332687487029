import { v4 } from 'uuid'
import * as API from 'helpers/api'
import * as APILA from 'helpers/apiLA'
import * as Logger from 'utils/logger'
import * as Modal from './modal.actions'
import { showLoader } from './loader.actions'
import { newEmailValidation } from './email.actions'

export const CATEGORIES_CLEAR = 'CATEGORIES:CLEAR'
export const CATEGORIES_REMOVE = 'CATEGORIES:REMOVE'
export const CATEGORIES_ADD_ONE = 'CATEGORIES:ADD:ONE'
export const CATEGORIES_SET_EDIT = 'CATEGORIES:SET:EDIT'
export const CATEGORIES_CLEAR_EDIT = 'CATEGORIES:CLEAR:EDIT'
export const CATEGORIES_SET_FILTER_TEXT = 'CATEGORIES:SET:FILTER:TEXT'

const TAG = 'Categories:Actions'



export const getCategories = (account, user, idMod, mod) => async (dispatch) => {
  dispatch(showLoader(true))
  dispatch(clearCategories())

  try {
    const response = await API.getCategories(account)
    await insertLog(account, "getCategories", "", response.data, user, "trae todas las categorias", idMod, mod)

    const { categories } = response.data

    dispatch(addCategory(categories))
  } catch (err) {
    Logger.log(TAG, 'error getCategories', err)
  }

  dispatch(showLoader(false))
}

export const setFilterText = text => ({
  type: CATEGORIES_SET_FILTER_TEXT,
  text
})

export const clearCategories = () => ({
  type: CATEGORIES_CLEAR
})

export const createCategory_logout = (id, name, account, edit, createAt) => dispatch => {
  //dispatch(showLoader(true))
  let now = Date.now()

  let body = {
    id,
    name,
    account,
    createAt: edit ? createAt : now,
    updateAt: now,
  }

  return API.createCategory(body)
}

export const createCategory = (id, name, account, edit, createAt, padre, tipo, user, idmod, mod, accounts, accion, userEmail) => async (dispatch) => {
  console.log("createCategory user", user);
  dispatch(showLoader(true))
  const body = {
    id,
    name,
    account,
    padre,
    tipo,
    userEmail
  }
  let bodyEmail = body;
  bodyEmail.createAt = createAt
  try {
    const response = edit
      ? await API.updateCategory(body)
      : await API.createCategory(body)
    edit ? await insertLog(account, "updateCategory", body, response.data, user, "edición de categoria", idmod, mod) :
      await insertLog(account, "createCategory", body, response.data, user, "creación de categoria", idmod, mod)

    Logger.log(TAG, 'createCategory', response)
    const title = edit
      ? 'Categoría Editada'
      : 'Categoría Creada'
    const message = edit
      ? 'La categoría fue editada exitosamente.'
      : 'La categoría fue creada exitosamente.'

    let bodyBitacora = {
      id: v4(),
      date: new Date(),
      account: account,
      action: edit ? "se ha editado una categoria" : 'se ha creado una categoria',
      component: 'categories',
      data: body
    }
    await APILA.insertBitacora(bodyBitacora)


    dispatch(newEmailValidation(account, 'Categorías', bodyEmail, user, accounts, accion))
    dispatch(Modal.showAlertModal(title, message, Modal.MODAL_TYPE_SUCCESS, () => {
      dispatch(getCategories(account))

    }))
  } catch (err) {
    Logger.log(TAG, 'error createCategory', err)
    const message = edit ? 'Ocurrió un error al editar la categoría' : 'Ocurrió un error al crear la categoría'
    dispatch(Modal.showAlertModal('Error!', message, Modal.MODAL_TYPE_ERROR))
  }

  dispatch(showLoader(false))
}

export const upsertCategory = (id, name, account, edit, padre) => async (dispatch) => {
  const body = {
    id,
    name,
    account,
    padre

  }

  try {
    const response = edit
      ? await API.updateCategory(body)
      : await API.createCategory(body)

    Logger.log(TAG, 'createCategory', response)
    // dispatch(getCategories(account))
  } catch (err) {
    Logger.log(TAG, 'error createCategory', err)
  }
}
export const activateCategory = (account, body) => async (dispatch) => {
  // const body = {
  //   id,
  //   name,
  //   account,
  //   padre,
  //   active:true

  // }
  console.log("activateCategory account", account)
  console.log("activateCategory body", body)
  try {
    const response = await APILA.updateCategoryStatus(body)
    Logger.log(TAG, 'activateCategory', response)
    dispatch(getCategories(account))
    dispatch(Modal.showAlertModal("Activación de categoria", "Categoria activada exitosamente", Modal.MODAL_TYPE_SUCCESS, () => {
      // window.location.reload(true);

    }))
  } catch (err) {
    Logger.log(TAG, 'error activateCategory', err)
  }
}
export const deleteCategory = (ids, account, accounts, body, user) => async (dispatch) => {
  dispatch(showLoader(true))
  console.log("action deleteCategory", ids)
  console.log('Las accounts', accounts);
  console.log('El body', body);
  console.log("action deleteCategory", account)
  try {
    for (let i = 0; i < ids.length; i++) {
      let response = await API.deleteCategory(ids[i])
      Logger.log(TAG, 'deleteCategory', response)
      let found = body?.find(ele => ele.id === ids[i])
      dispatch(newEmailValidation(account, 'Categorías', found, user, accounts, 'Eliminación'))
    }


    dispatch(getCategories(account))
    dispatch(Modal.showAlertModal('Categoría Eliminada', 'La categoría fue eliminada exitosamente.', Modal.MODAL_TYPE_SUCCESS, () => {
    }))




    dispatch(getCategories(account))
    dispatch(Modal.showAlertModal('Categoría Eliminada', 'La categoría fue eliminada exitosamente.', Modal.MODAL_TYPE_SUCCESS, () => {


    }))
  } catch (err) {
    Logger.log(TAG, 'error deleteCategory', err)
    dispatch(Modal.showAlertModal('Error!', 'Ocurrió un error al eliminar la categoría', Modal.MODAL_TYPE_ERROR))
  }

  dispatch(showLoader(false))
}

async function insertLog(account, action, request, response, user, obs, idmod, mod) {
  let idlog = v4()
  let now = Date.now()
  let logRequest = {
    "id": idlog,
    "source": "Lets-Advertise",
    "timestamp": now,
    "action": action,
    "user": user,
    "role": account,
    "idmodulo": idmod,
    "modulo": mod,
    "request": request,
    "response": response,
    "observation": obs

  }
  const responseLog = await APILA.insertLogLine(logRequest)

}
export const setEdit = edit => ({
  type: CATEGORIES_SET_EDIT,
  edit,
})

export const clearEdit = () => ({
  type: CATEGORIES_CLEAR_EDIT,
})

export const addCategory = category => ({
  type: CATEGORIES_ADD_ONE,
  category
})

const removeCategory = category => ({
  type: CATEGORIES_REMOVE,
  category
})