import React from "react";
import { Switch, Route, Redirect, withRouter } from "react-router-dom";

// pages
import RolesPermisosPage from "./list";

const RolesPermisos = (props) => {
  return (
    <Switch>
      <Route path={`${props.match.path}`} component={RolesPermisosPage} exact />
      <Route path="*">
        <Redirect to={`${props.match.path}`} />
      </Route>
    </Switch>
  );
};

export default withRouter(RolesPermisos);
