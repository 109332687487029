import React from "react";
import { SimpleText } from "./simpleText";
import { useSelector } from "react-redux";
import { TOTALES } from "../constants";

const CustomCellBanner = ({ row, editValueBanner }) => {
  const propsEdit = useSelector((state) => state.templates.edit);
  const goalsTemplate = useSelector((state) => state.templates.listGoals);
  const dataUser = useSelector((state) => state.templates.userInfo);
  const editCell = !row?.banner && row.name !== TOTALES ? true : false;
  if (row?.name === TOTALES) return <></>;
  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      {editCell && (
        <i
          className="fa fa-exclamation-circle text-danger"
          aria-hidden="true"
          style={{ marginRight: "8px" }}
        ></i>
      )}
      <SimpleText
        value={row.banner || ""}
        id={{ id: row.id, campo: "banner" }}
        type={"select"}
        editable={true}
        editCell={editCell}
        dataSelect={row?.optionsObject?.map((goal) => goal.banner) || []}
        nameColumn={row}
        onEditValue={(value) =>
          editValueBanner(value, propsEdit, row?.optionsObject || [], dataUser)
        }
      />
    </div>
  );
};

export default CustomCellBanner;
