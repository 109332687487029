import React from 'react'

// components
import MetaTags from 'components/metatags'
import UpdatePasswordForm from 'components/auth/update_password_form'

const UpdatePassword = (props) => (
  <>
    <MetaTags
      title='Cambiar Contraseña'
      description='Página de cambio de contraseña'
    />

    <UpdatePasswordForm {...props} />
  </>
)

export default UpdatePassword
