import React, { useState, useEffect, useLayoutEffect } from "react";
import _ from "lodash";
import { connect } from "react-redux";
import { withRouter, useLocation, useHistory } from "react-router-dom";
import { Tooltip } from "antd";
// organization components
import ReactDataTable, { Status } from "@easygosanet/react-datatable";
import classnames from "classnames";
import DataTableUtils, {
  Share,
  ShowColumn,
  ShowColumn2,
  FilterColumn,
  FilterColumn2,
} from "@easygosanet/react-datatable-utils";
import LinearProgress from "@material-ui/core/LinearProgress";
import * as Modal from "redux/actions/modal.actions";
import { showLoader } from "redux/actions/loader.actions";
import { getClients } from "redux/actions/clients.actions";
import { pwaDeleteCategory } from "redux/actions/pwa.actions";
import { updateAccountMembers } from "redux/actions/accounts.actions";
import PageLoader from "components/page_loader";
import * as API from "helpers/apiLA";
import { Auth } from "aws-amplify";
import {
  setEdit,
  clearEdit,
  getCategories,
  setFilterText,
  clearCategories,
  deleteCategory,
  activateCategory,
} from "redux/actions/categories.actions";
import DetailColEdit from "components/DetailCol";
import { createCategory } from "redux/actions/categories.actions";
import { withUtils } from "utils";
import * as APILA from "helpers/api";
import "./assets/css/styles.scss";
import { Pagination } from "antd";
import "antd/dist/antd.css";
import { cleanLogs } from "redux/actions/logs.actions";
import analytics from "../../helpers/analytics";
import { RingVolumeTwoTone } from "@material-ui/icons";
import { v4 } from "uuid";
import status from "config/status";
import Swal from "sweetalert2";
import CreateAccountLogOut from "components/flowLogOutUser/createAccount";
import { nextStep } from "redux/actions/userLogout.action";

const idMod = 1;
const mod = "CATEGORIES";

const LinearIndeterminate = () => (
  <div className="progress-table-component">
    <LinearProgress />
  </div>
);

// const DetailCol = ({ text, className, onClick }) => (
//   <div className="firstColl w-100 nombrePrimerColumn">
//     <div onClick={() => onClick()} className={className}>
//       <i className="fas fa-edit fa-edit1"></i>
//     </div>
//     <p className="name-fc22">{text}</p>
//   </div>
// );

const Actions = ({ onClick }) => (
  <div
    onClick={() => onClick()}
    className="secondColl orange cursor-pointer "
    id="orangeBtn"
  >
    Eliminar
  </div>
);
const ActionsActivate = ({ onClick }) => (
  <div
    onClick={() => onClick()}
    className="secondColl green cursor-pointer activate"
  >
    Activar
  </div>
);

const Content = (props) => {
  const history = useHistory();
  const location = useLocation();

  //Filtrado por librearia
  const queryString = window.location.search;
  const params = new URLSearchParams(queryString);
  const filterInfoParams = [];
  let currentColumn = "";
  params.forEach((value, key) => {
    if (key === "Column") {
      if (value.includes("=")) {
        const [columnName, columnValue] = value.split("=");
        currentColumn = columnName;
        const filterItem = { column: columnName, value: columnValue };
        filterInfoParams.push(filterItem);
      } else {
        currentColumn = value;
      }
    } else {
      const filterItem = { column: currentColumn, value };
      filterInfoParams.push(filterItem);
    }
  });

  //Filtrado
  const currentUrl = new URLSearchParams(location.search);
  const [filterText, setFilterText] = useState(currentUrl.get("search") || "");
  const [viewId, setViewId] = useState("list");
  const [filterInfo, setFilterInfo] = useState(filterInfoParams || []);
  const urlSegments = location.pathname.split("/");

  const agrupacionParams = urlSegments[2]?.split("&")[2]?.split("=")[1]; // Obtenemos el valor de la url que viene sobre la agrupacion
  const viewParams = urlSegments[2]?.split("&")[1]?.split("=")[1];
  const [groups, setGroups] = useState(agrupacionParams || "default");

  const orderDefault = ["name", "createAt", "updateAt", "status", "botones"];
  const [account, setAccount] = useState(props.currentAccount); // currentAccount tiene id por default
  const [searchFilterData, setSearchFilterData] = useState([]);
  const [filters, setFilters] = useState({});
  // const [filterText, setFilterText] = useState("");
  const [cat, setCat] = useState([]);
  const [filteredNormal, setFilteredNormal] = useState([]);
  const [nameView, setNameView] = useState("");
  const [colAct, setColAct] = useState([]);
  const [views, setViews] = useState([]);
  const [permissions, setPermissions] = useState({
    approve: false,
    create: false,
    delete: false,
    edit: false,
    export: false,
    manageRoles: false,
    view: true,
  });
  // const [filterInfo, setFilterInfo] = useState([]);
  const [nameGrouped, setNameGrouped] = useState("");
  const [isSearching, setIsSearching] = useState(false);
  const [categorie, setCategorie] = useState([]);
  const [categorieInit, setCategorieInit] = useState([]);
  const [optionsNames, setOptionsNames] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  // Modificaciones en la url para los deeplinks
  const urlChanged = ({ currentView = viewId, currentGroups = groups }) => {
    const urlUse = urlSegments[2]; // En esta posicion obtengo los valores que voy a utilizar
    const idAccount = urlUse?.split("=")[1]?.split("&")[0]; // Obtenemos el valor que le corresponde al id de la cuenta
    const validationAccount =
      idAccount === "" || idAccount === undefined
        ? props.currentAccount
        : idAccount;
    history.replace(
      `/categorias/account=${validationAccount}&view=${currentView}&group=${currentGroups}`
    );
    if (location.search !== "") {
      history.push({
        search: location.search,
      });
    }
  };

  const categoriesReturn = async () => {
    let categoriesz = await APILA.getCategories(account);
    let cate = categoriesz.data.categories;
    let arrayAux = [];

    categoriesz.data.categories.forEach((el) => {
      let elCat = cate.find((element) => element.id == el.padre);
      arrayAux.push({
        account: el.account,
        active: el.active,
        createAt: el.createAt,
        id: el.id,
        name: el.name,
        padre: el.padre,
        updateAt: el.updateAt,
        nombrePadre: elCat?.name ? elCat?.name : "",
      });
    });

    let aaux = arrayAux?.filter((item) => {
      return (
        item.padre !== "" ||
        item.padre !== "97bc9ee5-36a0-455b-974c-fa7832c9c341"
      );
    });
    aaux = _.sortBy(arrayAux, ["createAt"]);
    if (filterInfoParams.length > 0) {
      let dataFinal = [];
      filterInfoParams.forEach((valor) => {
        let search = aaux.filter(
          (item) => item.name?.toString() == valor.value
        );
        let padre = search.filter((el) => el.nombrePadre === "");
        if (padre.length > 0) {
          let arrayPadre = [];
          arrayPadre = padre;
          aaux.forEach((el) => {
            if (el.padre === padre[0].id) {
              arrayPadre.push(el);
            }
          });
          let dataAlt = arrayPadre;
          if (dataAlt.length > 0) {
            dataFinal = [...dataFinal, ...arrayPadre];
          }
        }
      });
      let set = new Set(dataFinal.map(JSON.stringify));
      let arrSinDuplicaciones = Array.from(set).map(JSON.parse);
      setFilteredNormal(arrSinDuplicaciones);
    }
  };

  useEffect(() => {
    let options = optionsNames;
    if (agrupacionParams) {
      if (agrupacionParams !== "default") {
        options.splice(0, 0, agrupacionParams);
        setNameGrouped(agrupacionParams);
        setGroups(agrupacionParams);
      }
    }
    categoriesReturn();
  }, []);

  const getStatusLabel = (mStatus) => {
    switch (mStatus) {
      case false:
        return status.ELIMINADO.label;
      case true:
        return status.ACTIVO.label;
      default:
        return "Totales";
    }
  };

  const getStatusClassName = (mStatus) => {
    switch (mStatus) {
      case false:
        return status.ELIMINADO.class;

      case true:
        return status.ACTIVO.class;

      default:
        return status.EN_IMPLEMENTACION.class;
    }
  };
  const [columns, setColumns] = useState([
    {
      id: "name",
      name: "Nombre",
      selector: "name",
      sortable: true,
      center: true,
      visible: false,
      group: true,
      omit: false,
      style: {
        color: "#02025b",
      },
      type: "catPadre",
      show: true,
      cell: (row) => (
        <DetailCol
          text={row.name}
          permisos={row.permisos ? row.permisos : ""}
          row={row}
          onClick={() =>
            goToEditPage(row.id, row.name, row.createAt, row.padre, row.hijo)
          }
          className="edit-fc"
          center={"true"}
        />
      ),
    },
    {
      id: "account",
      name: "Cuenta",
      selector: "account",
      sortable: true,
      omit: true,
      visible: false,
      center: true,
      style: {
        fontWeight: "bold",
      },
      type: "text",
      cell: (row) => getAccountName(row.account),
    },
    {
      id: "createAt",
      name: "Fecha de Creación",
      selector: "createAt",
      sortable: true,
      center: true,
      minWidth: "220px",
      omit: false,
      style: {
        fontWeight: "bold",
      },
      cell: (row) => formatDate(row.createAt),
    },
    {
      id: "padre",
      name: "Categoría",
      selector: "padre",
      visible: false,
      sortable: true,
      center: true,
      omit: true,
      style: {
        fontWeight: "bold",
      },
      cell: (row) => row.nombrePadre,
    },
    {
      id: "updateAt",
      name: "Última Actualización",
      selector: "updateAt",
      sortable: true,
      center: true,
      omit: false,
      style: {
        fontWeight: "bold",
      },
      cell: (row) => formatDate(row.updateAt),
    },
    {
      id: "status",
      name: "Status",
      selector: "status",
      sortable: true,
      omit: false,
      center: true,
      type: "status",
      style: {
        fontWeight: "bold",
      },
      cell: (row) => (
        <Status
          value={row.active == true ? "Activo" : "Eliminado"}
          label={getStatusLabel(row.active)}
          className={getStatusClassName(row.active)}
          justifyContent={"center"}
          center={"true"}
        />
      ),
    },
    // {
    //   id: "botones",
    //   name: "Acción",
    //   selector: "botones",
    //   sortable: false,
    //   button: true,
    //   minWidth: "110px",
    //   cell: (row) =>
    //     row.active == true ? (
    //       <Actions onClick={() => deleteCategory(row.id, row.name)} />
    //     ) : (
    //       <ActionsActivate
    //         onClick={() =>
    //           activateCategory(
    //             row.id,
    //             row.name,
    //             row.createAt,
    //             row.padre,
    //             row.hijo,
    //             row.active
    //           )
    //         }
    //       />
    //     ),
    // },
  ]);
  let col = columns;
  const DetailCol = ({ text, row, onClick, permisos }) => (
    <div className="content-firstColumn">
      <div className="iconBand">
        {permisos.edit ? (
          <Tooltip title="Editar" color="#002448" placement="top">
            <i
              onClick={(e) =>
                goToEditPage(
                  row.id,
                  row.name,
                  row.createAt,
                  row.padre,
                  row.hijo
                )
              }
              className="fas fa-edit fa-edit1"
            />
          </Tooltip>
        ) : null}

        {permisos.delete &&
          (row.active ? (
            <div className="eliminar">
              <i
                class="fas fa-trash"
                onClick={() => deleteCategory(row.id, row.name)}
              />
              <span className="etiqueta">Eliminar</span>
            </div>
          ) : (
            <div className="eliminar">
              <i
                class="fas fa-trash-restore-alt"
                onClick={() =>
                  activateCategory(
                    row.id,
                    row.name,
                    row.createAt,
                    row.padre,
                    row.hijo,
                    row.active
                  )
                }
              />
              <span className="etiqueta">Reactivar</span>
            </div>
          ))}
      </div>
      <p className="name-fc22">{text}</p>
    </div>
  );

  useEffect(() => {
    actualizarViews(true);
    setAccount(props.currentAccount);
    permiso();
  }, [props.currentAccount]);

  // useEffect(() => {
  //   actualizarViews(true);
  // }, []);

  // useEffect(() => {
  //   let show = false;
  //   let dataFinal = [];
  //   let or = [];
  //   if (views.length > 0)
  //     views.forEach((ele) => {
  //       if (ele.active) {
  //         ele.columns.forEach((elementos) => {
  //           console.log("Las colun", elementos);
  //           show = true;
  //           dataFinal.push(elementos);
  //           name(ele.name);
  //           handleShowColumnChange(elementos.selector, elementos.omit);
  //         });
  //       }
  //     });
  //   if (dataFinal.length == 0) setNameView("Lista");
  //   if (show) {
  //     dataFinal.sort((a, b) => {
  //       return a.position - b.position;
  //     });
  //   }
  //   dataFinal.forEach((element) => {
  //     or.push(element.id);
  //   });

  //   if (dataFinal.length > 0) {
  //     handleColumnSortChange(or);
  //   }
  // }, [colAct]);

  const permiso = async () => {
    try {
      props._showLoader(true);
      let userLog = await Auth.currentAuthenticatedUser();
      let permisoAcc = await API.getPermitsByAccountAndUser({
        account: props.currentAccount,
        user: userLog.username,
      });
      let idRol = permisoAcc.data.permisos[0].idRol;
      let permisosRes = await API.getRoleNameById({ id: idRol });

      let permissAud = permisosRes.data.rol[0].permissions;
      let permissFn = permissAud.filter((ele) => ele.name == "Categorías");

      setPermissions((state) => {
        return {
          approve: (state.approve = permissFn[0].approve),
          create: (state.create = permissFn[0].create),
          delete: (state.delete = permissFn[0].delete),
          edit: (state.edit = permissFn[0].edit),
          export: (state.export = permissFn[0].export),
          manageRoles: (state.manageRoles = permissFn[0].manageRoles),
          view: (state.view = permissFn[0].view),
        };
      });
      setTimeout(() => {
        props._showLoader(false);
      }, 1000);
    } catch (err) {
      setPermissions((state) => {
        return {
          approve: false,
          create: true,
          delete: true,
          edit: true,
          export: true,
          manageRoles: false,
          view: true,
        };
      });
      props._showLoader(false);
      console.log("err permisos", err);
    }
  };

  const actualizarViews = async (tipo) => {
    if (Auth.user) {
      // setIsLoading(true);
      let sub = Auth.user.signInUserSession.idToken.payload.sub;
      let viewsData = await API.getViewsByUser({
        account: props.currentAccount,
        user: sub,
        component: "categories",
      });
      setViews(viewsData.data.views);
      if (viewParams && viewParams !== "list") {
        let foundViewUser = viewsData.data.views.find(
          ({ id }) => id === viewParams
        );
        if (!foundViewUser) return history.replace({ pathname: "/campañas" });
      }
      if (viewsData.data.views.length === 0) {
        setNameView("lista");
        urlChanged({
          currentGroups: groups,
        });
      } else {
        let vistaActiva = viewsData.data.views.find((ele) => ele.active);
        if (vistaActiva) {
          setNameView(vistaActiva.name);
          setViewId(vistaActiva.id);
          urlChanged({
            currentView: vistaActiva.id,
            currentGroups: groups,
          });
          if (tipo) {
            let nombres = [];
            vistaActiva.columns.forEach((ele) => {
              nombres.push(ele.id);
              handleShowColumnChange(ele.selector, ele.omit);
            });
            handleColumnSortChange(nombres);
          }
        } else {
          setViewId("list");
          setGroups("default");
          urlChanged({
            currentGroups: groups,
            currentView: "list",
          });
          columns.forEach((ele) => {
            handleShowColumnChange(ele.selector, ele.omit);
          });
          handleColumnSortChange(orderDefault);
          setNameView("lista");
        }
      }
    }
  };

  const exportDataToExcel = () => {
    let headers = [];
    let dataExcel = [];
    let selectors = [];
    var filteredColumns = columns.filter(function (col) {
      return col.omit === false;
    });

    filteredColumns.forEach((element) => {
      headers.push(element.name);
      selectors.push(element.tag);
    });

    filteredNormal.forEach((el) => {
      if (
        el.padre == "97bc9ee5-36a0-455b-974c-fa7832c9c341" ||
        el.padre == ""
      ) {
        if (el.active) {
          dataExcel = [
            ...dataExcel,
            [
              el.name,
              "Cuenta",
              "Fecha de Creación",
              "Categoría",
              "Última Actualización",
              "Status",
            ],
          ];
          let groupedData = filteredNormal.filter((item) => {
            if (el.id === item.padre) {
              if (item.active) {
                return item;
              }
            }
          });
          if (groupedData.length > 0) {
            dataExcel = [
              ...dataExcel,
              ...groupedData.map((dataE) => {
                return [
                  dataE.name,
                  getAccountName(dataE.account),
                  formatDate(dataE.createAt),
                  dataE.nombrePadre,
                  formatDate(dataE.updateAt),
                  "Activo",
                ];
              }),
              [],
            ];
          }
        }
      }
    });
    return dataExcel;
  };

  const getCategoriess = async () => {
    let categoriesz = await APILA.getCategories(account);
    let cate = categoriesz.data.categories;
    let arrayAux = [];

    categoriesz.data.categories.forEach((el) => {
      let elCat = cate.find((element) => element.id == el.padre);
      arrayAux.push({
        account: el.account,
        active: el.active,
        createAt: el.createAt,
        id: el.id,
        name: el.name,
        padre: el.padre,
        updateAt: el.updateAt,
        nombrePadre: elCat?.name ? elCat?.name : "",
      });
    });

    let aaux = arrayAux?.filter((item) => {
      return (
        item.padre !== "" ||
        item.padre !== "97bc9ee5-36a0-455b-974c-fa7832c9c341"
      );
    });
    aaux = _.sortBy(arrayAux, ["createAt"]);
    setCategorie([...aaux]);
    setCat([...aaux]);
    setCategorieInit([...aaux]);
    setFilteredNormal([...aaux]);
  };

  useEffect(() => {
    if (props.currentUser !== "") {
      props._getClients();
      account !== "" &&
        props._getCategories(account, props.currentUser, idMod, mod);
      getCategoriess();
    }
  }, [account, props.currentUser]);

  let prueba = [
    { column: "name", value: "Productoss" },
    { column: "account", value: "Enero" },
  ];

  const handleFilterPerColumn = (value) => {
    // filtrar por los inputs de filtrar y agrupar
    let dataFinal = [];
    if (value.length > 0) {
      analytics.filtro({
        ubicacion: "Dashboard",
      });
      setFilterInfo([...value]);
      let dataToFilterr = [];
      dataToFilterr = filteredData;

      if (dataToFilterr.length > 0) {
        setIsSearching(false);
        value.forEach((ele) => {
          const filtered = {};
          setIsSearching(false);
          // value.forEach((ele) => {
          const { column, value: columnValue } = ele;

          if (!filtered[column]) {
            filtered[column] = columnValue;
          } else {
            filtered[column] += `&=${columnValue}`;
          }
          // });
          const searchParams = Object.entries(filtered)
            .map(([column, value]) => `${column}=${value}`)
            .join("&");
          if (location.search !== "") {
            let validation = ele.column
              ? `&Column=${searchParams}`
              : `&${ele.value}`;
            history.push({
              search: location.search + `=&Column=${searchParams}`,
            });
          } else {
            history.push({
              search: `?search=&Column=${searchParams}`,
            });
          }
          if (ele.column == "cat") {
            let search = cat.filter(
              (item) => item.name?.toString() == ele.value
            );
            let padre = search.filter((el) => el.nombrePadre === "");
            if (padre.length > 0) {
              let arrayPadre = [];
              arrayPadre = padre;
              cat.forEach((el) => {
                if (el.padre === padre[0].id) {
                  arrayPadre.push(el);
                }
              });
              let dataAlt = arrayPadre;
              if (dataAlt.length > 0) {
                dataFinal = [...dataFinal, ...arrayPadre];
              }
            }
          } else if (ele.column != "status") {
            let valueAux = [];
            let valueFinal = [];
            cat.forEach((el) => {
              el.estado = el.active ? "Activo" : "Eliminado";
              if (el.name.toString().toLowerCase() == ele.value.toLowerCase()) {
                valueAux.push(el);
                valueFinal.push(el);
              }
            });
            valueAux.forEach((el) => {
              el.estado = el.active ? "Activo" : "Eliminado";
              let auxx = cat.filter((item) => item.id === el.padre);
              valueFinal.push(...auxx);
            });
            let dataAlt = valueFinal;
            if (dataAlt.length > 0) {
              dataFinal = [...dataFinal, ...valueFinal];
            }
          } else {
            if (ele.value == "Eliminado") {
              let valueAux = [];
              let valueFinal = [];
              cat.forEach((el) => {
                el.estado = el.active ? "Activo" : "Eliminado";
                if (
                  el.estado.toString().toLowerCase() == ele.value.toLowerCase()
                ) {
                  valueAux.push(el);
                  valueFinal.push(el);
                }
              });
              valueAux.forEach((el) => {
                el.estado = el.active ? "Activo" : "Eliminado";
                let auxx = cat.filter((item) => item.id === el.padre);
                valueFinal.push(...auxx);
              });
              let dataAlt = valueFinal;
              if (dataAlt.length > 0) {
                dataFinal = [...dataFinal, ...valueFinal];
              }
            } else {
              let valueAux = [];
              let valueFinal = [];
              cat.forEach((el) => {
                el.estado = el.active ? "Activo" : "Eliminado";
                if (
                  el.estado.toString().toLowerCase() == ele.value.toLowerCase()
                ) {
                  valueAux.push(el);
                  valueFinal.push(el);
                }
              });
              setIsSearching(false);
              valueAux.forEach((el) => {
                el.estado = el.active ? "Activo" : "Eliminado";
                let auxx = cat.filter((item) => item.id === el.padre);
                valueFinal.push(...auxx);
              });
              let dataAlt = valueFinal;
              if (dataAlt.length > 0) {
                dataFinal = [...dataFinal, ...valueFinal];
              }
            }
          }
          let set = new Set(dataFinal.map(JSON.stringify));
          let arrSinDuplicaciones = Array.from(set).map(JSON.parse);
          setFilteredNormal([...arrSinDuplicaciones]);
        });
      }
    } else {
      history.push({
        search: "?search",
      });
      setFilterInfo(filterInfoParams || []);
      setFilteredNormal(cat);
      setIsSearching(false);
    }
  };

  const [orderCa, setorderCa] = useState([]);

  useEffect(() => {
    let nombre = [];
    let orderCate = _.orderBy(categorie, ["name"], ["asc", "desc"]);
    orderCate.sort(function (a, b) {
      var n = a.name
        .toLocaleLowerCase()
        .localeCompare(b.name.toLocaleLowerCase());
      return n === 0 && a.name !== b.name ? b.name.localeCompare(a.name) : n;
    });
    setorderCa(orderCate);
    orderCate.forEach((item) => {
      if (
        item.padre == "97bc9ee5-36a0-455b-974c-fa7832c9c341" ||
        item.padre == ""
      ) {
        if (item.active == true) {
          nombre.push(item.name);
        }
      }
    });
    // nombre.splice(0, 0, "Por defecto");
    setOptionsNames(nombre);
  }, [categorie]);

  let nombrePadre = [];
  const dataCopy = (row, idx) => {
    // let dataOrder = []
    if (row.active == true) {
      nombrePadre.push([row.name]);
    }
  };

  const handleShowView = async (vista, show) => {
    // let id = "";
    // views.forEach((el) => {
    //   if (el.active == true) {
    //     id = el.id;
    //   }
    // });
    // if (id != "") actualizarEstado(id, false);
    // actualizarEstado(vista, show);

    // let dataFinal = [];
    // let or = [];
    // let na = [];
    // views.forEach((ele) => {
    //   if (ele.id == vista) {
    //     ele.columns.forEach((elementos) => {
    //       na.push(elementos.name);
    //       if (show) {
    //         name(ele.name);
    //         handleShowColumnChange(elementos.selector, elementos.omit);
    //         if (elementos.position >= 0) {
    //           dataFinal.push(elementos);
    //         }
    //       } else {
    //         if (elementos.selector == "ninguno") {
    //           handleShowColumnChange(elementos.selector, true);
    //         }
    //         if (
    //           elementos.selector == "padre" ||
    //           elementos.selector == "account"
    //         ) {
    //           handleShowColumnChange(elementos.selector, true);
    //         }
    //         if (
    //           elementos.selector != "padre" &&
    //           elementos.selector != "account"
    //         ) {
    //           handleShowColumnChange(elementos.selector, false);
    //         }
    //         setNameView("Lista");
    //       }
    //     });
    //   }
    // });

    // if (show) {
    //   dataFinal.sort((a, b) => {
    //     return a.position - b.position;
    //   });
    // }
    // dataFinal.forEach((element) => {
    //   or.push(element.id);
    // });

    // if (dataFinal.length > 0) {
    //   handleColumnSortChange(or);
    // }
    // if (show == false) {
    //   let idCol = ["name", "createAt", "updateAt", "status", "botones"];
    //   handleColumnSortChange(idCol);
    // }
    let nombres = [];
    let id = views.find((ele) => ele.active);
    if (id) actualizarEstado(id.id, false);
    actualizarEstado(vista, show);
    let vistaEnc = views.find((ele) => ele.id === vista);
    vistaEnc.columns.sort((a, b) => {
      return a.position - b.position;
    });
    vistaEnc.columns.forEach((ele) => {
      if (show) {
        nombres.push(ele.id);
        handleShowColumnChange(ele.selector, ele.omit);
      } else {
        handleShowColumnChange(ele.selector, false);
      }
    });
    if (show) {
      handleColumnSortChange(nombres);
    } else {
      handleColumnSortChange(orderDefault);
    }
  };

  const handleAddView = async (name) => {
    props._showLoader(true);
    let id = views.find((ele) => ele.active);
    console.log("id", id);
    if (id) actualizarEstado(id.id, false);
    let viewEnc = views.find((ele) => ele.name === name);
    let idVIew = "";
    if (viewEnc) idVIew = viewEnc.id;
    else idVIew = v4();
    let body = {
      id: idVIew,
      name: name,
      component: "categories",
      columns: columns,
      account: account,
      user: props.currentUser,
      active: true,
    };
    await API.insertView(body);
    actualizarViews();
    props._showLoader(false);
  };

  const actualizarEstado = async (id, estado) => {
    props._showLoader(true);
    let body = {
      id: id,
      active: estado,
    };
    await API.updateViewActive(body);
    actualizarViews(false);
    props._showLoader(false);
  };

  const handleDeleteView = async (name) => {
    console.log("asss", name);
    props._showLoader(true);
    let id = views.find((ele) => ele.active);
    if (id) {
      if (id.id === name) {
        setNameView("lista");
        columns.forEach((ele) => {
          handleShowColumnChange(ele.selector, false);
        });
        handleColumnSortChange(orderDefault);
      }
    }
    await API.deleteView({ id: name });
    actualizarViews();
    props._showLoader(false);
  };

  const handleColumnSortChange = (positions) => {
    props.utils.log("handleColumnSortChange");
    props.utils.log("positions", positions);

    let aux = columns.map((col) => col);

    positions.forEach((columnId, position) => {
      let index = columns.findIndex((col) => col.selector === columnId);
      if (index != -1) {
        props.utils.log("Index", index);
        aux[index].position = position;
      }
    });

    aux = _.orderBy(aux, ["position"]);

    props.utils.log("new columns", aux);

    setColumns(aux);
    console.log("fillltrooo columnaaa", aux);
  };

  const handleShowColumnChange = (column, show) => {
    props.utils.log("handleFilterTextChange");
    props.utils.log("column", column);
    props.utils.log("show", show);

    let index = columns.findIndex((col) => col.selector === column);
    let aux = columns.map((col) => col);
    if (aux[index]) {
      aux[index].omit = show;
      setColumns(aux);
    }
  };

  const getAccountName = (account) => {
    let acc = _.find(props.accounts, { id: account });
    return acc ? acc.name || "" : "";
  };

  const goToEditPage = (id, name, createAt, padre, hijo) => {
    setIsLoading(true);
    let edit = {
      id,
      name,
      createAt,
      padre,
      hijo,
    };
    analytics.accionesTabla({
      accion: "Editar",
      ubicacion: "Categories",
    });
    props._setEdit(edit);

    window.$.fancybox.open({
      src: "#new-category-modal",
      //type: 'modal',
      opts: {
        modal: true,
        afterClose: () => {
          props._clearEdit();
          getCategoriess();
        },
      },
    });
    setIsLoading(false);
  };

  const deleteCategory = async (id, name) => {
    try {
      setIsLoading(true)
      console.log("loggggg", id, name);
      analytics.accionesTabla({
        accion: "Eliminar Categoria",
        ubicacion: "Categories",
      });
      // let categoriesz = await APILA.getCategories(account);
      let cate = categorieInit;
      let arrayAux = [];
      cate.forEach((el) => {
        let elCat = cate.find((element) => element.id == el.padre);
        arrayAux.push({
          account: el.account,
          active: el.active,
          createAt: el.createAt,
          id: el.id,
          name: el.name,
          padre: el.padre,
          updateAt: el.updateAt,
          nombrePadre: elCat?.name ? elCat?.name : "",
        });
      });
      let title = "";
      let deleteArray = [];
      let bodyDelete = [];
      let found = arrayAux.find((element) => element.id === id);
      arrayAux.map((item) => {
        if (item.padre === found?.id) {
          deleteArray.push(item.id);
          bodyDelete.push(item);
        }
      });
      if (found?.padre == "97bc9ee5-36a0-455b-974c-fa7832c9c341") {
        let count = await APILA.getCountCategories({
          account: props.currentAccount,
          idCategory: id,
          type: true,
        });
        let countCam = count.data.count;
        title = `La categoría "${name}" 
        que desea eliminar contiene ${deleteArray.length} subcategorías que también se eliminarían.  
        Además, está asociada a "${countCam}" campañas.`;

        console.log("es padre");
      } else {
        let count = await APILA.getCountCategories({
          account: props.currentAccount,
          idCategory: id,
          type: false,
        });
        let countCam = count.data.count;
        title = `La categoría "${name}" 
        que desea eliminar contiene ${deleteArray.length} subcategorías que también se eliminarían.  
        Además, está asociada a "${countCam}" campañas.`;
        console.log("es hijo");
      }
      if (found) {
        deleteArray.push(found.id);
        bodyDelete.push(found);
      }
      // props._showConfirmationModal('Eliminar Categoría?', `Por el momento no se pueden eliminar categorias, contacte a soporte `, () => {
      props._showConfirmationModal("¿Eliminar Categoría?", title, () => {
        if (props.currentUser !== "") {
          props._clearCategories();
          props._deleteCategory(
            deleteArray,
            props.currentAccount,
            props.accounts,
            bodyDelete.length !== 0 ? bodyDelete : found,
            props.currentUser
          );
        } else {
          props._clearCategories();
          props._deleteCategory(
            deleteArray,
            props.currentAccount,
            props.accounts,
            bodyDelete.length !== 0 ? bodyDelete : found,
            props.currentUser
          );
          // props._pwaDeleteCategory(id)
        }
        let bodyBitacora = {
          id: v4(),
          component: "categories",
          action: "se ha eliminado una categoria",
          date: new Date(),
          account: account,
        };
        API.insertBitacora(bodyBitacora);
        setTimeout(() => {
          setCat([]);
          setCategorieInit([]);
          getCategoriess();
          console.log("obteniendo categorias");
        }, 2000);
      });
    } catch (error) {
      console.log('Error delete', { error })
    } finally {
      setIsLoading(false)
    }
  };

  const activateCategory = (id, name, createAt, padre, hijo, active) => {
    const updateAt = new Date();
    updateAt.getTime();
    analytics.accionesTabla({
      accion: "Activar Categoria",
      ubicacion: "Categories",
    });
    console.log("currentAcc", account);
    let title = "";
    let found = {
      id,
      name,
      createAt,
      account,
      updateAt,
      padre,
      hijo,
      active,
    };
    console.log("catToEdit", found);
    console.log("props", props);

    if (found.active == false) {
      title = `Esta seguro que desea activar la categoría "${name}"?`;
      props._showConfirmationModal("Activar Categoría?", title, () => {
        console.log("Obteniendo para activar?", filterText);
        console.log("La data actual", id, name, createAt, padre, hijo, active);
        if (padre == "97bc9ee5-36a0-455b-974c-fa7832c9c341") {
          found.active = true;
          props._clearCategories();
          props._activateCategory(props.currentAccount, found);
        }
        if (padre != "97bc9ee5-36a0-455b-974c-fa7832c9c341") {
          console.log("Es una categoria hija la que vas a activar");
          let padreAct = filteredNormal.filter((item) => item.id == padre);
          if (padreAct[0].active == false) {
            padreAct[0].active = true;
            found.active = true;
            let founds = {
              id: padreAct[0].id,
              name: padreAct[0].name,
              createAt: padreAct[0].createAt,
              account: padreAct[0].account,
              updateAt: padreAct[0].updateAt,
              padre: padreAct[0].padre,
              hijo: padreAct[0].hijo,
              active: padreAct[0].active,
            };
            props._clearCategories();
            props._activateCategory(props.currentAccount, founds);
            props._activateCategory(props.currentAccount, found);
          } else {
            found.active = true;
            props._clearCategories();
            props._activateCategory(props.currentAccount, found);
          }
          console.log("Esta es la categoria padre", padreAct);
        }
      });
    }

    setTimeout(() => {
      setCat([]);
      getCategoriess();
      console.log("obteniendo categorias");
    }, 4000);

    //props._showCategoryAlert()

    /* if(found.padre=="97bc9ee5-36a0-455b-974c-fa7832c9c341"){
      title=`Esta seguro que desea activar la categoría "${name}"?`   
      props._showConfirmationModal('Activar Categoría?',title, () => {
        props._clearCategories()
        found.active=true;        
        props._activateCategory(props.currentAccount,found)
      })
    }else{      
      let found2 = categories.find(element => element.id == found.padre)
      console.log("found2",found2)
      title=`Esta seguro que desea activar la categoría "${name}"?`  
      if(found2.active==true){
        props._showConfirmationModal('Activar Categoría?',title, () => {
          props._clearCategories()
          found.active=true;
          props._activateCategory(props.currentAccount,found)
        })
      }else{
        props._showCategoryAlert() 
      }
      
    }
     */
    // props._showConfirmationModal('Eliminar Categoría?', `Por el momento no se pueden eliminar categorias, contacte a soporte `, () => {
    // props._showConfirmationModal('Eliminar Categoría?',title, () => {
    //   if (props.currentUser !== '') {
    //     console.log("Eliminando " , deleteArray)
    //     props._deleteCategory(deleteArray)
    //   } else {
    //     console.log("Eliminando " , deleteArray)
    //     props._deleteCategory(deleteArray)
    //     // props._pwaDeleteCategory(id)
    //   }
    // })
  };

  const formatDate = (millis) => {
    let date = new Date(millis);
    const months = [
      "Ene",
      "Feb",
      "Mar",
      "Abr",
      "May",
      "Jun",
      "Jul",
      "Ago",
      "Sep",
      "Oct",
      "Nov",
      "Dic",
    ];

    return `${date.getDate()} - ${months[date.getMonth()]
      } - ${date.getFullYear()}`;
  };

  const funIdentificador = (padre) => {
    // console.log("funIdentificador",props.categories);
    console.log("funIdentificador", padre);
    // if("21a894c8-6459-462a-8241-9e6a7594fd56"==padre){

    // }
    console.log("funCategories", cat);
    const found = cat.find((element) => element.id == padre);
    console.log("found", found);
    if (found) {
      return found.name;
    } else {
      return "";
    }
    // let categories = []
    // let filtroPadre = props.categories.filter((item)=>{
    //   console.log("ITEM",item);
  };

  const handleShareContact = (contact) => {
    props.utils.log("handleShareContact");
    props.utils.log("contact", contact);

    const currentAccountInfo = _.find(props.accounts, { id: account });
    let members = currentAccountInfo.members || [];
    analytics.Compartir({
      ubicacion: "Categories",
    });
    members = members.filter((member) => member !== contact.id);
    props._updateAccountMembers(account, members);
  };

  let categories = [];

  const onClickpin = (e, categoryInfo) => {
    e.stopPropagation();

    console.log("Ingreso a boton de header");
    console.log("categoryInfo", categoryInfo);
    if (categoryInfo.active == true) {
      deleteCategory(categoryInfo.id, categoryInfo.name);
    } else {
      activateCategory(
        categoryInfo.id,
        categoryInfo.name,
        categoryInfo.createAt,
        categoryInfo.padre,
        categoryInfo.hijo,
        categoryInfo.active
      );
    }
  };

  const onClickTitle = (e, categoryInfo) => {
    e.stopPropagation();
    goToEditPage(
      categoryInfo.id,
      categoryInfo.name,
      categoryInfo.createAt,
      categoryInfo.padre,
      categoryInfo.hijo
    );
  };

  const HeadIco = ({ title, categoryInfo }) => (
    <div className="ContIco">
      <button
        className={"btnDeletCategory" + title}
        onClick={(e) => onClickpin(e, categoryInfo)}
      >
        {title}
      </button>
    </div>
  );
  const HeadIcoTitle = ({ title, categoryInfo }) => (
    <div className="ContIco">
      <a
        className="fas fa-thumbtack"
        onClick={(e) => onClickTitle(e, categoryInfo)}
      ></a>
      {/* <i className='fas fa-sort-down'></i> */}
      <p className="nameTilte"> {title} </p>
    </div>
  );

  // if (props.currentUser !== '') {
  //   categories = _.sortBy(props.categories, ['createAt'])
  // } else {
  //   categories = props.pwaCategories.filter((item)=>{
  //     return item.padre !== ""
  //   })
  // }

  if (props.currentUser !== "") {
  } else {
    categories = props.pwaCategories.filter((item) => {
      return (
        item.padre !== "" ||
        item.padre !== "97bc9ee5-36a0-455b-974c-fa7832c9c341"
      );
    });
  }

  let filteredData = [];

  filteredData = cat?.filter((item) => {
    if (item.name.toString().toLowerCase().includes(filterText.toLowerCase())) {
      return item.name
        .toString()
        .toLowerCase()
        .includes(filterText.toLowerCase());
    }
  });
  const [maximo, setMaximo] = useState(88);
  const [maximo2, setMaximo2] = useState(parseInt(filteredNormal.length / 2));
  /* let filteredData = categories.filter(
    (item) =>
      item.name.toLowerCase().includes(filterText.toLowerCase()) 
  )
 */
  let timeout;
  const handleFilterTextChange = (value) => {
    // Filtro del unico input
    console.log("Valindando los filtros", value);
    /* setFilterText(value) */
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      if (value != "") {
        analytics.busqueda({
          ubicacion: "Categories",
          busqueda: value,
        });
        changeFilteredData(value);

        setFilterText(value);
        setIsSearching(true);
        history.push({
          search: `?search=${value}`,
        });
      } else {
        setIsSearching(false);
        setFilteredNormal(cat);
        setFilterText(value);
        history.push({
          search: "",
        });
      }
    }, 500);

    /* */
  };

  const changeFilteredData = (value) => {
    let search = cat.filter((item) =>
      item.name?.toString().toLowerCase().includes(value.toLowerCase())
    );
    console.log("Resultado de búsqueda", search);
    let padre = search.filter((el) => el.nombrePadre === "");
    console.log("Busqueda de padre", padre);
    if (padre.length > 0) {
      let arrayPadre = [];
      arrayPadre = padre;
      cat.forEach((el) => {
        if (el.padre === padre[0].id) {
          arrayPadre.push(el);
        }
      });
      console.log("ArrayPadre", arrayPadre);
      setFilteredNormal(arrayPadre);
    } else {
      let valueAux = [];
      let valueFinal = [];

      cat.forEach((el) => {
        el.estado = el.active ? "Activo" : "Eliminado";
        if (
          el.name.toString().toLowerCase().includes(value.toLowerCase()) ||
          el.estado.toString().toLowerCase().includes(value.toLowerCase())
        ) {
          valueAux.push(el);
          valueFinal.push(el);
        }
      });

      valueAux.forEach((el) => {
        el.estado = el.active ? "Activo" : "Eliminado";
        let auxx = cat.filter((item) => item.id === el.padre);
        valueFinal.push(...auxx);
      });
      let set = new Set(valueFinal.map(JSON.stringify));
      let arrSinDuplicaciones = Array.from(set).map(JSON.parse);
      console.log("ValueFinal", arrSinDuplicaciones);
      setFilteredNormal(arrSinDuplicaciones);
    }
  };

  if (!_.isEmpty(filters)) {
    setFilteredNormal([
      ...filteredNormal.filter((item) => {
        let result = true;

        for (const filterKey in filters) {
          let response;
          let filter = filters[filterKey];
          let dataValue = item[filterKey];

          props.utils.log("key", filterKey);
          props.utils.log("filter", filter);
          props.utils.log("data value", dataValue);

          switch (filter.type) {
            case "EQUALS":
              response =
                `${filter.value}`.toLowerCase() ===
                `${dataValue}`.toLowerCase();
              break;
            case "INLIST":
              response = filter.value[`${dataValue}`];
              break;
            case "GREATERTHAN":
              response = filter.value === -1 || dataValue > filter.value;
              break;
            case "LESSTHAN":
              response = filter.value === -1 || dataValue < filter.value;
              break;
            case "EQUALTHAN":
              response = filter.value === -1 || filter.value === dataValue;
              break;
            default:
              response = `${dataValue}`
                .toLowerCase()
                .includes(`${filter.value}`.toLowerCase());
              break;
          }

          result = result && response;
        }

        return result;
      }),
    ]);
  }

  let myContacts = [];

  props.clients.map((client) => {
    if (
      client.id !== props.currentUser &&
      client.name !== "" &&
      account !== ""
    ) {
      const currentAccountInfo = _.find(props.accounts, { id: account });
      //props.utils.log('currentAccountInfo', currentAccountInfo)
      // console.log("data de la cuenta", currentAccountInfo?.name);
      if (currentAccountInfo) {
        let members = currentAccountInfo.members || [];
        //props.utils.log('members', members)

        if (!members.includes(client.id)) {
          myContacts = [
            ...myContacts,
            {
              id: client.id,
              name: `${client.name} (${client.email})`,
            },
          ];
        }
      }
    }
  });

  const categoriesPadre = categories.filter((item) => {
    if (
      item.padre == "97bc9ee5-36a0-455b-974c-fa7832c9c341" ||
      item.padre == ""
    ) {
      return item;
    }
  });

  const editCategorie = (value) => {
    let title = "Está seguro que desea editar la categoría?";
    props._showConfirmationModal("Editar categoría", title, async () => {
      let valor = value.find((ele) => ele.id === value[0].id);
      console.log("EL valor que estoy recibiendo", valor);
      analytics.accionesTabla({
        accion: "Editar con doble clic",
        ubicacion: "Categories",
      });
      props
        ._createCategory(
          valor.id,
          valor.value,
          props.currentAccount,
          true,
          valor.column.createAt,
          valor.column.padre,
          undefined,
          props.currentUser,
          1,
          "CATEGORIES"
          // "97bc9ee5-36a0-455b-974c-fa7832c9c341"
        )
        .then(() => {
          props._clearEdit();
          getCategoriess();
        });
    });
  };

  const mapData = (row, idx) => {
    // console.log("entro a mapData", row);
    let groupedData = [];
    let groupedDataSearch = [];
    let isFatherActivated = row.active;
    let isSonActivated = false;
    let a0 = columns.filter((a) => a.id == "name");
    let a1 = columns.filter((a) => a.id == "account");
    let a2 = columns.filter((a) => a.id == "createAt");
    let a3 = columns.filter((a) => a.id == "padre");
    let a4 = columns.filter((a) => a.id == "updateAt");
    let a5 = columns.filter((a) => a.id == "status");
    // let a6 = columns.filter((a) => a.id == "botones");
    let columns2 = [
      {
        id: "name",
        name: row.name,
        omit: a0[0].omit,
        position: a0[0].position,
        name: (
          <DetailColEdit
            text={row.name}
            id={row.id}
            nameColumn={"name"}
            budgetOld={false}
            accounts={[]}
            viewBudget={false}
            onEditValue={editCategorie}
            // editable={row.permisos ? row.permisos.edit : false}
            editable={false}
            type={"text"}
            reActivar={row.permisos.delete ? true : false}
            deleteData={() => deleteCategory(row.id, row.name)}
            activate={row.active}
            deleteV={true}
            edit={true}
            editInfo={() =>
              goToEditPage(row.id, row.name, row.createAt, row.padre, row.hijo)
            }
            activeData={() =>
              activateCategory(
                row.id,
                row.name,
                row.createAt,
                row.padre,
                row.hijo,
                row.active
              )
            }
            permissions={row.permisos ? row.permisos : ""}
          />
        ),
        selector: "name",
        sortable: true,
        center: true,
        group: true,
        style: {
          color: "#02025b",
        },
        type: "text",
        cell: (row) =>
          row.solita === true ? (
            " "
          ) : (
            // <DetailCol
            //   text={row.name}
            //   row={row}
            //   permisos={row.permisos ? row.permisos : ''}
            //   onClick={() =>
            //     goToEditPage(
            //       row.id,
            //       row.name,
            //       row.createAt,
            //       row.padre,
            //       row.hijo
            //     )
            //   }
            //   className="edit-fc"
            // />
            <DetailColEdit
              row={row}
              text={row.name}
              id={row.id}
              nameColumn={row}
              deleteV={true}
              budgetOld={false}
              accounts={[]}
              viewBudget={false}
              onEditValue={editCategorie}
              editable={false}
              type={"text"}
              edit={true}
              editInfo={() =>
                goToEditPage(
                  row.id,
                  row.name,
                  row.createAt,
                  row.padre,
                  row.hijo
                )
              }
              reActivar={row.permisos.delete ? true : false}
              deleteData={() => deleteCategory(row.id, row.name)}
              activate={row.active}
              activeData={() =>
                activateCategory(
                  row.id,
                  row.name,
                  row.createAt,
                  row.padre,
                  row.hijo,
                  row.active
                )
              }
              permissions={row.permisos ? row.permisos : ""}
            />
          ),
      },
      // {
      //   id: "account",
      //   name: "Cuenta",
      //   selector: "account",
      //   sortable: true,
      //   omit: a1[0].omit,
      //   position: a1[0].position,
      //   center: true,
      //   style: {
      //     fontWeight: "bold",
      //   },
      //   type: "text",
      //   cell: (row) => getAccountName(row.account),
      // },
      {
        id: "createAt",
        name: "Fecha de Creación",
        selector: "createAt",
        sortable: true,
        center: true,
        omit: a2[0].omit,
        position: a2[0].position,
        minWidth: "4rem",
        style: {
          fontWeight: "bold",
        },
        cell: (row) => (row.solita === true ? " " : formatDate(row.createAt)),
      },
      // {
      //   id: "padre",
      //   name: "Categoría",
      //   selector: "padre",
      //   sortable: true,
      //   omit: a3[0].omit,
      //   position: a3[0].position,
      //   center: true,
      //   style: {
      //     fontWeight: "bold",
      //   },
      //   cell: (row) => row.nombrePadre,
      // },
      {
        id: "updateAt",
        name: "Última Actualización",
        selector: "updateAt",
        sortable: true,
        omit: a4[0].omit,
        position: a4[0].position,
        center: true,
        style: {
          fontWeight: "bold",
        },
        cell: (row) => (row.solita === true ? " " : formatDate(row.updateAt)),
      },
      {
        id: "status",
        name: "Status",
        selector: "status",
        sortable: true,
        omit: a5[0].omit,
        position: a5[0].position,
        center: true,
        style: {
          fontWeight: "bold",
        },
        cell: (row) =>
          row.solita === true ? (
            " "
          ) : (
            <Status
              value={row.active == true ? "Activo" : "Eliminado"}
              label={getStatusLabel(row.active)}
              className={getStatusClassName(row.active)}
              justifyContent={"center"}
              center={"true"}
            />
          ),
      },
      // {
      //   id: "botones",
      //   name: (
      //     <HeadIco
      //       title={row.active == true ? "Eliminar" : "Activar"}
      //       categoryInfo={row}
      //     />
      //   ),
      //   selector: "botones",
      //   sortable: false,
      //   button: true,
      //   omit: a6[0].omit,
      //   position: a6[0].position,
      //   maxWidth: "110px",
      //   cell: (row) =>
      //     row.solita === true ? (
      //       <></>
      //     ) : row.active == true ? (
      //       <Actions onClick={() => deleteCategory(row.id, row.name)} />
      //     ) : (
      //       <ActionsActivate
      // onClick={() =>
      //   activateCategory(
      //     row.id,
      //     row.name,
      //     row.createAt,
      //     row.padre,
      //     row.hijo,
      //     row.active
      //   )
      //         }
      //       />
      //     ),
      // },
    ];

    columns2.sort((a, b) => {
      return a.position - b.position;
    });
    const customStyles = {
      headCells: {
        style: {
          fontWeight: "bold",
          color: "#000",
          fontSize: "1rem",
          textAlign: "center",
          justifyContent: "center",
          backgroundColor: "#f7f7f7",
          "&:nth-of-type(1)": {
            maxWidth: "8rem",
            minWidth: "8rem",
            fontWeight: "bold",
            color: "#ff5",
          },
          "&:nth-of-type(2n)": {
            backgroundColor: "#E3E4E6",
            borderRadius: "10px 10px 0 0",
          },
        },
      },
      cells: {
        style: {
          justifyContent: "center",
          backgroundColor: "#f7f7f7",
          "&:nth-of-type(1)": {
            justifyContent: "left",
          },
          "&:nth-of-type(2n)": {
            backgroundColor: "#E3E4E6",
          },
          "&:nth-of-type(4)": {
            maxWidth: "8rem",
          },
        },
      },
    };
    groupedData = filteredNormal.filter((item) => {
      if (row.id == item.padre) {
        if (isSearching == true) {
          return item;
        } else {
          if (item.active) {
            return item;
          }
        }
      }
    });
    if (groupedData.length < 1) {
      groupedData = [
        {
          name: " ",
          account: " ",
          createAt: " ",
          updateAt: " ",
          solita: true,
        },
      ];
    }
    if (groupedData.length > 0) {
      isSonActivated = true;
    }
    groupedDataSearch = filteredNormal.filter((item) => {
      if (row.id == item.padre) {
        return item;
      }
    });
    if (groupedDataSearch.length < 1) {
      groupedDataSearch = [
        {
          name: " ",
          account: " ",
          createAt: " ",
          updateAt: " ",
          solita: true,
        },
      ];
    }
    let data = filterText == "" ? groupedData : groupedDataSearch;
    if (data.length !== 0) {
      data.forEach((ele) => {
        ele.permisos = permissions;
      });
    }
    if (row.active == true || isSearching == true) {
      if (isFatherActivated || isSonActivated) {
        return (
          <>
            <div className="scroll_agrupaciones tablaCat" key={idx}>
              {/* {console.log("TextoAle", groupedData)} */}
              {nameGrouped != "" && <h1>{row.name}</h1>}
              <ReactDataTable
                className="categoryTable"
                progressComponent={<LinearIndeterminate />}
                progressPending={false}
                // onSort={handleSort}
                data={data}
                columns={columns2}
                persistTableHead
                expandable
                showTable={false}
                customStyles={customStyles}
                maxWidth={"800px"}
              />
            </div>
            {nameGrouped != "" && (
              <p className="paginador">
                <span>Rows per page</span> {parseInt(data.length)}
              </p>
            )}
          </>
        );
      } else {
        return null;
      }
    } else {
      return null;
    }
  };

  // const maximo = filteredData.length/2
  // console.log("divisioooooon", maximo);
  // console.log("divisioooooon", maximo);
  let cate = [];
  cat.forEach((el) => {
    if (el.active) {
      cate.push(el);
    }
  });
  const changeGrouped = (value) => {
    analytics.agrupar({
      ubicacion: "Categories",
    });
    let options = optionsNames;
    props._showLoader(true);
    switch (value) {
      case "Por defecto":
        // options.splice(0, 0, "Por defecto");
        // setNameGrouped("");
        setNameGrouped("");
        setGroups("default");
        urlChanged({
          currentView: viewId,
          currentGroups: "default",
        });
        break;

      default:
        // options.splice(0, 0, value);
        // options.splice(1, 1, "Por defecto");
        // setNameGrouped(value);
        setNameGrouped(value);
        setGroups(value);
        urlChanged({
          currentGroups: value,
          currentView: viewId,
        });
        break;
    }
    let result = options.filter((item, idx) => {
      return options.indexOf(item) === idx;
    });
    setOptionsNames(result);
    setTimeout(() => {
      props._showLoader(false);
    }, 500);
  };

  if (props.isIpv4) {
    return (
      <>
        {isLoading
          ?
          <PageLoader />
          :
          <>
            {categorieInit.length > 0 && (
              <div className="barraSuperior">
                <DataTableUtils
                  data={categories}
                  action={() => {
                    const mCurrentAccount =
                      props.currentUser !== ""
                        ? props.currentAccount
                        : props.currentPWAAccount;

                    if (mCurrentAccount === "") {
                      Swal.fire({
                        title: "Cuenta Requerida",
                        text: "Para utilizar esta sección, debes crear una cuenta.",
                        icon: "warning",
                      }).then((response) => {
                        if (!response.isConfirmed) return
                        if (props?.accounts.length > 0) return
                        props._nexStep(1)
                      })
                      return;
                    }
                    analytics.Add({
                      ubicacion: "Categories",
                    });
                    window.$.fancybox.open({
                      src: "#new-category-modal",
                      data: "new-category",
                      //type: 'modal',
                      opts: {
                        modal: true,
                        afterClose: () => {
                          getCategoriess();
                        },
                      },
                    });
                  }}
                  actionIcon={
                    <Tooltip
                      title="Crear categoría"
                      color="#002448"
                      placement="top"
                    >
                      <i className="fas fa-plus" />
                    </Tooltip>
                  }
                  onChangeFilterText={handleFilterTextChange}
                >
                  <Tooltip
                    title="Exportar información"
                    color="#002448"
                    placement="top"
                  >
                    <div
                      onClick={() => {
                        analytics.Compartir({
                          ubicacion: "Categories",
                        });
                      }}
                      className="ExportBtn"
                    >
                      <Share
                        data={exportDataToExcel()}
                        columns={columns}
                        isArray={true}
                        exportXLSX={true}
                        exportTXT={true}
                        exportDropbox={true}
                        exportDrive={true}
                        exportCSV={true}
                        printable={true}
                        exportPNG={true}
                        exportLink={true}
                        idImg={"root"}
                        appKey="2u78gno172idwbz"
                      />
                    </div>
                  </Tooltip>
                  <Tooltip
                    title="Personalizar columnas"
                    color="#002448"
                    placement="top"
                  >
                    <div id="shareBtn">
                      <ShowColumn2
                        columns={columns}
                        showColumn={(data, data1) => {
                          analytics.ShowHide({
                            ubicacion: "Categories",
                          });
                          handleShowColumnChange(data, data1);
                        }}
                        showView={handleShowView}
                        onSortChange={(positions) => {
                          analytics.Columnas({
                            ubicacion: "Categories",
                          });
                          handleColumnSortChange(positions);
                        }}
                        vistas={views}
                        setVista={(nombre) => {
                          analytics.accionesVista({
                            ubicacion: "Categories",
                            accion: "Agregar",
                          });
                          handleAddView(nombre);
                        }}
                        deleteView={(name) => {
                          analytics.accionesVista({
                            ubicacion: "Categories",
                            accion: "ELiminar",
                          });
                          handleDeleteView(name);
                        }}
                        nameAc={nameView}
                      />
                    </div>
                  </Tooltip>
                  <Tooltip
                    title="Filtrar y agrupar"
                    color="#002448"
                    placement="top"
                  >
                    <div className="Filtro2 filtro3 " id="FiltroCategorias">
                      <FilterColumn2
                        columns={columns}
                        options={optionsNames}
                        changeGrouped={changeGrouped}
                        data={cate}
                        onFilterColumn={handleFilterPerColumn}
                        filterInfoCheck={filterInfo}
                        categories={nombrePadre}
                      />
                    </div>
                  </Tooltip>
                </DataTableUtils>
              </div>
            )}

            {categorieInit.length > 0 ? (
              <>
                <div className="Kanbang-content">
                  <div className="contenedorTablasCat">
                    {nameGrouped == ""
                      ? filteredNormal.map((item, idx) => {
                        if (
                          item.padre ==
                          "97bc9ee5-36a0-455b-974c-fa7832c9c341" ||
                          item.padre == ""
                        ) {
                          item.permisos = permissions;

                          return mapData(item, idx);
                        }
                      })
                      : filteredNormal.map((item, idx) => {
                        if (
                          item.padre ==
                          "97bc9ee5-36a0-455b-974c-fa7832c9c341" ||
                          item.padre == ""
                        ) {
                          if (item.name == nameGrouped) {
                            item.permisos = permissions;
                            return mapData(item, idx);
                          }
                        }
                      })}
                    {orderCa.map((item, idx) => {
                      if (
                        item.padre == "97bc9ee5-36a0-455b-974c-fa7832c9c341" ||
                        (item.padre == "" && item.active == true)
                      ) {
                        item.permisos = permissions;
                        return dataCopy(item, idx);
                      }
                    })}
                  </div>
                </div>
                {nameGrouped == "" && (
                  <p className="paginador">
                    <span>Rows per page</span> {parseInt(filteredNormal.length)}
                  </p>
                )}
              </>
            ) : (
              <div className="form homeSinLogin">
                <div>
                  <p className="subTitle">Bienvenido a Let's Advertise</p>
                  <h1 className="title">Crea tu primera categoría</h1>
                  <a
                    href="#"
                    className="btn-plus"
                    onClick={() => {
                      const mCurrentAccount =
                        props.currentUser !== ""
                          ? props.currentAccount
                          : props.currentPWAAccount;

                      if (mCurrentAccount === "") {
                        Swal.fire({
                          title: "Cuenta Requerida",
                          text: "Para utilizar esta sección, debes crear una cuenta.",
                          icon: "warning",
                        }).then((response) => {
                          if (!response.isConfirmed) return
                          if (props?.accounts.length > 0) return
                          props._nexStep(1)
                        })
                        return;
                      }
                      analytics.Add({
                        ubicacion: "Categories",
                      });
                      window.$.fancybox.open({
                        src: "#new-category-modal",
                        data: "new-category",
                        opts: {
                          modal: true,
                          afterClose: () => {
                            getCategoriess();
                          },
                        },
                      });
                    }}
                  >
                    <i className="fas fa-plus" />
                  </a>
                </div>
                <div></div>
              </div>
            )}
            {props.step == 1 && <CreateAccountLogOut clase={"component"} />}
          </>
        }
      </>
    )
  }

  return (
    <>
      {permissions.view ? (
        <>
          {categorieInit.length > 0 && (
            <div className="barraSuperior">
              <DataTableUtils
                data={categories}
                action={
                  permissions.create
                    ? () => {
                      const mCurrentAccount =
                        props.currentUser !== ""
                          ? props.currentAccount
                          : props.currentPWAAccount;

                      if (mCurrentAccount === "") {
                        props._showSelectAccountModal();
                        return;
                      }
                      analytics.Add({
                        ubicacion: "Categories",
                      });
                      window.$.fancybox.open({
                        src: "#new-category-modal",
                        data: "new-category",
                        //type: 'modal',
                        opts: {
                          modal: true,
                          afterClose: () => {
                            console.log("Regresando a CXategorias");
                            getCategoriess();
                          },
                        },
                      });
                    }
                    : null
                }
                actionIcon={
                  permissions.create && (
                    <Tooltip
                      title="Crear categoría"
                      color="#002448"
                      placement="top"
                    >
                      <i className="fas fa-plus" />
                    </Tooltip>
                  )
                }
                onChangeFilterText={handleFilterTextChange}
              >
                <Tooltip
                  title="Exportar información"
                  color="#002448"
                  placement="top"
                >
                  <div
                    onClick={() => {
                      analytics.Compartir({
                        ubicacion: "Categories",
                      });
                    }}
                    className="ExportBtn"
                  >
                    {permissions.export && (
                      <Share
                        // data={columns}
                        // contacts={myContacts}
                        // onShareContact={handleShareContact}
                        // contacts={myContacts}
                        data={exportDataToExcel()}
                        columns={columns}
                        isArray={true}
                        exportXLSX={true}
                        exportTXT={true}
                        exportDropbox={true}
                        exportDrive={true}
                        exportCSV={true}
                        printable={true}
                        exportPNG={true}
                        exportLink={true}
                        idImg={"root"}
                        appKey="2u78gno172idwbz"
                      />
                    )}
                  </div>
                </Tooltip>
                {/* <ShowColumn
          columns={columns}
          showColumn={handleShowColumnChange}
          onSortChange={handleColumnSortChange}
        /> */}
                <Tooltip
                  title="Personalizar columnas"
                  color="#002448"
                  placement="top"
                >
                  <div id="shareBtn">
                    <ShowColumn2
                      columns={columns}
                      showColumn={(data, data1) => {
                        analytics.ShowHide({
                          ubicacion: "Categories",
                        });
                        handleShowColumnChange(data, data1);
                      }}
                      showView={handleShowView}
                      onSortChange={(positions) => {
                        analytics.Columnas({
                          ubicacion: "Categories",
                        });
                        handleColumnSortChange(positions);
                      }}
                      vistas={views}
                      // setVista={handleAddView}
                      // deleteView={handleDeleteView}
                      setVista={(nombre) => {
                        analytics.accionesVista({
                          ubicacion: "Categories",
                          accion: "Agregar",
                        });
                        handleAddView(nombre);
                      }}
                      deleteView={(name) => {
                        analytics.accionesVista({
                          ubicacion: "Categories",
                          accion: "ELiminar",
                        });
                        handleDeleteView(name);
                      }}
                      nameAc={nameView}
                    />
                  </div>
                </Tooltip>
                <Tooltip
                  title="Filtrar y agrupar"
                  color="#002448"
                  placement="top"
                >
                  <div className="Filtro2 filtro3 " id="FiltroCategorias">
                    <FilterColumn2
                      columns={columns}
                      options={optionsNames}
                      changeGrouped={changeGrouped}
                      data={cate}
                      groupedDefault={groups}
                      onFilterColumn={handleFilterPerColumn}
                      filterInfoCheck={filterInfo}
                      categories={nombrePadre}
                    />
                  </div>
                </Tooltip>
              </DataTableUtils>
            </div>
          )}

          {categorieInit.length > 0 ? (
            <>
              <div className="Kanbang-content">
                <div className="contenedorTablasCat">
                  {nameGrouped == "" /*.slice(
          (pagina - 1)* counterPerPage,
          (pagina - 1)* counterPerPage + counterPerPage
        )*/
                    ? filteredNormal.map((item, idx) => {
                      if (
                        item.padre ==
                        "97bc9ee5-36a0-455b-974c-fa7832c9c341" ||
                        item.padre == ""
                      ) {
                        // if(item.padre=="97bc9ee5-36a0-455b-974c-fa7832c9c341" ){
                        item.permisos = permissions;

                        return mapData(item, idx);
                      }
                    })
                    : filteredNormal.map((item, idx) => {
                      if (
                        item.padre ==
                        "97bc9ee5-36a0-455b-974c-fa7832c9c341" ||
                        item.padre == ""
                      ) {
                        if (item.name == nameGrouped) {
                          item.permisos = permissions;
                          return mapData(item, idx);
                        }
                      }
                    })}
                  {orderCa.map((item, idx) => {
                    if (
                      item.padre == "97bc9ee5-36a0-455b-974c-fa7832c9c341" ||
                      (item.padre == "" && item.active == true)
                    ) {
                      item.permisos = permissions;
                      // if(item.padre=="97bc9ee5-36a0-455b-974c-fa7832c9c341" ){
                      return dataCopy(item, idx);
                    }
                  })}
                </div>
              </div>
              {nameGrouped == "" && (
                <p className="paginador">
                  <span>Rows per page</span> {parseInt(filteredNormal.length)}
                </p>
              )}
            </>
          ) : (
            <div className="form homeSinLogin">
              {/* <div className='form-margin'> */}
              <div>
                <p className="subTitle">Bienvenido a Let's Advertise</p>
                <h1 className="title">Crea una categoría</h1>
                {permissions.create && (
                  <a
                    href="#"
                    className="btn-plus"
                    onClick={() => {
                      const mCurrentAccount =
                        props.currentUser !== ""
                          ? props.currentAccount
                          : props.currentPWAAccount;

                      if (mCurrentAccount === "") {
                        props._showSelectAccountModal();
                        return;
                      }
                      analytics.Add({
                        ubicacion: "Categories",
                      });
                      window.$.fancybox.open({
                        src: "#new-category-modal",
                        data: "new-category",
                        //type: 'modal',
                        opts: {
                          modal: true,
                          afterClose: () => {
                            console.log("Regresando a CXategorias");
                            getCategoriess();
                          },
                        },
                      });
                    }}
                  >
                    <i className="fas fa-plus" />
                  </a>
                )}
              </div>
              <div></div>
              {/* </div> */}
            </div>
          )}
        </>
      ) : (
        <h1>No tienes permisos</h1>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  step: state.userLogout.nextStepCategory,
  isIpv4: state.app.isIPV4,
  clients: state.clients,
  accounts: state.accounts.list,
  categories: state.categories.list,
  currentUser: state.app.current_user,
  currentAccount: state.accounts.default,
  pwaCategories: state.pwa.categories.list,
  currentPWAAccount: state.pwa.accounts.default,
});

const mapDispatchToProps = (dispatch) => ({
  _nexStep: (step) => dispatch(nextStep(step)),
  _clearEdit: () => dispatch(clearEdit()),
  _getClients: () => dispatch(getClients()),
  _setEdit: (edit) => dispatch(setEdit(edit)),
  _showLoader: (show) => dispatch(showLoader(show)),
  _clearCategories: () => dispatch(clearCategories()),
  _deleteCategory: (id, account, accounts, body, usuario) =>
    dispatch(deleteCategory(id, account, accounts, body, usuario)),
  _setFilterText: (text) => dispatch(setFilterText(text)),
  _pwaDeleteCategory: (id) => dispatch(pwaDeleteCategory(id)),
  _getCategories: (account, user, idmod, mod) =>
    dispatch(getCategories(account, user, idmod, mod)),
  _activateCategory: (account, body) =>
    dispatch(activateCategory(account, body)),
  _updateAccountMembers: (account, members) =>
    dispatch(updateAccountMembers(account, members)),
  _showSelectAccountModal: () =>
    dispatch(
      Modal.showAlertModal(
        "Cuenta Requerida",
        "Por favor selecciona una cuenta antes",
        Modal.MODAL_TYPE_WARNING,
        () => { }
      )
    ),
  _showCategoryAlert: () =>
    dispatch(
      Modal.showAlertModal(
        "Advertencia",
        "Debe activar la categoria padre primero",
        Modal.MODAL_TYPE_WARNING,
        () => { }
      )
    ),
  _showConfirmationModal: (title, message, callback) =>
    dispatch(
      Modal.showAlertModal(
        title,
        message,
        Modal.MODAL_TYPE_CONFIRMATION,
        callback
      )
    ),
  _createCategory: (
    id,
    name,
    account,
    edit,
    createAt,
    padre,
    tipo,
    user,
    idmod,
    mod
  ) =>
    dispatch(
      createCategory(
        id,
        name,
        account,
        edit,
        createAt,
        padre,
        tipo,
        user,
        idmod,
        mod
      )
    ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withUtils(withRouter(Content)));
