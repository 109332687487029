import { APP_CLEAR_CURRENT_USER } from '../actions/app.actions'
import * as Actions from '../actions/users.actions'

const initialState = []

export default (state = initialState, action) => {
  switch (action.type) {
    case Actions.CLEAN_USER:
      return []
    case Actions.ADD_USER:
       return [...state, action.users]
    case APP_CLEAR_CURRENT_USER:
      return initialState
    default:
      return state
  }
}