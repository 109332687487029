import React from "react";
import { SimpleText } from "./simpleText";
import { useSelector } from "react-redux";
import { Tooltip } from "antd";
import { TOTALES } from "../constants";

const CustomCellFormat = ({ row, editValueFormat }) => {
  const propsEdit = useSelector((state) => state.templates.edit);
  const formatTemplate = useSelector((state) => state.templates.listFormat);
  const dataUser = useSelector((state) => state.templates.userInfo);
  let editCell = !row?.formato && row.name !== TOTALES ? true : false;

  if (row?.name === TOTALES) return <></>;

  return (
    <Tooltip title={`${row?.formato}` || ""} color="#002448" placement="top">
      <div style={{ display: "flex", alignItems: "center" }}>
        {editCell && (
          <i
            className="fa fa-exclamation-circle text-danger"
            aria-hidden="true"
            style={{ marginRight: "8px" }}
          ></i>
        )}
        <SimpleText
          value={
            row?.formato && row.formato.length > 30
              ? row?.formato.substr(0, 25) + "..."
              : row?.formato || ""
          }
          id={{ id: row.id, campo: "formato" }}
          type={"select"}
          editable={true}
          editCell={editCell}
          dataSelect={row?.optionsFormat?.map((format) => format.art) || []}
          nameColumn={row}
          onEditValue={(value) =>
            editValueFormat(
              value,
              propsEdit,
              row?.optionsFormat || [],
              dataUser
            )
          }
        />
      </div>
    </Tooltip>
  );
};

export default CustomCellFormat;
