import React from "react";
// components
import Content from "components/ConfigEmails";
import MetaTags from "components/metatags";

const ConfigEmails = () => (
  <>
    <div className="contAccount">
      <MetaTags
        title="Emails"
        description="Página de configuración de emails"
      />
      <Content />
    </div>
  </>
);

export default ConfigEmails;
