import React, { useEffect, useState } from 'react';
import { DatePicker, Select } from 'antd';
import moment from 'moment';
import dayjs from 'dayjs';
import { connect } from 'react-redux';
import { editData } from 'redux/actions/schedule.actions';
import { parse, isWithinInterval, isValid } from 'date-fns';
import { Share } from "@easygosanet/react-datatable-utils";
import { Tooltip } from "antd";
import _ from 'lodash';
import "./style.scss";

const freeDays = true
const moduleComponent = "Horarios de atención"

const MultipleDatePicker = (props) => {
    const [disabled, setDisabled] = useState({});
    const disabledDate = (current) => {
        return current && current < dayjs().endOf('day');
    };
    const fechaActual = new Date().toLocaleDateString('es-ES', { day: '2-digit', month: '2-digit', year: 'numeric' }).split('/').reverse().join('/');
    const fechasFiltradas = props?.selectedDates?.filter(fecha => {
        const [dia, mes, anio] = fecha.split('/');
        const fechaComparar = new Date(`${mes}/${dia}/${anio}`);
        return fechaComparar > new Date(fechaActual);
    });

    const [filterDates, setFilterDates] = useState(fechasFiltradas);

    const handleDateChange = async (dates) => {
        if (dates) {
            const newDate = moment(dates, 'DD/MM/YYYY');
            if (!newDate.isValid()) return
            const newDateString = newDate.format('DD/MM/YYYY');
            if (props.selectedDates.includes(newDateString)) return
            const updatedDates = [...props.selectedDates, newDateString];
            props.setSelectedDates(updatedDates);
            setFilterDates(updatedDates);
            await props._editData(updatedDates, props.edit, freeDays);
        }
    };

    const handleSelectChange = async (values) => {
        // Aqui se elimina
        let newArray = values?.map(value => value)
        props.setSelectedDates(newArray);
        setFilterDates(newArray);
        await props._editData(newArray, props.edit, freeDays)
    };

    useEffect(() => {
        const findPermisos = props.permits?.find(permiso => permiso.name === moduleComponent)
        setDisabled(findPermisos)
    }, [props.permits])

    const exportDataToExcel = () => {
        let headers = ["Dia", "Hora inicial", "Hora final", "Activo"];
        let dataExcel = [headers];

        if (!_.isEmpty(props.edit?.workDays)) {
            props.edit?.workDays?.forEach((ele) => {
                let excelData = [
                    ele?.day,
                    ele?.timeInitial,
                    ele?.timeFinish,
                    ele?.checked ? "Si" : "No",
                    ele?.freeDays
                ];
                dataExcel.push(excelData);
            });
            dataExcel.push([" ", " ", " ", " ", " "]);
            dataExcel.push(["Dias libres", props?.edit?.freeDays?.join(", ")]);
        }
        return dataExcel;
    };

    const handleDateRangeChange = (dates, dateStrings) => {
        if (!dateStrings) {
            // * Por si no viene la fecha
            setFilterDates(fechasFiltradas);
            return;
        } else {
            try {
                const fechaInicial = parse(dateStrings[0], 'dd/MM/yyyy', new Date());
                const fechaFinal = parse(dateStrings[1], 'dd/MM/yyyy', new Date());

                if (!isValid(fechaInicial) || !isValid(fechaFinal)) {
                    // * Si la fecha no es valida
                    setFilterDates(fechasFiltradas);
                    return;
                }

                const filteredDates = props.edit.freeDays.filter(fecha => {
                    const fechaDateFns = parse(fecha, 'dd/MM/yyyy', new Date());
                    return isWithinInterval(fechaDateFns, { start: fechaInicial, end: fechaFinal });
                });

                setFilterDates(filteredDates);
            } catch (error) {
                console.error('Error al procesar fechas:', error);
            }
        }
    };

    return (
        <>
            <div className='container_free_days' >
                <div className='sub_cointainer_free_days' >
                    <h3 className='free_days_text' >Días libres</h3>
                    <div className='share_container_button' >
                        {disabled?.export && (
                            <Tooltip
                                title="Exportar información"
                                color="#002448"
                                placement="top"
                            >
                                <div className='btn_import' >
                                    <Share
                                        data={exportDataToExcel()}
                                        columns={[]}
                                        isArray={true}
                                        exportTemplate={false}
                                        exportXLSX={true}
                                        exportTXT={true}
                                        exportDropbox={true}
                                        exportDrive={true}
                                        exportCSV={false}
                                        printable={true}
                                        exportPNG={true}
                                        exportLink={true}
                                        idImg={"root"}
                                        appKey="2u78gno172idwbz"
                                    />
                                </div>
                            </Tooltip>
                        )}
                    </div>
                </div>
                <div style={{ width: '100%', marginBottom: '20px' }}>
                    <DatePicker
                        onChange={handleDateChange}
                        format="DD/MM/YYYY"
                        allowClear={true}
                        style={{ width: '100%' }}
                        disabled={!disabled?.create}
                        disabledDate={disabledDate}
                        placeholder="Agregar fechas"
                    />
                </div>
                <span style={{ marginBottom: '10px' }}>Filtrar fechas</span>
                <div className='dates_picker_container' >

                    <DatePicker.RangePicker
                        onChange={handleDateRangeChange}
                        format="DD/MM/YYYY"
                        allowClear={true}
                        // style={{ width: '100%' }}
                        className='range_picker_container'
                        disabled={!disabled?.create}
                        placeholder={['Fecha inicial', 'Fecha final']}
                    />
                    <Select
                        mode="multiple"
                        // style={{ width: '400px' }}
                        placeholder="Seleccionar fechas"
                        className='select_picker_container'
                        value={filterDates?.map(date => date)}
                        onChange={handleSelectChange}
                        disabled={!disabled?.edit}
                    >
                    </Select>
                </div>
            </div>
        </>
    );

};

const mapStateToProps = (state) => ({
    onSave: state.schedule.onSave,
    edit: state.schedule.edit,
    permits: state.schedule.permisos
})

const mapDispatchToProps = (dispatch) => ({
    _editData: (newEdit, edit, freeDays) => dispatch(editData(newEdit, edit, freeDays)),

})

export default connect(mapStateToProps, mapDispatchToProps)(MultipleDatePicker);
