import { useState, useEffect, useRef } from "react";
import Editor from "components/EditorText";
import * as APILA from "helpers/apiLA";
import makeAnimated from "react-select/animated";
import MySelect from "components/CampOneStep/MySelect";
import CreatableSelect from "libs/select/creatable";
import "../style.scss";
import _, { set } from "lodash";
import Swal from "sweetalert2";
import PageLoader from "components/page_loader";
import { components } from "react-select";
import { Input, FormFeedback } from "reactstrap";
import { v4 } from "uuid";
const defaultState = {
  campo: "",
  operador: "igual a",
  contraValidacion: "",
  condicional: "y",
  position: 0,
};
const Row = ({ dataRow, onRemove, onChange, opciones }) => {
  const fechas = ["createAt", "fechaIni", "updateAt", "lastModified"];
  let campo = dataRow.campo !== "" ? dataRow.campo : "DEFAULT";
  let operador = dataRow.operador !== "" ? dataRow.operador : "";
  let contraValidacion =
    dataRow.contraValidacion !== "" ? dataRow.contraValidacion : "";
  let condicional = dataRow.condicional !== "" ? dataRow.condicional : "";
  let positionD = dataRow.position;

  const [data, setData] = useState({
    campo: campo,
    operador: operador,
    contraValidacion: contraValidacion,
    condicional: condicional,
    position: positionD,
  });
  useEffect(() => {
    if (
      data.contraValidacion !== dataRow.contraValidacion ||
      data.campo !== dataRow.campo ||
      data.condicional !== dataRow.condicional
    ) {
      onChange(data);
    }
  }, [data]);

  useEffect(() => {
    let datos = {
      campo: campo,
      operador: operador,
      contraValidacion: contraValidacion,
      condicional: condicional,
      position: positionD,
    };
    setData(datos);
  }, [dataRow]);

  const hanleChangeOptions = (e) => {
    e.persist();
    let { name, value } = e.target;
    setData((prev) => ({ ...prev, [name]: value }));
  };
  return (
    <>
      <label className="area1">
        Campo
        <select
          name="campo"
          onChange={(e) => hanleChangeOptions(e)}
          value={data.campo}
          placeholder="selecciona una opción"
        >
          {opciones.length > 0 ? (
            <>
              <option value={"DEFAULT"} disabled>
                selecciona una opción:
              </option>
              {opciones.map((ele, idx) => {
                if (ele.value !== "userName" && ele.value !== "accountName") {
                  return (
                    <option value={ele.value} key={idx}>
                      {ele.label}
                    </option>
                  );
                }
              })}
            </>
          ) : (
            <option>No hay opciones</option>
          )}
        </select>
      </label>
      <label className="operador area2">
        Operador
        <select
          name="operador"
          value={data.operador}
          onChange={(e) => hanleChangeOptions(e)}
          placeholder="selecciona una opción"
        >
          <option value={"igual a"}>Igual a</option>
          <option value={"Dentro"}>Dentro</option>
          <option value={"mayor que"}>mayor que</option>
          <option value={"diferente"}>!=</option>
          <option value={"menor que"}>menor que</option>
        </select>
      </label>
      <label className="area3">
        Campo contra validación
        <Input
          type={fechas.includes(data.campo) ? "date" : "text"}
          name="contraValidacion"
          className="inputEmails"
          bsSize="sm"
          value={data.contraValidacion}
          onChange={(e) => hanleChangeOptions(e)}
          placeholder="Ingresa la validación"
          invalid={false}
        />
        <FormFeedback>Ingresa un cuerpo de mensaje</FormFeedback>
      </label>
      <label className="area4">
        <select
          value={data.condicional}
          name="condicional"
          onChange={(e) => hanleChangeOptions(e)}
        >
          <option value={"y"}>y</option>
          <option value={"o"}>o</option>
        </select>
      </label>

      <button className="btns area5" onClick={() => onRemove(dataRow)}>
        <i className="fas fa-times-circle"></i>
      </button>

      <button className="area6"></button>
    </>
  );
};

const bodyConfigLets = {
  module: "",
  channel: "email",
  action: "",
  restriction: "Usuario con acceso",
  userByRol: [],
};

const optionsValidations = [
  {
    id: "",
    name: "Campañas",
    options: [
      {
        label: "Nombre",
        value: "name",
        check: false,
      },
      {
        label: "Archivos",
        value: "files",
        check: false,
      },
      {
        label: "Cuenta",
        value: "accountName",
        check: false,
      },
      {
        label: "Usuario de aprobación",
        value: "ApprovalUser",
        check: false,
      },
      {
        label: "Usuario de edición",
        value: "userEdit",
        check: false,
      },
      {
        label: "Fecha de inicio",
        value: "startDate",
        check: false,
      },
      {
        label: "Fecha de fin",
        value: "endDate",
        check: false,
      },
      {
        label: "Id",
        value: "id",
        check: false,
      },
      {
        label: "Orden de venta",
        value: "id_sale",
        check: false,
      },
      {
        label: "País",
        value: "country",
        check: false,
      },
      {
        label: "Medio",
        value: "medium",
        check: false,
      },
      {
        label: "Objetivo",
        value: "banner",
        check: false,
      },
      {
        label: "Formato",
        value: "format",
        check: false,
      },
      {
        label: "Público",
        value: "public",
        check: false,
      },
      {
        label: "Presupuesto invertido",
        value: "budget",
        check: false,
      },
      {
        label: "Etiqueta",
        value: "tag",
        check: false,
      },
      {
        label: "Categorías",
        value: "categories",
        check: false,
      },
      {
        label: "Copy",
        value: "copy",
        check: false,
      },
      {
        label: "Observaciones",
        value: "observation",
        check: false,
      },
      {
        label: "Activo digital",
        value: "activo digital",
        check: false,
      },
    ],
  },
  {
    id: "",
    name: "Categorías",
    options: [
      {
        label: "Nombre",
        value: "name",
        check: false,
      },
      {
        label: "Cuenta",
        value: "accountName",
        checked: false,
      },
      {
        label: "Usuario",
        value: "userName",
        checked: false,
      },
      {
        label: "Fecha de Creación",
        value: "createAt",
        check: false,
      },
      {
        label: "Última Actualización",
        value: "updateAt",
        check: false,
      },
      {
        label: "Status",
        value: "active",
        check: false,
      },
    ],
  },
  {
    id: "",
    name: "Publicos",
    options: [
      {
        label: "Nombre",
        value: "name",
        check: false,
      },
      {
        label: "Cuenta",
        value: "accountName",
        checked: false,
      },
      {
        label: "Usuario",
        value: "userName",
        checked: false,
      },
      {
        label: "Fecha de creación",
        value: "createAt",
        check: false,
      },
      {
        label: "Última Actualización",
        value: "updateAt",
        check: false,
      },
      {
        label: "Estado",
        value: "active",
        check: false,
      },
    ],
  },
  {
    id: "",
    name: "Creatividad",
    options: [
      {
        label: "Nombre de Archivo",
        value: "name",
        check: false,
      },
      {
        label: "Cuenta",
        value: "accountName",
        checked: false,
      },
      {
        label: "Usuario",
        value: "userName",
        checked: false,
      },
      {
        label: "Última Actualización",
        value: "lastModified",
        check: false,
      },
      {
        label: "Formato",
        value: "format",
        check: false,
      },
      {
        label: "Tamaño",
        value: "size",
        check: false,
      },
    ],
  },
  {
    id: "",
    name: "Presupuestos",
    options: [
      {
        label: "Nombre",
        value: "title",
        check: false,
      },
      {
        label: "Cuenta",
        value: "accountName",
        checked: false,
      },
      {
        label: "Usuario",
        value: "userName",
        checked: false,
      },
      {
        label: "Fecha de creación",
        value: "createAt",
        check: false,
      },
      {
        label: "Última actualización",
        value: "updateAt",
        check: false,
      },
      {
        label: "Presupuesto total",
        value: "amount",
        check: false,
      },
      {
        label: "Presupuesto implementado",
        value: "availableC",
        check: false,
      },
      {
        label: "Presupuesto disponible",
        value: "available",
        check: false,
      },
      {
        label: "Estado",
        value: "active",
        check: false,
      },
    ],
  },
  {
    id: "",
    name: "Reportes",
    options: [
      {
        label: "Enlace",
        value: "link",
      },
      {
        label: "Nombre",
        value: "name",
      },
      {
        label: "Cuenta",
        value: "accountName",
        checked: false,
      },
      {
        label: "Usuario",
        value: "userName",
        checked: false,
      },
    ],
  },
  // {
  //   id: "",
  //   name: "Usuarios",
  //   options: [
  //     {
  //       label: "Nombre",
  //       value: "name",
  //       check: false,
  //     },
  //     {
  //       label: "Email",
  //       value: "email",
  //       check: false,
  //     },
  //     {
  //       label: "Cuenta",
  //       value: "accountName",
  //       checked: false
  //     },
  //     {
  //       label: "Usuario",
  //       value: 'userName',
  //       checked: false
  //     },
  //   ],
  // },
  {
    id: "",
    name: "Roles",
    options: [
      {
        label: "Usuario",
        value: "userName",
        check: false,
      },
      {
        label: "Cuenta",
        value: "accountName",
        checked: false,
      },
      {
        label: "Tipo de rol",
        value: "type",
        checked: false,
      },
    ],
  },
  {
    id: "",
    name: "Cuentas",
    options: [
      {
        label: "Nombre",
        value: "name",
        check: false,
      },
      {
        label: "Fanpage",
        value: "fanpage",
        check: false,
      },
      {
        label: "Cuenta",
        value: "accountName",
        checked: false,
      },
      {
        label: "Usuario",
        value: "userName",
        checked: false,
      },
      {
        label: "Fecha de creación",
        value: "createAt",
        check: false,
      },
      {
        label: "Crédito",
        value: "credit",
        check: false,
      },
    ],
  },
  {
    id: "",
    name: "Permisos",
    options: [
      {
        label: "Usuario asignado",
        value: "name",
        check: false,
      },
      {
        label: "Rol",
        value: "role",
        check: false,
      },
      {
        label: "Cuenta asignada",
        value: "account",
        checked: false,
      },
      {
        label: "Cuenta",
        value: "accountName",
        checked: false,
      },
      {
        label: "Usuario",
        value: "userName",
        checked: false,
      },
      // {
      //   label: "Tipo de rol",
      //   values: "type",
      //   check: false,
      // },
    ],
  },
];

const ModalAddData = ({
  usersAws,
  showModal,
  setShowModal,
  account,
  refresh,
  currentAccount,
  isSuperAdmin,
  permissions,
}) => {
  const modules = [
    "Campañas",
    "Categorías",
    "Publicos",
    "Presupuestos",
    "Reportes",
    "Roles",
    "Cuentas",
    "Permisos",
  ];
  const modulesByRol = [];
  const permissionActions = {
    Campañas: "Campañas",
    Categorías: "Categorías",
    Publicos: "Publicos",
    Presupuestos: "Presupuestos",
    Reportes: "Reportes",
    Roles: "Roles",
    Cuentas: "Cuentas",
    Permisos: "Permisos",
  };
  permissions.forEach((item) => {
    if (item.view && permissionActions[item.name]) {
      modulesByRol.push(permissionActions[item.name]);
    }
  });
  const [disabledRoles, setDisabledRoles] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [rows, setRows] = useState([]);
  const [roles, setRoles] = useState([]);
  const [dataConfigLets, setDataConfigLets] = useState(bodyConfigLets);
  const [bodyOptions, setBodyOptions] = useState({
    position: 0,
    value: "",
    refresh: false,
    delete: false,
  });
  const [dataConfigEmail, setDataConfigEmail] = useState({
    sender: "info@lets-advertise.com",
    recipients: [],
    affair: "",
    messajeBody: "",
  });
  const [rolSelected, setRolSelected] = useState([]);
  const [bodyMensaje, setBodyMensaje] = useState({
    texto: "",
    html: "",
    content: "",
    change: false,
  });
  const [opciones, setOpciones] = useState([]);
  const [error, setError] = useState({
    module: false,
    channel: false,
    action: false,
    restriction: false,
    userByRol: false,
    sender: false,
    recipients: false,
    affair: false,
    messajeBody: false,
    campo: false,
    operador: false,
    contraValidacion: false,
    condicional: false,
    position: false,
  });
  useEffect(() => {
    if (dataConfigLets.module !== "") {
      let dataOp = optionsValidations.filter(
        (ele) => ele.name === dataConfigLets.module
      );
      setOpciones(dataOp[0].options);
    }
  }, [dataConfigLets.module]);

  const handleOnAdd = () => {
    let idx = 0;
    let ultimaPosition = 0;
    if (rows.length !== 0) {
      idx = rows.length - 1;
      ultimaPosition = rows[idx].position;
    }

    let data = {
      campo: "",
      operador: "igual a",
      contraValidacion: "",
      condicional: "y",
      position: ultimaPosition + 1,
    };

    setRows([...rows, data]);
  };

  const handleOnRemove = (index) => {
    let cols = rows.filter((ele) => ele.position !== index.position);
    cols.forEach((ele, position) => {
      ele.position = position;
    });
    setRows(cols);
  };
  useEffect(() => {
    getRolesNames();
  }, []);

  const getRolesNames = async () => {
    let responseRoleNames = await APILA.getRoleNames({
      account: currentAccount,
    });
    let data = responseRoleNames.data.roleNames;
    let options = [];
    data.forEach((ele) => {
      let body = {
        id: ele.id,
        value: ele.id,
        label: ele.name,
      };
      options.push(body);
    });
    setRoles(options);
  };

  const handleChangeConfigLets = (e) => {
    e.persist();
    let { name, value } = e.target;
    if (value === "Usuarios con acceso") {
      setDisabledRoles(false);
    } else {
      setDisabledRoles(true);
    }
    if (value !== "") {
      setError((prev) => ({ ...prev, [name]: false }));
    }
    setDataConfigLets((prev) => ({ ...prev, [name]: value }));
  };
  const handleChangeEmailData = (e) => {
    e.persist();
    let { value, name } = e.target;
    if (value !== "") {
      setError((prev) => ({ ...prev, [name]: false }));
    }
    setDataConfigEmail((prev) => ({ ...prev, [name]: value }));
  };

  const handleAddValidation = (data) => {
    setRows((state) => {
      const upsertData = [...state];

      let idx = upsertData.findIndex((ele) => ele.position === data.position);

      upsertData[idx] = data;
      return upsertData;
    });
  };
  const addEmailrecipients = (value) => {
    let idx = value ? value.length - 1 : 0;
    let name = "recipients";
    let dataRep = value ? value : [];
    if (value) {
      if (value.length !== 0) {
        setError((prev) => ({ ...prev, recipients: false }));
      }
    }
    let verificar =
      /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/g;
    let emailValidation = true;

    try {
      emailValidation = verificar.test(value[idx].value);
    } catch (err) {}

    if (!emailValidation) {
      Swal.fire({
        title: "Ups!",
        text: "Ingresa un correo válido",
        icon: "error",
        showCancelButton: false,
        confirmButtonColor: "#3085d6",
        confirmButtonText: "Ok",
      });
    } else if (emailValidation) {
      setDataConfigEmail((prev) => ({ ...prev, [name]: dataRep }));
    } else {
      setDataConfigEmail((prev) => ({ ...prev, [name]: dataRep }));
    }
  };
  const areValidateImputs = () => {
    const mErrors = {
      module: dataConfigLets.module === "",
      channel: dataConfigLets.channel === "",
      action: dataConfigLets.action === "",
      // userByRol: dataConfigLets.userByRol.length === 0,
      sender: dataConfigEmail.sender === "",
      recipients: dataConfigEmail.recipients?.length === 0,
      affair: dataConfigEmail.affair === "",
      messajeBody: dataConfigEmail.messajeBody === "",
    };

    console.log("Los errores actuales creacion", { mErrors });

    setError((prev) => ({
      ...prev,
      ...mErrors,
    }));

    return !_.some(mErrors, (e) => e);
  };

  const onSave = async () => {
    // dataConfigLets
    if (!areValidateImputs()) return;
    let bodyConfig = {
      id: v4(),
      module: dataConfigLets.module,
      channel: dataConfigLets.channel,
      action: dataConfigLets.action,
      restriction: dataConfigLets.restriction,
      userByRol: dataConfigLets.userByRol,
      account: account,
    };
    //dataEmail
    let bodyConfigEmail = {
      id: v4(),
      sender: dataConfigEmail.sender,
      recipients: dataConfigEmail.recipients,
      affair: dataConfigEmail.affair,
      messajeBody: dataConfigEmail.messajeBody,
      account: account,
    };
    //bodyValidations
    let bodyValidations = {
      id: v4(),
      validations: rows,
      account: account,
    };
    //objeto global
    let bodyFinal = {
      id: v4(),
      idConfigLets: bodyConfig.id,
      idConfigEmail: bodyConfigEmail.id,
      idValidations: bodyValidations.id,
      account: account,
      date: new Date().toISOString().substring(0, 10),
    };

    try {
      setIsLoading(true);
      await APILA.insertValidacion(bodyValidations);
      await APILA.insertConfigLetsEmail(bodyConfig);
      await APILA.insertConfigEmail(bodyConfigEmail);
      await APILA.insertObjEmails(bodyFinal);
      let bodyBitacora = {
        id: v4(),
        date: new Date(),
        account: account,
        action: "se ha creado una politica",
        component: "configEmails",
        data: bodyFinal,
      };
      await APILA.insertBitacora(bodyBitacora);
      refresh(true);
      setShowModal(false);
      Swal.fire({
        title: "Política agregada!",
        text: "Política agregada correctamente.",
        icon: "success",
        showCancelButton: false,
        confirmButtonColor: "#3085d6",
        confirmButtonText: "Ok",
      });
    } catch (error) {
      Swal.fire({
        title: "Ups!",
        text: "Hubo un error al agregar la politica",
        icon: "error",
        showCancelButton: false,
        confirmButtonColor: "#3085d6",
        confirmButtonText: "Ok",
      });
    } finally {
      setIsLoading(false);
    }
  };
  /**Drag and Drop */
  const dragItem = useRef();
  const dragOverItem = useRef();

  const dragStart = (e, position) => {
    dragItem.current = position;
  };

  const dragEnter = (e, position) => {
    dragOverItem.current = position;
  };

  const drop = () => {
    const copyListItems = [...rows];
    const dragItemContent = copyListItems[dragItem.current];
    copyListItems.splice(dragItem.current, 1);
    copyListItems.splice(dragOverItem.current, 0, dragItemContent);
    dragItem.current = null;
    dragOverItem.current = null;
    setRows(copyListItems);
  };
  const Option = (props) => {
    return (
      <div>
        <components.Option {...props}>
          <input
            type="checkbox"
            checked={props.isSelected}
            onChange={() => null}
          />{" "}
          <label>{props.label}</label>
        </components.Option>
      </div>
    );
  };
  const animatedComponents = makeAnimated();

  const MultiValue = (props) => (
    <components.MultiValue {...props}>
      <span>{props.data.label}</span>
    </components.MultiValue>
  );
  const handleSelectRol = (selected, id) => {
    let name = "userByRol";
    let arrAux = [];
    let data = roles;
    try {
      if (selected != null) {
        if (selected.length > 0) {
          setError((prev) => ({ ...prev, userByRol: false }));
          selected.forEach((ele) => {
            let dataSel = data.filter((el) => el.id === ele.id);
            arrAux = [...arrAux, ...dataSel];
          });
        }
        setRolSelected(arrAux);
        setDataConfigLets((prev) => ({ ...prev, [name]: arrAux }));
      } else {
        setRolSelected([]);
        setDataConfigLets((prev) => ({ ...prev, [name]: [] }));
      }
    } catch {}
  };

  const changeOptionBody = (value) => {
    setOpciones((prevOpciones) =>
      prevOpciones.map((ele) => {
        if (ele.value === value) {
          const newCheck = !ele.check;
          updateBodyOptions(value, newCheck);
          return { ...ele, check: newCheck };
        }
        return ele;
      })
    );
  };

  const updateBodyOptions = (value, active) => {
    if (active) {
      setBodyOptions((state) => {
        return {
          value: "${" + value + "}",
          refresh: true,
          delete: false,
        };
      });
    } else {
      setBodyOptions((state) => {
        return {
          value: "${" + value + "}",
          refresh: false,
          delete: true,
        };
      });
    }
  };

  const changeBody = (texto, html, content, idx) => {
    let value = {
      texto: texto,
      html: html,
      content: content,
      refresh: false,
    };
    setBodyMensaje(value);
    setDataConfigEmail((state) => {
      return {
        affair: state.affair,
        messajeBody: value,
        recipients: state.recipients,
        sender: state.sender,
      };
    });
  };

  return (
    <>
      {isLoading ? (
        <PageLoader />
      ) : (
        <div className="blur-div">
          <div className="content-modal">
            <div className="header-modal">
              <h3 className="titleModal">Configuración Alertas</h3>
            </div>

            <div className="body-modal">
              <section className="body-part">
                <label>
                  Canal
                  <select placeholder="selecciona una opción">
                    <option>Email</option>
                  </select>
                  {error.channel && (
                    <span style={{ color: "red" }}>Agrega un canal</span>
                  )}
                </label>
                <label>
                  Módulos
                  <select
                    name="module"
                    onChange={(e) => handleChangeConfigLets(e)}
                    defaultValue={"DISABLED"}
                    placeholder="selecciona una opción"
                  >
                    <option value={"DISABLED"} disabled>
                      selecciona un módulo:
                    </option>
                    {(isSuperAdmin ? modules : modulesByRol).map((ele, idx) => (
                      <option value={ele} key={idx}>
                        {ele}
                      </option>
                    ))}
                  </select>
                  {error.module && (
                    <span style={{ color: "red" }}>Agrega un modulo</span>
                  )}
                </label>
                <label>
                  Acción
                  <select
                    name="action"
                    onChange={(e) => handleChangeConfigLets(e)}
                    defaultValue={"DISABLED"}
                    placeholder="selecciona una opción"
                  >
                    <option value={"DISABLED"}>selecciona una acción:</option>
                    <option value={"Creación"}>Creación</option>
                    <option value={"Lectura"}>Lectura</option>
                    <option value={"Edición"}>Edición</option>
                    <option value={"Eliminación"}>Eliminación</option>
                  </select>
                  {error.action && (
                    <span style={{ color: "red" }}>Agrega una acción</span>
                  )}
                </label>
                <p className="labelAction">Condición</p>
                <div className="containerRows">
                  {rows.map((row, index) => (
                    <div className="containerLabels" key={index}>
                      <Row
                        dataRow={row}
                        opciones={opciones}
                        position={row.position}
                        onChange={handleAddValidation}
                        onRemove={handleOnRemove}
                        key={index}
                      />
                      <button
                        className="area6"
                        onDragStart={(e) => dragStart(e, index)}
                        onDragEnter={(e) => dragEnter(e, index)}
                        onDragEnd={drop}
                        draggable
                      >
                        <i className="fas fa-grip-vertical"></i>
                      </button>
                    </div>
                  ))}
                </div>
                <button className="addButton" onClick={handleOnAdd}>
                  {" "}
                  Agregar
                </button>
              </section>
              <section className="body-part">
                <label>
                  Remitente
                  {/* <input
              type="textarea"
              name="sender"
              onChange={(e)=> handleChangeEmailData(e)}
              placeholder="Ingresa los correos"
              className="inputEmails"
            /> */}
                  <select
                    name="restriction"
                    onChange={(e) => {
                      handleChangeConfigLets(e);
                    }}
                    defaultValue={"DEFAULT"}
                    placeholder="selecciona una opción"
                  >
                    <option value={"DEFAULT"}>info@lets-advertise.com</option>
                  </select>
                  {error.sender && (
                    <span style={{ color: "red" }}>Agrega un remitente</span>
                  )}
                </label>
                <label>
                  Destinatarios específicos
                  {/* <input
              type="textarea"
              name="recipients"
              onChange={(e) => handleChangeEmailData(e)}
              placeholder="Ingresa los correos"
              className="inputEmails"
            /> */}
                  <CreatableSelect
                    value={dataConfigEmail.recipients}
                    name={"recipients"}
                    options={[]}
                    onChange={addEmailrecipients}
                    placeholder="Ingresa tus destinatarios"
                  />
                  {error.recipients && (
                    <span style={{ color: "red" }}>Agrega un destinatario</span>
                  )}
                </label>
                <label>
                  Usuarios de la plataforma
                  <select
                    name="restriction"
                    onChange={(e) => {
                      handleChangeConfigLets(e);
                    }}
                    placeholder="selecciona una opción"
                  >
                    {isSuperAdmin && (
                      <option value={"Todos los usuarios"}>
                        Todos los usuarios
                      </option>
                    )}
                    <option value={"Usuarios con acceso"}>
                      Usuarios con acceso
                    </option>
                    <option value={"Ningun usuario"}>Ningún usuario</option>
                  </select>
                  {error.restriction && (
                    <span style={{ color: "red" }}>Agrega la restricción</span>
                  )}
                </label>
                {!disabledRoles && (
                  <label>
                    Usuarios por rol
                    <MySelect
                      placeholder="Selecciona un rol"
                      options={roles}
                      isMulti={true}
                      isDisabled={false}
                      closeMenuOnSelect={false}
                      hideSelectedOptions={false}
                      components={{ Option, MultiValue, animatedComponents }}
                      onChange={(e) => {
                        handleSelectRol(e, e);
                      }}
                      allowSelectAll={true}
                      value={rolSelected}
                    />
                    {error.userByRol && (
                      <span style={{ color: "red" }}>Agrega un rol</span>
                    )}
                  </label>
                )}
                <label>
                  Asunto
                  <input
                    type="text"
                    name="affair"
                    onChange={(e) => handleChangeEmailData(e)}
                    placeholder="Asunto"
                    className="inputEmails"
                  />
                  {error.affair && (
                    <span style={{ color: "red" }}>Agrega un asunto</span>
                  )}
                </label>
                <label>
                  Cuerpo del mensaje
                  {error.messajeBody && (
                    <span style={{ color: "red" }}>
                      Agrega un cuerpo para el mensaje
                    </span>
                  )}
                </label>
                {/* {dataConfigLets.module !== "" && (
                  <div className="optionsBody">
                    {opciones.map((ele, idx) => (
                      <p key={idx}>
                        {ele.label}
                        <input
                          type="checkbox"
                          value={ele.value}
                          checked={ele.check}
                          onChange={(e) => {
                            changeOptionBody(e.target.value);
                          }}
                        />
                      </p>
                    ))}
                  </div>
                )}
                 */}
                <div className="optionsBody">
                  {opciones.map((ele, idx) => (
                    <p key={idx}>
                      {ele.label}
                      <input
                        name="messajeBody"
                        type="checkbox"
                        // disabled={disabled}
                        value={ele.value}
                        checked={ele.check}
                        onChange={(e) => {
                          changeOptionBody(e.target.value);
                        }}
                      />
                    </p>
                  ))}
                </div>
                <Editor
                  setBody={setBodyMensaje}
                  setBodyOption={setBodyOptions}
                  bodyPosition={bodyOptions}
                  newPosition={bodyOptions.refresh}
                  deletePosition={bodyOptions.delete}
                  value={bodyMensaje.content}
                  refresh={bodyMensaje.change}
                  change={changeBody}
                />
              </section>
            </div>

            <div className="footer-Modal">
              <button
                className="btn btn_cancel btnCancelOS"
                onClick={(e) => setShowModal(false)}
              >
                Cancelar
              </button>
              <button className="btn btn_save btnConfirmOS" onClick={onSave}>
                Guardar
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ModalAddData;
