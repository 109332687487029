/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { connect } from "react-redux";
import { FormGroup, FormFeedback } from "reactstrap";

import { saveSteps } from "redux/actions/nameCamp.actions";
import { getBudgets } from "redux/actions/budgets.actions";
import { saveBudgets } from "redux/actions/budgetCamp.actions";
import {
  getCampaignsBudget,
  msgsPersonalizado,
} from "redux/actions/dashboard.actions";
import CurrencyInput from "react-currency-input-field";
const BudgetCamp = (props) => {
  const [budget, setBudget] = useState(props.campaign.budget || "");
  const [error, setError] = useState(false);
  const [messageInversion, setMessageInversion] = useState("");

  const handleInputChange = (e) => {
    const value = e;
    setBudget(value);
  };

  const goBack = (e) => {
    e.preventDefault();
    props._saveSteps(3);
  };

  const saveBudget = (e) => {
    e.preventDefault();
    if (!budget || budget === "0" || Number(budget) === 0) {
      setError(true);
      setMessageInversion("Ingrese la inversion");
      setBudget("")
      return;
    }
    setError(false);
    setMessageInversion("");
    props._saveBudgets(budget);
    props._saveSteps(6);
    // props._saveSteps(12)
  };

  return (
    <div className={props.clase}>
      <h1 className="title">Asigna un presupuesto a tu campaña</h1>
      <CurrencyInput
        intlConfig={{ locale: "es-GT", currency: "USD" }}
        prefix="$"
        name="amount"
        className="input form-control"
        style={{
          color: "#05252d",
        }}
        value={budget}
        onValueChange={handleInputChange}
        //placeholder="P. ejemplo 1000.25"
        placeholder="Ingresa el monto de inversión. Ej: $1000.00"
        invalid={error}
      />
      {error && (
        <span style={{ color: "red", marginBottom: "1.5rem" }}>
          {messageInversion}
        </span>
      )}
      <div className="space-btns">
        <a className="btn-camp gray" onClick={goBack} href="#">
          Volver
        </a>
        <a className="btn-camp" onClick={saveBudget} href="#" id="nextMedio">
          Siguiente
        </a>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  campaign: state.campaign,
  budgets: state.budgets.list,
  accounts: state.accounts.list,
  campaignupd: state.campaignupd,
  currentUser: state.app.current_user,
  currentAccount: state.accounts.default,
  campaignsbudget: state.campaignsbudget,
});

const mapDispatchToProps = (dispatch) => ({
  _saveSteps: (step) => dispatch(saveSteps(step)),
  _getBudgets: (account) => dispatch(getBudgets(account)),
  _saveBudgets: (budget) => dispatch(saveBudgets(budget)),
  _msgsPersonalizado: (text, type) => dispatch(msgsPersonalizado(text, type)),
  _getCampaignsBudget: (id_budget) => dispatch(getCampaignsBudget(id_budget)),
});

export default connect(mapStateToProps, mapDispatchToProps)(BudgetCamp);
