import * as Actions from "../actions/templateDeepLink.actions"

const location = window.location;
const currentUrl = new URLSearchParams(location.pathname)
const currentSearch = new URLSearchParams(location.search);
const pageParams = Number(currentUrl.get("page"));
const currentTemplate = currentUrl.get("template")
const searchParams = currentSearch.get("results")

const initialState = {
    currentPage: pageParams || 1,
    currentTemplate: currentTemplate || "1",
    filterText:searchParams || ""
}

export default (state = initialState, action) => {
    switch (action.type) {
        case Actions.TEMPLATE_ADD_PAGE:
            return {
                ...state,
                currentPage: action.page
            };
        case Actions.TEMPLATE_ADD_TEMPLATE_DI:
            return {
                ...state,
                currentTemplate: action.template
            };
        case Actions.TEMPLATE_ADD_FILTER_TEXT:
            return {
                ...state,
                filterText: action.filterText
            };
        default:
            return state;
    }
}