import * as API from 'helpers/api'
import * as Logger from 'utils/logger'
import * as Modal from './modal.actions'
import { showLoader } from './loader.actions'
import { newEmailValidation } from './email.actions'

export const REPORT_CLEAR = 'REPORT:CLEAR'
export const REPORT_REMOVE = 'REPORT:REMOVE'
export const REPORT_ADD_ONE = 'REPORT:ADD:ONE'
export const REPORT_SET_EDIT = 'REPORT:SET:EDIT'
export const REPORT_CLEAR_EDIT = 'REPORT:CLEAR:EDIT'
export const REPORT_SET_FILTER_TEXT = 'REPORT:SET:FILTER:TEXT'

const TAG = 'Report:Actions'

export const getReports = (account) => (dispatch) => {
  dispatch(showLoader(true))
  dispatch(clearReports())

  API.getReports(account)
    .then((response) => {
      Logger.log(TAG, 'getReports', response)
      let { reports } = response.data

      reports.forEach((report) => {
        dispatch(addReport(report))
      })
    })
    .catch((err) => {
      Logger.log(TAG, 'error getReports', err)
    })
    .then(() => {
      dispatch(showLoader(false))
    })
}

export const setFilterText = (text) => ({
  type: REPORT_SET_FILTER_TEXT,
  text,
})

export const clearReports = () => ({
  type: REPORT_CLEAR,
})

export const upsertReport = (id, name, link, account, edit, accounts, user, userMail) => async (dispatch) => {
  dispatch(showLoader(true))
 
  let body = {
    id,
    account,
    userMail
  }

  if (name) {
    body = {
      ...body,
      name
    }
  }

  if (link) {
    body = {
      ...body,
      link
    }
  }

  try {
    const response = edit
      ? await API.updateReport(body)
      : await API.createReport(body)

    Logger.log(TAG, 'upsertReport', response)

    const title = edit ? 'Reporte Editado' : 'Reporte Creado'
    const message = edit
      ? 'El reporte fue editado exitosamente.'
      : 'El reporte fue creado exitosamente.'

    dispatch(newEmailValidation(account, 'Reportes', body, user, accounts, edit ? 'Edición' : 'Creación'))

    dispatch(
      Modal.showAlertModal(title, message, Modal.MODAL_TYPE_SUCCESS, () => {
        dispatch(getReports(account))
      })
    )
  } catch (err) {
    Logger.log(TAG, 'error upsertReport', err)
    const message = edit
      ? 'Ocurrió un error al editar el reporte'
      : 'Ocurrió un error al crear el reporte'
    dispatch(Modal.showAlertModal('Error!', message, Modal.MODAL_TYPE_ERROR))
  }

  dispatch(showLoader(false))
}

export const deleteReport = (id) => async (dispatch) => {
  dispatch(showLoader(true))

  try {
    const response = await API.deleteReport(id)
    Logger.log(TAG, 'deleteReport', response)
    dispatch(
      Modal.showAlertModal(
        'Reporte Eliminado',
        'El reporte fue eliminado exitosamente.',
        Modal.MODAL_TYPE_SUCCESS,
        () => {
          dispatch(removeReport(id))
        }
      )
    )
  } catch (err) {
    Logger.log(TAG, 'error deleteReport', err)
    dispatch(
      Modal.showAlertModal(
        'Error!',
        'Ocurrió un error al eliminar el reporte',
        Modal.MODAL_TYPE_ERROR
      )
    )
  }
  
  dispatch(showLoader(false))
}

export const setEdit = (edit) => ({
  type: REPORT_SET_EDIT,
  edit,
})

export const clearEdit = () => ({
  type: REPORT_CLEAR_EDIT,
})

const addReport = (report) => ({
  type: REPORT_ADD_ONE,
  report,
})

const removeReport = (report) => ({
  type: REPORT_REMOVE,
  report,
})