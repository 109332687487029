export const TEMPLATE_ADD_PAGE = "TEMPLATE_ADD_PAGE";
export const TEMPLATE_ADD_TEMPLATE_DI = "TEMPLATE_ADD_TEMPLATE_DI";
export const TEMPLATE_ADD_FILTER_TEXT = "TEMPLATE_ADD_FILTER_TEXT";

export const initialLink = (currentAccunt, history) => (dispatch) => {
    try {
        history.push({
            pathname: `/templates/account=${currentAccunt}&page=1&template=1`
        })
        dispatch(setCurrentPage(1))
        dispatch(setTemplateId("1"))
        dispatch(setFilterText(""))
    } catch (error) {
        console.log("Error en la primera carga del link", error);
    }
}

export const generateLink = (currentAccount, history, page, template, filter_text) => (dispatch) => {
    try {
        if (!page && !template) {
            dispatch(initialLink(currentAccount, history));
        } else {
            dispatch(setCurrentPage(page))
            let templateId = template === "default" ? "1" : template
            dispatch(setTemplateId(templateId))
            history.push({
                pathname: `/templates/account=${currentAccount}&page=${page}&template=${template}`
            })
            if (filter_text !== "") {
                dispatch(setFilterText(filter_text))
                history.push({
                    search: `results=${filter_text}`
                })
            } else {
                dispatch(setFilterText(""))
            }
        }
    } catch (error) {
        console.log("Error en la generacion del link", error)
    }
}

export const setCurrentPageLink = (page) => (dispatch) => {
    dispatch(setCurrentPage(page))
}


export const setCurrentPage = (page) => ({
    type: TEMPLATE_ADD_PAGE,
    page
})

export const setTemplateId = (template) => ({
    type: TEMPLATE_ADD_TEMPLATE_DI,
    template
})
export const setFilterText = (filterText) => ({
    type: TEMPLATE_ADD_FILTER_TEXT,
    filterText
})