import React from 'react'
import { Switch, Route, Redirect, withRouter } from 'react-router-dom'

// pages
import MenuPage from './menu'
import AsociarPage from './asociar'
import DatosPage from './datos'
import DatosWB from './datoswh'
import Mensajes from './message'

const ScanPage = props => (
    <Switch>
        <Route path={`${props.match.path}`} component={MenuPage} exact />
        <Route path={`${props.match.path}/asociar`} component={AsociarPage} exact />
        <Route path={`${props.match.path}/datos`} component={DatosPage} exact />
        <Route path={`${props.match.path}/datos-web`} component={DatosWB} exact />
        <Route path={`${props.match.path}/mensajes`} component={Mensajes} exact />
        <Route path="*">
            <Redirect to={`${props.match.path}`} />
        </Route>
    </Switch>
)

export default withRouter(ScanPage)
