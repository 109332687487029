import React, { useEffect, useState } from "react";
import propTypes from "prop-types";
import CurrencyInput from "react-currency-input-field";
import * as APILA from "helpers/apiLA";
import { useSelector } from "react-redux";

const ModalMeta = (props) => {
    const [disabled, setDisabled] = useState(false);
    const isSuperAdmin = useSelector((state) => state.app.isSuperAdmin)

    const isActiveCampaign = async (id) => {
        try {
            let validateGeneral = false
            if (isSuperAdmin) return;
            let response = await APILA.getBitacoraByidActionAndMix({
                idMixDetail: id,
                action: "estado activo de la pauta",
            })
            if (response.data.bitacoras.length > 0) validateGeneral = true;
            else validateGeneral = false;
            let arrayStatus = [12, 13, 9, 10, 11, 14, 15, 16, 17, 18];
            if (validateGeneral && arrayStatus.includes(props.stateKpi.status)) setDisabled(true);
            else if (arrayStatus.includes(props.stateKpi.status)) {
                setDisabled(true)
                document.getElementById("input-goal-campaign").focus();
            }
        } catch (error) {
            console.log("Viene el error", error)
            return false
        }
    }

    useEffect(() => {
        isActiveCampaign(props.stateKpi.id)
    }, [props.stateKpi.status])

    return (
        <div className="blur-div modalKpi">
            <div className="content-modal modalKpicontent">
                <div className="closeModal-content">
                    <i
                        className="fas fa-times-circle close"
                        onClick={(e) => props.setShowModal2(false)}
                    ></i>
                </div>
                <div>
                    <h3 className="titleModal titleKPI"> Ingrese la meta</h3>
                </div>

                <div className="body-modal bodyModalComment">
                    <div className="row1">
                        <label className="label1">
                            Meta de {props.getKpiName(props.stateKpi.columnaCompare)}
                        </label>
                        <CurrencyInput
                            intlConfig={{ locale: "es-GT" }}
                            name="amount"
                            className="input1"
                            style={{
                                color: "#05252d",
                            }}
                            value={props.stateKpi.goal}
                            onValueChange={props.onChangeGoal}
                            placeholder="P. ejemplo 1000.25"
                            disabled={disabled}
                            id="input-goal-campaign"
                        />
                    </div>
                </div>

                <div className="footer-Modal">
                    <div className="buttons-Modal-content">
                        <button
                            className="btnModal btnCancelarModal"
                            onClick={(e) => props.setShowModal2(false)}
                        >
                            Cancelar
                        </button>
                        <button
                            className="btnModal btnGuardarModal"
                            onClick={(e) => props.onSaveModal2()}
                        >
                            Guardar
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

ModalMeta.propTypes = {
    setShowModal2: propTypes.func.isRequired,
    stateKpi: propTypes.object.isRequired,
    onSaveModal2: propTypes.func.isRequired,
    getKpiName: propTypes.func.isRequired,
    onChangeGoal: propTypes.func.isRequired,
}

export default ModalMeta;