/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useCallback } from "react";
import _ from "lodash";
import classnames from "classnames";
import { connect, useDispatch, useSelector } from "react-redux";
import * as Modal from "redux/actions/modal.actions";
import {
  createNewDigitalAsset,
  updateDigitalAsset,
  currentTyeDigitalAssets,
  initialTypeDigitalAsset,
} from "redux/actions/digitalAssets.actions";
import analytics from "helpers/analytics";
import { CustomInput, ButtonsConfirmAndSave, OptionSelect } from "./input";
import { useForm } from "./useForm";
import "./style.scss";

const ModalDigitalInfo = (props) => {
  const dispatch = useDispatch();
  const { typesOfDigitalAssets, currentTypeDigitalAsset } = useSelector(
    (state) => ({
      typesOfDigitalAssets: state.digitalAssets.typesOfDigitalAssets,
      currentTypeDigitalAsset: state.digitalAssets.currentTypeDigitalAsset,
    })
  );

  const validate = () => {
    if (Object.keys(props.editData).length === 0) {
      return false;
    }
    let isvalid =
      props.editData.name !== "" ||
      props.editData.reference !== "" ||
      props.editData.url !== "";
    return isvalid;
  };
  const initialState = {
    edit: false,
  };

  const initialStateReducer = {
    name: {
      value: props.editData.name || "",
      hasError: props.editData.name === "",
      name: "name",
      messageError: "",
      isFormInvalid: validate(),
    },
    reference: {
      value: props.editData.reference || "",
      hasError: props.editData.reference === "",
      name: "reference",
      messageError: "",
      isFormInvalid: validate(),
    },
    url: {
      value: props.editData.url || "",
      hasError: props.editData.url === "",
      name: "url",
      messageError: "",
      isFormInvalid: validate(),
    },
  };

  const {
    state: stateReducer,
    onChange,
    isValidaFormState,
  } = useForm(initialStateReducer);
  const [state, setState] = useState(initialState);

  useEffect(() => {
    setState({});
    if (!_.isEmpty(props.editData)) {
      let currentType = typesOfDigitalAssets.find(
        (type) => type.id === props.editData.id_type
      );
      dispatch(currentTyeDigitalAssets(currentType));
      setState((prev) => ({
        ...prev,
        edit: true,
      }));
    } else {
      setState((prev) => ({
        ...prev,
        edit: false,
      }));
    }
  }, [props.editData]);

  const handleInputChangeTypeDigitalAsset = (value) => {
    dispatch(currentTyeDigitalAssets(value));
  };

  const closeModal = (e) => {
    if (e) e.preventDefault();
    props.setShowModal(false);
    setState(initialState);
    dispatch(currentTyeDigitalAssets(initialTypeDigitalAsset));
  };

  const handleEditInfo = async (e) => {
    if (e) e.preventDefault();
    if (!props.currentUser) return;
    try {
      await props._updateDigitalAsset({
        name: stateReducer.name.value.trim(),
        url: stateReducer.url.value.trim(),
        reference: stateReducer.reference.value.trim(),
        medio: currentTypeDigitalAsset,
        id: props.editData.id,
      });
    } catch (error) {
      console.log("ERROR ACTUALIZANDO ACTIVOS", { error });
    } finally {
      closeModal();
      props.info();
    }
  };

  const handleCreateInfo = async (e) => {
    if (e) e.preventDefault();
    analytics.modalesAcciones({
      accion: "Confirmar",
      ubicacion: "Publicos",
    });
    if (!props.currentUser) return;
    try {
      await props._createNewDigitalAsset({
        name: stateReducer.name.value.trim(),
        url: stateReducer.url.value.trim(),
        reference: stateReducer.reference.value.trim(),
        medio: currentTypeDigitalAsset,
        account: props.currentAccount,
      });
    } catch (error) {
      console.log("ERROR CREANDO ACTIVOS", { error });
    } finally {
      closeModal();
      props.info();
    }
  };

  const onChangeValue = useCallback(
    (e) => {
      const { value, name } = e.target;
      onChange({ value, name, currentTypeDigitalAsset });
    },
    [onChange]
  );

  return (
    <div className="blur-div">
      <div
        id="new-audience-modal"
        style={{ display: props.showModal ? "block" : "none" }}
        className={classnames("upsert-modal")}
      >
        <div className="foot-mnc">
          <div className="formOneStep">
            <div className="titleOneStep">
              <p className="h3">
                {state.edit
                  ? "Actualizemos activos digitales"
                  : "Agreguemos tus activos digitales"}
              </p>
            </div>
            <div className="bodyOneStep bodyOneStepPublico ">
              <div className="twoColBody">
                <CustomInput
                  placeholder={"P. ejemplo: Scentia"}
                  value={stateReducer.name.value}
                  label={"Nombre"}
                  handleInputChange={onChangeValue}
                  hasError={stateReducer.name.hasError}
                  name={stateReducer.name.name}
                  messageError={stateReducer.name.messageError}
                />
              </div>
              <div className="twoColBody">
                <CustomInput
                  placeholder={"P. ejemplo: 718486511990"}
                  value={stateReducer.reference.value}
                  label={"Referencia"}
                  handleInputChange={onChangeValue}
                  hasError={stateReducer.reference.hasError}
                  name={stateReducer.reference.name}
                  messageError={stateReducer.reference.messageError}
                />
              </div>
              <div className="twoColBody">
                <CustomInput
                  placeholder={"P. ejemplo: https://www.facebook.com"}
                  value={stateReducer.url.value}
                  label={null}
                  handleInputChange={onChangeValue}
                  hasError={stateReducer.url.hasError}
                  name={stateReducer.url.name}
                  messageError={stateReducer.url.messageError}
                />
              </div>
              <div className="twoColBody">
                <OptionSelect
                  handleInputChange={handleInputChangeTypeDigitalAsset}
                  digitalMedium={typesOfDigitalAssets}
                  defaultValueMedio={currentTypeDigitalAsset}
                  currentTypeDigitalAsset={currentTypeDigitalAsset}
                />
              </div>
            </div>
            <div className="footOneStep w-100 footOneStepModal3">
              <ButtonsConfirmAndSave
                closeModal={closeModal}
                isValidaFormState={isValidaFormState}
                state={state}
                handleEditInfo={handleEditInfo}
                handleCreateInfo={handleCreateInfo}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  edit: state.audiences.edit,
  accounts: state.accounts.list,
  audiences: state.audiences.list,
  currentUser: state.app.current_user,
  pwaAudiences: state.pwa.audiences.list,
  currentAccount: state.accounts.default,
  currentPWAAccount: state.pwa.accounts.default,
});

const mapDispatchToProps = (dispatch) => ({
  _createNewDigitalAsset: ({ name, medio, url, reference, account }) =>
    dispatch(createNewDigitalAsset({ name, medio, url, reference, account })),
  _showAlertModal: (title, message, type, callback) =>
    dispatch(Modal.showAlertModal(title, message, type, callback)),
  _updateDigitalAsset: ({ name, medio, url, reference, id, account }) =>
    dispatch(updateDigitalAsset({ name, medio, url, reference, id, account })),
});

export default connect(mapStateToProps, mapDispatchToProps)(ModalDigitalInfo);
