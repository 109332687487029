/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_content_delivery_bucket": "ets20dvertise-20200626141322-hostingbucket-release",
    "aws_content_delivery_bucket_region": "us-east-1",
    "aws_content_delivery_url": "http://ets20dvertise-20200626141322-hostingbucket-release.s3-website-us-east-1.amazonaws.com",
    "aws_dynamodb_all_tables_region": "us-east-1",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "campaigns-release",
            "region": "us-east-1"
        },
        {
            "tableName": "pautaobj-release",
            "region": "us-east-1"
        },
        {
            "tableName": "creatividad-release",
            "region": "us-east-1"
        },
        {
            "tableName": "categoriesLets-release",
            "region": "us-east-1"
        },
        {
            "tableName": "bitacora-release",
            "region": "us-east-1"
        },
        {
            "tableName": "etiquetas-release",
            "region": "us-east-1"
        },
        {
            "tableName": "accountsLets-release",
            "region": "us-east-1"
        },
        {
            "tableName": "permisos-release",
            "region": "us-east-1"
        },
        {
            "tableName": "roleNames-release",
            "region": "us-east-1"
        },
        {
            "tableName": "views-release",
            "region": "us-east-1"
        },
        {
            "tableName": "budgetLets-release",
            "region": "us-east-1"
        },
        {
            "tableName": "configEmail-release",
            "region": "us-east-1"
        },
        {
            "tableName": "configLetsEmail-release",
            "region": "us-east-1"
        },
        {
            "tableName": "objEmails-release",
            "region": "us-east-1"
        },
        {
            "tableName": "validaciones-release",
            "region": "us-east-1"
        },
        {
            "tableName": "usersLets-release",
            "region": "us-east-1"
        },
        {
            "tableName": "products-release",
            "region": "us-east-1"
        },
        {
            "tableName": "OdooConnections-release",
            "region": "us-east-1"
        },
        {
            "tableName": "odooOvs-release",
            "region": "us-east-1"
        },
        {
            "tableName": "infoOdoo-release",
            "region": "us-east-1"
        },
        {
            "tableName": "bitacorasBudgetsLets-release",
            "region": "us-east-1"
        },
        {
            "tableName": "templates-release",
            "region": "us-east-1"
        },
        {
            "tableName": "audience-release",
            "region": "us-east-1"
        },
        {
            "tableName": "scheduleConfiguration-release",
            "region": "us-east-1"
        }
    ],
    "aws_cloud_logic_custom": [
        {
            "name": "LetsAdvertise",
            "endpoint": "https://mc28enjfh2.execute-api.us-east-1.amazonaws.com/release",
            "region": "us-east-1"
        }
    ]
};


export default awsmobile;
