import React, {useEffect,useState} from 'react'
import { Auth } from 'aws-amplify'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import CreatableSelect from 'libs/select/creatable'
import { saveSteps} from 'redux/actions/nameCamp.actions'
import { saveCategory } from 'redux/actions/campana.actions'
import {
  FormGroup,
  Label,
  Input,
  FormFeedback,
} from 'reactstrap'
const CategoryCamp = props => {
const [category, setCategory]=useState('')
  useEffect(() => {
    // Auth.currentAuthenticatedUser()
    //   .then(userData => {
    //     if (!props.categories || props.categories.length <= 1 ) {
    //       const general = props.categories.filter(category => category.name === "General");
    //       if (general.length === 1) {
    //         console.log(props.categories.length)
    //         props._saveSteps(5)  
    //       }
    //     }
    //   })
    //   .catch(() => props._saveSteps(5))
    // eslint-disable-next-line react-hooks/exhaustive-deps
}, [])

    const cambio = ()=>{
			let select = document.getElementById('btnObjetoCamp')
			select.style.borderBottom = "1px solid grey"
			select.style.color = "#292f4c"
    }

    const handleCategoryChange = (value) => {
      // console.log('handleTagChange')
      console.log('newValue', value)
      if(value){
        setCategory(value)
      }
      
      
    }
  
		const saveCategory = ()=> {
			let objTxt = document.getElementById('btnObjetoCamp')		
			if (category) {
        console.log("save category " , category)
				props._saveCategory(category)
				 props._saveSteps(12)
			}else{
				objTxt.style.borderBottom = "1px solid red"
				objTxt.style.color = "red"
			}
		}

    return (
    <div clase="component">
      <h1 className="title">Ingresa una o más categoria para la pauta</h1>
      <div className='oneColBody'>
          <FormGroup>
            {/* <Label
              style={{
                color: '#05252d',
                fontWeight: 500,
                marginBottom: '5px',
              }}
            >
              Elige una categor&iacute;a
            </Label> */}
            <CreatableSelect
              // options={[]}
              // value={}
              placeholder='Ingresa el nombre de tu categoria y presionar enter'
              onChange={handleCategoryChange}
              // className={classnames({
              //   'is-invalid': errors.category,
              // })}
            />
            <FormFeedback>Categor&iacute;a no debe estar vacia o tener asignadas m&aacute;s de una</FormFeedback>
          </FormGroup>
        </div>
      <div className="space-btns">
        <a className="btn-camp gray" onClick={e => props._saveSteps(5)} href="#">volver</a>
        <a className="btn-camp" onClick={saveCategory} href="#" id="nextMedio">siguiente</a>
      </div>
    </div>
    )
}

const mapStateToProps = state => ({
    campaign: state.campaign,
    campaignupd:state.campaignupd
})
  
const mapDispatchToProps = dispatch => {
return {
    _saveCategory: (category) => dispatch(saveCategory(category)),
    _saveSteps: (step) => dispatch(saveSteps(step)),
}
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CategoryCamp))
