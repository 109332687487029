import React from 'react'
// components
import Content from 'components/etiquetas'
import MetaTags from 'components/metatags'

const EtiquetadoListPage = () => (
  <>
    <div className='contAccount'>
      <MetaTags title="Let's Advertise Ads - Etiquetas automatizadas" description="Crea la nomenclatura de los nombres de tus campañas de publicidad digital. Let's Advertise Ads utilizará esa información para darte informes segmentados." />
      {/* <MetaTags title='Etiquetados' description='Página de cuentas' /> */}

      <Content/>
    </div>
  </>
)

export default EtiquetadoListPage
