import React, { useState, useEffect } from 'react';
import { Table, TimePicker, Checkbox } from 'antd';
import ConfigurationFreeDates from './freeDays';
import { connect } from 'react-redux';
import moment from 'moment';
import PageLoader from 'components/page_loader';
import { getSchedules, editData, updateInDynamo, getPermitsByUser } from 'redux/actions/schedule.actions';
import { useHistory, useLocation } from 'react-router-dom';
import _ from 'lodash';
import './style.scss';

const { Column } = Table;
const freeDays = false
const moduleComponent = "Horarios de atención"

const ConfigurationWeek = (props) => {
    const history = useHistory();
    const location = useLocation()

    const currentAccount = location?.pathname?.split("/configuracion/configuracionSemanal/account")[1]?.split("=")[1]
    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState(
        [
            { day: 'lunes', timeInitial: "7:00 AM", timeFinish: "5:00 PM", checked: true },
            { day: 'martes', timeInitial: "7:00 AM", timeFinish: "5:00 PM", checked: true },
            { day: 'miercoles', timeInitial: "7:00 AM", timeFinish: "5:00 PM", checked: true },
            { day: 'jueves', timeInitial: "7:00 AM", timeFinish: "5:00 PM", checked: true },
            { day: 'viernes', timeInitial: "7:00 AM", timeFinish: "5:00 PM", checked: true },
            { day: 'sabado', timeInitial: "7:00 AM", timeFinish: "5:00 PM", checked: false },
            { day: 'domingo', timeInitial: "7:00 AM", timeFinish: "5:00 PM", checked: false },
        ],
    );
    const [selectedDates, setSelectedDates] = useState([]);
    const [disable, setDisable] = useState({});

    const getSchedules = async () => {
        try {
            setIsLoading(true)
            const response = await props._getSchedules()
            setData(response.workDays)
            setSelectedDates(response.freeDays)
        } catch (error) {
            console.log("Error schedules", error)
        } finally {
            setIsLoading(false)
        }
    }

    const changeUrl = () => {
        history.push(`/configuracion/configuracionSemanal/account=${currentAccount || props.currentAccount}`)
    }

    useEffect(() => {
        changeUrl()
        getSchedules()
    }, [])

    useEffect(() => {
        let findPermisos = props.permits?.find(permiso => permiso.name === moduleComponent)
        setDisable(findPermisos)
    }, [props.permits])

    const validateTime = (timeInitial, timeFinish) => {
        if (!timeInitial || !timeFinish) return true;
        return moment(timeInitial, 'h:mm A').isBefore(moment(timeFinish, 'h:mm A'));
    };

    const onChangeTimeInitial = async (time, record) => {
        const newData = [...data];
        const index = newData.findIndex(item => record.key === item.key);
        let formatedTime = moment(time).format('h:mm A');
        newData[index].timeInitial = formatedTime;
        if (!validateTime(formatedTime, newData[index].timeFinish)) return
        setData(newData);
        await props._editData(newData, props.edit, freeDays)
    };

    const onChangeTimeFinish = async (time, record) => {
        const newData = [...data];
        const index = newData.findIndex(item => record.key === item.key);
        let formatedTime = moment(time).format('h:mm A');
        if (!validateTime(newData[index].timeInitial, formatedTime)) return
        newData[index].timeFinish = formatedTime;
        setData(newData);
        await props._editData(newData, props.edit, freeDays)
    };

    const onChangeCheckbox = async (e, record) => {
        const newData = [...data];
        const index = newData.findIndex(item => record.key === item.key);
        newData[index].checked = e.target.checked;
        setData(newData);
        await props._editData(newData, props.edit, freeDays)
    };

    const onCancel = async () => {
        await getSchedules()
    }

    const onSaveData = async () => {
        setIsLoading(true)
        await props._updateInDynamo(props.edit)
        setIsLoading(false)
    }

    return (
        <>
            {isLoading ? <PageLoader /> :
                <div className='table_data' >
                    <div style={{ flex: '1', marginLeft: '10px' }}>
                        <h3 className='text_title' >Horarios de atención</h3>
                        <div style={{ display: 'flex' }} className='table_horarios' >
                            <Table dataSource={data} pagination={false}>
                                <Column title="Día" dataIndex="day" key="day" />
                                <Column
                                    title="Hora inicial"
                                    dataIndex="timeInitial"
                                    key="timeInitial"
                                    render={(_, record) => (
                                        <>
                                            <TimePicker
                                                use12Hours
                                                format="h:mm A"
                                                value={moment(record?.timeInitial, 'h:mm A') || null}
                                                onChange={(time) => onChangeTimeInitial(time, record)}
                                                disabled={!disable?.edit || !record.checked}
                                                allowClear={false}
                                            />
                                        </>
                                    )}
                                />
                                <Column
                                    title="Hora final"
                                    dataIndex="timeFinish"
                                    key="timeFinish"
                                    render={(_, record) => (
                                        <>
                                            <TimePicker
                                                use12Hours
                                                format="h:mm A"
                                                value={moment(record?.timeFinish, 'h:mm A') || null}
                                                onChange={(time) => onChangeTimeFinish(time, record)}
                                                disabled={!disable?.edit || !record.checked}
                                                allowClear={false}
                                            />

                                        </>
                                    )}
                                />
                                <Column
                                    title="Activo"
                                    dataIndex="checked"
                                    key="checked"
                                    render={(_, record) => (
                                        <Checkbox
                                            disabled={!disable?.edit}
                                            checked={record.checked}
                                            onChange={(e) => onChangeCheckbox(e, record)}
                                        />
                                    )}
                                />
                            </Table>
                        </div>
                    </div>
                    <div className='free_days' >
                        <ConfigurationFreeDates setSelectedDates={setSelectedDates} selectedDates={selectedDates} />
                        <div >
                            {props.onSave && (
                                <div className="btn-container">
                                    <button className="btn btn_cancel btnCancelOS" onClick={onCancel}>
                                        Cancelar
                                    </button>
                                    <button className="btn btn_save btnConfirmOS" onClick={onSaveData}>
                                        Guardar
                                    </button>
                                </div>
                            )}
                        </div>
                    </div>
                </div>}
        </>
    );

};

const mapStateToProps = (state) => ({
    edit: state.schedule.edit,
    onSave: state.schedule.onSave,
    currentAccount: state.accounts.default,
    permits: state.schedule.permisos
})

const mapDispatchToProps = (dispatch) => ({
    _getSchedules: () => dispatch(getSchedules()),
    _editData: (newEdit, edit, freeDays) => dispatch(editData(newEdit, edit, freeDays)),
    _updateInDynamo: (data) => dispatch(updateInDynamo(data)),
    _getPermitsByUser: () => dispatch(getPermitsByUser())
})

export default connect(mapStateToProps, mapDispatchToProps)(ConfigurationWeek);
