import React, { useEffect } from "react";
import Swal from "sweetalert2";
import { connect } from "react-redux";
import { nextStepLogoutUser } from "redux/actions/nameCamp.actions";

const PageHome = (props) => {
    return (
        <div className="form homeSinLogin">
            <div>
                <p className="subTitle">Bienvenido a Let's Advertise</p>
                <h1 className="title">Crea tu primera campaña</h1>
                <a
                    href="#"
                    className="btn-plus"
                    onClick={() => {
                        const mCurrentAccount =
                            props.currentUser !== ""
                                ? props.currentAccount
                                : props.currentPWAAccount;

                        if (mCurrentAccount === "") {
                            Swal.fire({
                                title: "Cuenta Requerida",
                                text: "Para utilizar esta sección, debes crear una cuenta.",
                                icon: "warning",
                            }).then((response) => {
                                if (!response.isConfirmed) return;
                                if (props?.accounts?.length > 0) return;
                                //props._nexStep(15);
                                props._nextStepLogoutUser(15);
                            });
                            return;
                        }
                        props._nextStepLogoutUser(15);
                    }}
                >
                    <i className="fas fa-plus" />
                </a>
            </div>
            <div></div>
        </div>
    );
};

const mapStateToProps = (state) => ({
    currentUser: state.app.current_user,
    account: state.accounts.default,
    accounts: state.accounts.list,
    currentPWAAccount: state.pwa.accounts.default,
    step: state.campaign.stepLogout,
});

const mapDispatchToProps = (dispatch) => ({
    _nextStepLogoutUser: (step) => dispatch(nextStepLogoutUser(step)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PageHome);
