import React from 'react';
import { connect } from 'react-redux';
import { Table, InputNumber } from 'antd';
import PageLoader from 'components/page_loader';
import * as APILA from 'helpers/apiLA';
import Swal from 'sweetalert2';
import { useHistory, useLocation } from 'react-router-dom';
import 'antd/dist/antd.css';

import './styles.scss';
const moduleComponent = "Configuración prioridades de campañas"

const ConfigurationPriority = (props) => {
    const history = useHistory();
    const location = useLocation()

    const currentAccount = location?.pathname?.split("/configuracion/configuracionPriority/account")[1]?.split("=")[1]
    let permisos = props?.permits?.find(permiso => permiso?.name === moduleComponent)?.edit
    const [save, setSave] = React.useState(false);
    const [disabled, setDisabled] = React.useState(!permisos || false);
    const initialState = {
        critical: {
            minHours: 0,
            maxHours: 24
        },
        urgent: {
            minHours: 0,
            maxHours: 24
        },
        normal: {
            minHours: 0,
            maxHours: 24
        }
    }
    const initialStatePriorityRecod = {
        availableCritical: 5,
        availableUrgent: 5,
        countCritical: 5,
        countUrgent: 2
    }
    const [priorityRecord, setPriorityRecord] = React.useState(initialStatePriorityRecod);
    const [state, setState] = React.useState(initialState)
    const [errors, setErrors] = React.useState({
        critical: {
            minHours: false,
            message: ""
        },
        urgent: {
            minHours: false,
            message: ""
        },
        normal: {
            minHours: false,
            message: ""
        }
    });
    const [backUp, setBackUp] = React.useState(initialState);
    const [isLoading, setIsLoading] = React.useState(false);

    const getPriorityRecord = async () => {
        try {
            setIsLoading(true)
            const response = await APILA.getConfigPriority({ account: props.currentAccount })
            setPriorityRecord(response?.data?.data?.priorityRecord)
        } catch (error) {
            console.log('PriorityRecord error', error)
        } finally {
            setIsLoading(false)
        }
    }

    const getConfiguration = async () => {
        try {
            setIsLoading(true);
            const response = await APILA.getConfigPriority({ account: props.currentAccount })
            if (Object.keys(response?.data?.data?.configPriority).length === 0) return
            setState(response?.data?.data?.configPriority);
            setBackUp(response?.data?.data?.configPriority);
        } catch (error) {
            console.log('Error al obtener la configuración de prioridades', { error })
        } finally {
            setIsLoading(false);
        }
    }

    React.useEffect(() => {
        let permisos = props?.permits?.find(permiso => permiso?.name === moduleComponent)?.edit
        setDisabled(!permisos)
    }, [props.permits])

    const changeUrl = () => {
        history.push({
            pathname: `/configuracion/configuracionPriority/account=${currentAccount || props.currentAccount}`
        })
    }

    React.useEffect(() => {
        if (props.currentAccount === "") return
        getConfiguration();
        getPriorityRecord()
        changeUrl()
    }, [props.currentAccount])

    /* 
    * @param {number} value
    * @param {string} key: critical, urgent, normal
    * @param {string} type: minHours, maxHours
    * */
    const onChange = (value, key, type) => {
        if ((value === undefined || value === null)) {
            setErrors(prevErrors => ({
                ...prevErrors,
                [key]: {
                    minHours: false,
                    maxHours: false,
                    message: ""
                }
            }));
            setSave(false);
            return;
        }

        let newErrors = { ...errors };
        const minValue = type === "minHours" ? value : state[key].minHours;
        const maxValue = type === "maxHours" ? value : state[key].maxHours;

        if (type === "minHours" && value > state[key].maxHours) {
            newErrors[key] = {
                minHours: true,
                maxHours: false,
                message: "El valor mínimo no puede ser mayor que el valor máximo"
            };
        } else if (type === "maxHours" && value < state[key].minHours) {
            newErrors[key] = {
                minHours: false,
                maxHours: true,
                message: "El valor máximo no puede ser menor que el valor mínimo"
            };
        } else if (minValue === maxValue) {
            newErrors[key] = {
                minHours: true,
                maxHours: true,
                message: "El valor mínimo y máximo no pueden ser iguales"
            };
        } else {
            newErrors[key] = {
                minHours: false,
                maxHours: false,
                message: ""
            };
        }

        setErrors(newErrors);

        const hasActiveError = Object.values(newErrors).some(error => error.minHours || error.maxHours);

        setSave(!hasActiveError);

        if (!hasActiveError) {
            setState(prevState => ({
                ...prevState,
                [key]: {
                    ...prevState[key],
                    [type]: value
                }
            }));
        }
    };

    const onChangePriority = (value, key) => {
        if (value === undefined || value === null) {
            setSave(false);
            return
        }
        let map = {
            critical: 'availableCritical',
            urgent: 'availableUrgent'
        }
        let state = map[key];
        setSave(true);
        setPriorityRecord(prev => ({
            ...prev,
            [state]: value
        }))
    }

    const onSave = async () => {
        try {
            setIsLoading(true);
            await APILA.updateConfigPriority({ account: props.currentAccount, configPriority: state });
            let newObject = {
                availableCritical: priorityRecord.availableCritical,
                availableUrgent: priorityRecord.availableUrgent,
            }
            await APILA.updateRecordPriority({ account: props.currentAccount, priorityRecord: newObject })
            alert('Editado', 'La configuración de prioridades ha sido editada correctamente', 'success');
        } catch (error) {
            console.log('Error config prioridades', { error })
            alert('Error', 'Ocurrió un error al guardar la configuración de prioridades', 'error');
        } finally {
            setIsLoading(false);
            setSave(false);
            getConfiguration();
        }
    }

    const alert = (title, text, icon) => {
        Swal.fire({
            title,
            text,
            icon,
            confirmButtonText: 'Aceptar'
        });
    }

    const onCancel = () => {
        setSave(false);
        setState(backUp);
    }

    const columns = [
        {
            title: '',
            dataIndex: 'rowTitle',
            render: (text, record) => {
                return (
                    <div style={{ width: 'auto' }} >
                        <span style={{ width: 'auto' }} >{text}</span>
                    </div>
                )
            }
        },
        {
            title: 'Mínimo de horas',
            dataIndex: 'col1',
            height: 500,
            render: (_, row) => {
                const errorMessage = errors[row.key].minHours && errors[row.key].message;
                return (
                    <div
                        className='input-min-width'
                        style={{ width: 'auto', height: '40px' }} >
                        <InputNumber
                            min={0}
                            max={24}
                            disabled={disabled}
                            style={{ width: "auto", height: "30px" }}
                            onChange={(value) => onChange(value, row.key, 'minHours')}
                            value={0 || row.values.minHours}
                        />
                        {errorMessage && <span style={{ fontSize: "8px", color: "red" }}>{errorMessage}</span>}
                    </div>
                )
            }
        },
        {
            title: 'Máximo de horas',
            dataIndex: 'col2',
            render: (_, row) => {
                const errorMessage = errors[row.key].maxHours && errors[row.key].message;
                return (
                    <div
                        className='input-min-width'
                        style={{ width: 'auto', height: '40px' }} >
                        <InputNumber
                            min={0}
                            max={24}
                            disabled={disabled}
                            style={{ width: 'auto', height: '30px' }}
                            onChange={(value) => onChange(value, row.key, 'maxHours')}
                            value={row.values.maxHours || 0}
                        />
                        {errorMessage && <span style={{ fontSize: "8px", color: "red" }}>{errorMessage}</span>}
                    </div>
                )
            },
        },
        {
            title: 'Máximo de pautas',
            dataIndex: 'col3',
            render: (_, row, index) => {
                if (index === 2) {
                    return null;
                }
                return (
                    <div
                        className='input-min-width'
                        style={{ width: 'auto', height: '40px' }} >
                        <InputNumber
                            min={0}
                            // max={25}
                            disabled={disabled}
                            style={{ width: 'auto', height: '30px' }}
                            onChange={(value) => onChangePriority(value, row.key)}
                            value={row.values.maxPriority || 5}
                        />
                    </div>
                )
            },
        },
    ];

    const rows = [
        {
            key: 'critical',
            rowTitle: 'Prioridad Crítica',
            col1: {},
            values: {
                minHours: 0 || state.critical.minHours,
                maxHours: state.critical.maxHours || 24,
                maxPriority: priorityRecord.availableCritical || 5
            },
            col2: '',
            col3: '',
            col4: '',
        },
        {
            key: 'urgent',
            rowTitle: 'Prioridad Urgente',
            col1: {},
            values: {
                minHours: state.urgent.minHours || 0,
                maxHours: state.urgent.maxHours || 24,
                maxPriority: priorityRecord.availableUrgent || 5
            },
            col2: '',
            col3: '',
            col4: '',
        },
        {
            key: 'normal',
            rowTitle: 'Prioridad Normal',
            col1: {},
            values: {
                minHours: state.normal.minHours || 0,
                maxHours: state.normal.maxHours || 24
            },
            col2: '',
            col3: '',
            col4: '',
        },
    ];

    return (
        <>
            {isLoading && <PageLoader />}
            <div className="configuration-container">
                <h3 className='text_priority_setting' >Configuración de horarios para prioridades de las campañas.</h3>
                <div className="timepickers-container">
                    <Table columns={columns} dataSource={rows} pagination={false} />
                </div>
                {save && (
                    <div className="btn-container">
                        <button className="btn btn_cancel btnCancelOS" onClick={onCancel}>Cancelar</button>
                        <button className="btn btn_save btnConfirmOS" onClick={onSave}>Guardar</button>
                    </div>
                )}
            </div>
        </>
    );

};

const mapStateToProps = (state) => ({
    currentAccount: state.accounts.default,
    permits: state.schedule.permisos
});

export default connect(mapStateToProps)(ConfigurationPriority);
