import React from 'react'
// components
import Content from 'components/RolesPermisos'
import MetaTags from 'components/metatags'

const RolesPermisosPage = () => (
  <>
    <div className='contAccount'>
      <MetaTags title="Let's Advertise Ads - Gestión de Roles" description="Gestiona de forma segura los permisos a administrar tus clientes. Let's Advertise Ads te ofrece una sección amigable para realizar las configuraciones." />
      {/* <MetaTags title='Asignación de roles' description='Página de cuentas' /> */}

      <Content/>
    </div>
  </>
)

export default RolesPermisosPage
