import React from 'react'
// components
import TermsAndConditions from 'components/auth/termsAndCondition'
import MetaTags from 'components/metatags'

const RolesPermisosPage = () => (
  <>
    <div className='contAccount'>
      <MetaTags title="Let's Advertise Ads - politica" description="Politica de privacidad" />
      {/* <MetaTags title='Asignación de roles' description='Página de cuentas' /> */}

      <TermsAndConditions/>
    </div>
  </>
)

export default RolesPermisosPage
