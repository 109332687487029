import React from 'react'

// components
import MetaTags from '../../components/metatags'
import SignUpForm from '../../components/auth/signup_form'
import SignInForm from '../../components/auth/signin_form'

const SignUp = props => (
  <>
    <MetaTags title='Registro' description='Página de registro' />
    <SignInForm {...props} />
    {/* <SignUpForm {...props} /> */}
  </>
)

export default SignUp
