/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { v4 } from 'uuid'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import InputMask from 'react-input-mask'
import { saveSteps } from 'redux/actions/nameCamp.actions'
import { showLoader } from 'redux/actions/loader.actions'
import { createAudience } from 'redux/actions/audiences.actions'
import { createCampaign, createCampaignup } from 'redux/actions/dashboard.actions'
import { setCurrentUser } from 'redux/actions/app.actions'
import * as API from 'helpers/api'
import * as APILA from 'helpers/apiLA'
import { setDefault } from "redux/actions/accounts.actions";
import { updateCurrentAccount } from "redux/actions/dashboard.actions";
import './assets/css/styles.css'

const pagoCamp = (props) => {
  const nameVerifi = () => {
    let cardName = document.getElementById('nameCard')
    cardName.classList.remove('noText')
  }

  const numberVerifi = () => {
    let cardNumber = document.getElementById('numberCard')
    cardNumber.classList.remove('noText')
  }

  const cvvVerifi = () => {
    let cardCvv = document.getElementById('cvvCard')
    let cvv = document.getElementById('cvv')
    cardCvv.classList.remove('noText')
    cvv.classList.remove('cvv')
  }

  const backFinal = () => {
    props._saveSteps(7)
  }

  const sendCampaign = (crearCamp) => {
    props._showLoader(true)

    const id = v4()
    const id_audiencia = v4()
    const campaign = props.campaign
    const campaingnBudg = props.campaignsbudget[0]

    crearCamp(
      campaign.name,
      campaign.redSocial,
      campaign.iniDate,
      campaign.endDate,
      campaign.objetivo,
      campaign.budget,
      campaign.category,
      campaign.tags,
      1,
      campaign.user,
      campaign.observations,
      campaign.copy
    )

    if (campaign.tags != null && campaign.tags != undefined && campaign.tags != '') {
      let list = {
        data: campaign.tags,
      }
      props._createAudience(
        id_audiencia,
        props.campaign.public_name,
        campaign.account,
        null,
        list,
        null
      )
    }
  }

  const Pay = async () => {
    let cardName = document.getElementById('nameCard')
    let cardNumber = document.getElementById('numberCard')
    let cardDate = document.getElementById('datexp')
    let cardCvv = document.getElementById('cvvCard')
    let cvv = document.getElementById('cvv')

    if (!cardName.value) {
      cardName.classList.add('noText')
    } else if (!cardNumber.value || cardNumber.value.length < 13) {
      cardNumber.classList.add('noText')
    } else if (!cardDate.value || cardDate.value.length < 5) {
      cardDate.classList.add('noText')
    } else if (!cvv.value) {
      cardCvv.classList.add('noText')
      cvv.classList.add('cvv')
    } else {
      let now = Date.now()
      let id = v4()
      const bodycard = {
        id,
        name: cardName.value,
        card: cardNumber.value,
        expiration_date: cardDate.value,
        cvv: cvv.value,
        status: true,
        default: true,
        createAt: now,
        updateAt: now,
        client: ""
      }
      props._showLoader(true)
      try {
        await run()
      } catch (error) {
        console.log("Error fin.camp", error)
      }
    }
  }

  const run = async (e) => {
    props._showLoader(true)
    //obtenemos información de la campaña dummy
    const campaign = props.campaign
    //obtenemos información de dirección IP para vincular todos los dummy data
    const apiUrl = 'https://api.ipify.org?format=json';
    const responseGeo = await fetch(apiUrl);
    const data = await responseGeo.json();
    const idUser = data.ip
    // Creamos la cuenta
    const idAccount = v4();
    const bodyAccount = {
      id: idAccount,
      name: campaign.accountName,
      phone: '',
      email: '',
      adress: '',
      members: [
        idUser
      ],
      status: true,
      client: '',
      credit: 0,
      priorityRecord: {
        availableCritical: 5,
        availableUrgent: 5,
        countCritical: 0,
        countUrgent: 0
      },
      configPriority: {
        critical: {
          maxHours: 2,
          minHours: 0
        },
        normal: {
          maxHours: 8,
          minHours: 4
        },
        urgent: {
          maxHours: 4,
          minHours: 2
        }
      },
    }
    await API.createAccount(bodyAccount)
    //Creación de categorías por defecto
    let catFacher = []
    let categoriasPadre = []
    let defaultReponse = await APILA.getDefaultCategories({})
    let defaultCategories = defaultReponse.data.categories;
    let categoriaPadre = defaultCategories.filter((item) => {
      return item.padre == 0
    })


    for (let i = 0; i < categoriaPadre.length; i++) {
      let idcate = v4();
      let bodycat = {
        id: idcate,
        idAWS: categoriaPadre[i].id
      }

      catFacher.push(bodycat);
      categoriasPadre.push({
        id: idcate,
        name: categoriaPadre[i].name,
        account: idAccount,
        padre: "97bc9ee5-36a0-455b-974c-fa7832c9c341"
      })
      await API.createCategory({
        id: idcate,
        name: categoriaPadre[i].name,
        account: idAccount,
        padre: "97bc9ee5-36a0-455b-974c-fa7832c9c341"
      })
    };
    let cateSon = []
    for (let y = 0; y < catFacher.length; y++) {
      let filtered = defaultCategories.filter((cat) => {
        return cat.padre == catFacher[y].idAWS
      })
      for (let z = 0; z < filtered.length; z++) {
        let idcat = v4();
        let now = Date.now()
        cateSon.push({
          id: idcat,
          name: filtered[z].name,
          account: idAccount,
          padre: catFacher[y].id
        })
        await API.createCategory({
          id: idcat,
          name: filtered[z].name,
          account: idAccount,
          padre: catFacher[y].id
        })
      }

    }
    //Creación del presupuesto
    let idbudget = v4();
    const today = new Date();
    const now = Date.now()
    const fechaHoy = today.getDate() + '-' + (today.getMonth() + 1) + '-' + today.getFullYear();
    let bodyBudget = {
      id: idbudget,
      tags: [],
      title: "Presupuesto_" + campaign.accountName,
      amount: Number(campaign.budget),
      status: 1,
      account: idAccount,
      available: Number(campaign.budget),
      createAt: now,
      updateAt: now,
      padre: '',
      nombrePadre: '',
      active: true
    }
    // await props._createNewBudgetLogOutFinalCamp(bodyBudget)
    await API.createNewBudgetLogOut(bodyBudget)

    //Crear Público
    let idAudience = v4();
    let bodyAudience = {
      id: idAudience,
      name: campaign.tags.name,
      account: idAccount,
      tags: campaign.tags.tags,
      tasgE: campaign.tags.tagsE
    }
    await API.createAudience(bodyAudience)
    //Crear la pauta
    let idCampaign = v4();
    const bodyCampaign = {
      id: idCampaign,
      name: campaign.name,
      medio: campaign.medium,
      objetivo: '',
      formato: {
        art: props?.campaign?.id_product?.banner,
        id_format: "",
      },
      status: 1,
      fechaIni: campaign.iniDate,
      fechafin: campaign.endDate,
      budget: 0,
      publico: campaign.tags.tags.data,
      id_medium: campaign.id_medium,
      medioo: campaign.medium,
      medio: campaign.medium,
      id_ad_product: campaign.id_product.id_ad_product,
      banner: campaign.id_product.banner,
      idBudget: idbudget,
      account: idAccount,
      observation: campaign.observations,
      copy: campaign.copy,
      id_region: campaign.country.id_region,
      name_region: campaign.country.name,
      version: '',
      categoriesSelected: [],
      creativity: [],
      id_sale: '123456',
      priority: 3,
      idAudience: idAudience,
      UserOutside: true
    }
    await APILA.createCampaign(bodyCampaign)
    await props._updateCurrentAccount(idUser, idAccount);
    if (props.currentUser !== "") {
      await props._setDefaultAccount(idAccount);
    } else {
      await props._pwaSetDefaultAccount(idAccount);
    }
    props._showLoader(false)
    window.location.reload(true);
  }

  return (
    <div className={props.clase}>
      <h1 className='title'>¡Bienvenido!</h1>
      <p className='subTitle'>¿Deseas correr ahora mismo tu nueva campaña?</p>
      <div className='form-tar'>
        <p className='textos'>Nombre que aparece en la tarjeta</p>
        <input
          type='text'
          id='nameCard'
          onKeyDown={nameVerifi}
          className='formInput all margin'
          placeholder='Ingresa el nombre que aparece en la tarjeta'
        />
        <p className='textos'>Numeración de la tarjeta</p>
        <InputMask
          id='numberCard'
          maskPlaceholder={null}
          onKeyDown={numberVerifi}
          mask='9999 9999 9999 9999'
          className='formInput all margin'
          placeholder='Ingrese la numeración de la tarjeta sin guiones'
        />
        <div className='dateCvv'>
          <div className='mitad'>
            <p className='textos'>Fecha de vencimiento</p>
            <InputMask
              id='datexp'
              mask='99/99'
              placeholder='mm/aa'
              maskPlaceholder={null}
              className='formInput all margin'
            />
          </div>
          <div className='mitad'>
            <p className='textos'>CVV</p>
            <div className='formInput all margin' id='cvvCard'>
              <InputMask
                id='cvv'
                mask='9999'
                className='noBorder'
                onKeyDown={cvvVerifi}
                maskPlaceholder={null}
                placeholder='Ingresa código CVV'
              />
              <p className='interogative'>?</p>
            </div>
          </div>
        </div>
      </div>
      <div className='space-btns'>
        <a className='btn-camp gray' onClick={backFinal} href='#'>
          Volver
        </a>
        <a className='btn-camp' onClick={Pay} href='#'>
          Finalizar
        </a>
      </div>
    </div>
  )
}

const mapStateToProps = state => ({
  campaign: state.campaign,
  campaignsbudget: state.campaignsbudget,
})

const mapDispatchToProps = (dispatch) => ({
  _setCurrentUser: (user) => dispatch(setCurrentUser(user)),
  _saveSteps: (step) => dispatch(saveSteps(step)),
  _showLoader: (value) => dispatch(showLoader(value)),
  _setDefaultAccount: (account) => dispatch(setDefault(account)),
  _updateCurrentAccount: (user, account) =>
    dispatch(updateCurrentAccount(user, account)),
  _createAudience: (id, name, account, edit, tags, createAt) =>
    dispatch(createAudience(id, name, account, edit, tags, createAt)),
  _createCampaignup: (
    name,
    medio,
    status,
    fechaIni,
    fechafin,
    objetivo,
    budget,
    category,
    publico,
    id_medium,
    id_ad_product,
    banner,
    user,
    observations,
    copy,
    id_region,
    name_region,
    email,
    emailName,
    accountName,
    step,
    card
  ) =>
    dispatch(
      createCampaignup(
        name,
        medio,
        status,
        fechaIni,
        fechafin,
        objetivo,
        budget,
        category,
        publico,
        id_medium,
        id_ad_product,
        banner,
        user,
        observations,
        copy,
        id_region,
        name_region,
        email,
        emailName,
        accountName,
        step,
        card
      )
    ),
  _createCampaign: (
    id,
    name,
    medio,
    fechaIni,
    fechafin,
    objetivo,
    budget,
    category,
    publico,
    id_medium,
    medioo,
    id_ad_product,
    banner,
    estado,
    id_budget,
    account
  ) =>
    dispatch(
      createCampaign(
        id,
        name,
        medio,
        fechaIni,
        fechafin,
        objetivo,
        budget,
        category,
        publico,
        id_medium,
        medioo,
        id_ad_product,
        banner,
        estado,
        id_budget,
        account
      )
    ),
})

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(pagoCamp))
