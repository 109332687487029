/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { getBudgets, setInfoBudget } from "redux/actions/budgets.actions";
import { saveBudgets } from "redux/actions/budgetCamp.actions";
import {
    getCampaignsBudget,
    msgsPersonalizado,
} from "redux/actions/dashboard.actions";
import CurrencyInput from "react-currency-input-field";
import Select from "libs/select";
import { nextStepLogoutUser } from "redux/actions/nameCamp.actions";

const BudgetCamp = (props) => {
    const [budget, setBudget] = useState(props.campaign.budget || 0);
    const [error, setError] = useState(false);
    const [errorInversion, setErrorInversion] = useState(false);
    const [message, setMessage] = useState("");
    const [messageInversion, setMessageInversion] = useState("");
    const [valueBudgetInfo, setValueBudgetInfo] = useState(
        props?.budgetInfo || {
            id: "",
            label: "",
            available: 0,
        },
    );

    const handleInputChange = (e) => {
        const value = e;
        if (
            Object.keys(props.budgetInfo).length === 0 &&
            props?.budgets.length > 0
        ) {
            setError(true);
            setMessage("Selecciona un presupuesto");
            return;
        }
        const currentBudget = props?.budgets.find(
            (budget) => budget.id === props?.budgetInfo?.id,
        );
        const newValue = parseFloat(value).toFixed(2);
        const currentAvailable = parseFloat(currentBudget?.available).toFixed(2);
        if (currentBudget && parseFloat(currentAvailable) < parseFloat(newValue)) {
            setError(true);
            setMessage(
                "La inversion no puede ser mayor al disponible del presupuesto.",
            );
            return;
        }
        setError(false);
        setErrorInversion(false);
        setMessageInversion("");
        setMessage("");
        setBudget(value);
    };

    const goBack = (e) => {
        e.preventDefault();
        props._saveSteps(16);
        props._setInfoBudget({});
    };

    // Formato para las opciones de los presupouestos
    const getBudgetsByAccount = async () => {
        await props._getBudgets(props.currentAccount);
    };

    useEffect(() => {
        if (!props.currentAccount) return;
        getBudgetsByAccount();
    }, [props.currentAccount]);

    const handleChangeSelectBudgets = (values) => {
        props._setInfoBudget(values);
        setValueBudgetInfo(values);
        if (parseInt(props.budgetInfo.available) < parseFloat(budget)) {
            setError(true);
            setMessage("Invertido mayor al disponible del presupuesto");
            return;
        }
        setError(false);
        setMessage("");
    };

    const budgetsOptions = new Set();
    const filterOpciones = props?.budgets?.filter((item) => item.active);
    filterOpciones?.forEach((budget) => {
        const available = numberWithCommas(myRound(parseFloat(budget.available)));
        const amount = numberWithCommas(parseFloat(budget.amount).toFixed(2));
        budgetsOptions.add({
            id: budget?.id,
            label: `${budget.title}: $${amount} (Disponible: $${available})`,
            available: budget?.available,
        });
    });
    const newArrayOptionsBudgets = Array.from(budgetsOptions);

    // Cuando no existe el presupuesto
    const saveBudget = (e) => {
        e?.preventDefault();
        if (
            newArrayOptionsBudgets.length > 0 &&
            Object.keys(props.budgetInfo).length === 0
        ) {
            setError(true);
            setMessage("Selecciona un presupuesto");
            return;
        }
        if (!budget || budget === "0" || Number(budget) === 0) {
            setErrorInversion(true);
            setMessageInversion("Ingrese la inversion");
            return;
        }
        setError(false);
        setMessage("");
        props._saveSteps(18);
        props._saveBudgets(parseFloat(budget));
    };

    function numberWithCommas(x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    function myRound(num, dec) {
        var exp = Math.pow(10, dec || 2);
        return parseInt(num * exp, 10) / exp;
    }

    return (
        <>
            <div className={props.clase}>
                <h1 className="title" style={{ marginBottom: "1rem" }}>
                    Asigna un presupuesto a tu pauta
                </h1>
                {error && <span style={{ color: "red" }}>{message}</span>}
                {newArrayOptionsBudgets.length > 0 && (
                    <div style={{ marginTop: "1rem", marginBottom: "1.5rem" }}>
                        <Select
                            type="select"
                            options={newArrayOptionsBudgets || []}
                            value={Object.keys(props?.budgetInfo).length > 0 ? valueBudgetInfo : null}
                            onChange={handleChangeSelectBudgets}
                            placeholder="Selecciona tu presupuesto"
                            invalid={error}
                        />
                    </div>
                )}
                <div style={{ marginTop: "4rem" }}>
                    <CurrencyInput
                        intlConfig={{ locale: "es-GT", currency: "USD" }}
                        prefix="$"
                        value={budget}
                        name="amount"
                        className="input form-control"
                        onValueChange={handleInputChange}
                        placeholder="P. ejemplo 1000.25"
                        invalid={error}
                    />
                </div>
                {errorInversion && (
                    <span style={{ color: "red", marginBottom: "1.5rem" }}>
                        {messageInversion}
                    </span>
                )}
                <div className="space-btns" style={{ marginTop: "1rem" }}>
                    <a className="btn-camp gray" onClick={goBack} href="#">
                        volver
                    </a>
                    <a className="btn-camp" onClick={saveBudget} href="#" id="nextMedio">
                        siguiente
                    </a>
                </div>
            </div>
        </>
    );
};

const mapStateToProps = (state) => ({
    campaign: state.campaign,
    budgets: state.budgets.list,
    accounts: state.accounts.list,
    campaignupd: state.campaignupd,
    currentUser: state.app.current_user,
    currentAccount: state.accounts.default,
    campaignsbudget: state.campaignsbudget,
    budgetInfo: state.budgets.budgetInfo,
});

const mapDispatchToProps = (dispatch) => ({
    _setInfoBudget: (budgetInfo) => dispatch(setInfoBudget(budgetInfo)), // wizard
    _saveSteps: (step) => dispatch(nextStepLogoutUser(step)),
    _getBudgets: (account) => dispatch(getBudgets(account)),
    _saveBudgets: (budget) => dispatch(saveBudgets(budget)),
    _msgsPersonalizado: (text, type) => dispatch(msgsPersonalizado(text, type)),
    _getCampaignsBudget: (id_budget) => dispatch(getCampaignsBudget(id_budget)),
});

export default connect(mapStateToProps, mapDispatchToProps)(BudgetCamp);
