import * as Actions from '../actions/permissions.actions'

const initialState = {
  isLoading: false,
  current_permissions: {},
  portal_name: "lets-advertise",
}

export default (state = initialState, action) => {
  switch (action.type) {
    case Actions.APP_LOADED:
      return {
        ...state,
        isLoading: false,
      }
    case Actions.APP_SET_CURRENT_PERMISSIONS:
      return {
        ...state,
        current_permissions: action.permisos,
      }
    case Actions.APP_CLEAR_CURRENT_PERMISSIONS:
      return {
        ...state,
        current_permissions: '',
      }
    default:
      return state
  }
}
