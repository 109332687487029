import React, { useEffect, useState } from "react";
import _, { forEach, lowerFirst, words } from "lodash";
import ReactDataTable, {
  NumberFormat,
  OptionsSelectedEdit,
} from "@easygosanet/react-datatable";
import analytics from "../../helpers/analytics";
import { newEmailValidation } from "redux/actions/email.actions";
import DataTableUtils, {
  Share,
  ShowColumn2,
  FilterColumn,
  FilterColumn2,
} from "@easygosanet/react-datatable-utils";
import "./assets/css/styles.scss";
import Swal from "sweetalert2";
import * as Modal from "redux/actions/modal.actions";
import { connect } from "react-redux";
import { Tooltip } from "antd";
import { withUtils } from "utils";
import * as APILA from "helpers/apiLA";
import * as API from "helpers/api";
import PageLoader from "components/page_loader";
import ModalUsers from "./ModalUsers";
import ModalAccounts from "./ModalAccounts";
import { find } from "lodash";
import { v4 } from "uuid";
import { A, Col2 } from "aws-amplify-react/lib-esm/AmplifyTheme";
import { Auth } from "aws-amplify";
import { useLocation, useHistory } from "react-router-dom";

const RolesPermisos = (props) => {

    // Hooks para el push al pathname
    const location = useLocation()
    const history = useHistory()  
  
    /* Instancias para buscar en la url */
    const currentUrl = new URLSearchParams(location.pathname)
    const currentSearch = new URLSearchParams(location.search)
    /* */
  
    /* Obtencion de valores de la url */
    const accountParams = location.pathname.split('/')[3]?.split('&')[0]?.split('=')[1]
    const viewParams = currentUrl.get('view')
    const agrupationParams = currentUrl.get('group')
    const searchParams = currentSearch.get('search_query')
    /* */

  const [usuariosModal, setUsuariosModal] = useState([])
  const [showModal, setShowModal] = useState(false);
  const [accounts, setAccounts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [view, setView] = useState(false);
  const [roleNames, setRoleNames] = useState([]);
  const [roleNames2, setRoleNames2] = useState([]);
  const [userList, setUserList] = useState([]);
  const [userList2, setUserList2] = useState([]);
  const [awsClients, setAwsClients] = useState([]);
  const [accountList, setAccountList] = useState([]);
  const [accountList2, setAccountList2] = useState([]);
  const [views, setViews] = useState([]);
  const [nameView, setNameView] = useState("");
  const [filterInfo, setFilterInfo] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [showModalUsers, setShowModalUsers] = useState(false);
  const [busquedaActivaAccount, setbusquedaActivaAccount] = useState(false);
  const [filterAccount, setFilterAccount] = useState([]);
  const [busquedaActivaUser, setBusquedaActivaUser] = useState(false);
  const [filterUser, setFilterUser] = useState(false);
  const [showModalAccounts, setShowModalAccounts] = useState(false);
  const [modalTypeView, setModalTypeView] = useState("Cuentas");
  const [usersRoles, setUsersRoles] = useState([]);
  const [usersRoles2, setUsersRoles2] = useState([]);
  const [usuarios, setUsuarios] = useState([]);
  const [dataAccount, setDataAccount] = useState([]);
  const [categories, setCategories] = useState([["Cuentas"], ["Rol"]]);
  const [searchAccount, setSearchAccount] = useState("");
  const [searchUser, setSearchUser] = useState("");
  const [dataFil2, setDataFil2] = useState([]);
  const [isAdmin, setIsAdmin] = useState(false)
  const [permisosModal, setPermisosModal] = useState({ createUser: false, createAccount: false, deleteUser: false })
  const [optionsForm, setOptionsForm] = useState([["Cuentas"], ["Usuarios"]]);
  const [permissions, setPermissions] = useState({
    approve: false,
    create: false,
    delete: false,
    edit: false,
    export: false,
    manageRoles: false,
    view: false,
  });

  const changeUrL = ({agrupation = agrupationParams, view = viewParams }) => {
    const validateAccount = accountParams == undefined || accountParams === null || accountParams.length == 0 ? props.currentAccount : accountParams
    history.push({
      pathname: `/configuracion/roles/account=${validateAccount}&view=${view}&group=${agrupation}`
    })

    if(location.search.length > 0) {
      history.push({
        search: location.search
      })
    }

  }

  const [columns, setColumns] = useState([
    {
      id: "user",
      name: "Cuenta",
      selector: "user",
      sortable: true,
      center: true,
      tag: "user",
      // show: view ? false : true,
      visible: false,
      type: "text",
      omit: false,
      cell: (row) => (
        <DetailCol permisos={permissions} text={row.user} onClick={() => { }} />
      ),
    },
    {
      id: "role",
      name: "Rol",
      tag: "role",
      selector: "role",
      sortable: true,
      center: true,
      omit: false,
      show: false,
      type: "text",
      cell: (row) => (
        <OptionsSelectedEdit
          id={row.role}
          data={roleNames.map(el => el.name)}
          value={row.role}
          nameColumn={"rol"}
          editable={true}
          onEditValue={() => { }}
        />
      ),
    },
    {
      id: "date",
      name: "Fecha último ingreso",
      show: false,
      tag: "date",
      selector: "date",
      omit: false,
      sortable: true,
      center: true,
      type: "text",
      style: {
        fontWeight: "normal",
      },
    },
  ]);
  useEffect(() => {
    if (refresh) {
      getAcc();
      setRefresh(false)
    }
  }, [refresh]);

  useEffect(() => {
    permisos();
    getAcc();
    actualizarViews(true);
    if(agrupationParams === 'default') groupedData('Por defecto')
    else groupedData(agrupationParams)
  }, [props.currentAccount]);

  useEffect(() => {
    if (usuarios && dataAccount && usersRoles2) {
      if (searchParams && searchParams.length > 0) {
        try{
          setIsLoading(true)
          filterAccounts2(searchParams);
        }catch(error) {
          setIsLoading(false)
        }finally{
          setIsLoading(false)
        }
      }
    }
  }, [usuarios, dataAccount, usersRoles2, searchParams]);

  const permisos = async () => {
    try {
      setIsLoading(true)
      let sub = Auth.user.signInUserSession.idToken.payload.sub;
      let permisosUser = await APILA.getPermitsByUser({
        user: sub
      })
      const superAdminPermission = permisosUser.data.permits?.find(permission => permission.idRol === "Super Administrador");
      if (superAdminPermission) {
        const response = await APILA.getRoleNameById({ id: superAdminPermission.idRol });
        const superAdminPermissions = response.data.rol[0].permissions;
        const gestionAccesosPermission = superAdminPermissions.find(permission => permission.name === "Gestión de accesos");
        const permmissionAccounts = response.data.rol[0].permissions.find(permission => permission.name === 'Cuentas');
        setPermissions(state => ({
          view: gestionAccesosPermission.view,
          approve: gestionAccesosPermission.approve,
          create: gestionAccesosPermission.create,
          delete: gestionAccesosPermission.delete,
          edit: gestionAccesosPermission.edit,
          export: gestionAccesosPermission.export,
          manageRoles: gestionAccesosPermission.manageRoles
        }));
        setPermisosModal((prev) => ({ ...prev, deleteUser: true, createAccount: permmissionAccounts.create, createUser: true }))
      } else {

        const accountPermission = permisosUser.data.permits?.find(permission => permission.account === props.currentAccount);
        const roleId = accountPermission.idRol;
        const response = await APILA.getRoleNameById({ id: roleId });
        const createUser = response.data.rol[0].id === 'Administrador';
        const gestionAccesosPermission = response.data.rol[0].permissions.find(permission => permission.name === 'Gestión de accesos');
        const permmissionAccounts = response.data.rol[0].permissions.find(permission => permission.name === 'Cuentas');
        setPermisosModal((prev) => ({ ...prev, deleteUser: false, createAccount: permmissionAccounts.create, createUser: createUser }))
        setPermissions(state => ({
          view: gestionAccesosPermission.view,
          approve: gestionAccesosPermission.approve,
          create: gestionAccesosPermission.create,
          delete: gestionAccesosPermission.delete,
          edit: gestionAccesosPermission.edit,
          export: gestionAccesosPermission.export,
          manageRoles: gestionAccesosPermission.manageRoles
        }));
      }

    } catch (err) {
      setPermissions((state) => {
        return {
          approve: false,
          create: false,
          delete: false,
          edit: false,
          export: false,
          manageRoles: false,
          view: false,
        };
      });
      setIsLoading(false);
      console.log("err permits", err);
    }
  };

  const getAcc = async () => {
    setIsLoading(true);
    const sub = Auth.user.signInUserSession.idToken.payload.sub;
    const permitsResponse = await APILA.getPermitsAll();
    const users = permitsResponse.data.permisos;
    const dataRoles = users.map((ele) => ({
      nombrePadre: "Rol",
      name: ele.role,
    }));
    const usuarioSession = users.filter((us) => us.user === sub);

    const isAdmin = usuarioSession.some((ele) => ele.role === "Administrador");
    const supAdmin = usuarioSession.some((ele) => ele.role === "Super Administrador");
    const admin = isAdmin && !supAdmin;
    const found = usuarioSession.find(ele => ele.account === props.currentAccount);
    const response = await APILA.getRoleNameById({ id: found.idRol });
    const permisosResponse = response.data.rol;

    const responseClients = await APILA.getAllAccountsAWS();
    const { clients } = responseClients.data;

    const clientsArr = clients.map((client_response) => {
      const nameAttr = find(client_response.Attributes, { Name: "name" });
      const emailAttr = find(client_response.Attributes, { Name: "email" });

      const nameValue = nameAttr ? nameAttr.Value : "";
      const emailValue = emailAttr ? emailAttr.Value : "";
      const format = nameValue ? `${nameValue} (${emailValue})` : emailValue;

      return {
        id: client_response.Username,
        name: nameValue,
        email: emailValue,
        format: format,
      };
    });

    const us = clientsArr.map((client) => ({
      nombrePadre: "Usuario",
      name: client.email,
    }));

    let mUsers = _.sortBy(clientsArr, ["email"]);

    const dataCon = [...dataRoles, ...us];
    if (admin) {
      setDataFil2(dataCon.filter(ele => ele.name !== 'Super Administrador'));
      setIsAdmin(admin);
      await handleAdminMode(mUsers, users, props.currentAccount, permisosResponse);
    } else if (supAdmin) {
      setDataFil2(dataCon)
      await handleSupAdminMode(mUsers);
    }

    setUsersRoles(users);
    setUsersRoles2(users);
    setIsLoading(false);
  };

  const handleAdminMode = async (mUsers, users, currentAccount, permiso) => {
    const upsertAdmin = permiso[0].id === 'Administrador' || permiso[0].rolPadre === 'Administrador';
    getRoleNames(false, upsertAdmin ? 'isAdmin' : "notAdmin");
    const responseCurrentAccount = await API.getAccountById({ id: currentAccount });
    const currentAccountData = responseCurrentAccount.data.account[0];

    let filteredUserRoles;
    if (!upsertAdmin) {
      filteredUserRoles = users.filter((user) =>
        user.account === currentAccountData.id && user.idRol !== "Super Administrador");
    } else {
      filteredUserRoles = users.filter((user) =>
        user.account === currentAccountData.id && user.idRol !== "Super Administrador");
    }

    const filteredUserIds = filteredUserRoles.map(user => user.user);
    const filteredUserAccounts = mUsers.filter(user => filteredUserIds.includes(user.id));
    const userAccounts = [];

    if (currentAccountData.members?.length > 0) {
      currentAccountData.members.forEach((member) => {
        const foundUserAccount = filteredUserAccounts.find(user => user.id === member);
        if (foundUserAccount) {
          userAccounts.push(foundUserAccount);
        }
      });
    }

    setAccounts([currentAccountData]);
    setAwsClients(userAccounts);
    setUsuarios(userAccounts);
    setDataAccount([currentAccountData]);

    getClientsOrder([currentAccountData], userAccounts);
  };

  const handleSupAdminMode = async (mUsers) => {
    getRoleNames(true);

    const responseAcc = await API.getAllAcounts();
    const mAccounts = _.sortBy(responseAcc.data.accounts, ["name"]);
    setAwsClients([...mUsers]);
    setUsuarios([...mUsers]);
    setAccounts([...mAccounts]);
    setDataAccount([...mAccounts]);
    getClientsOrder(mAccounts, mUsers);
  };

  const getRoleNames = async (obt, userNotAdmin) => {
    setIsLoading(true);
    let responseRoleNames = await APILA.getRoleNames({ account: props.currentAccount });

    if (responseRoleNames.data.count > 0) {
      let filteredRoleNames;
      if (userNotAdmin && userNotAdmin === 'notAdmin') {
        filteredRoleNames = responseRoleNames.data.roleNames.filter(el => el.id !== 'Super Administrador' && el.id !== 'Administrador');
      } else {
        filteredRoleNames = responseRoleNames.data.roleNames.filter(el => obt === false ? el.id !== "Super Administrador" : el);
      }

      const array = filteredRoleNames.map(el => ({
        id: el.id,
        name: el.name,
        checked: false,
      }));
      setRoleNames([...array]);
      setRoleNames2([...array]);
    }

    setIsLoading(false);
  };

  const getClientsOrder = (mAccounts, mUsers) => {
    const accountListArray = mAccounts.map((account) => ({
      id: account.id,
      name: account.name,
      checked: false,
    }));

    const userss = mUsers.map((user) => ({
      email: user.email,
      id: user.id,
      checked: false,
      name: user.name,
      format: `${user.name} (${user.email})`,
    }));

    setUserList([...userss]);
    setUserList2([...userss]);
    setAccountList([...accountListArray]);
    setAccountList2([...accountListArray]);
  };

  const DetailCol = ({ text, onClick, permisos }) => (
    <div className="content-firstColumn">
      <div className="iconBand">
        {permisos.delete && (
          <Tooltip color="#002448" placement="top" title="Eliminar">
            <i className="fas fa-trash" onClick={() => onClick()} />
          </Tooltip>
        )}
      </div>
      <p className="name-fc">{text}</p>
    </div>
  );

  const actualizarViews = async (tipo) => {
    if (Auth.user) {
      let sub = Auth.user.signInUserSession.idToken.payload.sub;
      let viewsData = await APILA.getViewsByUser({
        account: props.currentAccount,
        user: sub,
        component: "roles",
      });
      let dataVie = viewsData.data.views;
      setViews(dataVie);
      if (dataVie.length == 0) {
        changeUrL({
          agrupation: agrupationParams,
          view: 'list'
        })
        setNameView("Lista");
        let idCol = ["user", "role", "date"];
        setTimeout(() => {
          handleColumnSortChange(idCol);
        });
        columns.forEach((ele) => {
          handleShowColumnChange(ele.selector, false);
        });
      } else {
        let vistaActiva = viewsData.data.views.find((ele) => ele.active);
        if (vistaActiva) {
          changeUrL({
            agrupation: agrupationParams,
            view: vistaActiva.id
          })
          setNameView(vistaActiva.name);
          handleColumnSortChange(vistaActiva.columns.map(ele => ele.id));
          vistaActiva.columns.forEach((ele) => {
            handleShowColumnChange(ele.selector, ele.omit);
          });
        } else {
          changeUrL({
            agrupation: agrupationParams,
            view: 'list'
          })
          setNameView("lista");
        }
      }
    }
  };

  const dataUser = (userId, accountId) => {
    const user = usuarios.find((user) => user.id === userId);
    const userRole = usersRoles.find((role) => role.user === userId && role.account === accountId);

    if (user && userRole) {
      const userData = {
        id: user.id,
        email: user.email,
        name: user.name,
        format: `${user.name} (${user.email})`,
        account: userRole.account,
        date: userRole.date,
        rol: userRole.role,
      };

      return userData;
    } else {
      return null;
    }
  };

  const obtenerUsuarios = (userId, accountId) => {
    const usuarioEncontrado = awsClients.find((ele) => ele.id === userId);
    const rolesUsuario = usersRoles.find((ele) => ele.user == userId && ele.account == accountId);

    if (usuarioEncontrado && rolesUsuario) {
      const usuario = {
        id: usuarioEncontrado.id,
        email: usuarioEncontrado.email,
        name: usuarioEncontrado.name,
        account: rolesUsuario.account,
        format: `${usuarioEncontrado.name} (${usuarioEncontrado.email})`,
        date: rolesUsuario.date,
        rol: rolesUsuario.role,
      };

      return usuario;
    } else {
      return null;
    }
  };

  const obtenerUsuarioRol = async (user, account) => {
    setIsLoading(true);
    let body = {
      account: account,
      user: user,
    };
    let respuesta = APILA.getPermitsByAccountAndUser(body);
    setIsLoading(false);
    return respuesta;
  };

  const mapData = (acc) => {
    let arrayToShow = [];
    const elimRol = async (row) => {
      let title =
        "Está seguro que desea eliminar el rol y la asignación de " +
        row.user +
        "?";
      props._showConfirmationModal(
        "Eliminar asignación de cuenta y rol",
        title,
        async () => {
          let response = await obtenerUsuarioRol(row.id, acc.id);
          setIsLoading(true);
          let body = {
            id: response.data.permisos[0].id,
          };
          let responseDelete = await APILA.deleteRol(body);
          props._newEmailValidation(
            props.currentAccount,
            "Permisos",
            body,
            props.currentUser,
            props.accounts,
            "Eliminación"
          );
          let membersAcc = [];
          if (acc.members) {
            if (acc.members.length > 0) {
              acc.members.forEach((ele) => {
                membersAcc.push(ele);
              });
            }
          }
          let membersAc = membersAcc.filter((users) => users != row.id);
          let responseUpAc = await API.updateAccountMembers({
            id: acc.id,
            members: membersAc,
          });
          analytics.accionesTabla({
            accion: "Eliminar",
            ubicacion: "Roles",
          });

          setRefresh(true);
          setIsLoading(false);
        }
      );
    };

    const editAc = async (value) => {
      let title =
        "Está seguro que desea editar el rol de " + value[0].column + "?";
      props._showConfirmationModal("Editar rol", title, async () => {
        analytics.accionesTabla({
          accion: "Editar con doble clic",
          ubicacion: "Roles",
        });
        let response = await obtenerUsuarioRol(value[0].id, acc.id);
        let data = roleNames2.filter((ele) => ele.name == value[0].value);
        setIsLoading(true);
        let body = {
          id: response.data.permisos[0].id,
          account: acc.id,
          user: response.data.permisos[0].user,
          role: value[0].value,
          idRol: data[0].id,
        };
        let responseInsert = await APILA.insertPermisos(body);
        props._newEmailValidation(
          props.currentAccount,
          "Permisos",
          body,
          props.currentUser,
          props.accounts,
          "Edición"
        );

        getAcc();
        setRefresh(true);
        setIsLoading(false);
      });
    };
    let column1 = columns.find((value) => value.id == "user");
    let column2 = columns.find((value) => value.id == "role");
    let column3 = columns.find((value) => value.id == "date");
    let columns3 = [
      {
        id: "user",
        name: "Usuario",
        selector: "user",
        sortable: true,
        tag: "user",
        omit: column1.omit,
        position: column1.position,
        center: true,
        style: {
          fontWeight: "bold",
        },
        type: "text",
        cell: (row) => (
          <DetailCol
            text={row.user}
            permisos={permissions}
            onClick={() => elimRol(row)}
          />
        ),
      },
      {
        id: "role",
        name: "Rol",
        selector: "role",
        sortable: true,
        tag: "role",
        omit: column2.omit,
        position: column2.position,
        center: true,
        style: {
          fontWeight: "bold",
          backgroundColor: "#eff0f2",
        },
        type: "text",
        cell: (row) =>
          permissions.edit ? (
            <OptionsSelectedEdit
              id={row.id}
              data={roleNames.map(ele => ele.name)}
              value={row.role}
              editable={permissions.edit}
              nameColumn={row.user}
              onEditValue={editAc}
            />
          ) : (
            row.role
          ),
      },
      {
        id: "date",
        omit: column3.omit,
        position: column3.position,
        name: "Fecha último ingreso",
        tag: "date",
        selector: "date",
        sortable: true,
        center: true,
        type: "text",
      },
    ];
    columns3.sort((a, b) => {
      return a.position - b.position;
    });
    if (acc.members && acc.members.length > 0) {
      acc.members.forEach((ele) => {
        const dataUse = obtenerUsuarios(ele, acc.id);
        if (dataUse) {
          const da = dataUse.date;
          const c = da ? da.toString().match(/[0-9]+\/[0-9]+\/[0-9]+/gi) : "Sin asignar";
          arrayToShow.push({
            id: dataUse.id,
            user: dataUse.format,
            role: dataUse.rol || "Sin asignar",
            date: c,
          });
        }
      });
    }

    const customStyles1 = {
      headCells: {
        style: {
          backgroundColor: "#eff0f2",
          "&:nth-of-type(2n)": {
            backgroundColor: "#eff0f2",
          },
        },
      },
      cells: {
        style: {
          "&:nth-of-type(2n)": {
            backgroundColor: "#eff0f2",
          },
        },
      },
    };
    return (
      <>
        <div className="scroll_agrupaciones">
          <h2>{acc.name}</h2>
          <ReactDataTable
            columns={columns3}
            data={arrayToShow}
            customStyles={customStyles1}
            persistTableHead
            expandable
            pagination
            noHeader
          />
        </div>
      </>
    );
  };

  const mapDataUser = (user) => {
    const elimRolAccount = async (row) => {
      let title =
        "Está seguro que desea eliminar el rol y la asignación de " +
        user.email +
        "?";
      props._showConfirmationModal(
        "Eliminar asignación de cuenta y rol",
        title,
        async () => {
          analytics.accionesTabla({
            accion: "Eliminar",
            ubicacion: "Roles",
          });
          let response = await obtenerUsuarioRol(user.id, row.id);
          setIsLoading(true);
          let body = {
            id: response.data.permisos[0].id,
          };
          let responseDelete = await APILA.deleteRol(body);
          props._newEmailValidation(
            props.currentAccount,
            "Permisos",
            body,
            props.currentUser,
            props.accounts,
            "Eliminación"
          );
          let membersAcc = [];

          let accoun = accounts.filter((acc) => acc.id == row.id);
          if (accoun[0].members) {
            if (accoun[0].members.length > 0) {
              accoun[0].members.forEach((acc) => {
                membersAcc.push(acc);
              });
            }
          }
          let membersAc = membersAcc.filter((acc) => acc != row.idUser);
          let responseUpAc = await API.updateAccountMembers({
            id: row.id,
            members: membersAc,
          });
          setRefresh(true);
          setIsLoading(false);
        }
      );
    };
    let arrayToShow = [];
    const editRol = async (value) => {
      let title = "Está seguro que desea editar el rol de " + user.email + "?";
      props._showConfirmationModal("Editar rol", title, async () => {
        let response = await obtenerUsuarioRol(user.id, value[0].id);

        let data = roleNames2.filter((ele) => ele.name == value[0].value);
        setIsLoading(true);
        setIsLoading(true);
        let body = {
          id: response.data.permisos[0].id,
          account: value[0].id,
          user: response.data.permisos[0].user,
          role: value[0].value,
          idRol: data[0].id,
        };
        let responseInsert = await APILA.insertPermisos(body);
        props._newEmailValidation(
          props.currentAccount,
          "Permisos",
          body,
          props.currentUser,
          props.accounts,
          "Edición"
        );
        // getAcc()
        setRefresh(true);
        setIsLoading(false);
      });
    };
    let a0 = columns.filter((a) => a.id == "user");
    let a1 = columns.filter((a) => a.id == "role");
    let a2 = columns.filter((a) => a.id == "date");
    let columns1 = [
      {
        id: "account",
        name: "Cuenta",
        selector: "account",
        sortable: true,
        omit: a0[0].omit,
        position: a0[0].position,
        center: true,
        style: {
          fontWeight: "bold",
        },
        type: "text",
        cell: (row) => (
          <DetailCol
            permisos={permissions}
            text={row.account}
            onClick={() => elimRolAccount(row)}
          />
        ),
      },
      {
        id: "role",
        name: "Rol",
        selector: "role",
        sortable: true,
        center: true,
        omit: a1[0].omit,
        position: a1[0].position,
        style: {
          fontWeight: "bold",
        },
        type: "text",
        cell: (row) =>
          permissions.edit ? (
            <OptionsSelectedEdit
              id={row.id}
              data={roleNames.map(el => el.name)}
              value={row.role}
              editable={permissions.edit}
              nameColumn={row.user}
              onEditValue={editRol}
            />
          ) : (
            row.role
          ),
      },
      {
        id: "date",
        name: "Fecha último ingreso",
        selector: "date",
        sortable: true,
        omit: a2[0].omit,
        position: a2[0].position,
        center: true,
        style: {
          fontWeight: "bold",
        },
        type: "text",
      },
    ];
    columns1.sort((a, b) => {
      return a.position - b.position;
    });

    accounts.forEach((acc) => {
      const userFound = acc.members?.includes(user.id);
      if (userFound) {
        const data = dataUser(user.id, acc.id);
        if (data) {
          const da = data.date;
          const c = da ? da.toString().match(/[0-9]+\/[0-9]+\/[0-9]+/gi) : "Sin asignar";
          arrayToShow.push({
            id: acc.id,
            account: acc.name,
            idUser: data.id,
            role: data.rol || "Sin asignar",
            date: c,
          });
        }
      }
    });


    return (
      <div className="subTablas">
        <ReactDataTable
          columns={columns1}
          data={arrayToShow}
          persistTableHead
          pagination
          noHeader
          expandable
        />
      </div>
    );
  };

  const onChangeCheckUser = (user) => {

    let auxUsersArray = userList;
    var foundIndex = auxUsersArray.findIndex(
      (userElement) => userElement.id == user.id
    );
    auxUsersArray[foundIndex].checked = !auxUsersArray[foundIndex].checked;
    setUserList([...auxUsersArray]);
  };

  const onChangeCheckAccount2 = (acc) => {
    let auxAccArray = accountList2;
    var foundIndex = auxAccArray.findIndex(
      (accElement) => accElement.id == acc.id
    );
    auxAccArray[foundIndex].checked = !auxAccArray[foundIndex].checked;
    setAccountList2([...auxAccArray]);
  };

  const onChangeCheckRole = (rol) => {
    let auxRoleNames = roleNames;
    auxRoleNames.forEach((roleElement) => {
      if (rol.checked === false) {
        if (roleElement.id === rol.id) {
          roleElement.checked = true;
        } else {
          roleElement.checked = false;
        }
      } else {
        roleElement.checked = false;
      }
    });
    setRoleNames([...auxRoleNames]);
  };

  const onChangeCheckRole2 = (rol) => {
    let auxRoleNames = roleNames2;
    auxRoleNames.forEach((roleElement) => {
      if (rol.checked === false) {
        if (roleElement.id === rol.id) {
          roleElement.checked = true;
        } else {
          roleElement.checked = false;
        }
      } else {
        roleElement.checked = false;
      }
    });
    setRoleNames2([...auxRoleNames]);
  };

  const onChangeCheckAccount = (acc) => {
    let auxAccounts = accountList;
    auxAccounts.forEach((accElement) => {
      if (acc.checked === false) {
        if (accElement.id === acc.id) {
          accElement.checked = true;
        } else {
          accElement.checked = false;
        }
      } else {
        accElement.checked = false;
      }
    });
    setAccountList([...auxAccounts]);
  };

  const onChangeCheckUser2 = (user) => {
    let auxUsers = userList2;
    auxUsers.forEach((userElement) => {
      if (user.checked === false) {
        if (userElement.id === user.id) {
          userElement.checked = true;
        } else {
          userElement.checked = false;
        }
      } else {
        userElement.checked = false;
      }
    });
    setUserList2([...auxUsers]);
  };

  const onSave = async () => {
    if (modalTypeView === "Cuentas") {
      let accSelected = accountList.filter((ele) => ele.checked === true);
      let usersSelected = userList.filter((ele) => ele.checked === true);
      let roleSelected = roleNames.filter((ele) => ele.checked === true);
      if (accSelected.length == 0) {
        Swal.fire({
          title: "Ups!",
          text: "Selecciona una cuenta.",
          icon: "error",
          showCancelButton: false,
          confirmButtonColor: "#3085d6",
          confirmButtonText: "Ok",
        });
      }
      if (usersSelected.length == 0) {
        Swal.fire({
          title: "Ups!",
          text: "Selecciona un usuario.",
          icon: "error",
          showCancelButton: false,
          confirmButtonColor: "#3085d6",
          confirmButtonText: "Ok",
        });
      }
      if (roleSelected.length == 0) {
        Swal.fire({
          title: "Ups!",
          text: "Selecciona un rol.",
          icon: "error",
          showCancelButton: false,
          confirmButtonColor: "#3085d6",
          confirmButtonText: "Ok",
        });
      }

      //si es agregar usuarios a cuentas
      //revisar si forman parte de la cuenta
      if (
        accSelected.length > 0 &&
        usersSelected.length > 0 &&
        roleSelected.length > 0
      ) {
        let accFound = accounts.filter((ele) => ele.id === accSelected[0].id);
        if (accFound.length > 0) {
          if (accFound[0].members) {
            let members = accFound[0].members;
            let validarNuevo = 0;
            usersSelected.forEach(async (user) => {
              let memberFound = accFound[0].members.filter(
                (ele) => ele === user.id
              );
              if (memberFound.length > 0) {
                // ya es miembro
              } else {
                //no es miembro, agregar
                members.push(user.id);
                validarNuevo = 1;
              }
            });
            if (validarNuevo === 1) {
              let response = await API.updateAccountMembers({
                id: accSelected[0].id,
                members: members,
              });
            }
          } else {
            //no tiene miembros
            let members = [];
            usersSelected.forEach((user) => {
              members.push(user.id);
            });
            let response = await API.updateAccountMembers({
              id: accSelected[0].id,
              members: members,
            });
          }
        }
        //agregar permisos
        setIsLoading(true);
        usersSelected.forEach(async (user) => {
          let response = await APILA.getPermitsByAccountAndUser({
            account: accSelected[0].id,
            user: user.id,
          });

          if (response.data.permisos.length > 0) {
            //ya cuenta con permisos en esa cuenta
            if (response.data.permisos[0].role === roleSelected[0].id) {
              //es el mismo rol
            } else {
              let body = {
                id: response.data.permisos[0].id,
                account: accSelected[0].id,
                user: user.id,
                role: roleSelected[0].name,
                idRol: roleSelected[0].id,
              };
              let responseInsert = await APILA.insertPermisos(body);
              let body2 = {
                name: user.format,
                role: roleSelected[0].name,
                account: accSelected[0].name,
              };
              props._newEmailValidation(
                props.currentAccount,
                "Permisos",
                body2,
                props.currentUser,
                props.accounts,
                "Creación"
              );
            }
          } else {
            //aun no tiene permisos
            let id = v4();
            let body = {
              id: id,
              account: accSelected[0].id,
              user: user.id,
              role:
                roleSelected.length > 0 ? roleSelected[0].name : "Sin asignar",
              idRol: roleSelected[0].id,
            };
            let responseInsert = await APILA.insertPermisos(body);
            let body2 = {
              name: user.format,
              role: roleSelected[0].name,
              account: accSelected[0].name,
            };
            props._newEmailValidation(
              props.currentAccount,
              "Permisos",
              body2,
              props.currentUser,
              props.accounts,
              "Creación"
            );
          }
        });
        // setRefresh(true);
        setTimeout(() => {
          setShowModal(false);
          setRefresh(true);
          setSearchAccount("");
          setSearchUser("");
          setBusquedaActivaUser(false);
          setbusquedaActivaAccount(false);
          setIsLoading(false);
        }, 4000);
      }
    } else {
      //falta codigo de agregar cuentas a usuarios
      let accSelected = accountList2.filter((ele) => ele.checked === true);
      let usersSelected = userList2.filter((ele) => ele.checked === true);
      let roleSelected = roleNames2.filter((ele) => ele.checked === true);
      let error = false;
      let mensaje = "";
      //Revisar si el usuario forma parte de las cuentas
      if (accSelected.length == 0) {
        Swal.fire({
          title: "Ups!",
          text: "Selecciona una cuenta.",
          icon: "error",
          showCancelButton: false,
          confirmButtonColor: "#3085d6",
          confirmButtonText: "Ok",
        });
      }
      if (usersSelected.length == 0) {
        Swal.fire({
          title: "Ups!",
          text: "Selecciona un usuario.",
          icon: "error",
          showCancelButton: false,
          confirmButtonColor: "#3085d6",
          confirmButtonText: "Ok",
        });
      }
      if (roleSelected.length == 0) {
        Swal.fire({
          title: "Ups!",
          text: "Selecciona un rol.",
          icon: "error",
          showCancelButton: false,
          confirmButtonColor: "#3085d6",
          confirmButtonText: "Ok",
        });
      }

      if (
        accSelected.length > 0 &&
        usersSelected.length > 0 &&
        roleSelected.length > 0
      ) {
        let accsEncountered = [];
        accSelected.forEach((eleAccs) => {
          let foundAcc = accounts.filter((ele) => ele.id == eleAccs.id);
          if (foundAcc.length > 0) {
            accsEncountered.push(foundAcc[0]);
          }
        });
        accsEncountered.forEach(async (accEnc) => {
          if (accEnc.members) {
            let members = accEnc.members;
            let membrsFound = accEnc.members.filter(
              (ele) => ele === usersSelected[0].id
            );
            if (membrsFound.length > 0) {
              //encontro el miembro
            } else {
              //no es miembro, agregar
              members.push(usersSelected[0].id);
              let response = await API.updateAccountMembers({
                id: accEnc.id,
                members: members,
              });
            }
          } else {
            let memberss = [usersSelected[0].id];
            let response = await API.updateAccountMembers({
              id: accEnc.id,
              members: memberss,
            });
          }
        });
        setIsLoading(true);
        accSelected.forEach(async (acc) => {
          let response = await APILA.getPermitsByAccountAndUser({
            account: acc.id,
            user: usersSelected[0].id,
          });
          let permisos = response.data.permisos;
          if (permisos.length > 0) {
            //Cuando ya tiene permisos en la cuenta
            if (permisos[0].role === roleSelected[0].id) {
              // alert('Es el mismo')
            } else {
              let body = {
                id: permisos[0].id,
                account: acc.id,
                user: usersSelected[0].id,
                role: roleSelected[0].name,
                idRol: roleSelected[0].id,
              };
              let responseUpdate = await APILA.insertPermisos(body);
              let body2 = {
                name: usersSelected[0].format,
                role: roleSelected[0].name,
                account: acc.name,
              };
              props._newEmailValidation(
                props.currentAccount,
                "Permisos",
                body2,
                props.currentUser,
                props.accounts,
                "Creación"
              );
            }
          } else {
            //Insertar permisos nuevos
            let id = v4();
            let body = {
              id: id,
              account: acc.id,
              user: usersSelected[0].id,
              role:
                roleSelected.length > 0 ? roleSelected[0].name : "Sin asignar",
              idRol: roleSelected[0].id,
            };
            let body2 = {
              name: usersSelected[0].format,
              role: roleSelected[0].name,
              account: acc.name,
            };
            props._newEmailValidation(
              props.currentAccount,
              "Permisos",
              body2,
              props.currentUser,
              props.accounts,
              "Creación"
            );
            let responseInsert = await APILA.insertPermisos(body);
          }
        });
        setTimeout(() => {
          setRefresh(true);
          setShowModal(false);
          setSearchAccount("");
          setSearchUser("");
          setBusquedaActivaUser(false);
          setbusquedaActivaAccount(false);
        }, 4000);
      }

      //validar permisos
    }

  };

  const onChangeTypeView = (value) => {
    let auxAccountList = accountList;
    let auxUsersList = userList;
    let auxRoleNames = roleNames;
    let auxAccountList2 = accountList2;
    let auxUsersList2 = userList2;
    let auxRoleNames2 = roleNames2;
    auxAccountList.forEach((eleAcc) => {
      eleAcc.checked = false;
    });
    auxAccountList2.forEach((eleAcc) => {
      eleAcc.checked = false;
    });
    auxUsersList.forEach((eleUser) => {
      eleUser.checked = false;
    });
    auxUsersList2.forEach((eleUser) => {
      eleUser.checked = false;
    });
    auxRoleNames.forEach((eleRole) => {
      eleRole.checked = false;
    });
    auxRoleNames2.forEach((eleRole) => {
      eleRole.checked = false;
    });
    setAccountList([...auxAccountList]);
    setAccountList2([...auxAccountList2]);
    setUserList([...auxUsersList]);
    setUserList2([...auxUsersList2]);
    setRoleNames([...auxRoleNames]);
    setRoleNames2([...auxRoleNames2]);
    setModalTypeView(value);
    setBusquedaActivaUser(false);
    setbusquedaActivaAccount(false);
    setFilterAccount([]);
    setFilterUser([]);
    setSearchAccount("");
    setSearchUser("");
  };

  const filterAccounts = (texto) => {
    setSearchAccount(texto);
    let data = [];
    if (texto != "") {
      setbusquedaActivaAccount(true);

      let dataFilter = accountList.filter((ele) =>
        ele.name.toLowerCase().includes(texto.toLowerCase())
      );
      if (dataFilter.length > 0) {
        data = [...dataFilter];
      } else {
        setFilterAccount([]);
      }
    } else {
      setbusquedaActivaAccount(false);
    }
    if (data.length > 0) {
      setFilterAccount(data);
    }
  };

  const filterUsers = (texto) => {
    setSearchUser(texto);
    let data = [];
    if (texto != "") {
      setBusquedaActivaUser(true);

      let dataFilter = userList.filter((ele) =>
        ele.format.toLowerCase().includes(texto.toLowerCase())
      );
      if (dataFilter.length > 0) {
        data = [...dataFilter];
      } else {
        setFilterUser([]);
      }
    } else {
      setBusquedaActivaUser(false);
    }
    if (data.length > 0) {
      setFilterUser(data);
    }
  };

  const handleShowColumnChange = (column, show) => {

    let index = columns.findIndex((col) => col.id === column);
    let aux = columns.map((col) => col);
    aux[index].omit = show;
    setColumns(aux);
  };

  const handleColumnSortChange = (positions) => {
    props.utils.log("handleColumnSortChange");
    props.utils.log("positions", positions);

    let aux = columns.map((col) => col);

    positions.forEach((columnId, position) => {
      let index = columns.findIndex((col) => col.selector === columnId);
      props.utils.log("Index", index);
      aux[index].position = position;
    });

    aux = _.orderBy(aux, ["position"]);

    props.utils.log("new columns", aux);

    setColumns(aux);
  };

  const handleFilterTextChange = (value) => {
    analytics.busqueda({
      ubicacion: "Roles",
      busqueda: value,
    });
    if(value !== "") {
      history.push({
        search: `search_query=${value}`
      })
    }else{
      history.push({
        search: ""
      })
    }
    filterAccounts2(value);
  };

  const filterAccounts2 = (texto) => {
    let data = [];
    let dataUsers = [];
    let dataRoles = [];
    let dataFinal = [];
    let dataUsers2 = [];
    // if (!view) {
    let userId = usuarios.filter((ele) =>
      ele.format.toLowerCase().includes(texto.toLowerCase())
    );
    let usersRol = usersRoles2.filter((ele) =>
      ele.role.toLowerCase().includes(texto.toLowerCase())
    );
    if (texto != "") {
      let nombres = dataAccount.filter((ele) =>
        ele.name.toLowerCase().includes(texto.toLowerCase())
      );
      if (nombres.length == 0) {
        dataAccount.forEach((ele) => {
          if (ele.members) {
            if (ele.members.length > 0) {
              if (userId.length > 0) {
                ele.members.forEach((elementos) => {
                  userId.forEach((user) => {
                    if (elementos == user.id) {
                      let dataUse = dataUser(elementos, ele.id);
                      if (dataUse) {
                        data.push(ele);
                      }
                    }
                  });
                });
              } else {
                usersRol.forEach((user) => {
                  if (ele.id == user.account) {
                    dataFinal.push(ele);
                  }
                });
              }
            }
          }
        });
      }
      if (view && userId.length > 0) {
        let data = arrSinDuplicaciones(userId);
        setAwsClients(data);
        // if (accounts.length == 0) {
        //   setAccounts(dataAccount)
        // }
      } else if (nombres.length > 0) {
        let set = new Set(nombres.map(JSON.stringify));
        let arrSinDuplicaciones = Array.from(set).map(JSON.parse);
        setAccounts(arrSinDuplicaciones);

        if (view) {
          arrSinDuplicaciones.forEach((ele) => {
            if (ele.members) {
              if (ele.members.length > 0) {
                ele.members.forEach((eles) => {
                  let dataUse = dataUser(eles, ele.id);
                  if (dataUse) {
                    let body = {
                      email: dataUse.email,
                      id: dataUse.id,
                      name: dataUse.name,
                    };
                    dataUsers = [...dataUsers, body];
                  }
                });
              }
            }
            setAwsClients(dataUsers);
          });
        }
        // setAccounts(arrSinDuplicaciones)
      } else if (data.length > 0) {
        let set = new Set(data.map(JSON.stringify));
        let arrSinDuplicaciones = Array.from(set).map(JSON.parse);
        setAccounts(arrSinDuplicaciones);
        setAwsClients(userId);
      } else if (dataFinal.length > 0) {
        // alert('aqio 3')
        setUsersRoles(usersRol);
        // if (view) {
        usersRol.forEach((ele) => {
          let userFilter = usuarios.filter((user) => user.id == ele.user);
          let accountsFilter = dataAccount.filter(
            (acc) => acc.id == ele.account
          );
          dataRoles = [...dataRoles, ...accountsFilter];
          if (userFilter.length > 0) {
            dataUsers2 = [...dataUsers2, ...userFilter];
          }
        });
        let datRol = arrSinDuplicaciones(dataRoles);
        setAccounts(datRol);
        let data = arrSinDuplicaciones(dataUsers2);
        setAwsClients(data);
        // } else {
        //   let set = new Set(dataFinal.map(JSON.stringify));
        //   let arrSinDuplicaciones = Array.from(set).map(JSON.parse);
        //   setAccounts(arrSinDuplicaciones);
        // }
      } else {
        setAccounts([]);
        // alert('aqui')
      }
    } else {
      setAccounts(dataAccount);
      setUsersRoles(usersRoles2);
      setAwsClients(usuarios);
    }
  };

  const arrSinDuplicaciones = (data) => {
    let set = new Set(data.map(JSON.stringify));
    let arrSinDuplicaciones = Array.from(set).map(JSON.parse);
    return arrSinDuplicaciones;
  };

  const eliminarDuplicaciones = (data) => {
    let set = new Set(data.map(JSON.stringify));
    let arrSinDuplicaciones = Array.from(set).map(JSON.parse);
    return arrSinDuplicaciones;
  };

  const handleAddView = async (name) => {
    setIsLoading(true);
    let id = views.find((ele) => ele.active);
    let viewEnc = views.find(ele => ele.name === name)
    let idVIew = ''
    if (viewEnc) idVIew = viewEnc.id
    else idVIew = v4()
    if (id) actualizarEstado(id.id, false);
    setNameView(name)
    let body = {
      id: idVIew,
      name: name,
      component: "roles",
      columns: columns,
      account: props.currentAccount,
      user: props.currentUser,
      active: true,
    };
    await APILA.insertView(body);
    actualizarViews(false);
    setIsLoading(false);
  };

  const handleShowView = async (vista, show) => {
    // if (view == false) {
    let dataFinal = [];
    let or = [];
    let idCol = ["user", "role", "date"];
    views.forEach((el) => {
      if (el.active == true) {
        actualizarEstado(el.id, false);
      }
    });
    actualizarEstado(vista, show);
    views.forEach((ele) => {
      if (ele.id == vista) {
        ele.columns.forEach((elementos) => {
          dataFinal.push(elementos);
          if (show) {
            setNameView(ele.name)
            handleShowColumnChange(elementos.selector, elementos.omit);
          } else {
            handleShowColumnChange(elementos.selector, false);
            setNameView('Lista')
            handleColumnSortChange(idCol);
          }
        });
      }
    });
    if (show) {
      dataFinal.sort((a, b) => {
        return a.position - b.position;
      });
      dataFinal.forEach((element) => or.push(element.id));
      handleColumnSortChange(or);
    }
    // }
  };

  const handleDeleteView = async (name) => {
    setIsLoading(true);
    let id = "";
    let idCol = ["user", "role", "date"];
    views.forEach((el) => {
      if (el.active == true) {
        id = el.id;
      }
    });
    if (id == name) {
      setNameView("Lista");
      handleColumnSortChange(idCol);
    }
    await APILA.deleteView({ id: name });
    actualizarViews(false);
    setIsLoading(false);
  };

  const actualizarEstado = async (id, estado) => {
    setIsLoading(true);
    let body = {
      id: id,
      active: estado,
    };
    await APILA.updateViewActive(body);
    actualizarViews(false);
    setIsLoading(false);
  };

  const handleFilterPerColumn = async (value) => {
    let usuariosAws = [];
    let permisosUsuarios = [];
    let accountsFinales = [];

    if (value.length > 0) {
      analytics.filtro({
        ubicacion: "Roles",
      });
      setFilterInfo([...value]);
      value.forEach((val) => {
        if (val.column == "Cuentas" && view == false) {
          let dataAcc = dataAccount.filter((acc) => acc.name == val.value);
          if (dataAcc.length > 0) {
            accountsFinales = [...accountsFinales, ...dataAcc];
            dataAcc.forEach((acc) => {
              let userPermiss = usersRoles2.filter(
                (user) => user.account == acc.id
              );
              if (userPermiss.length > 0) {
                permisosUsuarios = [...permisosUsuarios, ...userPermiss];
              }
            });
          }
        }
        if (val.column == "Rol" && view == false) {
          let dataRol = usersRoles2.filter((user) => user.role == val.value);
          if (dataRol.length > 0) {
            permisosUsuarios = [...permisosUsuarios, ...dataRol];
            dataRol.forEach((user) => {
              let accFilter = dataAccount.filter(
                (acc) => acc.id == user.account
              );
              if (accFilter.length > 0) {
                accountsFinales = [...accountsFinales, ...accFilter];
              }
            });
          }
        }
        if (val.column == "Usuario" && view) {
          let useAws = usuarios.filter((user) => user.email == val.value);
          if (useAws.length > 0) {
            usuariosAws = [...usuariosAws, ...useAws];
            useAws.forEach((user) => {
              let usuariosRole = usersRoles2.filter(
                (role) => role.user == user.id
              );
              if (usuariosRole.length > 0) {
                permisosUsuarios = [...permisosUsuarios, ...usuariosRole];
              }
            });
          }
        }
        if (val.column == "Rol" && view) {
          let dataRol = usersRoles2.filter((user) => user.role == val.value);

          if (dataRol.length > 0) {
            permisosUsuarios = [...permisosUsuarios, ...dataRol];
            dataRol.forEach((user) => {
              let usuarAws = usuarios.filter((us) => us.id == user.user);
              if (usuarAws.length > 0) {
                usuariosAws = [...usuariosAws, ...usuarAws];
              }
            });
          }
        }
      });
      if (accountsFinales.length > 0) {
        let arrSinDuplicaciones = eliminarDuplicaciones(accountsFinales);
        setAccounts(arrSinDuplicaciones);
      }
      if (permisosUsuarios.length > 0) {
        let arrSinDuplicaciones = eliminarDuplicaciones(permisosUsuarios);
        setUsersRoles(arrSinDuplicaciones);
      }
      if (usuariosAws.length > 0) {
        let arrSinDuplicaciones = eliminarDuplicaciones(usuariosAws);
        setAwsClients(arrSinDuplicaciones);
      }
    } else {
      setFilterInfo([]);
      setAccounts(dataAccount);
      setUsersRoles(usersRoles2);
      setAwsClients(usuarios);
    }
  };

  const groupedData = (value) => {
    analytics.agrupar({
      ubicacion: "Roles",
    });

    if(value === 'Por defecto') {
      changeUrL({ 
        agrupation: 'default',
        view: viewParams
       })
    }else{
      changeUrL({
        agrupation: value,
        view: viewParams
      })
    }
    // setIsLoading(true);

    if (value === "Usuarios") {
      const formato = [["Usuarios"], ["Cuentas"]];
      setOptionsForm(formato);

      const data = [["Usuario"], ["Rol"]];
      setCategories(data);
      setView(true);
    } else {
      const data = [["Cuentas"], ["Rol"]];
      const formato = [["Cuentas"], ["Usuarios"]];
      setOptionsForm(formato);
      setCategories(data);
      setView(false);
    }

    // setTimeout(() => {
    //   setIsLoading(false);
    // }, 500);
  };

  const elimUser = async (usuario) => {
    let title = "Está seguro que desea eliminar el usuario de " + usuario.email;
    let containPermis = usersRoles2.filter((ele) => ele.user == usuario.id);

    if (containPermis.length == 0) {
      props._showConfirmationModal("Eliminar usuario", title, async () => {
        // Auth.delet
        // Auth.deleteUserAws
        // setAccountList([])

        let elimUser = await APILA.deleteUserAws({ username: usuario.id });
        setFilterUser([]);
        setSearchUser("");
        setBusquedaActivaUser();
        setRefresh(true);
      });
    } else {
      Swal.fire({
        title: "Ups!",
        text: "Este usuario cuenta con permisos.",
        icon: "error",
        showCancelButton: false,
        confirmButtonColor: "#3085d6",
        confirmButtonText: "Ok",
      });
    }
  };

  return (
    <>
      {isLoading ? (
        <PageLoader />
      ) : permissions.view ? (
        <div className="main-GestionRoles">
          <div className="barraSuperior">
            <DataTableUtils
              data={[]}
              action={
                permissions.create ?
                  () => {
                    setShowModal(true);
                    analytics.Add({
                      ubicacion: "Roles",
                    });
                  }
                  :
                  null
              }
              actionIcon={
                permissions.create && (
                  <Tooltip
                    color="#002448"
                    placement="top"
                    title="Crear asignación"
                  >
                    <i className="fas fa-plus" />
                  </Tooltip>
                )
              }
              onChangeFilterText={handleFilterTextChange}
              valueFilter={searchParams || ""}
            >
              <Tooltip
                color="#002448"
                placement="top"
                title="Exportar información"
              >
                <div
                  onClick={() => {
                    analytics.Compartir({
                      ubicacion: "Roles",
                    });
                  }}
                >
                  {permissions.export && <Share idImg={'root'}
                    appKey='2u78gno172idwbz' contacts={[]} data={[]} />}
                </div>
              </Tooltip>
              <Tooltip
                color="#002448"
                placement="top"
                title="Personalizar columnas"
              >
                <div id="shareBtn">
                  <ShowColumn2
                    columns={columns}
                    showColumn={(data, data1) => {
                      analytics.ShowHide({
                        ubicacion: "Roles",
                      });
                      handleShowColumnChange(data, data1);
                    }}
                    showView={handleShowView}
                    onSortChange={(positions) => {
                      analytics.Columnas({
                        ubicacion: "Roles",
                      });
                      handleColumnSortChange(positions);
                    }}
                    vistas={views}
                    setVista={(nombre) => {
                      analytics.accionesVista({
                        ubicacion: "Roles",
                        accion: "Agregar",
                      });
                      handleAddView(nombre);
                    }}
                    deleteView={(name) => {
                      analytics.accionesVista({
                        ubicacion: "Roles",
                        accion: "ELiminar",
                      });
                      handleDeleteView(name);
                    }}
                    nameAc={nameView}
                  />
                </div>
              </Tooltip>
              <Tooltip
                color="#002448"
                placement="top"
                title="Filtrar y agrupar"
              >
                <div className="Filtro2 filtro3">
                  <FilterColumn2
                    columns={columns}
                    data={dataFil2}
                    onFilterColumn={handleFilterPerColumn}
                    filterInfoCheck={filterInfo}
                    // limpiarData={elim}
                    groupedDefault={agrupationParams || "Por defecto"}
                    options={optionsForm}
                    changeGrouped={groupedData}
                    // setLimpiarData={setElim}
                    categories={categories}
                  />
                </div>
              </Tooltip>
            </DataTableUtils>
          </div>
          <div className="contaner-tables">
            <div className="box-table">
              {view === false
                ? accounts.map((acc) => {
                  return <>{mapData(acc)}</>;
                })
                : awsClients.map((user) => {
                  return (
                    <>
                      <h1>{user.name + " " + `(${user.email})`}</h1>
                      {mapDataUser(user)}
                    </>
                  );
                })}
            </div>
          </div>
          {showModal && (
            <div className="blur-div ">
              <div className="content-modal contentModalRol modalAsigRol">
                {/* <div className="closeModal-content">
                  <i
                    className="fas fa-times-circle close"
                    onClick={(e) => closeModal(false)}
                  ></i>
                </div> */}
                <div>
                  <h3 className="titleModal titleModalRol">
                    {" "}
                    Asignación de roles
                  </h3>
                </div>
                <div className="body-modal bodyRoles">
                  <select
                    value={modalTypeView}
                    onChange={(e) => {
                      onChangeTypeView(e.target.value);
                    }}
                    placeholder="selecciona una opcción"
                  >
                    <option value="Cuentas">Acceso a una cuenta</option>
                    <option value="Usuarios">Acceso a un usuario</option>
                  </select>
                  {modalTypeView === "Cuentas" ? (
                    <>
                      <div className="boxRoles">
                        <div className="boxRoles__title">
                          <h5>
                            CUENTAS
                            {(permisosModal.createAccount) && (
                              <span
                                className="iconPlus"
                                onClick={() =>
                                  setShowModalAccounts(!showModalAccounts)
                                }
                              >
                                +
                              </span>
                            )}
                          </h5>
                          <h5>
                            USUARIOS
                            {(permisosModal.createUser) && (
                              <span
                                className="iconPlus"
                                onClick={() => setShowModalUsers(!showModalUsers)}
                              >
                                +
                              </span>
                            )}
                          </h5>
                          <h5>ROLES</h5>
                        </div>
                        <div className="boxRoles__content">
                          <div className="box boxCuenta">
                            <input
                              type="search"
                              placeholder="Buscar cuenta"
                              value={searchAccount}
                              onChange={(e) => filterAccounts(e.target.value)}
                            />
                            {busquedaActivaAccount
                              ? filterAccount.map((acc) => {
                                return (
                                  <div className="boxCheck">
                                    <input
                                      type="checkbox"
                                      className="check"
                                      onChange={() => {
                                        onChangeCheckAccount(acc);
                                      }}
                                      checked={acc.checked}
                                    />
                                    {acc.name}
                                  </div>
                                );
                              })
                              : accountList.map((acc) => {
                                return (
                                  <div className="boxCheck">
                                    <input
                                      type="checkbox"
                                      className="check"
                                      onChange={() => {
                                        onChangeCheckAccount(acc);
                                      }}
                                      checked={acc.checked}
                                    />
                                    {acc.name}
                                  </div>
                                );
                              })}
                          </div>
                          <div className="box boxUsuario">
                            <input
                              type="search"
                              placeholder="Buscar usuario"
                              value={searchUser}
                              onChange={(e) => filterUsers(e.target.value)}
                            />
                            {busquedaActivaUser
                              ? filterUser.map((user) => {
                                return (
                                  <div className="boxCheck">
                                    <input
                                      type="checkbox"
                                      className="check"
                                      onChange={() => {
                                        onChangeCheckUser(user);
                                      }}
                                      checked={user.checked}
                                    />
                                    {user.format}
                                    {(permisosModal.deleteUser) && (
                                      <i
                                        className="fa fa-remove"
                                        style={{ cursor: "pointer" }}
                                        onClick={() => elimUser(user)}
                                      />
                                    )}
                                  </div>
                                );
                              })
                              : userList.map((user) => {
                                return (
                                  <div className="boxCheck">
                                    <input
                                      type="checkbox"
                                      className="check"
                                      onChange={() => {
                                        onChangeCheckUser(user);
                                      }}
                                      checked={user.checked}
                                    />
                                    {user.format}
                                    {(permisosModal.deleteUser) && (
                                      <i
                                        className="fa fa-remove"
                                        style={{ cursor: "pointer" }}
                                        onClick={() => elimUser(user)}
                                      />
                                    )}
                                  </div>
                                );
                              })}
                          </div>
                          <div className="box boxRoles">
                            {roleNames.map((rol) => {
                              return (
                                <div className="boxCheck">
                                  <input
                                    type="checkbox"
                                    className="check"
                                    onChange={() => {
                                      onChangeCheckRole(rol);
                                    }}
                                    checked={rol.checked}
                                  />
                                  {rol.name}
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="boxRoles">
                        <div className="boxRoles__title">
                          <h5>
                            USUARIOS
                            {(permisosModal.createUser) && (
                              <span
                                className="iconPlus"
                                onClick={() => setShowModalUsers(!showModalUsers)}
                              >
                                +
                              </span>
                            )}
                          </h5>
                          <h5>
                            CUENTAS
                            {(permisosModal.createAccount) && (
                              <span
                                className="iconPlus"
                                onClick={() =>
                                  setShowModalAccounts(!showModalAccounts)
                                }
                              >
                                +
                              </span>
                            )}
                          </h5>
                          <h5>ROLES</h5>
                        </div>
                        <div className="boxRoles__content">
                          <div className="box boxCuenta">
                            <input
                              type="search"
                              placeholder="Buscar usuario"
                              value={searchUser}
                              onChange={(e) => filterUsers(e.target.value)}
                            />
                            {/* {userList2.map((user) => {
                                return (
                                  <div className="boxCheck">
                                    <input
                                      type="checkbox"
                                      className="check"
                                      onChange={() => { onChangeCheckUser2(user) }}
                                      checked={user.checked}
                                    />
                                    {user.email}
                                  </div>
                                );
                              })} */}
                            {busquedaActivaUser
                              ? filterUser.map((user) => {
                                return (
                                  <div className="boxCheck">
                                    <input
                                      type="checkbox"
                                      className="check"
                                      onChange={() => {
                                        onChangeCheckUser2(user);
                                      }}
                                      checked={user.checked}
                                    />
                                    {user.format}
                                    {(permisosModal.deleteUser) && (
                                      <i
                                        className="fa fa-remove"
                                        style={{ cursor: "pointer" }}
                                        onClick={() => elimUser(user)}
                                      />
                                    )}

                                  </div>
                                );
                              })
                              : userList.map((user) => {
                                return (
                                  <div className="boxCheck">
                                    <input
                                      type="checkbox"
                                      className="check"
                                      onChange={() => {
                                        onChangeCheckUser2(user);
                                      }}
                                      checked={user.checked}
                                    />
                                    {user.format}
                                    {(permisosModal.deleteUser) && (
                                      <i
                                        className="fa fa-remove"
                                        style={{ cursor: "pointer" }}
                                        onClick={() => elimUser(user)}
                                      />
                                    )}
                                  </div>
                                );
                              })}
                          </div>
                          <div className="box boxUsuario">
                            <input
                              type="search"
                              placeholder="Buscar cuenta"
                              value={searchAccount}
                              onChange={(e) => filterAccounts(e.target.value)}
                            />
                            {/* {accountList2.map((acc) => {
                                return (
                                  <div className="boxCheck">
                                    <input
                                      type="checkbox"
                                      className="check"
                                      onChange={() => { onChangeCheckAccount2(acc) }}
                                      checked={acc.checked}
                                    />
                                    {acc.name}
                                  </div>
                                );
                              })} */}
                            {busquedaActivaAccount
                              ? filterAccount.map((acc) => {
                                return (
                                  <div className="boxCheck">
                                    <input
                                      type="checkbox"
                                      className="check"
                                      onChange={() => {
                                        onChangeCheckAccount2(acc);
                                      }}
                                      checked={acc.checked}
                                    />
                                    {acc.name}
                                  </div>
                                );
                              })
                              : accountList.map((acc) => {
                                return (
                                  <div className="boxCheck">
                                    <input
                                      type="checkbox"
                                      className="check"
                                      onChange={() => {
                                        onChangeCheckAccount2(acc);
                                      }}
                                      checked={acc.checked}
                                    />
                                    {acc.name}
                                  </div>
                                );
                              })}
                          </div>
                          <div className="box boxRoles">
                            {roleNames2.map((rol) => {
                              return (
                                <div className="boxCheck">
                                  <input
                                    type="checkbox"
                                    className="check"
                                    onChange={() => {
                                      onChangeCheckRole2(rol);
                                    }}
                                    checked={rol.checked}
                                  />
                                  {rol.name}
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </div>

                <div className="footer-Modal">
                  <div className="buttons-Modal-content">
                    <button
                      className="btnModal btnCancelarModalRol btnCancelOS"
                      onClick={(e) => setShowModal(false)}
                    >
                      Cancelar
                    </button>
                    <button
                      className="btnModal btnGuardarModalRol btnConfirmOS"
                      onClick={(e) => {
                        onSave();
                      }}
                    >
                      Guardar
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      ) : (
        <div>
          <h4>No tienes permisos</h4>
        </div>
      )}
      {showModalUsers && (
        <ModalUsers
          usuariosAws={usuarios}
          setShowModalUsers={setShowModalUsers}
          setRefresh={setRefresh}
          accounts={accountList}
          users={userList}
          getClientsOrder={getClientsOrder}
          isAdmin={isAdmin}
        />
      )}
      {showModalAccounts && (
        <ModalAccounts
          setShowModalAccounts={setShowModalAccounts}
          showModalAccounts={showModalAccounts}
          refresh={refresh}
          setRefresh={setRefresh}
          setShowModal={setShowModal}
        />
      )}
    </>
  );
};
const mapStateToProps = (state) => ({
  clients: state.clients,
  accounts: state.accounts.list,
  currentUser: state.app.current_user,
  pwaAccounts: state.pwa.accounts.list,
  currentAccount: state.accounts.default,
  currentPWAAccount: state.pwa.accounts.default,
});
const mapDispatchToProps = (dispatch) => ({
  _showConfirmationModal: (title, message, callback) =>
    dispatch(
      Modal.showAlertModal(
        title,
        message,
        Modal.MODAL_TYPE_CONFIRMATION,
        callback
      )
    ),
  _newEmailValidation: (idAccount, modulo, data, usuario, accounts, action) =>
    dispatch(
      newEmailValidation(idAccount, modulo, data, usuario, accounts, action)
    ),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withUtils(RolesPermisos));
