import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import * as Modal from "redux/actions/modal.actions";
import { withUtils } from "utils";
import _, { words } from "lodash";
import { newEmailValidation } from "redux/actions/email.actions";
import ReactDataTable, {
  SimpleText,
  NumberFormat,
  OptionsSelectedEdit,
} from "@easygosanet/react-datatable";
import "./assets/style.scss";
import DetailColEdit from "components/DetailCol";
import DataTableUtils, {
  Share,
  ShowColumn2,
  ShowColumn,
  FilterColumn,
  FilterColumn2,
} from "@easygosanet/react-datatable-utils";
import ModalAddRol from "./ModalAddRol";
import { v4 } from "uuid";
import classnames from "classnames";
import Swal from "sweetalert2";
import analytics from "../../helpers/analytics";
import * as APILA from "helpers/apiLA";
import * as API from "helpers/api";
import PageLoader from "components/page_loader";
import ModalEditRol from "./modalEditRol";
import "./assets/style.scss";
import { Tooltip } from "antd";
import { log } from "utils/logger";
import { Auth } from "aws-amplify";
import { useLocation, useHistory } from "react-router-dom/cjs/react-router-dom.min";

const RolesAssignment = (props) => {

  /* Hooks para navegacion y para push del pathname */
  const location = useLocation()
  const history = useHistory()

  /* Instancias para obtener los parametros */
  const currentUrl = new URLSearchParams(location.pathname)
  const currentSearch = new URLSearchParams(location.search)

  /* Obteneido los parametros */
  const accountParams = location.pathname?.split("/configuracion/asignacion-roles/account=")[1]?.split('&')[0]
  const pageParams = Number(currentUrl.get('page'))
  const searchParams = currentSearch.get("search_query")
  const librearyParams = currentSearch.get('Roles')

  /* Valor del input de la libreria */
  const url = window.location.href;
  const urlObject = new URL(url);
  const nombreParamans = urlObject.searchParams.get("Roles") || "";
  const [currentPage, setCurrentPage] = useState(pageParams || 1);

  const initialValue = [
    {
      column: "Roles",
      value: nombreParamans || ""
    }
  ]


  const [dataEdit, setDataEdit] = useState([]);
  const [dataBack, setDataBack] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [modalAdd, setmodalAdd] = useState(false);
  const [modalEdit, setmodalEdit] = useState(false);
  const [data, setData] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [rolesPredeterminado, setRolesPredeterminado] = useState([]);
  const [filterInfo, setfilterInfo] = useState(initialValue);
  const [isAction, setIsAction] = useState(true);
  const [editSup, setEditSup] = useState(false);
  const [permissions, setPermissions] = useState({
    approve: false,
    create: false,
    delete: false,
    edit: false,
    export: false,
    manageRoles: false,
    view: true,
  });

  const changeUrl = ({ pagination = pageParams }) => {
    const validateAccount = accountParams === undefined || accountParams === null || accountParams.length == 0 ? props.currentAccount : accountParams
    history.push({
      pathname: `/configuracion/asignacion-roles/account=${validateAccount}&page=${pagination}`
    })
    if (location.search.length > 0) {
      history.push({
        search: location.search
      })
    }
  }

  const [columns, setColumns] = useState([
    {
      id: "name",
      name: "Roles",
      selector: "name",
      sortable: true,
      center: true,
      tag: "name",
      show: true,
      visible: false,
      type: "text",
      omit: false,
      style: {
        width: "100%",
        fontWeight: "normal",
      },
      cell: (row) => (
        // <DetailCol text={row} onClick={() => {}} />,
        <DetailColEdit
          className="editCol"
          text={row.name}
          budgetOld={false}
          accounts={[]}
          id={row.id}
          viewBudget={false}
          deleteData={() => eliminarRol(row)}
          deleteV={row.type === "Predeterminado" ? false : row.editable}
          // deleteV={row.permisos.delete}
          nameColumn={row}
          activate={true}
          editInfo={() => {
            setmodalEdit(true);
            setDataEdit(row);
            analytics.accionesTabla({
              accion: "Editar",
              ubicacion: "Asignaciones",
            });
          }}
          edit={
            // row.type === "Predeterminado" ? false : row.editable
            true
          }
          onEditValue={editData}
          editable={
            // row.type === "Predeterminado" ? false : row.editable
            false
          }
          type={"text"}
          permissions={
            {
              approve: true,
              create: true,
              delete: true,
              edit: true,
              export: true,
              manageRoles: true,
              view: true,
            }
            // row.permisos
          }
        />
      ),
    },
    {
      id: "type",
      name: "Tipo de rol",
      selector: "type",
      sortable: true,
      center: true,
      tag: "type",
      visible: true,
      type: "text",
      omit: false,
    },
  ]);

  const handleChangePage = (page) => {
    setCurrentPage(page)
    changeUrl({
      pagination: page
    })
  }

  const permisos = async () => {
    try {
      setIsLoading(true);
      let sub = Auth.user.signInUserSession.idToken.payload.sub;
      let permisosUser = await APILA.getPermitsByUser({
        user: sub,
      });
      let permisSupAdmin = permisosUser.data.permits?.find(
        (ele) => ele.idRol === "Super Administrador",
      );
      if (permisSupAdmin) {
        console.log("Eres superAdmin", permisSupAdmin);
        let permisosResponse = await APILA.getRoleNameById({ id: permisSupAdmin.idRol });
        console.log("Permisos response", permisosResponse);
        let permisosRoles = permisosResponse.data.rol[0].permissions;
        let permissFn = permisosRoles.find((ele) => ele.name === "Roles de sistema");
        console.log("gestion", permissFn);
        // setPermissions(permissFn)
        setPermissions((state) => {
          return {
            view: permissFn.view,
            approve: permissFn.approve,
            create: permissFn.create,
            delete: permissFn.delete,
            edit: permissFn.edit,
            export: permissFn.export,
            manageRoles: permissFn.manageRoles,
          };
        });
        console.log("Los permisos", permisosRoles);
        return permissFn;
      } else {
        let permisAccouunt = permisosUser.data.permits?.find(
          (ele) => ele.account === props.currentAccount,
        );
        console.log("El permiso por cuenta", permisSupAdmin);
        let idRol = permisAccouunt.idRol;
        let permisosResponse = await APILA.getRoleNameById({ id: idRol });
        let permissFn = permisosResponse.data.rol[0].permissions.filter(
          (ele) => ele.name === "Roles de sistema",
        );
        setPermissions((state) => {
          return {
            view: permissFn[0].view,
            approve: permissFn[0].approve,
            create: permissFn[0].create,
            delete: permissFn[0].delete,
            edit: permissFn[0].edit,
            export: permissFn[0].export,
            manageRoles: permissFn[0].manageRoles,
          };
        });
        console.log("Los permisos de esta cuenta", permissFn);
        return permissFn[0];
      }
    } catch (err) {
      setPermissions((state) => {
        return {
          approve: true,
          create: true,
          delete: true,
          edit: true,
          export: true,
          manageRoles: true,
          view: true,
        };
      });
      setIsLoading(false);
      console.log("err permits", err);
    }

    // try {

    //   setIsLoading(true);
    //   let userLog = await Auth.currentAuthenticatedUser()
    //   let permisoAcc = await APILA.getPermitsByAccountAndUser({
    //     account: props.currentAccount,
    //     user: userLog.username,
    //   });
    //   let idRol = permisoAcc.data.permisos[0].idRol;
    //   let permisosRes = await APILA.getRoleNameById({ id: idRol });

    //   let permissAud = permisosRes.data.rol[0].permissions;
    //   let permissFn = permissAud.filter((ele) => ele.name == "Roles de sistema");

    //   setPermissions((state) => {
    //     return {
    //       approve: (state.approve = permissFn[0].approve),
    //       create: (state.create = permissFn[0].create),
    //       delete: (state.delete = permissFn[0].delete),
    //       edit: (state.edit = permissFn[0].edit),
    //       export: (state.export = permissFn[0].export),
    //       manageRoles: (state.manageRoles = permissFn[0].manageRoles),
    //       view: (state.view = permissFn[0].view),
    //     };
    //   });
    //   console.log('El usuario logeado', userLog);
    //   /* setIsLoading(false); */
    // } catch (err) {

    //   /* setIsLoading(false); */
    //   console.log("err permisos", err);
    // }
  };
  const editData = async (value) => {
    let title = "Está seguro que desea editar?";
    props._showConfirmationModal("Editar rol", title, async () => {
      analytics.accionesTabla({
        accion: "Editar con doble clic",
        ubicacion: "Asignaciones",
      });
      let { id, column } = value[0];
      let valor = value[0].value;

      let body = {
        id: id,
        name: valor,
        type: column.type == "Predeterminado" ? "Predeterminado" : "Personalizado",
        rolPadre: column.rolPadre,
        permissions: column.permissions,
      };
      try {
        let insertRoles = await APILA.insertRoles(body);
        props._newEmailValidation(
          props.currentAccount,
          "Roles",
          body,
          props.currentUser,
          props.accounts,
          "Edición",
        );
        setRefresh(true);
        Swal.fire({
          title: "Rol editado!",
          text: "Rol editado correctamente.",
          icon: "success",
          showCancelButton: false,
          confirmButtonColor: "#3085d6",
          confirmButtonText: "Ok",
        });
      } catch (error) {
        Swal.fire({
          title: "Ups!",
          text: "Hubo un error inesperado.",
          icon: "error",
          showCancelButton: false,
          confirmButtonColor: "#3085d6",
          confirmButtonText: "Ok",
        });
      }
    });
  };

  const getRolesNames = async () => {
    let rolesDefault = [];
    setIsLoading(true);
    let responseRoleNames = await APILA.getRoleNames({ account: props.currentAccount });
    let response = await APILA.getPermitsByUser({
      user: Auth.user.signInUserSession.idToken.payload.sub,
    });
    response = response.data.permits;
    // setPermissions(response)
    console.log("AAAAAAAAA", response);
    let edit = false;
    if (response.length > 0) edit = response.some((ele) => ele.idRol === "Super Administrador");
    console.log("La data ", response);
    permisos().then((data) => {
      if (responseRoleNames.data.count > 0) {
        let array = [];
        responseRoleNames.data.roleNames.forEach((el) => {
          array.push({
            id: el.id,
            name: el.name,
            type: el.type,
            permissions: el.permissions,
            rolPadre: el.rolPadre,
            editable: edit,
            permisos: data,
          });
          if (el.type == "Predeterminado") {
            rolesDefault.push({
              id: el.id,
              name: el.name,
              type: el.type,
              permissions: el.permissions,
              rolPadre: el.rolPadre,
              editable: edit,
              permisos: data,
            });
          }
        });
        console.log("Los datossss", array);
        setData([...array]);
        setDataBack([...array]);
        setRolesPredeterminado([...rolesDefault]);
      }
      setRefresh(false);
      setIsLoading(false);
    });
  };

  useEffect(() => {
    getRolesNames();
  }, [refresh, props.currentAccount]);

  // useEffect(() => {
  // if(nombreParamans && nombreParamans.length > 0) {
  //   setTimeout(() => {
  //     handleFilterPerColumn(initialValue)
  //   }, 1000)
  // }
  // }, [data]);

  // useEffect(() => {
  //   permiso()
  // }, [props.currentUser])

  const DetailCol = ({ text, onClick }) => (
    <div className="content-firstColumn">
      <div className={classnames("iconBand")}>
        <i
          onClick={(e) => {
            setmodalEdit(true);
            setDataEdit(text);
          }}
          className="fas fa-edit"
        />
        {text.type != "Predeterminado" && (
          <>
            <i className="fas fa-trash" onClick={(e) => eliminarRol(text)} />
          </>
        )}
      </div>
      <p className="name-fc">{text.name}</p>
    </div>
  );

  const esp = {
    rowsPerPageText: "Filas por página:",
    rangeSeparatorText: "de",
    selectAllRowsItem: true,
  };

  const customStyles = {
    headCells: {
      style: {
        color: "#000",
        textAlign: "center",
        justifyContent: "center",
        backgroundColor: "#fff",
        "&:nth-of-type(2n)": {
          backgroundColor: "#eff0f2",
          borderRadius: "10px 10px 0 0",
        },
      },
    },
    cells: {
      style: {
        justifyContent: "center",
        backgroundColor: "#fff",
        "&:nth-of-type(1)": {
          justifyContent: "left",
        },
        "&:nth-of-type(2n)": {
          backgroundColor: "#eff0f2",
        },
      },
    },
  };
  const eliminarRol = async (rol) => {
    // console.log('El rol que estoy recibiendo', rol.id);
    let title = "Esta seguro que desea eliminar el rol " + rol.name + "?";
    props._showConfirmationModal("Eliminar rol?", title, async () => {
      let usuarioELiminado = await APILA.deleteRolName({ id: rol.id });
      props._newEmailValidation(
        props.currentAccount,
        "Roles",
        rol,
        props.currentUser,
        props.accounts,
        "Eliminación",
      );
      let bodyBitacora = {
        id: v4(),
        date: new Date(),
        account: props.currentAccount,
        action: "se ha eliminado un rol",
        component: "roles",
        data: rol,
      };
      await APILA.insertBitacora(bodyBitacora);
      Swal.fire({
        title: "Rol eliminado!",
        text: "Rol eliminado correctamente.",
        icon: "success",
        showCancelButton: false,
        confirmButtonColor: "#3085d6",
        confirmButtonText: "Ok",
      });
      //comentar en prod
      analytics.accionesTabla({
        accion: "Eliminar",
        ubicacion: "Asignaciones",
      });
      setRefresh(true);
    });
  };
  const handleFilterTextChange = (texto) => {
    //comentar en prod
    analytics.busqueda({
      ubicacion: "Asignaciones",
      busqueda: texto,
    });

    if (texto !== "") {
      if (librearyParams && librearyParams.length > 0) {
        setfilterInfo(initialValue)
        history.push({
          search: ""
        })
        history.push({
          search: `search_query=${texto}`
        })
      } else {
        history.push({
          search: `search_query=${texto}`
        })
      }
    } else {
      history.push({
        search: ""
      })
    }

    let filteredData = dataBack.filter((item) => {
      if (item.name.toLowerCase().includes(texto.toLowerCase())) {
        return item.name.toLowerCase().includes(texto.toLowerCase());
      } else if (item.type.toLowerCase().includes(texto.toLowerCase())) {
        return item.type.toLowerCase().includes(texto.toLowerCase());
      }
    });
    setData([...filteredData]);
  };
  const handleColumnSortChange = (positions) => {
    props.utils.log("handleColumnSortChange");
    props.utils.log("positions", positions);

    let aux = columns.map((col) => col);

    positions.forEach((columnId, position) => {
      let index = columns.findIndex((col) => col.selector === columnId);
      props.utils.log("Index", index);
      aux[index].position = position;
    });

    aux = _.orderBy(aux, ["position"]);

    props.utils.log("new columns", aux);

    setColumns(aux);
  };
  const handleShowColumnChange = (column, show) => {
    props.utils.log("handleFilterTextChange");
    props.utils.log("column", column);
    props.utils.log("show", show);

    let index = columns.findIndex((col) => col.id === column);
    let aux = columns.map((col) => col);
    aux[index].omit = show;
    setColumns(aux);
  };
  const handleFilterPerColumn = (value) => {
    let dataFinal = [];
    if (value[0]?.value === "") {
      history.push({
        search: ""
      });
    }
    if (value.length > 0) {
      setfilterInfo([...value]);
      analytics.filtro({
        ubicacion: "Asignaciones",
      });

      value.forEach((ele) => {
        if (searchParams && searchParams.length > 0) {
          history.push({
            search: ""
          })
          history.push({
            search: `filter_query=&${ele.column}=${ele.value}`
          })
        } else {
          if (value[0].value.length > 0) {
            history.push({
              search: `filter_query=&${ele.column}=${ele.value}`
            })
          }
        }
        if (ele.column == "Roles") {
          let dataAlt = dataBack.filter((eles) =>
            eles.name.toLowerCase().includes(ele.value.toLowerCase()),
          );
          if (dataAlt.length > 0) {
            dataFinal = [...dataFinal, ...dataAlt];
          }
        }
        if (ele.column == "Tipo de rol") {
          let dataAlt = dataBack.filter((eles) => eles.type == ele.value);
          if (dataAlt.length > 0) {
            dataFinal = [...dataFinal, ...dataAlt];
          }
        }
      });
      // let arrSinDuplicaciones = eliminarDuplicaciones(accountsFinales);
      let set = new Set(dataFinal.map(JSON.stringify));
      let arrSinDuplicaciones = Array.from(set).map(JSON.parse);
      setData(arrSinDuplicaciones);
    } else {
      history.push({
        search: ""
      })
      setfilterInfo([]);
      setData(dataBack);
    }
  };
  const exportDataToExcel = () => {
    let headers = [];
    let dataExcel = [];
    let selectors = [];
    let excelData = [];

    let filterColumn = columns.filter((col) => {
      return col.omit === false;
    });

    filterColumn.forEach((element) => {
      headers.push(element.name);
      selectors.push(element.tag);
    });
    dataExcel.push(headers);
    if (data.length > 0) {
      data.forEach((element) => {
        excelData = [];
        for (let k = 0; k < selectors.length; k++) {
          excelData.push(selectors[k] == "name" ? element[selectors[k]] : element[selectors[k]]);
        }
        dataExcel.push(excelData);
      });
    }

    return dataExcel;
  };

  return (
    <>
      {isLoading ? (
        <PageLoader />
      ) : permissions.view ? (
        <>
          <div className="mainRolSistema">
            <div className="barraSuperior">
              <DataTableUtils
                data={[]}
                action={
                  permissions.create
                    ? () => {
                      setmodalAdd(true);
                      analytics.Add({
                        ubicacion: "Asignaciones",
                      });
                    }
                    : null
                }
                actionIcon={
                  permissions.create && (
                    <Tooltip color="#002448" placement="top" title="Crear rol">
                      <i className="fas fa-plus" />
                    </Tooltip>
                  )
                }
                onChangeFilterText={handleFilterTextChange}
                valueFilter={searchParams || ""}
              >
                <Tooltip color="#002448" placement="top" title="Exportar infomación">
                  <div
                    onClick={() => {
                      //comentar en prod
                      analytics.Compartir({
                        ubicacion: "Asignaciones",
                      });
                    }}
                    className="ExportBtn"
                  >
                    {permissions.export && (
                      <Share
                        contacts={[]}
                        data={exportDataToExcel()}
                        columns={columns}
                        isArray={true}
                        exportXLSX={true}
                        exportTXT={true}
                        exportDropbox={true}
                        exportDrive={true}
                        exportCSV={true}
                        printable={true}
                        exportPNG={true}
                        exportLink={true}
                        idImg={"root"}
                        appKey="2u78gno172idwbz"
                      />
                    )}
                  </div>
                </Tooltip>
                <Tooltip color="#002448" placement="top" title="Personalizar columnas">
                  <div id="shareBtn">
                    <ShowColumn2
                      columns={columns}
                      showColumn={(data, data1) => {
                        analytics.ShowHide({ ubicacion: "Asignaciones" });
                        handleShowColumnChange(data, data1);
                      }}
                      showView={() => { }}
                      onSortChange={(position) => {
                        analytics.Columnas({
                          ubicacion: "Asignacion",
                        });
                        handleColumnSortChange(position);
                      }}
                      vistas={[]}
                      setVista={() => { }}
                      deleteView={() => { }}
                      nameAc={"lista"}
                    />
                  </div>
                </Tooltip>
                <Tooltip color="#002448" placement="top" title="Filtrar y agrupar">
                  <div className="Filtro2 filtro3">
                    <FilterColumn2
                      columns={columns}
                      data={[
                        { name: "Predeterminado", nombrePadre: "Tipo de rol" },
                        { name: "Personalizado", nombrePadre: "Tipo de rol" },
                      ]}
                      onFilterColumn={handleFilterPerColumn}
                      filterInfoCheck={filterInfo}
                      categories={[["Tipo de rol"]]}
                    />
                  </div>
                </Tooltip>
              </DataTableUtils>
            </div>

            <div className="containerTable">
              <ReactDataTable
                columns={columns}
                data={data}
                persistTableHead
                customStyles={customStyles}
                pagination
                currentPage={currentPage}
                onChangePage={handleChangePage}
                paginationDefaultPage={currentPage}
                paginationComponentOptions={esp}
                noHeader
              />
            </div>
          </div>
          {modalAdd && (
            <ModalAddRol
              setShowModal={setmodalAdd}
              roleNames={rolesPredeterminado}
              setRefresh={setRefresh}
            />
          )}
          {modalEdit && (
            <ModalEditRol setShowModal={setmodalEdit} data={dataEdit} setRefresh={setRefresh} />
          )}
        </>
      ) : (
        <h1>No tienes Permisos</h1>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  clients: state.clients,
  accounts: state.accounts.list,
  currentUser: state.app.current_user,
  pwaAccounts: state.pwa.accounts.list,
  currentAccount: state.accounts.default,
  currentPWAAccount: state.pwa.accounts.default,
});
const mapDispatchToProps = (dispatch) => ({
  _newEmailValidation: (idAccount, modulo, data, usuario, accounts, action) =>
    dispatch(newEmailValidation(idAccount, modulo, data, usuario, accounts, action)),
  _showConfirmationModal: (title, message, callback) =>
    dispatch(Modal.showAlertModal(title, message, Modal.MODAL_TYPE_CONFIRMATION, callback)),
});
export default connect(mapStateToProps, mapDispatchToProps)(withUtils(RolesAssignment));
