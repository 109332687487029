import * as Actions from '../actions/schedule.actions';

const initialState = {
    edit: {},
    onSave: false,
    permisos: []
}

export default (state = initialState, action) => {
    switch (action.type) {
        case Actions.SCHEDULE_SET_EDIT:
            return {
                ...state,
                edit: action.edit
            }
        case Actions.SCHEDULE_CLEAR_EDIT:
            return {
                ...state,
                edit: {}
            }
        case Actions.SCHEDULE_ONSAVE:
            return {
                ...state,
                onSave: action.onSave
            }
        case Actions.SCHEDULE_PERMISOS:
            return {
                ...state,
                permisos: action.permisos
            }
        default:
            return state
    }
}