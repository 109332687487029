import React, { useState, useEffect } from "react";
import _ from "lodash";
import classnames from "classnames";
import { connect } from "react-redux";
import { v4 } from "uuid";
import { FormGroup, Label, Input, FormFeedback } from "reactstrap";
import analytics from "../../../helpers/analytics";
import * as Modal from "redux/actions/modal.actions";
import { createCategory } from "redux/actions/categories.actions";
import { pwaUpsertCategory } from "redux/actions/pwa.actions";
import { withUtils } from "utils";
import Select from "libs/select";
import "./style.scss";
import { Auth } from "aws-amplify";
const idmod = 1;
const mod = "CATEGORIES";

const TAG = "UpsertCategory";

const UpsertCategory = (props) => {
  // const [hijoValidacion, setHijoValidacion]=useState(false)
  const [errors, setErrors] = useState({ name: false });
  const [state, setState] = useState({
    id: "",
    name: "",
    edit: false,
    createAt: 0,
    padre: "",
    tipo: "",
  });

  useEffect(() => {
    if (!_.isEmpty(props.edit)) {
      let newState = {
        edit: true,
        id: props.edit.id,
        name: props.edit.name,
        createAt: props.edit.createAt,
        padre: props.edit.padre,
        tipo: props.edit.tipo,
      };

      setState((prev) => ({
        ...prev,
        ...newState,
      }));
    } else {
      setState({
        id: "",
        name: "",
        edit: false,
        createAt: 0,
        padre: "",
        tipo: "",
      });
    }
  }, [props.edit]);

  const handleInputChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;

    setState((prev) => ({
      ...prev,
      [name]: value,
    }));

    setErrors((prev) => ({
      ...prev,
      [name]: _.isEmpty(value),
    }));
  };

  const closeModal = (e) => {
    if (e) e.preventDefault();
    props.utils.log(TAG, "clearState");
    analytics.modalesAcciones({
      accion: "Cancelar",
      ubicacion: "Categories",
    });
    window.$.fancybox.close();
    setState({
      id: "",
      name: "",
      edit: false,
      createAt: 0,
      padre: "",
      tipo: "",
    });
  };

  const handleUpsert = (e) => {
    e.preventDefault();
    analytics.modalesAcciones({
      accion: "Confirmar",
      ubicacion: "Categories",
    });
    if (!_.isEmpty(state.name)) {
      const categories =
        props.currentUser !== "" ? props.categories : props.pwaCategories;
      let existingPreviousCategory = _.find(categories, {
        name: state.name.trim(),
      });
      if (!state.edit && existingPreviousCategory) {
        props._showAlertModal(
          "Categoría Existente",
          "Ya existe una categoría con este nombre",
          Modal.MODAL_TYPE_ERROR,
          () => { }
        );
        return;
      }
      let categoryId = state.id;
      if (categoryId === "") {
        categoryId = v4();
      }
      if (props.currentUser !== "") {
        console.log("state", state);
        if (state.padre == "") {
          //Si es padre tendra el id de categoria por defecto
          state.padre = "97bc9ee5-36a0-455b-974c-fa7832c9c341";
        }
        console.log("stateCategory", state);
        if (state.createAt === 0) state.createAt = Date.now()
        Auth.currentAuthenticatedUser().then((userdata) => {
          console.log('userData', userdata.attributes.email)
          props._createCategory(
            categoryId,
            state.name,
            props.currentAccount,
            state.edit,

            state.createAt,
            state.padre,
            state.tipo,
            props.currentUser,
            idmod,
            mod,
            props.accounts,
            state.edit ? 'Edición' : 'Creación',
            userdata.attributes.email
            // "97bc9ee5-36a0-455b-974c-fa7832c9c341"
          );
        })
          .catch(error => {
            console.log('Sin usuario', { error })
            if (error === 'The user is not authenticated') {
              props._createCategory(
                categoryId,
                state.name,
                props.currentAccount,
                state.edit,
                state.createAt,
                state.padre,
                state.tipo,
                props.currentUser,
                idmod,
                mod,
                props.accounts,
                state.edit ? 'Edición' : 'Creación',
                props.currentUser,
              );
            }
          })

      } else {
        props._pwaUpsertCategory(
          categoryId,
          state.name,
          props.currentPWAAccount,
          state.edit,
          state.padre,
          state.tipo
        );
      }

      closeModal();
    } else {
      if (!state.name) {
        props._showAlertModal(
          "Informacion faltante",
          "Debe ingresar un nombre para la categoria",
          Modal.MODAL_TYPE_ERROR,
          () => { }
        );

        return;
      }
    }
  };

  const getIDDefaultCat = () => {
    console.log("getIDDefaultCat");
    console.log(props.categories);
    const found = props.categories.find((element) => element.padre == "");
    console.log("getIDDefaultCat ", found);
    if (!found) {
      return "";
    } else {
      return found.id;
    }
    // return "97bc9ee5-36a0-455b-974c-fa7832c9c341"
  };

  // Evalar si campo es null para verificar si es padre
  let categories = [];
  let filtroPadre = props.categories.filter((item) => {
    return item.padre;
  });

  //console.log("filtro,filtroCategoriaPadre", filtroPadre);

  filtroPadre.map((category) => {
    categories = [
      ...categories,
      {
        value: category.id,
        label: category.name,
      },
    ];
  });
  let currentCategory = categories.filter(
    (category) => category.value === state.padre
  );

  /* */
  let arrayPadre = [];
  let arrayCategoriaPadre = [
    {
      id: "Padre",
      name: "Padre",
    },
    {
      id: "Hijo",
      name: "Hijo",
    },
  ];

  //console.log("filtro,filtroCategoriaPadre", filtroPadre);

  arrayCategoriaPadre.map((item) => {
    arrayPadre = [
      ...arrayPadre,
      {
        value: item.id,
        label: item.name,
      },
    ];
  });
  let currentPadre = arrayPadre.filter((item) => item.label === state.tipo);
  /* */

  const handleCategoryChange = (option) => {
    console.log("handleCategoryChange", option);
    setState((prev) => ({
      ...prev,
      padre: option.value,
    }));
  };

  const handleCategoryFatherChange = (option) => {
    console.log("handleCategoryPadreChange option", option);
    console.log("handleCategoryFatherChange props.edit.hijo", props.edit.tipo);
    // if(option.label==="Hijo" || props.edit.hijo==="Hijo"){
    //   setHijoValidacion(true)
    // }else{
    //   setHijoValidacion(false)
    // }
    setState((prev) => ({
      ...prev,
      tipo: option.label,
    }));
  };

  return (
    <div
      id="new-category-modal"
      style={{ display: "none" }}
      className={classnames("upsert-modal")}
    >
      <div className="foot-mnc">
        <div className="formOneStep">
          <div className="titleOneStep">
            <p className="h3">
              {state.edit
                ? "Actualizemos tu categoría"
                : "Creemos una nueva categoría"}
            </p>
          </div>
          {/* !state.edit &&(
          <div className='bodyOneStep'>
            <div className='twoColBody'>
              <FormGroup>
                <Label
                  style={{
                    color: '#05252d',
                    fontWeight: 500,
                    marginBottom: '5px',
                  }}
                >
                  Seleccionar el tipo de categor&iacute;a que vas a ingresar
                </Label>
                   <Select
                    options={arrayPadre}
                    value={currentPadre}
                    placeholder='Ninguna'
                    onChange={handleCategoryFatherChange}
                    // className={classnames({
                    //   'is-invalid': errors.objetivo,
                    // })}
                  />
                <FormFeedback>Ingresa un nombre</FormFeedback>
              </FormGroup>
            </div>
          </div>) */}

          {!state.edit && (
            <div className="bodyOneStep">
              <br></br>
              <div className="twoColBody">
                <FormGroup>
                  <Label
                    style={{
                      color: "#05252d",
                      fontWeight: 500,
                      marginBottom: "5px",
                    }}
                  >
                    Seleccionar categor&iacute;a
                  </Label>
                  <Select
                    options={categories}
                    value={currentCategory}
                    placeholder="Ninguna"
                    onChange={handleCategoryChange}
                  // className={classnames({
                  //   'is-invalid': errors.objetivo,
                  // })}
                  />
                  <FormFeedback>Ingresa un nombre</FormFeedback>
                </FormGroup>
              </div>
            </div>
          )}

          <div className="bodyOneStep">
            <div className="twoColBody">
              <FormGroup>
                <Label
                  style={{
                    color: "#05252d",
                    fontWeight: 500,
                    marginBottom: "5px",
                  }}
                >
                  Ponle un nombre a tu subcategor&iacute;a
                </Label>
                <Input
                  type="text"
                  name="name"
                  className="input"
                  value={state.name}
                  onChange={handleInputChange}
                  placeholder="P. ejemplo madres solteras"
                  invalid={errors.name}
                />
                <FormFeedback>Ingresa un nombre</FormFeedback>
              </FormGroup>
            </div>
          </div>

          <div className="footOneStep w-100 footOneStepModal2">
            <button className="btnCancelOS firstBtn" onClick={closeModal}>
              Cancelar
            </button>
            <button
              href="#"
              className="btnConfirmOS secondBtn"
              onClick={handleUpsert}
            >
              Confirmar
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  edit: state.categories.edit,
  categories: state.categories.list,
  currentUser: state.app.current_user,
  currentAccount: state.accounts.default,
  pwaCategories: state.pwa.categories.list,
  accounts: state.accounts.list,
  currentPWAAccount: state.pwa.accounts.default,
});

const mapDispatchToProps = (dispatch) => ({
  _showAlertModal: (title, message, type, callback) =>
    dispatch(Modal.showAlertModal(title, message, type, callback)),
  _createCategory: (
    id,
    name,
    account,
    edit,
    createAt,
    padre,
    tipo,
    user,
    idmod,
    mod,
    accounts,
    accion,
    userEmail
  ) =>
    dispatch(
      createCategory(
        id,
        name,
        account,
        edit,
        createAt,
        padre,
        tipo,
        user,
        idmod,
        mod,
        accounts,
        accion,
        userEmail
      )
    ),
  _pwaUpsertCategory: (id, name, account, edit) =>
    dispatch(pwaUpsertCategory(id, name, account, edit)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withUtils(UpsertCategory));
