import CryptoJS from "crypto-js";

export const cifrar = (texto, password) => async (dispatch) => {
  var textoCirrado = CryptoJS.AES.encrypt(texto, password).toString();
  return textoCirrado;
};
export const descifrar = (texto, password) => async (dispatch) => {
  var bytes = CryptoJS.AES.decrypt(texto, password);
  var originalText = bytes.toString(CryptoJS.enc.Utf8);
  return originalText;
};