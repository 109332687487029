import React from 'react'
import { Switch, Route, Redirect, withRouter } from 'react-router-dom'

// componentes
import Navbar from '../../components/navBar'
import Loader from '../../components/loader'
import Modal from '../../components/modal'

// pages
import SignIn from './signin'
import SignUp from './signup'
import ConfirmSignUp from './confirm_signup'
import PasswordRecovery from './password_recovery'
import ConfirmNewPassword from './confirm_new_password'
import Register from './register'
import ConfirmUserService from './confirmUserService'
import TermsCondition from './termsAndCondition'

const Auth = props => {
  const validAuthStates = ['signIn', 'signUp', 'signedOut', 'signedUp', 'confirmSignUp', 'forgotPassword', 'register', 'termsAndCondition']
  const validLoginStates = ['signIn', 'signedOut', 'signedUp']
  const validSignUpStates = ['signUp']
  const register = ['register']
  const validPasswordRecoveryStates = ['forgotPassword']
  const validConfirmSignUpStates = ['confirmSignUp']
  // const validConfirmSignInStates = ['confirmSignIn']
  // const validVerifyContactStates = ['verifyContact']
  const validRequiredNewPasswordStates = ['requireNewPassword']
  const confirmUserService = ['confirmUserService']
  const termsService = ['termsAndCondition']
  return(
    <>
      {validAuthStates.includes(props.authState) && <Navbar {...props}/>}
      {termsService.includes(props.authState) && <TermsCondition {...props} />}
      {validLoginStates.includes(props.authState) && <SignIn {...props} />}
      {validSignUpStates.includes(props.authState) && <SignUp {...props} />}
      {register.includes(props.authState) && <Register {...props} />}
      {validConfirmSignUpStates.includes(props.authState) && <ConfirmSignUp {...props} />}
      {validPasswordRecoveryStates.includes(props.authState) && <PasswordRecovery {...props} />}
      {validRequiredNewPasswordStates.includes(props.authState) && <ConfirmNewPassword {...props} />}
      {confirmUserService.includes(props.authState) && <ConfirmUserService {...props}/>} 
      <Loader />
      <Modal />
    </>
  )
}

export default withRouter(Auth)
