import React from 'react'
import chroma from 'chroma-js'
import CreatableSelect from 'react-select/creatable'

import stringToColour from 'utils/stringToColour'

const colourStyles = {
  control: (styles) => ({ ...styles, backgroundColor: 'white' }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    console.log('data', data)
    const color = chroma(stringToColour(data.label))
    return {
      ...styles,
      backgroundColor: isDisabled
        ? null
        : isSelected
        ? stringToColour(data.label)
        : isFocused
        ? color.alpha(0.1).css()
        : null,
      color: isDisabled
        ? '#ccc'
        : isSelected
        ? chroma.contrast(color, 'white') > 2
          ? 'white'
          : 'black'
        : stringToColour(data.label),
      cursor: isDisabled ? 'not-allowed' : 'default',

      ':active': {
        ...styles[':active'],
        backgroundColor:
          !isDisabled && (isSelected ? stringToColour(data.label) : color.alpha(0.3).css()),
      },
    }
  },
  multiValue: (styles, { data }) => {
    console.log('data', data)
    const color = chroma(stringToColour(data.label))
    return {
      ...styles,
      backgroundColor: color.alpha(0.1).css(),
    }
  },
  multiValueLabel: (styles, { data }) => ({
    ...styles,
    color: stringToColour(data.label),
  }),
  multiValueRemove: (styles, { data }) => ({
    ...styles,
    color: stringToColour(data.label),
    ':hover': {
      backgroundColor: stringToColour(data.label),
      color: 'white',
    },
  }),
}

const CreatableMulti = (props) => (
  <CreatableSelect
    formatCreateLabel={(v) => `Crear "${v}"`}
    styles={colourStyles}
    isMulti
    {...props}
  />
)

export default CreatableMulti