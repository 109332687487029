import React, { useEffect, useState, useRef } from 'react'
import { useSelector } from 'react-redux'

export const SimpleText = ({ value, id, onEditValue, editable, nameColumn, type, dataSelect, editCell }) => {
    const [valueE, setvalueE] = useState(value)
    const [data, setData] = useState([])
    const inputRef = useRef(null);
    const useOutsideAlerter = (initialValue) => {
        const ref = useRef(null)
        const [visible, setVisible] = useState(initialValue)

        const handleClickOutside = (event) => {
            if (ref.current && !ref.current.contains(event.target)) setVisible(false)
        }
        useEffect(() => {
            document.addEventListener('click', handleClickOutside, true)

            return () => {
                document.removeEventListener('click', handleClickOutside, true)
            }
        }, [ref])

        return { visible, setVisible, ref }
    }

    const { visible, setVisible, ref } = useOutsideAlerter(false)
    const [dataOptions, setDataOptions] = useState([])
    const validNumber = useSelector((state) => state.templates.editField)

    useEffect(() => {
        if (data.length > 0) {
            onEditValue(data).then(() => {
                if (type !== "text") setvalueE(value)
            })
            setData([])
        }
        if (visible && type === 'text') {
            inputRef.current.focus();
        }
        // Validamos si el numero es valido
        if (validNumber) {
            setvalueE(value)
        }
    }, [visible])

    useEffect(() => {
        if (dataSelect) {
            setDataOptions(dataSelect)
        }
    }, [dataSelect])

    const editData = (valor) => {
        setvalueE(valor)
        let dataAdd = {
            id: id,
            column: nameColumn,
            value: valor
        }
        setData([dataAdd])
    }

    useEffect(() => {
        setvalueE(value)
    }, [value])

    const item = () => {
        if (type == 'select') {
            let result = []
            if (dataSelect) {
                result = dataOptions.filter((item, index) => {
                    return dataOptions.indexOf(item) === index;
                })
            }
            return (
                <>
                    <select className='option-select' defaultValue={"DEFAULT"} onChange={(e) => editData(e.target.value)}>
                        <option className='option-options' value="DEFAULT" disabled >Selecciona una opcion:</option>
                        {result.map((data2, idx) =>
                        (
                            <option className='option-options' key={idx} value={data2}>{data2}</option>
                        )
                        )}
                    </select>
                </>
            )
        }
        if (type == 'text') {
            return (
                <>
                    <input ref={inputRef} className='name-fc' type="text" onChange={(e) => editData(e.target.value)} value={valueE} />
                </>
            )
        }
    }

    const optionsView = () => {
        if (editable) {
            setVisible(true)
        }
    }

    const onPressEnter = (e) => {
        if (e.key === 'Enter') {
            setVisible(false)
        }
    }

    return (
        <>
            <div onKeyDown={onPressEnter} onDoubleClick={() => optionsView()}>
                {visible ?
                    <div ref={ref}>
                        {
                            item()
                        }
                    </div>
                    :
                    <div className={editCell ? "alerta texto" : "texto"} style={{ width: '100px' }} >
                        <p className="name-text">
                            {value}
                        </p>
                    </div>}
            </div>
        </>
    )
}
