import React from 'react'

// components
import MetaTags from '../../components/metatags'
import RegisterForm from '../../components/auth/register'

const Register = props => (
  <>
    <MetaTags title='Registro' description='Página de registro de usuario' />
    <RegisterForm {...props}/>
    {/* <SignInForm {...props} /> */}
  </>
)

export default Register
