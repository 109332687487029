import React, { useState, useEffect } from 'react'
import { includes } from 'lodash'
import { connect } from 'react-redux'
import ReactKanban from '@easygosanet/react-kanban'
import classnames from "classnames";
import status from 'config/status'
import { updateCampaingStatus,funcampaignupd } from 'redux/actions/dashboard.actions'
import { withUtils } from 'utils'
import ModalPauta from "components/modalPauta/ModalPauta";
const TAG = 'Kanban'

const Kanban = ({ nameSelectedGrouped, nameGrouped, campaigns, utils, _updateCampaingStatus, _funcampaignupd, dataEnviada, audiences, refresh, refreshData, clearCampaignupd }) => {
  const [showModal, setShowModal] = useState(false)
  const [campEdit, setCampEdit] = useState([]);
  const [data, setData] = useState({ lanes: []})

  const getCards = (data) => {

    if (nameGrouped === "" || nameGrouped === 'status') {
      const mCampaigns = campaigns.filter((campaign) =>
        includes(data, campaign.status)
      )
      // console.log('datos mcap', mCampaigns);
      return mCampaigns.map((campaign) => {
        return {
          hideCardDeleteIcon: true,
          id: campaign.id,
          title: campaign.name,
          label: campaign.medioo,
          description: `${campaign.fechaIni} al ${campaign.fechafin}`,
        }
      })
    }else{ 
      if(nameSelectedGrouped !== 'select'){ 
        const mCampaigns = campaigns.filter(cap => cap[nameGrouped] === data)
        return mCampaigns.map(camp => { 
          return { 
            id: camp.id, 
            title: camp.name, 
            label: camp.medioo, 
            description: `${camp.fechaIni} al ${camp.fechafin}`
          }
        })
      }else{ 
        const mCampaigns = campaigns.filter(ele => {  
          if(ele.categoriesSelected){ 
            return ele.categoriesSelected.some(cats => cats.padre === nameGrouped)
          }
        })

        return mCampaigns.map(camp => {
          return { 
            id: camp.id, 
            title: camp.name, 
            label: camp.medioo, 
            description: `${camp.fechaIni} al ${camp.fechafin}`
          }
        })
      }

    }

  }

  const getTitlesCars = () => {
    let data = []
    if (nameGrouped === "" || nameGrouped === 'status') {
      for (const propiedad in status) {
        if (campaigns.find(campa => campa.status === status[propiedad].id)) {
          let body = {
            id: status[propiedad].id,
            title: status[propiedad].label,
            label: '',
            style: {
              with: 280,
              color: 'white',
              backgroundColor: status[propiedad].color
            },
            cards: getCards([status[propiedad].id])
          }
          data.push(body)
        }
      }
    } else {
      if (nameSelectedGrouped !== 'select') {
        let titles = [...new Set(campaigns.map(ele => ele[nameGrouped]))]

        titles.forEach(ele => {
          const randomColor = `rgb(${Math.floor(Math.random() * 256)}, ${Math.floor(Math.random() * 256)}, ${Math.floor(Math.random() * 256)})`;
          let body = {
            id: ele,
            title: ele,
            label: "",
            style: {
              with: 280,
              color: 'white',
              backgroundColor: randomColor
            },
            cards: getCards(ele)
          }
          data.push(body)
        })
      } else {
        console.log('el sele', campaigns);

        let titles = campaigns.filter(ele => Array.isArray(ele.categoriesSelected))
          .flatMap(ele => ele.categoriesSelected)
          .filter(ele => ele.padre === nameGrouped)
          .map(ele => ele.label)

        titles.forEach(ele => {
          const randomColor = `rgb(${Math.floor(Math.random() * 256)}, ${Math.floor(Math.random() * 256)}, ${Math.floor(Math.random() * 256)})`;
          let body = {
            id: ele,
            title: ele,
            label: "",
            style: {
              with: 280,
              color: 'white',
              backgroundColor: randomColor
            },
            cards: getCards(ele)
          }
          data.push(body)
        })
      }
    }

    return data.sort((a, b)=>{ return a.title.localeCompare(b.title)})
  }

  useEffect(() => {
    setData({
      lanes: getTitlesCars()
    })
  }, [campaigns])
  

  const handleDragEnd = (cardId, sourceLaneId, targetLaneId) => {
    utils.log(TAG, 'handleDragEnd')
    utils.log(TAG, 'cardId', cardId)
    utils.log(TAG, 'sourceLaneId', sourceLaneId)
    utils.log(TAG, 'targetLaneId', targetLaneId)

    if (sourceLaneId !== targetLaneId) {
      _updateCampaingStatus(cardId, targetLaneId, sourceLaneId)
    }
  }

  const cardClick = (card) => {
    console.log('Card', card)
    const found = campaigns.filter((ele) => ele.id === card)
    console.log('Found', found);
    if (found.length > 0) {
      if (found[0].status != 3 &&
        found[0].status != 6 ) {
        console.log('Se puede editar');
        /* props._funcampaignupd(campaignupd); */
        _funcampaignupd(found[0])
        let fechaIni = found[0].fechaIni.replace(/\./g, "/")
        fechaIni = fechaIni.split('/')
        console.log('LA fecha', fechaIni);
        fechaIni = fechaIni[2] + "/" + fechaIni[1] + "/" + fechaIni[0]
        console.log('LA FECHA', fechaIni);
        setCampEdit(found[0]);
        setShowModal(true);
      }

    }

  }

  const TEXTS = {
    "Add another lane": "+ Agrega una nueva columna",
    "Click to add card": "+ Agrega una nueva",
    "Delete lane": "Elimina la columna",
    "Lane actions": "Opciones",
    "button": {
      "Add lane": "Guardar",
      "Add card": "Guardar",
      "Cancel": "Cancelar"
    },
    "placeholder": {
      "title": "títulos",
      "description": "descripción",
      "label": ""
    }
  }

  useEffect(() => {
    console.log('datarad', data);  
  }, [data])
  

  const handleAdd = (valor) => {
      // agregar la nueva columna solo si cumple con el criterio
      setData({
        ...data,
        lanes: [...data.lanes, valor]
      });
      return true;
  }

  return (
    <div id='Kanbang-content' className='kanban-container'>
      {console.log('las cards', data)}
      <ReactKanban data={data} handleDragEnd={handleDragEnd} onCardClick={cardClick}
        onLaneAdd={handleAdd} canAddLanes editable optionsLanguaje={TEXTS} 
      />
      {showModal && (
            <div className="blur-div blurNewPauta">
              <div
                className={classnames("upsert-modal w-75 h-75 modalapauta")}
                style={{
                  backgroundColor: "white",
                  padding: "20px",
                  borderRadius: "10px",
                }}
              >
                <ModalPauta
                  dataEnviada={dataEnviada}
                  setShowModal={setShowModal}
                  audiences={audiences}
                  campaignupd={campEdit}
                  setRefresh={() => refreshData()}
                  refresh={refresh}
                  _clearCampaignupd={clearCampaignupd}
                />
              </div>
            </div>
          )}
    </div>
  )
}

const mapDispatchToProps = (dispatch) => ({
  _updateCampaingStatus: (campaingId, status, prevStatus) => dispatch(updateCampaingStatus(campaingId, status, prevStatus)),
  _funcampaignupd: (camp) => dispatch(funcampaignupd(camp)),
})

export default connect(undefined, mapDispatchToProps)(withUtils(Kanban))