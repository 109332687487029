import React from "react";
import Lottie from "react-lottie";

import animation from "./assets/json/animation.json";

import "./assets/css/page_loader.scss";

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: animation,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

const PageLoader = () => (
  <div className="page-loader-container d-flex">
    <div className="align-self-center w-100">
      <Lottie options={defaultOptions} height={100} width={100} />
    </div>
  </div>
);

export default PageLoader;
