
import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import './assets/css/style.css'
import * as APILA from 'helpers/apiLA'
import { getUrlImages } from 'redux/actions/modalImage.actions'
import notFound from './assets/image/notfound.jpg'
import MImage from "react-modal-image";

const ModalImage = (props) => {
  const [imagenes, setImagenes] = useState([])
  const [respuesta, setRespuesta] = useState(0)


  const getImg = async () => {
    if (props.id > 0) {
      let response = await APILA.findImagesByMixDetail({ idMixDetaill: props.id })
      setImagenes(response.data.images)
    }
  }

  useEffect(() => {

    if (props.id > 0) {
      getImg();
    }

  }, [props.id])


  const closeModal = (e) => {
    setImagenes([]);
    if (e) e.preventDefault()
    window.$.fancybox.close()
  }


  return (
    <div
      id='detail-image-modal'
      style={{ display: 'none' }}
      className='upsert-modal w-75 h-75'
    >
      {imagenes.length > 0 ? <>
        <div className='formOneStep'>
          <div className='titleOneStep'>
            <p>Imagenes asociadas</p>
          </div>
          <div className='twoColBody'>
            <div class="div-Main">
              <div>
                {imagenes.map(function (d, idx) {
                  return (
                    <div className="div-son">
                      <div className="App">
                        <div className="AppImage">
                          <MImage
                            //comentar en prod

                            small={`https://apps.marketing-suite.net/eadvertising${d.url}`}
                            large={`https://apps.marketing-suite.net/eadvertising${d.url}`}
                            hideDownload={true}
                            hideZoom={true}

                          />
                        </div>
                      </div>
                    </div>)
                })}
              </div>

            </div>
          </div>

          <div className='footOneStep'>
            <a className='btnCancelOS' onClick={closeModal}>
              Cerrar
            </a>
          </div>
        </div>
      </> : <>

        <div className='formOneStep'>
          <div className='titleOneStep'>
            <p>Imagenes asociadas</p>
          </div>
          <div className='twoColBody'>
            <h3>No tiene imagenes asociadas</h3>
            <div className="div-son">
              <img className='img-fluid imgURL' src={notFound} />
            </div>
          </div>


          <div className='footOneStep'>
            <a className='btnCancelOS' onClick={closeModal}>
              Cerrar
            </a>
          </div>
        </div>


      </>}
    </div>
  )



}

const mapStateToProps = (state) => ({
  id: state.ModalImage,
})

const mapDispatchToProps = (dispatch) => ({
  _getUrlImages: (id) => dispatch(getUrlImages(id)),

})

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ModalImage))








