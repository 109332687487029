import React from 'react'
import { Link } from "react-router-dom";

// components
// import Home from 'components/home'
import MetaTags from 'components/metatags'

const MenuPage = () => (
    <>
        <MetaTags title='Escaner' description='Escaner de páginas' />

        <ul>
            <li><Link to="/scan/asociar">Asociar página</Link></li>
            <li><Link to="/scan/datos">Ver datos</Link></li>
            <li><Link to="/scan/datos-web">Datos recientes</Link></li>
            <li><Link to="/scan/mensajes">Mensajes</Link></li>
        </ul>
    </>
)

export default MenuPage
