export function formatDashboardModel(campaignMySql, foundDynamo, budgets) {
  const publicName = budgets?.find(
    (budget) => budget.id === foundDynamo?.idBudget
  );
  let body = {
    editable: foundDynamo ? true : false,
    src: campaignMySql.calificacion,
    fechaIni: campaignMySql.fecha_inicio,
    ctr: campaignMySql.ctr?.toFixed(2),
    cpr: campaignMySql.cpr?.toFixed(2),
    budgetName: publicName ? publicName.title : "",
    status: campaignMySql.idStatus,
    scr: campaignMySql?.calificacion || "",
    total_gastado_con_comision: campaignMySql?.total_gastado_con_comision ?? 0,
    priority: campaignMySql?.priority || 3,
    formato: campaignMySql?.formato || "",
    video_playbacks_75: campaignMySql.reproducciones_video_75,
    cpv: campaignMySql.cpv?.toFixed(3),
    orden_venta: campaignMySql.orden_venta,
    id_sale: campaignMySql.orden_venta,
    id_region: foundDynamo?.id_region || 0,
    name_region: foundDynamo?.name_region || 0,
    objetivo: foundDynamo?.objetivo || "",
    segmentacion: foundDynamo?.publico || campaignMySql.segmentacion || "",
    dias_campaña: campaignMySql.dias > 0 ? campaignMySql.dias : 0,
    comentarios: campaignMySql.comentarios,
    calificacion: campaignMySql.calificacion,
    searchable_status: campaignMySql.status,
    idBudget: foundDynamo ? foundDynamo.idBudget : 0,
    searchable_fechafin: foundDynamo?.searchable_fechafin || "",
    adv: campaignMySql.duracion_promedio_video
      ? campaignMySql.duracion_promedio_video
      : "00:00:00",
    searchable_medio: foundDynamo?.searchable_medio || "",
    id_ad_product: foundDynamo?.id_ad_product || "",
    postNumber: foundDynamo?.postNumber || "",
    net_budget: campaignMySql.presupuesto_neto,
    cpma: campaignMySql.cpma,
    cpci: campaignMySql.cpci,
    conversaciones_iniciadas: campaignMySql.conversaciones_iniciadas,
    searchable_idMixDetaill: foundDynamo?.id || campaignMySql.id_mix_detail,
    searchable_name: foundDynamo?.name || campaignMySql.post,
    reach: campaignMySql.reach,
    account: foundDynamo?.account || "",
    event_responses: campaignMySql.respuesta_eventos,
    postShares: campaignMySql.post_shares,
    photo: campaignMySql.visualizacion_fotos,
    video_playbacks_50: campaignMySql.reproducciones_video_50,
    video_playbacks_100: campaignMySql.reproducciones_video_100,
    spent_percent:
      campaignMySql.porcentaje_gastado > 0
        ? campaignMySql.porcentaje_gastado?.toFixed(2)
        : "0.00",
    budget: Number(foundDynamo?.budget)?.toFixed(2) || 0,
    budgetNeto: +campaignMySql.presupuesto_neto,
    banner: foundDynamo?.banner || campaignMySql.banner,
    fechafin: campaignMySql.fecha_fin,
    id_medium: foundDynamo?.id_medium || 0,
    idMixDetaill: foundDynamo?.idMixDetaill || campaignMySql.id_mix_detail,
    searchable_fechaIni: foundDynamo?.searchable_fechaIni || "",
    idMix: foundDynamo?.idMix || 0,
    video_playbacks_95: campaignMySql.reproducciones_video_95,
    url: campaignMySql.url,
    name: campaignMySql.post,
    idCampaign: foundDynamo?.idCampaign || 0,
    searchable_version: foundDynamo?.searchable_version || "",
    clicks: campaignMySql.clicks,
    purchase_unit: campaignMySql.tipo_compra,
    idMixDetaillActual: !foundDynamo && campaignMySql.id_mix_detail,
    medioo: foundDynamo?.medioo || campaignMySql.medio,
    prints: campaignMySql.impresiones,
    id: foundDynamo?.id || campaignMySql.id_mix_detail,
    idMySql: campaignMySql.id,
    publico: foundDynamo?.publico || campaignMySql.segmentacion,
    swc: +campaignMySql.gastado_con_comision.toFixed(2),
    segmentation: campaignMySql.segmentacion,
    leads: campaignMySql.leads,
    version: foundDynamo?.version || "",
    available: campaignMySql.presupuesto_disponible,
    postComments: campaignMySql.post_comments,
    installs: campaignMySql.instalacion_aplicaciones,
    frequency: campaignMySql.frecuencia,
    modification_date: foundDynamo?.modification_date || "",
    views: campaignMySql.views,
    video_playbacks_25: campaignMySql.reproducciones_video_25,
    cpa: campaignMySql.cpa?.toFixed(3),
    cpc: campaignMySql.cpc?.toFixed(2),
    client: campaignMySql.cliente,
    post_likes: campaignMySql.post_likes,
    medio: foundDynamo?.medio || campaignMySql.medio,
    idMixChange: foundDynamo?.idMixChange || 0,
    cpf: campaignMySql.cpf?.toFixed(2),
    cpi: campaignMySql.cpi?.toFixed(2),
    fans: campaignMySql.fans,
    cpm: campaignMySql.cpm?.toFixed(2),
    cprm: campaignMySql.cost_per_estimated_ad_recallers,
    estimated_ad_recallers: campaignMySql.estimated_ad_recallers,
    cpl: campaignMySql.cpl?.toFixed(2),
    budgetConsumido: +campaignMySql.presupuesto_gastado?.toFixed(2),
    budgetI:
      +campaignMySql.presupuesto ?? +campaignMySql.presupuesto.toFixed(2),
    budgetSinConsumir: campaignMySql.presupuesto_disponible?.toFixed(2),
    availableAmount: foundDynamo?.availableAmount || 0,
    copy: foundDynamo?.copy || "",
    observation: foundDynamo?.observation || "",
    idAudience: foundDynamo?.idAudience || "",
    nameForEmail: foundDynamo?.nameForEmail || "",
    emailForEmail: foundDynamo?.emailForEmail || "",
    categoriesSelected: foundDynamo?.categoriesSelected || [],
    creativity: foundDynamo?.creativity || [],
    country: foundDynamo ? foundDynamo.name_region : campaignMySql.pais,
    meta: campaignMySql.goal,
    columnaCompare: campaignMySql.metric,
  };

  if (foundDynamo && foundDynamo.categoriesSelected) {
    // Obtener las categorías seleccionadas
    const categories = foundDynamo.categoriesSelected;

    const categoriesGruped = categories.reduce(
      (groups, item) => ({
        ...groups,
        [item.nombrePadre]: [...(groups[item.nombrePadre] || []), item],
      }),
      {}
    );

    let valores = Object.values(categoriesGruped);

    for (let j = 0; j < valores.length; j++) {
      let catsString = "";
      for (let k = 0; k < valores[j].length; k++) {
        catsString += valores[j][k].label;
        if (k < valores[j].length - 1) {
          catsString += ", ";
        }
      }
      catsString = catsString.trim().replace(/,\s*$/, "");
      const dataCategorie = { [valores[j][0].padre]: catsString };
      body = { ...body, ...dataCategorie };
    }
  }

  return body;
}
