import { v4 } from "uuid";

import * as API from "helpers/api";
import * as APILA from "helpers/apiLA";
import * as Logger from "utils/logger";
import * as Modal from "./modal.actions";
import { showLoader } from "./loader.actions";
import { upsertAccountTag } from "./accounts.actions";
import { newEmailValidation } from "./email.actions";
import _ from "lodash";
import { timeout } from "workbox-core/_private";

export const BUDGETS_CLEAR = "BUDGETS:CLEAR";
export const BUDGETS_ADD_ONE = "BUDGETS:ADD:ONE";
export const BUDGETS_ADD_TOTAL = "BUDGETS:ADD:TOTAL";
export const BUDGETS_SET_EDIT = "BUDGETS:SET:EDIT";
export const BUDGETS_CLEAR_EDIT = "BUDGETS:CLEAR:EDIT";
export const BUDGETS_SET_FILTER_TEXT = "BUDGETS:SET:FILTER:TEXT";
export const BUDGETS_SET_INFO = "BUDGETS:SET:INFO";
export const BUDGETS_SET_CLEAR_INFO = "BUDGETS:SET:CLEAR:INFO";

const TAG = "Budgets:Actions";

export const getBudgets = (account, user, idmod, mod) => async (dispatch) => {
  dispatch(showLoader(true));
  dispatch(clearBudgets());

  API.getBudgets(account)
    .then((response) => {
      insertLog(
        account,
        "getBudgets",
        { account },
        response.data,
        user,
        "Trae todos los presupuestos, /budget/myBudgets",
        idmod,
        mod
      );
      let { budgets } = response.data;
      dispatch(addTotalBudget(budgets));
    })
    .catch((err) => {
      Logger.log(TAG, "error getBudgets", err);
    })
    .then(() => {
      dispatch(showLoader(false));
    });
};

export const setFilterText = (text) => ({
  type: BUDGETS_SET_FILTER_TEXT,
  text,
});

export const clearBudgets = () => ({
  type: BUDGETS_CLEAR,
});

export const createBudget_logout =
  (id, title, account, amount, edit, createAt) => (dispatch) => {
    dispatch(showLoader(true));
    let now = Date.now();

    let body = {
      id,
      title,
      amount,
      account,
      status: 1,
      available: amount,
      createAt: edit ? createAt : now,
      updateAt: now,
      userEmail: "logout",
    };

    return API.createBudget(body);
  };

const updateIdsBudgetOdooOvs = async (idsBudgetUpd, lovsModal) => {
  try {
    idsBudgetUpd.forEach(async (budget) => {
      if (budget?.addIdBudget) {
        let foundBudget = lovsModal.find((item) => item.id === budget.id); // trae el idsBudget actualizado
        if (foundBudget) {
          let newArrIdsBudget = [...foundBudget.idsBudget];
          newArrIdsBudget.push(budget.currentBudget);
          newArrIdsBudget = [...new Set(newArrIdsBudget)];
          await APILA.updateIdsBudgets({
            idOv: budget.id,
            idsBudget: newArrIdsBudget,
          });
        }
      }
      if (!budget?.addIdBudget) {
        let foundBudget = lovsModal.find((item) => item.id === budget.id); // trae el idsBudget actualizado
        if (foundBudget) {
          let uniqueIds = [...foundBudget.idsBudget];
          uniqueIds = uniqueIds.filter((item) => item !== budget.currentBudget);
          uniqueIds = [...new Set(uniqueIds)];
          await APILA.updateIdsBudgets({
            idOv: budget.id,
            idsBudget: uniqueIds,
          });
        }
      }
    });
  } catch (error) {
    console.log("Error en la actualizacion de idsBudget", error);
  }
};

export const createBudgetOvs =
  (account, title, amount, lovsSelected, userEmail, dataLovs) => async (dispatch) => {
    try {
      let id = v4();
      const budgetsTransact = [];
      const idsBudgetUpd = [];

      dispatch(showLoader(true));
      lovsSelected.forEach((ele) => {
        idsBudgetUpd.push({
          id: ele.id,
          idsBudget: ele.idsBudget,
          addIdBudget: true,
          currentBudget: id,
        });
        budgetsTransact.push({
          id: ele.id,
          quantity: parseFloat(parseFloat(ele.reserve).toFixed(2)),
          sum: false,
          ov: ele.order_line_id_v6,
          user: userEmail,
          account,
        });
      });

      await APILA.ovTransactionovTransaction({
        ovs: budgetsTransact,
      });
      await updateIdsBudgetOdooOvs(idsBudgetUpd, dataLovs);

      const body = {
        id: id,
        account,
        title,
        active: true,
        amount,
        available: amount,
        status: 1,
        budgetOld: false,
        consumed: 0,
        createAt: Date.now(),
        userEmail,
        idLovs: lovsSelected?.map((item) => item.order_line_id_v6),
        reserveForLovs: lovsSelected?.map((item) => ({
          order_line_id_v6: item.order_line_id_v6,
          budgetAvailable: item.budgetAvailable,
          idsBudget: item.idsBudget,
          idsCampaign: item.idsCampaign,
          orderNumber: item.orderNumber,
          reserve: parseFloat(item.reserve),
          id: item.id,
        })),
      };
      await API.createBudget(body);

      dispatch(
        Modal.showAlertModal(
          "Presupuesto Creado",
          "Su presupuesto fue creado exitosamente.",
          Modal.MODAL_TYPE_SUCCESS,
          () => {
            dispatch(getBudgets(account));
          }
        )
      );
    } catch (error) {
      dispatch(
        Modal.showAlertModal(
          "Error!",
          "Ocurrió un error al solicitar el presupuesto",
          Modal.MODAL_TYPE_ERROR
        )
      );
      throw new Error("_createBudgetOvs");
    }
  };

export const createBudget =
  (title, account, amount, rawTags, padre, user, userEmail) =>
    async (dispatch) => {
      dispatch(showLoader(true));

      const parseTags = async (array) => {
        const result = [];

        if (array) {
          const tagsCreated = array.filter((tag) => !tag.__isNew__);
          const tagsToCreate = array.filter((tag) => tag.__isNew__);

          tagsCreated.forEach((tag) => {
            result.push(tag.value);
          });

          for (const tag of tagsToCreate) {
            const tagId = v4();

            await dispatch(upsertAccountTag(tagId, tag.label, account, false));

            result.push(tagId);
          }
        }

        return result;
      };

      const now = Date.now();

      const tags = await parseTags(rawTags);

      let body = {
        id: v4(),
        tags,
        title,
        amount,
        status: 1,
        account,
        available: amount,
        createAt: now,
        updateAt: now,
        padre,
        nombrePadre: "",
        userEmail,
      };

      try {
        const response = await API.createBudget(body);
        insertLog(
          account,
          "createBudget",
          { body },
          response.data,
          user,
          "Crea un presupuesto nuevo  /createBudget",
          3,
          "PRESUPUESTOS"
        );
        insertBitacora(body.id, account, "se ha creado un presupuesto", body);

        dispatch(
          newEmailValidation(account, "Presupuestos", body, user, [], "Creación")
        );

        dispatch(
          Modal.showAlertModal(
            "Presupuesto Creado",
            "Su presupuesto fue creado exitosamente.",
            Modal.MODAL_TYPE_SUCCESS,
            () => {
              dispatch(getBudgets(account));
            }
          )
        );
      } catch (err) {
        console.log("El error al crear presupuesto", err);
        Logger.log(TAG, "error createBudget", err);
        dispatch(
          Modal.showAlertModal(
            "Error!",
            "Ocurrió un error al solicitar el presupuesto",
            Modal.MODAL_TYPE_ERROR
          )
        );
      }

      dispatch(showLoader(false));
    };

export const modifyBudget =
  (id, title, amount, rawTags, padre, user, userEmail, account) =>
    async (dispatch) => {
      try {
        dispatch(showLoader(true));
        const findBudget = _.get(
          await APILA.findAmountById({ id }),
          "data.response",
          "{}"
        );

        let difference =
          parseFloat(parseFloat(amount).toFixed(2)) -
          parseFloat(parseFloat(findBudget.amount).toFixed(2));

        const body = {
          id,
          title,
          amount: Math.abs(difference),
          sum: difference >= 0,
          userEmail,
          idLovs: [],
          reserveForLovs: [],
          user: userEmail,
          account,
        };
        await APILA.updateBudgetLovs({
          ovs: [],
          budget: body,
        });
        dispatch(
          Modal.showAlertModal(
            "Presupuesto editado",
            "Su presupuesto fue editado exitosamente.",
            Modal.MODAL_TYPE_SUCCESS,
            () => {
              dispatch(getBudgets(account));
            }
          )
        );
      } catch (error) {
        dispatch(
          Modal.showAlertModal(
            "Error!",
            "Ocurrió un error al solicitar el presupuesto",
            Modal.MODAL_TYPE_ERROR
          )
        );
        throw new Error("Error", error);
      } finally {
        dispatch(showLoader(false));
      }
    };

const insertBitacora = (idBudget, account, action, data) => {
  const bodyBitacora = {
    id: v4(),
    date: new Date(),
    account,
    action,
    component: "budgets",
    data: data,
    idBudget,
  };
  APILA.insertBitacora(bodyBitacora);
};

export const updateBudget =
  ({
    id,
    title,
    account,
    amount,
    status,
    createAt,
    availableAmount,
    user,
    accounts,
    userEmail,
    budgetOld,
    reserveForLovs,
    prevBudget,
    lovsSelected,
    lovsModal
  }) =>
    async (dispatch) => {
      try {
        const budgetsTransact = [];
        const idsBudgetUpd = [];

        const findBudget = _.get(
          await APILA.findAmountById({ id }),
          "data.response",
          "{}"
        );
        const prevReserve = findBudget?.reserveForLovs;
        const returnAvailable = _.differenceBy(prevReserve, lovsSelected, "id"); //Estas regresaran el disponible que tenian

        lovsSelected.forEach((ele) => {
          const foundEdit = prevReserve?.find((elem) => ele.id === elem.id);

          if (foundEdit) {
            // * Mantiene la misma ov pero pudo cambiar la cantidad
            const difference =
              parseFloat(parseFloat(ele.reserve).toFixed(2)) -
              parseFloat(parseFloat(foundEdit.reserve).toFixed(2));

            if (difference >= 0) {
              idsBudgetUpd.push({
                id: ele.id,
                idsBudget: ele.idsBudget,
                addIdBudget: true,
                currentBudget: id,
              });

              budgetsTransact.push({
                id: ele.id,
                quantity: difference,
                sum: false,
                idOv: ele.order_line_id_v6,
                user: userEmail,
                account,
              });
            } else {
              idsBudgetUpd.push({
                id: ele.id,
                idsBudget: ele.idsBudget,
                addIdBudget: true,
                currentBudget: id,
              });

              budgetsTransact.push({
                id: ele.id,
                quantity: Math.abs(difference),
                sum: true,
                user: userEmail,
                idOv: ele.order_line_id_v6,
                account,
              });
            }
          } else {
            idsBudgetUpd.push({
              id: ele.id,
              idsBudget: ele.idsBudget,
              addIdBudget: true,
              currentBudget: id,
            });

            // * es una nueva ov
            budgetsTransact.push({
              id: ele.id,
              quantity: parseFloat(ele.reserve),
              sum: false,
              user: userEmail,
              idOv: ele.order_line_id_v6,
              account,
            });
          }
        });

        returnAvailable.forEach((ele) => {
          idsBudgetUpd.push({
            id: ele.id,
            idsBudget: ele.idsBudget,
            addIdBudget: false,
            currentBudget: id,
          });

          budgetsTransact.push({
            id: ele.id,
            quantity: parseFloat(ele.reserve),
            sum: true,
            user: userEmail,
            idOv: ele.order_line_id_v6,
            account,
          });
        });

        let difference =
          parseFloat(parseFloat(amount).toFixed(2)) -
          parseFloat(parseFloat(findBudget.amount).toFixed(2));

        const body = {
          id,
          title,
          userEmail,
          amount: Math.abs(difference),
          sum: difference >= 0,
          reserveForLovs: lovsSelected?.map((item) => ({
            order_line_id_v6: item.order_line_id_v6,
            budgetAvailable: item.budgetAvailable,
            idsBudget: item.idsBudget,
            idsCampaign: item.idsCampaign,
            orderNumber: item.orderNumber,
            reserve: parseFloat(item.reserve),
            id: item.id,
          })),
          user: userEmail,
          idLovs: lovsSelected?.map((item) => item.order_line_id_v6),
          account,
        };
        const response = await APILA.updateBudgetLovs({
          ovs: budgetsTransact,
          budget: body,
        });

        await updateIdsBudgetOdooOvs(idsBudgetUpd, lovsModal);

        await insertLog(
          account,
          "createBudget",
          { body },
          response.data,
          user,
          "Actualiza un presupuesto , /createBudget",
          3,
          "PRESUPUESTOS"
        );

        let bodyBitacora = {
          id: v4(),
          date: new Date(),
          account: account,
          action: "se ha editado un presupuesto",
          component: "budgets",
          data: body,
          idBudget: id,
        };

        await APILA.insertBitacora(bodyBitacora);
        dispatch(
          newEmailValidation(
            account,
            "Presupuestos",
            body,
            user,
            accounts,
            "Edición"
          )
        );
        dispatch(
          Modal.showAlertModal(
            "Presupuesto editado",
            "Su presupuesto fue editado exitosamente.",
            Modal.MODAL_TYPE_SUCCESS,
            () => {
              dispatch(getBudgets(account));
            }
          )
        );
      } catch (err) {
        dispatch(
          Modal.showAlertModal(
            "Error!",
            "Ocurrió un error al solicitar el presupuesto",
            Modal.MODAL_TYPE_ERROR
          )
        );
        throw new Error("Error");
      }
    };

async function insertLog(
  account,
  action,
  request,
  response,
  user,
  obs,
  idmod,
  mod
) {
  let idlog = v4();
  let now = Date.now();
  let logRequest = {
    id: idlog,
    source: "Lets-Advertise",
    timestamp: now,
    action: action,
    user: user,
    role: account,
    idmodulo: idmod,
    modulo: mod,
    request: request,
    response: response,
    observation: obs,
  };
  const responseLog = await APILA.insertLogLine(logRequest);
}

export const createNewBudgetLogOut = (budget) => async (dispatch) => {
  try {
    dispatch(showLoader(true))
    let id = v4();
    let body = {
      ...budget,
      id,
      amount: parseFloat(budget.amount),
      status: 1,
      active: true,
      budgetOld: true,
      available: parseFloat(budget.amount),
    }
    await API.createNewBudgetLogOut(body)
  } catch (error) {
    console.log("Error al crear presupuesto", error)
  } finally {
    dispatch(showLoader(false))
  }
}

export const updateBudgetLogOut = (budget, prevBudget) => async (dispatch) => {
  try {
    dispatch(showLoader(true))
    const body = {
      id: budget.id,
      title: budget.name,
      tags: budget.tags || [],
      amountParams: Math.abs(parseFloat(budget.amount) - parseFloat(prevBudget.amount)),
      sum: parseFloat(parseFloat(budget.amount).toFixed(2)) > parseFloat(parseFloat(prevBudget.amount).toFixed(2)) ? true : false,
    }
    await APILA.updateBudgetLogOut({ budget: body })
  } catch (error) {
    console.log('Error al actualizar presupuesto', error)
  } finally {
    dispatch(showLoader(false))
  }
}

export const setEdit = (edit) => ({
  type: BUDGETS_SET_EDIT,
  edit,
});

export const clearEdit = () => ({
  type: BUDGETS_CLEAR_EDIT,
});

const addBudget = (budget) => ({
  type: BUDGETS_ADD_ONE,
  budget,
});

const addTotalBudget = (budgets) => ({
  type: BUDGETS_ADD_TOTAL,
  budgets,
});

export const setInfoBudget = (budgetInfo) => ({
  type: BUDGETS_SET_INFO,
  budgetInfo
})
