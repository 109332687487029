import React, { useState, useRef } from "react";
import { Auth } from "aws-amplify";
import PageLoader from "components/page_loader";
import Swal from "sweetalert2";
import * as APILA from "helpers/apiLA";
import { FormGroup, Label, Input, FormFeedback, InputGroup } from "reactstrap";
import _, { isEmpty, find } from "lodash";
const ModalUsers = ({
  setShowModalUsers,
  setRefresh,
  usuariosAws,
  setLoading,
  accounts,
  users,
  getClientsOrder,
  isAdmin,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [nameValue, setNameValue] = useState("");
  const [emailValue, setEmailValue] = useState("");
  const [passwordValue, setPasswordValue] = useState("");
  const [errorsEmail, setErrorsEmail] = useState(false);
  const [errorsName, setErrorsName] = useState(false);
  const [errorsPassword, setErrorsPassword] = useState(false);
  const [mensaje, setMensaje] = useState("");
  const [val, setVal] = useState(false);

  const signUp = async (e) => {
    e.preventDefault();
    setLoading(true);
    setIsLoading(true);
    let verificar =
      /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/g;

    let error = "";
    let mensaje = "";

    if (!errorsPassword && !errorsEmail && !errorsName && val) {
      try {
        let user = await Auth.signUp({
          username: emailValue,
          password: passwordValue,
          attributes: {
            name: nameValue,
            email: emailValue,
          },
        });
        console.log("user", user);
        await setRefresh();
        setShowModalUsers(false);
      } catch (err) {
        let { message } = err;
        error = message;
        mensaje = "Algo salió mal, por favor inténtalo de nuevo.";
        console.log("error signing up", err);
      }
    } else {
      if (!val) {
        setErrorsEmail(true);
        setMensaje("Ingrese un correo válido");
        setErrorsName(true);
        setErrorsPassword(true);
      }
    }
    setIsLoading(false);
    setLoading(false);

    if (error !== "") {
      Swal.fire({
        title: "Ups!",
        text: mensaje,
        icon: "error",
        showCancelButton: false,
        confirmButtonColor: "#3085d6",
        confirmButtonText: "Ok",
      });
    }
  };

  const email = (e) => {
    setVal(true);
    let value = e.target.value;
    let verificar =
      /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/g;
    let emailValidation = verificar.test(value);
    let emailFilter = usuariosAws.filter((user) => user.email == value);

    if (!emailValidation) {
      setErrorsEmail(true);
      setMensaje("Ingrese un correo válido");
    } else if (emailFilter.length > 0) {
      setMensaje("El correo ya existe");
      setErrorsEmail(true);
    } else {
      setErrorsEmail(false);
    }
    setEmailValue(value);
  };
  const name = (e) => {
    setVal(true);
    let value = e.target.value;
    if (value != "") setErrorsName(false);
    if (value == "") setErrorsName(true);
    setNameValue(value);
  };
  const pass = (e) => {
    setVal(true);
    let value = e.target.value;
    if (value.length >= 8) {
      setErrorsPassword(false);
    } else {
      setErrorsPassword(true);
    }
    setPasswordValue(value);
  };

  const handleAddUser = async () => {
    console.log("Agregar usuario...", emailValue, errorsEmail);
    if (emailValue !== "") {
      const responseClients = await APILA.getAllAccountsAWS();
      const { clients } = responseClients.data;

      const clientsLets = clients.map((client_response) => {
        const nameAttr = find(client_response.Attributes, { Name: "name" });
        const emailAttr = find(client_response.Attributes, { Name: "email" });

        const nameValue = nameAttr ? nameAttr.Value : "";
        const emailValue = emailAttr ? emailAttr.Value : "";
        const format = nameValue ? `${nameValue} (${emailValue})` : emailValue;

        return {
          id: client_response.Username,
          name: nameValue,
          email: emailValue,
          format: format,
        };
      });
      const foundUser = clientsLets.find((ele) => ele.email === emailValue);
      let arrayUser = [...users, foundUser];
      let uniqueArrayUser = _.uniqBy(arrayUser, "email");

      if (foundUser) {
        Swal.fire({
          title: "Agregado!",
          text: "Usuario agregado correctamente!",
          icon: "success",
          confirmButtonColor: "#3085d6",
          confirmButtonText: "Ok",
        });
        setShowModalUsers(false);
        getClientsOrder(accounts, uniqueArrayUser);
      } else {
        Swal.fire({
          title: "Ups!",
          text: "Usuario no encontrado!",
          icon: "info",
          confirmButtonColor: "#3085d6",
          confirmButtonText: "Ok",
        });
      }
    } else {
      setErrorsEmail(true);
      setMensaje("Ingrese un correo válido");
    }
  };

  return (
    <>
      {isLoading ? (
        <PageLoader />
      ) : (
        <div className="blur-div ">
          <div className="content-modal contentModalRolUser">
            <div className="closeModal-content">
              <i
                className="fas fa-times-circle close"
                onClick={(e) => setShowModalUsers(false)}
              ></i>
            </div>
            <div>
              {isAdmin ? (
                <h3 className="titleModal titleModalRol"> Invitar usuario</h3>
              ) : (
                <h3 className="titleModal titleModalRol">
                  {" "}
                  Creación de Usuario
                </h3>
              )}
            </div>
            <div className="body-modal modalUsers">
              <FormGroup>
                <Label
                  style={{
                    color: "#05252d",
                    fontWeight: 500,
                    marginBottom: "5px",
                  }}
                >
                  Correo
                </Label>
                <Input
                  type="email"
                  value={emailValue}
                  name="email"
                  onChange={(e) => email(e)}
                  placeholder="Ingresa tu correo electrónico"
                  invalid={errorsEmail}
                />
                <FormFeedback>{mensaje}</FormFeedback>
              </FormGroup>

              {!isAdmin && (
                <>
                  <FormGroup>
                    <Label
                      style={{
                        color: "#05252d",
                        fontWeight: 500,
                        marginBottom: "5px",
                      }}
                    >
                      Nombre Completo
                    </Label>
                    <Input
                      type="text"
                      value={nameValue}
                      onChange={(e) => name(e)}
                      invalid={errorsName}
                      placeholder="Ingresa tu nombre completo"
                    />
                    <FormFeedback>Ingrese el nombre completo</FormFeedback>
                  </FormGroup>

                  <FormGroup>
                    <Label
                      style={{
                        color: "#05252d",
                        fontWeight: 500,
                        marginBottom: "5px",
                      }}
                    >
                      Contraseña
                    </Label>
                    <Input
                      type="password"
                      value={passwordValue}
                      onChange={(e) => pass(e)}
                      invalid={errorsPassword}
                      placeholder="Ingresa tu contrase&ntilde;a"
                    />
                    <FormFeedback>
                      La contraseña tiene que tener mínimo 8 caracteres
                    </FormFeedback>
                  </FormGroup>
                </>
              )}
            </div>

            <div className="footer-Modal">
              <div className="buttons-Modal-content">
                <button
                  className="btnModal btnCancelarModalRol"
                  onClick={(e) => setShowModalUsers(false)}
                >
                  Cancelar
                </button>
                <button
                  className="btnModal btnGuardarModalRol"
                  onClick={isAdmin ? handleAddUser : signUp}
                >
                  Guardar
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ModalUsers;
