import React from "react";

// components
import Content from "components/budgetOld";
import MetaTags from "components/metatags";

const BudgetListPage = () => (
  <>
    <MetaTags
      title="Let's Advertise Ads - Presupuestos"
      description="Registra y gestiona todos tus presupuestos publicitarios en Let's Advertise Ads. Conecta tu sistema ERP para obtener un reporte contable automatizado."
    />
    {/* <MetaTags title="Presupuestos" description="Página de presupuestos" /> */}

    <div className="content-budgets">
      <Content />
    </div>
  </>
);

export default BudgetListPage;
