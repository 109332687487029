import React, { useEffect, useState } from "react";
import _ from "lodash";
import { connect } from "react-redux";
import { MDBContainer, MDBIframe } from "mdbreact";

import DataTableUtils from "@easygosanet/react-datatable-utils";

import { getReports } from "redux/actions/report.actions";
import analytics from "../../helpers/analytics";
import "./style.scss";
import { height } from "@mui/system";
import * as API from "helpers/apiLA";
import { Auth } from "aws-amplify";
import PageLoader from "components/page_loader";
const Content = (props) => {
  const [link, setLink] = useState("");
  const [account, setAccount] = useState(props.currentAccount);
  const [reportValue, setReportValue] = useState("");
  const [permissions, setPermissions] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    setAccount(props.currentAccount);
    setLink("");
    setReportValue("");
    permiso();
  }, [props.currentAccount]);

  const permiso = async () => {
    try {
      setIsLoading(true);
      let userLog = await Auth.currentAuthenticatedUser();
      let permisoAcc = await API.getPermitsByAccountAndUser({
        account: props.currentAccount,
        user: userLog.username,
      });
      let idRol = permisoAcc.data.permisos[0].idRol;
      let permisosRes = await API.getRoleNameById({ id: idRol });

      let permissAud = permisosRes.data.rol[0].permissions;
      let permissFn = permissAud.filter((ele) => ele.name == "Públicos");

      setPermissions((state) => {
        return {
          approve: (state.approve = permissFn[0].approve),
          create: (state.create = permissFn[0].create),
          delete: (state.delete = permissFn[0].delete),
          edit: (state.edit = permissFn[0].edit),
          export: (state.export = permissFn[0].export),
          manageRoles: (state.manageRoles = permissFn[0].manageRoles),
          view: (state.view = permissFn[0].view),
        };
      });
      setIsLoading(false);
    } catch (err) {
      setPermissions((state) => {
        return {
          approve: true,
          create: true,
          delete: true,
          edit: true,
          export: true,
          manageRoles: true,
          view: true,
        };
      });
      setIsLoading(false);
      console.log("err permisos", err);
    }
  };

  useEffect(() => {
    if (props.currentUser !== "") {
      account !== "" && props._getReports(account);
    }
  }, [account, props.currentUser]);

  const handleFilterTextChange = (value) => {};

  const handleViewReport = (e) => {
    const value = e.target.value;
    console.log("value", value);
    let reportFound = props.reports.filter((el) => el.link === value);
    console.log("Reporte", reportFound[0].name);
    setReportValue(value);
    setLink(value);
    analytics.seleccionReporte({
      ubicacion: "Reportes",
      nombre: reportFound[0].name,
    });
  };
  /* ESTILOS IFREM */
  const styleContIfrem1 = {
    marginTop: "30px",
    width: "100%",
  };
  const styleIfrem1 = {
    width: "100%",
    height: "65vh",
    border: "none",
    overflowY: "auto",
    overflowX: "auto",
  };
  const styleContIfrem2 = {
    marginTop: "30px",
    width: "95%",
    overflowX: "auto",
  };
  const styleIfrem2 = {
    width: "190%",
    height: "55vh",
    border: "none",
    overflowY: "auto",
    overflowX: "scroll",
    zoom: "115%",
  };

  const [matches, setMatches] = useState(
    window.matchMedia("(min-width: 900px)").matches
  );

  useEffect(() => {
    window
      .matchMedia("(min-width: 900px)")
      .addEventListener("change", (e) => setMatches(e.matches));
  }, []);

  return (
    <>
      {isLoading ? (
        <PageLoader />
      ) : (
        <>
          {permissions.view ? (
            <>
              <div className="barraSuperior barraSuperiorReport">
                <select
                  defaultValue=""
                  onChange={handleViewReport}
                  value={reportValue}
                  className="select-workspace "
                >
                  <option disabled hidden value="">
                    Elige un reporte
                  </option>
                  {props.reports.map((report, idx) => (
                    <option key={idx} value={report.link}>
                      {report.name}
                    </option>
                  ))}
                </select>

                <DataTableUtils onChangeFilterText={handleFilterTextChange} />
              </div>

              {matches ? (
                <div className="" style={styleContIfrem1}>
                  <iframe className="" src={link} style={styleIfrem1} />
                </div>
              ) : (
                <div className="" style={styleContIfrem2}>
                  <iframe className="" src={link} style={styleIfrem2} />
                </div>
              )}

              {/* <div className='Kanbang-content'>
                <div className='List-category'>
                  <MDBContainer className='text-center ifremCont'>
                    <MDBIframe src={link} />
                  </MDBContainer>
                </div>
              </div> */}
            </>
          ) : (
            <h1>No tienes permisos</h1>
          )}
        </>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  reports: state.report.list,
  currentAccount: state.accounts.default,
});

const mapDispatchToProps = (dispatch) => ({
  _getReports: (account) => dispatch(getReports(account)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Content);
