/* import { currencyFormatGTM } from './formatter';
import TagManager from 'react-gtm-module';
import jsSHA from 'jssha';
import ReactGA from 'react-ga';
import productHelpers from './product';
import ReactPixel from 'react-facebook-pixel'; */
import ReactPixel from 'react-facebook-pixel';
let sendDataLayer = true;
let dataLog = true;
const now = new Date();
/* if (process.env.PROD) { dataLog = false } */
/* const userId = () => {
  const id = sessionStorage.getItem('userId');
  if (id) {
    ReactGA.set({ userId: id });
  }
  return id;
}; */
const analytics = {
  //Click_Navbar
  Click_Navbar: (nombre) => {
    if (sendDataLayer) {
      const dataLayerInfo = {
        'event': 'Click_Navbar',
        'seleccion': nombre,
        'accion': 'seleccion',
        'Categoria': 'NavBar',
      }
      window.dataLayer.push(dataLayerInfo);
      ReactPixel.track('Click_Navbar', dataLayerInfo); 
    }
  },
  //Click_Navbar - Acciones
  Click_Navbar_acciones: (accion) => {
    if (sendDataLayer) {
      const dataLayerInfo = {
        'event': 'accion_navbar',
        'accion': accion,
        'Categoria': 'NavBar',
      }
      window.dataLayer.push(dataLayerInfo);
      ReactPixel.track('accion_navbar', dataLayerInfo); 
    }
  },
  //Click_Navbar - Logo
  Click_Navbar_logo: (nombre) => {
    if (sendDataLayer) {
      const dataLayerInfo = {
        'event': 'click_logo',
        'seleccion': nombre,
        'Categoria': 'NavBar',
      }
      window.dataLayer.push(dataLayerInfo);
      ReactPixel.track('click_logo', dataLayerInfo); 
    }
  },
  //Click_Navbar - Filtro selectBox 
  Click_Navbar_filtro: (nombre) => {
    if (sendDataLayer) {
      const dataLayerInfo = {
        'event': 'filtro_navbar',
        'accion': 'filtro clientes',
        'seleccion': nombre,
        'Categoria': 'NavBar',
      }
      window.dataLayer.push(dataLayerInfo);
       ReactPixel.track('filtro_navbar', dataLayerInfo); 
    }
  },
  //opciones de vista
  opciones_vista: (data) => {
    if (sendDataLayer) {
      const dataLayerInfo = {
        'event': 'opcion_vista',
        'vista_seleccionada': data.busqueda,
      }
      window.dataLayer.push(dataLayerInfo);
      ReactPixel.track('opcion_vista', dataLayerInfo); 
    }
  },
  //busqueda
  busqueda: (data) => {
    if (sendDataLayer) {
      const dataLayerInfo = {
        'event': 'busqueda_sitio',
        'categoria': data.ubicacion,
        'parametro_busqueda': data.busqueda,
        'accion':'busqueda',
      }
      window.dataLayer.push(dataLayerInfo);
      ReactPixel.track('busqueda_sitio', dataLayerInfo); 
    }
  },
  agrupar: (data) => {
    if (sendDataLayer) {
      const dataLayerInfo = {
        'event': 'click_agrupar',
        'categoria': data.ubicacion,
        'accion': 'click',
      }
      window.dataLayer.push(dataLayerInfo);
      ReactPixel.track('agrupar', dataLayerInfo); 
    }
  },
  //filtro
  filtro: (data) => {
    if (sendDataLayer) {
      const dataLayerInfo = {
        'event': 'filtro',
        'categoria': data.ubicacion,
        'accion': 'filtro'
      }
      window.dataLayer.push(dataLayerInfo);
      ReactPixel.track('filtro', dataLayerInfo);
    }
  },
  //Compartir
  Compartir: (data) => {
    if (sendDataLayer) {
      const dataLayerInfo = {
        'event': 'compartir',
        'accion': 'click',
        'categoria': data.ubicacion,
      }
      window.dataLayer.push(dataLayerInfo);
      ReactPixel.track('compartir', dataLayerInfo); 
    }
  },
  //Columnas
  Columnas: (data) => {
    if (sendDataLayer) {
      const dataLayerInfo = {
        'event': 'columnas',
        'accion': 'click',
        'categoria': data.ubicacion,
      }
      window.dataLayer.push(dataLayerInfo);
      ReactPixel.track('columnas', dataLayerInfo); 
    }
  },
  //Fechas
  Fechas: (data) => {
    if (sendDataLayer) {
      const dataLayerInfo = {
        'event': 'fechas',
        'accion': 'click',
        'categoria': data.ubicacion,
      }
      window.dataLayer.push(dataLayerInfo);
      ReactPixel.track('fechas', dataLayerInfo); 
    }
  },
  //ShowHide
  ShowHide: (data) => {
    if (sendDataLayer) {
      const dataLayerInfo = {
        'event': 'ShowHide',
        'categoria': 'click',
        'ubicacion': data.ubicacion,
      }
      window.dataLayer.push(dataLayerInfo);
      ReactPixel.track('ShowHide', dataLayerInfo);
    }
  },    
  //Add
  Add: (data) => {
    if (sendDataLayer) {
      const dataLayerInfo = {
        'event': 'Add'+data.ubicacion,
        'accion': 'click',
        'categoria': data.ubicacion,
      }
      window.dataLayer.push(dataLayerInfo);
      ReactPixel.track('Add'+data.ubicacion, dataLayerInfo); 
    }
  },    
  //Add
  Acciones: (data) => {
    if (sendDataLayer) {
      const dataLayerInfo = {
        'event': 'Acciones',
        'accion': 'click',
        'tipo_accion': data.accion,
        'categoria': data.ubicacion,
      }
      window.dataLayer.push(dataLayerInfo);
      ReactPixel.track('acciones', dataLayerInfo);
    }
  },   
  //Add
  modalesAcciones: (data) => {
    if (sendDataLayer) {
      const dataLayerInfo = {
        'event': 'modalesAcciones',
        'accion': 'click',
        'tipo_accion': data.accion,
        'categoria': data.ubicacion,
      }
      window.dataLayer.push(dataLayerInfo);
      ReactPixel.track('modalesAcciones', dataLayerInfo);
    }
  }, 
  //accion tabla
  accionesTabla: (data) => {
    if (sendDataLayer) {
      const dataLayerInfo = {
        'event': 'accionesTabla',
        'acion': 'click',
        'tipo_accion': data.accion,
        'categoria': data.ubicacion,
      }
      window.dataLayer.push(dataLayerInfo);
      ReactPixel.track('accionesTabla', dataLayerInfo); 
    }
  }, 
  //cabecera tabla
  filtroCabeceraTabla: (data) => {
    if (sendDataLayer) {
      const dataLayerInfo = {
        'event': 'filtroCabeceraTabla',
        'accion': 'click',
        'tipo_accion': 'click_opciones_tabla',
        'categoria': data.ubicacion,
      }
      window.dataLayer.push(dataLayerInfo);
      ReactPixel.track('filtroCabeceraTabla', dataLayerInfo); 
    }
  }, 
  //eliminar registro
  eliminarFila: (data) => {
    if (sendDataLayer) {
      const dataLayerInfo = {
        'event': 'eliminacion_fila',
        'accion': 'click',
        'tipo_accion': 'eliminar fila',
        'categoria': data.ubicacion,
      }
      window.dataLayer.push(dataLayerInfo);
       ReactPixel.track('eliminacion_fila', dataLayerInfo); 
    }
  }, 
  //editar registro
  editarFila: (data) => {
    if (sendDataLayer) {
      const dataLayerInfo = {
        'event': 'editar_fila',
        'accion': 'click',
        'tipo_accion': 'editar fila',
        'categoria': data.ubicacion,
      }
      window.dataLayer.push(dataLayerInfo);
      ReactPixel.track('editar_fila', dataLayerInfo); 
    }
  }, 
  //seleccion reporte
  seleccionReporte: (data) => {
    if (sendDataLayer) {
      const dataLayerInfo = {
        'event': 'reporteSeleccionado',
        'click': 'click',
        'categoria': data.ubicacion,
        'seleccion': data.nombre
      }
      window.dataLayer.push(dataLayerInfo);
      ReactPixel.track('reporteSeleccionado', dataLayerInfo); 
    }
  }, 
  //modal ayuda - videotutoriales
  modalAyudaVideotutoriales: (data) => {
    if (sendDataLayer) {
      const dataLayerInfo = {
        'event': 'modalAyudaVideotutoriales',
        'accion': 'click',
        'tipo_accion':'Video Tutoriales',
        'categoria': data.ubicacion,
      }
      window.dataLayer.push(dataLayerInfo);
      ReactPixel.track('reporteSeleccionado', dataLayerInfo); 
    }
  },
  //modal ayuda - Ticket
  modalAyudaTicket: (data) => {
    if (sendDataLayer) {
      const dataLayerInfo = {
        'event': 'modalAyudaTicket',
        'accion': 'click',
        'tipo_accion': 'Ayuda Ticket',
        'categoria': data.ubicacion,
      }
      window.dataLayer.push(dataLayerInfo);
      ReactPixel.track('reporteSeleccionado', dataLayerInfo); 
    }
  },
  //usuario - cambiar contraseña
  cambiarPass: (data) => {
    if (sendDataLayer) {
      const dataLayerInfo = {
        'event': 'cambiarPass',
        'accion': 'click_reset_pass',
        'tipo_accion': 'restablecer password',
        'categoria': data.ubicacion,
      }
      window.dataLayer.push(dataLayerInfo);
      ReactPixel.track('reporteSeleccionado', dataLayerInfo); 
    }
  },
   //usuario - cerrar sesion
   logout: (data) => {
    if (sendDataLayer) {
      const dataLayerInfo = {
        'event': 'logout',
        'accion': 'click_logout',
        'tipo_accion': 'logout',
        'categoria': data.ubicacion,
      }
      window.dataLayer.push(dataLayerInfo);
      ReactPixel.track('reporteSeleccionado', dataLayerInfo); 
    }
  },
   guardar: (data) => {
    if (sendDataLayer) {
      const dataLayerInfo = {
        'event': 'guardar',
        'accion': 'guardar',
        'tipo_accion': 'guardar',
        'categoria': data.ubicacion,
      }
      window.dataLayer.push(dataLayerInfo);
      ReactPixel.track('reporteSeleccionado', dataLayerInfo); 
    }
  },
  userData: (userId) => {
    if (sendDataLayer) {
      const dataLayerInfo = {
        'event': 'infoUser',
        'categoria': 'info_user',
        'userId': userId,
      }
      window.dataLayer.push(dataLayerInfo);
      ReactPixel.track('reporteSeleccionado', dataLayerInfo); 
    }
  },
  loginGoogle: (email)=>{
    if (sendDataLayer) {
      const dataLayerInfo = {
        'event': 'loginGoogle',
        'categoria': 'login_google',
        'email': email,
      }
      window.dataLayer.push(dataLayerInfo);
      ReactPixel.track('reporteSeleccionado', dataLayerInfo); 
    }
  },
  loginFacebook: (email)=>{
    if (sendDataLayer) {
      const dataLayerInfo = {
        'event': 'loginFacebook',
        'categoria': 'login_facebook',
        'email': email,
      }
      window.dataLayer.push(dataLayerInfo);
      ReactPixel.track('reporteSeleccionado', dataLayerInfo); 
    }
  }
  ,
  registerCognito: (email)=>{
    if (sendDataLayer) {
      const dataLayerInfo = {
        'event': 'signupCognito',
        'categoria': 'signup_cognito',
        'email': email,
      }
      window.dataLayer.push(dataLayerInfo);
      ReactPixel.track('reporteSeleccionado', dataLayerInfo); 
    }
  },
  updateUserPassword: (userData)=>{
    if (sendDataLayer) {
      const dataLayerInfo = {
        'event': 'changePassword',
        'categoria': 'change_password',
        'email': userData,
      }
      window.dataLayer.push(dataLayerInfo);
      ReactPixel.track('reporteSeleccionado', dataLayerInfo); 
    }
  },
  //acciones de la vista 
  accionesVista: (data) => {
    if (sendDataLayer) {
      const dataLayerInfo = {
        'event': 'vista',
        'categoria': data.ubicacion,
        'tipo_accion': data.accion,
      }
      window.dataLayer.push(dataLayerInfo);
      ReactPixel.track('opcion_vista', dataLayerInfo); 
    }
  },
  //cambio en configuracion 
  opciones_config: (data) => { 
    if (sendDataLayer) {
      const dataLayerInfo = {
        'event': 'opciones_config',
        'vista_seleccionada': data.ubicacion,
      }
      window.dataLayer.push(dataLayerInfo);
      ReactPixel.track('opciones_config', dataLayerInfo); 
    }
  }
};
export default analytics;