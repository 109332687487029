import { v4 } from 'uuid'
import * as Logger from 'utils/logger'
import * as APILA from 'helpers/apiLA'
import { showLoader } from './loader.actions'
export const ADD_All_CAMPAING = 'All_CAMPAING:ADD'
export const CLEAN_All_CAMPAING = 'All_CAMPAING:CLEAN'
export const ADD_EXPORTED_DATA = 'EXPORTED_DATA:ADD'
export const CLEAN_EXPORTED_DATA = 'EXPORTED_DATA:CLEAN'
const TAG = 'All_CAMPAING:Actions'



export const cleanAllCamp = () => (dispatch) => {
  dispatch({
    type: CLEAN_All_CAMPAING,
  })
}
export const cleanExpData = () => (dispatch) => {
  dispatch({
    type: CLEAN_EXPORTED_DATA,
  })
}

export const getAllCampaigns = (account,user,idmod,mod) => async dispatch => {
  dispatch(showLoader(true))
  dispatch(cleanAllCamp());
  dispatch(cleanExpData());
  try {
    const response = await APILA.getAllCampaigns({ account})

    // insertLog(account,"getAllCampaigns",{ account },response.data,user,"Trae todas las campañas sin el filtro de cuenta",idmod,mod)

    Logger.log(TAG, 'getALLCampaings', response)
    // const { campaigns, count } = response.data.campaigns    
    Logger.log(TAG, 'getALLCampaings campaigns', response.data.campaigns )
    let exportData=response.data.campaigns
    if (response.data.campaigns) {
      response.data.campaigns.filter((campaign) => {
        /*CAMPAIGNS_ADD_ONE */
        dispatch(addCampaign(campaign))
    
      })
      exportData.forEach((element, position) => {
        let body={
          // Client:element.client?element.cliente.toLowerCase().charAt(0).toUpperCase():"",
          Cliente:element.client?element.client:"",
          "Fecha de inicio":element.fechaIni?getDataFormat(element.fechaIni):"",
          "Fecha de fin":element.fechafin?getDataFormat(element.fechafin):"",
          "ID":element.idMixDetaill,
           OV:element.id_sale?element.id_sale:"",
           Estado:element.searchable_status?capitalizeFirstLetter(element.searchable_status):"",
           Pais:element.country?element.country:"",
           Medio:element.medio?element.medio:"",
           Banner:element.banner?element.banner:"",
           "Descripción del post":element.postNumber?element.postNumber:"",
           "Tipo de compra":element.purchase_unit?element.purchase_unit:"",
           "Presupuesto invertido":element.budget?element.budget:"",
           "Presupuesto neto":element.net_budget?element.net_budget:"",
           "Presupuesto consumido":"",
           "Presupuesto consumido con comisión":element.swc?element.swc:"",
           "Presupuesto consumido con comisión":element.swc?element.swc:"",
           "Presupuesto sin consumir":element.available?element.available:"",
           "Porcentaje de presupuesto consumido":element.spent_percent?element.spent_percent:"",
           "Dias de campañas":element.spent_percent?element.spent_percent:"",
           "Publicos":element.segmentation?element.segmentation:"",
           "Comentarios":"",
           "CTR":element.ctr?element.ctr:0,
           "Visualizacion de imagen":element.photo?element.photo:0,
           "Leads":element.leads?element.leads:0,
           "Respuestas a eventos":element.event_responses?element.event_responses:0,
           "Instalación de aplicaciones":element.installs?element.installs:0,
           "Frecuencia":element.frequency?element.frequency:0,
           "Calificación del anuncio":"",
           "CPL":element.cpl?element.cpl:0,
           "CPI":element.cpi?element.cpi:0,
           "CPRE":element.cpr?element.cpr:0,
           "CPInt":element.cpa?element.cpa:0,
           "CPC":element.cpc?element.cpc:0,
           "CPV":element.cpv?element.cpv:0,
           "CPM":element.cpm?element.cpm:0,
           "CPF":element.cpf?element.cpf:0,
           "Impresiones":element.prints?element.prints:0,
           "Clicks":element.clicks?element.clicks:0,
           "Fans":element.fans?element.fans:0,
           "Alcance":element.reach?element.reach:0,
           "Reproducciones de video":element.views?element.views:0,
           "Reproducciones de video 25%":element.video_playbacks_25?element.video_playbacks_25:0,
           "Reproducciones de video 50%":element.video_playbacks_50?element.video_playbacks_50:0,
           "Reproducciones de video 75%":element.video_playbacks_75?element.video_playbacks_75:0,
           "Reproducciones de video 95%":element.video_playbacks_95?element.video_playbacks_95:0,
           "Reproducciones de video 100%":element.video_playbacks_100?element.video_playbacks_100:0,
           "Duración promedio vistas":element.adv?element.adv:0,
           "Veces que se compartío la publicación":element.postShares?element.postShares:0,
           "Comentarios en la publicacíón":element.postComments?element.postComments:0,
           "Reacciones en la publicacíon":element.post_likes?element.post_likes:0,
           
           

           


        }
        dispatch(addExportData(body))
      })
  
      // exportData.filter((element) => {
      //   element.CampoNuevo="Holis"
      //   dispatch(addExportData(element))
    
      // })


    
      // response.data.campaigns.filter((campaign) => {
      //   /*CAMPAIGNS_ADD_ONE */
      //   dispatch(addExportData(campaign))
      // })
    }

    
  } catch (err) {
    Logger.log(TAG, 'error getAllCampaigns', err)
  }
  dispatch(showLoader(false))
}

const getDataFormat=(date)=>{
  let formated="";
  if(date){
    formated=date.replaceAll(".","/");
  }
  return formated; 

}
function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

async function  insertLog (account,action,request,response,user,obs,idmod,mod){
  let idlog = v4()
  let now = Date.now()
  let logRequest={
    "id": idlog,
    "source":"Lets-Advertise",
    "timestamp":now,
    "action":action,
    "user": user,
    "role":account,
    "idmodulo":idmod,
    "modulo": mod,
    "request":request,
    "response": response,
    "observation": obs 
    
  }
  const responseLog = await APILA.insertLogLine(logRequest)

}
  const addCampaign = allCampaings => {
    
    return dispatch => {
      dispatch({
        type: ADD_All_CAMPAING,
        allCampaings
      })
    }
  }
  const addExportData = exportedData => {
    
    return dispatch => {
      dispatch({
        type: ADD_EXPORTED_DATA,
        exportedData
      })
    }
  }
  