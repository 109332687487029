import * as API from 'helpers/api'
import * as Logger from 'utils/logger'
import * as Modal from './modal.actions'
import { showLoader } from './loader.actions'
import { newEmailValidation } from "redux/actions/email.actions";
import * as APILA from 'helpers/apiLA'
import { v4 } from 'uuid'

export const AUDIENCES_CLEAR = 'AUDIENCES:CLEAR'
export const AUDIENCES_REMOVE = 'AUDIENCES:REMOVE'
export const AUDIENCES_ADD_ONE = 'AUDIENCES:ADD:ONE'
export const AUDIENCES_SET_EDIT = 'AUDIENCES:SET:EDIT'
export const AUDIENCES_CLEAR_EDIT = 'AUDIENCES:CLEAR:EDIT'
export const AUDIENCES_SET_FILTER_TEXT = 'AUDIENCES:SET:FILTER:TEXT'

const TAG = 'Audiences:Actions'

export const getAudiences = (account,user,idmod,mod) => async (dispatch) => {
   dispatch(showLoader(true))
  dispatch(clearAudiences())

  try {
    const response = await API.getAudiences(account)
 
    await insertLog(account,"getAudiences",{ account},response.data,user,"Trae todas las audiencias , /audience/myAudience",idmod,mod)

    Logger.log(TAG, 'getAudiences', response)
    const { audiences } = response.data

    audiences.forEach((audience) => {
      dispatch(addAudience(audience))
    })
  } catch (err) {
    Logger.log(TAG, 'error getAudiences', err)
  }

  dispatch(showLoader(false))
}

export const setFilterText = text => ({
  type: AUDIENCES_SET_FILTER_TEXT,
  text
})

export const clearAudiences = () => ({
  type: AUDIENCES_CLEAR
})

export const updateAudience = (id, name, account, edit, tags, tagsE, updateAt, createAt,active, usuario, accounts,userEmail) => async (dispatch) => {
  console.log("*****EDICION DE AUDIENCIA*****");
  dispatch(showLoader(true))
  
  const bodyE = {
    id,
    name,
    account,
    edit,
    tags,
    tagsE,
    updateAt,
    createAt,
    active,
    userEmail
  }  

  console.log('Lo que viene al redux', bodyE, usuario, accounts);

  try {
    let bodyBitacora = { 
      id: v4(), 
      date: new Date(), 
      account: account, 
      action: 'se ha editado una audiencia', 
      component: 'audience', 
      data: bodyE
    }      
    await APILA.insertBitacora(bodyBitacora)
    console.log("*******updateAudience BODYE BODYE*****", bodyE);
    // await API.createAudience(bodyE) 
    await API.updateAudience(bodyE)

    const title = 'Audiencia Editada'
    const message = 'La audiencia fue editada exitosamente.'
    dispatch(newEmailValidation(account, 'Publicos', bodyE, usuario, accounts, 'Edición'))

    dispatch(
      Modal.showAlertModal(title, message, Modal.MODAL_TYPE_SUCCESS, () => {
        dispatch(getAudiences(account))
      })
    )

  } catch (err) {
    Logger.log(TAG, 'error updateAudience', err)
  }

  dispatch(showLoader(false))
}

export const createAudience = (id, name, account, edit, tags, tagsE) => async (dispatch) => {
  dispatch(showLoader(true))
  
  const body = {
    id,
    name,
    account,
    tags,
    tagsE,
  }

  try {

    console.log("*********Lo que trae EDIT**********", edit);
    console.log("+++++++++++++BODY+++++++++++",body) 
    const response = edit
      ? await API.updateAudience(body) 
      : await

    Logger.log(TAG, 'createAudience', response)
    console.log("************Response*****************" , response)

    const title = edit ? 'Audiencia Editada' : 'Audiencia Creada'
    const message = edit
      ? 'La Audiencia fue editada exitosamente.'
      : 'La audiencia fue creada exitosamente.'
    console.log("TITLE: ***********",title);
    let bodyBitacora = { 
      id: v4(), 
      date: new Date(), 
      account: account, 
      action: edit ? "se ha editado una audiencia" : 'se ha creado una audiencia', 
      component: 'audience', 
      data: body
    }      
    await APILA.insertBitacora(bodyBitacora)
    dispatch(
      Modal.showAlertModal(title, message, Modal.MODAL_TYPE_SUCCESS, () => {
        dispatch(getAudiences(account))
      })
    )
  } catch (err) {
    Logger.log(TAG, 'error createAudience', err)
    const message = edit
      ? 'Ocurrió un error al editar la audiencia'
      : 'Ocurrió un error al crear la audiencia'
    dispatch(Modal.showAlertModal('Error!', message, Modal.MODAL_TYPE_ERROR))
  }

  dispatch(showLoader(false))
}

export const createNewAudience = (id, name, account, tags, tagsE,  usuario, accounts,userEmail) => async (dispatch) => {
  dispatch(showLoader(true))
  
  const body = {
    id,
    name,
    account,
    tags,
    tagsE,
    userEmail
  }
  let bodyEmail = body 
  bodyEmail.createAt = Date.now()
  try {
    
    await API.createAudience(body)

    const title = 'Audiencia Creada'
    const message = 'La audiencia fue creada exitosamente.'
    dispatch(newEmailValidation(account, 'Publicos', bodyEmail, usuario, accounts, 'Creación'))

    dispatch(
      Modal.showAlertModal(title, message, Modal.MODAL_TYPE_SUCCESS, () => {
        dispatch(getAudiences(account))
      })
    )

  } catch (err) {
    Logger.log(TAG, 'error createAudience', err)
  }

  dispatch(showLoader(false))
}

export const deleteAudience = (id, usuario, accounts, idAccount, body) => async (dispatch) => {
  dispatch(showLoader(true))

  try {
    console.log('body delete', body);
    const response = await API.deleteAudience(id)
    let bodyBitacora = { 
      id: v4(), 
      date: new Date(), 
      account: idAccount, 
      action: 'se ha eliminado una audiencia', 
      component: 'audience', 
      data: body
    }      
    await APILA.insertBitacora(bodyBitacora)
    dispatch(newEmailValidation(idAccount, 'Publicos', body, usuario, accounts, 'Eliminación'))
    Logger.log(TAG, 'deleteAudience', response)
    dispatch(Modal.showAlertModal('Audiencia Eliminada', 'La audiencia fue eliminada exitosamente.', Modal.MODAL_TYPE_SUCCESS, () => {
      dispatch(removeAudience(id))
    }))
  } catch (err) {
    Logger.log(TAG, 'error deleteAudience', err)
    dispatch(Modal.showAlertModal('Error!', 'Ocurrió un error al eliminar la audiencia', Modal.MODAL_TYPE_ERROR))
  }

  dispatch(showLoader(false))
}

async function  insertLog (account,action,request,response,user,obs,idmod,mod){
  let idlog = v4()
  let now = Date.now()
  let logRequest={
    "id": idlog,
    "source":"Lets-Advertise",
    "timestamp":now,
    "action":action,
    "user": user,
    "role":account,
    "idmodulo":idmod,
    "modulo": mod,
    "request":request,
    "response": response,
    "observation": obs 
    
  }
  const responseLog = await APILA.insertLogLine(logRequest)

}
export const setEdit = edit => ({
  type: AUDIENCES_SET_EDIT,
  edit,
})

export const clearEdit = () => ({
  type: AUDIENCES_CLEAR_EDIT,
})

const addAudience = audience => ({
  type: AUDIENCES_ADD_ONE,
  audience
})

const removeAudience = audience => ({
  type: AUDIENCES_REMOVE,
  audience
})