import React, { useState, useEffect } from "react";
import _ from "lodash";
import classnames from "classnames";
import { connect } from "react-redux";
import { Auth } from "aws-amplify";
import { withRouter } from "react-router-dom";
import { FormGroup, Label, Input, FormFeedback } from "reactstrap";

import "./assets/css/styles.css";

import grupo from "./assets/img/grupo.png";

import * as Modal from "redux/actions/modal.actions";
import { showLoader } from "redux/actions/loader.actions";
import { Tooltip } from "antd";

import { withUtils } from "utils";
import analytics from "../../helpers/analytics";
const TAG = "UpdatePasswordForm";

const UpdatePasswordForm = (props) => {
  const [userData, setUserData] = useState(null);
  const [state, setState] = useState({
    newPassword: "",
    confirmPassword: "",
    currentPassword: "",
  });
  const [errors, setErrors] = useState({
    newPassword: false,
    confirmPassword: false,
    currentPassword: false,
  });

  useEffect(() => {
    Auth.currentAuthenticatedUser().then((userData) => setUserData(userData));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleInputChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;

    setState((prev) => ({
      ...prev,
      [name]: value,
    }));

    let error = _.isEmpty(value);

    if (name === "confirmPassword") {
      error = state.newPassword !== value;
    }

    setErrors((prev) => ({
      ...prev,
      [name]: error,
    }));
  };

  const updatePassword = async (e) => {
    e.preventDefault();
    props._showLoader(true);

    if (userData !== null) {
      let error = "";

      try {
        props.utils.log(TAG, "currentPassword", state.currentPassword);
        props.utils.log(TAG, "newPassword", state.newPassword);

        await Auth.changePassword(
          userData,
          state.currentPassword,
          state.newPassword
        );

        props.utils.log(TAG, "password updated successfully");
        analytics.updateUserPassword(userData);
        props._showAlertModal(
          "Actualizar Contraseña!",
          "Tu contraseña fue actualizada correctamente",
          Modal.MODAL_TYPE_SUCCESS,
          () => {
            props.history.push("/profile");
          }
        );

      } catch (err) {
        let { code, message } = err;
        error = message;

        switch (code) {
          case "NotAuthorizedException":
            error = "Tu contraseña actual es incorrecta";
            break;
          case "InvalidParameterException":
            error = "Tu contraseña nueva tiene que tener al menos 6 caracteres";
            break;
        }

        props.utils.log(TAG, "error updating password", err);
      }

      props._showLoader(false);

      if (error !== "") {
        props._showAlertModal("Error!", error, Modal.MODAL_TYPE_ERROR);
      }
    }
  };

  let submitBtnClass = classnames("btn-camp w-100 m-0 mb-3", {
    disabled:
      errors.newPassword ||
      errors.confirmPassword ||
      errors.currentPassword ||
      state.confirmPassword === "" ||
      state.currentPassword === "" ||
      state.newPassword === "",
  });

  return (
    <div className="form-login">
      <div className="form-margin-login">
        <div className="lado">
          <h1 className="title-form">Cambiar Contrase&ntilde;a!</h1>

          <div className="d-flex flex-column w-100">
            <div className="">
              <FormGroup>
                <Label
                  style={{
                    color: "#05252d",
                    fontWeight: 500,
                    marginBottom: "5px",
                  }}
                >
                  Contrase&ntilde;a actual
                </Label>
                <Input
                  type="password"
                  name="currentPassword"
                  className="input"
                  value={state.currentPassword}
                  onChange={handleInputChange}
                  placeholder="Ingresa tu contraseña actual"
                  invalid={errors.currentPassword}
                />
                <FormFeedback>Ingresa tu contraseña actual</FormFeedback>
              </FormGroup>
            </div>

            <div className="">
              <FormGroup>
                <Label
                  style={{
                    color: "#05252d",
                    fontWeight: 500,
                    marginBottom: "5px",
                  }}
                >
                  Nueva contrase&ntilde;a
                </Label>
                <Input
                  type="password"
                  name="newPassword"
                  className="input"
                  value={state.newPassword}
                  onChange={handleInputChange}
                  placeholder="Ingresa tu nueva contraseña"
                  invalid={errors.newPassword}
                />
                <FormFeedback>Ingresa una nueva contrase&ntilde;a</FormFeedback>
              </FormGroup>
            </div>

            <div className="">
              <FormGroup>
                <Label
                  style={{
                    color: "#05252d",
                    fontWeight: 500,
                    marginBottom: "5px",
                  }}
                >
                  Confirmar contrase&ntilde;a
                </Label>
                <Input
                  type="password"
                  name="confirmPassword"
                  className="input"
                  value={state.confirmPassword}
                  onChange={handleInputChange}
                  placeholder="Confirma tu nueva contraseña"
                  invalid={errors.confirmPassword}
                />
                <FormFeedback>Las contrase&ntilde;as no coinciden</FormFeedback>
              </FormGroup>
            </div>
          </div>
          <Tooltip
            placement="top"
            title="Actualizar contraseña"
            color="#002448"
          >
            <a onClick={updatePassword} className={submitBtnClass} href="#">
              Actualizar Contrase&ntilde;a
            </a>
          </Tooltip>
        </div>

        <div className="lado">
          <img src={grupo} className="people" alt="" />
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  _showLoader: (show) => dispatch(showLoader(show)),
  _showAlertModal: (title, message, type, callback) =>
    dispatch(Modal.showAlertModal(title, message, type, callback)),
});

export default connect(
  undefined,
  mapDispatchToProps
)(withUtils(withRouter(UpdatePasswordForm)));
