import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import PageLoader from "components/page_loader";
import { Tooltip } from "antd";
import { ShowColumn2, FilterColumn2 } from "@easygosanet/react-datatable-utils";
import ReactDataTable from "@easygosanet/react-datatable";
import { columnsBudgets2 } from "config/columns";
import * as APILA from "helpers/apiLA";
import _ from "lodash";
import { Input, FormFeedback } from "reactstrap";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import { orange } from "@mui/material/colors";
import { Auth } from "aws-amplify";
import { v4 } from "uuid";
import "./style.scss";

const BudgetLogs = (props) => {
  const ColorButton = styled(Button)(({ theme }) => ({
    backgroundColor: orange[700],
    "&:hover": {
      backgroundColor: orange[700],
    },
  }));

  // ? Estados iniciales
  const initialState = {
    value: "",
  };
  const invalid = {
    value: false,
  };

  const initialOptions = ["Id de ov", "Accion realizada", "Presupuesto total"];

  const nameMaped = {
    "Id de ov": "idOv",
    "Accion realizada": "action",
    "Presupuesto total": "amount",
  };

  const orderDefault = [
    "user",
    "idBudget",
    "amount",
    "action",
    "date",
    "idCampaign",
    "idOv",
  ];

  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [dataBack, setDataBack] = useState([]);
  const [state, setState] = useState(initialState);
  const [invalidState, setInvalidState] = useState(invalid);
  const [views, setViews] = useState([]);
  const [nameView, setNameView] = useState("");
  const [options, setOptions] = useState([...initialOptions]);
  const [filterInfo, setFilterInfo] = useState([]);
  const [nameGrouped, setNameGrouped] = useState("");
  const [columns, setColumns] = useState([...columnsBudgets2]);

  const customStyles = {
    headCells: {
      style: {
        color: "#000",
        fontSize: "0.9rem",
        textAlign: "center",
        justifyContent: "center",
        backgroundColor: "#fff",
        width: "160px",
        "&:nth-of-type(2n)": {
          backgroundColor: "#eff0f2",
          borderRadius: "10px 10px 0 0",
        },
      },
    },
    cells: {
      style: {
        justifyContent: "center",
        backgroundColor: "#fff",
        "&:nth-of-type(1)": {
          justifyContent: "left",
        },
        "&:nth-of-type(2n)": {
          backgroundColor: "#eff0f2",
        },
      },
    },
  };

  const handleFilterText = (e) => {
    let value = e.target.value;
    setState((prev) => ({
      ...prev,
      value: value,
    }));
    setInvalidState((prev) => ({
      ...prev,
      value: _.isEmpty(value),
    }));
  };

  const validError = () => {
    const errores = {
      value: _.isEmpty(state.value),
    };
    setInvalidState((prev) => ({
      ...prev,
      ...errores,
    }));
    return _.some(errores, (e) => e);
  };

  const handleClick = async () => {
    try {
      if (validError()) return;
      setIsLoading(true);
      let request = await APILA.getLogsBudget({ user: state.value.trim() });
      setData(request?.data?.data);
      setDataBack(request?.data?.data);
    } catch (error) {
      console.log("Error en la busqueda de la bitacora", { error });
    } finally {
      setIsLoading(false);
    }
  };

  const enterSend = (e) => {
    if (e.key === "Enter") {
      handleClick();
    }
  };

  // Componente ShowColumn2
  useEffect(() => {
    actualizarViews(true);
  }, [props.currentAccount]);

  const handleShowColumn = (column, show) => {
    let index = columns.findIndex((col) => col.id === column);
    let aux = columns.map((col) => col);
    if (aux[index]) {
      aux[index].omit = show;
      setColumns(aux);
    }
  };

  const handleShowView = (vista, show) => {
    let nombres = [];
    let id = views.find((ele) => ele.active);
    if (id) actualizarEstado(id.id, false);
    actualizarEstado(vista, show);
    let vistaEnc = views.find((ele) => ele.id === vista);
    vistaEnc.columns.sort((a, b) => {
      return a.position - b.position;
    });
    vistaEnc.columns.forEach((ele) => {
      if (show) {
        nombres.push(ele.id);
        handleShowColumn(ele.id, ele.omit);
      } else {
        handleShowColumn(ele.id, false);
      }
    });
    if (show) {
      handleColumnSortChange(nombres);
    } else {
      handleColumnSortChange(orderDefault);
    }
  };

  const actualizarEstado = async (id, estado) => {
    try {
      setIsLoading(true);
      let body = {
        id: id,
        active: estado,
      };
      await APILA.updateViewActive(body);
      actualizarViews(false);
    } catch (error) {
      console.log("Error en la actualizacion de la vista", { error });
    } finally {
      setIsLoading(false);
    }
  };

  const actualizarViews = async (tipo) => {
    try {
      if (!Auth.user) return;
      const sub = Auth.user.signInUserSession.idToken.payload.sub;
      const viewsData = await APILA.getViewsByUser({
        account: props.currentAccount,
        user: sub,
        component: "logs-budgets",
      });

      const dataVie = viewsData.data.views;
      setViews(dataVie);

      if (tipo) setFilterInfo([]);

      if (dataVie.length === 0) return setNameView("Lista");

      const vistaActiva = dataVie.find((ele) => ele.active);
      if (!vistaActiva) {
        return;
      }
      setNameView(vistaActiva.name);
      if (tipo) {
        const nombres = vistaActiva.columns.map((ele) => {
          handleShowColumn(ele.id, ele.omit);
          return ele.id;
        });
        handleColumnSortChange(nombres);
      }
    } catch (error) {
      console.error("Error fetching views:", error);
    }
  };

  const handleColumnSortChange = (positions) => {
    let aux = columns.map((col) => col);

    positions.forEach((columnId, position) => {
      let index = columns.findIndex((col) => col.id === columnId);
      if (index != -1) aux[index].position = position;
    });

    aux = _.orderBy(aux, ["position"]);

    setColumns(aux);
  };

  const handleAddView = async (name) => {
    try {
      setIsLoading(true);
      let id = views.find((ele) => ele.active);
      let viewEnc = views.find((ele) => ele.name === name);
      let idVIew = "";
      if (viewEnc) idVIew = viewEnc.id;
      else idVIew = v4();
      if (id) actualizarEstado(id.id, false);
      let body = {
        id: idVIew,
        name: name,
        component: "logs-budgets",
        columns: columns,
        account: props.currentAccount,
        user: props.currentUser,
        active: true,
      };
      await APILA.insertView(body);
      actualizarViews(false);
    } catch (error) {
      console.log("Error en agregar una vista", { error });
    } finally {
      setIsLoading(false);
    }
  };

  const handleDeleteView = async (name) => {
    try {
      setIsLoading(true);
      const activeView = views.find((view) => view.active);
      if (activeView && activeView.id === name) {
        setNameView("lista");
        columns.forEach((column) => {
          handleShowColumn(column.id, false);
        });
        handleColumnSortChange(orderDefault);
      }
      await APILA.deleteView({ id: name });
      actualizarViews(false);
    } catch (error) {
      console.error("Error deleting view:", error);
    } finally {
      setIsLoading(false);
    }
  };

  function numberWithCommas(x) {
    let auxNumber = Number(x).toFixed(2);
    return auxNumber.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  // Componete FilterColumn2
  const agrupacion = () => {
    if (!data) {
      return null;
    }
    let titleUsed = [];
    return data.map((bitacora, idx) => {
      let title = bitacora[nameGrouped];

      if (!titleUsed.includes(title)) {
        titleUsed.push(title);
        let agrupacionData = data.filter(
          (bitacoraAg) => bitacoraAg[nameGrouped] === title
        );

        return (
          <React.Fragment key={idx}>
            <h1>
              {nameGrouped === "amount" ? numberWithCommas(title) : title}
            </h1>
            <ReactDataTable
              noDataComponent={"No hay datos para mostrar"}
              columns={columns}
              data={agrupacionData}
              persistTableHead
            />
          </React.Fragment>
        );
      }
      return null;
    });
  };

  const viewChanges = () => {
    if (
      nameGrouped === "Por defecto" ||
      nameGrouped === "" ||
      nameGrouped === undefined
    ) {
      return (
        <ReactDataTable
          progressPending={isLoading}
          noDataComponent={"No hay datos para mostrar"}
          columns={columns}
          data={data}
          persistTableHead
          customStyles={customStyles}
          paginationComponentOptions={{
            rowsPerPageText: "Elementos por página",
            rangeSeparatorText: "de",
          }}
          // onChangePage={handleChangePage}
          // paginationPerPage={1}
          // paginationDefaultPage={currentPage || 1}
          pagination
        />
      );
    } else {
      return agrupacion();
    }
  };

  const handleGroupedChange = (value) => {
    let nameGrouped = nameMaped[value];
    setNameGrouped(nameGrouped);
  };

  const handleFilterPerColumn = (value) => {
    if (value.length === 0 || value[0].value === "") {
      setFilterInfo([]);
      setData(dataBack);
      return;
    }

    let filteredData = dataBack.filter(
      (item) => item.idOv && item.idOv.toString().includes(value[0].value)
    );
    setData(filteredData);
    setFilterInfo(value);
  };

  return (
    <>
      {isLoading && <PageLoader />}
      <div className="main__budgets__">
        <div className="barraSuperior">
          <Tooltip color="#002448" placement="top" title="Ingresa tu busqueda">
            <Input
              value={state.value}
              type="text"
              placeholder="Buscar por usuario, cuenta o id de presupuesto"
              invalid={invalidState.value}
              onChange={(e) => handleFilterText(e)}
              onKeyDown={enterSend}
            />
          </Tooltip>
          <FormFeedback className="formFeedBack">
            Ingresa una Búsqueda
          </FormFeedback>
          <Tooltip color="#002448" placement="top" title="Buscar">
            <ColorButton
              className="ColorButton"
              onClick={handleClick}
              variant="contained"
            >
              Buscar
            </ColorButton>
          </Tooltip>

          <Tooltip
            color="#002448"
            placement="top"
            title="Personalizar columnas"
          >
            <div id="shareBtn">
              <ShowColumn2
                className="showColumn"
                columns={columns}
                showColumn={handleShowColumn}
                showView={handleShowView}
                onSortChange={(positions) => {
                  handleColumnSortChange(positions);
                }}
                vistas={views}
                setVista={(nombre) => {
                  handleAddView(nombre);
                }}
                deleteView={(name) => {
                  handleDeleteView(name);
                }}
                nameAc={nameView}
              />
            </div>
          </Tooltip>
          <Tooltip color="#002448" placement="top" title="Filtrar y agrupar">
            <div className="Filtro2 filtro3">
              <FilterColumn2
                columns={columns}
                data={data}
                options={options}
                groupedDefault={"Por defecto"}
                changeGrouped={handleGroupedChange}
                onFilterColumn={(value) => handleFilterPerColumn(value)}
                categories={[]}
                filterInfoCheck={filterInfo}
              />
            </div>
          </Tooltip>
        </div>
        {/* <section className="main_emails_sectionTable"> */}
        <div className="Kanbang-content">{viewChanges()}</div>
        {/* </section> */}
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  currentUser: state.app.current_user,
  currentAccount: state.accounts.default,
});

export default connect(mapStateToProps, null)(BudgetLogs);
