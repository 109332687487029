export const NEXT_STEP = "NEXT_STEP";
export const SAVE_NAME = "SAVE_NAME";
export const CLEART_STEPS = "CLEART_STEPS";

export const nextStep = (step) => ({
    type: NEXT_STEP,
    step
})

export const saveName = (name) => ({
    type: SAVE_NAME,
    name
})

export const clearSteps = () => ({
    type: CLEART_STEPS
})